import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  closeIcon,
  filterLightIcon,
  filterPrimaryIcon,
} from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

interface StudentData {
  course: string;
  date: string;
  term: number;
  year: string;
  enrollno: number;
}

function createStudentData(
  course: string,
  date: string,
  term: number,
  year: string,
  enrollno: number
): StudentData {
  return {
    course,
    date,
    term,
    year,
    enrollno,
  };
}

const educatorsrow = [
  createStudentData("Life Beyond Trauma", "03 Nov 2022", 1, "2023", 7),
  createStudentData("Mindfulnes workshop", "10 Nov 2022", 1, "2023", 9),
  createStudentData("Creativity for recovery", "17 Nov 2022", 1, "2023", 10),
  createStudentData(
    "An introduction to recovery & the strength of approach",
    "03 Nov 2022",
    2,
    "2023",
    10
  ),
  createStudentData(
    "Making and keeping connections",
    "10 Nov 2022",
    2,
    "2022",
    10
  ),
  createStudentData("Exploring psychosis", "17 Nov 2022", 3, "2022", 10),
  createStudentData("Exploring trauma for women", "03 Nov 2022", 4, "2023", 10),
  createStudentData(
    "Taking control of your life",
    "03 Nov 2022",
    2,
    "2022",
    10
  ),
  createStudentData("Physical health & welbeing", "10 Nov 2022", 1, "2022", 10),
  createStudentData("Yoga for recovery", "17 Nov 2022", 4, "2022", 10),
];

const DesignerReportsStudent: React.FC = () => {
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [enrolType, setEnrolType] = React.useState("");
  const [existNew, setExistNew] = React.useState("");
  const [sector, setSector] = React.useState("");
  const [age, setAge] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [aboriginal, setAboriginal] = React.useState("");
  const [lgbtiq, setLgbtiq] = React.useState("");
  const [cald, setCald] = React.useState("");
  const [empStatus, setEmpStatus] = React.useState("");
  const [studyLoad, setStudyLoad] = React.useState("");

  const [filterbox, setFilterbox] = React.useState(false);

  const [exportMenu, setExportMenu] = React.useState<null | HTMLElement>(null);
  const exportOpen = Boolean(exportMenu);
  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenu(event.currentTarget);
  };
  const handleExportClose = () => {
    setExportMenu(null);
  };

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Box
        className="filter-overlay"
        onClick={() => {
          document.body.classList.toggle("filter-open");
          setFilterbox(false);
        }}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        <div className="main-content">
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Report - Student
            </Typography>
            <Box sx={{ display: "flex", columnGap: "15px" }}>
              <Button
                id="export-btn"
                aria-controls={exportOpen ? "export-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={exportOpen ? "true" : undefined}
                onClick={handleExportClick}
                disableElevation
                variant="outlined"
                className="btn-export"
              >
                Export
              </Button>
              <Menu
                id="export-menu"
                anchorEl={exportMenu}
                open={exportOpen}
                onClose={handleExportClose}
                MenuListProps={{
                  "aria-labelledby": "export-button",
                }}
                className="export-menu"
              >
                <MenuItem onClick={handleExportClose} title="Excel">
                  <Button>Excel</Button>
                </MenuItem>
                <MenuItem onClick={handleExportClose} title="PDF">
                  <Button>PDF</Button>
                </MenuItem>
              </Menu>
              <Button
                variant="outlined"
                className="btn-filter report-filter"
                onClick={() => {
                  setFilterbox(true);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={filterPrimaryIcon} alt="filter" className="default" />
                <img src={filterLightIcon} alt="filter" className="active" />
                <span className="text">Filter</span>
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            width: "370px",
                            minWidth: "240px",
                          }}
                        >
                          Course
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "206px", minWidth: "136px" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "206px", minWidth: "130px" }}
                        >
                          Term
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "206px", minWidth: "130px" }}
                        >
                          Year
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "136px", minWidth: "136px" }}
                        >
                          No. of Enrolments
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {educatorsrow.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography>{row.course}</Typography>
                          </TableCell>
                          <TableCell>{row.date}</TableCell>
                          <TableCell>{row.term}</TableCell>
                          <TableCell>{row.year}</TableCell>
                          <TableCell>{row.enrollno}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                    <Typography variant="body2" component="span">
                      Showing 10 - 20 of 50 entries
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={5}
                    variant="outlined"
                    shape="rounded"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
          <DesignerFooter />
        </div>
      </main>

      {filterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="select">
              <InputLabel id="enroltype-select-label">
                Enrolment Type
              </InputLabel>
              <Select
                labelId="enroltype-select-label"
                id="enroltype-select"
                label="Enrolment Type"
                value={enrolType}
                onChange={(event: SelectChangeEvent) => {
                  setEnrolType(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Consumer</MenuItem>
                <MenuItem value={2}>Carer</MenuItem>
                <MenuItem value={3}>Partner Employee</MenuItem>
                <MenuItem value={4}>Staff</MenuItem>
                <MenuItem value={5}>Volunteer</MenuItem>
                <MenuItem value={6}>Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="existnew-select-label">Existing / New</InputLabel>
              <Select
                labelId="existnew-select-label"
                id="existnew-select"
                label="Existing / New"
                value={existNew}
                onChange={(event: SelectChangeEvent) => {
                  setExistNew(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>New</MenuItem>
                <MenuItem value={2}>Existing</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="sector-select-label">Sector</InputLabel>
              <Select
                labelId="sector-select-label"
                id="sector-select"
                label="Sector"
                value={sector}
                onChange={(event: SelectChangeEvent) => {
                  setSector(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>CALD - Out of Area</MenuItem>
                <MenuItem value={2}>East</MenuItem>
                <MenuItem value={3}>Out of Area - IND</MenuItem>
                <MenuItem value={4}>St George</MenuItem>
                <MenuItem value={5}>Sutherland</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="age-select-label">Age</InputLabel>
              <Select
                labelId="age-select-label"
                id="age-select"
                label="Age"
                value={age}
                onChange={(event: SelectChangeEvent) => {
                  setAge(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>16-17</MenuItem>
                <MenuItem value={2}>18-24</MenuItem>
                <MenuItem value={3}>25-29</MenuItem>
                <MenuItem value={4}>30-34</MenuItem>
                <MenuItem value={5}>35-40</MenuItem>
                <MenuItem value={6}>41-45</MenuItem>
                <MenuItem value={7}>46-50</MenuItem>
                <MenuItem value={8}>51-55</MenuItem>
                <MenuItem value={9}>56-60</MenuItem>
                <MenuItem value={10}>61-64</MenuItem>
                <MenuItem value={11}>65+</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                labelId="gender-select-label"
                id="gender-select"
                label="Gender"
                value={gender}
                onChange={(event: SelectChangeEvent) => {
                  setGender(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Man</MenuItem>
                <MenuItem value={2}>Woman</MenuItem>
                <MenuItem value={3}>Trans</MenuItem>
                <MenuItem value={4}>30-34</MenuItem>
                <MenuItem value={5}>Prefer not to say</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="aboriginal-select-label">
                Aboriginal/Torres Strait Islander Identity
              </InputLabel>
              <Select
                labelId="aboriginal-select-label"
                id="aboriginal-select"
                label="Aboriginal/Torres Strait Islander Identity"
                value={aboriginal}
                onChange={(event: SelectChangeEvent) => {
                  setAboriginal(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Aboriginal</MenuItem>
                <MenuItem value={2}>Torres Strait Islander</MenuItem>
                <MenuItem value={3}>
                  Not Aboriginal or Torres Strait Islander
                </MenuItem>
                <MenuItem value={4}>Prefer not to say</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="lgbtiq-select-label">LGBTIQ+</InputLabel>
              <Select
                labelId="lgbtiq-select-label"
                id="lgbtiq-select"
                label="LGBTIQ+"
                value={lgbtiq}
                onChange={(event: SelectChangeEvent) => {
                  setLgbtiq(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Heterosexual</MenuItem>
                <MenuItem value={2}>Lesbian, Gay or Bisexual</MenuItem>
                <MenuItem value={3}>Other</MenuItem>
                <MenuItem value={4}>Prefer not to say</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="cald-select-label">CALD</InputLabel>
              <Select
                labelId="cald-select-label"
                id="cald-select"
                label="CALD"
                value={cald}
                onChange={(event: SelectChangeEvent) => {
                  setCald(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Heterosexual</MenuItem>
                <MenuItem value={2}>Lesbian, Gay or Bisexual</MenuItem>
                <MenuItem value={3}>Other</MenuItem>
                <MenuItem value={4}>Prefer not to say</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="empStatus-select-label">
                Current Employment Status
              </InputLabel>
              <Select
                labelId="empStatus-select-label"
                id="empStatus-select"
                label="Current Employment Status"
                value={empStatus}
                onChange={(event: SelectChangeEvent) => {
                  setEmpStatus(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Full-time employment</MenuItem>
                <MenuItem value={2}>Part-time employment</MenuItem>
                <MenuItem value={3}>Casual employment</MenuItem>
                <MenuItem value={4}>Volunteer work</MenuItem>
                <MenuItem value={5}>Supported employment</MenuItem>
                <MenuItem value={6}>Student</MenuItem>
                <MenuItem value={7}>
                  Unemployed, job seeking independently
                </MenuItem>
                <MenuItem value={8}>
                  Unemployed, job seeking with employment agency
                </MenuItem>
                <MenuItem value={9}>Unwaged, not seeking work</MenuItem>
                <MenuItem value={10}>Prefer not to say</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="studyLoad-select-label">Study Load</InputLabel>
              <Select
                labelId="studyLoad-select-label"
                id="studyLoad-select"
                label="Study Load"
                value={studyLoad}
                onChange={(event: SelectChangeEvent) => {
                  setStudyLoad(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Full-time</MenuItem>
                <MenuItem value={2}>Part-time</MenuItem>
                <MenuItem value={3}>N/A - Not currently studing</MenuItem>
                <MenuItem value={4}>Prefer not to say</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default DesignerReportsStudent;
