import "./App.css";
import "./config";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import projectTheme from "app.theme";
import Loader from "components/Loader";
import Routes from "./routes";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={projectTheme}>
      <Router>
        <Loader />
        <Routes />
      </Router>
    </ThemeProvider>
  );
};

export default App;
