import {
  UPCOMING_COURSE_LIST_REQUEST,
  UPCOMING_COURSE_LIST_SUCCESS,
  UPCOMING_COURSE_LIST_FAILURE,
  PAST_STUDENT_COURSE_LIST_FAILURE,
  PAST_STUDENT_COURSE_LIST_REQUEST,
  PAST_STUDENT_COURSE_LIST_SUCCESS,
  COURSE_OFFERING_LIST_REQUEST,
  COURSE_OFFERING_LIST_SUCCESS,
  COURSE_OFFERING_LIST_FAILURE,
  STUDENT_DASHBOARD_COURSE_DETAILS_REQUEST,
  STUDENT_DASHBOARD_COURSE_DETAILS_SUCCESS,
  STUDENT_DASHBOARD_COURSE_DETAILS_FAILURE,
  APPLY_OR_WITHDRAW_COURSE_REQUEST,
  APPLY_OR_WITHDRAW_COURSE_SUCCESS,
  APPLY_OR_WITHDRAW_COURSE_FAILURE,
  MY_ENROLMENT_LIST_REQUEST,
  MY_ENROLMENT_LIST_SUCCESS,
  MY_ENROLMENT_LIST_FAILURE,
  DOWNLOAD_CERTIFICATE_REQUEST,
  DOWNLOAD_CERTIFICATE_SUCCESS,
  DOWNLOAD_CERTIFICATE_FAILURE,
  CHANGE_COURSE_PREFERENCE_REQUEST,
  CHANGE_COURSE_PREFERENCE_SUCCESS,
  CHANGE_COURSE_PREFERENCE_FAILURE,
} from "./actionTypes";

import { StudentDashboardActions, StudentDashboardState } from "./types";

const initialState: StudentDashboardState = {
  pending: false,
  upcomingCourseList: [],
  pastCourseList: [],
  courseOfferingList: [],
  error: null,
};

const reducers = (
  state = initialState,
  action: StudentDashboardActions
): unknown => {
  switch (action.type) {
    case UPCOMING_COURSE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPCOMING_COURSE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        upcomingCourseList: action.payload.upcomingCourseList,
        error: null,
      };
    case UPCOMING_COURSE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case PAST_STUDENT_COURSE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PAST_STUDENT_COURSE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        pastCourseList: action.payload.pastCourseList,
        error: null,
      };
    case PAST_STUDENT_COURSE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case COURSE_OFFERING_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case COURSE_OFFERING_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        pastCourseList: action.payload.courseOfferingList,
        error: null,
      };
    case COURSE_OFFERING_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case STUDENT_DASHBOARD_COURSE_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case STUDENT_DASHBOARD_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        courseOfferingDetails: action.payload.courseOfferingDetails,
        error: null,
      };
    case STUDENT_DASHBOARD_COURSE_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case APPLY_OR_WITHDRAW_COURSE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case APPLY_OR_WITHDRAW_COURSE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case APPLY_OR_WITHDRAW_COURSE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case MY_ENROLMENT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case MY_ENROLMENT_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        myEnrolmentList: action.payload.myEnrolmentList,
        error: null,
      };

    case MY_ENROLMENT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DOWNLOAD_CERTIFICATE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DOWNLOAD_CERTIFICATE_SUCCESS:
      return {
        ...state,
        pending: false,
        item: action.payload.item,
        error: null,
      };
    case DOWNLOAD_CERTIFICATE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case CHANGE_COURSE_PREFERENCE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_COURSE_PREFERENCE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case CHANGE_COURSE_PREFERENCE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
