import { Box } from "@mui/system";
import {
  Modal,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  FormHelperText,
  Fade,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { closeIcon, saveIcon } from "assets/images";
import { Controller, useForm } from "react-hook-form";
import { IAddEditTerm, ITerm } from "utils/interfaces/term";
import {
  CLOSE_TOOLTIP_TITLE,
  DATE_PICKER_FORMAT,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  TERM_NAME_MAX_LENGTH,
  TERM_NUMBER,
  TERM_STATUS,
} from "utils/constants/constant";
import { useEffect } from "react";
import {
  checkEndDateLessThanStart,
  getTermStatusNumber,
  onNumberFieldKeyPress,
} from "utils/helper";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CalendarIcon from "components/CalendarIcon";
import {
  START_DATE_YEAR_SHOULD_BE_SAME_AS_TERM_YEAR,
  WHITE_SPACE_ERROR_SPACE,
} from "utils/constants/Messages";

interface IAddEditTermProps {
  isEditTerm: boolean;
  isOpenTermModal: boolean;
  handleTermClose: any;
  handleSaveClick: any;
  editTermData?: ITerm;
}

const AddEditTerm: React.FC<IAddEditTermProps> = ({
  isEditTerm,
  isOpenTermModal,
  handleTermClose,
  handleSaveClick,
  editTermData,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
  } = useForm<IAddEditTerm>({
    defaultValues: {
      status: 0,
    },
  });

  const onModalClose = () => {
    reset();
    handleTermClose();
  };

  const getTermNameError = (): string => {
    if (errors.termName) {
      if (errors.termName.type === "required") {
        return "Term name is required";
      }

      if (errors.termName.type === "maxLength") {
        return `Maximum length of term name should be ${TERM_NAME_MAX_LENGTH}`;
      }

      if (errors.termName.type === "pattern") {
        return `Term name ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getTermError = (): string => {
    if (errors.term) {
      if (errors.term.type === "required") {
        return "Term is required";
      }
    }

    return "";
  };

  const getYearError = (): string => {
    if (errors.year) {
      if (errors.year.type === "required") {
        return "Year is required";
      }
      if (errors.year.type === "minLength") {
        return "Enter a valid 4-digit year";
      }
      if (errors.year.type === "maxLength") {
        return "Enter a valid 4-digit year";
      }
    }

    return "";
  };

  const getStatusError = (): string => {
    if (errors.status) {
      if (errors.status.type === "required") {
        return "Term status is required";
      }
    }

    return "";
  };

  const getStartDateError = (): string => {
    if (errors.startDate) {
      if (errors.startDate.type === "required") {
        return "Start date is required";
      }
      if (errors.startDate.type === "validate") {
        return START_DATE_YEAR_SHOULD_BE_SAME_AS_TERM_YEAR;
      }
    }

    return "";
  };

  const getEndDateError = (): string => {
    if (errors.endDate) {
      if (errors.endDate.type === "required") {
        return "End date is required";
      }
      if (errors.endDate.type === "validate") {
        return "End Date should be greater than Start Date";
      }
    }

    return "";
  };

  useEffect(() => {
    if (isEditTerm && editTermData) {
      setValue("term", editTermData?.term);
      setValue("year", editTermData?.year);
      setValue("termName", editTermData?.termName);
      setValue("status", editTermData?.status);
      setValue("startDate", dayjs(editTermData?.startDate));
      setValue("endDate", dayjs(editTermData?.endDate));
    } else {
      reset();
    }
  }, [editTermData, isEditTerm, setValue, isOpenTermModal, reset]);

  useEffect(() => {
    if (watch("term") && watch("year")) {
      const name = `${watch("year")} T${watch("term")}`;
      if (isEditTerm && editTermData) {
        if (
          watch("term") !== editTermData?.term ||
          watch("year") !== editTermData?.year
        ) {
          setValue("termName", name);
        } else {
          setValue("termName", editTermData?.termName);
        }
      } else {
        setValue("termName", name);
      }

      trigger("termName");
    }
    if (watch("term")) {
      trigger("term");
    }
    if (watch("year")) {
      trigger("year");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("term"), watch("year")]);

  useEffect(() => {
    if (!editTermData) {
      if (watch("startDate") && watch("endDate")) {
        const start = new Date(watch("startDate"));
        const end = new Date(watch("endDate"));
        const status = getTermStatusNumber(start, end);
        setValue("status", Number(status));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("startDate"), watch("endDate"), setValue]);

  const generateDateDisabled = () => {
    const selectedYear = watch("year");

    if (!selectedYear) {
      return () => false;
    }

    const startDate = dayjs(`${selectedYear}-01-01`);
    const endDate = dayjs(`${selectedYear}-12-31`);

    return (date: any) => date.isBefore(startDate) || date.isAfter(endDate);
  };
  const checkYearAndDatesYear = (start: Date, year: number) => {
    const startYear = new Date(start).getFullYear();

    return startYear === (year as number);
  };

  return (
    <>
      <Modal open={isOpenTermModal} onClose={onModalClose}>
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <Box className="common-modal terms-modal">
            <Box className="modal-header">
              <Typography variant="h4">
                {isEditTerm ? "Edit Term" : "Add Term"}
              </Typography>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={CLOSE_TOOLTIP_TITLE}
                arrow
              >
                <IconButton onClick={onModalClose}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="modal-body">
              <Grid container columnSpacing="24px">
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="term-select-label">
                      Term <span className="color-red">*</span>
                    </InputLabel>
                    <Select
                      labelId="term-select-label"
                      id="term-select"
                      label="Term"
                      value={watch("term")}
                      error={!!errors.term}
                      {...register("term", {
                        required: true,
                      })}
                    >
                      {TERM_NUMBER?.map((term) => (
                        <MenuItem key={term.value} value={term.value}>
                          {term.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.term && (
                      <FormHelperText error>{getTermError()}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    id="year-select"
                    label={
                      <>
                        Year <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    type="number"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: !!editTermData?.year || !!watch("year"),
                    }}
                    error={!!errors.year}
                    helperText={getYearError()}
                    {...register("year", {
                      required: true,
                      maxLength: 4,
                      minLength: 4,
                    })}
                    onKeyPress={onNumberFieldKeyPress}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="termName"
                    label={
                      <>
                        Term Name <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    multiline
                    InputLabelProps={{
                      shrink: !!editTermData?.termName || !!watch("termName"),
                    }}
                    error={!!errors.termName}
                    helperText={getTermNameError()}
                    {...register("termName", {
                      required: true,
                      maxLength: TERM_NAME_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DesktopDatePicker"]}>
                      <Controller
                        control={control}
                        {...register("startDate", {
                          required: true,
                          validate: (value) =>
                            checkYearAndDatesYear(value, Number(watch("year"))),
                        })}
                        render={({ field }) => (
                          <DesktopDatePicker
                            {...field}
                            className="date-picker disabled-picker-text-field"
                            format={DATE_PICKER_FORMAT}
                            shouldDisableDate={generateDateDisabled()}
                            label={
                              <>
                                Term Start Date{" "}
                                <span className="color-red">*</span>
                              </>
                            }
                            slots={{
                              openPickerIcon: CalendarIcon,
                            }}
                            slotProps={{
                              textField: {
                                error: !!errors.startDate,
                                helperText: getStartDateError(),
                              },
                            }}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} lg={3} className="term-end-date-grid">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DesktopDatePicker"]}>
                      <Controller
                        control={control}
                        {...register("endDate", {
                          required: true,
                          validate: (value) =>
                            !checkEndDateLessThanStart(
                              watch("startDate"),
                              value
                            ),
                        })}
                        render={({ field }) => (
                          <DesktopDatePicker
                            {...field}
                            className="date-picker disabled-picker-text-field"
                            format={DATE_PICKER_FORMAT}
                            shouldDisableDate={generateDateDisabled()}
                            label={
                              <>
                                Term End Date{" "}
                                <span className="color-red">*</span>
                              </>
                            }
                            slots={{
                              openPickerIcon: CalendarIcon,
                            }}
                            slotProps={{
                              textField: {
                                error: !!errors.endDate,
                                helperText: errors.endDate?.message
                                  ? errors.endDate?.message?.toString()
                                  : getEndDateError(),
                              },
                            }}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6} className="term-status-grid">
                  <FormControl fullWidth className="select ">
                    <InputLabel
                      id="term-status-label"
                      shrink={watch("status") !== 0}
                    >
                      Term Status <span className="color-red">*</span>
                    </InputLabel>
                    <Select
                      labelId="term-status-label"
                      id="term-status"
                      label="Status"
                      value={watch("status")}
                      error={!!errors.status}
                      {...register("status", {
                        required: true,
                      })}
                    >
                      {TERM_STATUS?.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.status && (
                      <FormHelperText error>{getStatusError()}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Box className="modal-footer">
                <Button variant="contained" className="btn-save" type="submit">
                  <img src={saveIcon} alt="save" />
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="btn-cancel"
                  onClick={onModalClose}
                >
                  Cancel
                </Button>
              </Box>

              {isEditTerm && editTermData && (
                <Box className="terms-read-only">
                  <Grid
                    container
                    columnSpacing={{ sm: "16px", md: "24px" }}
                    rowSpacing="16px"
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Courses Delivered
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.coursesDelivered}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Avg. Number Enrolled Per Course
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.avgEnrolledPerCourse}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Avg. Attendance Per Course
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.avgAttendancePerCourse}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Total Student Course Enrolments
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.totalStudentCourseEnrolments}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        No. Of Course Completion
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.noOfCourseCompletion}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Courses with 15+ Students Enrolled
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.coursesWithMoreThan15Students}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Overall No. Of Attendance
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.overallNoOfAttendance}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Overall Students Numbers
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.overallStudentNumbers}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Dropout Rate
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.dropoutRate}%
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Courses Cancelled
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {editTermData?.coursesCancelled}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default AddEditTerm;
