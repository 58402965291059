import {
  DOCUMENT_LIST_REQUEST,
  DOCUMENT_LIST_SUCCESS,
  DOCUMENT_LIST_FAILURE,
  ADD_DOCUMENT_REQUEST,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAILURE,
  EDIT_DOCUMENT_REQUEST,
  EDIT_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_FAILURE,
  DOCUMENT_BY_ID_REQUEST,
  DOCUMENT_BY_ID_SUCCESS,
  DOCUMENT_BY_ID_FAILURE,
  DELETE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
} from "./actionTypes";
import {
  DocumentListPayload,
  DocumentListRequest,
  DocumentListSuccessPayload,
  DocumentListSuccess,
  DocumentListFailurePayload,
  DocumentListFailure,
  AddEditDocumentPayload,
  AddDocumentRequest,
  AddEditDocumentSuccessPayload,
  AddDocumentSuccess,
  AddEditDocumentFailurePayload,
  AddDocumentFailure,
  EditDocumentRequest,
  EditDocumentSuccess,
  EditDocumentFailure,
  DocumentByIdPayload,
  DocumentByIdRequest,
  DocumentByIdSuccessPayload,
  DocumentByIdSuccess,
  DocumentByIdFailurePayload,
  DocumentByIdFailure,
  DeleteDocumentFailurePayload,
  DeleteDocumentSuccessPayload,
  DeleteDocumentPayload,
  DeleteDocumentFailure,
  DeleteDocumentRequest,
  DeleteDocumentSuccess,
} from "./types";

export const documentListRequest = (
  payload: DocumentListPayload
): DocumentListRequest => ({
  type: DOCUMENT_LIST_REQUEST,
  payload,
});

export const documentListSuccess = (
  payload: DocumentListSuccessPayload
): DocumentListSuccess => ({
  type: DOCUMENT_LIST_SUCCESS,
  payload,
});

export const documentListFailure = (
  payload: DocumentListFailurePayload
): DocumentListFailure => ({
  type: DOCUMENT_LIST_FAILURE,
  payload,
});

export const addDocumentRequest = (
  payload: AddEditDocumentPayload
): AddDocumentRequest => ({
  type: ADD_DOCUMENT_REQUEST,
  payload,
});

export const addDocumentSuccess = (
  payload: AddEditDocumentSuccessPayload
): AddDocumentSuccess => ({
  type: ADD_DOCUMENT_SUCCESS,
  payload,
});

export const addDocumentFailure = (
  payload: AddEditDocumentFailurePayload
): AddDocumentFailure => ({
  type: ADD_DOCUMENT_FAILURE,
  payload,
});

export const editDocumentRequest = (
  payload: AddEditDocumentPayload
): EditDocumentRequest => ({
  type: EDIT_DOCUMENT_REQUEST,
  payload,
});

export const editDocumentSuccess = (
  payload: AddEditDocumentSuccessPayload
): EditDocumentSuccess => ({
  type: EDIT_DOCUMENT_SUCCESS,
  payload,
});

export const editDocumentFailure = (
  payload: AddEditDocumentFailurePayload
): EditDocumentFailure => ({
  type: EDIT_DOCUMENT_FAILURE,
  payload,
});

export const documentByIdRequest = (
  payload: DocumentByIdPayload
): DocumentByIdRequest => ({
  type: DOCUMENT_BY_ID_REQUEST,
  payload,
});

export const documentByIdSuccess = (
  payload: DocumentByIdSuccessPayload
): DocumentByIdSuccess => ({
  type: DOCUMENT_BY_ID_SUCCESS,
  payload,
});

export const documentByIdFailure = (
  payload: DocumentByIdFailurePayload
): DocumentByIdFailure => ({
  type: DOCUMENT_BY_ID_FAILURE,
  payload,
});

export const deleteDocumentRequest = (
  payload: DeleteDocumentPayload
): DeleteDocumentRequest => ({
  type: DELETE_DOCUMENT_REQUEST,
  payload,
});

export const deleteDocumentSuccess = (
  payload: DeleteDocumentSuccessPayload
): DeleteDocumentSuccess => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload,
});

export const deleteDocumentFailure = (
  payload: DeleteDocumentFailurePayload
): DeleteDocumentFailure => ({
  type: DELETE_DOCUMENT_FAILURE,
  payload,
});
