import { Card, Grid, Typography } from "@mui/material";
import {
  BLENDED_FACE_TO_FACE_ONLINE,
  DATE_VIEW_FORMAT,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import { IEducatorHomeScoDetails } from "utils/interfaces/educator-home";
import { convertToDateTime } from "utils/helper";

interface IScoDetailsSectionProps {
  scoDetails?: IEducatorHomeScoDetails;
}

const SCODetailsSection: React.FC<IScoDetailsSectionProps> = ({
  scoDetails,
}) => {
  return (
    <Card>
      <Typography variant="h3" className="sco-heading">
        Scheduled Course Offering Details
      </Typography>
      <Grid container spacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
        <Grid item xs={12}>
          <Typography variant="body1" className="sco-detail-title">
            Course
          </Typography>
          <Typography variant="body1" className="sco-detail">
            {scoDetails?.courseNo} {scoDetails?.courseName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className="sco-detail-title">
            Location
          </Typography>
          <Typography variant="body1" className="sco-detail">
            {scoDetails?.location}
            {scoDetails?.isBlended && BLENDED_FACE_TO_FACE_ONLINE}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className="sco-detail-title">
            Number Enrolled
          </Typography>
          <Typography variant="body1" className="sco-detail">
            {scoDetails?.noEnrolled}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className="sco-detail-title">
            Number Waitlisted
          </Typography>
          <Typography variant="body1" className="sco-detail">
            {scoDetails?.numberWaitlisted}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className="sco-detail-title">
            Date & Time Of First Session
          </Typography>
          <Typography variant="body1" className="sco-detail">
            {convertToDateTime(
              scoDetails?.startDateOfFirstSession,
              DATE_VIEW_FORMAT
            )}
            , {` `}
            {convertToDateTime(
              scoDetails?.startTimeOfFirstSession,
              TIME_VIEW_FORMAT
            )}{" "}
            -{` `}
            {convertToDateTime(
              scoDetails?.endTimeOfFirstSession,
              TIME_VIEW_FORMAT
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className="sco-detail-title">
            Sessions
          </Typography>
          <Typography variant="body1" className="sco-detail">
            {scoDetails?.noOfSessions}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className="sco-detail-title">
            Term
          </Typography>
          <Typography variant="body1" className="sco-detail">
            {scoDetails?.termName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" className="sco-detail-title">
            Status
          </Typography>
          <Typography variant="body1" className="sco-detail">
            {scoDetails?.status}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SCODetailsSection;
