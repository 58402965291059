import { connect, MapDispatchToProps } from "react-redux";
import StudentEnrolmentFormComponent, {
  StudentEnrolmentFormProps,
} from "pages/StudentEnrolmentForm";
import {
  addEnrolmentRequest,
  checkValidEnrolmentRequest,
  checkValidReCaptchaRequest,
  currentCourseListRequest,
  enrolmentOptionListRequest,
} from "store/studentEnrolment/actions";
import {
  IStudentEnrolmentFormContainerDispatch,
  IStudentEnrolmentForm,
} from "utils/interfaces/student-enrolment";

const mapDispatchToProps: MapDispatchToProps<
  IStudentEnrolmentFormContainerDispatch,
  IStudentEnrolmentForm
> = {
  enrolmentOptionListRequest,
  currentCourseListRequest,
  addEnrolmentRequest,
  checkValidEnrolmentRequest,
  checkValidReCaptchaRequest,
};

const connector = connect(null, mapDispatchToProps);

const StudentEnrolmentForm: React.FC<StudentEnrolmentFormProps> = (props) => {
  return <StudentEnrolmentFormComponent {...props} />;
};

export default connector(StudentEnrolmentForm);
