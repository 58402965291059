import {
  IDocument,
  IEducatorDocumentContainerDispatch,
} from "utils/interfaces/document";
import { MapDispatchToProps, connect } from "react-redux";
import {
  documentListRequest,
  documentByIdRequest,
} from "store/document/action";
import EducatorDocumentsComponent, {
  EducatorDocumentProps,
} from "pages/EducatorDocuments";

const mapDispatchToProps: MapDispatchToProps<
  IEducatorDocumentContainerDispatch,
  IDocument
> = {
  documentListRequest,
  documentByIdRequest,
};

const connector = connect(null, mapDispatchToProps);

const EducatorDocuments: React.FC<EducatorDocumentProps> = (props) => {
  return <EducatorDocumentsComponent {...props} />;
};

export default connector(EducatorDocuments);
