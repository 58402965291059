import {
  chevronDownIcon,
  coursesIcon,
  dashboardIcon,
  educatorhomeIcon,
  educatorsIcon,
  locationIcon,
  offeringIcon,
  reportsIcon,
  rolesIcon,
  studentDashboardIcon,
  studentEnrolIcon,
  studentIcon,
  termsIcon,
} from "assets/images";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { AppRoutings } from "utils/enums/app-routings";

const DesignerSidebar: React.FC = () => {
  const [openSupport, setOpenSupport] = React.useState(false);

  const handleSupportClick = () => {
    setOpenSupport(!openSupport);
  };

  return (
    <Box component="nav" className="sidebar">
      <Typography variant="h6" className="sidebar-item">
        Administrators
      </Typography>
      <List className="sidebar-menu">
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerDashboard}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={dashboardIcon} alt="Dashboard" />
            Dashboard
          </NavLink>
        </ListItemButton>
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerSco}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={offeringIcon} alt="Scheduled Offerings" />
            Scheduled Offerings
          </NavLink>
        </ListItemButton>
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerCourses}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={coursesIcon} alt="Courses" />
            Courses
          </NavLink>
        </ListItemButton>
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerLocation}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={locationIcon} alt="Locations" />
            Locations
          </NavLink>
        </ListItemButton>
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerTerms}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={termsIcon} alt="Terms" />
            Terms
          </NavLink>
        </ListItemButton>
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerEducators}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={educatorsIcon} alt="Educators" />
            Educators
          </NavLink>
        </ListItemButton>
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerRoles}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={rolesIcon} alt="Roles" />
            Roles
          </NavLink>
        </ListItemButton>
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerStudent}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={studentIcon} alt="Students" />
            Students
          </NavLink>
        </ListItemButton>
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerStudentEnrol}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={studentEnrolIcon} alt="Student Enrolments" />
            Student Enrolments
          </NavLink>
        </ListItemButton>
        <div className={openSupport ? "expanded container" : "container"}>
          <ListItemButton onClick={handleSupportClick}>
            <span className="nav-item">
              <img src={reportsIcon} alt="Reports" />
              Reports
              <img src={chevronDownIcon} alt="Arrow" className="arrow" />
            </span>
          </ListItemButton>
          {/* ------------- Submenu --------------- */}
          <Collapse in={openSupport} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                disableGutters
                component={NavLink}
                to={AppRoutings.DesignerReportsStudent}
                onClick={() => document.body.classList.remove("sidebar-toggle")}
              >
                <ListItemText
                  primary="Student"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
              <ListItemButton
                disableGutters
                component={NavLink}
                to={AppRoutings.DesignerReportStaff}
                onClick={() => document.body.classList.remove("sidebar-toggle")}
              >
                <ListItemText
                  primary="Staff"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
              <ListItemButton
                disableGutters
                component={NavLink}
                to={AppRoutings.DesignerReportEducator}
                onClick={() => document.body.classList.remove("sidebar-toggle")}
              >
                <ListItemText
                  primary="Educators"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
              <ListItemButton
                disableGutters
                component={NavLink}
                to={AppRoutings.DesignerStudentEnrol}
                onClick={() => document.body.classList.remove("sidebar-toggle")}
              >
                <ListItemText
                  primary="SLP"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </ListItemButton>
            </List>
          </Collapse>
        </div>
      </List>
      <Typography variant="h6" className="sidebar-item">
        Educator
      </Typography>
      <List className="sidebar-menu">
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerEducatorsHome}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={educatorhomeIcon} alt="Educators Home" />
            Educators Home
          </NavLink>
        </ListItemButton>
      </List>
      <Typography variant="h6" className="sidebar-item">
        Student
      </Typography>
      <List className="sidebar-menu">
        <ListItemButton>
          <NavLink
            to={AppRoutings.DesignerStudentDashboard}
            onClick={() => document.body.classList.remove("sidebar-toggle")}
          >
            <img src={studentDashboardIcon} alt="Dashboard" />
            Dashboard
          </NavLink>
        </ListItemButton>
      </List>
    </Box>
  );
};

export default DesignerSidebar;
