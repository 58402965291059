import { connect, MapDispatchToProps } from "react-redux";
import DashboardComponent, { DashboardProps } from "pages/Dashboard";
import { statisticsDataRequest } from "store/dashboard/actions";
import {
  IDashboardContainerDispatch,
  IDashboard,
} from "utils/interfaces/dashboard";

const mapDispatchToProps: MapDispatchToProps<
  IDashboardContainerDispatch,
  IDashboard
> = {
  statisticsDataRequest,
};

const connector = connect(null, mapDispatchToProps);

const Dashboard: React.FC<DashboardProps> = (props) => {
  return <DashboardComponent {...props} />;
};

export default connector(Dashboard);
