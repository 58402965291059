import {
  IAddEditEducatorRequest,
  IEducatorStatisticsPayloadValues,
} from "utils/interfaces/educator";
import baseService from "services/base-service";

const educatorBaseUrl = `/api/Educator`;

const getEducatorList = async (payload: {
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  name: string;
  educatorType?: number;
  status?: number;
  role?: number;
  term?: Array<number>;
}) => baseService.post(`${educatorBaseUrl}/GetEducators`, payload);

const addEducator = async (payload: IAddEditEducatorRequest) =>
  baseService.post(educatorBaseUrl, payload);

const editEducator = async (payload: IAddEditEducatorRequest) =>
  baseService.put(educatorBaseUrl, payload);

const deleteEducator = async (payload: { id: number }) =>
  baseService.delete(`${educatorBaseUrl}?id=${payload.id}`);

const getEducatorById = async (payload: { id: number }) =>
  baseService.get(`${educatorBaseUrl}?id=${payload.id}`);

const getCoursesDeliveredList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
}) =>
  baseService.post(
    `${educatorBaseUrl}/GetEducatorDeliveredCourses?id=${payload.id}`,
    payload
  );

const getEnroledCourseList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  course: string;
}) =>
  baseService.post(
    `${educatorBaseUrl}/GetEducatorEnroledCourses?id=${payload.id}`,
    payload
  );

const sendReminderToEducator = async (payload: { id: number }) =>
  baseService.post(
    `${educatorBaseUrl}/SendReminderToEducator?id=${payload.id}`
  );

const getEducatorStatisticsData = async (
  payload: IEducatorStatisticsPayloadValues
) => baseService.post(`${educatorBaseUrl}/GetEducatorStatistics`, payload);

export default {
  getEducatorList,
  addEducator,
  editEducator,
  deleteEducator,
  getEducatorById,
  getCoursesDeliveredList,
  getEnroledCourseList,
  sendReminderToEducator,
  getEducatorStatisticsData,
};
