import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  SCOBlendedCourse,
  SCOCertificate,
} from "utils/enums/scheduled-offering";
import { AxiosError } from "axios";
import { EnrolmentStatus } from "utils/enums/student-dashboard";
import {
  addScheduledOfferingFailure,
  addScheduledOfferingSuccess,
  addScoEducatorFailure,
  addScoEducatorSuccess,
  addSessionDayFailure,
  addSessionDaySuccess,
  cancelSessionDayFailure,
  cancelSessionDaySuccess,
  deleteDocumentFailure,
  deleteDocumentSuccess,
  deleteScheduledOfferingFailure,
  deleteScheduledOfferingSuccess,
  deleteScoEducatorFailure,
  deleteScoEducatorSuccess,
  deleteSessionDayFailure,
  deleteSessionDaySuccess,
  downloadDocumentFailure,
  downloadDocumentSuccess,
  editScheduledOfferingFailure,
  editScheduledOfferingSuccess,
  editSessionDayFailure,
  editSessionDaySuccess,
  enroledStudentListFailure,
  enroledStudentListSuccess,
  enrolmentCountFailure,
  enrolmentCountSuccess,
  fillGenerateCertificateFailure,
  fillGenerateCertificateSuccess,
  fillStudentAttendanceFailure,
  fillStudentAttendanceSuccess,
  getEducatorReflectionFormFailure,
  getEducatorReflectionFormSuccess,
  getScheduledOfferingByIdFailure,
  getScheduledOfferingByIdSuccess,
  printCourseEnrolmentsFailure,
  printCourseEnrolmentsSuccess,
  scheduledOfferingListFailure,
  scheduledOfferingListSuccess,
  scoDaysListFailure,
  scoDaysListSuccess,
  SCOGenerateCertificateFailure,
  SCOGenerateCertificateListSuccess,
  SCOStudentAttendanceFailure,
  SCOStudentAttendanceListSuccess,
  SCOStudentWhoAttendedFailure,
  SCOStudentWhoAttendedListSuccess,
  sessionListByScoIdFailure,
  sessionListByScoIdSuccess,
  uploadDocumentFailure,
  uploadDocumentSuccess,
} from "./actions";
import {
  SCHEDULED_OFFERING_LIST_REQUEST,
  ADD_SCHEDULED_OFFERING_REQUEST,
  EDIT_SCHEDULED_OFFERING_REQUEST,
  DELETE_SCHEDULED_OFFERING_REQUEST,
  GET_SCHEDULED_OFFERING_BY_ID_REQUEST,
  SCO_DAYS_LIST_REQUEST,
  SESSION_LIST_BY_SCO_ID_REQUEST,
  ADD_SESSION_DAY_REQUEST,
  EDIT_SESSION_DAY_REQUEST,
  DELETE_SESSION_DAY_REQUEST,
  DOWNLOAD_DOC_REQUEST,
  UPLOAD_DOC_REQUEST,
  DELETE_DOC_REQUEST,
  CANCEL_SESSION_DAY_REQUEST,
  ADD_SCO_EDUCATOR_REQUEST,
  DELETE_SCO_EDUCATOR_REQUEST,
  ENROLED_STUDENT_LIST_REQUEST,
  SCO_STUDENT_ATTENDANCE_LIST_REQUEST,
  STUDENT_FILL_ATTENDANCE_REQUEST,
  SCO_STUDENT_WHO_ATTENDED_LIST_REQUEST,
  ENROLED_COUNT_REQUEST,
  FILL_GENERATE_CERTIFICATE_REQUEST,
  SCO_GENERATE_CERTIFICATE_LIST_REQUEST,
  PRINT_COURSE_ENROLMENTS_REQUEST,
  GET_EDUCATOR_REFLECTION_FORM_REQUEST,
} from "./actionTypes";
import scheduledOfferingService from "./service";
import {
  AddEditScheduledOfferingResponse,
  AddEditSessionDayResponse,
  AddScoEducatorResponse,
  CancelSessionDayResponse,
  DeleteDocumentResponse,
  DeleteScheduledOfferingResponse,
  DeleteScoEducatorResponse,
  DeleteSessionDayResponse,
  DownloadDocumentResponse,
  EnroledStudentListResponse,
  EnrolmentCountResponse,
  FillGenerateCertificateResponse,
  FillStudentAttendanceResponse,
  GetEducatorReflectionFormRequest,
  GetEducatorReflectionFormResponse,
  GetScheduledOfferingByIdResponse,
  GetSCOGenerateCertificateListResponse,
  GetSCOStudentAttendanceListResponse,
  GetSCOStudentWhoAttendedListResponse,
  PrintCourseEnrolmentsResponse,
  ScheduledOfferingListResponse,
  ScoDaysListResponse,
  SessionListByScoIdResponse,
  UploadDocumentResponse,
} from "./types";

function* scheduledOfferingListSaga(action: any) {
  try {
    const payload: any = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      courseName: action.payload.values.courseName,
    };

    if (action.payload.values.courseNumber) {
      payload.courseNumber = action.payload.values.courseNumber;
    }
    if (action.payload.values.terms) {
      payload.term = action.payload.values.terms;
    }
    if (action.payload.values.locations) {
      payload.locations = action.payload.values.locations;
    }
    if (
      action.payload.values.certificateIssued === SCOCertificate.No ||
      action.payload.values.certificateIssued === SCOCertificate.Yes
    ) {
      payload.isCertificateIssued = Boolean(
        action.payload.values.certificateIssued
      );
    }
    if (
      action.payload.values.emrEntered === SCOCertificate.No ||
      action.payload.values.emrEntered === SCOCertificate.Yes
    ) {
      payload.isEMREntered = Boolean(action.payload.values.emrEntered);
    }
    if (action.payload.values.status) {
      payload.status = action.payload.values.status;
    }
    if (action.payload.values.year) {
      payload.year = action.payload.values.year;
    }
    if (
      action.payload.values.isBlendedCourse === SCOBlendedCourse.No ||
      action.payload.values.isBlendedCourse === SCOBlendedCourse.Yes
    ) {
      payload.isBlendedCourse = Boolean(action.payload.values.isBlendedCourse);
    }
    if (action.payload.values.isDashboard) {
      payload.isDashboard = action.payload.values.isDashboard;
    }
    const response: ScheduledOfferingListResponse = yield call(
      scheduledOfferingService.getScheduledOfferingList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      scheduledOfferingListSuccess({
        scheduledOfferingList: response.item.records,
        scheduledOfferingListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      scheduledOfferingList: response.item.records,
      scheduledOfferingListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      scheduledOfferingListFailure({
        error: e.message,
      })
    );
  }
}

function* addScheduledOfferingSaga(action: any) {
  try {
    const response: AddEditScheduledOfferingResponse = yield call(
      scheduledOfferingService.addScheduledOffering,
      {
        courseId: action.payload.values.courseId,
        locationId: action.payload.values.locationId,
        termId: action.payload.values.termId,
        educatorIds: action.payload.values.educatorIds,
        maxEnrollments: action.payload.values.maxEnrollments,
        certificatesIssued: action.payload.values.certificatesIssued,
        emrEntered: action.payload.values.emrEntered,
        blankEvaluationFormDoc: action.payload.values.blankEvaluationFormDoc,
        blankEvaluationForm: action.payload.values.blankEvaluationForm,
        startDateOfFirstSession: action.payload.values.startDateOfFirstSession,
        startTimeOfFirstSession: action.payload.values.startTimeOfFirstSession,
        endDateOfFirstSession: action.payload.values.endDateOfFirstSession,
        endTimeOfFirstSession: action.payload.values.endTimeOfFirstSession,
        isAllDay: action.payload.values.isAllDay,
        isRepeat: action.payload.values.isRepeat,
        courseOfferingRepeatSettings:
          action.payload.values.courseOfferingRepeatSettings,
        courseOfferingSessions: action.payload.values.courseOfferingSessions,
      }
    );

    yield put(
      addScheduledOfferingSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addScheduledOfferingFailure({
        error: e.message,
      })
    );
  }
}

function* editScheduledOfferingSaga(action: any) {
  try {
    const response: AddEditScheduledOfferingResponse = yield call(
      scheduledOfferingService.editScheduledOffering,
      {
        id: action.payload.values.id,
        courseId: action.payload.values.courseId,
        locationId: action.payload.values.locationId,
        termId: action.payload.values.termId,
        maxEnrollments: action.payload.values.maxEnrollments,
        isCertificateIssued: Boolean(action.payload.values.certificatesIssued),
        isEMREntered: Boolean(action.payload.values.emrEntered),
        startDate: action.payload.values.startDateOfFirstSession,
        startTime: action.payload.values.startTimeOfFirstSession,
        endDate: action.payload.values.endDateOfFirstSession,
        endTime: action.payload.values.endTimeOfFirstSession,
        isAllDay: action.payload.values.isAllDay,
        isRepeat: action.payload.values.isRepeat,
        isRepeatChange: action.payload.values.isRepeatChange,
        status: action.payload.values.status,
        courseOfferingRepeatSettings: JSON.stringify(
          action.payload.values.courseOfferingRepeatSettings
        ),
        courseOfferingSessions: JSON.stringify(
          action.payload.values.courseOfferingSessions
        ),
      }
    );

    yield put(
      editScheduledOfferingSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editScheduledOfferingFailure({
        error: e.message,
      })
    );
  }
}

function* deleteScheduledOfferingSaga(action: any) {
  try {
    const response: DeleteScheduledOfferingResponse = yield call(
      scheduledOfferingService.deleteScheduledOffering,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      deleteScheduledOfferingSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteScheduledOfferingFailure({
        error: e.message,
      })
    );
  }
}

function* scoDaysListSaga(action: any) {
  try {
    const payload: any = {
      startDateOfFirstSession: action.payload.values.startDateOfFirstSession,
      repeatType: action.payload.values.repeatType,
      occurenceStart: action.payload.values.occurenceStart,
      repeatDays: action.payload.values.repeatDays,
      endType: action.payload.values.endType,
      occurenceEnd: action.payload.values.occurenceEnd,
      endOn: action.payload.values.endOn,
    };

    const response: ScoDaysListResponse = yield call(
      scheduledOfferingService.getScoDaysList,
      payload
    );

    yield put(
      scoDaysListSuccess({
        scoDaysList: response.item,
      })
    );
    action.payload.callback({
      scoDaysList: response.item,
    });
  } catch (e: any) {
    yield put(
      scoDaysListFailure({
        error: e.message,
      })
    );
  }
}

function* getScheduledOfferingByIdSaga(action: any) {
  try {
    const response: GetScheduledOfferingByIdResponse = yield call(
      scheduledOfferingService.getScheduledOfferingById,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      getScheduledOfferingByIdSuccess({
        scheduledOfferingById: response.item,
      })
    );
    action.payload.callback({
      scheduledOfferingById: response.item,
    });
  } catch (e: any) {
    yield put(
      getScheduledOfferingByIdFailure({
        error: e.message,
      })
    );
  }
}

function* sessionListByScoIdSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
    };

    const response: SessionListByScoIdResponse = yield call(
      scheduledOfferingService.getSessionListByScoId,
      payload
    );

    yield put(
      sessionListByScoIdSuccess({
        sessionListByScoId: response.item,
      })
    );
    action.payload.callback({
      sessionListByScoId: response.item,
    });
  } catch (e: any) {
    yield put(
      sessionListByScoIdFailure({
        error: e.message,
      })
    );
  }
}

function* addSessionDaySaga(action: any) {
  try {
    const response: AddEditSessionDayResponse = yield call(
      scheduledOfferingService.addSessionDay,
      {
        dayNumber: action.payload.values.dayNumber,
        sessionStartDateTime: action.payload.values.sessionStartDateTime,
        sessionEndDateTime: action.payload.values.sessionEndDateTime,
        courseOfferingID: action.payload.values.courseOfferingID,
      }
    );

    yield put(
      addSessionDaySuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addSessionDayFailure({
        error: e.message,
      })
    );
  }
}

function* editSessionDaySaga(action: any) {
  try {
    const response: AddEditSessionDayResponse = yield call(
      scheduledOfferingService.editSessionDay,
      {
        id: action.payload.values.id,
        dayNumber: action.payload.values.dayNumber,
        sessionStartDateTime: action.payload.values.sessionStartDateTime,
        sessionEndDateTime: action.payload.values.sessionEndDateTime,
        courseOfferingID: action.payload.values.courseOfferingID,
      }
    );

    yield put(
      editSessionDaySuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editSessionDayFailure({
        error: e.message,
      })
    );
  }
}

function* deleteSessionDaySaga(action: any) {
  try {
    const response: DeleteSessionDayResponse = yield call(
      scheduledOfferingService.deleteSessionDay,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      deleteSessionDaySuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteSessionDayFailure({
        error: e.message,
      })
    );
  }
}

function* downloadDocumentSaga(action: any) {
  try {
    const payload: any = {
      name: action.payload.values.name,
      type: action.payload.values.type,
      courseOfferingID: action.payload.values.courseOfferingID,
    };

    const response: DownloadDocumentResponse = yield call(
      scheduledOfferingService.downloadDocument,
      payload
    );

    yield put(
      downloadDocumentSuccess({
        document: response.item,
        documentName: action.payload.values.name,
      })
    );
    action.payload.callback({
      document: response.item,
      documentName: action.payload.values.name,
    });
  } catch (e: any) {
    yield put(
      downloadDocumentFailure({
        error: e.message,
      })
    );
  }
}

function* uploadDocumentSaga(action: any) {
  try {
    const payload: any = {
      name: action.payload.values.name,
      type: action.payload.values.type,
      courseOfferingID: action.payload.values.courseOfferingID,
      document: action.payload.values.document,
    };

    const response: UploadDocumentResponse = yield call(
      scheduledOfferingService.uploadDocument,
      payload
    );

    yield put(
      uploadDocumentSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      uploadDocumentFailure({
        error: e.message,
      })
    );
  }
}

function* deleteDocumentSaga(action: any) {
  try {
    const response: DeleteDocumentResponse = yield call(
      scheduledOfferingService.deleteDocument,
      {
        id: action.payload.values.id,
        type: action.payload.values.type,
        courseOfferingID: action.payload.values.courseOfferingID,
      }
    );

    yield put(
      deleteDocumentSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteDocumentFailure({
        error: e.message,
      })
    );
  }
}

function* cancelSessionDaySaga(action: any) {
  try {
    const response: CancelSessionDayResponse = yield call(
      scheduledOfferingService.cancelSessionDay,
      {
        id: action.payload.values.id,
        cancellationComment: action.payload.values.cancellationComment,
      }
    );
    yield put(
      cancelSessionDaySuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      cancelSessionDayFailure({
        error: e.message,
      })
    );
  }
}

function* addScoEducatorSaga(action: any) {
  try {
    const response: AddScoEducatorResponse = yield call(
      scheduledOfferingService.addScoEducator,
      {
        courseOfferingId: action.payload.values.courseOfferingId,
        educatorIds: action.payload.values.educatorIds,
      }
    );

    yield put(
      addScoEducatorSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addScoEducatorFailure({
        error: e.message,
      })
    );
  }
}

function* deleteScoEducatorSaga(action: any) {
  try {
    const response: DeleteScoEducatorResponse = yield call(
      scheduledOfferingService.deleteScoEducator,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      deleteScoEducatorSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteScoEducatorFailure({
        error: e.message,
      })
    );
  }
}

function* enroledStudentListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };

    if (action.payload.values.name) {
      payload.name = action.payload.values.name;
    }

    if (action.payload.values.mrnNo) {
      payload.mrnNo = action.payload.values.mrnNo;
    }

    if (action.payload.values.type) {
      payload.type = action.payload.values.type;
    }

    if (
      action.payload.values.enrolmentStatus ||
      action.payload.values.enrolmentStatus === EnrolmentStatus.Pending
    ) {
      payload.enrolmentStatus = action.payload.values.enrolmentStatus;
    }
    const response: EnroledStudentListResponse = yield call(
      scheduledOfferingService.getEnroledStudentList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      enroledStudentListSuccess({
        enroledStudentList: response.item.records,
        enroledStudentListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      enroledStudentList: response.item.records,
      enroledStudentListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      enroledStudentListFailure({
        error: e.message,
      })
    );
  }
}

function* getSCOStudentAttendanceListSaga(action: any) {
  try {
    const response: GetSCOStudentAttendanceListResponse = yield call(
      scheduledOfferingService.getSCOStudentAttendanceList,
      {
        courseOfferingId: action.payload.values.courseOfferingId,
        sessionId: action.payload.values.sessionId,
      }
    );
    yield put(
      SCOStudentAttendanceListSuccess({
        studentAttendanceList: response.item,
      })
    );
    action.payload.callback({
      studentAttendanceList: response.item,
    });
  } catch (e: any) {
    yield put(
      SCOStudentAttendanceFailure({
        error: e.message,
      })
    );
  }
}

function* fillStudentAttendanceSaga(action: any) {
  try {
    const response: FillStudentAttendanceResponse = yield call(
      scheduledOfferingService.fillStudentAttendance,
      {
        sessionId: action.payload.values.sessionId,
        courseOfferingId: action.payload.values.courseOfferingId,
        attendanceLists: action.payload.values.attendanceLists,
      }
    );

    yield put(
      fillStudentAttendanceSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      fillStudentAttendanceFailure({
        error: e.message,
      })
    );
  }
}

function* getSCOStudentWhoAttendedListSaga(action: any) {
  try {
    const response: GetSCOStudentWhoAttendedListResponse = yield call(
      scheduledOfferingService.getSCOStudentWhoAttendedList,
      {
        courseOfferingId: action.payload.values.stdWhoAttendedCourseOfferingId,
        sessionId: action.payload.values.stdWhoAttendedSessionId,
      }
    );
    yield put(
      SCOStudentWhoAttendedListSuccess({
        studentWhoAttendedList: response.item,
      })
    );
    action.payload.callback({
      studentWhoAttendedList: response.item,
    });
  } catch (e: any) {
    yield put(
      SCOStudentWhoAttendedFailure({
        error: e.message,
      })
    );
  }
}

function* enrolmentCountSaga(action: any) {
  try {
    const response: EnrolmentCountResponse = yield call(
      scheduledOfferingService.getEnrolmentCount,
      {
        id: action.payload.values.id,
      }
    );
    yield put(
      enrolmentCountSuccess({
        attendingCount: response.item.attendingCount,
        enrolledCount: response.item.enrolledCount,
        withdrawnCount: response.item.withdrawnCount,
        isPrint: response.item.isPrint,
        isBlended: response.item.isBlended,
      })
    );
    action.payload.callback({
      attendingCount: response.item.attendingCount,
      enrolledCount: response.item.enrolledCount,
      withdrawnCount: response.item.withdrawnCount,
      isPrint: response.item.isPrint,
      isBlended: response.item.isBlended,
    });
  } catch (e: any) {
    yield put(
      enrolmentCountFailure({
        error: e.message,
      })
    );
  }
}
function* getSCOGenerateCertificateListSaga(action: any) {
  try {
    const response: GetSCOGenerateCertificateListResponse = yield call(
      scheduledOfferingService.getSCOGenerateCertificateList,
      {
        courseOfferingId: action.payload.values.courseOfferingId,
      }
    );
    yield put(
      SCOGenerateCertificateListSuccess({
        studentGenerateCertificateList: response.item,
      })
    );
    action.payload.callback({
      studentGenerateCertificateList: response.item,
    });
  } catch (e: any) {
    yield put(
      SCOGenerateCertificateFailure({
        error: e.message,
      })
    );
  }
}

function* fillGenerateCertificateSaga(action: any) {
  try {
    const response: FillGenerateCertificateResponse = yield call(
      scheduledOfferingService.fillGenerateCertificate,
      {
        courseOfferingId: action.payload.values.courseOfferingId,
        certificateStatusList: action.payload.values.certificateStatusList,
      }
    );

    yield put(
      fillGenerateCertificateSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      fillGenerateCertificateFailure({
        error: e.message,
      })
    );
  }
}

function* PrintCourseEnrolmentsSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };
    if (action.payload.values.name) {
      payload.name = action.payload.values.name;
    }

    if (action.payload.values.mrnNo) {
      payload.mrnNo = action.payload.values.mrnNo;
    }
    if (action.payload.values.IsEducatorHomeEnrolment) {
      payload.IsEducatorHomeEnrolment =
        action.payload.values.IsEducatorHomeEnrolment;
    }

    if (action.payload.values.type) {
      payload.type = action.payload.values.type;
    }

    if (
      action.payload.values.enrolmentStatus ||
      action.payload.values.enrolmentStatus === EnrolmentStatus.Pending
    ) {
      payload.enrolmentStatus = action.payload.values.enrolmentStatus;
    }

    const response: PrintCourseEnrolmentsResponse = yield call(
      scheduledOfferingService.printCourseEnrolments,
      payload
    );

    yield put(
      printCourseEnrolmentsSuccess({
        item: response.item,
      })
    );
    action.payload.callback({
      item: response.item,
    });
  } catch (e: any) {
    yield put(
      printCourseEnrolmentsFailure({
        error: e.message,
      })
    );
  }
}

function* GetEducatorReflectionFormSaga(
  action: GetEducatorReflectionFormRequest
) {
  try {
    const response: GetEducatorReflectionFormResponse = yield call(
      scheduledOfferingService.getEducatorReflectionForm,
      {
        educatorId: action.payload.values.educatorId,
        courseOfferingId: action.payload.values.courseOfferingId,
      }
    );

    yield put(
      getEducatorReflectionFormSuccess({
        formDetails: response.item,
      })
    );
    action.payload.callback({
      formDetails: response.item,
    });
  } catch (e) {
    yield put(
      getEducatorReflectionFormFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* scheduledOfferingSaga() {
  yield all([
    takeLatest(SCHEDULED_OFFERING_LIST_REQUEST, scheduledOfferingListSaga),
  ]);
  yield all([
    takeLatest(ADD_SCHEDULED_OFFERING_REQUEST, addScheduledOfferingSaga),
  ]);
  yield all([
    takeLatest(EDIT_SCHEDULED_OFFERING_REQUEST, editScheduledOfferingSaga),
  ]);
  yield all([
    takeLatest(DELETE_SCHEDULED_OFFERING_REQUEST, deleteScheduledOfferingSaga),
  ]);
  yield all([takeLatest(SCO_DAYS_LIST_REQUEST, scoDaysListSaga)]);
  yield all([
    takeLatest(
      GET_SCHEDULED_OFFERING_BY_ID_REQUEST,
      getScheduledOfferingByIdSaga
    ),
  ]);
  yield all([
    takeLatest(SESSION_LIST_BY_SCO_ID_REQUEST, sessionListByScoIdSaga),
  ]);
  yield all([takeLatest(ADD_SESSION_DAY_REQUEST, addSessionDaySaga)]);
  yield all([takeLatest(EDIT_SESSION_DAY_REQUEST, editSessionDaySaga)]);
  yield all([takeLatest(DELETE_SESSION_DAY_REQUEST, deleteSessionDaySaga)]);
  yield all([takeLatest(DOWNLOAD_DOC_REQUEST, downloadDocumentSaga)]);
  yield all([takeLatest(UPLOAD_DOC_REQUEST, uploadDocumentSaga)]);
  yield all([takeLatest(DELETE_DOC_REQUEST, deleteDocumentSaga)]);
  yield all([takeLatest(CANCEL_SESSION_DAY_REQUEST, cancelSessionDaySaga)]);
  yield all([takeLatest(ADD_SCO_EDUCATOR_REQUEST, addScoEducatorSaga)]);
  yield all([takeLatest(DELETE_SCO_EDUCATOR_REQUEST, deleteScoEducatorSaga)]);
  yield all([takeLatest(ENROLED_STUDENT_LIST_REQUEST, enroledStudentListSaga)]);
  yield all([
    takeLatest(
      SCO_STUDENT_ATTENDANCE_LIST_REQUEST,
      getSCOStudentAttendanceListSaga
    ),
  ]);
  yield all([
    takeLatest(STUDENT_FILL_ATTENDANCE_REQUEST, fillStudentAttendanceSaga),
  ]);
  yield all([
    takeLatest(
      SCO_STUDENT_WHO_ATTENDED_LIST_REQUEST,
      getSCOStudentWhoAttendedListSaga
    ),
  ]);
  yield all([takeLatest(ENROLED_COUNT_REQUEST, enrolmentCountSaga)]);
  yield all([
    takeLatest(
      SCO_GENERATE_CERTIFICATE_LIST_REQUEST,
      getSCOGenerateCertificateListSaga
    ),
  ]);
  yield all([
    takeLatest(FILL_GENERATE_CERTIFICATE_REQUEST, fillGenerateCertificateSaga),
  ]);
  yield all([
    takeLatest(PRINT_COURSE_ENROLMENTS_REQUEST, PrintCourseEnrolmentsSaga),
  ]);
  yield all([
    takeLatest(
      GET_EDUCATOR_REFLECTION_FORM_REQUEST,
      GetEducatorReflectionFormSaga
    ),
  ]);
}

export default scheduledOfferingSaga;
