import { pdfIcon, saveIcon, uploadIcon } from "assets/images";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { AppPages } from "utils/enums/app-pages";
import {
  base64ToArrayBuffer,
  convertToDateTime,
  getPagePermissions,
  getScoDocAcceptedTypes,
  getUploadedScoDocumentType,
  showLoader,
} from "utils/helper";
import {
  EditMediaConsentSuccessPayload,
  MediaConsentPayloadSuccessPayload,
} from "store/student/types";
import { IMediaConsentDetails } from "utils/interfaces/student";
import { Controller, useForm } from "react-hook-form";
import {
  DATE_PICKER_FORMAT,
  MAX_SIZE_IN_BYTES,
  SCO_DOC_SUPPORTED_TYPES,
} from "utils/constants/constant";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import { toast } from "react-toastify";
import {
  FILE_SIZE_EXCEEDED,
  MEDIA_CONSENT_EDIT_SUCCESS_MESSAGE,
  UPLOAD_PDF_OR_WORD_FILE,
} from "utils/constants/Messages";
import CalendarIcon from "components/CalendarIcon";
import { ISMEDIACONSENT } from "utils/constants/student";
import RadioCheckBoxIcon from "components/RadioCheckBoxIcon";
import RadioCheckedBoxIcon from "components/RadioCheckedBoxIcon";

interface IMediaConsentProps {
  mediaConsentRequest: any;
  editMediaConsentRequest: any;
  enrolmentOptionList: IEnrolmentOptions | undefined;
}

const MediaConsent: React.FC<IMediaConsentProps> = ({
  mediaConsentRequest,
  editMediaConsentRequest,
  enrolmentOptionList,
}) => {
  const location = useLocation<{ studentId: number; isDeleted?: boolean }>();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const [mediaConsentData, setMediaConsentData] = useState<any>(null);
  const [firstTimeFileName, setFirstTimeFileName] = useState<string>("");
  const [firstTimeFile, setFirstTimeFile] = useState<any>("");
  const [showApiFilePreview, setShowApiFilePreview] = useState<boolean>(false);
  const [isFileError, setIsFileError] = useState<boolean>(false);
  const [isNullFile, setIsNullFile] = useState<boolean>(false);

  const getFormattedDate = (date: any) => {
    const stringDate = new Date(date?.toString());
    const formattedDate = convertToDateTime(stringDate);

    return formattedDate;
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<IMediaConsentDetails>({
    defaultValues: { isMediaConsent: true, mediaConsentedTo: "Newsletter" },
  });

  const getMediaConsentToError = (): string => {
    if (errors.mediaConsentedTo) {
      if (errors.mediaConsentedTo.type === "required") {
        return "Media Consented To is required";
      }
    }

    return "";
  };

  const getConsentDateError = (): string => {
    if (errors.consentDate) {
      if (errors.consentDate.type === "required") {
        return "Media Consented To is required";
      }
    }

    return "";
  };

  const handleFileUploadButtonClick = async (event: React.ChangeEvent<any>) => {
    if (event.target.files.length > 0) {
      const { files } = event.target;
      const file = files[0];

      if (!SCO_DOC_SUPPORTED_TYPES.includes(file?.type)) {
        toast.error(UPLOAD_PDF_OR_WORD_FILE);

        return;
      }

      if (file?.size > MAX_SIZE_IN_BYTES) {
        toast.error(FILE_SIZE_EXCEEDED);

        return;
      }

      setIsFileError(false);
      setIsNullFile(false);
      setFirstTimeFile(file);
      setFirstTimeFileName(file?.name);
    }
  };

  const setMediaConsentFormValues = (mediaConsent: any) => {
    setValue("consentDate", dayjs(mediaConsent.consentDate));
    setValue("file", mediaConsent.file);
    setValue("isMediaConsent", mediaConsent.isMediaConsent);
    setValue("mediaConsentedTo", mediaConsent.mediaConsentedTo);
  };

  const onMediaConsentSuccess = (
    response: MediaConsentPayloadSuccessPayload
  ) => {
    if (response.MediaConsent) {
      setMediaConsentData(response.MediaConsent);
      setShowApiFilePreview(true);
      setMediaConsentFormValues(response.MediaConsent);
    } else {
      setIsNullFile(true);
    }

    setFirstTimeFile("");
    setFirstTimeFileName("");
    setMediaConsentFormValues(response.MediaConsent);
  };

  const getMediaConsentData = () => {
    if (mediaConsentRequest) {
      showLoader();
      const { studentId } = location.state;

      const payload = {
        values: { id: studentId },
        callback: onMediaConsentSuccess,
      };

      mediaConsentRequest(payload);
    }
  };

  const onApiPreviewClick = () => {
    const item = base64ToArrayBuffer(mediaConsentData.file);
    const docType = getUploadedScoDocumentType(mediaConsentData.fileName);
    const file = new Blob([item], {
      type: docType,
    });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  const onPreviewClick = () => {
    const filePath = URL.createObjectURL(firstTimeFile);
    window.open(filePath, "_blank");
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Student);
    if (location?.state.studentId && permission.edit) {
      getMediaConsentData();
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelClick = () => {
    setIsFileError(false);
    getMediaConsentData();
    reset({
      consentDate: mediaConsentData?.consentDate
        ? dayjs(mediaConsentData?.consentDate)
        : null,
      isMediaConsent: mediaConsentData?.isMediaConsent
        ? mediaConsentData?.isMediaConsent
        : true,
      mediaConsentedTo: mediaConsentData?.mediaConsentedTo
        ? mediaConsentData?.mediaConsentedTo
        : "Newsletter",
    });
  };

  const checkFileValiaton = () => {
    setIsFileError(true);
  };

  const onEditMediaConsentSuccess = (
    response: EditMediaConsentSuccessPayload
  ) => {
    if (response.success) {
      toast.success(MEDIA_CONSENT_EDIT_SUCCESS_MESSAGE);
      getMediaConsentData();
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const onSubmit = async (data: IMediaConsentDetails) => {
    if (!isNullFile) {
      if (
        location.state.studentId &&
        editMediaConsentRequest &&
        !location.state.isDeleted
      ) {
        let file;

        if (data.file && firstTimeFile === "") {
          const item = base64ToArrayBuffer(data.file);
          const docType = getUploadedScoDocumentType(mediaConsentData.fileName);
          file = new Blob([item], {
            type: docType,
          });
        }

        const fileCheck = file === undefined;

        showLoader();
        const payload = {
          values: {
            studentId: location.state.studentId,
            isMediaConsent: data.isMediaConsent,
            mediaConsentedTo: data.mediaConsentedTo,
            consentDate: getFormattedDate(data.consentDate),
            fileName: fileCheck ? firstTimeFileName : mediaConsentData.fileName,
            document: fileCheck ? firstTimeFile : file,
          },
          callback: onEditMediaConsentSuccess,
        };
        editMediaConsentRequest(payload);
      }
    } else {
      checkFileValiaton();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="std-details-card">
        <Typography variant="h3" className="attnd-heading">
          Media Consent
        </Typography>
        <Box className="media-upload">
          <Typography variant="h5" sx={{ display: "flex" }}>
            Media Consent Scan<span className="color-red">*</span>
          </Typography>
          <Box className="upload">
            <Button
              variant="contained"
              color="secondary"
              component="label"
              className="btn-upload"
            >
              <img src={uploadIcon} alt="cloud" />
              Choose File
              <input
                hidden
                accept={getScoDocAcceptedTypes()}
                type="file"
                onChange={(e: React.ChangeEvent<any>) =>
                  handleFileUploadButtonClick(e)
                }
              />
            </Button>

            {firstTimeFileName !== "" ? (
              <>
                <Typography variant="body1">{firstTimeFileName}</Typography>
                <IconButton className="upload-icon" onClick={onPreviewClick}>
                  <img src={pdfIcon} alt="pdf" />
                </IconButton>
              </>
            ) : (
              <>
                {!showApiFilePreview ? (
                  <Typography variant="body1">No Selected Files</Typography>
                ) : (
                  <>
                    <Typography variant="body1">
                      {mediaConsentData?.fileName}
                    </Typography>
                    <IconButton
                      className="upload-icon"
                      onClick={onApiPreviewClick}
                    >
                      <img src={pdfIcon} alt="pdf" />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </Box>
          {isFileError && (
            <FormHelperText error>please select a file</FormHelperText>
          )}
        </Box>
        <Grid
          container
          className="media-consent"
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        >
          <Grid item xs={12} lg={4}>
            <Typography variant="h5" className="confirmation">
              Media Consent?
            </Typography>
            <Controller
              control={control}
              {...register("isMediaConsent", {
                required: true,
              })}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  {ISMEDIACONSENT.map((option) => (
                    <FormControlLabel
                      value={option.value}
                      control={
                        <Radio
                          icon={<RadioCheckBoxIcon />}
                          checkedIcon={<RadioCheckedBoxIcon />}
                        />
                      }
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <Controller
                  control={control}
                  {...register("consentDate", {
                    required: true,
                  })}
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      className="date-picker"
                      disableFuture
                      format={DATE_PICKER_FORMAT}
                      label={
                        <>
                          Media Consent Date
                          <span className="color-red">*</span>
                        </>
                      }
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.consentDate,
                          helperText: getConsentDateError(),
                        },
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="MediaConTo-label"
                shrink={!!watch("mediaConsentedTo")}
              >
                Media Consented to <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="MediaConTo-label"
                id="MediaConTo"
                label={
                  <>
                    Media Consented to
                    <span className="color-red">*</span>
                  </>
                }
                value={watch("mediaConsentedTo")}
                error={!!errors.mediaConsentedTo}
                {...register("mediaConsentedTo", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.mediaConsentedTo?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.mediaConsentedTo && (
                <FormHelperText error>
                  {getMediaConsentToError()}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Box className="btn-group">
          <Button
            disabled={location.state.isDeleted}
            variant="contained"
            className="btn-save"
            type="submit"
          >
            <img src={saveIcon} alt="save" />
            Save
          </Button>
          <Button
            variant="outlined"
            className="btn-cancel"
            disabled={location.state.isDeleted}
            onClick={() =>
              location.state.isDeleted ? null : handleCancelClick()
            }
            type="button"
          >
            Cancel
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default MediaConsent;
