import {
  SCHEDULED_OFFERING_LIST_REQUEST,
  SCHEDULED_OFFERING_LIST_FAILURE,
  SCHEDULED_OFFERING_LIST_SUCCESS,
  ADD_SCHEDULED_OFFERING_REQUEST,
  ADD_SCHEDULED_OFFERING_SUCCESS,
  ADD_SCHEDULED_OFFERING_FAILURE,
  EDIT_SCHEDULED_OFFERING_REQUEST,
  EDIT_SCHEDULED_OFFERING_SUCCESS,
  EDIT_SCHEDULED_OFFERING_FAILURE,
  DELETE_SCHEDULED_OFFERING_REQUEST,
  DELETE_SCHEDULED_OFFERING_SUCCESS,
  DELETE_SCHEDULED_OFFERING_FAILURE,
  GET_SCHEDULED_OFFERING_BY_ID_REQUEST,
  GET_SCHEDULED_OFFERING_BY_ID_SUCCESS,
  GET_SCHEDULED_OFFERING_BY_ID_FAILURE,
  SCO_DAYS_LIST_REQUEST,
  SCO_DAYS_LIST_SUCCESS,
  SCO_DAYS_LIST_FAILURE,
  SESSION_LIST_BY_SCO_ID_REQUEST,
  SESSION_LIST_BY_SCO_ID_SUCCESS,
  SESSION_LIST_BY_SCO_ID_FAILURE,
  ADD_SESSION_DAY_REQUEST,
  ADD_SESSION_DAY_SUCCESS,
  ADD_SESSION_DAY_FAILURE,
  EDIT_SESSION_DAY_REQUEST,
  EDIT_SESSION_DAY_SUCCESS,
  EDIT_SESSION_DAY_FAILURE,
  DELETE_SESSION_DAY_REQUEST,
  DELETE_SESSION_DAY_SUCCESS,
  DELETE_SESSION_DAY_FAILURE,
  DOWNLOAD_DOC_REQUEST,
  DOWNLOAD_DOC_SUCCESS,
  DOWNLOAD_DOC_FAILURE,
  UPLOAD_DOC_REQUEST,
  UPLOAD_DOC_SUCCESS,
  UPLOAD_DOC_FAILURE,
  DELETE_DOC_REQUEST,
  DELETE_DOC_SUCCESS,
  DELETE_DOC_FAILURE,
  CANCEL_SESSION_DAY_REQUEST,
  CANCEL_SESSION_DAY_SUCCESS,
  CANCEL_SESSION_DAY_FAILURE,
  DELETE_SCO_EDUCATOR_REQUEST,
  DELETE_SCO_EDUCATOR_SUCCESS,
  DELETE_SCO_EDUCATOR_FAILURE,
  ADD_SCO_EDUCATOR_REQUEST,
  ADD_SCO_EDUCATOR_SUCCESS,
  ADD_SCO_EDUCATOR_FAILURE,
  ENROLED_STUDENT_LIST_FAILURE,
  ENROLED_STUDENT_LIST_REQUEST,
  ENROLED_STUDENT_LIST_SUCCESS,
  SCO_STUDENT_ATTENDANCE_LIST_FAILURE,
  SCO_STUDENT_ATTENDANCE_LIST_SUCCESS,
  SCO_STUDENT_ATTENDANCE_LIST_REQUEST,
  STUDENT_FILL_ATTENDANCE_REQUEST,
  STUDENT_FILL_ATTENDANCE_SUCCESS,
  STUDENT_FILL_ATTENDANCE_FAILURE,
  SCO_STUDENT_WHO_ATTENDED_LIST_FAILURE,
  SCO_STUDENT_WHO_ATTENDED_LIST_SUCCESS,
  SCO_STUDENT_WHO_ATTENDED_LIST_REQUEST,
  ENROLED_COUNT_REQUEST,
  ENROLED_COUNT_SUCCESS,
  ENROLED_COUNT_FAILURE,
  FILL_GENERATE_CERTIFICATE_SUCCESS,
  FILL_GENERATE_CERTIFICATE_REQUEST,
  FILL_GENERATE_CERTIFICATE_FAILURE,
  SCO_GENERATE_CERTIFICATE_LIST_SUCCESS,
  SCO_GENERATE_CERTIFICATE_LIST_FAILURE,
  SCO_GENERATE_CERTIFICATE_LIST_REQUEST,
  SAVE_ATTENDANCE_REQUEST,
  PRINT_COURSE_ENROLMENTS_FAILURE,
  PRINT_COURSE_ENROLMENTS_REQUEST,
  PRINT_COURSE_ENROLMENTS_SUCCESS,
  SAVE_GENERATE_CERTIFICATE_REQUEST,
  GET_EDUCATOR_REFLECTION_FORM_FAILURE,
  GET_EDUCATOR_REFLECTION_FORM_REQUEST,
  GET_EDUCATOR_REFLECTION_FORM_SUCCESS,
} from "./actionTypes";
import {
  ScheduledOfferingListPayload,
  ScheduledOfferingListRequest,
  ScheduledOfferingListSuccess,
  ScheduledOfferingListSuccessPayload,
  ScheduledOfferingListFailure,
  ScheduledOfferingListFailurePayload,
  DeleteScheduledOfferingPayload,
  DeleteScheduledOfferingRequest,
  DeleteScheduledOfferingSuccessPayload,
  DeleteScheduledOfferingSuccess,
  DeleteScheduledOfferingFailurePayload,
  DeleteScheduledOfferingFailure,
  AddEditScheduledOfferingPayload,
  AddScheduledOfferingRequest,
  AddEditScheduledOfferingSuccessPayload,
  AddScheduledOfferingSuccess,
  AddEditScheduledOfferingFailurePayload,
  AddScheduledOfferingFailure,
  EditScheduledOfferingSuccess,
  EditScheduledOfferingRequest,
  EditScheduledOfferingFailure,
  GetScheduledOfferingByIdPayload,
  GetScheduledOfferingByIdRequest,
  GetScheduledOfferingByIdSuccessPayload,
  GetScheduledOfferingByIdSuccess,
  GetScheduledOfferingByIdFailurePayload,
  GetScheduledOfferingByIdFailure,
  ScoDaysListPayload,
  ScoDaysListRequest,
  ScoDaysListSuccessPayload,
  ScoDaysListSuccess,
  ScoDaysListFailurePayload,
  ScoDaysListFailure,
  SessionListByScoIdPayload,
  SessionListByScoIdRequest,
  SessionListByScoIdSuccessPayload,
  SessionListByScoIdSuccess,
  SessionListByScoIdFailurePayload,
  SessionListByScoIdFailure,
  AddEditSessionDayPayload,
  AddSessionDayRequest,
  AddEditSessionDaySuccessPayload,
  AddSessionDaySuccess,
  AddEditSessionDayFailurePayload,
  AddSessionDayFailure,
  EditSessionDayRequest,
  EditSessionDaySuccess,
  EditSessionDayFailure,
  DeleteSessionDayPayload,
  DeleteSessionDayRequest,
  DeleteSessionDaySuccessPayload,
  DeleteSessionDaySuccess,
  DeleteSessionDayFailurePayload,
  DeleteSessionDayFailure,
  DownloadDocumentPayload,
  DownloadDocumentRequest,
  DownloadDocumentSuccessPayload,
  DownloadDocumentSuccess,
  DownloadDocumentFailurePayload,
  DownloadDocumentFailure,
  UploadDocumentPayload,
  UploadDocumentRequest,
  UploadDocumentSuccessPayload,
  UploadDocumentSuccess,
  UploadDocumentFailurePayload,
  UploadDocumentFailure,
  DeleteDocumentPayload,
  DeleteDocumentRequest,
  DeleteDocumentSuccessPayload,
  DeleteDocumentSuccess,
  DeleteDocumentFailurePayload,
  DeleteDocumentFailure,
  CancelSessionDayPayload,
  CancelSessionDayRequest,
  CancelSessionDaySuccessPayload,
  CancelSessionDaySuccess,
  CancelSessionDayFailurePayload,
  CancelSessionDayFailure,
  AddScoEducatorPayload,
  AddScoEducatorRequest,
  AddScoEducatorSuccessPayload,
  AddScoEducatorSuccess,
  AddScoEducatorFailurePayload,
  AddScoEducatorFailure,
  DeleteScoEducatorPayload,
  DeleteScoEducatorRequest,
  DeleteScoEducatorSuccessPayload,
  DeleteScoEducatorSuccess,
  DeleteScoEducatorFailurePayload,
  DeleteScoEducatorFailure,
  EnroledStudentListPayload,
  EnroledStudentListRequest,
  EnroledStudentListSuccessPayload,
  EnroledStudentListSuccess,
  EnroledStudentListFailurePayload,
  EnroledStudentListFailure,
  GetSCOStudentAttendanceListPayload,
  GetSCOStudentAttendanceListSuccessPayload,
  GetSCOStudentAttendanceListFailurePayload,
  GetSCOStudentAttendanceListRequest,
  GetSCOStudentAttendanceListSuccess,
  GetSCOStudentAttendanceListFailure,
  FillStudentAttendancePayload,
  FillStudentAttendanceSuccessPayload,
  FillStudentAttendanceFailurePayload,
  FillStudentAttendanceSuccess,
  FillStudentAttendanceRequest,
  FillStudentAttendanceFailure,
  GetSCOStudentWhoAttendedListFailure,
  GetSCOStudentWhoAttendedListFailurePayload,
  GetSCOStudentWhoAttendedListPayload,
  GetSCOStudentWhoAttendedListRequest,
  GetSCOStudentWhoAttendedListSuccess,
  GetSCOStudentWhoAttendedListSuccessPayload,
  EnrolmentCountPayload,
  EnrolmentCountRequest,
  EnrolmentCountSuccessPayload,
  EnrolmentCountSuccess,
  EnrolmentCountFailurePayload,
  EnrolmentCountFailure,
  GetSCOGenerateCertificateListFailure,
  GetSCOGenerateCertificateListFailurePayload,
  GetSCOGenerateCertificateListSuccessPayload,
  GetSCOGenerateCertificateListSuccess,
  GetSCOGenerateCertificateListRequest,
  GetSCOGenerateCertificateListPayload,
  FillGenerateCertificateRequest,
  FillGenerateCertificatePayload,
  FillGenerateCertificateSuccess,
  FillGenerateCertificateSuccessPayload,
  FillGenerateCertificateFailure,
  FillGenerateCertificateFailurePayload,
  SaveAttendanceRequest,
  SaveAttendanceRequestPayload,
  PrintCourseEnrolmentsRequest,
  PrintCourseEnrolmentsPayload,
  PrintCourseEnrolmentsFailure,
  PrintCourseEnrolmentsFailurePayload,
  PrintCourseEnrolmentsSuccess,
  PrintCourseEnrolmentsSuccessPayload,
  SaveGenerateCertificateRequestPayload,
  SaveGenerateCertificateRequest,
  GetEducatorReflectionFormFailure,
  GetEducatorReflectionFormFailurePayload,
  GetEducatorReflectionFormPayload,
  GetEducatorReflectionFormRequest,
  GetEducatorReflectionFormSuccess,
  GetEducatorReflectionFormSuccessPayload,
} from "./types";

export const scheduledOfferingListRequest = (
  payload: ScheduledOfferingListPayload
): ScheduledOfferingListRequest => ({
  type: SCHEDULED_OFFERING_LIST_REQUEST,
  payload,
});

export const scheduledOfferingListSuccess = (
  payload: ScheduledOfferingListSuccessPayload
): ScheduledOfferingListSuccess => ({
  type: SCHEDULED_OFFERING_LIST_SUCCESS,
  payload,
});

export const scheduledOfferingListFailure = (
  payload: ScheduledOfferingListFailurePayload
): ScheduledOfferingListFailure => ({
  type: SCHEDULED_OFFERING_LIST_FAILURE,
  payload,
});

export const addScheduledOfferingRequest = (
  payload: AddEditScheduledOfferingPayload
): AddScheduledOfferingRequest => ({
  type: ADD_SCHEDULED_OFFERING_REQUEST,
  payload,
});

export const addScheduledOfferingSuccess = (
  payload: AddEditScheduledOfferingSuccessPayload
): AddScheduledOfferingSuccess => ({
  type: ADD_SCHEDULED_OFFERING_SUCCESS,
  payload,
});

export const addScheduledOfferingFailure = (
  payload: AddEditScheduledOfferingFailurePayload
): AddScheduledOfferingFailure => ({
  type: ADD_SCHEDULED_OFFERING_FAILURE,
  payload,
});

export const editScheduledOfferingRequest = (
  payload: AddEditScheduledOfferingPayload
): EditScheduledOfferingRequest => ({
  type: EDIT_SCHEDULED_OFFERING_REQUEST,
  payload,
});

export const editScheduledOfferingSuccess = (
  payload: AddEditScheduledOfferingSuccessPayload
): EditScheduledOfferingSuccess => ({
  type: EDIT_SCHEDULED_OFFERING_SUCCESS,
  payload,
});

export const editScheduledOfferingFailure = (
  payload: AddEditScheduledOfferingFailurePayload
): EditScheduledOfferingFailure => ({
  type: EDIT_SCHEDULED_OFFERING_FAILURE,
  payload,
});

export const deleteScheduledOfferingRequest = (
  payload: DeleteScheduledOfferingPayload
): DeleteScheduledOfferingRequest => ({
  type: DELETE_SCHEDULED_OFFERING_REQUEST,
  payload,
});

export const deleteScheduledOfferingSuccess = (
  payload: DeleteScheduledOfferingSuccessPayload
): DeleteScheduledOfferingSuccess => ({
  type: DELETE_SCHEDULED_OFFERING_SUCCESS,
  payload,
});

export const deleteScheduledOfferingFailure = (
  payload: DeleteScheduledOfferingFailurePayload
): DeleteScheduledOfferingFailure => ({
  type: DELETE_SCHEDULED_OFFERING_FAILURE,
  payload,
});

export const scoDaysListRequest = (
  payload: ScoDaysListPayload
): ScoDaysListRequest => ({
  type: SCO_DAYS_LIST_REQUEST,
  payload,
});

export const scoDaysListSuccess = (
  payload: ScoDaysListSuccessPayload
): ScoDaysListSuccess => ({
  type: SCO_DAYS_LIST_SUCCESS,
  payload,
});

export const scoDaysListFailure = (
  payload: ScoDaysListFailurePayload
): ScoDaysListFailure => ({
  type: SCO_DAYS_LIST_FAILURE,
  payload,
});

export const getScheduledOfferingByIdRequest = (
  payload: GetScheduledOfferingByIdPayload
): GetScheduledOfferingByIdRequest => ({
  type: GET_SCHEDULED_OFFERING_BY_ID_REQUEST,
  payload,
});

export const getScheduledOfferingByIdSuccess = (
  payload: GetScheduledOfferingByIdSuccessPayload
): GetScheduledOfferingByIdSuccess => ({
  type: GET_SCHEDULED_OFFERING_BY_ID_SUCCESS,
  payload,
});

export const getScheduledOfferingByIdFailure = (
  payload: GetScheduledOfferingByIdFailurePayload
): GetScheduledOfferingByIdFailure => ({
  type: GET_SCHEDULED_OFFERING_BY_ID_FAILURE,
  payload,
});

export const sessionListByScoIdRequest = (
  payload: SessionListByScoIdPayload
): SessionListByScoIdRequest => ({
  type: SESSION_LIST_BY_SCO_ID_REQUEST,
  payload,
});

export const sessionListByScoIdSuccess = (
  payload: SessionListByScoIdSuccessPayload
): SessionListByScoIdSuccess => ({
  type: SESSION_LIST_BY_SCO_ID_SUCCESS,
  payload,
});

export const sessionListByScoIdFailure = (
  payload: SessionListByScoIdFailurePayload
): SessionListByScoIdFailure => ({
  type: SESSION_LIST_BY_SCO_ID_FAILURE,
  payload,
});

export const addSessionDayRequest = (
  payload: AddEditSessionDayPayload
): AddSessionDayRequest => ({
  type: ADD_SESSION_DAY_REQUEST,
  payload,
});

export const addSessionDaySuccess = (
  payload: AddEditSessionDaySuccessPayload
): AddSessionDaySuccess => ({
  type: ADD_SESSION_DAY_SUCCESS,
  payload,
});

export const addSessionDayFailure = (
  payload: AddEditSessionDayFailurePayload
): AddSessionDayFailure => ({
  type: ADD_SESSION_DAY_FAILURE,
  payload,
});

export const editSessionDayRequest = (
  payload: AddEditSessionDayPayload
): EditSessionDayRequest => ({
  type: EDIT_SESSION_DAY_REQUEST,
  payload,
});

export const editSessionDaySuccess = (
  payload: AddEditSessionDaySuccessPayload
): EditSessionDaySuccess => ({
  type: EDIT_SESSION_DAY_SUCCESS,
  payload,
});

export const editSessionDayFailure = (
  payload: AddEditSessionDayFailurePayload
): EditSessionDayFailure => ({
  type: EDIT_SESSION_DAY_FAILURE,
  payload,
});

export const deleteSessionDayRequest = (
  payload: DeleteSessionDayPayload
): DeleteSessionDayRequest => ({
  type: DELETE_SESSION_DAY_REQUEST,
  payload,
});

export const deleteSessionDaySuccess = (
  payload: DeleteSessionDaySuccessPayload
): DeleteSessionDaySuccess => ({
  type: DELETE_SESSION_DAY_SUCCESS,
  payload,
});

export const deleteSessionDayFailure = (
  payload: DeleteSessionDayFailurePayload
): DeleteSessionDayFailure => ({
  type: DELETE_SESSION_DAY_FAILURE,
  payload,
});

export const downloadDocumentRequest = (
  payload: DownloadDocumentPayload
): DownloadDocumentRequest => ({
  type: DOWNLOAD_DOC_REQUEST,
  payload,
});

export const downloadDocumentSuccess = (
  payload: DownloadDocumentSuccessPayload
): DownloadDocumentSuccess => ({
  type: DOWNLOAD_DOC_SUCCESS,
  payload,
});

export const downloadDocumentFailure = (
  payload: DownloadDocumentFailurePayload
): DownloadDocumentFailure => ({
  type: DOWNLOAD_DOC_FAILURE,
  payload,
});

export const uploadDocumentRequest = (
  payload: UploadDocumentPayload
): UploadDocumentRequest => ({
  type: UPLOAD_DOC_REQUEST,
  payload,
});

export const uploadDocumentSuccess = (
  payload: UploadDocumentSuccessPayload
): UploadDocumentSuccess => ({
  type: UPLOAD_DOC_SUCCESS,
  payload,
});

export const uploadDocumentFailure = (
  payload: UploadDocumentFailurePayload
): UploadDocumentFailure => ({
  type: UPLOAD_DOC_FAILURE,
  payload,
});

export const deleteDocumentRequest = (
  payload: DeleteDocumentPayload
): DeleteDocumentRequest => ({
  type: DELETE_DOC_REQUEST,
  payload,
});

export const deleteDocumentSuccess = (
  payload: DeleteDocumentSuccessPayload
): DeleteDocumentSuccess => ({
  type: DELETE_DOC_SUCCESS,
  payload,
});

export const deleteDocumentFailure = (
  payload: DeleteDocumentFailurePayload
): DeleteDocumentFailure => ({
  type: DELETE_DOC_FAILURE,
  payload,
});

export const cancelSessionDayRequest = (
  payload: CancelSessionDayPayload
): CancelSessionDayRequest => ({
  type: CANCEL_SESSION_DAY_REQUEST,
  payload,
});

export const cancelSessionDaySuccess = (
  payload: CancelSessionDaySuccessPayload
): CancelSessionDaySuccess => ({
  type: CANCEL_SESSION_DAY_SUCCESS,
  payload,
});

export const cancelSessionDayFailure = (
  payload: CancelSessionDayFailurePayload
): CancelSessionDayFailure => ({
  type: CANCEL_SESSION_DAY_FAILURE,
  payload,
});

export const addScoEducatorRequest = (
  payload: AddScoEducatorPayload
): AddScoEducatorRequest => ({
  type: ADD_SCO_EDUCATOR_REQUEST,
  payload,
});

export const addScoEducatorSuccess = (
  payload: AddScoEducatorSuccessPayload
): AddScoEducatorSuccess => ({
  type: ADD_SCO_EDUCATOR_SUCCESS,
  payload,
});

export const addScoEducatorFailure = (
  payload: AddScoEducatorFailurePayload
): AddScoEducatorFailure => ({
  type: ADD_SCO_EDUCATOR_FAILURE,
  payload,
});

export const deleteScoEducatorRequest = (
  payload: DeleteScoEducatorPayload
): DeleteScoEducatorRequest => ({
  type: DELETE_SCO_EDUCATOR_REQUEST,
  payload,
});

export const deleteScoEducatorSuccess = (
  payload: DeleteScoEducatorSuccessPayload
): DeleteScoEducatorSuccess => ({
  type: DELETE_SCO_EDUCATOR_SUCCESS,
  payload,
});

export const deleteScoEducatorFailure = (
  payload: DeleteScoEducatorFailurePayload
): DeleteScoEducatorFailure => ({
  type: DELETE_SCO_EDUCATOR_FAILURE,
  payload,
});

export const enroledStudentListRequest = (
  payload: EnroledStudentListPayload
): EnroledStudentListRequest => ({
  type: ENROLED_STUDENT_LIST_REQUEST,
  payload,
});

export const enroledStudentListSuccess = (
  payload: EnroledStudentListSuccessPayload
): EnroledStudentListSuccess => ({
  type: ENROLED_STUDENT_LIST_SUCCESS,
  payload,
});

export const enroledStudentListFailure = (
  payload: EnroledStudentListFailurePayload
): EnroledStudentListFailure => ({
  type: ENROLED_STUDENT_LIST_FAILURE,
  payload,
});

export const SCOStudentAttendanceListRequest = (
  payload: GetSCOStudentAttendanceListPayload
): GetSCOStudentAttendanceListRequest => ({
  type: SCO_STUDENT_ATTENDANCE_LIST_REQUEST,
  payload,
});

export const SCOStudentAttendanceListSuccess = (
  payload: GetSCOStudentAttendanceListSuccessPayload
): GetSCOStudentAttendanceListSuccess => ({
  type: SCO_STUDENT_ATTENDANCE_LIST_SUCCESS,
  payload,
});

export const SCOStudentAttendanceFailure = (
  payload: GetSCOStudentAttendanceListFailurePayload
): GetSCOStudentAttendanceListFailure => ({
  type: SCO_STUDENT_ATTENDANCE_LIST_FAILURE,
  payload,
});

export const fillStudentAttendanceRequest = (
  payload: FillStudentAttendancePayload
): FillStudentAttendanceRequest => ({
  type: STUDENT_FILL_ATTENDANCE_REQUEST,
  payload,
});

export const fillStudentAttendanceSuccess = (
  payload: FillStudentAttendanceSuccessPayload
): FillStudentAttendanceSuccess => ({
  type: STUDENT_FILL_ATTENDANCE_SUCCESS,
  payload,
});

export const fillStudentAttendanceFailure = (
  payload: FillStudentAttendanceFailurePayload
): FillStudentAttendanceFailure => ({
  type: STUDENT_FILL_ATTENDANCE_FAILURE,
  payload,
});

export const SCOStudentWhoAttendedListRequest = (
  payload: GetSCOStudentWhoAttendedListPayload
): GetSCOStudentWhoAttendedListRequest => ({
  type: SCO_STUDENT_WHO_ATTENDED_LIST_REQUEST,
  payload,
});

export const SCOStudentWhoAttendedListSuccess = (
  payload: GetSCOStudentWhoAttendedListSuccessPayload
): GetSCOStudentWhoAttendedListSuccess => ({
  type: SCO_STUDENT_WHO_ATTENDED_LIST_SUCCESS,
  payload,
});

export const SCOStudentWhoAttendedFailure = (
  payload: GetSCOStudentWhoAttendedListFailurePayload
): GetSCOStudentWhoAttendedListFailure => ({
  type: SCO_STUDENT_WHO_ATTENDED_LIST_FAILURE,
  payload,
});

export const enrolmentCountRequest = (
  payload: EnrolmentCountPayload
): EnrolmentCountRequest => ({
  type: ENROLED_COUNT_REQUEST,
  payload,
});

export const enrolmentCountSuccess = (
  payload: EnrolmentCountSuccessPayload
): EnrolmentCountSuccess => ({
  type: ENROLED_COUNT_SUCCESS,
  payload,
});

export const enrolmentCountFailure = (
  payload: EnrolmentCountFailurePayload
): EnrolmentCountFailure => ({
  type: ENROLED_COUNT_FAILURE,
  payload,
});

export const SCOGenerateCertificateListRequest = (
  payload: GetSCOGenerateCertificateListPayload
): GetSCOGenerateCertificateListRequest => ({
  type: SCO_GENERATE_CERTIFICATE_LIST_REQUEST,
  payload,
});

export const SCOGenerateCertificateListSuccess = (
  payload: GetSCOGenerateCertificateListSuccessPayload
): GetSCOGenerateCertificateListSuccess => ({
  type: SCO_GENERATE_CERTIFICATE_LIST_SUCCESS,
  payload,
});

export const SCOGenerateCertificateFailure = (
  payload: GetSCOGenerateCertificateListFailurePayload
): GetSCOGenerateCertificateListFailure => ({
  type: SCO_GENERATE_CERTIFICATE_LIST_FAILURE,
  payload,
});

export const fillGenerateCertificateRequest = (
  payload: FillGenerateCertificatePayload
): FillGenerateCertificateRequest => ({
  type: FILL_GENERATE_CERTIFICATE_REQUEST,
  payload,
});

export const fillGenerateCertificateSuccess = (
  payload: FillGenerateCertificateSuccessPayload
): FillGenerateCertificateSuccess => ({
  type: FILL_GENERATE_CERTIFICATE_SUCCESS,
  payload,
});

export const fillGenerateCertificateFailure = (
  payload: FillGenerateCertificateFailurePayload
): FillGenerateCertificateFailure => ({
  type: FILL_GENERATE_CERTIFICATE_FAILURE,
  payload,
});

export const saveAttendanceRequest = (
  payload: SaveAttendanceRequestPayload
): SaveAttendanceRequest => ({
  type: SAVE_ATTENDANCE_REQUEST,
  payload,
});

export const printCourseEnrolmentsRequest = (
  payload: PrintCourseEnrolmentsPayload
): PrintCourseEnrolmentsRequest => ({
  type: PRINT_COURSE_ENROLMENTS_REQUEST,
  payload,
});

export const printCourseEnrolmentsSuccess = (
  payload: PrintCourseEnrolmentsSuccessPayload
): PrintCourseEnrolmentsSuccess => ({
  type: PRINT_COURSE_ENROLMENTS_SUCCESS,
  payload,
});

export const printCourseEnrolmentsFailure = (
  payload: PrintCourseEnrolmentsFailurePayload
): PrintCourseEnrolmentsFailure => ({
  type: PRINT_COURSE_ENROLMENTS_FAILURE,
  payload,
});

export const saveGenerateCertificateRequest = (
  payload: SaveGenerateCertificateRequestPayload
): SaveGenerateCertificateRequest => ({
  type: SAVE_GENERATE_CERTIFICATE_REQUEST,
  payload,
});

export const getEducatorReflectionFormRequest = (
  payload: GetEducatorReflectionFormPayload
): GetEducatorReflectionFormRequest => ({
  type: GET_EDUCATOR_REFLECTION_FORM_REQUEST,
  payload,
});

export const getEducatorReflectionFormSuccess = (
  payload: GetEducatorReflectionFormSuccessPayload
): GetEducatorReflectionFormSuccess => ({
  type: GET_EDUCATOR_REFLECTION_FORM_SUCCESS,
  payload,
});

export const getEducatorReflectionFormFailure = (
  payload: GetEducatorReflectionFormFailurePayload
): GetEducatorReflectionFormFailure => ({
  type: GET_EDUCATOR_REFLECTION_FORM_FAILURE,
  payload,
});
