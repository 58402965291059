/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { closeIcon, saveIcon } from "assets/images";
import { useForm } from "react-hook-form";
import {
  EmailPattern,
  getPagePayloadValues,
  getPagePermissions,
  onTextFieldKeyPress,
  roleRenderValue,
  showLoader,
} from "utils/helper";
import {
  ADDRESS_MAX_LENGTH,
  ALPHABETIC_INPUT_REGEX,
  CLOSE_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  FIRST_NAME_MAX_LENGTH,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  LAST_NAME_MAX_LENGTH,
  MAX_INT_VALUE,
  ROLE_NAME,
  STUDENT_ROLE_ID,
} from "utils/constants/constant";
import { useDispatch, useSelector } from "react-redux";
import { RoleListSuccessPayload } from "store/role/types";
import { roleListRequest } from "store/role/actions";
import { IRole, IRolePagePayloadValues } from "utils/interfaces/role";
import { OrderByOptions } from "utils/enums/sorting";
import { AppPages } from "utils/enums/app-pages";
import { IAddEditUserRequest, IUser } from "utils/interfaces/user";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { EDUCATOR_EMAIL_MAX_LENGTH } from "utils/constants/educator";
import {
  ALPHABETIC_VALIDATION_MSG,
  WHITE_SPACE_ERROR_SPACE,
} from "utils/constants/Messages";

interface IAddEditUserProps {
  isEditUser: boolean;
  isOpenUserModal: boolean;
  handleUserClose: any;
  handleSaveClick: any;
  editUserData?: IUser;
}

const AddEditUser: React.FC<IAddEditUserProps> = ({
  isEditUser,
  isOpenUserModal,
  handleUserClose,
  handleSaveClick,
  editUserData,
}) => {
  const userDetails = useSelector((state: any) => state?.auth?.user);
  const dispatch = useDispatch();
  const [roleList, setRoleList] = useState<IRole[]>([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IAddEditUserRequest>({
    defaultValues: {
      roleIds: [],
    },
  });
  const onRoleListSuccess = (response: RoleListSuccessPayload) => {
    setRoleList(
      response.roleList.filter((role) => role.id !== STUDENT_ROLE_ID)
    );
  };

  const getRoleList = async () => {
    showLoader();
    const values: IRolePagePayloadValues = getPagePayloadValues(
      ROLE_NAME,
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.roleName = "";

    const payload = {
      values,
      callback: onRoleListSuccess,
    };

    dispatch(roleListRequest(payload));
  };

  useEffect(() => {
    const permission = getPagePermissions(
      userDetails?.permissions,
      AppPages.Users
    );
    if (permission?.edit || permission?.add) {
      getRoleList();
    }
  }, []);

  useEffect(() => {
    if (watch("roleIds").length > 0) {
      trigger("roleIds");
    }
  }, [watch("roleIds")]);

  useEffect(() => {
    if (isEditUser && editUserData) {
      setValue("firstName", editUserData?.name?.split(" ")[0]);
      setValue("lastName", editUserData?.name?.split(" ")[1]);
      setValue("email", editUserData?.email);
      setValue("roleIds", editUserData?.roleIds);
      setValue("address", editUserData?.address);
    } else {
      reset();
    }
  }, [editUserData, isEditUser, setValue, isOpenUserModal, reset]);

  const getFirstNameError = (): string => {
    if (errors.firstName) {
      if (errors.firstName.type === "required") {
        return "First name is required";
      }

      if (errors.firstName.type === "maxLength") {
        return `Maximum length of first name should be ${FIRST_NAME_MAX_LENGTH}`;
      }

      if (errors.firstName.type === "pattern") {
        return `First name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getLastNameError = (): string => {
    if (errors.lastName) {
      if (errors.lastName.type === "required") {
        return "Last name is required";
      }

      if (errors.lastName.type === "maxLength") {
        return `Maximum length of last name should be ${LAST_NAME_MAX_LENGTH}`;
      }
      if (errors.lastName.type === "pattern") {
        return `Last name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getAddressError = (): string => {
    if (errors.address) {
      if (errors.address.type === "maxLength") {
        return `Maximum length of address should be ${ADDRESS_MAX_LENGTH}`;
      }
      if (errors.address.type === "pattern") {
        return `Address should be ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getRoleError = (): string => {
    if (errors.roleIds) {
      if (errors.roleIds.type === "required") {
        return "Role is required";
      }
    }

    return "";
  };
  const getEmailError = (): string => {
    if (errors.email) {
      if (errors.email.type === "required") {
        return "Email is required";
      }
      if (errors.email.type === "pattern") {
        return "Enter valid email";
      }
      if (errors.email.type === "maxLength") {
        return `Maximum length of email should be ${EDUCATOR_EMAIL_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const onModalClose = () => {
    reset();
    handleUserClose();
  };

  return (
    <>
      <Modal open={isOpenUserModal} onClose={onModalClose}>
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <Box className="common-modal users-modal">
            <Box className="modal-header">
              <Typography variant="h4">
                {isEditUser ? "Edit User" : "Add User"}
              </Typography>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={CLOSE_TOOLTIP_TITLE}
                arrow
              >
                <IconButton onClick={onModalClose}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="modal-body">
              <Grid container columnSpacing="24px">
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    id="first-name"
                    label={
                      <>
                        First Name <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, watch("firstName")?.length)
                    }
                    InputLabelProps={{
                      shrink: Boolean(watch("firstName")),
                    }}
                    error={!!errors.firstName}
                    helperText={getFirstNameError()}
                    {...register("firstName", {
                      required: true,
                      maxLength: FIRST_NAME_MAX_LENGTH,
                      pattern: ALPHABETIC_INPUT_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    id="last-name"
                    label={
                      <>
                        Last Name <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("lastName")),
                    }}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, watch("lastName")?.length)
                    }
                    error={!!errors.lastName}
                    helperText={getLastNameError()}
                    {...register("lastName", {
                      required: true,
                      maxLength: LAST_NAME_MAX_LENGTH,
                      pattern: ALPHABETIC_INPUT_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="role-select-label">
                      Role <span className="color-red">*</span>
                    </InputLabel>
                    <Select
                      labelId="role-select-label"
                      id="role-select"
                      multiple
                      label="Role"
                      input={<OutlinedInput label="Role" />}
                      renderValue={(selected) =>
                        roleRenderValue(selected, roleList)
                      }
                      value={watch("roleIds")}
                      error={!!errors.roleIds}
                      {...register("roleIds", {
                        required: true,
                      })}
                    >
                      {roleList?.map((selectRole) => (
                        <MenuItem key={selectRole.id} value={selectRole.id}>
                          <Checkbox
                            checked={
                              watch("roleIds")?.indexOf(selectRole.id) > -1
                            }
                            icon={<CheckboxIcon />}
                            checkedIcon={<CheckedBoxIcon />}
                          />
                          <ListItemText primary={selectRole.roleName} />
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.roleIds && (
                      <FormHelperText error>{getRoleError()}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="email"
                    label={
                      <>
                        Email <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("email")),
                    }}
                    error={!!errors.email}
                    disabled={isEditUser}
                    helperText={getEmailError()}
                    {...register("email", {
                      required: true,
                      pattern: EmailPattern,
                      maxLength: EDUCATOR_EMAIL_MAX_LENGTH,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="address"
                    label="Address"
                    className="textarea-add"
                    fullWidth
                    multiline
                    InputLabelProps={{
                      shrink: Boolean(watch("address")),
                    }}
                    variant="outlined"
                    error={!!errors.address}
                    helperText={getAddressError()}
                    {...register("address", {
                      maxLength: ADDRESS_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="modal-footer">
              <Button variant="contained" className="btn-save" type="submit">
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={onModalClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default AddEditUser;
