import { Box } from "@mui/system";
import {
  Button,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IStudentDetailsContainerDispatch,
  IStudentDetailsContainerState,
} from "utils/interfaces/student";
import { useHistory, useLocation } from "react-router-dom";
import { AppRoutings } from "utils/enums/app-routings";
import {
  attendanceIcon,
  deleteIcon,
  enrolSummIcon,
  menuVerticalIcon,
  plusLightIcon,
  plusPrimaryIcon,
  sendMailIcon,
  sendSMSIcon,
} from "assets/images";
import React, { useState } from "react";
import { showLoader } from "utils/helper";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import { EnrolmentOptionListSuccessPayload } from "store/studentEnrolment/types";
import {
  APPROVE_BY_EMAIL_SUB_TITLE,
  APPROVE_BY_EMAIL_TITLE,
  APPROVE_BY_SMS_SUB_TITLE,
  APPROVE_BY_SMS_TITLE,
} from "utils/constants/student";
import { DeleteStudentSuccessPayload } from "store/student/types";
import {
  DELETE_STUDENT_CONFIRMATION,
  STUDENT_DELETE_SUCCESS_MSG,
} from "utils/constants/Messages";
import ConfirmModal from "components/ConfirmModal";
import { toast } from "react-toastify";
import StudentNote from "./StudentNote";
import StudentDetailData from "./StudentDetailsData";
import ConfirmationPreference from "./ConfirmationPreference";
import StaffDetails from "./StaffDetails";
import EnrolmentAndAttendanceHistory from "./EnrolmentAndAttendanceHistory";
import MediaConsent from "./MediaConsent";
import ResearchProfileDetails from "./ResearchProfileDetails";
import SendEmailSMSConfirmationModal from "./SendEmailSMSConfirmationModal";
import AddNewEnrolmentModal from "./AddNewEnrolment";

export type StudentDetailsProps = IStudentDetailsContainerState &
  IStudentDetailsContainerDispatch;

const StudentDetails: React.FC<StudentDetailsProps> = (props) => {
  const {
    studnetNoteRequest,
    editstudentNoteRequest,
    confirmationPreferenceRequest,
    editConfirmationPreferenceRequest,
    studentDetailsRequest,
    editStudentDetailRequest,
    researchProfileDetailsRequest,
    editResearchProfileDetailRequest,
    staffDetailsRequest,
    editStaffDetailRequest,
    mediaConsentRequest,
    editMediaConsentRequest,
    enrolmentOptionListRequest,
    enrollmentAndAttendanceHistoryListRequest,
    getPendingEnrolmentListRequest,
    getStudentEnrolmentForConfirmationRequest,
    approveEnrolmentByEmailSMSRequest,
    getCurrentCoursesForAddEnrolmentRequest,
    addNewEnrolmentFromStudentRequest,
    reSendConfirmationMailRequest,
    changeCoursePreferenceRequest,
  } = props;
  const history = useHistory();
  const location = useLocation<{
    studentId: number;
    studentName: string;
    isDeleted?: boolean;
  }>();
  const [stdDetails, setStdDetails] = React.useState<null | HTMLElement>(null);
  const [enrolmentOptionList, setEnrolmentOptionList] =
    React.useState<IEnrolmentOptions>();

  const [sendViaEmailOrSMS, setSendViaEmailOrSMS] = useState<string>("");
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
    useState<boolean>(false);
  const [isOpenAddNewEnrolmentModal, setIsOpenAddNewEnrolmentModal] =
    useState<boolean>(false);

  const [attendanceHistoryChange, setAttendanceHistoryChange] =
    useState<boolean>(true);

  const [studentDetailChange, setStudentDetailChange] = useState<boolean>(true);

  const handleAddNewEnrolmentModalClick = () => {
    setIsOpenAddNewEnrolmentModal(true);
    setAttendanceHistoryChange(false);
  };

  const handleAddNewEnrolmentModalClose = () => {
    setIsOpenAddNewEnrolmentModal(false);
  };

  const handleEmailConfirmationModalClick = () => {
    setIsOpenConfirmationModal(true);
    setSendViaEmailOrSMS("Email");
    setAttendanceHistoryChange(false);
    setStudentDetailChange(false);
  };

  const handleSMSConfirmationModalClick = () => {
    setIsOpenConfirmationModal(true);
    setSendViaEmailOrSMS("SMS");
    setAttendanceHistoryChange(false);
    setStudentDetailChange(false);
  };

  const open = Boolean(stdDetails);
  const handleStdDetailsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setStdDetails(event.currentTarget);
  };
  const handleStdDetailsClose = () => {
    setStdDetails(null);
  };
  const [isOpenStudentDeleteModal, setIsOpenStudentDeleteModal] =
    useState<boolean>(false);
  const [isStaff, setIsStaff] = React.useState(null);
  const handleIsStaffChange = (newIsStaff: any) => {
    setIsStaff(newIsStaff);
  };

  const handleConfirmationModalClose = () => {
    setIsOpenConfirmationModal(false);
    setIsOpenConfirmationModal(false);
    handleStdDetailsClose();
  };

  const onEnrolmentOptionListSuccess = (
    response: EnrolmentOptionListSuccessPayload
  ) => {
    setEnrolmentOptionList(response.enrolmentOptionList);
  };

  const handleStudentDeleteCloseModal = () => {
    setIsOpenStudentDeleteModal(false);
  };

  const getEnrolmentOptionList = () => {
    if (enrolmentOptionListRequest) {
      showLoader();

      const payload = {
        values: {},
        callback: onEnrolmentOptionListSuccess,
      };

      enrolmentOptionListRequest(payload);
    }
  };

  const onDeleteStudentSuccess = (response: DeleteStudentSuccessPayload) => {
    if (response.success) {
      toast.success(STUDENT_DELETE_SUCCESS_MSG);
      handleStudentDeleteCloseModal();
      history.push(AppRoutings.Students);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const handleOptionsMenuDelete = () => {
    setIsOpenStudentDeleteModal(true);
    handleStdDetailsClose();
  };

  const handleDeleteStudent = () => {
    const { deleteStudentRequest } = props;

    if (deleteStudentRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(location.state.studentId),
        },
        callback: onDeleteStudentSuccess,
      };

      deleteStudentRequest(payload);
    }
  };

  React.useEffect(() => {
    getEnrolmentOptionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="std-details">
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Student Details
          </Typography>
          <Box className="std-detail-btn-group">
            <Button
              variant="outlined"
              className="btn-back"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
            {!location.state.isDeleted && (
              <Button
                variant="outlined"
                className="btn-add-enrol"
                onClick={handleAddNewEnrolmentModalClick}
              >
                <img src={plusPrimaryIcon} alt="plus" className="default" />
                <img src={plusLightIcon} alt="plus" className="active" />
                Add New Enrolment
              </Button>
            )}
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title="Student Details Menu"
              arrow
            >
              <Button
                id="std-details-button"
                aria-controls={open ? "std-details-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleStdDetailsClick}
                className="btn-vertical-menu"
                variant="contained"
              >
                <img src={menuVerticalIcon} alt="Menu" />
              </Button>
            </Tooltip>
            <Menu
              id="std-details-menu"
              anchorEl={stdDetails}
              className="std-details-menu"
              open={open}
              onClose={handleStdDetailsClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() =>
                  history.push({
                    pathname: AppRoutings.EnrollmentSummary,
                    state: { studentId: location?.state?.studentId },
                  })
                }
              >
                <img src={enrolSummIcon} alt="Enrolment Summary" />
                Enrolment Summary
              </MenuItem>
              <MenuItem
                onClick={() =>
                  history.push({
                    pathname: AppRoutings.StudentAttendance,
                    state: {
                      studentId: location?.state?.studentId,
                      studentName: location?.state?.studentName,
                    },
                  })
                }
              >
                <img src={attendanceIcon} alt="Attendance Record" />
                Attendance Record
              </MenuItem>
              <MenuItem
                disabled={location.state.isDeleted}
                onClick={() =>
                  location.state.isDeleted
                    ? null
                    : handleSMSConfirmationModalClick()
                }
              >
                <img src={sendSMSIcon} alt="Send SMS Confirmation" />
                Send SMS Approval
              </MenuItem>
              <MenuItem
                disabled={location.state.isDeleted}
                onClick={() =>
                  location.state.isDeleted
                    ? null
                    : handleEmailConfirmationModalClick()
                }
              >
                <img src={sendMailIcon} alt="Send Email Confirmation" />
                Send Email Approval
              </MenuItem>
              <MenuItem
                disabled={location.state.isDeleted}
                onClick={() =>
                  location.state.isDeleted ? null : handleOptionsMenuDelete()
                }
              >
                <img src={deleteIcon} alt="Delete" />
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Grid container spacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
              <Grid item xs={12} lg={7}>
                <StudentNote
                  studnetNoteRequest={studnetNoteRequest}
                  editstudentNoteRequest={editstudentNoteRequest}
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <ConfirmationPreference
                  confirmationPreferenceRequest={confirmationPreferenceRequest}
                  editConfirmationPreferenceRequest={
                    editConfirmationPreferenceRequest
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <EnrolmentAndAttendanceHistory
                  enrollmentAndAttendanceHistoryListRequest={
                    enrollmentAndAttendanceHistoryListRequest
                  }
                  attendanceHistoryChange={attendanceHistoryChange}
                  reSendConfirmationMailRequest={reSendConfirmationMailRequest}
                  enrolmentOptionListRequest={enrolmentOptionListRequest}
                  changeCoursePreferenceRequest={changeCoursePreferenceRequest}
                />
              </Grid>
              <Grid item xs={12}>
                <StudentDetailData
                  studentDetailsRequest={studentDetailsRequest}
                  editStudentDetailRequest={editStudentDetailRequest}
                  enrolmentOptionList={enrolmentOptionList}
                  onIsStaffChange={handleIsStaffChange}
                  studentDetailChange={studentDetailChange}
                />
              </Grid>
              {isStaff != null && (
                <>
                  {!isStaff ? (
                    <Grid item xs={12}>
                      <ResearchProfileDetails
                        enrolmentOptionList={enrolmentOptionList}
                        researchProfileDetailsRequest={
                          researchProfileDetailsRequest
                        }
                        editResearchProfileDetailRequest={
                          editResearchProfileDetailRequest
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <StaffDetails
                        staffDetailsRequest={staffDetailsRequest}
                        editStaffDetailRequest={editStaffDetailRequest}
                        enrolmentOptionList={enrolmentOptionList}
                      />
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={12}>
                <MediaConsent
                  mediaConsentRequest={mediaConsentRequest}
                  editMediaConsentRequest={editMediaConsentRequest}
                  enrolmentOptionList={enrolmentOptionList}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isOpenStudentDeleteModal && (
          <ConfirmModal
            handleSubmitConfirmationModalClose={handleStudentDeleteCloseModal}
            submitConfirmationMessage={DELETE_STUDENT_CONFIRMATION}
            handleYesClick={handleDeleteStudent}
          />
        )}
      </div>
      {isOpenConfirmationModal && (
        <SendEmailSMSConfirmationModal
          SendViaEmailOrSMS={sendViaEmailOrSMS}
          handleConfirmationModalClose={handleConfirmationModalClose}
          getPendingEnrolmentListRequest={getPendingEnrolmentListRequest}
          getStudentEnrolmentForConfirmationRequest={
            getStudentEnrolmentForConfirmationRequest
          }
          approveEnrolmentByEmailSMSRequest={approveEnrolmentByEmailSMSRequest}
          enrolmentOptionListRequest={enrolmentOptionListRequest}
          modalTitle={
            sendViaEmailOrSMS === "Email"
              ? APPROVE_BY_EMAIL_TITLE
              : APPROVE_BY_SMS_TITLE
          }
          modalSubTitle={
            sendViaEmailOrSMS === "Email"
              ? APPROVE_BY_EMAIL_SUB_TITLE
              : APPROVE_BY_SMS_SUB_TITLE
          }
          setAttendanceHistoryChange={setAttendanceHistoryChange}
          setStudentDetailChange={setStudentDetailChange}
        />
      )}

      {isOpenAddNewEnrolmentModal && (
        <AddNewEnrolmentModal
          getCurrentCoursesForAddEnrolmentRequest={
            getCurrentCoursesForAddEnrolmentRequest
          }
          addNewEnrolmentFromStudentRequest={addNewEnrolmentFromStudentRequest}
          handleAddNewEnrolmentModalClose={handleAddNewEnrolmentModalClose}
          setAttendanceHistoryChange={setAttendanceHistoryChange}
          enrolmentOptionList={enrolmentOptionList}
        />
      )}
    </>
  );
};

export default StudentDetails;
