/* eslint-disable no-useless-escape */
import { loginLogo } from "assets/images";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { AppRoutings } from "utils/enums/app-routings";

const DesignerForgotPassword: React.FC = () => {
  useEffect(() => {
    AOS.init({
      easing: "ease-in",
      duration: 400,
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <div className="login-wrapper">
        <Grid container className="login-container">
          <Grid xs={12} sm="auto">
            <a
              data-aos="fade-right"
              data-aos-delay="300"
              href="/"
              title="South Eastern Sydney Recovery and Wellbeing College"
              className="login-logo"
            >
              <img
                src={loginLogo}
                alt="South Eastern Sydney Recovery and Wellbeing College"
              />
            </a>
          </Grid>
          <Grid xs={12} sm="auto">
            <Card
              className="login-card"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              <CardContent>
                <Typography variant="h2" className="card-heading">
                  Forgot Password
                </Typography>
                <Typography variant="h6" className="card-subheading">
                  Please enter you email address.
                </Typography>
                <div className="login-content-form">
                  <TextField
                    id="email"
                    type="email"
                    label="Email"
                    fullWidth
                    variant="outlined"
                  />
                </div>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  title="Reset Password"
                  href={AppRoutings.DesignerResetPassword}
                  className="login-btn"
                  disableElevation
                >
                  Reset Password
                </Button>
                <Button
                  title="Back to Login"
                  href={AppRoutings.DesignerLogin}
                  className="login-btn"
                  disableElevation
                >
                  Back to Login
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DesignerForgotPassword;
