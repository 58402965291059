import { all, call, put, takeLatest } from "redux-saga/effects";
import { EducatorStatus } from "utils/enums/educator";
import { AxiosError } from "axios";
import {
  addEducatorFailure,
  addEducatorSuccess,
  coursesDeliveredListFailure,
  coursesDeliveredListSuccess,
  coursesEnroledListFailure,
  coursesEnroledListSuccess,
  deleteEducatorFailure,
  deleteEducatorSuccess,
  editEducatorFailure,
  editEducatorSuccess,
  educatorByIdFailure,
  educatorByIdSuccess,
  educatorListFailure,
  educatorListSuccess,
  educatorStatisticsDataFailure,
  educatorStatisticsDataSuccess,
  sendReminderToEducatorFailure,
  sendReminderToEducatorSuccess,
} from "./actions";
import {
  EDUCATOR_LIST_REQUEST,
  ADD_EDUCATOR_REQUEST,
  EDIT_EDUCATOR_REQUEST,
  DELETE_EDUCATOR_REQUEST,
  EDUCATOR_BY_ID_REQUEST,
  COURSES_DELIVERED_LIST_REQUEST,
  COURSES_ENROLED_LIST_REQUEST,
  SEND_REMINDER_TO_EDUCATOR_REQUEST,
  EDUCATOR_STATISTICS_DATA_REQUEST,
} from "./actionTypes";
import educatorService from "./service";
import {
  AddEditEducatorResponse,
  CoursesDeliveredListResponse,
  CoursesEnroledListResponse,
  DeleteEducatorResponse,
  EducatorByIdResponse,
  EducatorListResponse,
  EducatorStatisticsDataRequest,
  EducatorStatisticsDataResponse,
  SendReminderToEducatorResponse,
} from "./types";

function* educatorListSaga(action: any) {
  try {
    const payload: any = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      name: action.payload.values.name,
      term: action.payload.values.term,
    };
    if (action.payload.values.educatorType) {
      payload.educatorType = action.payload.values.educatorType;
    }

    if (
      action.payload.values.status === EducatorStatus.Inactive ||
      action.payload.values.status === EducatorStatus.Active
    ) {
      payload.status = action.payload.values.status;
    }
    if (action.payload.values.roleId) {
      payload.roleId = action.payload.values.roleId;
    }

    const response: EducatorListResponse = yield call(
      educatorService.getEducatorList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      educatorListSuccess({
        educatorList: response.item.records,
        educatorListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      educatorList: response.item.records,
      educatorListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      educatorListFailure({
        error: e.message,
      })
    );
  }
}

function* addEducatorSaga(action: any) {
  try {
    const response: AddEditEducatorResponse = yield call(
      educatorService.addEducator,
      {
        firstName: action.payload.values.firstName,
        lastName: action.payload.values.lastName,
        emailAddress: action.payload.values.emailAddress,
        secondaryEmail: action.payload.values.secondaryEmail,
        roleIds: action.payload.values.roleIds,
        status: action.payload.values.status,
        educatorType: action.payload.values.educatorType,
        streetAddress: action.payload.values.streetAddress,
        suburb: action.payload.values.suburb,
        postCode: action.payload.values.postCode,
        state: action.payload.values.state,
        streetAddress2Line1: action.payload.values.streetAddress2Line1,
        streetAddress2Line2: action.payload.values.streetAddress2Line2,
        city: action.payload.values.city,
        state2: action.payload.values.state2,
        zip: action.payload.values.zip,
        service: action.payload.values.service,
        otherWorkplace: action.payload.values.otherWorkplace,
        dayTimeBusinessPhone: action.payload.values.dayTimeBusinessPhone,
        afterHoursPersonalPhone: action.payload.values.afterHoursPersonalPhone,
        availability: action.payload.values.availability,
        emergencyContact: action.payload.values.emergencyContact,
        emergencyContactRelationship:
          action.payload.values.emergencyContactRelationship,
        emergencyContactNumber: action.payload.values.emergencyContactNumber,
        skillsExperienceInterest:
          action.payload.values.skillsExperienceInterest,
        courseDeliveryLanguage: action.payload.values.courseDeliveryLanguage,
        studentProfileLink: action.payload.values.studentProfileLink,
      }
    );

    yield put(
      addEducatorSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addEducatorFailure({
        error: e.message,
      })
    );
  }
}

function* editEducatorSaga(action: any) {
  try {
    const response: AddEditEducatorResponse = yield call(
      educatorService.editEducator,
      {
        id: action.payload.values.id,
        userID: action.payload.values.userID,
        firstName: action.payload.values.firstName,
        lastName: action.payload.values.lastName,
        emailAddress: action.payload.values.emailAddress,
        secondaryEmail: action.payload.values.secondaryEmail,
        roleIds: action.payload.values.roleIds,
        status: action.payload.values.status,
        educatorType: action.payload.values.educatorType,
        streetAddress: action.payload.values.streetAddress,
        suburb: action.payload.values.suburb,
        postCode: action.payload.values.postCode,
        state: action.payload.values.state,
        streetAddress2Line1: action.payload.values.streetAddress2Line1,
        streetAddress2Line2: action.payload.values.streetAddress2Line2,
        city: action.payload.values.city,
        state2: action.payload.values.state2,
        zip: action.payload.values.zip,
        service: action.payload.values.service,
        otherWorkplace: action.payload.values.otherWorkplace,
        dayTimeBusinessPhone: action.payload.values.dayTimeBusinessPhone,
        afterHoursPersonalPhone: action.payload.values.afterHoursPersonalPhone,
        availability: action.payload.values.availability,
        emergencyContact: action.payload.values.emergencyContact,
        emergencyContactRelationship:
          action.payload.values.emergencyContactRelationship,
        emergencyContactNumber: action.payload.values.emergencyContactNumber,
        skillsExperienceInterest:
          action.payload.values.skillsExperienceInterest,
        courseDeliveryLanguage: action.payload.values.courseDeliveryLanguage,
        studentProfileLink: action.payload.values.studentProfileLink,
      }
    );

    yield put(
      editEducatorSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editEducatorFailure({
        error: e.message,
      })
    );
  }
}

function* deleteEducatorSaga(action: any) {
  try {
    const response: DeleteEducatorResponse = yield call(
      educatorService.deleteEducator,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      deleteEducatorSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteEducatorFailure({
        error: e.message,
      })
    );
  }
}

function* educatorByIdSaga(action: any) {
  try {
    const response: EducatorByIdResponse = yield call(
      educatorService.getEducatorById,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      educatorByIdSuccess({
        educatorDetails: response.item,
      })
    );
    action.payload.callback({
      educatorDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      educatorByIdFailure({
        error: e.message,
      })
    );
  }
}

function* coursesDeliveredListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };

    const response: CoursesDeliveredListResponse = yield call(
      educatorService.getCoursesDeliveredList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      coursesDeliveredListSuccess({
        coursesDeliveredList: response.item.records,
        coursesDeliveredListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      coursesDeliveredList: response.item.records,
      coursesDeliveredListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      coursesDeliveredListFailure({
        error: e.message,
      })
    );
  }
}

function* coursesEnroledListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      course: action.payload.values.course,
    };

    const response: CoursesEnroledListResponse = yield call(
      educatorService.getEnroledCourseList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      coursesEnroledListSuccess({
        coursesEnroledList: response.item.records,
        coursesEnroledListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      coursesEnroledList: response.item.records,
      coursesEnroledListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      coursesEnroledListFailure({
        error: e.message,
      })
    );
  }
}

function* sendReminderToEducatorSaga(action: any) {
  try {
    const response: SendReminderToEducatorResponse = yield call(
      educatorService.sendReminderToEducator,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      sendReminderToEducatorSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      sendReminderToEducatorFailure({
        error: e.message,
      })
    );
  }
}

function* educatorStatisticsDataSaga(action: EducatorStatisticsDataRequest) {
  try {
    const response: EducatorStatisticsDataResponse = yield call(
      educatorService.getEducatorStatisticsData,
      {
        term: action.payload.values.term,
        name: action.payload.values.name,
        roleId: action.payload.values.roleId,
        educatorType: action.payload.values.educatorType,
        status: action.payload.values.status,
      }
    );

    yield put(
      educatorStatisticsDataSuccess({
        statisticsData: response.item,
      })
    );
    action.payload.callback({
      statisticsData: response.item,
    });
  } catch (e) {
    yield put(
      educatorStatisticsDataFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* educatorSaga() {
  yield all([takeLatest(EDUCATOR_LIST_REQUEST, educatorListSaga)]);
  yield all([takeLatest(ADD_EDUCATOR_REQUEST, addEducatorSaga)]);
  yield all([takeLatest(EDIT_EDUCATOR_REQUEST, editEducatorSaga)]);
  yield all([takeLatest(DELETE_EDUCATOR_REQUEST, deleteEducatorSaga)]);
  yield all([takeLatest(EDUCATOR_BY_ID_REQUEST, educatorByIdSaga)]);
  yield all([
    takeLatest(COURSES_DELIVERED_LIST_REQUEST, coursesDeliveredListSaga),
  ]);
  yield all([takeLatest(COURSES_ENROLED_LIST_REQUEST, coursesEnroledListSaga)]);
  yield all([
    takeLatest(SEND_REMINDER_TO_EDUCATOR_REQUEST, sendReminderToEducatorSaga),
  ]);
  yield all([
    takeLatest(EDUCATOR_STATISTICS_DATA_REQUEST, educatorStatisticsDataSaga),
  ]);
}

export default educatorSaga;
