import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon, saveIcon } from "assets/images";
import { useForm } from "react-hook-form";
import { IAddEditLocation, ILocation } from "utils/interfaces/location";
import { useEffect } from "react";
import {
  CLOSE_TOOLTIP_TITLE,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  LOCATION_MAX_LENGTH,
} from "utils/constants/constant";
import { WHITE_SPACE_ERROR_SPACE } from "utils/constants/Messages";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";

interface IAddEditLocationProps {
  isEditLocation: boolean;
  isOpenLocationModal: boolean;
  handleLocationClose: any;
  handleSaveClick: any;
  editLocationData?: ILocation;
}

const AddEditLocation: React.FC<IAddEditLocationProps> = ({
  isEditLocation,
  isOpenLocationModal,
  handleLocationClose,
  handleSaveClick,
  editLocationData,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IAddEditLocation>();

  const onModalClose = () => {
    reset();
    handleLocationClose();
  };

  const getLocationError = (): string => {
    if (errors.location) {
      if (errors.location.type === "required") {
        return "Location is required";
      }

      if (errors.location.type === "maxLength") {
        return `Maximum length of location should be ${LOCATION_MAX_LENGTH}`;
      }

      if (errors.location.type === "pattern") {
        return `Location ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  useEffect(() => {
    if (isEditLocation && editLocationData) {
      setValue("location", editLocationData?.location);
      setValue("isBlended", editLocationData?.isBlended);
    } else {
      setValue("location", "");
      setValue("isBlended", false);
    }
  }, [editLocationData, isEditLocation, setValue, isOpenLocationModal]);

  return (
    <>
      <Modal open={isOpenLocationModal} onClose={onModalClose}>
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <Box className="common-modal location-modal">
            <Box className="modal-header">
              <Typography variant="h4">
                {isEditLocation ? "Edit Location" : "Add Location"}
              </Typography>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={CLOSE_TOOLTIP_TITLE}
                arrow
              >
                <IconButton onClick={onModalClose}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="modal-body">
              <TextField
                id="location"
                label={
                  <>
                    Location <span className="color-red">*</span>
                  </>
                }
                fullWidth
                variant="outlined"
                multiline
                error={!!errors.location}
                helperText={getLocationError()}
                {...register("location", {
                  required: true,
                  maxLength: LOCATION_MAX_LENGTH,
                  pattern:
                    INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedBoxIcon />}
                    {...register("isBlended")}
                    checked={watch("isBlended")}
                    onChange={() => setValue("isBlended", !watch("isBlended"))}
                    className="stepper-check-course"
                  />
                }
                label="Is Blended?"
                title="Is Blended?"
              />
            </Box>
            <Box className="modal-footer">
              <Button variant="contained" className="btn-save" type="submit">
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={onModalClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default AddEditLocation;
