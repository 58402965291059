import { connect, MapDispatchToProps } from "react-redux";
import {
  generateCertificateRequest,
  studnetAttendanceListRequest,
} from "store/student/action";
import {
  IStudentAttendance,
  IStudentAttendanceDispatch,
} from "utils/interfaces/student";
import StudentAttendanceComponent, {
  StudentAttendanceProps,
} from "pages/Students/StudentAttendance/index";
import { downloadCertificateRequest } from "store/studentDashboard/actions";

const mapDispatchToProps: MapDispatchToProps<
  IStudentAttendanceDispatch,
  IStudentAttendance
> = {
  studnetAttendanceListRequest,
  generateCertificateRequest,
  downloadCertificateRequest,
};

const connector = connect(null, mapDispatchToProps);

const StudentAttendance: React.FC<StudentAttendanceProps> = (props) => {
  return <StudentAttendanceComponent {...props} />;
};

export default connector(StudentAttendance);
