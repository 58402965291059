import { Box } from "@mui/system";
import {
  Modal,
  Button,
  Grid,
  IconButton,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Fade,
  Tooltip,
} from "@mui/material";
import {
  closeIcon,
  saveIcon,
  userSmallIcon,
  userSmallLightIcon,
} from "assets/images";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import {
  IAddEditScheduledOffering,
  IAddScheduleDetails,
  ICourseOfferingDaysResponse,
  ISCODetail,
} from "utils/interfaces/scheduled-offering";
import { Controller, useForm } from "react-hook-form";
import {
  checkEndDateLessThanOrEqualToStart,
  convertToDateTimeWithFormat,
  handlePasteNumberField,
  onNumberFieldKeyPress,
} from "utils/helper";
import { toast } from "react-toastify";
import {
  CLOSE_TOOLTIP_TITLE,
  DATE_PICKER_FORMAT,
  DATE_VIEW_FORMAT,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import { SCOAvailableStatus } from "utils/enums/scheduled-offering";
import { ADD_SCO_DAY_MSG } from "utils/constants/Messages";
import CalendarIcon from "components/CalendarIcon";

const createAddScheduleData = (
  day: number,
  studentAttendance: number,
  sessionDate: any,
  attendedNo: number,
  attendance: string
): IAddScheduleDetails => {
  return {
    day,
    studentAttendance,
    sessionDate,
    attendedNo,
    attendance,
  };
};

interface IAddSCODaysProps {
  addSCODaysModal: boolean;
  handleAddSCODaysModalClose: any;
  addFormData?: IAddEditScheduledOffering;
  handleFinalAddSCOClick: any;
  scoDetails?: ISCODetail;
  scoDaysList?: Array<ICourseOfferingDaysResponse>;
  pickerMinDate: any;
  pickerMaxDate: any;
}

const AddSCODays: React.FC<IAddSCODaysProps> = ({
  addSCODaysModal,
  handleAddSCODaysModalClose,
  addFormData,
  handleFinalAddSCOClick,
  scoDetails,
  scoDaysList,
  pickerMinDate,
  pickerMaxDate,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<IAddScheduleDetails>({
    defaultValues: {
      sessionDate: null,
    },
  });
  const [addScheduleRow, setAddScheduleRow] = useState<IAddScheduleDetails[]>(
    []
  );

  useEffect(() => {
    if (scoDaysList && scoDaysList.length > 0 && addFormData?.isRepeat) {
      const rowInitialData: Array<IAddScheduleDetails> = [];
      scoDaysList.forEach((dayItem) => {
        const date = convertToDateTimeWithFormat(
          dayItem?.sessionStartDateTime,
          DATE_VIEW_FORMAT
        );
        const data = createAddScheduleData(dayItem.dayNumber, 0, date, 0, "No");
        rowInitialData.push(data);
      });
      setAddScheduleRow(rowInitialData);
    } else {
      const date = convertToDateTimeWithFormat(
        addFormData?.startDateOfFirstSession,
        DATE_VIEW_FORMAT
      );
      const rowInitialData = createAddScheduleData(1, 0, date, 0, "No");
      setAddScheduleRow([rowInitialData]);
    }
    setValue("day", null);
    setValue("sessionDate", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSCODaysModal, addFormData, scoDaysList]);

  const getDayError = (): string => {
    if (errors.day) {
      if (errors.day.type === "required") {
        return "Day number is required";
      }
      if (errors.day.type === "validate") {
        return "Day number already exists";
      }
    }

    return "";
  };

  const checkDayNumberExist = (dayNumber: number) => {
    let exist = false;

    addScheduleRow?.forEach((row) => {
      if (Number(row.day) === Number(dayNumber)) {
        exist = true;
      }
    });

    return exist;
  };

  const checkDateOfOfferingDayExist = (date: any) => {
    let exist = false;
    const selectedDate = convertToDateTimeWithFormat(date, DATE_VIEW_FORMAT);

    addScheduleRow?.forEach((row) => {
      if (row.sessionDate === selectedDate) {
        exist = true;
      }
    });

    return exist;
  };

  const getDateOfOfferingDayError = (): string => {
    if (errors.sessionDate) {
      if (errors.sessionDate.type === "required") {
        return "Date of offering day is required";
      }
      if (errors.sessionDate.type === "validate") {
        if (checkDateOfOfferingDayExist(watch("sessionDate"))) {
          return "Date of offering day is already exists";
        }

        return "Please select date greater than start date";
      }
    }

    return "";
  };

  const handleAddDaysClick = (formData: IAddScheduleDetails) => {
    const dayNumber = Number(formData.day);
    const date = convertToDateTimeWithFormat(
      formData?.sessionDate,
      DATE_VIEW_FORMAT
    );
    const data = createAddScheduleData(dayNumber, 0, date, 0, "No");
    setAddScheduleRow([...addScheduleRow, data]);
    reset();
  };

  const generateDateTimeString = () => {
    if (addFormData) {
      const dateString = convertToDateTimeWithFormat(
        addFormData.startDateOfFirstSession,
        DATE_VIEW_FORMAT
      );
      const startTimeString = convertToDateTimeWithFormat(
        addFormData.startTimeOfFirstSession,
        TIME_VIEW_FORMAT
      );
      const endTimeString = convertToDateTimeWithFormat(
        addFormData.endTimeOfFirstSession,
        TIME_VIEW_FORMAT
      );

      return `${dateString} ${startTimeString} to ${endTimeString}`;
    }

    return "";
  };

  const onSaveClick = () => {
    if (addScheduleRow?.length > 0) {
      handleFinalAddSCOClick(addScheduleRow);
    } else {
      toast.error(ADD_SCO_DAY_MSG);
    }
  };

  return (
    <>
      <Modal open={addSCODaysModal} onClose={handleAddSCODaysModalClose}>
        <Box className="common-modal addsco-modal addsco-offering-modal">
          <Box className="modal-header">
            <Typography variant="h4">
              Add Scheduled Course Offering Days
            </Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleAddSCODaysModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <form onSubmit={handleSubmit(handleAddDaysClick)}>
            <Box className="modal-body">
              <Grid
                container
                rowSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                    rowSpacing="16px"
                  >
                    <Grid item xs={12} sm={5} md={3}>
                      <TextField
                        type="number"
                        id="day-hum"
                        label={
                          <>
                            Day Number <span className="color-red">*</span>
                          </>
                        }
                        fullWidth
                        InputLabelProps={{ shrink: !!watch("day") }}
                        variant="outlined"
                        onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                        onPaste={handlePasteNumberField}
                        error={!!errors.day}
                        helperText={getDayError()}
                        {...register("day", {
                          required: true,
                          validate: (value) =>
                            !checkDayNumberExist(Number(value)),
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} md={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DesktopDatePicker"]}>
                          <Controller
                            control={control}
                            {...register("sessionDate", {
                              required: true,
                              validate: (value) =>
                                !checkDateOfOfferingDayExist(Number(value)) &&
                                !checkEndDateLessThanOrEqualToStart(
                                  addFormData?.startDateOfFirstSession,
                                  value
                                ),
                            })}
                            render={({ field }) => (
                              <DesktopDatePicker
                                {...field}
                                className="date-picker disabled-picker-text-field"
                                format={DATE_PICKER_FORMAT}
                                label={
                                  <>
                                    Date Of Offering Day{" "}
                                    <span className="color-red">*</span>
                                  </>
                                }
                                value={watch("sessionDate")}
                                minDate={pickerMinDate}
                                maxDate={pickerMaxDate}
                                slots={{
                                  openPickerIcon: CalendarIcon,
                                }}
                                slotProps={{
                                  textField: {
                                    disabled: true,
                                    error: !!errors.sessionDate,
                                    helperText: getDateOfOfferingDayError(),
                                  },
                                }}
                              />
                            )}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={2} md>
                      <Button
                        variant="contained"
                        className="btn-add-small"
                        type="submit"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className="date-label">
                    <strong>{scoDetails?.courseName}</strong> [
                    {scoDetails?.termName}]{" "}
                    {addFormData?.maxEnrollments
                      ? `(Max -${addFormData?.maxEnrollments} - ${SCOAvailableStatus.Open})`
                      : `(${SCOAvailableStatus.Open})`}{" "}
                    at {scoDetails?.location}, {generateDateTimeString()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              width: "76px",
                              minWidth: "76px",
                            }}
                          >
                            Day No.
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "156px", minWidth: "156px" }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ maxWidth: "136px" }}
                            >
                              Students Who Attended
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "167px", minWidth: "167px" }}
                          >
                            Date of Offering Day
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ minWidth: "126px", width: "126px" }}
                          >
                            No. Attended
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "130px", minWidth: "130px" }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ maxWidth: "110px" }}
                            >
                              Attendance Submitted
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {addScheduleRow?.map((row) => (
                          <TableRow key={row.day}>
                            <TableCell component="th" scope="row">
                              {row.day}
                            </TableCell>
                            <TableCell>
                              <Button
                                className="btn-course"
                                variant="outlined"
                                disabled
                              >
                                <img
                                  src={userSmallIcon}
                                  alt="Course List"
                                  className="default"
                                />
                                <img
                                  src={userSmallLightIcon}
                                  alt="Course List"
                                  className="active"
                                />
                                <Typography variant="body2">
                                  {row.studentAttendance}
                                </Typography>
                              </Button>
                            </TableCell>
                            <TableCell>{row.sessionDate}</TableCell>
                            <TableCell>{row.attendedNo}</TableCell>
                            <TableCell>{row.attendance}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </form>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={onSaveClick}
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleAddSCODaysModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddSCODays;
