import { Box } from "@mui/system";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon, notificationCircle } from "assets/images";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface ISubmitConfirmationModalProps {
  handleReminderClose: any;
  handleYesClick: any;
  remainingCourses: number;
}

const EducatorReminderModal: React.FC<ISubmitConfirmationModalProps> = ({
  handleReminderClose,
  handleYesClick,
  remainingCourses,
}) => {
  return (
    <>
      <Modal open onClose={handleReminderClose}>
        <Box className="common-modal reminder-modal">
          <Box className="modal-header">
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleReminderClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body">
            <div className="reminder-icon">
              <img src={notificationCircle} alt="bell-icon" />
            </div>
            <Typography variant="body2" className="reminder-text">
              {remainingCourses} Courses Remaining
            </Typography>
            <Typography variant="body1" className="reminder-question">
              Do you want to send reminder to attend the remaining courses?{" "}
            </Typography>
            <Box className="modal-footer">
              <Button
                variant="contained"
                className="btn-yes"
                onClick={handleYesClick}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                className="btn-no"
                onClick={handleReminderClose}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default EducatorReminderModal;
