import {
  REPORT_LIST_REQUEST,
  REPORT_LIST_SUCCESS,
  REPORT_LIST_FAILURE,
  PRINT_ENROLMENT_REPORT_FAILURE,
  PRINT_ENROLMENT_REPORT_REQUEST,
  PRINT_ENROLMENT_REPORT_SUCCESS,
  GET_ENROLMENT_REPORT_STUDENT_DETAILS_REQUEST,
  GET_ENROLMENT_REPORT_STUDENT_DETAILS_FAILURE,
  GET_ENROLMENT_REPORT_STUDENT_DETAILS_SUCCESS,
} from "./actionTypes";

import { ReportActions, ReportState } from "./types";

const initialState: ReportState = {
  pending: false,
  reportList: [],
  error: null,
};

const reducers = (state = initialState, action: ReportActions): unknown => {
  switch (action.type) {
    case REPORT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REPORT_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        reportList: action.payload.reportList,
        error: null,
      };
    case REPORT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case PRINT_ENROLMENT_REPORT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PRINT_ENROLMENT_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        item: action.payload.item,
        error: null,
      };
    case PRINT_ENROLMENT_REPORT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_ENROLMENT_REPORT_STUDENT_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_ENROLMENT_REPORT_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        item: action.payload.enrolmentReportStudentDetailList,
        error: null,
      };
    case GET_ENROLMENT_REPORT_STUDENT_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
