import { documentPreviewIcon, closeIcon } from "assets/images";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import NoRecords from "components/NoRecords";
import {
  TableCell,
  Box,
  SelectChangeEvent,
  Typography,
  Grid,
  Card,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Select,
  MenuItem,
  Pagination,
  PaginationItem,
  InputAdornment,
  Fade,
  Tooltip,
} from "@mui/material";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NO,
  PAGE_SIZES,
  CLEAR_TOOLTIP_TITLE,
} from "utils/constants/constant";
import { AppPages } from "utils/enums/app-pages";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  hideLoaderForBatchApiCall,
  showLoaderForBatchApiCall,
  getPagePayloadValues,
  getPagePermissions,
  allowSearch,
  getPaginationDetailText,
  showLoader,
  onTextFieldKeyPress,
} from "utils/helper";
import {
  IDocumentContainerState,
  IEnhancedTableProps,
  IDocument,
  IDocumentListMeta,
  IAddEditDocument,
  IDocumentPagePayloadValues,
  IDocumentPreview,
  IEducatorDocumentContainerDispatch,
} from "utils/interfaces/document";
import projectTheme from "app.theme";
import React, { useState, useEffect, KeyboardEvent } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IRoleModulePermission } from "store/role/types";
import { DocumentListSuccessPayload } from "store/document/types";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IDocument) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type EducatorDocumentProps = IDocumentContainerState &
  IEducatorDocumentContainerDispatch;

const EducatorDocuments: React.FC<EducatorDocumentProps> = (props) => {
  const [documentList, setDocumentList] = useState<IDocument[]>();
  const [documentListMeta, setDocumentListMeta] = useState<IDocumentListMeta>();
  const [page, setPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IDocument>("name");
  const [searchText, setSearchText] = useState<string>("");
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [filteredData, setFilteredData] = useState<IAddEditDocument>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);

  const history = useHistory();
  const document = useSelector((state: any) => state?.auth.user);

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IDocument) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onDocumentListSuccess = (response: DocumentListSuccessPayload) => {
    setDocumentList(response.documentList);
    setDocumentListMeta(response.documentListPagination);
    hideLoaderForBatchApiCall();
  };

  const getDocumentList = async (
    filterData?: IAddEditDocument,
    filtered?: boolean,
    pageNum?: number
  ) => {
    const { documentListRequest } = props;
    if (documentListRequest) {
      showLoaderForBatchApiCall();

      const values: IDocumentPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.searchName = filtered ? "" : searchText.trim();
      values.type = "EducatorDocument";
      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onDocumentListSuccess,
      };
      documentListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      document?.permissions,
      AppPages.EducatorHome
    );
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getDocumentList(filteredData, true, 1);
      } else if (filteredData) {
        getDocumentList(filteredData);
      } else {
        getDocumentList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleDocumentSearch = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getDocumentList(filteredData, true, 1);
  };

  const handleDocumentSearchKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getDocumentList(filteredData);
    }
  };

  const onDocumentPreviewSuccess = (response: IDocumentPreview) => {
    if (response && response.documentDetails && response.documentDetails.path) {
      const url = response.documentDetails.path;
      window.open(url, "_blank");
    }
  };

  const handleDocumentPreview = (id: number) => {
    const { documentByIdRequest } = props;
    if (documentByIdRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(id),
        },
        callback: onDocumentPreviewSuccess,
      };
      documentByIdRequest(payload);
    }
  };

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Documents
        </Typography>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Card>
            <Box className="table-card-header">
              <Box
                sx={{ justifyContent: "space-between", width: "100%" }}
                className="search-filter-inputs"
              >
                <TextField
                  id="search-box"
                  variant="outlined"
                  className="search-input"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleDocumentSearch}
                  onKeyDown={handleDocumentSearchKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        sx={{ position: "absolute", right: "0" }}
                        position="end"
                      >
                        {searchText && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={CLEAR_TOOLTIP_TITLE}
                            arrow
                          >
                            <IconButton edge="end" onClick={handleClearSearch}>
                              <img src={closeIcon} alt="close" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) =>
                    onTextFieldKeyPress(event, searchText?.length)
                  }
                />
              </Box>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "name")}
                      fieldName="Document Name"
                      keyName="name"
                      sxStyle={{
                        maxWidth: "281px",
                        width: "281px",
                        minWidth: "281px",
                      }}
                    />
                    <TableCell
                      align="left"
                      sx={{
                        width: "245px",
                        minWidth: "245px",
                        [projectTheme.breakpoints.down("sm")]: {
                          width: "245px",
                        },
                      }}
                    >
                      Document Link
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        width: "355px",
                        [projectTheme.breakpoints.down("sm")]: {
                          width: "355px",
                        },
                      }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        width: "100px",
                        [projectTheme.breakpoints.down("sm")]: {
                          width: "112px",
                        },
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documentList?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        sx={{ wordWrap: "break-word" }}
                        component="th"
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" className="email">
                          {row.link}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" className="email">
                          {row.description || "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <div className="table-actions">
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Preview"
                            arrow
                          >
                            <IconButton
                              onClick={() => handleDocumentPreview(row.id)}
                              disabled={!pagePermission?.view}
                              className={
                                !pagePermission?.edit ? "disabled-icon" : ""
                              }
                            >
                              <img src={documentPreviewIcon} alt="edit" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {documentList && documentList.length > 0 ? (
              <Box className="custom-pagination">
                <Box className="custom-rowperpage">
                  <Typography variant="body2" component="span">
                    Page:
                  </Typography>
                  <Select
                    id="pagination-select"
                    value={page}
                    onChange={handleChange}
                    MenuProps={{
                      className: "pagination-menu",
                    }}
                  >
                    {PAGE_SIZES?.map((pageSize) => (
                      <MenuItem
                        key={pageSize.value}
                        value={pageSize.value}
                        selected={pageSize?.selected}
                      >
                        {pageSize.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="body2" component="span">
                    {getPaginationDetailText(documentListMeta, pageNo, page)}
                  </Typography>{" "}
                </Box>
                <Pagination
                  count={documentListMeta?.totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={pageNo}
                  onChange={onPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                    />
                  )}
                />
              </Box>
            ) : (
              <NoRecords />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EducatorDocuments;
