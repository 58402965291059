import { Box } from "@mui/system";
import {
  Button,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { useForm } from "react-hook-form";
import { IAddEditCourse } from "utils/interfaces/course";
import { useEffect } from "react";
import {
  CLOSE_TOOLTIP_TITLE,
  COURSE_STREAM,
  DEFAULT_PAGE_NO,
} from "utils/constants/constant";

interface ICourseFilterProps {
  filterBox: boolean;
  setFilterBox: any;
  handleApplyClick: any;
  filteredData?: IAddEditCourse;
  pageNo: number;
  setPageNo: any;
  setFilteredData: any;
  getCourseList: any;
  setAppliedFilter: any;
}

const CourseFilter: React.FC<ICourseFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getCourseList,
  setAppliedFilter,
}) => {
  const { register, handleSubmit, reset, setValue, watch } =
    useForm<IAddEditCourse>({
      defaultValues: {
        stream: "",
      },
    });

  useEffect(() => {
    if (filteredData) {
      setValue("courseNo", filteredData?.courseNo);
      setValue("stream", filteredData?.stream);
    } else {
      reset();
    }
  }, [filteredData, setValue, filterBox, reset]);

  const onClearClick = () => {
    reset();
    setFilterBox(false);
    setAppliedFilter(false);
    document.body.classList.toggle("filter-open");
    if (pageNo === DEFAULT_PAGE_NO) {
      getCourseList();
    } else {
      setPageNo(1);
    }
    setFilteredData();
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setFilterBox(false);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="select">
              <InputLabel
                id="stream-select-label"
                shrink={watch("stream") !== ""}
              >
                Stream{" "}
              </InputLabel>
              <Select
                labelId="stream-select-label"
                id="stream-select"
                label="Stream "
                value={watch("stream")}
                {...register("stream")}
              >
                {COURSE_STREAM?.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-clear"
              onClick={onClearClick}
              disabled={!(watch("courseNo") || watch("stream"))}
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CourseFilter;
