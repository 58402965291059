import {
  EDUCATOR_LIST_REQUEST,
  EDUCATOR_LIST_FAILURE,
  EDUCATOR_LIST_SUCCESS,
  ADD_EDUCATOR_REQUEST,
  ADD_EDUCATOR_SUCCESS,
  ADD_EDUCATOR_FAILURE,
  EDIT_EDUCATOR_REQUEST,
  EDIT_EDUCATOR_SUCCESS,
  EDIT_EDUCATOR_FAILURE,
  DELETE_EDUCATOR_REQUEST,
  DELETE_EDUCATOR_SUCCESS,
  DELETE_EDUCATOR_FAILURE,
  EDUCATOR_BY_ID_REQUEST,
  EDUCATOR_BY_ID_SUCCESS,
  EDUCATOR_BY_ID_FAILURE,
  COURSES_DELIVERED_LIST_REQUEST,
  COURSES_DELIVERED_LIST_SUCCESS,
  COURSES_DELIVERED_LIST_FAILURE,
  COURSES_ENROLED_LIST_REQUEST,
  COURSES_ENROLED_LIST_FAILURE,
  COURSES_ENROLED_LIST_SUCCESS,
  SEND_REMINDER_TO_EDUCATOR_REQUEST,
  SEND_REMINDER_TO_EDUCATOR_SUCCESS,
  SEND_REMINDER_TO_EDUCATOR_FAILURE,
  EDUCATOR_STATISTICS_DATA_FAILURE,
  EDUCATOR_STATISTICS_DATA_REQUEST,
  EDUCATOR_STATISTICS_DATA_SUCCESS,
} from "./actionTypes";
import {
  EducatorListPayload,
  EducatorListRequest,
  EducatorListSuccess,
  EducatorListSuccessPayload,
  EducatorListFailure,
  EducatorListFailurePayload,
  DeleteEducatorPayload,
  DeleteEducatorRequest,
  DeleteEducatorSuccessPayload,
  DeleteEducatorSuccess,
  DeleteEducatorFailurePayload,
  DeleteEducatorFailure,
  AddEditEducatorPayload,
  AddEducatorRequest,
  AddEditEducatorSuccessPayload,
  AddEducatorSuccess,
  AddEditEducatorFailurePayload,
  AddEducatorFailure,
  EditEducatorSuccess,
  EditEducatorRequest,
  EditEducatorFailure,
  EducatorByIdPayload,
  EducatorByIdRequest,
  EducatorByIdSuccessPayload,
  EducatorByIdSuccess,
  EducatorByIdFailurePayload,
  EducatorByIdFailure,
  CoursesDeliveredListPayload,
  CoursesDeliveredListSuccessPayload,
  CoursesDeliveredListRequest,
  CoursesDeliveredListSuccess,
  CoursesDeliveredListFailurePayload,
  CoursesDeliveredListFailure,
  CoursesEnroledListPayload,
  CoursesEnroledListRequest,
  CoursesEnroledListSuccess,
  CoursesEnroledListSuccessPayload,
  CoursesEnroledListFailurePayload,
  CoursesEnroledListFailure,
  SendReminderToEducatorPayload,
  SendReminderToEducatorRequest,
  SendReminderToEducatorSuccessPayload,
  SendReminderToEducatorSuccess,
  SendReminderToEducatorFailurePayload,
  SendReminderToEducatorFailure,
  EducatorStatisticsDataFailure,
  EducatorStatisticsDataFailurePayload,
  EducatorStatisticsDataPayload,
  EducatorStatisticsDataRequest,
  EducatorStatisticsDataSuccess,
  EducatorStatisticsDataSuccessPayload,
} from "./types";

export const educatorListRequest = (
  payload: EducatorListPayload
): EducatorListRequest => ({
  type: EDUCATOR_LIST_REQUEST,
  payload,
});

export const educatorListSuccess = (
  payload: EducatorListSuccessPayload
): EducatorListSuccess => ({
  type: EDUCATOR_LIST_SUCCESS,
  payload,
});

export const educatorListFailure = (
  payload: EducatorListFailurePayload
): EducatorListFailure => ({
  type: EDUCATOR_LIST_FAILURE,
  payload,
});

export const addEducatorRequest = (
  payload: AddEditEducatorPayload
): AddEducatorRequest => ({
  type: ADD_EDUCATOR_REQUEST,
  payload,
});

export const addEducatorSuccess = (
  payload: AddEditEducatorSuccessPayload
): AddEducatorSuccess => ({
  type: ADD_EDUCATOR_SUCCESS,
  payload,
});

export const addEducatorFailure = (
  payload: AddEditEducatorFailurePayload
): AddEducatorFailure => ({
  type: ADD_EDUCATOR_FAILURE,
  payload,
});

export const editEducatorRequest = (
  payload: AddEditEducatorPayload
): EditEducatorRequest => ({
  type: EDIT_EDUCATOR_REQUEST,
  payload,
});

export const editEducatorSuccess = (
  payload: AddEditEducatorSuccessPayload
): EditEducatorSuccess => ({
  type: EDIT_EDUCATOR_SUCCESS,
  payload,
});

export const editEducatorFailure = (
  payload: AddEditEducatorFailurePayload
): EditEducatorFailure => ({
  type: EDIT_EDUCATOR_FAILURE,
  payload,
});

export const deleteEducatorRequest = (
  payload: DeleteEducatorPayload
): DeleteEducatorRequest => ({
  type: DELETE_EDUCATOR_REQUEST,
  payload,
});

export const deleteEducatorSuccess = (
  payload: DeleteEducatorSuccessPayload
): DeleteEducatorSuccess => ({
  type: DELETE_EDUCATOR_SUCCESS,
  payload,
});

export const deleteEducatorFailure = (
  payload: DeleteEducatorFailurePayload
): DeleteEducatorFailure => ({
  type: DELETE_EDUCATOR_FAILURE,
  payload,
});

export const educatorByIdRequest = (
  payload: EducatorByIdPayload
): EducatorByIdRequest => ({
  type: EDUCATOR_BY_ID_REQUEST,
  payload,
});

export const educatorByIdSuccess = (
  payload: EducatorByIdSuccessPayload
): EducatorByIdSuccess => ({
  type: EDUCATOR_BY_ID_SUCCESS,
  payload,
});

export const educatorByIdFailure = (
  payload: EducatorByIdFailurePayload
): EducatorByIdFailure => ({
  type: EDUCATOR_BY_ID_FAILURE,
  payload,
});

export const coursesDeliveredListRequest = (
  payload: CoursesDeliveredListPayload
): CoursesDeliveredListRequest => ({
  type: COURSES_DELIVERED_LIST_REQUEST,
  payload,
});

export const coursesDeliveredListSuccess = (
  payload: CoursesDeliveredListSuccessPayload
): CoursesDeliveredListSuccess => ({
  type: COURSES_DELIVERED_LIST_SUCCESS,
  payload,
});

export const coursesDeliveredListFailure = (
  payload: CoursesDeliveredListFailurePayload
): CoursesDeliveredListFailure => ({
  type: COURSES_DELIVERED_LIST_FAILURE,
  payload,
});

export const coursesEnroledListRequest = (
  payload: CoursesEnroledListPayload
): CoursesEnroledListRequest => ({
  type: COURSES_ENROLED_LIST_REQUEST,
  payload,
});

export const coursesEnroledListSuccess = (
  payload: CoursesEnroledListSuccessPayload
): CoursesEnroledListSuccess => ({
  type: COURSES_ENROLED_LIST_SUCCESS,
  payload,
});

export const coursesEnroledListFailure = (
  payload: CoursesEnroledListFailurePayload
): CoursesEnroledListFailure => ({
  type: COURSES_ENROLED_LIST_FAILURE,
  payload,
});

export const sendReminderToEducatorRequest = (
  payload: SendReminderToEducatorPayload
): SendReminderToEducatorRequest => ({
  type: SEND_REMINDER_TO_EDUCATOR_REQUEST,
  payload,
});

export const sendReminderToEducatorSuccess = (
  payload: SendReminderToEducatorSuccessPayload
): SendReminderToEducatorSuccess => ({
  type: SEND_REMINDER_TO_EDUCATOR_SUCCESS,
  payload,
});

export const sendReminderToEducatorFailure = (
  payload: SendReminderToEducatorFailurePayload
): SendReminderToEducatorFailure => ({
  type: SEND_REMINDER_TO_EDUCATOR_FAILURE,
  payload,
});

export const educatorStatisticsDataRequest = (
  payload: EducatorStatisticsDataPayload
): EducatorStatisticsDataRequest => ({
  type: EDUCATOR_STATISTICS_DATA_REQUEST,
  payload,
});

export const educatorStatisticsDataSuccess = (
  payload: EducatorStatisticsDataSuccessPayload
): EducatorStatisticsDataSuccess => ({
  type: EDUCATOR_STATISTICS_DATA_SUCCESS,
  payload,
});

export const educatorStatisticsDataFailure = (
  payload: EducatorStatisticsDataFailurePayload
): EducatorStatisticsDataFailure => ({
  type: EDUCATOR_STATISTICS_DATA_FAILURE,
  payload,
});
