import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  VALIDATE_RESET_PASSWORD_URL_REQUEST,
  VALIDATE_RESET_PASSWORD_URL_SUCCESS,
  VALIDATE_RESET_PASSWORD_URL_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_USER,
} from "./actionTypes";

import { AuthActions, AuthState } from "./types";

const initialState: AuthState = {
  pending: false,
  token: "",
  user: {},
  error: null,
};

const reducers = (state = initialState, action: AuthActions): unknown => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
        token: action.payload.token,
        user: action.payload.user,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        token: "",
        error: action.payload.error,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        pending: false,
        token: action.payload.token,
        error: null,
      };
    case REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case VALIDATE_RESET_PASSWORD_URL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case VALIDATE_RESET_PASSWORD_URL_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case VALIDATE_RESET_PASSWORD_URL_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_USER:
      return {
        ...state,
        pending: false,
        user: {
          ...state.user,
          firstName: action.payload.values.firstName,
          lastName: action.payload.values.lastName,
          address: action.payload.values.address,
        },
        error: null,
      };
    default:
      return state;
  }
};

export default reducers;
