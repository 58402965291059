import { Box, Typography, Grid, Card, Button } from "@mui/material";

const SLPReport = () => {
  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Report - SLP
        </Typography>
        <Button variant="outlined" className="btn-export">
          Export
        </Button>
      </Box>
      <Grid
        sx={{ height: "738px" }}
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Card sx={{ alignContent: "center", textAlign: "center" }}>
            Coming Soon
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default SLPReport;
