import { IAddEditCourseReflectionForm } from "utils/interfaces/educator-home";
import baseService from "services/base-service";

const educatorHomeBaseUrl = `/api/EducatorHome`;

const getCurrentCourseList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  course?: string;
  termId?: number;
}) => baseService.post(`${educatorHomeBaseUrl}/GetCurrentCourses`, payload);

const getPastCourseList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
}) => baseService.post(`${educatorHomeBaseUrl}/GetPastCourses`, payload);

const getScheduledOfferingDetails = async (payload: {
  id: number;
  userId: number;
}) =>
  baseService.get(
    `${educatorHomeBaseUrl}/GetCourseOfferingDetails?id=${payload.id}&userId=${payload.userId}`
  );

const addCourseReflectionForm = async (payload: IAddEditCourseReflectionForm) =>
  baseService.post(
    `${educatorHomeBaseUrl}/AddEducatorCourseReflections`,
    payload
  );

const editCourseReflectionForm = async (
  payload: IAddEditCourseReflectionForm
) =>
  baseService.put(
    `${educatorHomeBaseUrl}/UpdateEducatorCourseReflections`,
    payload
  );

const getEnroledStudentForEducatorHome = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
}) =>
  baseService.post(
    `${educatorHomeBaseUrl}/GetEnroledStudentsDetails?id=${payload.id}`,
    payload
  );

const getMissingAttendanceCourseList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
}) =>
  baseService.post(
    `${educatorHomeBaseUrl}/GetMissingAttendanceCourses?id=${payload.id}`,
    payload
  );

export default {
  getCurrentCourseList,
  getPastCourseList,
  getScheduledOfferingDetails,
  addCourseReflectionForm,
  editCourseReflectionForm,
  getEnroledStudentForEducatorHome,
  getMissingAttendanceCourseList,
};
