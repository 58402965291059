export const TERM_LIST_REQUEST = "TERM_LIST_REQUEST";
export const TERM_LIST_SUCCESS = "TERM_LIST_SUCCESS";
export const TERM_LIST_FAILURE = "TERM_LIST_FAILURE";

export const ADD_TERM_REQUEST = "ADD_TERM_REQUEST";
export const ADD_TERM_SUCCESS = "ADD_TERM_SUCCESS";
export const ADD_TERM_FAILURE = "ADD_TERM_FAILURE";

export const EDIT_TERM_REQUEST = "EDIT_TERM_REQUEST";
export const EDIT_TERM_SUCCESS = "EDIT_TERM_SUCCESS";
export const EDIT_TERM_FAILURE = "EDIT_TERM_FAILURE";

export const DELETE_TERM_REQUEST = "DELETE_TERM_REQUEST";
export const DELETE_TERM_SUCCESS = "DELETE_TERM_SUCCESS";
export const DELETE_TERM_FAILURE = "DELETE_TERM_FAILURE";

export const TERM_BY_ID_REQUEST = "TERM_BY_ID_REQUEST";
export const TERM_BY_ID_SUCCESS = "TERM_BY_ID_SUCCESS";
export const TERM_BY_ID_FAILURE = "TERM_BY_ID_FAILURE";
