import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  USER_BY_ID_REQUEST,
  USER_BY_ID_SUCCESS,
  USER_BY_ID_FAILURE,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_REQUEST,
} from "./actionTypes";
import { UserState, UserActions } from "./types";

const initialState: UserState = {
  pending: false,
  userList: [],
  error: null,
};

const reducers = (state = initialState, action: UserActions): unknown => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        userList: action.payload.userList,
        error: null,
      };
    case USER_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_USER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_USER_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_USER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_USER_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case USER_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case USER_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        userDetails: action.payload.userDetails,
        error: null,
      };
    case USER_BY_ID_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
