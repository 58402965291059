export const REPORT_LIST_REQUEST = "REPORT_LIST_REQUEST";
export const REPORT_LIST_SUCCESS = "REPORT_LIST_SUCCESS";
export const REPORT_LIST_FAILURE = "REPORT_LIST_FAILURE";

export const PRINT_ENROLMENT_REPORT_REQUEST = "PRINT_ENROLMENT_REPORT_REQUEST";
export const PRINT_ENROLMENT_REPORT_SUCCESS = "PRINT_ENROLMENT_REPORT_SUCCESS";
export const PRINT_ENROLMENT_REPORT_FAILURE = "PRINT_ENROLMENT_REPORT_FAILURE";

export const GET_ENROLMENT_REPORT_STUDENT_DETAILS_REQUEST =
  "GET_ENROLMENT_REPORT_STUDENT_DETAILS_REQUEST";
export const GET_ENROLMENT_REPORT_STUDENT_DETAILS_SUCCESS =
  "GET_ENROLMENT_REPORT_STUDENT_DETAILS_SUCCESS";
export const GET_ENROLMENT_REPORT_STUDENT_DETAILS_FAILURE =
  "GET_ENROLMENT_REPORT_STUDENT_DETAILS_FAILURE";
