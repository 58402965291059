import { all, call, put, takeLatest } from "redux-saga/effects";
import documentService from "./service";
import {
  documentListSuccess,
  documentListFailure,
  addDocumentSuccess,
  addDocumentFailure,
  editDocumentSuccess,
  editDocumentFailure,
  documentByIdSuccess,
  documentByIdFailure,
  deleteDocumentSuccess,
  deleteDocumentFailure,
} from "./action";
import {
  DocumentListResponse,
  AddEditDocumentResponse,
  DocumentByIdResponse,
  DeleteDocumentResponse,
} from "./types";
import {
  ADD_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_REQUEST,
  DOCUMENT_BY_ID_REQUEST,
  DOCUMENT_LIST_REQUEST,
  EDIT_DOCUMENT_REQUEST,
} from "./actionTypes";

function* documentListSaga(action: any) {
  try {
    const payload: any = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      searchName: action.payload.values.searchName,
      type: action.payload.values.type,
    };
    const response: DocumentListResponse = yield call(
      documentService.getDocumentList,
      payload
    );
    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };
    yield put(
      documentListSuccess({
        documentList: response.item.records,
        documentListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      documentList: response.item.records,
      documentListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      documentListFailure({
        error: e.message,
      })
    );
  }
}

function* addDocumentSaga(action: any) {
  try {
    const response: AddEditDocumentResponse = yield call(
      documentService.addDocument,
      {
        id: action.payload.values.id,
        name: action.payload.values.name,
        type: action.payload.values.type,
        link: action.payload.values.link,
        description: action.payload.values.description,
        document: action.payload.values.document,
      }
    );

    yield put(
      addDocumentSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addDocumentFailure({
        error: e.message,
      })
    );
  }
}

function* editDocumentSaga(action: any) {
  try {
    const response: AddEditDocumentResponse = yield call(
      documentService.editDocument,
      {
        id: action.payload.values.id,
        name: action.payload.values.name,
        type: action.payload.values.type,
        description: action.payload.values.description,
        link: action.payload.values.link,
      }
    );

    yield put(
      editDocumentSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editDocumentFailure({
        error: e.message,
      })
    );
  }
}

function* documentByIdSaga(action: any) {
  try {
    const response: DocumentByIdResponse = yield call(
      documentService.getDocumentById,
      {
        id: action.payload.values.id,
      }
    );
    yield put(
      documentByIdSuccess({
        documentDetails: response.item,
      })
    );
    action.payload.callback({
      documentDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      documentByIdFailure({
        error: e.message,
      })
    );
  }
}

function* deleteDocumentSaga(action: any) {
  try {
    const response: DeleteDocumentResponse = yield call(
      documentService.deleteDocument,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      deleteDocumentSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteDocumentFailure({
        error: e.message,
      })
    );
  }
}

function* documentSaga() {
  yield all([takeLatest(DOCUMENT_LIST_REQUEST, documentListSaga)]);
  yield all([takeLatest(ADD_DOCUMENT_REQUEST, addDocumentSaga)]);
  yield all([takeLatest(EDIT_DOCUMENT_REQUEST, editDocumentSaga)]);
  yield all([takeLatest(DOCUMENT_BY_ID_REQUEST, documentByIdSaga)]);
  yield all([takeLatest(DELETE_DOCUMENT_REQUEST, deleteDocumentSaga)]);
}

export default documentSaga;
