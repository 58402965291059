import {
  STATISTICS_DATA_REQUEST,
  STATISTICS_DATA_SUCCESS,
  STATISTICS_DATA_FAILURE,
} from "./actionTypes";
import {
  StatisticsDataPayload,
  StatisticsDataRequest,
  StatisticsDataSuccess,
  StatisticsDataSuccessPayload,
  StatisticsDataFailure,
  StatisticsDataFailurePayload,
} from "./types";

export const statisticsDataRequest = (
  payload: StatisticsDataPayload
): StatisticsDataRequest => ({
  type: STATISTICS_DATA_REQUEST,
  payload,
});

export const statisticsDataSuccess = (
  payload: StatisticsDataSuccessPayload
): StatisticsDataSuccess => ({
  type: STATISTICS_DATA_SUCCESS,
  payload,
});

export const statisticsDataFailure = (
  payload: StatisticsDataFailurePayload
): StatisticsDataFailure => ({
  type: STATISTICS_DATA_FAILURE,
  payload,
});
