/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import {
  STAFF_ENROLMENT_OPTIONS,
  STUDENT_ENROLMENT_COB_MAX_LENGTH,
  STUDENT_ENROLMENT_COMMENT_MAX_LENGTH,
  STUDENT_ENROLMENT_CONTACT_PERSON_MAX_LENGTH,
  STUDENT_ENROLMENT_ETHNICITY_MAX_LENGTH,
  STUDENT_ENROLMENT_LAH_MAX_LENGTH,
  STUDENT_ENROLMENT_MANAGER_EMAIL_MAX_LENGTH,
  STUDENT_ENROLMENT_MANAGER_NAME_MAX_LENGTH,
  STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH,
  STUDENT_ENROLMENT_RELATIONSHIP_MAX_LENGTH,
  STUDENT_ENROLMENT_STAFF_LINK_ID_MAX_LENGTH,
} from "utils/constants/student-enrolment";
import { FormSteps, StaffEnrolment } from "utils/enums/student-enrolment";
import { Controller, useForm } from "react-hook-form";
import {
  IEnrolmentOptions,
  IStep3Details,
} from "utils/interfaces/student-enrolment";
import {
  EmailPattern,
  handlePasteNumberField,
  onNumberFieldKeyPress,
  onTextFieldKeyPress,
} from "utils/helper";
import {
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
} from "utils/constants/constant";
import {
  WHITE_SPACE_ERROR_SPACE,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER,
} from "utils/constants/Messages";
import RadioCheckedBoxIcon from "components/RadioCheckedBoxIcon";
import RadioCheckBoxIcon from "components/RadioCheckBoxIcon";
import FormStepButtons from "../FormStepButtons";

interface IFormStep3Props {
  movePrev: (step: number, data: any, isNext: boolean) => void;
  moveNext: (step: number, data: any, isNext: boolean) => void;
  stepDetails: { data: IStep3Details };
  enrolmentOptionList: IEnrolmentOptions | undefined;
}

const FormStep3: React.FC<IFormStep3Props> = (props) => {
  const { movePrev, moveNext, stepDetails, enrolmentOptionList } = props;

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IStep3Details>({
    defaultValues: {
      currentServiceProviders: null,
      emergencyContactName: null,
      emergencyContactRelationship: null,
      emergencyContactNumber: null,
      comment: null,
      enrolmentType: null,
      staffEnrolment: null,
      staffEmploymentStatus: null,
      currentRole: null,
      workEnvironment: null,
      site: null,
      nameOfManager: null,
      emailOfManager: null,
      whereAboutUs: null,
      identity: null,
      disability: null,
      countryOfBirth: null,
      languageAtHome: null,
      ethnicity: null,
      genderIdentity: null,
      qualifications: null,
      employmentStatus: null,
      highestCompletedSchoolLevel: null,
      currentlyStudying: null,
      studyLoad: null,
      staffLinkId: null,
    },
  });

  const setFormValues = (data: any) => {
    reset((formValues) => ({
      ...formValues,
      currentServiceProviders: data.currentServiceProviders,
      emergencyContactName: data.emergencyContactName,
      emergencyContactRelationship: data.emergencyContactRelationship,
      emergencyContactNumber: data.emergencyContactNumber,
      comment: data.comment,
      enrolmentType: data.enrolmentType,
      staffEnrolment: data.staffEnrolment,
      staffEmploymentStatus: data.staffEmploymentStatus,
      currentRole: data.currentRole,
      workEnvironment: data.workEnvironment,
      site: data.site,
      nameOfManager: data.nameOfManager,
      emailOfManager: data.emailOfManager,
      whereAboutUs: data.whereAboutUs,
      identity: data.identity,
      disability: data.disability,
      countryOfBirth: data.countryOfBirth,
      languageAtHome: data.languageAtHome,
      ethnicity: data.ethnicity,
      genderIdentity: data.genderIdentity,
      qualifications: data.qualifications,
      employmentStatus: data.employmentStatus,
      highestCompletedSchoolLevel: data.highestCompletedSchoolLevel,
      currentlyStudying: data.currentlyStudying,
      studyLoad: data.studyLoad,
      staffLinkId: data.staffLinkId,
    }));
  };

  useEffect(() => {
    if (stepDetails && stepDetails.data) {
      setFormValues(stepDetails.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const staffEnrolment = watch("staffEnrolment")?.toString();

  const handleBack = () => {
    const stepData = {
      data: getValues(),
    };

    movePrev(FormSteps.StudentDetails, stepData, false);
  };

  const resetFormValues = (isStaffEnrolment: boolean) => {
    if (isStaffEnrolment === true) {
      reset((formValues) => ({
        ...formValues,
        whereAboutUs: null,
        identity: null,
        disability: null,
        countryOfBirth: null,
        languageAtHome: null,
        ethnicity: null,
        genderIdentity: null,
        qualifications: null,
        employmentStatus: null,
        highestCompletedSchoolLevel: null,
        currentlyStudying: null,
        studyLoad: null,
      }));
    } else {
      reset((formValues) => ({
        ...formValues,
        staffEmploymentStatus: null,
        currentRole: null,
        workEnvironment: null,
        site: null,
        nameOfManager: null,
        emailOfManager: null,
        staffLinkId: null,
      }));
    }
  };

  useEffect(() => {
    resetFormValues(watch("staffEnrolment") === StaffEnrolment.Staff);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("staffEnrolment")]);

  useEffect(() => {
    if (watch("currentServiceProviders")) {
      trigger("currentServiceProviders");
    }
    if (watch("enrolmentType")) {
      if (watch("enrolmentType")?.toString() === "Staff") {
        setValue("staffEnrolment", StaffEnrolment.Staff);
      } else setValue("staffEnrolment", StaffEnrolment.NonStaff);
      trigger("enrolmentType");
    }
    if (watch("staffEmploymentStatus")) {
      trigger("staffEmploymentStatus");
    }
    if (watch("currentRole")) {
      trigger("currentRole");
    }
    if (watch("workEnvironment")) {
      trigger("workEnvironment");
    }
    if (watch("site")) {
      trigger("site");
    }
    if (watch("whereAboutUs")) {
      trigger("whereAboutUs");
    }
    if (watch("identity")) {
      trigger("identity");
    }
    if (watch("disability")) {
      trigger("disability");
    }
    if (watch("genderIdentity")) {
      trigger("genderIdentity");
    }
    if (watch("qualifications")) {
      trigger("qualifications");
    }
    if (watch("employmentStatus")) {
      trigger("employmentStatus");
    }
    if (watch("highestCompletedSchoolLevel")) {
      trigger("highestCompletedSchoolLevel");
    }
    if (watch("currentlyStudying")) {
      trigger("currentlyStudying");
    }
    if (watch("studyLoad")) {
      trigger("studyLoad");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watch("currentServiceProviders"),
    watch("enrolmentType"),
    watch("staffEmploymentStatus"),
    watch("currentRole"),
    watch("workEnvironment"),
    watch("site"),
    watch("whereAboutUs"),
    watch("identity"),
    watch("disability"),
    watch("genderIdentity"),
    watch("qualifications"),
    watch("employmentStatus"),
    watch("highestCompletedSchoolLevel"),
    watch("currentlyStudying"),
    watch("studyLoad"),
  ]);

  const getCurrentServiceProviderError = (): string => {
    if (errors.currentServiceProviders) {
      if (errors.currentServiceProviders.type === "required") {
        return "Current service providers is required";
      }
    }

    return "";
  };

  const getEmergencyContactNameError = (): string => {
    if (errors.emergencyContactName) {
      if (errors.emergencyContactName.type === "required") {
        return "Name of contact person is required";
      }
      if (errors.emergencyContactName.type === "maxLength") {
        return `Maximum length of name of contact person should be ${STUDENT_ENROLMENT_CONTACT_PERSON_MAX_LENGTH}`;
      }
      if (errors.emergencyContactName.type === "pattern") {
        return `Name of contact person ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getRelationshipError = (): string => {
    if (errors.emergencyContactRelationship) {
      if (errors.emergencyContactRelationship.type === "required") {
        return "Relationship is required";
      }
      if (errors.emergencyContactRelationship.type === "maxLength") {
        return `Maximum length of relationship should be ${STUDENT_ENROLMENT_RELATIONSHIP_MAX_LENGTH}`;
      }
      if (errors.emergencyContactRelationship.type === "pattern") {
        return `Relationship ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getPhoneNumberError = (): string => {
    if (errors.emergencyContactNumber) {
      if (errors.emergencyContactNumber.type === "required") {
        return "Phone number is required";
      }
      if (errors.emergencyContactNumber.type === "maxLength") {
        return `Maximum length of phone number should be ${STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getCommentError = (): string => {
    if (errors.comment) {
      if (errors.comment.type === "maxLength") {
        return `Maximum length of comment should be ${STUDENT_ENROLMENT_COMMENT_MAX_LENGTH}`;
      }
      if (errors.comment.type === "pattern") {
        return `Comment ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getLanguageAtHomeError = (): string => {
    if (errors.languageAtHome) {
      if (errors.languageAtHome.type === "maxLength") {
        return `Maximum length of language at home should be ${STUDENT_ENROLMENT_LAH_MAX_LENGTH}`;
      }
      if (errors.languageAtHome.type === "pattern") {
        return `Language at home ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getEthnicityError = (): string => {
    if (errors.ethnicity) {
      if (errors.ethnicity.type === "maxLength") {
        return `Maximum length of ethnicity should be ${STUDENT_ENROLMENT_ETHNICITY_MAX_LENGTH}`;
      }
      if (errors.ethnicity.type === "pattern") {
        return `Ethnicity should be ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getEnrolmentTypeError = (): string => {
    if (errors.enrolmentType) {
      if (errors.enrolmentType.type === "required") {
        return "Enrolment type is required";
      }
    }

    return "";
  };

  const getStaffEmploymentStatusError = (): string => {
    if (errors.staffEmploymentStatus) {
      if (errors.staffEmploymentStatus.type === "required") {
        return "Employment status is required";
      }
    }

    return "";
  };

  const getCurrentRoleError = (): string => {
    if (errors.currentRole) {
      if (errors.currentRole.type === "required") {
        return "Current role is required";
      }
    }

    return "";
  };

  const getWorkEnvironmentError = (): string => {
    if (errors.workEnvironment) {
      if (errors.workEnvironment.type === "required") {
        return "Work environment is required";
      }
    }

    return "";
  };

  const getSiteServiceError = (): string => {
    if (errors.site) {
      if (errors.site.type === "required") {
        return "Site/Service is required";
      }
    }

    return "";
  };

  const getManagerNameError = (): string => {
    if (errors.nameOfManager) {
      if (errors.nameOfManager.type === "maxLength") {
        return `Maximum length of manager's name should be ${STUDENT_ENROLMENT_MANAGER_NAME_MAX_LENGTH}`;
      }
      if (errors.nameOfManager.type === "pattern") {
        return `Manager's name ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getManagerEmailError = (): string => {
    if (errors.emailOfManager) {
      if (errors.emailOfManager.type === "pattern") {
        return "Enter valid email";
      }
      if (errors.emailOfManager.type === "maxLength") {
        return `Maximum length of manager's email should be ${STUDENT_ENROLMENT_MANAGER_EMAIL_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getWhereAboutUsError = (): string => {
    if (errors.whereAboutUs) {
      if (errors.whereAboutUs.type === "required") {
        return "How did you hear about the recovery & wellbeing college is required";
      }
    }

    return "";
  };

  const getIdentityError = (): string => {
    if (errors.identity) {
      if (errors.identity.type === "required") {
        return "Aboriginal/Torres strait islander identity is required";
      }
    }

    return "";
  };

  const getDisabilityError = (): string => {
    if (errors.disability) {
      if (errors.disability.type === "required") {
        return "Disability is required";
      }
    }

    return "";
  };

  const getCountryOfBirthError = (): string => {
    if (errors.countryOfBirth) {
      if (errors.countryOfBirth.type === "required") {
        return "Country of birth is required";
      }
      if (errors.countryOfBirth.type === "maxLength") {
        return `Maximum length of country of birth should be ${STUDENT_ENROLMENT_COB_MAX_LENGTH}`;
      }
      if (errors.countryOfBirth.type === "pattern") {
        return `Country of birth ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getGenderIdentityError = (): string => {
    if (errors.genderIdentity) {
      if (errors.genderIdentity.type === "required") {
        return "Gender identity is required";
      }
    }

    return "";
  };

  const getQualificationsError = (): string => {
    if (errors.qualifications) {
      if (errors.qualifications.type === "required") {
        return "Completed qualifications is required";
      }
    }

    return "";
  };

  const getEmploymentStatusError = (): string => {
    if (errors.employmentStatus) {
      if (errors.employmentStatus.type === "required") {
        return "Employment status is required";
      }
    }

    return "";
  };

  const getHighestCompletedSchoolError = (): string => {
    if (errors.highestCompletedSchoolLevel) {
      if (errors.highestCompletedSchoolLevel.type === "required") {
        return "Highest completed school level is required";
      }
    }

    return "";
  };

  const getCurrentlyStudyingError = (): string => {
    if (errors.currentlyStudying) {
      if (errors.currentlyStudying.type === "required") {
        return "Currently studying is required";
      }
    }

    return "";
  };

  const getStudyLoadError = (): string => {
    if (errors.studyLoad) {
      if (errors.studyLoad.type === "required") {
        return "Study load is required";
      }
    }

    return "";
  };

  const getStaffLinkIdError = (): string => {
    if (errors.staffLinkId) {
      if (errors.staffLinkId.type === "maxLength") {
        return `Max value of Staff Link Id can be ${STUDENT_ENROLMENT_STAFF_LINK_ID_MAX_LENGTH}`;
      }
      if (errors.staffLinkId.type === "max") {
        return "Max value of Staff Link Id can be '2147483647'";
      }
    }

    return "";
  };
  const onSubmit = (data: any) => {
    const stepData = {
      data,
    };

    moveNext(FormSteps.CourseSelection, stepData, true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="stepper-card step-3">
        <Typography
          variant="h4"
          className="questions"
          sx={{ marginBottom: "12px !important" }}
        >
          Emergency Contacts
        </Typography>
        <Grid container columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="name"
              label={
                <>
                  Name Of Contact Person <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emergencyContactName")),
              }}
              error={!!errors.emergencyContactName}
              helperText={getEmergencyContactNameError()}
              onKeyPress={(event) =>
                onTextFieldKeyPress(
                  event,
                  watch("emergencyContactName")?.length
                )
              }
              {...register("emergencyContactName", {
                required: true,
                maxLength: STUDENT_ENROLMENT_CONTACT_PERSON_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="relationship"
              label={
                <>
                  Relationship <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emergencyContactRelationship")),
              }}
              error={!!errors.emergencyContactRelationship}
              helperText={getRelationshipError()}
              onKeyPress={(event) =>
                onTextFieldKeyPress(
                  event,
                  watch("emergencyContactRelationship")?.length
                )
              }
              {...register("emergencyContactRelationship", {
                required: true,
                maxLength: STUDENT_ENROLMENT_RELATIONSHIP_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="phone-num-2"
              type="number"
              label={
                <>
                  Phone Number <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emergencyContactNumber")),
              }}
              error={!!errors.emergencyContactNumber}
              helperText={getPhoneNumberError()}
              onKeyPress={onNumberFieldKeyPress}
              onPaste={handlePasteNumberField}
              {...register("emergencyContactNumber", {
                required: true,
                maxLength: STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH,
              })}
            />
          </Grid>
        </Grid>
        <Typography variant="h4" className="questions">
          Information to help us support you
        </Typography>
        <Typography variant="body2" className="instruction">
          Do you have any specific learning or support requirement of which you
          would like the college to be aware? &#40;optional&#41;
        </Typography>
        <TextField
          id="comment"
          className="skills-textarea"
          label="Comment"
          fullWidth
          variant="outlined"
          multiline
          InputLabelProps={{
            shrink: Boolean(watch("comment")),
          }}
          error={!!errors.comment}
          helperText={getCommentError()}
          onKeyPress={(event) =>
            onTextFieldKeyPress(event, watch("comment")?.length)
          }
          {...register("comment", {
            maxLength: STUDENT_ENROLMENT_COMMENT_MAX_LENGTH,
            pattern:
              INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
          })}
        />
        <Box className="staff-enroll">
          <FormControl fullWidth className="select">
            <InputLabel id="enroltype-label" shrink={!!watch("enrolmentType")}>
              Enrolment Type <span className="color-red">*</span>
            </InputLabel>
            <Select
              labelId="enroltype-label"
              id="enroltype"
              label="Enrolment Type"
              value={watch("enrolmentType")}
              error={!!errors.enrolmentType}
              {...register("enrolmentType", {
                required: true,
              })}
            >
              {enrolmentOptionList?.enrolmentType?.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
            {!!errors.enrolmentType && (
              <FormHelperText error>{getEnrolmentTypeError()}</FormHelperText>
            )}
          </FormControl>
          <Box>
            <Typography variant="h5" className="confirmation">
              Staff Enrolment <span className="color-red">*</span>
            </Typography>
            <Controller
              control={control}
              {...register("staffEnrolment", {
                required: true,
              })}
              render={({ field }) => (
                <>
                  <RadioGroup row {...field}>
                    {STAFF_ENROLMENT_OPTIONS.map((option) => (
                      <FormControlLabel
                        value={option.value}
                        disabled
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedBoxIcon />}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                  {!!errors.staffEnrolment && (
                    <FormHelperText error>
                      Please select an option
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Box>
        </Box>
        <Typography
          variant="body2"
          className="instruction"
          sx={{ marginBottom: "8px !important" }}
        >
          Your Current Service Providers:
        </Typography>
        <FormControl fullWidth className="select">
          <InputLabel
            id="ques-label"
            shrink={!!watch("currentServiceProviders")}
          >
            Current Service Providers
            {Boolean(!Number(watch("staffEnrolment"))) && (
              <span className="color-red"> *</span>
            )}
          </InputLabel>
          <Select
            labelId="ques-label"
            disabled={Boolean(Number(watch("staffEnrolment")))}
            id="ques"
            label="Current Service Providers*"
            value={watch("currentServiceProviders")}
            error={!!errors.currentServiceProviders}
            {...register("currentServiceProviders", {
              required: Boolean(!Number(watch("staffEnrolment"))),
            })}
          >
            {enrolmentOptionList?.currentServiceProviders?.map((option) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
          {!!errors.currentServiceProviders && (
            <FormHelperText error>
              {getCurrentServiceProviderError()}
            </FormHelperText>
          )}
        </FormControl>
        {staffEnrolment === "0" && (
          <>
            <Typography
              variant="h4"
              className="questions"
              sx={{ marginBottom: "4px !important" }}
            >
              Questionnaire
            </Typography>
            <Typography
              variant="body2"
              className="instruction"
              sx={{ marginBottom: "4px !important" }}
            >
              The following questionnaire will assist us to ensure the Recovery
              & Wellbeing College is accessible to the diverse population groups
              in our community
            </Typography>
            <Typography
              variant="body2"
              className="instruction"
              sx={{ marginBottom: "4px !important" }}
            >
              The information related to employment and education will assist us
              with our service evaluation and in applying for future funding.
            </Typography>
            <Typography
              variant="body2"
              className="instruction"
              sx={{ marginBottom: "15px !important" }}
            >
              You can choose &#39;prefer not say&#39; for any questions that you
              do not feel comfortable answering.
            </Typography>
            <Grid
              container
              columnSpacing={{
                sm: "16px",
                md: " 20px",
                xl: "24px",
              }}
            >
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="whereabouts-label"
                    shrink={!!watch("whereAboutUs")}
                  >
                    How Did You Hear About The Recovery & Wellbeing College?{" "}
                    <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="whereabouts-label"
                    id="whereabouts"
                    label=" How Did You Hear About The Recovery & Wellbeing
                              College?*"
                    value={watch("whereAboutUs")}
                    error={!!errors.whereAboutUs}
                    {...register("whereAboutUs", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.hearAboutRC?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.whereAboutUs && (
                    <FormHelperText error>
                      {getWhereAboutUsError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel id="identity-label" shrink={!!watch("identity")}>
                    Aboriginal/Torres Strait Islander Identity
                    <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="identity-label"
                    id="identity"
                    label="Aboriginal/Torres Strait Islander Identity*"
                    value={watch("identity")}
                    error={!!errors.identity}
                    {...register("identity", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.atsiIdentity?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.identity && (
                    <FormHelperText error>{getIdentityError()}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="disability-label"
                    shrink={!!watch("disability")}
                  >
                    Disability <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="disability-label"
                    id="disability"
                    label="Disability*"
                    value={watch("disability")}
                    error={!!errors.disability}
                    {...register("disability", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.disability?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.disability && (
                    <FormHelperText error>
                      {getDisabilityError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="cob"
                  label={
                    <>
                      Country Of Birth <span className="color-red">*</span>
                    </>
                  }
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: Boolean(watch("countryOfBirth")),
                  }}
                  error={!!errors.countryOfBirth}
                  helperText={getCountryOfBirthError()}
                  onKeyPress={(event) =>
                    onTextFieldKeyPress(event, watch("countryOfBirth")?.length)
                  }
                  {...register("countryOfBirth", {
                    required: true,
                    maxLength: STUDENT_ENROLMENT_COB_MAX_LENGTH,
                    pattern:
                      INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="homelang"
                  label="Language At Home"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: Boolean(watch("languageAtHome")),
                  }}
                  error={!!errors.languageAtHome}
                  helperText={getLanguageAtHomeError()}
                  onKeyPress={(event) =>
                    onTextFieldKeyPress(event, watch("languageAtHome")?.length)
                  }
                  {...register("languageAtHome", {
                    maxLength: STUDENT_ENROLMENT_LAH_MAX_LENGTH,
                    pattern:
                      INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="ethnicity"
                  label="Ethnicity"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: Boolean(watch("ethnicity")),
                  }}
                  error={!!errors.ethnicity}
                  helperText={getEthnicityError()}
                  onKeyPress={(event) =>
                    onTextFieldKeyPress(event, watch("ethnicity")?.length)
                  }
                  {...register("ethnicity", {
                    maxLength: STUDENT_ENROLMENT_ETHNICITY_MAX_LENGTH,
                    pattern:
                      INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="gender-label"
                    shrink={!!watch("genderIdentity")}
                  >
                    Gender Identity <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    label="Gender Identity*"
                    value={watch("genderIdentity")}
                    error={!!errors.genderIdentity}
                    {...register("genderIdentity", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.genderIdentity?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.genderIdentity && (
                    <FormHelperText error>
                      {getGenderIdentityError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="qualification-label"
                    shrink={!!watch("qualifications")}
                  >
                    Completed Qualifications{" "}
                    <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="qualification-label"
                    id="qualification"
                    label="Completed Qualifications*"
                    value={watch("qualifications")}
                    error={!!errors.qualifications}
                    {...register("qualifications", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.completedQualifications?.map(
                      (option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )
                    )}
                  </Select>
                  {!!errors.qualifications && (
                    <FormHelperText error>
                      {getQualificationsError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="empstatus-label"
                    shrink={!!watch("employmentStatus")}
                  >
                    Employment Status <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="empstatus-label"
                    id="empstatus"
                    label="Employment Status*"
                    value={watch("employmentStatus")}
                    error={!!errors.employmentStatus}
                    {...register("employmentStatus", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.employmentStatus?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.employmentStatus && (
                    <FormHelperText error>
                      {getEmploymentStatusError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="schoollvel-label"
                    shrink={!!watch("highestCompletedSchoolLevel")}
                  >
                    Highest Completed School Level{" "}
                    <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="schoollvel-label"
                    id="schoollvel"
                    label="Highest Completed School Level*"
                    value={watch("highestCompletedSchoolLevel")}
                    error={!!errors.highestCompletedSchoolLevel}
                    {...register("highestCompletedSchoolLevel", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.highestCompletedSchoolLevel?.map(
                      (option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )
                    )}
                  </Select>
                  {!!errors.highestCompletedSchoolLevel && (
                    <FormHelperText error>
                      {getHighestCompletedSchoolError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="currentstudy-label"
                    shrink={!!watch("currentlyStudying")}
                  >
                    Currently Studying <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="currentstudy-label"
                    id="currentstudy"
                    label="Currently Studying*"
                    value={watch("currentlyStudying")}
                    error={!!errors.currentlyStudying}
                    {...register("currentlyStudying", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.currentlyStuding?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.currentlyStudying && (
                    <FormHelperText error>
                      {getCurrentlyStudyingError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="studyload-label"
                    shrink={!!watch("studyLoad")}
                  >
                    Study Load <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="studyload-label"
                    id="studyload"
                    label="Study Load*"
                    value={watch("studyLoad")}
                    error={!!errors.studyLoad}
                    {...register("studyLoad", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.studyLoad?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.studyLoad && (
                    <FormHelperText error>{getStudyLoadError()}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        {staffEnrolment === "1" && (
          <>
            <Grid
              container
              columnSpacing={{
                sm: "16px",
                md: " 20px",
                xl: "24px",
              }}
            >
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="empStatus-label"
                    shrink={!!watch("staffEmploymentStatus")}
                  >
                    Employment Status <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="empStatus-label"
                    id="empStatus"
                    label="Employment Status*"
                    value={watch("staffEmploymentStatus")}
                    error={!!errors.staffEmploymentStatus}
                    {...register("staffEmploymentStatus", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.staffEmploymentStatus?.map(
                      (option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )
                    )}
                  </Select>
                  {!!errors.staffEmploymentStatus && (
                    <FormHelperText error>
                      {getStaffEmploymentStatusError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="currntRole-label"
                    shrink={!!watch("currentRole")}
                  >
                    Current Role <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="currntRole-label"
                    id="currntRole"
                    label="Current Role*"
                    value={watch("currentRole")}
                    error={!!errors.currentRole}
                    {...register("currentRole", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.currentRole?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.currentRole && (
                    <FormHelperText error>
                      {getCurrentRoleError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel
                    id="workEnv-label"
                    shrink={!!watch("workEnvironment")}
                  >
                    Work Environment <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="workEnv-label"
                    id="workEnv"
                    label="Work Environment*"
                    value={watch("workEnvironment")}
                    error={!!errors.workEnvironment}
                    {...register("workEnvironment", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.workEnvironment?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.workEnvironment && (
                    <FormHelperText error>
                      {getWorkEnvironmentError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel id="siteServ-label" shrink={!!watch("site")}>
                    Site/Service <span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="siteServ-label"
                    id="siteServ"
                    label="Site/Service*"
                    value={watch("site")}
                    error={!!errors.site}
                    {...register("site", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.siteOrService?.map((option) => (
                      <MenuItem value={option.label}>{option.value}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.site && (
                    <FormHelperText error>
                      {getSiteServiceError()}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="mang-name"
                  label="Manager's Name"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: Boolean(watch("nameOfManager")),
                  }}
                  error={!!errors.nameOfManager}
                  helperText={getManagerNameError()}
                  onKeyPress={(event) =>
                    onTextFieldKeyPress(event, watch("nameOfManager")?.length)
                  }
                  {...register("nameOfManager", {
                    maxLength: STUDENT_ENROLMENT_MANAGER_NAME_MAX_LENGTH,
                    pattern:
                      INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="mang-email"
                  label="Manager's Email"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: Boolean(watch("emailOfManager")),
                  }}
                  error={!!errors.emailOfManager}
                  helperText={getManagerEmailError()}
                  {...register("emailOfManager", {
                    pattern: EmailPattern,
                    maxLength: STUDENT_ENROLMENT_MANAGER_EMAIL_MAX_LENGTH,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  type="number"
                  id="staff-link-id"
                  label="Staff Link ID"
                  fullWidth
                  onKeyPress={onNumberFieldKeyPress}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: Boolean(watch("staffLinkId")),
                  }}
                  error={!!errors.staffLinkId}
                  helperText={getStaffLinkIdError()}
                  {...register("staffLinkId", {
                    maxLength: STUDENT_ENROLMENT_STAFF_LINK_ID_MAX_LENGTH,
                    max: 2147483647,
                  })}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Card>
      <FormStepButtons handleBack={handleBack} />
    </form>
  );
};

export default FormStep3;
