import { Box } from "@mui/system";
import {
  Modal,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  Fade,
  Tooltip,
} from "@mui/material";
import { closeIcon, saveIcon } from "assets/images";
import { useForm } from "react-hook-form";
import {
  CLOSE_TOOLTIP_TITLE,
  ENROLMENT_STATUS,
} from "utils/constants/constant";
import { useEffect } from "react";
import { IChangeEnrolmentStatus } from "utils/interfaces/scheduled-offering";

interface IChangeEnrolmentStatusProps {
  isOpenChangeEnrolmentStatusModal: boolean;
  handleChangeEnrolmentStatusClose: () => void;
  handleSaveClick: (status: IChangeEnrolmentStatus) => void;
  status: IChangeEnrolmentStatus | undefined;
}

const ChangeEnrolmentStatus: React.FC<IChangeEnrolmentStatusProps> = ({
  isOpenChangeEnrolmentStatusModal,
  handleChangeEnrolmentStatusClose,
  handleSaveClick,
  status,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IChangeEnrolmentStatus>({
    defaultValues: {
      enrolmentStatus: 0,
    },
  });

  const onModalClose = () => {
    reset();
    handleChangeEnrolmentStatusClose();
  };

  const getStatusError = (): string => {
    if (errors.enrolmentStatus) {
      if (errors.enrolmentStatus.type === "required") {
        return "Enrolment Status is required";
      }
    }

    return "";
  };

  useEffect(() => {
    setValue("enrolmentStatus", status?.enrolmentStatus as number);
  }, [status, setValue, isOpenChangeEnrolmentStatusModal, reset]);

  return (
    <>
      <Modal open={isOpenChangeEnrolmentStatusModal} onClose={onModalClose}>
        <Box className="common-modal addsco-modal addsco-offering-modal">
          <Box className="modal-header">
            <Typography variant="h4">Change Enrolment Status</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={onModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <form onSubmit={handleSubmit(handleSaveClick)}>
            <Box className="modal-body">
              <Grid
                container
                rowSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
              >
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                    rowSpacing="16px"
                  >
                    <Grid item xs={12}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="enrol-select-label">
                          Enrolment Status
                        </InputLabel>
                        <Select
                          labelId="enrol-select-label"
                          id="enrol-select"
                          label="Enrolment Status"
                          value={watch("enrolmentStatus")}
                          error={!!errors.enrolmentStatus}
                          {...register("enrolmentStatus", {
                            required: true,
                          })}
                        >
                          {ENROLMENT_STATUS?.map((enrolmentStatus) => (
                            <MenuItem
                              key={enrolmentStatus.value}
                              value={enrolmentStatus.value}
                            >
                              {enrolmentStatus.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors.enrolmentStatus && (
                          <FormHelperText error>
                            {getStatusError()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box className="modal-footer">
              <Button variant="contained" className="btn-save" type="submit">
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={onModalClose}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ChangeEnrolmentStatus;
