import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  calendarFilledDarkIcon,
  calendarFilledLightIcon,
  checkIcon,
  coursesTotalIcon,
  deleteIcon,
  dropboxLogo,
  editIcon,
  educatorsTotalIcon,
  mailchimpLogo,
  nswLogo,
  offeringTotalIcon,
  uncheckIcon,
} from "assets/images";
import React, { useEffect } from "react";
import DesignerFooter from "components/designerFooter";
import AOS from "aos";
import "aos/dist/aos.css";

function CheckboxIcon() {
  return <img src={uncheckIcon} alt="checkbox" />;
}
function CheckedboxIcon() {
  return <img src={checkIcon} alt="checkbox" />;
}

interface Data {
  coursenum: string;
  course: string;
  term: string;
  location: string;
  days: number;
  issued: string;
  emr: string;
  enrollnum: number;
  attndday: number;
  status: string;
}

function createData(
  coursenum: string,
  course: string,
  term: string,
  location: string,
  days: number,
  issued: string,
  emr: string,
  enrollnum: number,
  attndday: number,
  status: string
): Data {
  return {
    coursenum,
    course,
    term,
    location,
    days,
    issued,
    emr,
    enrollnum,
    attndday,
    status,
  };
}

const rows = [
  createData(
    "S020017-ENG",
    "Life Beyond Trauma",
    "2022 T3",
    "151 Goswell Road London",
    3,
    "No",
    "No",
    7,
    7,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Mindfulnes workshop",
    "2022 T1",
    "151 Goswell Road London",
    4,
    "No",
    "No",
    9,
    9,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Creativity for recovery",
    "2022 T2",
    "143 Pinkville Road ",
    4,
    "Yes",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "An introduction to recovery & the strength of approch",
    "2023 T1",
    "Goswell Square II",
    3,
    "No",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Making and keeping connections",
    "2022 T5",
    "104 ShopMart Complex",
    3,
    "No",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Exploring psychosis  ",
    "2022 T5",
    "122 Square III",
    3,
    "Yes",
    "No",
    10,
    10,
    "confirmed"
  ),
];

const terms = ["2022 T1", "2022 T4", "2022 T3"];

const DesignerDashboard: React.FC = () => {
  const [term, setterm] = React.useState<string[]>([]);

  const handletermChange = (event: SelectChangeEvent<typeof term>) => {
    const {
      target: { value },
    } = event;
    setterm(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    AOS.init({
      easing: "ease-in",
      duration: 500,
      startEvent: "load",
      offset: 50,
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        <div className="main-content">
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Dashboard
            </Typography>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Grid container spacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
                <Grid item xs={12} md={4}>
                  <Card className="counter-card" data-aos="zoom-in">
                    <Typography variant="h5">
                      Total Scheduled Offering
                    </Typography>
                    <Typography variant="h1">43</Typography>
                    <div className="counter-card-icon">
                      <img
                        src={offeringTotalIcon}
                        alt="Total Scheduled Offering"
                      />
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card
                    className="counter-card"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <Typography variant="h5">Total Active Courses</Typography>
                    <Typography variant="h1">60</Typography>
                    <div className="counter-card-icon">
                      <img src={coursesTotalIcon} alt="Total Active Courses" />
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card
                    className="counter-card"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <Typography variant="h5">Total Educators</Typography>
                    <Typography variant="h1">54</Typography>
                    <div className="counter-card-icon">
                      <img src={educatorsTotalIcon} alt="Total Educators" />
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Box className="table-card-header">
                  <Typography variant="h3">Scheduled Offering</Typography>
                  <Box>
                    <TextField
                      id="search"
                      variant="outlined"
                      className="search-input"
                      placeholder="Search"
                      defaultValue="Life Beyond"
                    />
                    <FormControl fullWidth className="table-select select">
                      <InputLabel id="term-select-label">
                        Select Term
                      </InputLabel>
                      <Select
                        labelId="term-select-label"
                        id="term-select"
                        multiple
                        label="Select Term"
                        value={term}
                        onChange={handletermChange}
                        input={<OutlinedInput label="Select Term" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {terms.map((selectterm) => (
                          <MenuItem key={selectterm} value={selectterm}>
                            <Checkbox
                              checked={term.indexOf(selectterm) > -1}
                              icon={<CheckboxIcon />}
                              checkedIcon={<CheckedboxIcon />}
                            />
                            <ListItemText primary={selectterm} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button variant="outlined" className="btn-view-all">
                      View All
                    </Button>
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            maxWidth: "115px",
                            width: "115px",
                            minWidth: "115px",
                          }}
                        >
                          Course Number
                        </TableCell>
                        <TableCell sx={{ width: "154px", minWidth: "154px" }}>
                          Course
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "82px", minWidth: "82px" }}
                        >
                          Term
                        </TableCell>
                        <TableCell sx={{ width: "146px", minWidth: "146px" }}>
                          Location
                        </TableCell>
                        <TableCell sx={{ width: "120px", minWidth: "120px" }}>
                          No. Of Session Days
                        </TableCell>
                        <TableCell sx={{ width: "103px", minWidth: "103px" }}>
                          Certificates Issued?
                        </TableCell>
                        <TableCell sx={{ width: "75px", minWidth: "75px" }}>
                          EMR Entered
                        </TableCell>
                        <TableCell sx={{ width: "78px", minWidth: "78px" }}>
                          No. Enrolled
                        </TableCell>
                        <TableCell sx={{ width: "112px", minWidth: "112px" }}>
                          Days With Unsubmitted Attendance
                        </TableCell>
                        <TableCell align="center" sx={{ width: "102px" }}>
                          Status
                        </TableCell>
                        <TableCell align="center" sx={{ width: "92px" }}>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {row.coursenum}
                          </TableCell>
                          <TableCell>
                            <a href="-">
                              <Typography variant="body2">
                                {row.course}
                              </Typography>
                            </a>
                          </TableCell>
                          <TableCell align="center">{row.term}</TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {row.location}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Button className="btn-course" variant="outlined">
                              <img
                                src={calendarFilledDarkIcon}
                                alt="Course List"
                                className="default"
                              />
                              <img
                                src={calendarFilledLightIcon}
                                alt="Course List"
                                className="active"
                              />
                              <Typography variant="body2">
                                {row.days}
                              </Typography>
                            </Button>
                          </TableCell>
                          <TableCell>{row.issued}</TableCell>
                          <TableCell>{row.emr}</TableCell>
                          <TableCell>{row.enrollnum}</TableCell>
                          <TableCell>{row.attndday}</TableCell>
                          <TableCell
                            align="center"
                            className={`${row.status} status`}
                          >
                            <div>{row.status}</div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="table-actions">
                              <IconButton>
                                <img src={editIcon} alt="edit" />
                              </IconButton>
                              <IconButton>
                                <img src={deleteIcon} alt="delete" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={{ xs: "16px", sm: "12px", xl: "16px" }}>
                <Grid item xs={12} sm={4} lg>
                  <a href="-" className="logo-card bg-nsw">
                    <div className="logo-card-content">
                      <img
                        src={nswLogo}
                        alt="NSW Health Webmail"
                        className="nsw-img"
                      />
                      <Typography variant="h5">NSW Health Webmail</Typography>
                    </div>
                  </a>
                </Grid>
                <Grid item xs={12} sm={4} lg>
                  <a href="-" className="logo-card bg-mailchimp">
                    <div className="logo-card-content">
                      <img src={mailchimpLogo} alt="Mailchimp" />
                      <Typography variant="h5">Mailchimp</Typography>
                    </div>
                  </a>
                </Grid>
                <Grid item xs={12} sm={4} lg>
                  <a href="-" className="logo-card bg-dropbox">
                    <div className="logo-card-content">
                      <img src={dropboxLogo} alt="Dropbox" />
                      <Typography variant="h5">Dropbox</Typography>
                    </div>
                  </a>
                </Grid>
                <Grid item xs={12} sm={4} lg />
                <Grid item xs={12} sm={4} lg />
              </Grid>
            </Grid>
          </Grid>
          <DesignerFooter />
        </div>
      </main>
    </div>
  );
};

export default DesignerDashboard;
