import {
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  USER_BY_ID_REQUEST,
  USER_BY_ID_SUCCESS,
  USER_BY_ID_FAILURE,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
} from "./actionTypes";
import {
  UserListPayload,
  UserListRequest,
  UserListSuccessPayload,
  UserListSuccess,
  UserListFailurePayload,
  UserListFailure,
  AddEditUserPayload,
  AddUserRequest,
  AddEditUserSuccessPayload,
  AddUserSuccess,
  AddEditUserFailurePayload,
  AddUserFailure,
  EditUserRequest,
  EditUserSuccess,
  EditUserFailure,
  UserByIdPayload,
  UserByIdRequest,
  UserByIdSuccessPayload,
  UserByIdSuccess,
  UserByIdFailurePayload,
  UserByIdFailure,
  DeleteUserFailurePayload,
  DeleteUserSuccessPayload,
  DeleteUserPayload,
  DeleteUserFailure,
  DeleteUserRequest,
  DeleteUserSuccess,
} from "./types";

export const userListRequest = (payload: UserListPayload): UserListRequest => ({
  type: USER_LIST_REQUEST,
  payload,
});

export const userListSuccess = (
  payload: UserListSuccessPayload
): UserListSuccess => ({
  type: USER_LIST_SUCCESS,
  payload,
});

export const userListFailure = (
  payload: UserListFailurePayload
): UserListFailure => ({
  type: USER_LIST_FAILURE,
  payload,
});

export const addUserRequest = (
  payload: AddEditUserPayload
): AddUserRequest => ({
  type: ADD_USER_REQUEST,
  payload,
});

export const addUserSuccess = (
  payload: AddEditUserSuccessPayload
): AddUserSuccess => ({
  type: ADD_USER_SUCCESS,
  payload,
});

export const addUserFailure = (
  payload: AddEditUserFailurePayload
): AddUserFailure => ({
  type: ADD_USER_FAILURE,
  payload,
});

export const editUserRequest = (
  payload: AddEditUserPayload
): EditUserRequest => ({
  type: EDIT_USER_REQUEST,
  payload,
});

export const editUserSuccess = (
  payload: AddEditUserSuccessPayload
): EditUserSuccess => ({
  type: EDIT_USER_SUCCESS,
  payload,
});

export const editUserFailure = (
  payload: AddEditUserFailurePayload
): EditUserFailure => ({
  type: EDIT_USER_FAILURE,
  payload,
});

export const userByIdRequest = (payload: UserByIdPayload): UserByIdRequest => ({
  type: USER_BY_ID_REQUEST,
  payload,
});

export const userByIdSuccess = (
  payload: UserByIdSuccessPayload
): UserByIdSuccess => ({
  type: USER_BY_ID_SUCCESS,
  payload,
});

export const userByIdFailure = (
  payload: UserByIdFailurePayload
): UserByIdFailure => ({
  type: USER_BY_ID_FAILURE,
  payload,
});

export const deleteUserRequest = (
  payload: DeleteUserPayload
): DeleteUserRequest => ({
  type: DELETE_USER_REQUEST,
  payload,
});

export const deleteUserSuccess = (
  payload: DeleteUserSuccessPayload
): DeleteUserSuccess => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserFailure = (
  payload: DeleteUserFailurePayload
): DeleteUserFailure => ({
  type: DELETE_USER_FAILURE,
  payload,
});
