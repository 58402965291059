import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  calendarIcon,
  closeIcon,
  courseListSecondaryIcon,
  filterLightIcon,
  filterPrimaryIcon,
  plusLightIcon,
  userIcon,
} from "assets/images";
import React, { useEffect } from "react";
import DesignerFooter from "components/designerFooter";
import AOS from "aos";
import "aos/dist/aos.css";
import projectTheme from "app.theme";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AppRoutings } from "utils/enums/app-routings";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

interface UpcomingCoursesData {
  course: string;
  term: string;
  location: string;
  datetime: string;
}

function createUpcomingCoursesData(
  course: string,
  term: string,
  location: string,
  datetime: string
): UpcomingCoursesData {
  return {
    course,
    term,
    location,
    datetime,
  };
}

const upcomingCoursesrow = [
  createUpcomingCoursesData(
    "Education for Recovery: Foundations",
    "2023 T1",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023 10:00 AM to 4:00 PM"
  ),
  createUpcomingCoursesData(
    "Skills for Co-facilitations",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM to 2:00 PM"
  ),
  createUpcomingCoursesData(
    "Education for Recovery: Foundations",
    "2023 T1",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023 10:00 AM to 4:00 PM"
  ),
  createUpcomingCoursesData(
    "Skills for Co-facilitations",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM to 2:00 PM"
  ),
  createUpcomingCoursesData(
    "Education for Recovery: Foundations",
    "2023 T1",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023 10:00 AM to 4:00 PM"
  ),
];

interface CourseOfferData {
  sessname: string;
  term: string;
  location: string;
  datetime: string;
}

function createCourseOfferData(
  sessname: string,
  term: string,
  location: string,
  datetime: string
): CourseOfferData {
  return {
    sessname,
    term,
    location,
    datetime,
  };
}

function CalendarIcon() {
  return (
    <div className="calendar-icon">
      <img src={calendarIcon} alt="calendar" />
    </div>
  );
}

const courseOfferrow = [
  createCourseOfferData(
    "Challenging Stigma",
    "2023 T1",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    " 01 Feb 2023 10:00 AM to 4:00 PM"
  ),
  createCourseOfferData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createCourseOfferData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createCourseOfferData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createCourseOfferData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
];

interface PastCourseData {
  sessname: string;
  term: string;
  location: string;
  datetime: string;
}

function createPastCourseData(
  sessname: string,
  term: string,
  location: string,
  datetime: string
): PastCourseData {
  return {
    sessname,
    term,
    location,
    datetime,
  };
}

const pastCourserow = [
  createPastCourseData(
    "Challenging Stigma",
    "2023 T1",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    " 01 Feb 2023 10:00 AM to 4:00 PM"
  ),
  createPastCourseData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createPastCourseData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createPastCourseData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createPastCourseData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
];

const DesignerStudentDashboard: React.FC = () => {
  useEffect(() => {
    AOS.init({
      easing: "ease-in",
      duration: 500,
      startEvent: "load",
      offset: 50,
      once: true,
    });
    AOS.refresh();
  }, []);

  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [studentDashboard, setStudentDashboard] = React.useState(true);
  const [courseDetails, setCourseDetails] = React.useState(false);

  const [filterbox, setFilterbox] = React.useState(false);

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        {studentDashboard && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Dashboard
              </Typography>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container student-table"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h3">My Upcoming Courses</Typography>

                      <Box
                        sx={{
                          columnGap: "18px",
                          display: "flex",
                          width: { xs: "100%", md: "auto" },
                        }}
                        className="search-filter-inputs"
                      >
                        <TextField
                          id="search"
                          variant="outlined"
                          className="search-input"
                          placeholder="Search"
                        />

                        <Button
                          variant="outlined"
                          className="btn-filter"
                          onClick={() => {
                            setFilterbox(true);
                            document.body.classList.toggle("filter-open");
                          }}
                        >
                          <img
                            src={filterPrimaryIcon}
                            alt="filter"
                            className="default"
                          />
                          <img
                            src={filterLightIcon}
                            alt="filter"
                            className="active"
                          />
                          <span>Filter</span>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              width: "302px",
                              minWidth: "302px",
                            }}
                          >
                            Course
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "110px", minWidth: "80px" }}
                          >
                            Term
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ maxWidth: "392px", width: "392px" }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "280px", minWidth: "280px" }}
                          >
                            <Typography variant="body2">
                              Date & Time of First Session
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "108px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "80px",
                              },
                            }}
                          >
                            Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {upcomingCoursesrow.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.course}
                            </TableCell>
                            <TableCell>{row.term}</TableCell>
                            <TableCell>
                              <Typography variant="body2" className="location">
                                {row.location}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {row.datetime}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setCourseDetails(true);
                                    setStudentDashboard(false);
                                  }}
                                >
                                  <img
                                    src={courseListSecondaryIcon}
                                    alt="list"
                                  />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <Typography variant="h3">Course Offerings</Typography>
                      <Box
                        sx={{
                          columnGap: "18px",
                          display: "flex",
                          width: { xs: "100%", md: "auto" },
                        }}
                        className="search-filter-inputs"
                      >
                        <TextField
                          id="search"
                          variant="outlined"
                          className="search-input"
                          placeholder="Search"
                        />

                        <Button
                          variant="outlined"
                          className="btn-filter"
                          onClick={() => {
                            setFilterbox(true);
                            document.body.classList.toggle("filter-open");
                          }}
                        >
                          <img
                            src={filterPrimaryIcon}
                            alt="filter"
                            className="default"
                          />
                          <img
                            src={filterLightIcon}
                            alt="filter"
                            className="active"
                          />
                          <span>Filter</span>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              width: "266px",
                              minWidth: "266px",
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "210px",
                                minWidth: "210px",
                              },
                            }}
                          >
                            Session Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              width: "110px",
                              minWidth: "110px",
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "94px",
                                minWidth: "94px",
                              },
                            }}
                          >
                            Term
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "368px", minWidth: "368px" }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              width: "313px",
                              minWidth: "313px",
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "254px",
                                minWidth: "254px",
                              },
                            }}
                          >
                            Date & Time of First Session
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "135px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "90px",
                              },
                            }}
                          >
                            View and Enrol
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {courseOfferrow.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.sessname}
                            </TableCell>
                            <TableCell>{row.term}</TableCell>
                            <TableCell>
                              <Typography variant="body2" className="location">
                                {row.location}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.datetime}</TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setCourseDetails(true);
                                    setStudentDashboard(false);
                                  }}
                                >
                                  <img
                                    src={courseListSecondaryIcon}
                                    alt="list"
                                  />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <Typography variant="h3">My Past Courses</Typography>
                      <Box
                        sx={{
                          columnGap: "18px",
                          display: "flex",
                          width: { xs: "100%", md: "auto" },
                        }}
                        className="search-filter-inputs"
                      >
                        <TextField
                          id="search"
                          variant="outlined"
                          className="search-input"
                          placeholder="Search"
                        />

                        <Button
                          variant="outlined"
                          className="btn-filter"
                          onClick={() => {
                            setFilterbox(true);
                            document.body.classList.toggle("filter-open");
                          }}
                        >
                          <img
                            src={filterPrimaryIcon}
                            alt="filter"
                            className="default"
                          />
                          <img
                            src={filterLightIcon}
                            alt="filter"
                            className="active"
                          />
                          <span>Filter</span>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              width: "282px",
                              minWidth: "282px",
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "210px",
                                minWidth: "210px",
                              },
                            }}
                          >
                            Session Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              width: "110px",
                              minWidth: "110px",
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "94px",
                                minWidth: "94px",
                              },
                            }}
                          >
                            Term
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "392px", minWidth: "392px" }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              width: "280px",
                              minWidth: "280px",
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "254px",
                                minWidth: "254px",
                              },
                            }}
                          >
                            Date & Time of First Session
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "108px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "90px",
                              },
                            }}
                          >
                            Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pastCourserow.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.sessname}
                            </TableCell>
                            <TableCell>{row.term}</TableCell>
                            <TableCell>
                              <Typography variant="body2" className="location">
                                {row.location}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.datetime}</TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setCourseDetails(true);
                                    setStudentDashboard(false);
                                  }}
                                >
                                  <img
                                    src={courseListSecondaryIcon}
                                    alt="list"
                                  />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
        {courseDetails && (
          <div className="main-content std-details">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Course Details
              </Typography>

              <Box sx={{ display: "flex", columnGap: "15px" }}>
                <Button
                  variant="outlined"
                  className="btn-back"
                  onClick={() => {
                    setCourseDetails(false);
                    setStudentDashboard(true);
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  className="btn-add-enrol-filled"
                  href={AppRoutings.DesignerStudentTemp}
                >
                  <img src={plusLightIcon} alt="plus" />
                  Add Enrolment
                </Button>
              </Box>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                >
                  <Grid item xs={12} lg={6}>
                    <Card>
                      <Typography variant="h3" className="sco-heading">
                        Scheduled Course Offering Details
                      </Typography>
                      <Grid
                        container
                        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Course
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            320605_ENG Education for Recovery: Foundations
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Location
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            Recovery & wellbeing college, Kogarah BLENDED - Face
                            to Face/Online
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Number Enrolled
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            1
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Number Waitlisted
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            1
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Date & Time Of First Session
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            12 Jan 2023, 12:00 PM - 2:00 PM
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Sessions
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            3
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Term
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            2023 T1
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Status
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            Confirmed
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {/* <Grid
                      container
                      spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                    > */}
                    {/* <Grid item xs={12}> */}
                    <Card>
                      <Typography variant="h3" className="attnd-heading">
                        Sessions
                      </Typography>
                      <Box className="attnd-body-wrapper">
                        <Grid
                          container
                          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 1
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                03 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 2
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                10 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Button variant="outlined" className="btn-session">
                              <Typography
                                variant="h5"
                                component="span"
                                className="day-num"
                              >
                                Day 3
                              </Typography>
                              <Typography
                                variant="h6"
                                component="span"
                                className="date"
                              >
                                17 Nov 2022
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                    {/* </Grid> */}
                    {/* <Grid item xs={12}>
                        <Card>
                          <List className="form-list">
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Completed Evaluation Forms" />
                                <img src={pdfIcon} alt="pdf" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Completed Learning Outcomes forms" />
                                <img src={pdfIcon} alt="pdf" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Blank Evaluation Forms" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Upload Completed Evaluation Forms" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Researcher’s Summary(Student evaluations - Qualitative Research)" />
                                <img src={pdfIcon} alt="pdf" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Researcher’s Summary(Learning Outcomes - Quantitative Research)" />
                              </ListItemButton>
                            </ListItem>
                          </List>
                        </Card>
                      </Grid> */}
                    {/* </Grid> */}
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Typography variant="h3" className="attnd-heading">
                        Educators
                      </Typography>
                      <Box className="educators-card-group">
                        <div
                          role="link"
                          className="educators-card std-educators-card"
                        >
                          <img src={userIcon} alt="user" />
                          <Box>
                            <Typography variant="body2" className="name">
                              Alsie Blayney
                            </Typography>
                            <Typography variant="body2" className="designation">
                              Peer Educator
                            </Typography>
                            <Link
                              type="email"
                              href="mailto: alsie.blayney@health.nsw.gov.au"
                            >
                              alsie.blayney@health.nsw.gov.au
                            </Link>
                          </Box>
                        </div>
                        <div
                          role="link"
                          className="educators-card std-educators-card"
                        >
                          <img src={userIcon} alt="user" />
                          <Box>
                            <Typography variant="body2" className="name">
                              Michelle McGerrell
                            </Typography>
                            <Typography variant="body2" className="designation">
                              Clinical Educator
                            </Typography>
                            <Link
                              type="email"
                              href="mailto: michelle.mcgerrell@health.nsw.gov.au"
                            >
                              michelle.mcgerrell@health.nsw.gov.au
                            </Link>
                          </Box>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
      </main>

      {filterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <DesktopDatePicker
                  className="date-picker"
                  label="Date of Birth"
                  slots={{
                    openPickerIcon: CalendarIcon,
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <TextField
              id="mobile"
              label="Mobile"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="email"
              label="Email"
              fullWidth
              variant="outlined"
              type="email"
            />
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default DesignerStudentDashboard;
