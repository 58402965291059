import {
  CURRENT_COURSE_LIST_REQUEST,
  CURRENT_COURSE_LIST_SUCCESS,
  CURRENT_COURSE_LIST_FAILURE,
  PAST_COURSE_LIST_REQUEST,
  PAST_COURSE_LIST_SUCCESS,
  PAST_COURSE_LIST_FAILURE,
  SCHEDULED_OFFERING_DETAILS_REQUEST,
  SCHEDULED_OFFERING_DETAILS_SUCCESS,
  SCHEDULED_OFFERING_DETAILS_FAILURE,
  ADD_COURSE_REFLECTION_FORM_REQUEST,
  ADD_COURSE_REFLECTION_FORM_SUCCESS,
  ADD_COURSE_REFLECTION_FORM_FAILURE,
  EDIT_COURSE_REFLECTION_FORM_REQUEST,
  EDIT_COURSE_REFLECTION_FORM_SUCCESS,
  EDIT_COURSE_REFLECTION_FORM_FAILURE,
  ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_REQUEST,
  ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_SUCCESS,
  ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_FAILURE,
  MISSING_ATTENDANCE_COURSE_LIST_REQUEST,
  MISSING_ATTENDANCE_COURSE_LIST_SUCCESS,
  MISSING_ATTENDANCE_COURSE_LIST_FAILURE,
} from "./actionTypes";

import { EducatorHomeActions, EducatorHomeState } from "./types";

const initialState: EducatorHomeState = {
  pending: false,
  currentCourseList: [],
  pastCourseList: [],
  error: null,
};

const reducers = (
  state = initialState,
  action: EducatorHomeActions
): unknown => {
  switch (action.type) {
    case CURRENT_COURSE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CURRENT_COURSE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        currentCourseList: action.payload.currentCourseList,
        error: null,
      };
    case CURRENT_COURSE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case PAST_COURSE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PAST_COURSE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        pastCourseList: action.payload.pastCourseList,
        error: null,
      };
    case PAST_COURSE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case SCHEDULED_OFFERING_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SCHEDULED_OFFERING_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        scheduledOfferingDetails: action.payload,
        error: null,
      };
    case SCHEDULED_OFFERING_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_COURSE_REFLECTION_FORM_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_COURSE_REFLECTION_FORM_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_COURSE_REFLECTION_FORM_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_COURSE_REFLECTION_FORM_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_COURSE_REFLECTION_FORM_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_COURSE_REFLECTION_FORM_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_SUCCESS:
      return {
        ...state,
        pending: false,
        pastCourseList: action.payload.enroledStudentList,
        error: null,
      };
    case ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case MISSING_ATTENDANCE_COURSE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MISSING_ATTENDANCE_COURSE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        missingAttendanceCourseList: action.payload.missingAttendanceCourseList,
        error: null,
      };
    case MISSING_ATTENDANCE_COURSE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
