import {
  DELETE_STUDENT_FAILURE,
  DELETE_STUDENT_REQUEST,
  DELETE_STUDENT_SUCCESS,
  EDIT_CONFIRMATION_PREFERENCE_FAILURE,
  EDIT_CONFIRMATION_PREFERENCE_REQUEST,
  EDIT_CONFIRMATION_PREFERENCE_SUCCESS,
  EDIT_RESEARCH_PROFILE_DETAILS_FAILURE,
  EDIT_RESEARCH_PROFILE_DETAILS_REQUEST,
  EDIT_RESEARCH_PROFILE_DETAILS_SUCCESS,
  EDIT_STUDENTNOTE_FAILURE,
  EDIT_STUDENTNOTE_REQUEST,
  EDIT_STUDENTNOTE_SUCCESS,
  EDIT_STUDENT_DETAILS_FAILURE,
  EDIT_STUDENT_DETAILS_REQUEST,
  EDIT_STUDENT_DETAILS_SUCCESS,
  GET_CONFIRMATION_PREFERENCE_FAILURE,
  GET_CONFIRMATION_PREFERENCE_REQUEST,
  GET_CONFIRMATION_PREFERENCE_SUCCESS,
  GET_RESEARCH_PROFILE_DETAILS_FAILURE,
  GET_RESEARCH_PROFILE_DETAILS_REQUEST,
  GET_RESEARCH_PROFILE_DETAILS_SUCCESS,
  GET_STUDENTNOTE_FAILURE,
  GET_STUDENTNOTE_REQUEST,
  GET_STUDENTNOTE_SUCCESS,
  GET_STUDENT_DETAILS_FAILURE,
  GET_STUDENT_DETAILS_REQUEST,
  GET_STUDENT_DETAILS_SUCCESS,
  ENROLLMENT_SUMMARY_LIST_FAILURE,
  ENROLLMENT_SUMMARY_LIST_REQUEST,
  ENROLLMENT_SUMMARY_LIST_SUCCESS,
  STUDENT_LIST_FAILURE,
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_SUCCESS,
  GET_STAFF_DETAILS_FAILURE,
  GET_STAFF_DETAILS_SUCCESS,
  GET_STAFF_DETAILS_REQUEST,
  EDIT_STAFF_DETAILS_REQUEST,
  EDIT_STAFF_DETAILS_SUCCESS,
  EDIT_STAFF_DETAILS_FAILURE,
  GET_MEDIA_CONSENT_REQUEST,
  GET_MEDIA_CONSENT_SUCCESS,
  GET_MEDIA_CONSENT_FAILURE,
  EDIT_MEDIA_CONSENT_REQUEST,
  EDIT_MEDIA_CONSENT_SUCCESS,
  EDIT_MEDIA_CONSENT_FAILURE,
  ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_REQUEST,
  ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_SUCCESS,
  ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_FAILURE,
  STUDENT_ATTENDANCE_LIST_REQUEST,
  STUDENT_ATTENDANCE_LIST_SUCCESS,
  STUDENT_ATTENDANCE_LIST_FAILURE,
  GET_PENDING_ENROLLMENT_LIST_REQUEST,
  GET_PENDING_ENROLLMENT_LIST_SUCCESS,
  GET_PENDING_ENROLLMENT_LIST_FAILURE,
  GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_FAILURE,
  GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_REQUEST,
  GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_SUCCESS,
  APPROVE_ENROLMENT_BY_EMAIL_SMS_REQUEST,
  APPROVE_ENROLMENT_BY_EMAIL_SMS_SUCCESS,
  APPROVE_ENROLMENT_BY_EMAIL_SMS_FAILURE,
  GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_FAILURE,
  GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_REQUEST,
  GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_SUCCESS,
  ADD_NEW_ENROLMENT_FROM_STUDENT_REQUEST,
  ADD_NEW_ENROLMENT_FROM_STUDENT_SUCCESS,
  ADD_NEW_ENROLMENT_FROM_STUDENT_FAILURE,
  RE_SEND_CONFIRMATION_MAIL_REQUEST,
  RE_SEND_CONFIRMATION_MAIL_SUCCESS,
  RE_SEND_CONFIRMATION_MAIL_FAILURE,
  STUDENT_ENROLMENT_WITHDRAWN_FAILURE,
  STUDENT_ENROLMENT_WITHDRAWN_REQUEST,
  STUDENT_ENROLMENT_WITHDRAWN_SUCCESS,
  CHANGE_CONFIRMATION_PREFERENCE_REQUEST,
  GENERATE_CERTIFICATE_REQUEST,
  GENERATE_CERTIFICATE_FAILURE,
  GENERATE_CERTIFICATE_SUCCESS,
  EDIT_STUDENT_PROFILE_DETAILS_FAILURE,
  EDIT_STUDENT_PROFILE_DETAILS_REQUEST,
  EDIT_STUDENT_PROFILE_DETAILS_SUCCESS,
  GET_STUDENT_PROFILE_DETAILS_FAILURE,
  GET_STUDENT_PROFILE_DETAILS_REQUEST,
  GET_STUDENT_PROFILE_DETAILS_SUCCESS,
  PRINT_ENROLMENT_SUMMARY_FAILURE,
  PRINT_ENROLMENT_SUMMARY_REQUEST,
  PRINT_ENROLMENT_SUMMARY_SUCCESS,
} from "./actionTypes";
import {
  ConfirmationPreferenceFailure,
  ConfirmationPreferenceFailurePayload,
  ConfirmationPreferencePayload,
  ConfirmationPreferenceRequest,
  ConfirmationPreferenceSuccess,
  ConfirmationPreferenceSuccessPayload,
  DeleteStudentFailure,
  DeleteStudentFailurePayload,
  DeleteStudentPayload,
  DeleteStudentRequest,
  DeleteStudentSuccess,
  DeleteStudentSuccessPayload,
  EditConfirmationPreferenceFailure,
  EditConfirmationPreferenceFailurePayload,
  EditConfirmationPreferencePayload,
  EditConfirmationPreferenceRequest,
  EditConfirmationPreferenceSuccess,
  EditConfirmationPreferenceSuccessPayload,
  EditResearchProfileDetailsFailure,
  EditResearchProfileDetailsFailurePayload,
  EditResearchProfileDetailsPayload,
  EditResearchProfileDetailsRequest,
  EditResearchProfileDetailsSuccess,
  EditResearchProfileDetailsSuccessPayload,
  EditStudentDetailsFailure,
  EditStudentDetailsFailurePayload,
  EditStudentDetailsPayload,
  EditStudentDetailsRequest,
  EditStudentDetailsSuccess,
  EditStudentDetailsSuccessPayload,
  EditStudentNoteFailure,
  EditStudentNoteFailurePayload,
  EditStudentNotePayload,
  EditStudentNoteRequest,
  EditStudentNoteSuccess,
  EditStudentNoteSuccessPayload,
  ResearchProfileDetailsFailure,
  ResearchProfileDetailsFailurePayload,
  ResearchProfileDetailsPayload,
  ResearchProfileDetailsRequest,
  ResearchProfileDetailsSuccess,
  ResearchProfileDetailsSuccessPayload,
  StudentDetailsFailure,
  StudentDetailsRequest,
  StudentDetailsSuccess,
  EnrollmentSummaryListFailure,
  EnrollmentSummaryListFailurePayload,
  EnrollmentSummaryListPayload,
  EnrollmentSummaryListRequest,
  EnrollmentSummaryListSuccess,
  EnrollmentSummaryListSuccessPayload,
  StudentListFailure,
  StudentListFailurePayload,
  StudentListPayload,
  StudentListRequest,
  StudentListSuccess,
  StudentListSuccessPayload,
  StudentNoteFailure,
  StudentNoteFailurePayload,
  StudentNotePayload,
  StudentNoteRequest,
  StudentNoteSuccess,
  StudentNoteSuccessPayload,
  StudnetDetailsFailurePayload,
  StudnetDetailsPayload,
  StudnetDetailsSuccessPayload,
  StaffDetailsFailure,
  StaffDetailsFailurePayload,
  StaffDetailsSuccess,
  StaffDetailsSuccessPayload,
  StaffDetailsRequest,
  StaffDetailsPayload,
  EditStaffDetailsPayload,
  EditStaffDetailsDetailsRequest,
  EditStaffDetailsSuccessPayload,
  EditStaffDetailsDetailsSuccess,
  EditStaffDetailsFailurePayload,
  EditStaffDetailsDetailsFailure,
  MediaConsentPayload,
  MediaConsentRequest,
  MediaConsentPayloadSuccessPayload,
  MediaConsentSuccess,
  MediaConsentFailurePayload,
  MediaConsentFailure,
  EditMediaConsentPayload,
  EditMediaConsentRequest,
  EditMediaConsentSuccessPayload,
  EditMediaConsentSuccess,
  EditMediaConsentFailurePayload,
  EditMediaConsentFailure,
  EnrollmentAndAttendanceHistoryListRequest,
  EnrollmentAndAttendanceHistoryListPayload,
  EnrollmentAndAttendanceHistoryListSuccessPayload,
  EnrollmentAndAttendanceHistoryListSuccess,
  EnrollmentAndAttendanceHistoryListFailurePayload,
  EnrollmentAndAttendanceHistoryListFailure,
  StudentAttendanceListPayload,
  StudentAttenadanceListRequest,
  StudentAttendanceListSuccessPayload,
  StudentAttenadanceListSuccess,
  StudentAttendanceListFailurePayload,
  StudentAttenadanceListFailure,
  GetPendingEnrolmentListPayload,
  GetPendingEnrolmentListRequest,
  GetPendingEnrolmentListSuccess,
  GetPendingEnrolmentListListSuccessPayload,
  GetPendingEnrolmentListFailurePayload,
  GetPendingEnrolmentListFailure,
  GetStudentEnrolmentForConfirmationPayload,
  GetStudentEnrolmentForConfirmationSuccessPayload,
  GetStudentEnrolmentForConfirmationFailurePayload,
  GetStudentEnrolmentForConfirmationRequest,
  GetStudentEnrolmentForConfirmationSuccess,
  GetStudentEnrolmentForConfirmationFailure,
  ApproveEnrolmentByEmailSMSPayload,
  ApproveEnrolmentByEmailSMSSuccessPayload,
  ApproveEnrolmentByEmailSMSFailurePayload,
  ApproveEnrolmentByEmailSMSRequest,
  ApproveEnrolmentByEmailSMSSuccess,
  ApproveEnrolmentByEmailSMSFailure,
  GetCurrentCoursesForAddEnrolmentPayload,
  GetCurrentCoursesForAddEnrolmentSuccessPayload,
  GetCurrentCoursesForAddEnrolmentRequest,
  GetCurrentCoursesForAddEnrolmentSuccess,
  GetCurrentCoursesForAddEnrolmentFailure,
  GetCurrentCoursesForAddEnrolmentFailurePayload,
  AddNewEnrolmentFromStudentPayload,
  AddNewEnrolmentFromStudentSuccessPayload,
  AddNewEnrolmentFromStudentFailurePayload,
  AddNewEnrolmentFromStudentRequest,
  AddNewEnrolmentFromStudentSuccess,
  AddNewEnrolmentFromStudentFailure,
  ReSendConfirmationMailPayload,
  ReSendConfirmationMailRequest,
  ReSendConfirmationMailSuccessPayload,
  ReSendConfirmationMailSuccess,
  ReSendConfirmationMailFailurePayload,
  ReSendConfirmationMailFailure,
  StudentEnrolmentWithdrawnFailure,
  StudentEnrolmentWithdrawnFailurePayload,
  StudentEnrolmentWithdrawnPayload,
  StudentEnrolmentWithdrawnRequest,
  StudentEnrolmentWithdrawnSuccess,
  StudentEnrolmentWithdrawnSuccessPayload,
  ChangeConfirmationPreferenceRequest,
  ChangeConfirmationPreferenceRequestPayload,
  GenerateCertificatePayload,
  GenerateCertificateRequest,
  GenerateCertificateSuccessPayload,
  GenerateCertificateSuccess,
  GenerateCertificateFailurePayload,
  GenerateCertificateFailure,
  EditStudentProfileDetailsFailure,
  EditStudentProfileDetailsFailurePayload,
  EditStudentProfileDetailsPayload,
  EditStudentProfileDetailsRequest,
  EditStudentProfileDetailsSuccess,
  EditStudentProfileDetailsSuccessPayload,
  StudentProfileDetailsFailure,
  StudentProfileDetailsFailurePayload,
  StudentProfileDetailsPayload,
  StudentProfileDetailsRequest,
  StudentProfileDetailsSuccess,
  StudentProfileDetailsSuccessPayload,
  PrintEnrolmentSummaryFailure,
  PrintEnrolmentSummaryFailurePayload,
  PrintEnrolmentSummaryPayload,
  PrintEnrolmentSummaryRequest,
  PrintEnrolmentSummarySuccess,
  PrintEnrolmentSummarySuccessPayload,
} from "./types";

export const studentListRequest = (
  payload: StudentListPayload
): StudentListRequest => ({
  type: STUDENT_LIST_REQUEST,
  payload,
});

export const studentListSuccess = (
  payload: StudentListSuccessPayload
): StudentListSuccess => ({
  type: STUDENT_LIST_SUCCESS,
  payload,
});

export const studentListFailure = (
  payload: StudentListFailurePayload
): StudentListFailure => ({
  type: STUDENT_LIST_FAILURE,
  payload,
});

export const deleteStudentRequest = (
  payload: DeleteStudentPayload
): DeleteStudentRequest => ({
  type: DELETE_STUDENT_REQUEST,
  payload,
});

export const deleteStudentSuccess = (
  payload: DeleteStudentSuccessPayload
): DeleteStudentSuccess => ({
  type: DELETE_STUDENT_SUCCESS,
  payload,
});

export const deleteStudentFailure = (
  payload: DeleteStudentFailurePayload
): DeleteStudentFailure => ({
  type: DELETE_STUDENT_FAILURE,
  payload,
});

export const studnetNoteRequest = (
  payload: StudentNotePayload
): StudentNoteRequest => ({
  type: GET_STUDENTNOTE_REQUEST,
  payload,
});

export const studnetNoteSuccess = (
  payload: StudentNoteSuccessPayload
): StudentNoteSuccess => ({
  type: GET_STUDENTNOTE_SUCCESS,
  payload,
});

export const studnetNoteFailure = (
  payload: StudentNoteFailurePayload
): StudentNoteFailure => ({
  type: GET_STUDENTNOTE_FAILURE,
  payload,
});

export const editstudentNoteRequest = (
  payload: EditStudentNotePayload
): EditStudentNoteRequest => ({
  type: EDIT_STUDENTNOTE_REQUEST,
  payload,
});

export const editstudentNoteSuccess = (
  payload: EditStudentNoteSuccessPayload
): EditStudentNoteSuccess => ({
  type: EDIT_STUDENTNOTE_SUCCESS,
  payload,
});

export const editstudentNoteFailure = (
  payload: EditStudentNoteFailurePayload
): EditStudentNoteFailure => ({
  type: EDIT_STUDENTNOTE_FAILURE,
  payload,
});

export const confirmationPreferenceRequest = (
  payload: ConfirmationPreferencePayload
): ConfirmationPreferenceRequest => ({
  type: GET_CONFIRMATION_PREFERENCE_REQUEST,
  payload,
});

export const confirmationPreferenceSuccess = (
  payload: ConfirmationPreferenceSuccessPayload
): ConfirmationPreferenceSuccess => ({
  type: GET_CONFIRMATION_PREFERENCE_SUCCESS,
  payload,
});

export const confirmationPreferenceFailure = (
  payload: ConfirmationPreferenceFailurePayload
): ConfirmationPreferenceFailure => ({
  type: GET_CONFIRMATION_PREFERENCE_FAILURE,
  payload,
});

export const editConfirmationPreferenceRequest = (
  payload: EditConfirmationPreferencePayload
): EditConfirmationPreferenceRequest => ({
  type: EDIT_CONFIRMATION_PREFERENCE_REQUEST,
  payload,
});

export const editConfirmationPreferenceSuccess = (
  payload: EditConfirmationPreferenceSuccessPayload
): EditConfirmationPreferenceSuccess => ({
  type: EDIT_CONFIRMATION_PREFERENCE_SUCCESS,
  payload,
});

export const editConfirmationPreferenceFailure = (
  payload: EditConfirmationPreferenceFailurePayload
): EditConfirmationPreferenceFailure => ({
  type: EDIT_CONFIRMATION_PREFERENCE_FAILURE,
  payload,
});

export const studentDetailsRequest = (
  payload: StudnetDetailsPayload
): StudentDetailsRequest => ({
  type: GET_STUDENT_DETAILS_REQUEST,
  payload,
});

export const studentDetailsSuccess = (
  payload: StudnetDetailsSuccessPayload
): StudentDetailsSuccess => ({
  type: GET_STUDENT_DETAILS_SUCCESS,
  payload,
});

export const studentDetailsFailure = (
  payload: StudnetDetailsFailurePayload
): StudentDetailsFailure => ({
  type: GET_STUDENT_DETAILS_FAILURE,
  payload,
});

export const editStudentDetailRequest = (
  payload: EditStudentDetailsPayload
): EditStudentDetailsRequest => ({
  type: EDIT_STUDENT_DETAILS_REQUEST,
  payload,
});

export const editStudentDetailSuccess = (
  payload: EditStudentDetailsSuccessPayload
): EditStudentDetailsSuccess => ({
  type: EDIT_STUDENT_DETAILS_SUCCESS,
  payload,
});

export const editStudentDetailFailure = (
  payload: EditStudentDetailsFailurePayload
): EditStudentDetailsFailure => ({
  type: EDIT_STUDENT_DETAILS_FAILURE,
  payload,
});

export const researchProfileDetailsRequest = (
  payload: ResearchProfileDetailsPayload
): ResearchProfileDetailsRequest => ({
  type: GET_RESEARCH_PROFILE_DETAILS_REQUEST,
  payload,
});

export const researchProfileDetailsSuccess = (
  payload: ResearchProfileDetailsSuccessPayload
): ResearchProfileDetailsSuccess => ({
  type: GET_RESEARCH_PROFILE_DETAILS_SUCCESS,
  payload,
});

export const researchProfileDetailsFailure = (
  payload: ResearchProfileDetailsFailurePayload
): ResearchProfileDetailsFailure => ({
  type: GET_RESEARCH_PROFILE_DETAILS_FAILURE,
  payload,
});

export const editResearchProfileDetailRequest = (
  payload: EditResearchProfileDetailsPayload
): EditResearchProfileDetailsRequest => ({
  type: EDIT_RESEARCH_PROFILE_DETAILS_REQUEST,
  payload,
});

export const editResearchProfileDetailSuccess = (
  payload: EditResearchProfileDetailsSuccessPayload
): EditResearchProfileDetailsSuccess => ({
  type: EDIT_RESEARCH_PROFILE_DETAILS_SUCCESS,
  payload,
});

export const editResearchProfileDetailFailure = (
  payload: EditResearchProfileDetailsFailurePayload
): EditResearchProfileDetailsFailure => ({
  type: EDIT_RESEARCH_PROFILE_DETAILS_FAILURE,
  payload,
});

export const staffDetailsRequest = (
  payload: StaffDetailsPayload
): StaffDetailsRequest => ({
  type: GET_STAFF_DETAILS_REQUEST,
  payload,
});

export const staffDetailsSuccess = (
  payload: StaffDetailsSuccessPayload
): StaffDetailsSuccess => ({
  type: GET_STAFF_DETAILS_SUCCESS,
  payload,
});

export const staffDetailsFailure = (
  payload: StaffDetailsFailurePayload
): StaffDetailsFailure => ({
  type: GET_STAFF_DETAILS_FAILURE,
  payload,
});

export const editStaffDetailRequest = (
  payload: EditStaffDetailsPayload
): EditStaffDetailsDetailsRequest => ({
  type: EDIT_STAFF_DETAILS_REQUEST,
  payload,
});

export const editStaffDetailSuccess = (
  payload: EditStaffDetailsSuccessPayload
): EditStaffDetailsDetailsSuccess => ({
  type: EDIT_STAFF_DETAILS_SUCCESS,
  payload,
});

export const editStaffDetailFailure = (
  payload: EditStaffDetailsFailurePayload
): EditStaffDetailsDetailsFailure => ({
  type: EDIT_STAFF_DETAILS_FAILURE,
  payload,
});

export const mediaConsentRequest = (
  payload: MediaConsentPayload
): MediaConsentRequest => ({
  type: GET_MEDIA_CONSENT_REQUEST,
  payload,
});

export const mediaConsentSuccess = (
  payload: MediaConsentPayloadSuccessPayload
): MediaConsentSuccess => ({
  type: GET_MEDIA_CONSENT_SUCCESS,
  payload,
});

export const mediaConsentFailure = (
  payload: MediaConsentFailurePayload
): MediaConsentFailure => ({
  type: GET_MEDIA_CONSENT_FAILURE,
  payload,
});

export const editMediaConsentRequest = (
  payload: EditMediaConsentPayload
): EditMediaConsentRequest => ({
  type: EDIT_MEDIA_CONSENT_REQUEST,
  payload,
});

export const editMediaConsentSuccess = (
  payload: EditMediaConsentSuccessPayload
): EditMediaConsentSuccess => ({
  type: EDIT_MEDIA_CONSENT_SUCCESS,
  payload,
});

export const editMediaConsentFailure = (
  payload: EditMediaConsentFailurePayload
): EditMediaConsentFailure => ({
  type: EDIT_MEDIA_CONSENT_FAILURE,
  payload,
});

export const enrollmentSummaryListRequest = (
  payload: EnrollmentSummaryListPayload
): EnrollmentSummaryListRequest => ({
  type: ENROLLMENT_SUMMARY_LIST_REQUEST,
  payload,
});

export const enrollmentSummaryListSuccess = (
  payload: EnrollmentSummaryListSuccessPayload
): EnrollmentSummaryListSuccess => ({
  type: ENROLLMENT_SUMMARY_LIST_SUCCESS,
  payload,
});

export const enrollmentSummaryListFailure = (
  payload: EnrollmentSummaryListFailurePayload
): EnrollmentSummaryListFailure => ({
  type: ENROLLMENT_SUMMARY_LIST_FAILURE,
  payload,
});

export const enrollmentAndAttendanceHistoryListRequest = (
  payload: EnrollmentAndAttendanceHistoryListPayload
): EnrollmentAndAttendanceHistoryListRequest => ({
  type: ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_REQUEST,
  payload,
});

export const enrollmentAndAttendanceHistoryListSuccess = (
  payload: EnrollmentAndAttendanceHistoryListSuccessPayload
): EnrollmentAndAttendanceHistoryListSuccess => ({
  type: ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_SUCCESS,
  payload,
});

export const enrollmentAndAttendanceHistoryListFailure = (
  payload: EnrollmentAndAttendanceHistoryListFailurePayload
): EnrollmentAndAttendanceHistoryListFailure => ({
  type: ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_FAILURE,
  payload,
});

export const studnetAttendanceListRequest = (
  payload: StudentAttendanceListPayload
): StudentAttenadanceListRequest => ({
  type: STUDENT_ATTENDANCE_LIST_REQUEST,
  payload,
});

export const studnetAttendanceListSuccess = (
  payload: StudentAttendanceListSuccessPayload
): StudentAttenadanceListSuccess => ({
  type: STUDENT_ATTENDANCE_LIST_SUCCESS,
  payload,
});

export const studnetAttendanceListFailure = (
  payload: StudentAttendanceListFailurePayload
): StudentAttenadanceListFailure => ({
  type: STUDENT_ATTENDANCE_LIST_FAILURE,
  payload,
});

export const getPendingEnrolmentListRequest = (
  payload: GetPendingEnrolmentListPayload
): GetPendingEnrolmentListRequest => ({
  type: GET_PENDING_ENROLLMENT_LIST_REQUEST,
  payload,
});

export const getPendingEnrolmentListSuccess = (
  payload: GetPendingEnrolmentListListSuccessPayload
): GetPendingEnrolmentListSuccess => ({
  type: GET_PENDING_ENROLLMENT_LIST_SUCCESS,
  payload,
});

export const getPendingEnrolmentListFailure = (
  payload: GetPendingEnrolmentListFailurePayload
): GetPendingEnrolmentListFailure => ({
  type: GET_PENDING_ENROLLMENT_LIST_FAILURE,
  payload,
});

export const getStudentEnrolmentForConfirmationRequest = (
  payload: GetStudentEnrolmentForConfirmationPayload
): GetStudentEnrolmentForConfirmationRequest => ({
  type: GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_REQUEST,
  payload,
});

export const getStudentEnrolmentForConfirmationSuccess = (
  payload: GetStudentEnrolmentForConfirmationSuccessPayload
): GetStudentEnrolmentForConfirmationSuccess => ({
  type: GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_SUCCESS,
  payload,
});

export const getStudentEnrolmentForConfirmationFailure = (
  payload: GetStudentEnrolmentForConfirmationFailurePayload
): GetStudentEnrolmentForConfirmationFailure => ({
  type: GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_FAILURE,
  payload,
});

export const approveEnrolmentByEmailSMSRequest = (
  payload: ApproveEnrolmentByEmailSMSPayload
): ApproveEnrolmentByEmailSMSRequest => ({
  type: APPROVE_ENROLMENT_BY_EMAIL_SMS_REQUEST,
  payload,
});

export const approveEnrolmentByEmailSMSSuccess = (
  payload: ApproveEnrolmentByEmailSMSSuccessPayload
): ApproveEnrolmentByEmailSMSSuccess => ({
  type: APPROVE_ENROLMENT_BY_EMAIL_SMS_SUCCESS,
  payload,
});

export const approveEnrolmentByEmailSMSFailure = (
  payload: ApproveEnrolmentByEmailSMSFailurePayload
): ApproveEnrolmentByEmailSMSFailure => ({
  type: APPROVE_ENROLMENT_BY_EMAIL_SMS_FAILURE,
  payload,
});

export const getCurrentCoursesForAddEnrolmentRequest = (
  payload: GetCurrentCoursesForAddEnrolmentPayload
): GetCurrentCoursesForAddEnrolmentRequest => ({
  type: GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_REQUEST,
  payload,
});

export const getCurrentCoursesForAddEnrolmentSuccess = (
  payload: GetCurrentCoursesForAddEnrolmentSuccessPayload
): GetCurrentCoursesForAddEnrolmentSuccess => ({
  type: GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_SUCCESS,
  payload,
});

export const getCurrentCoursesForAddEnrolmentFailure = (
  payload: GetCurrentCoursesForAddEnrolmentFailurePayload
): GetCurrentCoursesForAddEnrolmentFailure => ({
  type: GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_FAILURE,
  payload,
});

export const addNewEnrolmentFromStudentRequest = (
  payload: AddNewEnrolmentFromStudentPayload
): AddNewEnrolmentFromStudentRequest => ({
  type: ADD_NEW_ENROLMENT_FROM_STUDENT_REQUEST,
  payload,
});

export const addNewEnrolmentFromStudentSuccess = (
  payload: AddNewEnrolmentFromStudentSuccessPayload
): AddNewEnrolmentFromStudentSuccess => ({
  type: ADD_NEW_ENROLMENT_FROM_STUDENT_SUCCESS,
  payload,
});

export const addNewEnrolmentFromStudentFailure = (
  payload: AddNewEnrolmentFromStudentFailurePayload
): AddNewEnrolmentFromStudentFailure => ({
  type: ADD_NEW_ENROLMENT_FROM_STUDENT_FAILURE,
  payload,
});

export const reSendConfirmationMailRequest = (
  payload: ReSendConfirmationMailPayload
): ReSendConfirmationMailRequest => ({
  type: RE_SEND_CONFIRMATION_MAIL_REQUEST,
  payload,
});

export const reSendConfirmationMailSuccess = (
  payload: ReSendConfirmationMailSuccessPayload
): ReSendConfirmationMailSuccess => ({
  type: RE_SEND_CONFIRMATION_MAIL_SUCCESS,
  payload,
});

export const reSendConfirmationMailFailure = (
  payload: ReSendConfirmationMailFailurePayload
): ReSendConfirmationMailFailure => ({
  type: RE_SEND_CONFIRMATION_MAIL_FAILURE,
  payload,
});

export const studentEnrolmentWithdrawnRequest = (
  payload: StudentEnrolmentWithdrawnPayload
): StudentEnrolmentWithdrawnRequest => ({
  type: STUDENT_ENROLMENT_WITHDRAWN_REQUEST,
  payload,
});

export const studentEnrolmentWithdrawnSuccess = (
  payload: StudentEnrolmentWithdrawnSuccessPayload
): StudentEnrolmentWithdrawnSuccess => ({
  type: STUDENT_ENROLMENT_WITHDRAWN_SUCCESS,
  payload,
});

export const studentEnrolmentWithdrawnFailure = (
  payload: StudentEnrolmentWithdrawnFailurePayload
): StudentEnrolmentWithdrawnFailure => ({
  type: STUDENT_ENROLMENT_WITHDRAWN_FAILURE,
  payload,
});

export const changeConfirmationPreferenceRequest = (
  payload: ChangeConfirmationPreferenceRequestPayload
): ChangeConfirmationPreferenceRequest => ({
  type: CHANGE_CONFIRMATION_PREFERENCE_REQUEST,
  payload,
});

export const generateCertificateRequest = (
  payload: GenerateCertificatePayload
): GenerateCertificateRequest => ({
  type: GENERATE_CERTIFICATE_REQUEST,
  payload,
});

export const generateCertificateSuccess = (
  payload: GenerateCertificateSuccessPayload
): GenerateCertificateSuccess => ({
  type: GENERATE_CERTIFICATE_SUCCESS,
  payload,
});

export const generateCertificateFailure = (
  payload: GenerateCertificateFailurePayload
): GenerateCertificateFailure => ({
  type: GENERATE_CERTIFICATE_FAILURE,
  payload,
});

export const editStudentProfileDetailRequest = (
  payload: EditStudentProfileDetailsPayload
): EditStudentProfileDetailsRequest => ({
  type: EDIT_STUDENT_PROFILE_DETAILS_REQUEST,
  payload,
});

export const editStudentProfileDetailSuccess = (
  payload: EditStudentProfileDetailsSuccessPayload
): EditStudentProfileDetailsSuccess => ({
  type: EDIT_STUDENT_PROFILE_DETAILS_SUCCESS,
  payload,
});

export const editStudentProfileDetailFailure = (
  payload: EditStudentProfileDetailsFailurePayload
): EditStudentProfileDetailsFailure => ({
  type: EDIT_STUDENT_PROFILE_DETAILS_FAILURE,
  payload,
});

export const studentProfileDetailsRequest = (
  payload: StudentProfileDetailsPayload
): StudentProfileDetailsRequest => ({
  type: GET_STUDENT_PROFILE_DETAILS_REQUEST,
  payload,
});

export const studentProfileDetailsSuccess = (
  payload: StudentProfileDetailsSuccessPayload
): StudentProfileDetailsSuccess => ({
  type: GET_STUDENT_PROFILE_DETAILS_SUCCESS,
  payload,
});

export const studentProfileDetailsFailure = (
  payload: StudentProfileDetailsFailurePayload
): StudentProfileDetailsFailure => ({
  type: GET_STUDENT_PROFILE_DETAILS_FAILURE,
  payload,
});

export const printEnrolmentSummaryRequest = (
  payload: PrintEnrolmentSummaryPayload
): PrintEnrolmentSummaryRequest => ({
  type: PRINT_ENROLMENT_SUMMARY_REQUEST,
  payload,
});

export const printEnrolmentSummarySuccess = (
  payload: PrintEnrolmentSummarySuccessPayload
): PrintEnrolmentSummarySuccess => ({
  type: PRINT_ENROLMENT_SUMMARY_SUCCESS,
  payload,
});

export const printEnrolmentSummaryFailure = (
  payload: PrintEnrolmentSummaryFailurePayload
): PrintEnrolmentSummaryFailure => ({
  type: PRINT_ENROLMENT_SUMMARY_FAILURE,
  payload,
});
