/* eslint-disable react/jsx-boolean-value */
import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  IEnrolmentOptions,
  IReviewStepDetails,
} from "utils/interfaces/student-enrolment";
import { convertToDateTime } from "utils/helper";
import { DATE_PICKER_FORMAT } from "utils/constants/constant";
import { FormSteps } from "utils/enums/student-enrolment";
import { useForm } from "react-hook-form";
import projectTheme from "app.theme";
import FormStepButtons from "../FormStepButtons";

interface IReviewStepProps {
  isExistingStudent?: boolean;
  movePrev: (step: number, data: any, isNext: boolean) => void;
  moveNext: (isSubmit: boolean) => void;
  stepDetails: IReviewStepDetails | undefined;
  currentCourseListRequest: any;
  enrolmentOptionList: IEnrolmentOptions | undefined;
}

const ReviewStep: React.FC<IReviewStepProps> = ({
  enrolmentOptionList,
  stepDetails,
  moveNext,
  movePrev,
}) => {
  const handleBack = () => {
    movePrev(
      FormSteps.CourseSelection,
      {
        data: {
          courseIds: stepDetails?.selectedCourses.length && [
            ...stepDetails?.selectedCourses,
          ],
          courseNames: stepDetails?.selectedCoursesNames,
          preferredCourseDelivery: stepDetails?.preferredCourseDelivery,
        },
      },
      false
    );
  };
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    moveNext(true);
  };

  const getFormattedDate = (date: any) => {
    const stringDate = new Date(date?.toString());
    const formattedDate = convertToDateTime(stringDate, DATE_PICKER_FORMAT);

    return formattedDate;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className="finished-details-container">
        <Grid container rowSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
          <Grid item xs={12}>
            <Card className="stepper-card finished-details">
              <Box className="eligibility">
                <Typography variant="h4">Eligibility Criteria</Typography>
                <Typography variant="h6">
                  {
                    enrolmentOptionList?.eligibilityCriteria.find(
                      (ec) =>
                        ec.value ===
                        (stepDetails?.eligibilityCriteria as string)
                    )?.label
                  }
                </Typography>
              </Box>
              <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                personal details
              </Typography>
              <Box className="terms-read-only">
                <Grid
                  container
                  columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                  rowSpacing={{ xs: "16px", lg: "20px" }}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Student Name
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {`${stepDetails?.firstName} ${stepDetails?.lastName}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Date Of Birth
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {getFormattedDate(stepDetails?.dateOfBirth)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Phone Number
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.phoneNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Email Address
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.emailAddress}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Street Address
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.streetAddress}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Suburb
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.suburb}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      State
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.state}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Post Code
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.postCode}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box className="terms-read-only">
                <Typography variant="body2" className="detail-name">
                  Current Service Provider
                </Typography>
                <Typography variant="body1" className="detail-score">
                  {stepDetails?.studentEnrolment.isStaff
                    ? "-"
                    : enrolmentOptionList?.currentServiceProviders.find(
                        (csp) =>
                          csp.value ===
                          stepDetails?.studentEnrolment.currentServiceProviders
                      )?.label}
                </Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="stepper-card finished-details">
              <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                Emergency Contact details
              </Typography>
              <Box className="terms-read-only">
                <Grid
                  container
                  columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                  rowSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Contact Name
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.studentEnrolment.emergencyContactName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Contact Relationship
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {
                        stepDetails?.studentEnrolment
                          .emergencyContactRelationship
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Contact Phone
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.studentEnrolment.emergencyContactNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" className="detail-name">
                      Information to help us support you
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.studentEnrolment.information || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" className="detail-name">
                      Enrolment type
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      {stepDetails?.studentEnrolment.enrolmentType}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card className="stepper-card finished-details">
              {!stepDetails?.studentEnrolment.isStaff ? (
                <>
                  <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                    Questionnaire
                  </Typography>
                  <Box className="terms-read-only">
                    <Grid
                      container
                      columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      rowSpacing={{ xs: "16px", lg: "20px" }}
                    >
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          className="detail-name"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          How did you hear about the Recovery & Wellbeing
                          College?*
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.studentProfileDetails?.hearAboutRC}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2" className="detail-name">
                          Aboriginal/Torres strait islander identity
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.studentProfileDetails?.atsiIdentity}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Disability
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.studentProfileDetails?.disability}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Country of birth
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.studentProfileDetails?.countryOfBirth}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Language at home
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.studentProfileDetails?.languageAtHome ||
                            "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Ethnicity
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.studentProfileDetails?.ethnicity || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Gender identity
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.studentProfileDetails?.genderIdentity}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Completed Qualifications
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {
                            stepDetails?.studentProfileDetails
                              ?.completedQualifications
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Employment status
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {
                            enrolmentOptionList?.employmentStatus.find(
                              (es) =>
                                es.value ===
                                stepDetails?.studentProfileDetails
                                  ?.employmentStatus
                            )?.label
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Highest completed school level
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {
                            stepDetails?.studentProfileDetails
                              ?.highestCompletedSchoolLevel
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Currently Studying
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.studentProfileDetails?.isStuding}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Study load
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.studentProfileDetails?.studyLoad}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                    Staff details
                  </Typography>
                  <Box className="terms-read-only">
                    <Grid
                      container
                      columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      rowSpacing={{ xs: "16px", lg: "20px" }}
                    >
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          variant="body2"
                          className="detail-name"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Employment Status
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.staffEnrolment?.employmentStatus}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Current Role
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.staffEnrolment?.currentRole}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Work Environment
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.staffEnrolment?.workEnvironment}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Site/Service
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.staffEnrolment?.site}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Manager&#39;s Name
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.staffEnrolment?.managerName || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Manager&#39;s Email
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.staffEnrolment?.managerEmail || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Staff Link ID
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          {stepDetails?.staffEnrolment?.staffLinkId || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className="stepper-card finished-details">
              <Box className="terms-read-only">
                <Typography
                  variant="h3"
                  sx={{ marginBottom: "12px", fontWeight: "500" }}
                >
                  Selected Courses
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            [projectTheme.breakpoints.down("md")]: {
                              minWidth: "442px",
                            },
                            [projectTheme.breakpoints.down("sm")]: {
                              minWidth: "242px",
                            },
                          }}
                        >
                          Course
                        </TableCell>
                        <TableCell
                          align="center"
                          className="course-del"
                          sx={{
                            width: "240px",
                            [projectTheme.breakpoints.down("md")]: {
                              minWidth: "210px",
                            },
                          }}
                        >
                          Preferred Course Delivery
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stepDetails?.selectedCoursesNames.map((row) => {
                        return (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row}
                            </TableCell>
                            <TableCell align="center">
                              <span className="prefer-course-del">
                                Preferred Course Delivery
                              </span>
                              {
                                stepDetails.preferredCourseDelivery.find(
                                  (x) => x.courseName === row
                                )?.preferredCourseDelivery
                              }
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <FormStepButtons
          handleBack={handleBack}
          isSubmitBtn={true}
          isBackBtn={true}
        />
      </Box>
    </form>
  );
};

export default ReviewStep;
