export const SCHEDULED_OFFERING_LIST_REQUEST =
  "SCHEDULED_OFFERING_LIST_REQUEST";
export const SCHEDULED_OFFERING_LIST_SUCCESS =
  "SCHEDULED_OFFERING_LIST_SUCCESS";
export const SCHEDULED_OFFERING_LIST_FAILURE =
  "SCHEDULED_OFFERING_LIST_FAILURE";

export const ADD_SCHEDULED_OFFERING_REQUEST = "ADD_SCHEDULED_OFFERING_REQUEST";
export const ADD_SCHEDULED_OFFERING_SUCCESS = "ADD_SCHEDULED_OFFERING_SUCCESS";
export const ADD_SCHEDULED_OFFERING_FAILURE = "ADD_SCHEDULED_OFFERING_FAILURE";

export const EDIT_SCHEDULED_OFFERING_REQUEST =
  "EDIT_SCHEDULED_OFFERING_REQUEST";
export const EDIT_SCHEDULED_OFFERING_SUCCESS =
  "EDIT_SCHEDULED_OFFERING_SUCCESS";
export const EDIT_SCHEDULED_OFFERING_FAILURE =
  "EDIT_SCHEDULED_OFFERING_FAILURE";

export const DELETE_SCHEDULED_OFFERING_REQUEST =
  "DELETE_SCHEDULED_OFFERING_REQUEST";
export const DELETE_SCHEDULED_OFFERING_SUCCESS =
  "DELETE_SCHEDULED_OFFERING_SUCCESS";
export const DELETE_SCHEDULED_OFFERING_FAILURE =
  "DELETE_SCHEDULED_OFFERING_FAILURE";

export const SCO_DAYS_LIST_REQUEST = "SCO_DAYS_LIST_REQUEST";
export const SCO_DAYS_LIST_SUCCESS = "SCO_DAYS_LIST_SUCCESS";
export const SCO_DAYS_LIST_FAILURE = "SCO_DAYS_LIST_FAILURE";

export const GET_SCHEDULED_OFFERING_BY_ID_REQUEST =
  "GET_SCHEDULED_OFFERING_BY_ID_REQUEST";
export const GET_SCHEDULED_OFFERING_BY_ID_SUCCESS =
  "GET_SCHEDULED_OFFERING_BY_ID_SUCCESS";
export const GET_SCHEDULED_OFFERING_BY_ID_FAILURE =
  "GET_SCHEDULED_OFFERING_BY_ID_FAILURE";

export const SESSION_LIST_BY_SCO_ID_REQUEST = "SESSION_LIST_BY_SCO_ID_REQUEST";
export const SESSION_LIST_BY_SCO_ID_SUCCESS = "SESSION_LIST_BY_SCO_ID_SUCCESS";
export const SESSION_LIST_BY_SCO_ID_FAILURE = "SESSION_LIST_BY_SCO_ID_FAILURE";

export const ADD_SESSION_DAY_REQUEST = "ADD_SESSION_DAY_REQUEST";
export const ADD_SESSION_DAY_SUCCESS = "ADD_SESSION_DAY_SUCCESS";
export const ADD_SESSION_DAY_FAILURE = "ADD_SESSION_DAY_FAILURE";

export const EDIT_SESSION_DAY_REQUEST = "EDIT_SESSION_DAY_REQUEST";
export const EDIT_SESSION_DAY_SUCCESS = "EDIT_SESSION_DAY_SUCCESS";
export const EDIT_SESSION_DAY_FAILURE = "EDIT_SESSION_DAY_FAILURE";

export const DELETE_SESSION_DAY_REQUEST = "DELETE_SESSION_DAY_REQUEST";
export const DELETE_SESSION_DAY_SUCCESS = "DELETE_SESSION_DAY_SUCCESS";
export const DELETE_SESSION_DAY_FAILURE = "DELETE_SESSION_DAY_FAILURE";

export const DOWNLOAD_DOC_REQUEST = "DOWNLOAD_DOC_REQUEST";
export const DOWNLOAD_DOC_SUCCESS = "DOWNLOAD_DOC_SUCCESS";
export const DOWNLOAD_DOC_FAILURE = "DOWNLOAD_DOC_FAILURE";

export const UPLOAD_DOC_REQUEST = "UPLOAD_DOC_REQUEST";
export const UPLOAD_DOC_SUCCESS = "UPLOAD_DOC_SUCCESS";
export const UPLOAD_DOC_FAILURE = "UPLOAD_DOC_FAILURE";

export const DELETE_DOC_REQUEST = "DELETE_DOC_REQUEST";
export const DELETE_DOC_SUCCESS = "DELETE_DOC_SUCCESS";
export const DELETE_DOC_FAILURE = "DELETE_DOC_FAILURE";

export const CANCEL_SESSION_DAY_REQUEST = "CANCEL_SESSION_DAY_REQUEST";
export const CANCEL_SESSION_DAY_SUCCESS = "CANCEL_SESSION_DAY_SUCCESS";
export const CANCEL_SESSION_DAY_FAILURE = "CANCEL_SESSION_DAY_FAILURE";

export const ADD_SCO_EDUCATOR_REQUEST = "ADD_SCO_EDUCATOR_REQUEST";
export const ADD_SCO_EDUCATOR_SUCCESS = "ADD_SCO_EDUCATOR_SUCCESS";
export const ADD_SCO_EDUCATOR_FAILURE = "ADD_SCO_EDUCATOR_FAILURE";

export const DELETE_SCO_EDUCATOR_REQUEST = "DELETE_SCO_EDUCATOR_REQUEST";
export const DELETE_SCO_EDUCATOR_SUCCESS = "DELETE_SCO_EDUCATOR_SUCCESS";
export const DELETE_SCO_EDUCATOR_FAILURE = "DELETE_SCO_EDUCATOR_FAILURE";

export const ENROLED_STUDENT_LIST_REQUEST = "ENROLED_STUDENT_LIST_REQUEST";
export const ENROLED_STUDENT_LIST_SUCCESS = "ENROLED_STUDENT_LIST_SUCCESS";
export const ENROLED_STUDENT_LIST_FAILURE = "ENROLED_STUDENT_LIST_FAILURE";

export const SCO_STUDENT_ATTENDANCE_LIST_REQUEST =
  "SCO_STUDENT_ATTENDANCE_LIST_REQUEST";
export const SCO_STUDENT_ATTENDANCE_LIST_SUCCESS =
  "SCO_STUDENT_ATTENDANCE_LIST_SUCCESS";
export const SCO_STUDENT_ATTENDANCE_LIST_FAILURE =
  "SCO_STUDENT_ATTENDANCE_LIST_FAILURE";

export const STUDENT_FILL_ATTENDANCE_REQUEST =
  "STUDENT_FILL_ATTENDANCE_REQUEST";
export const STUDENT_FILL_ATTENDANCE_SUCCESS =
  "STUDENT_FILL_ATTENDANCE_SUCCESS";
export const STUDENT_FILL_ATTENDANCE_FAILURE =
  "STUDENT_FILL_ATTENDANCE_FAILURE";

export const SCO_STUDENT_WHO_ATTENDED_LIST_REQUEST =
  "SCO_STUDENT_WHO_ATTENDED_LIST_REQUEST";
export const SCO_STUDENT_WHO_ATTENDED_LIST_SUCCESS =
  "SCO_STUDENT_WHO_ATTENDED_LIST_SUCCESS";
export const SCO_STUDENT_WHO_ATTENDED_LIST_FAILURE =
  "SCO_STUDENT_WHO_ATTENDED_LIST_FAILURE";

export const ENROLED_COUNT_REQUEST = "ENROLED_COUNT_REQUEST";
export const ENROLED_COUNT_SUCCESS = "ENROLED_COUNT_SUCCESS";
export const ENROLED_COUNT_FAILURE = "ENROLED_COUNT_FAILURE";

export const FILL_GENERATE_CERTIFICATE_REQUEST =
  "FILL_GENERATE_CERTIFICATE_REQUEST";
export const FILL_GENERATE_CERTIFICATE_SUCCESS =
  "STUDENT_FILL_ATTENDANCE_SUCCESS";
export const FILL_GENERATE_CERTIFICATE_FAILURE =
  "STUDENT_FILL_ATTENDANCE_FAILURE";

export const SCO_GENERATE_CERTIFICATE_LIST_REQUEST =
  "SCO_GENERATE_CERTIFICATE_LIST_REQUEST";
export const SCO_GENERATE_CERTIFICATE_LIST_SUCCESS =
  "SCO_GENERATE_CERTIFICATE_LIST_SUCCESS";
export const SCO_GENERATE_CERTIFICATE_LIST_FAILURE =
  "SCO_GENERATE_CERTIFICATE_LIST_FAILURE";

export const SAVE_ATTENDANCE_REQUEST = "SAVE_ATTENDANCE_REQUEST";

export const PRINT_COURSE_ENROLMENTS_REQUEST =
  "PRINT_COURSE_ENROLMENTS_REQUEST";
export const PRINT_COURSE_ENROLMENTS_SUCCESS =
  "PRINT_COURSE_ENROLMENTS_SUCCESS";
export const PRINT_COURSE_ENROLMENTS_FAILURE =
  "PRINT_COURSE_ENROLMENTS_FAILURE";

export const SAVE_GENERATE_CERTIFICATE_REQUEST =
  "SAVE_GENERATE_CERTIFICATE_REQUEST";

export const GET_EDUCATOR_REFLECTION_FORM_REQUEST =
  "GET_EDUCATOR_REFLECTION_FORM_REQUEST";
export const GET_EDUCATOR_REFLECTION_FORM_SUCCESS =
  "GET_EDUCATOR_REFLECTION_FORM_SUCCESS";
export const GET_EDUCATOR_REFLECTION_FORM_FAILURE =
  "GET_EDUCATOR_REFLECTION_FORM_FAILURE";
