import { all, call, put, takeLatest } from "redux-saga/effects";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import {
  addEnrolmentFailure,
  addEnrolmentSuccess,
  approveEnrolmentFailure,
  approveEnrolmentSuccess,
  checkValidEnrolmentFailure,
  checkValidEnrolmentSuccess,
  checkValidReCaptchaFailure,
  checkValidReCaptchaSuccess,
  currentCourseListFailure,
  currentCourseListSuccess,
  enrolmentOptionListFailure,
  enrolmentOptionListSuccess,
  rejectEnrolmentFailure,
  rejectEnrolmentSuccess,
  studentEnrolmentFailure,
  getStudentEnrolmentDataForApproveFailure,
  getStudentEnrolmentDataForApproveSuccess,
  studentEnrolmentListFailure,
  studentEnrolmentListSuccess,
  studentEnrolmentSuccess,
  changeEnrolmentStatusFailure,
  changeEnrolmentStatusSuccess,
} from "./actions";
import {
  ADD_ENROLMENT_REQUEST,
  APPROVE_ENROLMENT_REQUEST,
  CHECK_VALID_ENROLMENT_REQUEST,
  CHECK_VALID_RECAPTCHA_REQUEST,
  CURRENT_ENROL_COURSE_LIST_REQUEST,
  ENROLMENT_OPTION_LIST_REQUEST,
  REJECT_ENROLMENT_REQUEST,
  STUDENT_DETAIL_REQUEST,
  GET_STUDENT_ENROLMENT_FOR_APPROVAL_REQUEST,
  STUDENT_ENROLMENT_LIST_REQUEST,
  CHANGE_ENROLMENT_STATUS_REQUEST,
} from "./actionTypes";
import studentEnrolmentService from "./service";
import {
  AddEnrolmentResponse,
  ApproveEnrolmentResponse,
  CheckValidEnrolmentResponse,
  CheckValidReCaptchaResponse,
  CurrentCourseListResponse,
  RejectEnrolmentResponse,
  StudentEnrolmentDetailResponse,
  GetStudentEnrolmentDataForApproveResponse,
  StudentEnrolmentListResponse,
  ChangeEnrolmentStatusResponse,
} from "./types";

function* enrolmentOptionListSaga(action: any) {
  try {
    const response: IEnrolmentOptions = yield call(
      studentEnrolmentService.getEnrolmentOptionList
    );

    yield put(
      enrolmentOptionListSuccess({
        enrolmentOptionList: response,
      })
    );
    action.payload.callback({
      enrolmentOptionList: response,
    });
  } catch (e: any) {
    yield put(
      enrolmentOptionListFailure({
        error: e.message,
      })
    );
  }
}

function* currentCourseListSaga(action: any) {
  try {
    const response: CurrentCourseListResponse = yield call(
      studentEnrolmentService.getCurrentCourseList
    );

    yield put(
      currentCourseListSuccess({
        currentCourseList: response.item,
      })
    );
    action.payload.callback({
      currentCourseList: response.item,
    });
  } catch (e: any) {
    yield put(
      currentCourseListFailure({
        error: e.message,
      })
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* studentEnrolmentDetailSaga(action: any) {
  try {
    const response: StudentEnrolmentDetailResponse = yield call(
      studentEnrolmentService.getStudentEnrolmentDetail,
      {
        id: action.payload.values.id,
      }
    );
    yield put(
      studentEnrolmentSuccess({
        studentDetail: response.item,
      })
    );
    action.payload.callback({
      studentDetail: response.item,
    });
  } catch (e: any) {
    yield put(
      studentEnrolmentFailure({
        error: e.message,
      })
    );
  }
}

function* rejectEnrolmentSaga(action: any) {
  try {
    const response: RejectEnrolmentResponse = yield call(
      studentEnrolmentService.rejectEnrolment,
      {
        studentId: action.payload.values.id,
        rejectionMessage: action.payload.values.reasonOfRejection,
      }
    );
    yield put(
      rejectEnrolmentSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      rejectEnrolmentFailure({
        error: e.message,
      })
    );
  }
}

function* addEnrolmentSaga(action: any) {
  try {
    const response: AddEnrolmentResponse = yield call(
      studentEnrolmentService.addEnrolment,
      {
        isStudentExists: action.payload.values.isStudentExists,
        eligibilityCriteria: action.payload.values.eligibilityCriteria,
        firstName: action.payload.values.firstName,
        lastName: action.payload.values.lastName,
        dateOfBirth: action.payload.values.dateOfBirth,
        phoneNumber: action.payload.values.phoneNumber,
        emailAddress: action.payload.values.emailAddress,
        streetAddress: action.payload.values.streetAddress,
        suburb: action.payload.values.suburb,
        state: action.payload.values.state,
        postCode: action.payload.values.postCode,
        studentEnrolment: action.payload.values.studentEnrolment,
        staffEnrolment: action.payload.values.staffEnrolment,
        studentProfileDetails: action.payload.values.studentProfileDetails,
        selectedCourses: action.payload.values.selectedCourses,
        preferredCourseDelivery: action.payload.values.preferredCourseDelivery,
      }
    );

    yield put(
      addEnrolmentSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addEnrolmentFailure({
        error: e.message,
      })
    );
  }
}

function* checkValidEnrolmentSaga(action: any) {
  try {
    const response: CheckValidEnrolmentResponse = yield call(
      studentEnrolmentService.checkValidEnrolment,
      {
        firstName: action.payload.values.firstName,
        lastName: action.payload.values.lastName,
        dateOfBirth: action.payload.values.dateOfBirth,
        emailAddress: action.payload.values.emailAddress,
      }
    );

    yield put(
      checkValidEnrolmentSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      checkValidEnrolmentFailure({
        error: e.message,
      })
    );
  }
}

function* studentEnrolmentListSaga(action: any) {
  try {
    const payload: any = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      name: action.payload.values.name,
    };

    const response: StudentEnrolmentListResponse = yield call(
      studentEnrolmentService.getStudentEnrolmentList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      studentEnrolmentListSuccess({
        studentEnrolmentList: response.item.records,
        studentEnrolmentListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      studentEnrolmentList: response.item.records,
      studentEnrolmentListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      studentEnrolmentListFailure({
        error: e.message,
      })
    );
  }
}

function* approveEnrolmentSaga(action: any) {
  try {
    const response: ApproveEnrolmentResponse = yield call(
      studentEnrolmentService.approveEnrollment,
      {
        studentId: action.payload.values.studentId,
        enrolmentConfirmPref: action.payload.values.enrolmentConfirmPref,
        sector: action.payload.values.sector,
        researchCode: action.payload.values.researchCode,
        mrn: action.payload.values.mrn,
        activeCommunityEmr: action.payload.values.activeCommunityEmr,
        status: action.payload.values.status,
        course: action.payload.values.course,
      }
    );

    yield put(
      approveEnrolmentSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
        student: response.item,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
      student: response.item,
    });
  } catch (e: any) {
    yield put(
      approveEnrolmentFailure({
        error: e.message,
      })
    );
  }
}

function* getStudentEnrolmentForApproval(action: any) {
  try {
    const response: GetStudentEnrolmentDataForApproveResponse = yield call(
      studentEnrolmentService.getStudentEnrolmentDataForApprove,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      getStudentEnrolmentDataForApproveSuccess({
        studentApproveEnrolmentData: response.item,
      })
    );
    action.payload.callback({
      studentApproveEnrolmentData: response.item,
    });
  } catch (e: any) {
    yield put(
      getStudentEnrolmentDataForApproveFailure({
        error: e.message,
      })
    );
  }
}

function* checkValidReCaptchaSaga(action: any) {
  try {
    const response: CheckValidReCaptchaResponse = yield call(
      studentEnrolmentService.checkValidReCaptcha,
      {
        reCaptchaToken: action.payload.values.reCaptchaToken,
      }
    );
    yield put(
      checkValidReCaptchaSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      checkValidReCaptchaFailure({
        error: e.message,
      })
    );
  }
}

function* changeEnrolmentStatusSaga(action: any) {
  try {
    const response: ChangeEnrolmentStatusResponse = yield call(
      studentEnrolmentService.changeEnrolmentStatus,
      {
        id: action.payload.values.id,
        enrolmentStatus: action.payload.values.enrolmentStatus,
      }
    );

    yield put(
      changeEnrolmentStatusSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      changeEnrolmentStatusFailure({
        error: e.message,
      })
    );
  }
}

function* studentEnrolmentSaga() {
  yield all([
    takeLatest(ENROLMENT_OPTION_LIST_REQUEST, enrolmentOptionListSaga),
  ]);
  yield all([
    takeLatest(CURRENT_ENROL_COURSE_LIST_REQUEST, currentCourseListSaga),
  ]);
  yield all([takeLatest(ADD_ENROLMENT_REQUEST, addEnrolmentSaga)]);
  yield all([
    takeLatest(CHECK_VALID_ENROLMENT_REQUEST, checkValidEnrolmentSaga),
  ]);
  yield all([
    takeLatest(STUDENT_ENROLMENT_LIST_REQUEST, studentEnrolmentListSaga),
  ]);
  yield all([takeLatest(STUDENT_DETAIL_REQUEST, studentEnrolmentDetailSaga)]);
  yield all([takeLatest(REJECT_ENROLMENT_REQUEST, rejectEnrolmentSaga)]);
  yield all([takeLatest(APPROVE_ENROLMENT_REQUEST, approveEnrolmentSaga)]);
  yield all([
    takeLatest(
      GET_STUDENT_ENROLMENT_FOR_APPROVAL_REQUEST,
      getStudentEnrolmentForApproval
    ),
  ]);
  yield all([
    takeLatest(CHECK_VALID_RECAPTCHA_REQUEST, checkValidReCaptchaSaga),
  ]);
  yield all([
    takeLatest(CHANGE_ENROLMENT_STATUS_REQUEST, changeEnrolmentStatusSaga),
  ]);
}

export default studentEnrolmentSaga;
