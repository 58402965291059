import { connect, MapDispatchToProps } from "react-redux";
import {
  IEnrollmentSummary,
  IEnrollmentSummaryDispatch,
} from "utils/interfaces/student";
import {
  enrollmentSummaryListRequest,
  studentEnrolmentWithdrawnRequest,
  printEnrolmentSummaryRequest,
} from "store/student/action";
import EnrollmentSummaryComponent, {
  EnrollmentSummaryProps,
} from "pages/Students/EnrollmentSummary/index";

const mapDispatchToProps: MapDispatchToProps<
  IEnrollmentSummaryDispatch,
  IEnrollmentSummary
> = {
  enrollmentSummaryListRequest,
  studentEnrolmentWithdrawnRequest,
  printEnrolmentSummaryRequest,
};

const connector = connect(null, mapDispatchToProps);

const Student: React.FC<EnrollmentSummaryProps> = (props) => {
  return <EnrollmentSummaryComponent {...props} />;
};

export default connector(Student);
