import { IUserContainerDispatch, IUser } from "utils/interfaces/user";
import { MapDispatchToProps, connect } from "react-redux";
import {
  userListRequest,
  addUserRequest,
  editUserRequest,
  userByIdRequest,
  deleteUserRequest,
} from "store/user/action";
import UsersComponent, { UserProps } from "pages/Users";

const mapDispatchToProps: MapDispatchToProps<IUserContainerDispatch, IUser> = {
  userListRequest,
  addUserRequest,
  editUserRequest,
  userByIdRequest,
  deleteUserRequest,
};

const connector = connect(null, mapDispatchToProps);

const Users: React.FC<UserProps> = (props) => {
  return <UsersComponent {...props} />;
};

export default connector(Users);
