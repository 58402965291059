import baseService from "services/base-service";

const dashboardBaseUrl = `/api/Dashboard`;

const getStatisticsData = () =>
  baseService.get(`${dashboardBaseUrl}/GetStatistics`);

export default {
  getStatisticsData,
};
