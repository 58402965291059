import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosError } from "axios";
import { ICourseReportPagePayloadValues } from "utils/interfaces/course-report";
import {
  printCourseReportFailure,
  printCourseReportSuccess,
  courseReportListFailure,
  courseReportListSuccess,
} from "./actions";
import {
  PRINT_COURSE_REPORT_REQUEST,
  COURSE_REPORT_LIST_REQUEST,
} from "./actionTypes";
import courseReportService from "./service";
import {
  CourseReportListRequest,
  CourseReportListResponse,
  PrintCourseReportRequest,
  PrintCourseReportResponse,
} from "./types";

function* courseReportListSaga(action: CourseReportListRequest) {
  try {
    const payload: ICourseReportPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };
    if (action.payload.values.locations) {
      payload.locations = action.payload.values.locations;
    }
    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }
    if (action.payload.values.year) {
      payload.year = action.payload.values.year;
    }

    const response: CourseReportListResponse = yield call(
      courseReportService.getCourseReportList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      courseReportListSuccess({
        courseReportList: response.item.records,
        courseReportListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      courseReportList: response.item.records,
      courseReportListPagination: paginationDetails,
    });
  } catch (e) {
    yield put(
      courseReportListFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* printCourseReportSaga(action: PrintCourseReportRequest) {
  try {
    const payload: ICourseReportPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };
    if (action.payload.values.locations) {
      payload.locations = action.payload.values.locations;
    }
    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }
    if (action.payload.values.year) {
      payload.year = action.payload.values.year;
    }

    const response: PrintCourseReportResponse = yield call(
      courseReportService.printCourseReport,
      payload
    );

    yield put(
      printCourseReportSuccess({
        item: response.item,
      })
    );
    action.payload.callback({
      item: response.item,
    });
  } catch (e) {
    yield put(
      printCourseReportFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* courseReportSaga() {
  yield all([takeLatest(COURSE_REPORT_LIST_REQUEST, courseReportListSaga)]);
  yield all([takeLatest(PRINT_COURSE_REPORT_REQUEST, printCourseReportSaga)]);
}

export default courseReportSaga;
