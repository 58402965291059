import { connect, MapDispatchToProps } from "react-redux";
import StudentsComponent, { StudentsProps } from "pages/Students";
import { IStudent, IStudentsContainerDispatch } from "utils/interfaces/student";
import { deleteStudentRequest, studentListRequest } from "store/student/action";

const mapDispatchToProps: MapDispatchToProps<
  IStudentsContainerDispatch,
  IStudent
> = {
  studentListRequest,
  deleteStudentRequest,
};

const connector = connect(null, mapDispatchToProps);

const Student: React.FC<StudentsProps> = (props) => {
  return <StudentsComponent {...props} />;
};

export default connector(Student);
