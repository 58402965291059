import { Box } from "@mui/system";
import {
  Button,
  Card,
  Checkbox,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  closeIcon,
  courseListIcon,
  courseListLightIcon,
  courseListSecondaryIcon,
  deleteIcon,
  editIcon,
  educatorsTotalIcon,
  filterLightIcon,
  filterPrimaryIcon,
  plusLightIcon,
} from "assets/images";
import React, { useEffect, useState } from "react";
import projectTheme from "app.theme";
import {
  IAddEditEducator,
  IEducator,
  IEducatorContainerDispatch,
  IEducatorContainerState,
  IEducatorListMeta,
  IEducatorsDetails,
  IEnhancedTableProps,
  IEducatorPagePayloadValues,
  IEducatorStatisticsData,
  IEducatorStatisticsPayloadValues,
} from "utils/interfaces/educator";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPagePermissions,
  roleRenderValue,
  showLoader,
  showLoaderForBatchApiCall,
  hideLoaderForBatchApiCall,
  allowSearch,
  getPaginationDetailText,
  getPagePayloadValues,
  onTextFieldKeyPress,
} from "utils/helper";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  IRoleModulePermission,
  RoleListSuccessPayload,
} from "store/role/types";
import {
  DeleteEducatorSuccessPayload,
  EducatorListSuccessPayload,
  EducatorStatisticsDataSuccessPayload,
} from "store/educator/types";
import {
  CLEAR_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DELETE_TOOLTIP_TITLE,
  EDIT_TOOLTIP_TITLE,
  MAX_INT_VALUE,
  PAGE_SIZES,
  ROLE_NAME,
  STUDENT_ROLE_ID,
} from "utils/constants/constant";
import { EDUCATOR_STATUS, EDUCATOR_TYPES } from "utils/constants/educator";
import { IRole, IRolePagePayloadValues } from "utils/interfaces/role";
import { roleListRequest } from "store/role/actions";
import { EducatorStatus } from "utils/enums/educator";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import NoRecords from "components/NoRecords";
import { AppRoutings } from "utils/enums/app-routings";
import {
  EDUCATOR_DELETE_SUCCESS_MSG,
  SELECT_MIN_ONE_FIELD,
} from "utils/constants/Messages";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { AppPages } from "utils/enums/app-pages";
import OverlayBox from "components/OverlayBox";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { termListRequest } from "store/term/actions";
import { ITerm, ITermPagePayloadValues } from "utils/interfaces/term";
import { TermListSuccessPayload } from "store/term/types";
import { ExportToCsv } from "export-to-csv";
import EducatorFilter from "./EducatorFilter";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IEducatorsDetails) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type EducatorProps = IEducatorContainerState &
  IEducatorContainerDispatch;

const Educators: React.FC<EducatorProps> = (props) => {
  const [educatorList, setEducatorList] = useState<IEducator[]>();
  const [educatorListMeta, setEducatorListMeta] = useState<IEducatorListMeta>();
  const [page, setPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IEducatorsDetails>("name");
  const [searchText, setSearchText] = useState<string>("");
  const [filterBox, setFilterBox] = useState(false);
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [filteredData, setFilteredData] = useState<IAddEditEducator>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
  const [isOpenEducatorDeleteModal, setIsOpenEducatorDeleteModal] =
    useState(false);
  const [deleteEducatorId, setDeleteEducatorId] = useState<number>();
  const [roleList, setRoleList] = useState<IRole[]>([]);
  const [terms, setTerms] = useState<string[]>([]);
  const [termList, setTermList] = useState<ITerm[]>();
  const [statisticsData, setStatisticsData] = useState<IEducatorStatisticsData>(
    {
      totalEducators: 0,
      totalPeerEducators: 0,
      totalOtherEducators: 0,
      totalClinicalEducators: 0,
    }
  );
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const dispatch = useDispatch();

  const handleTermChange = (event: SelectChangeEvent<typeof terms>) => {
    const {
      target: { value },
    } = event;
    setTerms(typeof value === "string" ? value.split(",") : value);
  };

  const termRenderValue = (selected: Array<string>) => {
    const selectedString: string[] = [];
    const selectedTerm = selected.map((select) => +select);
    selectedTerm.forEach((item: number) => {
      const termItem = termList?.find((term) => term.id === item);
      if (termItem) {
        selectedString.push(termItem.termName);
      }
    });

    return selectedString.join(", ");
  };

  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
  };

  const getTermList = () => {
    showLoaderForBatchApiCall();

    const values: ITermPagePayloadValues = getPagePayloadValues(
      "TermName",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.termName = "";

    const payload = {
      values,
      callback: onTermListSuccess,
    };

    dispatch(termListRequest(payload));
  };

  const getStatusLabel = (status: number) => {
    let statusString = "";
    EDUCATOR_STATUS?.forEach((item) => {
      if (item.value === status) {
        statusString = item.label;
      }
    });

    return statusString;
  };

  const getEducatorType = (typeId: number) => {
    const typeItem = EDUCATOR_TYPES.find((type) => type.value === typeId);

    return typeItem?.label;
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IEducatorsDetails) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleEducatorDeleteModal = (educatorId: number) => {
    setDeleteEducatorId(educatorId);
    setIsOpenEducatorDeleteModal(true);
  };

  const handleEducatorDeleteCloseModal = () => {
    setDeleteEducatorId(0);
    setIsOpenEducatorDeleteModal(false);
  };

  const onEducatorListSuccess = (response: EducatorListSuccessPayload) => {
    setEducatorList(response.educatorList);
    setEducatorListMeta(response.educatorListPagination);
    hideLoaderForBatchApiCall();
  };

  const getEducatorList = async (
    filterData?: IAddEditEducator,
    filtered?: boolean,
    pageNum?: number
  ) => {
    const { educatorListRequest } = props;

    if (educatorListRequest) {
      showLoaderForBatchApiCall();

      const values: IEducatorPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.name = filtered ? "" : searchText.trim();

      if (filterData?.educatorType) {
        values.educatorType = filterData?.educatorType;
      }
      if (
        filterData?.status === EducatorStatus.Inactive ||
        filterData?.status === EducatorStatus.Active
      ) {
        values.status = filterData?.status;
      }
      if (filterData?.roleFilter) {
        values.roleId = filterData?.roleFilter;
      }
      if (terms.length > 0) {
        values.term = terms.map((term) => +term);
      }
      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onEducatorListSuccess,
      };

      educatorListRequest(payload);
    }
  };

  const onRoleListSuccess = (response: RoleListSuccessPayload) => {
    setRoleList(response.roleList);
  };

  const getRoleList = async () => {
    showLoaderForBatchApiCall();

    const values: IRolePagePayloadValues = getPagePayloadValues(
      ROLE_NAME,
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.roleName = "";

    const payload = {
      values,
      callback: onRoleListSuccess,
    };

    dispatch(roleListRequest(payload));
  };

  const onStatisticsDataSuccess = (
    response: EducatorStatisticsDataSuccessPayload
  ) => {
    setStatisticsData(response.statisticsData);
    if (!pagePermission?.view) {
      hideLoaderForBatchApiCall();
    }
  };

  const getStatisticsData = async (
    filterData?: IAddEditEducator,
    filtered?: boolean
  ) => {
    const { educatorStatisticsDataRequest } = props;
    if (educatorStatisticsDataRequest) {
      showLoaderForBatchApiCall();
      const values: IEducatorStatisticsPayloadValues = {
        name: filtered ? "" : searchText.trim(),
      };
      if (filterData?.educatorType) {
        values.educatorType = filterData?.educatorType;
      }
      if (
        filterData?.status === EducatorStatus.Inactive ||
        filterData?.status === EducatorStatus.Active
      ) {
        values.status = filterData?.status;
      }
      if (filterData?.roleFilter) {
        values.roleId = filterData?.roleFilter;
      }
      if (terms.length > 0) {
        values.term = terms.map((term) => +term);
      }
      const payload = {
        values,
        callback: onStatisticsDataSuccess,
      };
      educatorStatisticsDataRequest(payload);
    }
  };
  useEffect(() => {
    getRoleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEducatorSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getEducatorList(filteredData, true, 1);
    getStatisticsData(filteredData, true);
  };

  const handleEducatorSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getEducatorList(filteredData);
      getStatisticsData(filteredData);
    }
  };

  const handleFilterApplyClick = (formData: IAddEditEducator) => {
    if (
      !(
        formData.educatorType ||
        formData.status === EducatorStatus.Inactive ||
        formData.status === EducatorStatus.Active ||
        formData.roleFilter
      )
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      setSearchText("");
      if (pageNo === DEFAULT_PAGE_NO) {
        getEducatorList(formData, true, 1);
        getStatisticsData(formData, true);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      document.body.classList.toggle("filter-open");
    }
  };

  const onDeleteEducatorSuccess = (response: DeleteEducatorSuccessPayload) => {
    if (response.success) {
      toast.success(EDUCATOR_DELETE_SUCCESS_MSG);
      handleEducatorDeleteCloseModal();
      getEducatorList();
    }
  };

  const handleDeleteEducator = () => {
    const { deleteEducatorRequest } = props;

    if (deleteEducatorRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(deleteEducatorId),
        },
        callback: onDeleteEducatorSuccess,
      };

      deleteEducatorRequest(payload);
    }
  };

  const hasStudentRole = (educator: IEducator): boolean => {
    return educator.roleIds.includes(STUDENT_ROLE_ID);
  };

  const handleCourseAttendedButtonClick = (educator: IEducator) => {
    if (hasStudentRole(educator)) {
      history.push({
        pathname: AppRoutings.CoursesAttendedAsStudent,
        state: {
          educatorData: {
            id: educator.userID,
            name: educator.name,
            emailAddress: educator.emailAddress,
          },
        },
      });
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.Educators
    );
    setPagePermission(permission);
    if (permission?.view) {
      getTermList();
      if (filteredData && !appliedFilter) {
        getEducatorList(filteredData, true, 1);
        getStatisticsData(filteredData, true);
      } else if (filteredData) {
        getEducatorList(filteredData);
        getStatisticsData(filteredData);
      } else {
        getEducatorList();
        getStatisticsData();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy, terms]);

  const csvFileHeaders = [
    "Name",
    "Email",
    "Type",
    "Courses Delivered",
    "Courses Attended As Student",
    "Role",
    "Status",
  ];

  const getEducatorModifiedData = (list: IEducator[]) => {
    const data = list?.map((educator: IEducator) => {
      return {
        Name: educator.name,
        Email: educator.emailAddress,
        Type: getEducatorType(educator.educatorType),
        "Courses Delivered": educator.courseDelivered,
        "Courses Attended As Student": educator.courseAttendedAsStudent,
        Role: roleRenderValue(educator.roleIds, roleList),
        Status: getStatusLabel(educator.status),
      };
    });

    return data;
  };

  const onExportEducatorListSuccess = (
    response: EducatorListSuccessPayload
  ) => {
    const modifiedEducatorData = getEducatorModifiedData(response.educatorList);
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeaders,
      filename: "Educators",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedEducatorData);
    hideLoaderForBatchApiCall();
  };

  const getExportEducators = () => {
    const { educatorListRequest } = props;

    if (educatorListRequest) {
      showLoaderForBatchApiCall();

      const values: IEducatorPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );
      values.name = searchText.trim();

      if (filteredData?.educatorType) {
        values.educatorType = filteredData?.educatorType;
      }
      if (
        filteredData?.status === EducatorStatus.Inactive ||
        filteredData?.status === EducatorStatus.Active
      ) {
        values.status = filteredData?.status;
      }
      if (filteredData?.roleFilter) {
        values.roleId = filteredData?.roleFilter;
      }
      if (terms.length > 0) {
        values.term = terms.map((term) => +term);
      }

      const payload = {
        values,
        callback: onExportEducatorListSuccess,
      };

      educatorListRequest(payload);
    }
  };

  return (
    <div>
      <>
        <div>
          {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Educators
            </Typography>
            <Box sx={{ display: "flex", columnGap: "15px" }}>
              <Button
                variant="contained"
                className="btn-add"
                onClick={() => history.push(AppRoutings.AddEducator)}
                disabled={!pagePermission?.add}
              >
                <img src={plusLightIcon} alt="plus" />
                Add
              </Button>
              <Button
                variant="outlined"
                className="btn-export"
                onClick={getExportEducators}
              >
                Export
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Grid container spacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Card className="counter-card">
                    <Typography variant="h5">Current Educators</Typography>
                    <Typography variant="h1">
                      {statisticsData?.totalEducators}
                    </Typography>
                    <div className="counter-card-icon">
                      <img src={educatorsTotalIcon} alt="Total Educators" />
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Card className="counter-card">
                    <Typography variant="h5">Peer Educators</Typography>
                    <Typography variant="h1">
                      {statisticsData?.totalPeerEducators}
                    </Typography>
                    <div className="counter-card-icon">
                      <img src={educatorsTotalIcon} alt="Total Educators" />
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Card className="counter-card">
                    <Typography variant="h5">Clinical Educators</Typography>
                    <Typography variant="h1">
                      {statisticsData?.totalClinicalEducators}
                    </Typography>
                    <div className="counter-card-icon">
                      <img src={educatorsTotalIcon} alt="Total Educators" />
                    </div>
                  </Card>
                </Grid>{" "}
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <Card className="counter-card">
                    <Typography variant="h5">External Organisations</Typography>
                    <Typography variant="h1">
                      {statisticsData?.totalOtherEducators}
                    </Typography>
                    <div className="counter-card-icon">
                      <img src={educatorsTotalIcon} alt="Total Educators" />
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Box className="table-card-header">
                  <Box
                    sx={{ justifyContent: "space-between", width: "100%" }}
                    className="search-filter-inputs"
                  >
                    <TextField
                      id="search-box"
                      variant="outlined"
                      className="search-input"
                      placeholder="Search"
                      value={searchText}
                      onChange={handleEducatorSearch}
                      onKeyDown={handleEducatorSearchKeyDown}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ position: "absolute", right: "0" }}
                            position="end"
                          >
                            {searchText && (
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={CLEAR_TOOLTIP_TITLE}
                                arrow
                              >
                                <IconButton
                                  edge="end"
                                  onClick={handleClearSearch}
                                >
                                  <img src={closeIcon} alt="close" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onKeyPress={(event) =>
                        onTextFieldKeyPress(event, searchText?.length)
                      }
                    />
                    <Box
                      sx={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <FormControl fullWidth className="table-select select">
                        <InputLabel id="term-select-label">
                          Select Term
                        </InputLabel>
                        <Select
                          labelId="term-select-label"
                          id="term-select"
                          multiple
                          label="Select Term"
                          value={terms}
                          onChange={handleTermChange}
                          input={<OutlinedInput label="Select Term" />}
                          renderValue={(selected) => termRenderValue(selected)}
                        >
                          {termList?.map((term) => (
                            <MenuItem key={term.id} value={term.id}>
                              <Checkbox
                                checked={
                                  (terms as unknown as Number[]).indexOf(
                                    term.id
                                  ) > -1
                                }
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckedBoxIcon />}
                              />
                              <ListItemText primary={term.termName} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button
                        variant="outlined"
                        className="btn-filter"
                        sx={{ marginLeft: "20px" }}
                        onClick={() => {
                          setFilterBox(true);
                          document.body.classList.toggle("filter-open");
                        }}
                      >
                        <img
                          src={filterPrimaryIcon}
                          alt="filter"
                          className="default"
                        />
                        <img
                          src={filterLightIcon}
                          alt="filter"
                          className="active"
                        />
                        <span>Filter</span>
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "name")}
                          fieldName="Name"
                          keyName="name"
                          sxStyle={{
                            maxWidth: "173px",
                            width: "173px",
                            minWidth: "203px",
                            wordWrap: "break-word",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) =>
                            handleRequestSort(e, "emailAddress")
                          }
                          fieldName="Email"
                          keyName="emailAddress"
                          sxStyle={{ width: "209px", minWidth: "209px" }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) =>
                            handleRequestSort(e, "educatorType")
                          }
                          fieldName="Type"
                          keyName="educatorType"
                          sxStyle={{ width: "161px", minWidth: "161px" }}
                        />
                        <TableCell
                          align="center"
                          sx={{ width: "96px", minWidth: "96px" }}
                        >
                          Courses Delivered
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "146px", minWidth: "146px" }}
                        >
                          Course Attended As Student
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "175px", minWidth: "175px" }}
                        >
                          Role
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "90px", minWidth: "90px" }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            [projectTheme.breakpoints.down("sm")]: {
                              width: "112px",
                            },
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {educatorList?.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ wordWrap: "break-word" }}
                          >
                            {row.name}
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="email">
                              {row.emailAddress}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {getEducatorType(row.educatorType)}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              className="btn-course"
                              variant="outlined"
                              onClick={() =>
                                history.push({
                                  pathname: AppRoutings.CoursesDelivered,
                                  state: {
                                    educatorData: {
                                      id: row.id,
                                      name: row.name,
                                      emailAddress: row.emailAddress,
                                    },
                                  },
                                })
                              }
                            >
                              <img
                                src={courseListIcon}
                                alt="Course List"
                                className="default"
                              />
                              <img
                                src={courseListLightIcon}
                                alt="Course List"
                                className="active"
                              />
                              <Typography variant="body2">
                                {row.courseDelivered}
                              </Typography>
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              className="btn-course"
                              variant="outlined"
                              disabled={!hasStudentRole(row)}
                              onClick={() =>
                                handleCourseAttendedButtonClick(row)
                              }
                            >
                              <img
                                src={
                                  hasStudentRole(row)
                                    ? courseListIcon
                                    : courseListSecondaryIcon
                                }
                                alt="Course List"
                                className="default"
                              />
                              <img
                                src={courseListLightIcon}
                                alt="Course List"
                                className="active"
                              />
                              <Typography variant="body2">
                                {row.courseAttendedAsStudent}
                              </Typography>
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="role">
                              {roleRenderValue(row.roleIds, roleList)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            className={`${getStatusLabel(
                              row.status
                            ).toLowerCase()} status`}
                          >
                            <div>{getStatusLabel(row.status)}</div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="table-actions">
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={EDIT_TOOLTIP_TITLE}
                                arrow
                              >
                                <IconButton
                                  onClick={() =>
                                    history.push({
                                      pathname: AppRoutings.EditEducator,
                                      state: {
                                        educatorId: row.id,
                                        courseDelivered: row.courseDelivered,
                                        courseAttended:
                                          row.courseAttendedAsStudent,
                                      },
                                    })
                                  }
                                  disabled={!pagePermission?.edit}
                                  className={
                                    !pagePermission?.edit ? "disabled-icon" : ""
                                  }
                                >
                                  <img src={editIcon} alt="edit" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={DELETE_TOOLTIP_TITLE}
                                arrow
                              >
                                <IconButton
                                  onClick={() =>
                                    handleEducatorDeleteModal(Number(row?.id))
                                  }
                                  disabled={!pagePermission?.delete}
                                  className={
                                    !pagePermission?.delete
                                      ? "disabled-icon"
                                      : ""
                                  }
                                >
                                  <img src={deleteIcon} alt="delete" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {educatorList && educatorList.length > 0 ? (
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        {PAGE_SIZES?.map((pageSize) => (
                          <MenuItem
                            key={pageSize.value}
                            value={pageSize.value}
                            selected={pageSize?.selected}
                          >
                            {pageSize.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="body2" component="span">
                        {getPaginationDetailText(
                          educatorListMeta,
                          pageNo,
                          page
                        )}
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={educatorListMeta?.totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={pageNo}
                      onChange={onPageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <NoRecords />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </>

      {filterBox && (
        <EducatorFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          getEducatorList={getEducatorList}
          setAppliedFilter={setAppliedFilter}
          getStatisticsData={getStatisticsData}
          roleList={roleList}
        />
      )}

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenEducatorDeleteModal}
        handleDeleteConfirmationModalClose={handleEducatorDeleteCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete educator?"
        handleYesClick={handleDeleteEducator}
      />
    </div>
  );
};

export default Educators;
