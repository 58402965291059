import {
  IDocumentContainerDispatch,
  IDocument,
} from "utils/interfaces/document";
import { MapDispatchToProps, connect } from "react-redux";
import {
  documentListRequest,
  addDocumentRequest,
  editDocumentRequest,
  documentByIdRequest,
  deleteDocumentRequest,
} from "store/document/action";
import DocumentsComponent, { DocumentProps } from "pages/Documents";

const mapDispatchToProps: MapDispatchToProps<
  IDocumentContainerDispatch,
  IDocument
> = {
  documentListRequest,
  addDocumentRequest,
  editDocumentRequest,
  documentByIdRequest,
  deleteDocumentRequest,
};

const connector = connect(null, mapDispatchToProps);

const Documents: React.FC<DocumentProps> = (props) => {
  return <DocumentsComponent {...props} />;
};

export default connector(Documents);
