import {
  IEnhancedTableProps,
  IStudent,
  IStudentDetailKeys,
  IStudentFilter,
  IStudentListMeta,
  IStudentListPagePayloadValues,
  IStudentsContainerDispatch,
  IStudentsContainerState,
} from "utils/interfaces/student";

import { Box } from "@mui/system";
import {
  Button,
  Card,
  MenuItem,
  Grid,
  Pagination,
  PaginationItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  SelectChangeEvent,
  IconButton,
  InputAdornment,
  Fade,
  Tooltip,
} from "@mui/material";
import {
  closeIcon,
  deleteIcon,
  editIcon,
  filterLightIcon,
  filterPrimaryIcon,
} from "assets/images";
import React, { useEffect, useState } from "react";
import projectTheme from "app.theme";
import moment from "moment";
import {
  CLEAR_TOOLTIP_TITLE,
  DATE_FORMAT,
  DATE_OF_BIRTH_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DELETE_TOOLTIP_TITLE,
  EDIT_TOOLTIP_TITLE,
  MAX_INT_VALUE,
  PAGE_SIZES,
} from "utils/constants/constant";
import { toast } from "react-toastify";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  DeleteStudentSuccessPayload,
  StudentListSuccessPayload,
} from "store/student/types";
import {
  allowSearch,
  checkEndDateLessThanStart,
  convertToDateTime,
  convertToDateTimeWithFormat,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import {
  DELETE_STUDENT_CONFIRMATION,
  END_DATE_SHOULD_GREATER_MSG,
  SELECT_MIN_ONE_FIELD,
  STUDENT_DELETE_SUCCESS_MSG,
} from "utils/constants/Messages";
import { NO_EMAIL_OF_STUDENT } from "utils/constants/student-enrolment";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import NoRecords from "components/NoRecords";
import { ExportToCsv } from "export-to-csv";
import { useHistory } from "react-router-dom";
import { AppRoutings } from "utils/enums/app-routings";
import { useSelector } from "react-redux";
import { AppPages } from "utils/enums/app-pages";
import { IRoleModulePermission } from "store/role/types";
import OverlayBox from "components/OverlayBox";
import ConfirmModal from "components/ConfirmModal";
import StudentFilter from "./StudentFilter";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IStudentDetailKeys) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type StudentsProps = IStudentsContainerState &
  IStudentsContainerDispatch;

const Students: React.FC<StudentsProps> = (props) => {
  const [studentList, setStudentList] = useState<IStudent[]>();
  const [studentListMeta, setStudentListMeta] = useState<IStudentListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IStudentDetailKeys>("name");
  const [searchText, setSearchText] = useState<string>("");
  const [filterBox, setFilterBox] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<IStudentFilter>();
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);

  const [isOpenStudentDeleteModal, setIsOpenStudentDeleteModal] =
    useState<boolean>(false);
  const [deleteStudentId, setDeleteStudentId] = useState<number>();

  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const onStudentListSuccess = (response: StudentListSuccessPayload) => {
    setStudentList(response.studentList);
    setStudentListMeta(response.studentListPagination);
  };

  const getFormattedDate = (date: string) => {
    const stringDate = new Date(date?.toString());
    const formattedDate = convertToDateTime(stringDate);

    return formattedDate;
  };
  const getStudentList = async (
    filterData?: IStudentFilter,
    filtered?: boolean,
    pageNum?: number
  ) => {
    const { studentListRequest } = props;

    if (studentListRequest) {
      showLoader();

      const values: IStudentListPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.name = filtered ? "" : searchText.trim();

      if (filterData?.email) {
        values.emailAddress = filterData?.email;
      }
      if (filterData?.mobile) {
        values.phoneNumber = filterData?.mobile;
      }
      if (filterData?.dateOfBirth) {
        values.dateOfBirth = getFormattedDate(filterData?.dateOfBirth);
      }
      if (filterData?.startDate) {
        values.startDate = getFormattedDate(filterData?.startDate);
      }
      if (filterData?.endDate) {
        values.endDate = getFormattedDate(filterData?.endDate);
      }

      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onStudentListSuccess,
      };

      studentListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Student);
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getStudentList(filteredData, true, 1);
      } else if (filteredData) {
        getStudentList(filteredData);
      } else {
        getStudentList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleRequestSort = React.useCallback(
    (
      event: React.MouseEvent<unknown>,
      newOrderBy: keyof IStudentDetailKeys
    ) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleStudentSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getStudentList(filteredData, true, 1);
  };

  const handleStudentSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getStudentList(filteredData);
    }
  };

  const filterToggle = () => {
    document.body.classList.toggle("filter-open");
  };

  const handleFilterApplyClick = (formData: IStudentFilter) => {
    if (
      !(
        formData.email ||
        formData.mobile ||
        formData.dateOfBirth ||
        formData.startDate ||
        formData.endDate
      )
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      const endDateError = checkEndDateLessThanStart(
        new Date(formData.startDate as string),
        new Date(formData.endDate as string)
      );

      if (endDateError) {
        toast.error(END_DATE_SHOULD_GREATER_MSG);
      } else {
        setFilteredData(formData);
        setSearchText("");
        if (pageNo === DEFAULT_PAGE_NO) {
          getStudentList(formData, true, 1);
        } else {
          setPageNo(1);
        }
        setFilterBox(false);
        filterToggle();
      }
    }
  };

  const handleStudentDeleteModal = (studentId: number) => {
    setDeleteStudentId(studentId);
    setIsOpenStudentDeleteModal(true);
  };

  const handleStudentDeleteCloseModal = () => {
    setDeleteStudentId(0);
    setIsOpenStudentDeleteModal(false);
  };

  const onDeleteStudentSuccess = (response: DeleteStudentSuccessPayload) => {
    if (response.success) {
      toast.success(STUDENT_DELETE_SUCCESS_MSG);
      handleStudentDeleteCloseModal();
      getStudentList();
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const handleDeleteStudent = () => {
    const { deleteStudentRequest } = props;

    if (deleteStudentRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(deleteStudentId),
        },
        callback: onDeleteStudentSuccess,
      };

      deleteStudentRequest(payload);
    }
  };

  const getStudentModifiedData = (list: IStudent[]) => {
    const data = list?.map((student: IStudent) => {
      return {
        "First Name": student.firstName,
        "Last Name": student.lastName,
        "Date Of Birth": convertToDateTimeWithFormat(
          student.dateOfBirth,
          DATE_FORMAT
        ),
        "Email Address": student.emailAddress,
        "Phone Number": student.phoneNumber,
        Suburb: student.suburb,
        "Street Address": student.streetAddress,
        State: student.state,
      };
    });

    return data;
  };
  const csvFileHeaders = [
    "First Name",
    "Last Name",
    "Date Of Birth",
    "Email Address",
    "Phone Number",
    "Suburb",
    "Street Address",
    "State",
  ];

  const onExportStudentListSuccess = (response: StudentListSuccessPayload) => {
    const modifiedCourseData = getStudentModifiedData(response.studentList);
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeaders,
      filename: "Students",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedCourseData);
  };

  const getExportStudents = () => {
    const { studentListRequest } = props;

    if (studentListRequest) {
      showLoader();

      const values: IStudentListPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );
      values.name = searchText?.trim();

      values.emailAddress = filteredData?.email;

      values.phoneNumber = filteredData?.mobile;

      values.dateOfBirth = filteredData?.dateOfBirth;

      const payload = {
        values,
        callback: onExportStudentListSuccess,
      };

      studentListRequest(payload);
    }
  };

  return (
    <>
      <>
        {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Students
          </Typography>
          <Button
            variant="outlined"
            className="btn-export"
            onClick={getExportStudents}
          >
            Export
          </Button>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Card>
              <Box className="table-card-header">
                <Box
                  sx={{ justifyContent: "space-between", width: "100%" }}
                  className="search-filter-inputs"
                >
                  <TextField
                    id="search-box"
                    variant="outlined"
                    className="search-input"
                    placeholder="Search"
                    onChange={handleStudentSearch}
                    onKeyDown={handleStudentSearchKeyDown}
                    value={searchText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ position: "absolute", right: "0" }}
                          position="end"
                        >
                          {searchText && (
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={CLEAR_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                edge="end"
                                onClick={handleClearSearch}
                              >
                                <img src={closeIcon} alt="close" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, searchText?.length)
                    }
                  />

                  <Button
                    variant="outlined"
                    className="btn-filter"
                    onClick={() => {
                      setFilterBox(true);
                      filterToggle();
                    }}
                  >
                    <img
                      src={filterPrimaryIcon}
                      alt="filter"
                      className="default"
                    />
                    <img
                      src={filterLightIcon}
                      alt="filter"
                      className="active"
                    />
                    <span>Filter</span>
                  </Button>
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "name")}
                        fieldName="Student Name"
                        keyName="name"
                        sxStyle={{ width: "153px", minWidth: "153px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) =>
                          handleRequestSort(e, "dateOfBirth")
                        }
                        fieldName="Date Of Birth"
                        keyName="dateOfBirth"
                        sxStyle={{ width: "144px", minWidth: "125px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "mobile")}
                        fieldName="Phone"
                        keyName="mobile"
                        sxStyle={{ width: "123px", minWidth: "105px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "email")}
                        fieldName="Email"
                        keyName="email"
                        sxStyle={{ width: "218px", minWidth: "166px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "createdOn")}
                        fieldName="Profile Created On"
                        keyName="createdOn"
                        sxStyle={{ width: "174px", minWidth: "165px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "address")}
                        fieldName="Address"
                        keyName="address"
                        sxStyle={{ width: "271px", minWidth: "176px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) =>
                          handleRequestSort(e, "enrolmentType")
                        }
                        fieldName="Enrolment Type"
                        keyName="enrolmentType"
                        sxStyle={{ width: "156px", minWidth: "156px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "sector")}
                        fieldName="Sector"
                        keyName="sector"
                        sxStyle={{ width: "156px", minWidth: "156px" }}
                      />
                      <TableCell
                        align="center"
                        sx={{
                          width: "152px",
                          [projectTheme.breakpoints.down("sm")]: {
                            width: "112px",
                          },
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentList?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ wordWrap: "break-word" }}
                        >
                          {row.firstName} {row.lastName}
                        </TableCell>
                        <TableCell>
                          {moment(row.dateOfBirth).format(DATE_OF_BIRTH_FORMAT)}
                        </TableCell>
                        <TableCell>{row.phoneNumber}</TableCell>
                        <TableCell sx={{ wordWrap: "break-word" }}>
                          {" "}
                          {row.emailAddress === NO_EMAIL_OF_STUDENT
                            ? "-"
                            : row.emailAddress}
                        </TableCell>
                        <TableCell>
                          {moment(row.createdOn).format(DATE_OF_BIRTH_FORMAT)}
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {row.streetAddress}, {row.suburb}, {row.state}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {row.enrolmentType}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{row.sector}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <div className="table-actions">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={EDIT_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                disabled={!pagePermission?.edit}
                                onClick={() => {
                                  history.push({
                                    pathname: AppRoutings.StudentsDetails,
                                    state: {
                                      studentId: Number(row.id),
                                      studentName: `${row.firstName} ${row.lastName}`,
                                    },
                                  });
                                }}
                              >
                                <img src={editIcon} alt="edit" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={DELETE_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                disabled={!pagePermission?.delete}
                                onClick={() =>
                                  handleStudentDeleteModal(row?.id)
                                }
                              >
                                <img src={deleteIcon} alt="delete" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {studentList && studentList.length > 0 ? (
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      {PAGE_SIZES?.map((pageSize) => (
                        <MenuItem
                          key={pageSize.value}
                          value={pageSize.value}
                          selected={pageSize?.selected}
                        >
                          {pageSize.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" component="span">
                      {getPaginationDetailText(studentListMeta, pageNo, page)}
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={studentListMeta?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={pageNo}
                    onChange={onPageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              ) : (
                <NoRecords />
              )}
            </Card>
          </Grid>
        </Grid>
      </>

      {filterBox && (
        <StudentFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          getStudentList={getStudentList}
        />
      )}

      {isOpenStudentDeleteModal && (
        <ConfirmModal
          handleSubmitConfirmationModalClose={handleStudentDeleteCloseModal}
          submitConfirmationMessage={DELETE_STUDENT_CONFIRMATION}
          handleYesClick={handleDeleteStudent}
        />
      )}
    </>
  );
};

export default Students;
