import { closeIcon, uncheckIcon } from "assets/images";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { showLoader } from "utils/helper";
import {
  AddNewEnrolmentFromStudentSuccessPayload,
  GetCurrentCoursesForAddEnrolmentSuccessPayload,
} from "store/student/types";
import { ICurrentCourseList } from "utils/interfaces/student";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ENROLMENT_ADDED } from "utils/constants/Messages";
import {
  IEnrolmentOptions,
  IStep4Details,
  ISwitchCourse,
} from "utils/interfaces/student-enrolment";
import { CLOSE_TOOLTIP_TITLE, FACE_TO_FACE } from "utils/constants/constant";
import projectTheme from "app.theme";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { Controller, useForm } from "react-hook-form";
import RadioCheckedBoxIcon from "components/RadioCheckedBoxIcon";
import RadioCheckBoxIcon from "components/RadioCheckBoxIcon";

interface IAddNewEnrolmentConfirmationProps {
  handleAddNewEnrolmentModalClose: any;
  getCurrentCoursesForAddEnrolmentRequest: any;
  addNewEnrolmentFromStudentRequest: any;
  setAttendanceHistoryChange: any;
  enrolmentOptionList: IEnrolmentOptions | undefined;
}

const AddNewEnrolmentModal: React.FC<IAddNewEnrolmentConfirmationProps> = ({
  handleAddNewEnrolmentModalClose,
  getCurrentCoursesForAddEnrolmentRequest,
  addNewEnrolmentFromStudentRequest,
  setAttendanceHistoryChange,
}) => {
  function CheckboxIcon() {
    return <img src={uncheckIcon} alt="checkbox" />;
  }
  const location = useLocation<{ studentId: number }>();

  const [currentCourses, setCurrentCourses] = useState<ICurrentCourseList[]>();

  const [courseError, setCourseError] = useState<string>("");

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IStep4Details>({
    defaultValues: {
      courseIds: [],
      courseNames: [],
      preferredCourseDelivery: [],
    },
  });

  const onGetCurrentCoursesSuccess = (
    response: GetCurrentCoursesForAddEnrolmentSuccessPayload
  ) => {
    setCurrentCourses(response.currentCourses);
    const switchCourses: ISwitchCourse[] = response.currentCourses.map(
      (course) => ({
        courseOfferingId: course.id,
        preferredCourseDelivery: course.isBlended ? FACE_TO_FACE : null,
        courseName: course.courseName,
      })
    );
    setValue("preferredCourseDelivery", switchCourses);
  };

  const getCurrentCourses = () => {
    if (getCurrentCoursesForAddEnrolmentRequest) {
      showLoader();

      const payload = {
        values: { id: location?.state?.studentId },
        callback: onGetCurrentCoursesSuccess,
      };

      getCurrentCoursesForAddEnrolmentRequest(payload);
    }
  };

  useEffect(() => {
    getCurrentCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNewEnrolmentFromStudentSuccess = (
    response: AddNewEnrolmentFromStudentSuccessPayload
  ) => {
    if (response.success) {
      handleAddNewEnrolmentModalClose();
      setAttendanceHistoryChange(true);
      toast.success(ENROLMENT_ADDED);
    } else {
      toast.error(response.message);
    }
  };

  const handleYesClick = () => {
    if (watch("courseIds").length === 0) {
      setCourseError("Please select at least one course");
    } else if (addNewEnrolmentFromStudentRequest) {
      showLoader();
      const payload = {
        values: {
          studentId: location?.state?.studentId,
          preferredCourseDelivery: watch("preferredCourseDelivery")
            .filter((course) =>
              watch("courseIds").includes(course.courseOfferingId)
            )
            .map((course) => {
              return {
                courseOfferingId: course.courseOfferingId,
                preferredCourseDelivery: course.preferredCourseDelivery,
              };
            }),
        },
        callback: onAddNewEnrolmentFromStudentSuccess,
      };
      addNewEnrolmentFromStudentRequest(payload);
    }
  };

  const handleSelectedCourses = (e: React.ChangeEvent<HTMLInputElement>) => {
    const courseIds = watch("courseIds");
    const currentIndex = courseIds.indexOf(Number(e.target.value));
    const newCourseIds = [...courseIds];

    if (currentIndex === -1) {
      newCourseIds.push(Number(e.target.value));
    } else {
      newCourseIds.splice(currentIndex, 1);
    }

    const courseNames = currentCourses
      ?.filter((course) => newCourseIds.includes(course.id))
      .map((course) => course.courseName) as string[];

    setValue("courseNames", courseNames);
    setValue("courseIds", newCourseIds);
    setValue("preferredCourseDelivery", watch("preferredCourseDelivery"));
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    courseOfferingId: number,
    courseName: string
  ) => {
    const checkedCourses = watch("preferredCourseDelivery");
    const courseIndex = checkedCourses.findIndex(
      (x) => x.courseOfferingId === courseOfferingId
    );
    if (courseIndex === -1) {
      checkedCourses.push({
        courseOfferingId,
        preferredCourseDelivery: event.currentTarget.value,
        courseName,
      });
    } else {
      checkedCourses[courseIndex].preferredCourseDelivery =
        event.currentTarget.value;
    }
    setValue("preferredCourseDelivery", checkedCourses);
  };

  return (
    <>
      <>
        <Modal open onClose={handleAddNewEnrolmentModalClose}>
          <Box className="common-modal delete-modal send-sms-modal">
            <Box className="modal-header">
              <Typography variant="h2">Course Enrolment</Typography>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={CLOSE_TOOLTIP_TITLE}
                arrow
              >
                <IconButton onClick={handleAddNewEnrolmentModalClose}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="modal-body">
              {currentCourses && currentCourses.length > 0 ? (
                <>
                  <Box className="stdenrol-course">
                    <Typography sx={{ paddingBottom: "7px" }} variant="h5">
                      Current and Ongoing Courses Offerings
                    </Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                [projectTheme.breakpoints.down("lg")]: {
                                  minWidth: "442px",
                                },
                                [projectTheme.breakpoints.down("sm")]: {
                                  minWidth: "0",
                                },
                              }}
                            >
                              Course
                            </TableCell>
                            <TableCell
                              className="course-del"
                              align="center"
                              sx={{
                                width: "260px",
                                minWidth: "260px",
                                maxWidth: "260px",
                              }}
                            >
                              Preferred Course Delivery
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentCourses?.map((course) => (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Controller
                                  control={control}
                                  {...register("courseIds", {
                                    required: true,
                                  })}
                                  render={() => (
                                    <>
                                      <FormControlLabel
                                        value={course?.id}
                                        className="add-new-enrlmnt"
                                        control={
                                          <Checkbox
                                            checked={
                                              watch("courseIds")?.indexOf(
                                                course.id
                                              ) > -1
                                            }
                                            icon={<CheckboxIcon />}
                                            checkedIcon={<CheckedBoxIcon />}
                                            onChange={(e) =>
                                              handleSelectedCourses(e)
                                            }
                                            className="stepper-check-course"
                                          />
                                        }
                                        label={course.courseName}
                                        labelPlacement="end"
                                      />
                                    </>
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                {course.isBlended ? (
                                  <Controller
                                    control={control}
                                    {...register("preferredCourseDelivery")}
                                    render={({ field }) => (
                                      <RadioGroup
                                        row
                                        {...field}
                                        onChange={(e) =>
                                          watch("courseIds")?.indexOf(
                                            course.id
                                          ) > -1
                                            ? handleChange(
                                                e,
                                                course.id,
                                                course.courseName
                                              )
                                            : null
                                        }
                                      >
                                        <FormControlLabel
                                          checked={
                                            watch("courseIds")?.indexOf(
                                              course.id
                                            ) > -1 &&
                                            watch(
                                              "preferredCourseDelivery"
                                            )?.find(
                                              (x) =>
                                                x.courseOfferingId === course.id
                                            )?.preferredCourseDelivery ===
                                              "Face To Face"
                                          }
                                          value="Face To Face"
                                          control={
                                            <Radio
                                              icon={<RadioCheckBoxIcon />}
                                              checkedIcon={
                                                <RadioCheckedBoxIcon />
                                              }
                                            />
                                          }
                                          label="Face To Face"
                                        />
                                        <FormControlLabel
                                          checked={
                                            watch("courseIds")?.indexOf(
                                              course.id
                                            ) > -1 &&
                                            watch(
                                              "preferredCourseDelivery"
                                            )?.find(
                                              (x) =>
                                                x.courseOfferingId === course.id
                                            )?.preferredCourseDelivery ===
                                              "Online"
                                          }
                                          value="Online"
                                          control={
                                            <Radio
                                              sx={{ marginLeft: "15px" }}
                                              icon={<RadioCheckBoxIcon />}
                                              checkedIcon={
                                                <RadioCheckedBoxIcon />
                                              }
                                            />
                                          }
                                          label="Online"
                                        />
                                      </RadioGroup>
                                    )}
                                  />
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                          {!!errors.courseIds && (
                            <FormHelperText error>
                              Please select a course
                            </FormHelperText>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {courseError && (
                      <FormHelperText sx={{ mt: 2, mb: 2 }} error>
                        {courseError}
                      </FormHelperText>
                    )}

                    <Box className="add-enrolment-modal-footer">
                      <Button
                        variant="contained"
                        className="btn-yes"
                        onClick={() => handleYesClick()}
                      >
                        Add
                      </Button>
                      <Button
                        variant="outlined"
                        className="btn-cancel"
                        onClick={handleAddNewEnrolmentModalClose}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </>
              ) : (
                <Typography className="no-record-text">
                  Currently no courses available
                </Typography>
              )}
            </Box>
          </Box>
        </Modal>
      </>
    </>
  );
};

export default AddNewEnrolmentModal;
