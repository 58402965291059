import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosError } from "axios";
import {
  IStaffReportDetailsPayloadValues,
  IStaffReportPagePayloadValues,
} from "utils/interfaces/staff-report";
import {
  getStaffReportDetailsFailure,
  getStaffReportDetailsSuccess,
  printStaffReportFailure,
  printStaffReportSuccess,
  staffReportListFailure,
  staffReportListSuccess,
} from "./actions";
import {
  GET_STAFF_REPORT_DETAILS_REQUEST,
  PRINT_STAFF_REPORT_REQUEST,
  STAFF_REPORT_LIST_REQUEST,
} from "./actionTypes";
import staffReportService from "./service";
import {
  GetStaffReportDetailsRequest,
  GetStaffReportDetailsResponse,
  PrintStaffReportRequest,
  PrintStaffReportResponse,
  StaffReportListRequest,
  StaffReportListResponse,
} from "./types";

function* staffReportListSaga(action: StaffReportListRequest) {
  try {
    const payload: IStaffReportPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };
    if (action.payload.values.currentRole) {
      payload.currentRole = action.payload.values.currentRole;
    }
    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }
    if (action.payload.values.workEnvironment) {
      payload.workEnvironment = action.payload.values.workEnvironment;
    }
    if (action.payload.values.site) {
      payload.site = action.payload.values.site;
    }
    if (action.payload.values.employmentStatus) {
      payload.employmentStatus = action.payload.values.employmentStatus;
    }

    const response: StaffReportListResponse = yield call(
      staffReportService.getStaffReportList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      staffReportListSuccess({
        staffReportList: response.item.records,
        staffReportListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      staffReportList: response.item.records,
      staffReportListPagination: paginationDetails,
    });
  } catch (e) {
    yield put(
      staffReportListFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* printStaffReportSaga(action: PrintStaffReportRequest) {
  try {
    const payload: IStaffReportPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };
    if (action.payload.values.currentRole) {
      payload.currentRole = action.payload.values.currentRole;
    }
    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }
    if (action.payload.values.workEnvironment) {
      payload.workEnvironment = action.payload.values.workEnvironment;
    }
    if (action.payload.values.site) {
      payload.site = action.payload.values.site;
    }
    if (action.payload.values.employmentStatus) {
      payload.employmentStatus = action.payload.values.employmentStatus;
    }

    const response: PrintStaffReportResponse = yield call(
      staffReportService.printStaffReport,
      payload
    );

    yield put(
      printStaffReportSuccess({
        item: response.item,
      })
    );
    action.payload.callback({
      item: response.item,
    });
  } catch (e) {
    yield put(
      printStaffReportFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* getStaffReportDetailsSaga(action: GetStaffReportDetailsRequest) {
  try {
    const payload: IStaffReportDetailsPayloadValues = {
      studentId: action.payload.values.studentId,
      isCourseAttended: action.payload.values.isCourseAttended,
    };

    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }

    const response: GetStaffReportDetailsResponse = yield call(
      staffReportService.getStaffReportDetails,
      payload
    );
    yield put(
      getStaffReportDetailsSuccess({
        staffReportDetailList: response.item,
      })
    );
    action.payload.callback({
      staffReportDetailList: response.item,
    });
  } catch (e) {
    yield put(
      getStaffReportDetailsFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* staffReportSaga() {
  yield all([takeLatest(STAFF_REPORT_LIST_REQUEST, staffReportListSaga)]);
  yield all([takeLatest(PRINT_STAFF_REPORT_REQUEST, printStaffReportSaga)]);
  yield all([
    takeLatest(GET_STAFF_REPORT_DETAILS_REQUEST, getStaffReportDetailsSaga),
  ]);
}

export default staffReportSaga;
