import { Card, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import CalendarIcon from "components/CalendarIcon";
import { Controller, useForm } from "react-hook-form";
import {
  STUDENT_ENROLMENT_EMAIL_MAX_LENGTH,
  STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH,
  STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH,
  STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH,
  STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH,
  STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH,
  STUDENT_ENROLMENT_SUBURB_MAX_LENGTH,
  STUDENT_ENROLMENT_STATE_MAX_LENGTH,
  MINIMUM_STUDENT_AGE,
} from "utils/constants/student-enrolment";
import {
  EmailPattern,
  handlePasteNumberField,
  onNumberFieldKeyPress,
  onTextFieldKeyPress,
} from "utils/helper";
import { useEffect } from "react";
import { FormSteps } from "utils/enums/student-enrolment";
import { IStep2Details } from "utils/interfaces/student-enrolment";
import {
  ALPHABETIC_INPUT_REGEX,
  DATE_PICKER_FORMAT,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
} from "utils/constants/constant";
import {
  ALPHABETIC_VALIDATION_MSG,
  WHITE_SPACE_ERROR_SPACE,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER,
} from "utils/constants/Messages";
import dayjs from "dayjs";
import FormStepButtons from "../FormStepButtons";

interface IFormStep2Props {
  isExistingStudent?: boolean;
  movePrev: (step: number, data: any, isNext: boolean) => void;
  moveNext: (step: number, data: any, isNext: boolean) => void;
  stepDetails: { data: IStep2Details };
}

const FormStep2: React.FC<IFormStep2Props> = (props) => {
  const { movePrev, moveNext, stepDetails } = props;

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<IStep2Details>({
    defaultValues: {
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      phoneNumber: null,
      emailAddress: null,
      streetAddress: null,
      suburb: null,
      state: null,
      postCode: null,
    },
  });

  const setFormValues = (data: any) => {
    reset((formValues) => ({
      ...formValues,
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      phoneNumber: data.phoneNumber,
      emailAddress: data.emailAddress,
      streetAddress: data.streetAddress,
      suburb: data.suburb,
      state: data.state,
      postCode: data.postCode,
    }));
  };

  useEffect(() => {
    if (stepDetails && stepDetails.data) {
      setFormValues(stepDetails.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFirstNameError = (): string => {
    if (errors.firstName) {
      if (errors.firstName.type === "required") {
        return "First name is required";
      }
      if (errors.firstName.type === "maxLength") {
        return `Maximum length of first name should be ${STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH}`;
      }
      if (errors.firstName.type === "pattern") {
        return `First name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getLastNameError = (): string => {
    if (errors.lastName) {
      if (errors.lastName.type === "required") {
        return "Last name is required";
      }
      if (errors.lastName.type === "maxLength") {
        return `Maximum length of last name should be ${STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH}`;
      }
      if (errors.lastName.type === "pattern") {
        return `Last name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getDateOfBirthError = (): string => {
    if (errors.dateOfBirth) {
      if (errors.dateOfBirth.type === "required") {
        return "Date of birth is required";
      }
      if (errors.dateOfBirth.type === "validate") {
        return `Minimum 'Age' should be ${MINIMUM_STUDENT_AGE}yrs`;
      }
    }

    return "";
  };

  const getPhoneNumberError = (): string => {
    if (errors.phoneNumber) {
      if (errors.phoneNumber.type === "required") {
        return "Phone number is required";
      }
      if (errors.phoneNumber.type === "maxLength") {
        return `Maximum length of phone number should be ${STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getEmailAddressError = (): string => {
    if (errors.emailAddress) {
      if (errors.emailAddress.type === "required") {
        return "Email is required";
      }
      if (errors.emailAddress.type === "pattern") {
        return "Enter valid email";
      }
      if (errors.emailAddress.type === "maxLength") {
        return `Maximum length of email should be ${STUDENT_ENROLMENT_EMAIL_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getStreetAddressError = (): string => {
    if (errors.streetAddress) {
      if (errors.streetAddress.type === "required") {
        return "Street Address is required";
      }
      if (errors.streetAddress.type === "maxLength") {
        return `Maximum length of street address should be ${STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH}`;
      }
      if (errors.streetAddress.type === "pattern") {
        return `Street address ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getSuburbError = (): string => {
    if (errors.suburb) {
      if (errors.suburb.type === "required") {
        return "Suburb is required";
      }
      if (errors.suburb.type === "maxLength") {
        return `Maximum length of suburb should be ${STUDENT_ENROLMENT_SUBURB_MAX_LENGTH}`;
      }
      if (errors.suburb.type === "pattern") {
        return `Suburb ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getPostCodeError = (): string => {
    if (errors.postCode) {
      if (errors.postCode.type === "required") {
        return "Post Code is required";
      }
      if (errors.postCode.type === "maxLength") {
        return `Maximum length of post code should be ${STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getStateError = (): string => {
    if (errors.state) {
      if (errors.state.type === "required") {
        return "State is required";
      }
      if (errors.state.type === "maxLength") {
        return `Maximum length of state should be ${STUDENT_ENROLMENT_STATE_MAX_LENGTH}`;
      }
      if (errors.state.type === "pattern") {
        return `State ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const onSubmit = async (data: any) => {
    const stepData = {
      data,
    };

    moveNext(FormSteps.StudentSupports, stepData, true);
  };

  const handleBack = () => {
    const stepData = {
      data: getValues(),
    };

    movePrev(FormSteps.CollegeForm, stepData, false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="stepper-card step-2">
        <Typography variant="h4" className="questions">
          Student details
        </Typography>
        <Grid container columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="first-name"
              label={
                <>
                  First Name <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("firstName")),
              }}
              onKeyPress={(event) =>
                onTextFieldKeyPress(event, watch("firstName")?.length)
              }
              error={!!errors.firstName}
              helperText={getFirstNameError()}
              {...register("firstName", {
                required: true,
                maxLength: STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH,
                pattern: ALPHABETIC_INPUT_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="last-name"
              label={
                <>
                  Last Name <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              onKeyPress={(event) =>
                onTextFieldKeyPress(event, watch("lastName")?.length)
              }
              InputLabelProps={{
                shrink: Boolean(watch("lastName")),
              }}
              error={!!errors.lastName}
              helperText={getLastNameError()}
              {...register("lastName", {
                required: true,
                maxLength: STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH,
                pattern: ALPHABETIC_INPUT_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <Controller
                  control={control}
                  {...register("dateOfBirth", {
                    required: true,
                    validate: (value) => {
                      const selectedDate = dayjs(value);
                      const minDate = dayjs().subtract(
                        MINIMUM_STUDENT_AGE,
                        "year"
                      );

                      return !selectedDate.isAfter(minDate);
                    },
                  })}
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      className="date-picker disabled-picker-text-field"
                      format={DATE_PICKER_FORMAT}
                      label={
                        <>
                          Date Of Birth <span className="color-red">*</span>
                        </>
                      }
                      value={watch("dateOfBirth")}
                      disableFuture
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.dateOfBirth,
                          helperText: getDateOfBirthError(),
                        },
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="phone-num"
              label={
                <>
                  Phone Number <span className="color-red">*</span>
                </>
              }
              fullWidth
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("phoneNumber")),
              }}
              error={!!errors.phoneNumber}
              helperText={getPhoneNumberError()}
              onKeyPress={onNumberFieldKeyPress}
              onPaste={handlePasteNumberField}
              {...register("phoneNumber", {
                required: true,
                maxLength: STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="emai-add"
              label={
                <>
                  Email Address <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emailAddress")),
              }}
              error={!!errors.emailAddress}
              helperText={getEmailAddressError()}
              {...register("emailAddress", {
                required: true,
                pattern: EmailPattern,
                maxLength: STUDENT_ENROLMENT_EMAIL_MAX_LENGTH,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="street-add"
              label={
                <>
                  Street Address <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("streetAddress")),
              }}
              error={!!errors.streetAddress}
              helperText={getStreetAddressError()}
              onKeyPress={(event) =>
                onTextFieldKeyPress(event, watch("streetAddress")?.length)
              }
              {...register("streetAddress", {
                required: true,
                maxLength: STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="suburb"
              label={
                <>
                  Suburb <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("suburb")),
              }}
              error={!!errors.suburb}
              helperText={getSuburbError()}
              onKeyPress={(event) =>
                onTextFieldKeyPress(event, watch("suburb")?.length)
              }
              {...register("suburb", {
                required: true,
                maxLength: STUDENT_ENROLMENT_SUBURB_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="state"
              label={
                <>
                  State <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("state")),
              }}
              error={!!errors.state}
              helperText={getStateError()}
              onKeyPress={(event) =>
                onTextFieldKeyPress(event, watch("state")?.length)
              }
              {...register("state", {
                required: true,
                maxLength: STUDENT_ENROLMENT_STATE_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="post-code"
              label={
                <>
                  Post Code <span className="color-red">*</span>
                </>
              }
              fullWidth
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("postCode")),
              }}
              error={!!errors.postCode}
              helperText={getPostCodeError()}
              {...register("postCode", {
                required: true,
                maxLength: STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH,
              })}
              onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
              onPaste={handlePasteNumberField}
            />
          </Grid>
        </Grid>
      </Card>
      <FormStepButtons handleBack={handleBack} />
    </form>
  );
};

export default FormStep2;
