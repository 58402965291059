import { useEffect } from "react";
import { showLoader } from "utils/helper";
import { toast } from "react-toastify";
import { StudentDocumentsSuccessPayload } from "store/studentDashboard/types";
import { studentDocumentsRequest } from "store/studentDashboard/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppRoutings } from "utils/enums/app-routings";
import { DOCUMENT_PATH, STUDENT_DOCUMENT_PATH } from "utils/constants/constant";

const Handbook: React.FC = () => {
  const history = useHistory();
  const onHandbookPreviewSuccess = (
    response: StudentDocumentsSuccessPayload
  ) => {
    if (response.document !== null) {
      const url = response.document.replace(
        DOCUMENT_PATH,
        STUDENT_DOCUMENT_PATH
      );
      window.open(url, "_blank");
      history.push(AppRoutings.StudentDashboard);
    } else {
      toast.success("Handbook will be available soon");
    }
  };
  const dispatch = useDispatch();
  const handleHandbookPreview = () => {
    if (studentDocumentsRequest) {
      showLoader();
      const payload = {
        values: {
          type: "HandBook",
        },
        callback: onHandbookPreviewSuccess,
      };
      dispatch(studentDocumentsRequest(payload));
    }
  };
  useEffect(() => {
    handleHandbookPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Handbook;
