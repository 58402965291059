export const STAFF_REPORT_LIST_REQUEST = "STAFF_REPORT_LIST_REQUEST";
export const STAFF_REPORT_LIST_SUCCESS = "STAFF_REPORT_LIST_SUCCESS";
export const STAFF_REPORT_LIST_FAILURE = "STAFF_REPORT_LIST_FAILURE";

export const PRINT_STAFF_REPORT_REQUEST = "PRINT_STAFF_REPORT_REQUEST";
export const PRINT_STAFF_REPORT_SUCCESS = "PRINT_STAFF_REPORT_SUCCESS";
export const PRINT_STAFF_REPORT_FAILURE = "PRINT_STAFF_REPORT_FAILURE";

export const GET_STAFF_REPORT_DETAILS_REQUEST =
  "GET_STAFF_REPORT_DETAILS_REQUEST";
export const GET_STAFF_REPORT_DETAILS_SUCCESS =
  "GET_STAFF_REPORT_DETAILS_SUCCESS";
export const GET_STAFF_REPORT_DETAILS_FAILURE =
  "GET_STAFF_REPORT_DETAILS_FAILURE";
