import { saveIcon } from "assets/images";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  convertToDateTimeWithFormat,
  getPagePermissions,
  showLoader,
} from "utils/helper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  ConfirmationPreferenceSuccessPayload,
  EditConfirmationPreferenceSuccessPayload,
} from "store/student/types";
import { AppPages } from "utils/enums/app-pages";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { DATE_FORMAT } from "utils/constants/constant";
import { CONFIRMATION_PREFERENCE_EDIT_SUCCESS_MESSAGE } from "utils/constants/Messages";
import { changeConfirmationPreferenceRequest } from "store/student/action";

interface IConfirmationPreferenceProps {
  confirmationPreferenceRequest: any;
  editConfirmationPreferenceRequest: any;
}
interface IEditConfirmationPreferenceProps {
  id: number;
  confirmationPreference: string;
}
const ConfirmationPreference: React.FC<IConfirmationPreferenceProps> = ({
  confirmationPreferenceRequest,
  editConfirmationPreferenceRequest,
}) => {
  const history = useHistory();
  const location = useLocation<{ studentId: number; isDeleted?: boolean }>();
  const user = useSelector((state: any) => state?.auth?.user);
  const [createrName, setCreaterName] = useState<string>("");
  const [createDate, setCreateDate] = useState<string>("");
  const dispatch = useDispatch();

  const { register, watch, handleSubmit, setValue } =
    useForm<IEditConfirmationPreferenceProps>({
      defaultValues: {
        id: location?.state.studentId,
        confirmationPreference: "",
      },
    });

  const onConfirmationPreferenceSuccess = (
    response: ConfirmationPreferenceSuccessPayload
  ) => {
    const date = convertToDateTimeWithFormat(
      response.confirmationPreference.createdOn,
      DATE_FORMAT
    );
    setValue(
      "confirmationPreference",
      response?.confirmationPreference?.enrollmentConfirmationPreference
    );
    setCreaterName(response?.confirmationPreference?.createrName);
    setCreateDate(date);
  };

  const getConfirmationPreference = () => {
    if (confirmationPreferenceRequest) {
      showLoader();
      const { studentId } = location.state;

      const payload = {
        values: { id: studentId },
        callback: onConfirmationPreferenceSuccess,
      };
      confirmationPreferenceRequest(payload);
    }
  };

  const isConfirmationPreferenceChange = useSelector(
    (state: any) => state?.student?.isConfirmationPreferenceChange
  );

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Student);
    if (location?.state.studentId && permission.edit) {
      getConfirmationPreference();
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmationPreferenceChange]);

  const onEditConfirmationPreferenceSuccess = (
    response: EditConfirmationPreferenceSuccessPayload
  ) => {
    if (response.success) {
      toast.success(CONFIRMATION_PREFERENCE_EDIT_SUCCESS_MESSAGE);
      dispatch(
        changeConfirmationPreferenceRequest({
          isConfirmationPreferenceChange: !isConfirmationPreferenceChange,
        })
      );
    } else if (response.message) {
      toast.error(response.message);
    }
  };
  const onSubmit = async (data: IEditConfirmationPreferenceProps) => {
    if (location.state.studentId && !location.state.isDeleted) {
      if (editConfirmationPreferenceRequest) {
        showLoader();
        const payload = {
          values: {
            id: data.id,
            enrollmentConfirmationPreference: data.confirmationPreference,
          },
          callback: onEditConfirmationPreferenceSuccess,
        };
        editConfirmationPreferenceRequest(payload);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="std-details-card">
        <Grid container spacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" className="sco-detail-title">
              Profile Create By
            </Typography>
            <Typography variant="body1" className="sco-detail">
              {createrName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" className="sco-detail-title">
              Date Profile Created
            </Typography>
            <Typography variant="body1" className="sco-detail">
              {createDate}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth className="select">
              <InputLabel id="enol-con-pref-label">
                Enrolment Confirmation Preference
              </InputLabel>
              <Select
                labelId="enol-con-pref-label"
                id="enol-con-pref"
                label="Enrolment Confirmation Preference"
                value={watch("confirmationPreference")}
                required
                className="std-details-input"
                {...register("confirmationPreference")}
              >
                <MenuItem value="Email">Email</MenuItem>
                <MenuItem value="SMS">SMS</MenuItem>
              </Select>
            </FormControl>
            <Box className="btn-group">
              <Button
                disabled={location.state.isDeleted}
                variant="contained"
                className="btn-save"
                type="submit"
              >
                <img src={saveIcon} alt="save" />
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </form>
  );
};
export default ConfirmationPreference;
