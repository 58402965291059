import { calendarIcon } from "assets/images";

const CalendarIcon = () => {
  return (
    <div className="calendar-icon">
      <img src={calendarIcon} alt="calendar" />
    </div>
  );
};

export default CalendarIcon;
