import {
  ENROLMENT_OPTION_LIST_REQUEST,
  ENROLMENT_OPTION_LIST_FAILURE,
  ENROLMENT_OPTION_LIST_SUCCESS,
  CURRENT_ENROL_COURSE_LIST_REQUEST,
  CURRENT_ENROL_COURSE_LIST_SUCCESS,
  CURRENT_ENROL_COURSE_LIST_FAILURE,
  ADD_ENROLMENT_REQUEST,
  ADD_ENROLMENT_SUCCESS,
  ADD_ENROLMENT_FAILURE,
  CHECK_VALID_ENROLMENT_REQUEST,
  CHECK_VALID_ENROLMENT_SUCCESS,
  CHECK_VALID_ENROLMENT_FAILURE,
  STUDENT_ENROLMENT_LIST_REQUEST,
  STUDENT_ENROLMENT_LIST_SUCCESS,
  STUDENT_ENROLMENT_LIST_FAILURE,
  STUDENT_DETAIL_REQUEST,
  STUDENT_DETAIL_SUCCESS,
  STUDENT_DETAIL_FAILURE,
  REJECT_ENROLMENT_REQUEST,
  REJECT_ENROLMENT_SUCCESS,
  REJECT_ENROLMENT_FAILURE,
  APPROVE_ENROLMENT_REQUEST,
  APPROVE_ENROLMENT_SUCCESS,
  APPROVE_ENROLMENT_FAILURE,
  GET_STUDENT_ENROLMENT_FOR_APPROVAL_FAILURE,
  GET_STUDENT_ENROLMENT_FOR_APPROVAL_REQUEST,
  GET_STUDENT_ENROLMENT_FOR_APPROVAL_SUCCESS,
  CHECK_VALID_RECAPTCHA_FAILURE,
  CHECK_VALID_RECAPTCHA_REQUEST,
  CHECK_VALID_RECAPTCHA_SUCCESS,
  CHANGE_ENROLMENT_STATUS_FAILURE,
  CHANGE_ENROLMENT_STATUS_REQUEST,
  CHANGE_ENROLMENT_STATUS_SUCCESS,
} from "./actionTypes";
import {
  EnrolmentOptionListPayload,
  EnrolmentOptionListRequest,
  EnrolmentOptionListSuccess,
  EnrolmentOptionListSuccessPayload,
  EnrolmentOptionListFailure,
  EnrolmentOptionListFailurePayload,
  CurrentCourseListPayload,
  CurrentCourseListRequest,
  CurrentCourseListSuccessPayload,
  CurrentCourseListSuccess,
  CurrentCourseListFailurePayload,
  CurrentCourseListFailure,
  AddEnrolmentPayload,
  AddEnrolmentRequest,
  AddEnrolmentSuccessPayload,
  AddEnrolmentSuccess,
  AddEnrolmentFailurePayload,
  AddEnrolmentFailure,
  CheckValidEnrolmentPayload,
  CheckValidEnrolmentRequest,
  CheckValidEnrolmentSuccessPayload,
  CheckValidEnrolmentSuccess,
  CheckValidEnrolmentFailurePayload,
  CheckValidEnrolmentFailure,
  StudentEnrolmentListPayload,
  StudentEnrolmentListRequest,
  StudentEnrolmentListSuccessPayload,
  StudentEnrolmentListSuccess,
  StudentEnrolmentListFailurePayload,
  StudentEnrolmentListFailure,
  StudentEnrolmentSuccess,
  StudentEnrolmentRequest,
  StudentEnrolmentFailure,
  StudentEnrolmentDetailSuccessPayload,
  StudentEnrolmentDetailFailurePayload,
  StudentEnrolmentDetailPayload,
  RejectEnrolmentRequest,
  RejectEnrolmentPayload,
  RejectEnrolmentSuccess,
  RejectEnrolmentSuccessPayload,
  RejectEnrolmentFailure,
  RejectEnrolmentFailurePayload,
  ApproveEnrolmentPayload,
  ApproveEnrollmentSuccess,
  ApproveEnrollmentRequest,
  ApproveEnrolmentSuccessPayload,
  ApproveEnrolmentFailurePayload,
  ApproveEnrollmentFailure,
  GetStudentEnrolmentDataForApprovePayload,
  GetStudentEnrolmentDataForApproveRequest,
  GetStudentEnrolmentDataForApproveSuccessPayload,
  GetStudentEnrolmentDataForApproveSuccess,
  GetStudentEnrolmentDataForApproveFailure,
  GetStudentEnrolmentDataForApproveFailurePayload,
  CheckValidReCaptchaFailurePayload,
  CheckValidReCaptchaPayload,
  CheckValidReCaptchaSuccessPayload,
  CheckValidReCaptchaFailure,
  CheckValidReCaptchaRequest,
  CheckValidReCaptchaSuccess,
  ChangeEnrolmentStatusFailure,
  ChangeEnrolmentStatusFailurePayload,
  ChangeEnrolmentStatusPayload,
  ChangeEnrolmentStatusRequest,
  ChangeEnrolmentStatusSuccess,
  ChangeEnrolmentStatusSuccessPayload,
} from "./types";

export const enrolmentOptionListRequest = (
  payload: EnrolmentOptionListPayload
): EnrolmentOptionListRequest => ({
  type: ENROLMENT_OPTION_LIST_REQUEST,
  payload,
});

export const enrolmentOptionListSuccess = (
  payload: EnrolmentOptionListSuccessPayload
): EnrolmentOptionListSuccess => ({
  type: ENROLMENT_OPTION_LIST_SUCCESS,
  payload,
});

export const enrolmentOptionListFailure = (
  payload: EnrolmentOptionListFailurePayload
): EnrolmentOptionListFailure => ({
  type: ENROLMENT_OPTION_LIST_FAILURE,
  payload,
});

export const currentCourseListRequest = (
  payload: CurrentCourseListPayload
): CurrentCourseListRequest => ({
  type: CURRENT_ENROL_COURSE_LIST_REQUEST,
  payload,
});

export const currentCourseListSuccess = (
  payload: CurrentCourseListSuccessPayload
): CurrentCourseListSuccess => ({
  type: CURRENT_ENROL_COURSE_LIST_SUCCESS,
  payload,
});

export const currentCourseListFailure = (
  payload: CurrentCourseListFailurePayload
): CurrentCourseListFailure => ({
  type: CURRENT_ENROL_COURSE_LIST_FAILURE,
  payload,
});

export const addEnrolmentRequest = (
  payload: AddEnrolmentPayload
): AddEnrolmentRequest => ({
  type: ADD_ENROLMENT_REQUEST,
  payload,
});

export const addEnrolmentSuccess = (
  payload: AddEnrolmentSuccessPayload
): AddEnrolmentSuccess => ({
  type: ADD_ENROLMENT_SUCCESS,
  payload,
});

export const addEnrolmentFailure = (
  payload: AddEnrolmentFailurePayload
): AddEnrolmentFailure => ({
  type: ADD_ENROLMENT_FAILURE,
  payload,
});

export const checkValidEnrolmentRequest = (
  payload: CheckValidEnrolmentPayload
): CheckValidEnrolmentRequest => ({
  type: CHECK_VALID_ENROLMENT_REQUEST,
  payload,
});

export const checkValidEnrolmentSuccess = (
  payload: CheckValidEnrolmentSuccessPayload
): CheckValidEnrolmentSuccess => ({
  type: CHECK_VALID_ENROLMENT_SUCCESS,
  payload,
});

export const checkValidEnrolmentFailure = (
  payload: CheckValidEnrolmentFailurePayload
): CheckValidEnrolmentFailure => ({
  type: CHECK_VALID_ENROLMENT_FAILURE,
  payload,
});

export const studentEnrolmentListRequest = (
  payload: StudentEnrolmentListPayload
): StudentEnrolmentListRequest => ({
  type: STUDENT_ENROLMENT_LIST_REQUEST,
  payload,
});

export const studentEnrolmentListSuccess = (
  payload: StudentEnrolmentListSuccessPayload
): StudentEnrolmentListSuccess => ({
  type: STUDENT_ENROLMENT_LIST_SUCCESS,
  payload,
});

export const studentEnrolmentListFailure = (
  payload: StudentEnrolmentListFailurePayload
): StudentEnrolmentListFailure => ({
  type: STUDENT_ENROLMENT_LIST_FAILURE,
  payload,
});

export const studentEnrolmentDetailRequest = (
  payload: StudentEnrolmentDetailPayload
): StudentEnrolmentRequest => ({
  type: STUDENT_DETAIL_REQUEST,
  payload,
});

export const studentEnrolmentSuccess = (
  payload: StudentEnrolmentDetailSuccessPayload
): StudentEnrolmentSuccess => ({
  type: STUDENT_DETAIL_SUCCESS,
  payload,
});

export const studentEnrolmentFailure = (
  payload: StudentEnrolmentDetailFailurePayload
): StudentEnrolmentFailure => ({
  type: STUDENT_DETAIL_FAILURE,
  payload,
});

export const rejectEnrolmentRequest = (
  payload: RejectEnrolmentPayload
): RejectEnrolmentRequest => ({
  type: REJECT_ENROLMENT_REQUEST,
  payload,
});

export const rejectEnrolmentSuccess = (
  payload: RejectEnrolmentSuccessPayload
): RejectEnrolmentSuccess => ({
  type: REJECT_ENROLMENT_SUCCESS,
  payload,
});

export const rejectEnrolmentFailure = (
  payload: RejectEnrolmentFailurePayload
): RejectEnrolmentFailure => ({
  type: REJECT_ENROLMENT_FAILURE,
  payload,
});

export const approveEnrolmentRequest = (
  payload: ApproveEnrolmentPayload
): ApproveEnrollmentRequest => ({
  type: APPROVE_ENROLMENT_REQUEST,
  payload,
});

export const approveEnrolmentSuccess = (
  payload: ApproveEnrolmentSuccessPayload
): ApproveEnrollmentSuccess => ({
  type: APPROVE_ENROLMENT_SUCCESS,
  payload,
});

export const approveEnrolmentFailure = (
  payload: ApproveEnrolmentFailurePayload
): ApproveEnrollmentFailure => ({
  type: APPROVE_ENROLMENT_FAILURE,
  payload,
});

export const getStudentEnrolmentDataForApproveRequest = (
  payload: GetStudentEnrolmentDataForApprovePayload
): GetStudentEnrolmentDataForApproveRequest => ({
  type: GET_STUDENT_ENROLMENT_FOR_APPROVAL_REQUEST,
  payload,
});

export const getStudentEnrolmentDataForApproveSuccess = (
  payload: GetStudentEnrolmentDataForApproveSuccessPayload
): GetStudentEnrolmentDataForApproveSuccess => ({
  type: GET_STUDENT_ENROLMENT_FOR_APPROVAL_SUCCESS,
  payload,
});

export const getStudentEnrolmentDataForApproveFailure = (
  payload: GetStudentEnrolmentDataForApproveFailurePayload
): GetStudentEnrolmentDataForApproveFailure => ({
  type: GET_STUDENT_ENROLMENT_FOR_APPROVAL_FAILURE,
  payload,
});

export const checkValidReCaptchaRequest = (
  payload: CheckValidReCaptchaPayload
): CheckValidReCaptchaRequest => ({
  type: CHECK_VALID_RECAPTCHA_REQUEST,
  payload,
});

export const checkValidReCaptchaSuccess = (
  payload: CheckValidReCaptchaSuccessPayload
): CheckValidReCaptchaSuccess => ({
  type: CHECK_VALID_RECAPTCHA_SUCCESS,
  payload,
});

export const checkValidReCaptchaFailure = (
  payload: CheckValidReCaptchaFailurePayload
): CheckValidReCaptchaFailure => ({
  type: CHECK_VALID_RECAPTCHA_FAILURE,
  payload,
});

export const changeEnrolmentStatusRequest = (
  payload: ChangeEnrolmentStatusPayload
): ChangeEnrolmentStatusRequest => ({
  type: CHANGE_ENROLMENT_STATUS_REQUEST,
  payload,
});

export const changeEnrolmentStatusSuccess = (
  payload: ChangeEnrolmentStatusSuccessPayload
): ChangeEnrolmentStatusSuccess => ({
  type: CHANGE_ENROLMENT_STATUS_SUCCESS,
  payload,
});

export const changeEnrolmentStatusFailure = (
  payload: ChangeEnrolmentStatusFailurePayload
): ChangeEnrolmentStatusFailure => ({
  type: CHANGE_ENROLMENT_STATUS_FAILURE,
  payload,
});
