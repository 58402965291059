export enum SCORepeat {
  Daily = 1,
  Weekly = 2,
}

export enum SCORepeatEnd {
  After = 1,
  On = 3,
}

export enum SessionDayStatus {
  Active = 1,
  Cancelled = 2,
}

export enum SCOCertificate {
  Yes = 1,
  No = 0,
}

export enum SCOAvailableStatus {
  Open = "Open",
  Close = "Close",
}

export enum SCOBlendedCourse {
  Yes = 1,
  No = 0,
}
