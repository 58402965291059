import {
  ROLE_LIST_REQUEST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_FAILURE,
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  EDIT_ROLE_REQUEST,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  ROLE_PERMISSIONS_REQUEST,
  ROLE_PERMISSIONS_SUCCESS,
  ROLE_PERMISSIONS_FAILURE,
  MODULE_LIST_REQUEST,
  MODULE_LIST_SUCCESS,
  MODULE_LIST_FAILURE,
} from "./actionTypes";

import { RoleActions, RoleState } from "./types";

const initialState: RoleState = {
  pending: false,
  roleList: [],
  roleListPagination: {},
  error: null,
};

const reducers = (state = initialState, action: RoleActions): unknown => {
  switch (action.type) {
    case ROLE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ROLE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        roleList: action.payload.roleList,
        roleListPagination: action.payload.roleListPagination,
        error: null,
      };
    case ROLE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_ROLE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_ROLE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_ROLE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_ROLE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_ROLE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_ROLE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_ROLE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ROLE_PERMISSIONS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        rolePermissionList: action.payload.rolePermissionList,
        error: null,
      };
    case ROLE_PERMISSIONS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case MODULE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case MODULE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        moduleList: action.payload.moduleList,
        error: null,
      };
    case MODULE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
