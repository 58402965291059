import {
  IAddNewEnrolmentFromStudentPayloadValues,
  IApproveEnrolmentByEmailSMSPayloadValues,
  IEditMediaConsentDetails,
  IEditResearchProfileDetails,
  IEditStaffDetails,
  IEditStudentDetails,
  IEditStudentProfileDetails,
  IEnrollmentSummaryListPagePayloadValues,
  IEnrolmentAndAttendanceHistoryPagePayloadValues,
  IStudentAttendanceListPagePayloadValues,
  IStudentListPagePayloadValues,
} from "utils/interfaces/student";
import baseService from "services/base-service";

const studentBaseUrl = `/api/Student`;

const getStudentList = async (payload: IStudentListPagePayloadValues) =>
  baseService.post(`${studentBaseUrl}/GetEnroledStudents`, payload);

const deleteStudent = async (payload: { id: number }) =>
  baseService.delete(`${studentBaseUrl}/DeleteStudent/?id=${payload.id}`);

const getStudentNote = async (payload: { id: number }) =>
  baseService.get(`${studentBaseUrl}/GetStudentNote?id=${payload.id}`);

const editStudentNote = async (payload: { id: number; studentNote: string }) =>
  baseService.put(`${studentBaseUrl}/EditStudentNote`, payload);

const getConfirmationPreference = async (payload: { id: number }) =>
  baseService.get(
    `${studentBaseUrl}/GetConfirmationPreference?id=${payload.id}`
  );
const editConfirmationPreference = async (payload: {
  id: number;
  enrollmentConfirmationPreference: string;
}) => baseService.put(`${studentBaseUrl}/EditConfirmationPreference`, payload);

const getStudentDetails = async (payload: { id: number }) =>
  baseService.get(`${studentBaseUrl}/GetStudentDetail?id=${payload.id}`);

const getStudentProfileDetails = async (payload: { id: number }) =>
  baseService.get(`${studentBaseUrl}/GetStudentProfileDetail?id=${payload.id}`);

const editStudentDetails = async (payload: IEditStudentDetails) =>
  baseService.put(`${studentBaseUrl}/EditStudentDetail`, payload);

const editStudentProfileDetails = async (payload: IEditStudentProfileDetails) =>
  baseService.put(`${studentBaseUrl}/EditStudentProfileDetail`, payload);

const getResearchProfileDetails = async (payload: { id: number }) =>
  baseService.get(
    `${studentBaseUrl}/GetResearchProfileDetail?id=${payload.id}`
  );

const editResearchProfileDetails = async (
  payload: IEditResearchProfileDetails
) => baseService.put(`${studentBaseUrl}/EditResearchProfileDetail`, payload);

const getStaffDetails = async (payload: { id: number }) =>
  baseService.get(`${studentBaseUrl}/GetStaffDetail?id=${payload.id}`);

const editStaffDetails = async (payload: IEditStaffDetails) =>
  baseService.put(`${studentBaseUrl}/EditStaffDetail`, payload);

const getMediaConsent = async (payload: { id: number }) =>
  baseService.get(`${studentBaseUrl}/GetStudentMediaConsent?id=${payload.id}`);

const editMediaConsent = async (payload: IEditMediaConsentDetails) => {
  const headers = {
    contentType: "multipart/form-data",
  };

  return baseService.put(`${studentBaseUrl}/EditStudentMediaConsent`, payload, {
    headers,
  });
};

const getStudentEnrollmentSummary = async (
  payload: IEnrollmentSummaryListPagePayloadValues
) => baseService.post(`${studentBaseUrl}/GetEnrolmentSummary`, payload);

const getEnrolmentAndAttendanceHistoryList = async (
  payload: IEnrolmentAndAttendanceHistoryPagePayloadValues
) =>
  baseService.post(
    `${studentBaseUrl}/GetEnrolmentAndAttendanceHistory`,
    payload
  );

const getStudentAttendanceList = async (
  payload: IStudentAttendanceListPagePayloadValues
) => baseService.post(`${studentBaseUrl}/GetStudentAttendance`, payload);

const getStudentPendingEnrolmentList = async (payload: { id: number }) =>
  baseService.get(
    `${studentBaseUrl}/GetStudentPendingEnrolmentList?studentId=${payload.id}`
  );

const getStudentEnrolmentForConfirmation = async (payload: { id: number }) =>
  baseService.get(
    `${studentBaseUrl}/EnrolmentDetailForCourseConfirmationByType?studentId=${payload.id}`
  );
const approveEnrolmentByEmailSMS = async (
  payload: IApproveEnrolmentByEmailSMSPayloadValues
) =>
  baseService.put(`${studentBaseUrl}/ApproveCourseConfirmationByType`, payload);

const getCurrentCoursesForAddEnrolment = async (payload: { id: number }) =>
  baseService.get(
    `${studentBaseUrl}/GetCurrentCoursesForAddEnrolment?studentId=${payload.id}`
  );

const addNewEnrolmentFromStudent = async (
  payload: IAddNewEnrolmentFromStudentPayloadValues
) => baseService.post(`${studentBaseUrl}/AddNewEnrolmentFromStudent`, payload);

const reSendConfirmationMail = async (payload: {
  studentCourseEnrolmentId: number;
  courseName: string;
  isEmailConfirmation: boolean;
}) => baseService.post(`${studentBaseUrl}/ResendConfirmationMail`, payload);

const generateCertificate = async (payload: { id: number }) =>
  baseService.put(
    `${studentBaseUrl}/GenerateCertificate?courseEnrolmentId=${payload.id}`
  );

const printEnrolmentSummary = async (
  payload: IEnrollmentSummaryListPagePayloadValues
) => baseService.post(`${studentBaseUrl}/PrintEnrolmentSummary`, payload);

export default {
  getStudentList,
  deleteStudent,
  getStudentNote,
  editStudentNote,
  getConfirmationPreference,
  editConfirmationPreference,
  getStudentDetails,
  editStudentDetails,
  getResearchProfileDetails,
  editResearchProfileDetails,
  getStudentEnrollmentSummary,
  editStaffDetails,
  getMediaConsent,
  editMediaConsent,
  getStaffDetails,
  getEnrolmentAndAttendanceHistoryList,
  getStudentAttendanceList,
  getStudentPendingEnrolmentList,
  getStudentEnrolmentForConfirmation,
  approveEnrolmentByEmailSMS,
  getCurrentCoursesForAddEnrolment,
  addNewEnrolmentFromStudent,
  reSendConfirmationMail,
  generateCertificate,
  editStudentProfileDetails,
  getStudentProfileDetails,
  printEnrolmentSummary,
};
