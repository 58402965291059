import { calendarIcon, saveIcon } from "assets/images";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  showLoader,
  getPagePermissions,
  convertToDateTime,
  EmailPattern,
  onNumberFieldKeyPress,
} from "utils/helper";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  EditStudentDetailsSuccessPayload,
  StudnetDetailsSuccessPayload,
} from "store/student/types";
import { AppPages } from "utils/enums/app-pages";
import { Controller, useForm } from "react-hook-form";
import {
  ALPHABETIC_INPUT_REGEX,
  DATE_PICKER_FORMAT,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  MAX_INT_VALUE,
} from "utils/constants/constant";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import {
  ALPHABETIC_VALIDATION_MSG,
  STUDENT_DETAIL_EDIT_SUCCESS_MESSAGE,
  STUDENT_PROFILE_UPDATE_SUCCESS_MESSAGE,
  WHITE_SPACE_ERROR_SPACE,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER,
} from "utils/constants/Messages";
import { toast } from "react-toastify";
import { IStudentDetailsData } from "utils/interfaces/student";
import { updateUser } from "store/auth/actions";
import {
  MINIMUM_STUDENT_AGE,
  STUDENT_EMERGENCY_CONTACT_NAME_MAX_LENGTH,
  STUDENT_EMERGENCY_CONTACT_NUMBER_MAX_LENGTH,
  STUDENT_EMERGENCY_CONTACT_RELATIONSHIP_MAX_LENGTH,
  STUDENT_ENROLMENT_COMMENT_MAX_LENGTH,
  STUDENT_ENROLMENT_EMAIL_MAX_LENGTH,
  STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH,
  STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH,
  STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH,
  STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH,
  STUDENT_ENROLMENT_STATE_MAX_LENGTH,
  STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH,
  STUDENT_ENROLMENT_SUBURB_MAX_LENGTH,
} from "utils/constants/student-enrolment";
import { changeConfirmationPreferenceRequest } from "store/student/action";

function CalendarIcon() {
  return (
    <div className="calendar-icon">
      <img src={calendarIcon} alt="calendar" />
    </div>
  );
}
interface IStudnetDetailsProps {
  studentDetailsRequest: any;
  editStudentDetailRequest: any;
  onIsStaffChange: any;
  enrolmentOptionList: IEnrolmentOptions | undefined;
  studentDetailChange?: boolean;
}

interface IStudentData {
  id?: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  streetAddress: string;
  suburb: string;
  postCode: number;
  state: string;
  dateOfBirth: any;
  enrollmentConfirmationPreference: string;
  emergencyContactName: string;
  emergencyContactRelationship: string;
  emergencyContactNumber: string;
  isStaff: string;
  enrolmentType: string;
  permanentInactivation: string;
  deceased: string;
  mhEncounter: string;
  sector: string;
  feeForService: string;
  information: string;
  mrn: string | null;
}

const StudentDetailData: React.FC<IStudnetDetailsProps> = ({
  studentDetailsRequest,
  editStudentDetailRequest,
  onIsStaffChange,
  enrolmentOptionList,
  studentDetailChange,
}) => {
  const location = useLocation<{ studentId: number; isDeleted?: boolean }>();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const dispatch = useDispatch();
  const [studentData, setStudentData] = useState<IStudentDetailsData>();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<IStudentData>({
    defaultValues: {
      enrollmentConfirmationPreference: "Email",
      enrolmentType: "",
      sector: "",
    },
  });

  const getFirstNameError = (): string => {
    if (errors.firstName) {
      if (errors.firstName.type === "required") {
        return "First Name is required";
      }
      if (errors.firstName.type === "maxLength") {
        return `Maximum length of first name should be ${STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH}`;
      }
      if (errors.firstName.type === "pattern") {
        return `First name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getLastNameError = (): string => {
    if (errors.lastName) {
      if (errors.lastName.type === "required") {
        return "Last Name is required";
      }
      if (errors.lastName.type === "maxLength") {
        return `Maximum length of last name should be ${STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH}`;
      }
      if (errors.lastName.type === "pattern") {
        return `Last name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getPhoneNumberError = (): string => {
    if (errors.phoneNumber) {
      if (errors.phoneNumber.type === "required") {
        return "Phone number is required";
      }
      if (errors.phoneNumber.type === "maxLength") {
        return `Maximum length of phone number should be ${STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getEmailAddressError = (): string => {
    if (errors.emailAddress) {
      if (errors.emailAddress.type === "required") {
        return "Email Address is required";
      }
      if (errors.emailAddress.type === "pattern") {
        return "Enter valid email";
      }
      if (errors.emailAddress.type === "maxLength") {
        return `Maximum length of email should be ${STUDENT_ENROLMENT_EMAIL_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getStreetAddressError = (): string => {
    if (errors.streetAddress) {
      if (errors.streetAddress.type === "required") {
        return "Street Address is required";
      }
      if (errors.streetAddress.type === "maxLength") {
        return `Maximum length of Street Address should be ${STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH}`;
      }
      if (errors.streetAddress.type === "pattern") {
        return `Street address ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getSuburbError = (): string => {
    if (errors.suburb) {
      if (errors.suburb.type === "required") {
        return "Suburb is required";
      }
      if (errors.suburb.type === "maxLength") {
        return `Maximum length of suburb should be ${STUDENT_ENROLMENT_SUBURB_MAX_LENGTH}`;
      }
      if (errors.suburb.type === "pattern") {
        return `Suburb ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getPostCodeError = (): string => {
    if (errors.postCode) {
      if (errors.postCode.type === "required") {
        return "Post Code is required";
      }
      if (errors.postCode.type === "maxLength") {
        return `Maximum length of post code should be ${STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH}`;
      }
      if (errors.postCode.type === "max") {
        return `Max value of Post Code can be '${MAX_INT_VALUE}'`;
      }
    }

    return "";
  };

  const getStateError = (): string => {
    if (errors.state) {
      if (errors.state.type === "required") {
        return "state is required";
      }
      if (errors.state.type === "maxLength") {
        return `Maximum length of state should be ${STUDENT_ENROLMENT_STATE_MAX_LENGTH}`;
      }
      if (errors.state.type === "pattern") {
        return `State ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getDateOfBirthError = (): string => {
    if (errors.dateOfBirth) {
      if (errors.dateOfBirth.type === "required") {
        return "Date of birth is required";
      }
      if (errors.dateOfBirth.type === "validate") {
        return `Minimum 'Age' should be ${MINIMUM_STUDENT_AGE}yrs`;
      }
    }

    return "";
  };

  const getEmergencyContactNameError = (): string => {
    if (errors.emergencyContactName) {
      if (errors.emergencyContactName.type === "required") {
        return "Emergency Contact Name is required";
      }
      if (errors.emergencyContactName.type === "maxLength") {
        return `Maximum length of name should be ${STUDENT_EMERGENCY_CONTACT_NAME_MAX_LENGTH}`;
      }
      if (errors.emergencyContactName.type === "pattern") {
        return `Name of contact person ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getEmergencyContactRelationshipError = (): string => {
    if (errors.emergencyContactRelationship) {
      if (errors.emergencyContactRelationship.type === "required") {
        return "Emergency Contact relation is required";
      }
      if (errors.emergencyContactRelationship.type === "maxLength") {
        return `Maximum length of relationship should be ${STUDENT_EMERGENCY_CONTACT_RELATIONSHIP_MAX_LENGTH}`;
      }
      if (errors.emergencyContactRelationship.type === "pattern") {
        return `Relationship ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getEmergencyContactNumberError = (): string => {
    if (errors.emergencyContactNumber) {
      if (errors.emergencyContactNumber.type === "required") {
        return "Emergency Contact Number is required";
      }
      if (errors.emergencyContactNumber.type === "maxLength") {
        return `Maximum length of contact number should be ${STUDENT_EMERGENCY_CONTACT_NUMBER_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getEnrolmentTypeError = (): string => {
    if (errors.enrolmentType) {
      if (errors.enrolmentType.type === "required") {
        return "Enrolment type is required";
      }
    }

    return "";
  };

  const getSectorError = (): string => {
    if (errors.sector) {
      if (errors.sector.type === "required") {
        return "sector is required";
      }
    }

    return "";
  };

  const getPermenentActivationError = (): string => {
    if (errors.permanentInactivation) {
      if (errors.permanentInactivation.type === "pattern") {
        return `Permanent Inactivation ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER}`;
      }
    }

    return "";
  };

  const getDeceaseError = (): string => {
    if (errors.deceased) {
      if (errors.deceased.type === "pattern") {
        return `Deceased ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER}`;
      }
    }

    return "";
  };

  const getMHEncounterError = (): string => {
    if (errors.mhEncounter) {
      if (errors.mhEncounter.type === "pattern") {
        return `MhEncounter ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER}`;
      }
    }

    return "";
  };

  const getInformationToHelpError = (): string => {
    if (errors.information) {
      if (errors.information.type === "maxLength") {
        return `Maximum length of information should be ${STUDENT_ENROLMENT_COMMENT_MAX_LENGTH}`;
      }
      if (errors.information.type === "pattern") {
        return `Information ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getMRNError = (): string => {
    if (errors.mrn) {
      if (errors.mrn.type === "pattern") {
        return `MRN ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };
  const getFormattedDate = (date: any) => {
    const stringDate = new Date(date?.toString());
    const formattedDate = convertToDateTime(stringDate);

    return formattedDate;
  };

  const setStudentFormValues = (studentDetails: any) => {
    reset((formValues) => ({
      ...formValues,
      firstName: studentDetails.firstName,
      lastName: studentDetails.lastName,
      phoneNumber: studentDetails.phoneNumber,
      emailAddress: studentDetails.emailAddress,
      streetAddress: studentDetails.streetAddress,
      suburb: studentDetails.suburb,
      postCode: studentDetails.postCode,
      state: studentDetails.state,
      dateOfBirth: dayjs(studentDetails.dateOfBirth),
      enrollmentConfirmationPreference:
        studentDetails.studentEnrolmentDTO.enrollmentConfirmationPreference,
      emergencyContactName:
        studentDetails.studentEnrolmentDTO.emergencyContactName,
      emergencyContactNumber:
        studentDetails.studentEnrolmentDTO.emergencyContactNumber,
      emergencyContactRelationship:
        studentDetails.studentEnrolmentDTO.emergencyContactRelationship,
      isStaff: studentDetails.studentEnrolmentDTO.isStaff ? "Yes" : "No",
      enrolmentType: studentDetails.studentEnrolmentDTO.enrolmentType,
      sector: studentDetails.studentEnrolmentDTO.sector,
      permanentInactivation: studentDetails.permanentInactivation,
      deceased: studentDetails.deceased,
      mhEncounter: studentDetails.mhEncounter,
      feeForService: studentDetails.feeForService,
      information: studentDetails.studentEnrolmentDTO.information,
      mrn: studentDetails.studentEnrolmentDTO.mrn,
    }));
  };

  const onStudentDataSuccess = (response: StudnetDetailsSuccessPayload) => {
    setStudentFormValues(response.studentDetails);
    setStudentData(response.studentDetails);
    onIsStaffChange(response.studentDetails.studentEnrolmentDTO.isStaff);
  };

  const getStudentData = () => {
    if (studentDetailsRequest) {
      showLoader();
      const { studentId } = location.state;

      const payload = {
        values: { id: studentId },
        callback: onStudentDataSuccess,
      };

      studentDetailsRequest(payload);
    }
  };

  const handleCancelClick = () => {
    setStudentFormValues(studentData);
    if (user?.studentId) {
      history.goBack();
    }
  };
  const isConfirmationPreferenceChange = useSelector(
    (state: any) => state?.student?.isConfirmationPreferenceChange
  );

  useEffect(() => {
    if (studentDetailChange) {
      let permission;
      if (user?.studentId === null || user?.studentId === undefined) {
        permission = getPagePermissions(
          user?.permissions,
          AppPages.Student,
          "administrator"
        );
      } else {
        permission = getPagePermissions(
          user?.permissions,
          AppPages.StudentDashboard,
          "student"
        );
      }
      if (location?.state.studentId && permission.edit) {
        getStudentData();
      } else {
        history.goBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentDetailChange]);

  useEffect(() => {
    let permission;
    if (!user?.studentId) {
      permission = getPagePermissions(
        user?.permissions,
        AppPages.Student,
        "administrator"
      );
    } else {
      permission = getPagePermissions(
        user?.permissions,
        AppPages.StudentDashboard,
        "student"
      );
    }
    if (location?.state.studentId && permission.edit) {
      getStudentData();
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmationPreferenceChange]);

  const onEditStudentDetailSuccess = (
    response: EditStudentDetailsSuccessPayload
  ) => {
    if (response.success) {
      dispatch(
        changeConfirmationPreferenceRequest({
          isConfirmationPreferenceChange: !isConfirmationPreferenceChange,
        })
      );
      toast.success(
        user?.studentId
          ? STUDENT_PROFILE_UPDATE_SUCCESS_MESSAGE
          : STUDENT_DETAIL_EDIT_SUCCESS_MESSAGE
      );
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const handleUpdateUser = (payloads: any) => {
    dispatch(updateUser(payloads));
  };
  const onSubmit = async (data: IStudentData) => {
    if (location.state.studentId && !location.state.isDeleted) {
      if (editStudentDetailRequest) {
        showLoader();
        const payload = {
          values: {
            id: location.state.studentId,
            firstName: data.firstName,
            lastName: data.lastName,
            emailAddress: data.emailAddress,
            dateOfBirth: getFormattedDate(data.dateOfBirth),
            phoneNumber: data.phoneNumber,
            streetAddress: data.streetAddress,
            suburb: data.suburb,
            state: data.state,
            postCode: data.postCode,
            permanentInactivation: data.permanentInactivation,
            deceased: data.deceased,
            mhEncounter: data.mhEncounter,
            feeForService: data.feeForService,
            studentEnrolment: {
              emergencyContactName: data.emergencyContactName,
              emergencyContactRelationship: data.emergencyContactRelationship,
              emergencyContactNumber: data.emergencyContactNumber,
              information: data.information,
              enrolmentType: data.enrolmentType,
              enrollmentConfirmationPreference:
                data.enrollmentConfirmationPreference,
              sector: data.sector,
              mrn: data.mrn,
            },
          },
          callback: onEditStudentDetailSuccess,
        };
        editStudentDetailRequest(payload);
        if (user?.studentId) handleUpdateUser(payload);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="std-details-card">
        <Typography variant="h3" className="attnd-heading">
          {user?.studentId ? "Student Profile" : "Student Details"}
        </Typography>
        <Grid container columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="first-name1"
              label={
                <>
                  First Name <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("firstName")),
              }}
              error={!!errors.firstName}
              helperText={getFirstNameError()}
              {...register("firstName", {
                required: true,
                maxLength: STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH,
                pattern: ALPHABETIC_INPUT_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="last-name1"
              label={
                <>
                  Last Name <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("lastName")),
              }}
              helperText={getLastNameError()}
              error={!!errors.lastName}
              {...register("lastName", {
                required: true,
                maxLength: STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH,
                pattern: ALPHABETIC_INPUT_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="phone"
              label={
                <>
                  Phone <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              type="number"
              InputLabelProps={{
                shrink: Boolean(watch("phoneNumber")),
              }}
              helperText={getPhoneNumberError()}
              error={!!errors.phoneNumber}
              {...register("phoneNumber", {
                required: true,
                maxLength: STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH,
              })}
              onKeyPress={onNumberFieldKeyPress}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="email"
              label={
                <>
                  Email <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emailAddress")),
              }}
              helperText={getEmailAddressError()}
              error={!!errors.emailAddress}
              {...register("emailAddress", {
                required: true,
                pattern: EmailPattern,
                maxLength: STUDENT_ENROLMENT_EMAIL_MAX_LENGTH,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="strt-add"
              label={
                <>
                  Street Address <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("streetAddress")),
              }}
              helperText={getStreetAddressError()}
              error={!!errors.streetAddress}
              {...register("streetAddress", {
                required: true,
                maxLength: STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="suburd"
              label={
                <>
                  Suburb <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("suburb")),
              }}
              helperText={getSuburbError()}
              error={!!errors.suburb}
              {...register("suburb", {
                required: true,
                maxLength: STUDENT_ENROLMENT_SUBURB_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="post-code"
              label={
                <>
                  Post Code <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              type="number"
              InputLabelProps={{
                shrink: Boolean(watch("postCode")),
              }}
              helperText={getPostCodeError()}
              error={!!errors.postCode}
              {...register("postCode", {
                required: true,
                maxLength: STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH,
                max: MAX_INT_VALUE,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="state"
              label={
                <>
                  State <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("state")),
              }}
              helperText={getStateError()}
              error={!!errors.state}
              {...register("state", {
                required: true,
                maxLength: STUDENT_ENROLMENT_STATE_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <Controller
                  control={control}
                  {...register("dateOfBirth", {
                    required: true,
                    validate: (value) => {
                      const selectedDate = dayjs(value);
                      const minDate = dayjs().subtract(
                        MINIMUM_STUDENT_AGE,
                        "year"
                      );

                      return !selectedDate.isAfter(minDate);
                    },
                  })}
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      className="date-picker"
                      format={DATE_PICKER_FORMAT}
                      label={
                        <>
                          Date Of Birth <span className="color-red">*</span>
                        </>
                      }
                      value={
                        watch("dateOfBirth")
                          ? watch("dateOfBirth")
                          : dayjs(new Date())
                      }
                      disableFuture
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.dateOfBirth,
                          helperText: getDateOfBirthError(),
                        },
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <FormControl fullWidth className="select">
              <InputLabel id="enol-con-pref-label">
                Contact Method <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="enol-con-pref-label"
                id="contact-method"
                label={
                  <>
                    Contact Method <span className="color-red">*</span>
                  </>
                }
                required
                className="std-details-input"
                value={watch("enrollmentConfirmationPreference")}
                {...register("enrollmentConfirmationPreference")}
              >
                <MenuItem key="Email" value="Email">
                  Email
                </MenuItem>
                <MenuItem key="SMS" value="SMS">
                  SMS
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="emrg-ctnt-name"
              label={
                <>
                  Emergency Contact Name <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emergencyContactName")),
              }}
              helperText={getEmergencyContactNameError()}
              error={!!errors.emergencyContactName}
              {...register("emergencyContactName", {
                required: true,
                maxLength: STUDENT_EMERGENCY_CONTACT_NAME_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="emrg-ctnt-rel"
              label={
                <>
                  Emergency Contact Relationship{" "}
                  <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emergencyContactRelationship")),
              }}
              helperText={getEmergencyContactRelationshipError()}
              error={!!errors.emergencyContactRelationship}
              {...register("emergencyContactRelationship", {
                required: true,
                maxLength: STUDENT_EMERGENCY_CONTACT_RELATIONSHIP_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="emrg-ctnt-ph"
              label={
                <>
                  Emergency Contact Phone <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              onKeyPress={onNumberFieldKeyPress}
              InputLabelProps={{
                shrink: Boolean(watch("emergencyContactNumber")),
              }}
              helperText={getEmergencyContactNumberError()}
              error={!!errors.emergencyContactNumber}
              {...register("emergencyContactNumber", {
                required: true,
                maxLength: STUDENT_EMERGENCY_CONTACT_NUMBER_MAX_LENGTH,
              })}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="staff-enrol"
              label={
                <>
                  Staff Enrolment <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              disabled
              InputLabelProps={{
                shrink: Boolean(watch("isStaff")),
              }}
              {...register("isStaff")}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="enroltype-label"
                shrink={!!watch("enrolmentType")}
              >
                Enrolment Type <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="enroltype-label"
                id="enroltype"
                disabled={watch("isStaff") === "Yes"}
                label={
                  <>
                    Enrolment Type <span className="color-red">*</span>
                  </>
                }
                value={watch("enrolmentType")}
                error={!!errors.enrolmentType}
                {...register("enrolmentType", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.enrolmentType
                  ?.filter((x) =>
                    watch("isStaff") === "Yes"
                      ? x.value === "Staff"
                      : x.value !== "Staff"
                  )
                  .map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
              {!!errors.enrolmentType && (
                <FormHelperText error>{getEnrolmentTypeError()}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="pmt-inactive"
              label="Permanent Inactivation"
              fullWidth
              variant="outlined"
              helperText={getPermenentActivationError()}
              error={!!errors.permanentInactivation}
              InputLabelProps={{
                shrink: Boolean(watch("permanentInactivation")),
              }}
              {...register("permanentInactivation", {
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="deceased"
              label="Deceased?"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("deceased")),
              }}
              {...register("deceased", {
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_REGEX,
              })}
              helperText={getDeceaseError()}
              error={!!errors.deceased}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="mh-enc"
              label="MH Encounter"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("mhEncounter")),
              }}
              {...register("mhEncounter", {
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_REGEX,
              })}
              helperText={getMHEncounterError()}
              error={!!errors.mhEncounter}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <FormControl fullWidth className="select">
              <InputLabel id="sector1-label" shrink={!!watch("sector")}>
                Sector <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="sector1-label"
                id="sector1"
                label="Sector"
                value={watch("sector")}
                error={!!errors.sector}
                {...register("sector", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.sector?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {!!errors.sector && (
                <FormHelperText error>{getSectorError()}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <FormControl fullWidth className="select">
              <InputLabel id="feeforServ-label">Fee for Service</InputLabel>
              <Select
                labelId="feeforServ-label"
                id="feeforServ"
                label="Fee for Service"
                value={watch("feeForService") || ""}
                {...register("feeForService")}
              >
                <MenuItem key="No" value="No">
                  No
                </MenuItem>
                <MenuItem key="Yes" value="Yes">
                  Yes
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="mh-mrn"
              label="MRN(MH)"
              fullWidth
              variant="outlined"
              error={!!errors.mrn}
              helperText={getMRNError()}
              InputLabelProps={{
                shrink: Boolean(watch("mrn")),
              }}
              {...register("mrn", {
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="support-need"
              className="skills-textarea"
              label="Supports Required/Specific Learning Needs"
              fullWidth
              variant="outlined"
              multiline
              InputLabelProps={{
                shrink: Boolean(watch("information")),
              }}
              helperText={getInformationToHelpError()}
              error={!!errors.information}
              {...register("information", {
                maxLength: STUDENT_ENROLMENT_COMMENT_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
        </Grid>
        <Box className="btn-group">
          <Button
            disabled={location.state.isDeleted}
            variant="contained"
            className="btn-save"
            type="submit"
          >
            <img src={saveIcon} alt="save" />
            Save
          </Button>
          <Button
            variant="outlined"
            className="btn-cancel"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default StudentDetailData;
