import baseService from "services/base-service";

const loginBaseUrl = `/api/Auth`;

const login = async (payload: {
  username: string;
  password: string;
  rememberMe: boolean;
}) => baseService.post(`${loginBaseUrl}/Login`, payload);

const forgotPassword = async (payload: { userName: string }) =>
  baseService.post(`${loginBaseUrl}/ForgotPassword`, payload);

const refreshToken = async (payload: { token: string }) =>
  baseService.post(`${loginBaseUrl}/RefreshToken`, payload);

const validateResetPasswordUrl = async (payload: { url: string }) =>
  baseService.post(`${loginBaseUrl}/ValidateResetPasswordUrl`, payload);

const resetPassword = async (payload: { url: string; password: string }) =>
  baseService.post(`${loginBaseUrl}/ResetPassword`, payload);

export default {
  login,
  forgotPassword,
  refreshToken,
  validateResetPasswordUrl,
  resetPassword,
};
