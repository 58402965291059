import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { closeIcon } from "assets/images";
import { IAddEditScheduledOffering } from "utils/interfaces/scheduled-offering";
import {
  BLENDED_FACE_TO_FACE_ONLINE,
  CLOSE_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  MAX_INT_VALUE,
  SCO_BLENDED_COURSE,
  SCO_CERTIFICATE,
  SCO_STATUS,
} from "utils/constants/constant";
import {
  getPagePayloadValues,
  onNumberFieldKeyPress,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import { LocationListSuccessPayload } from "store/location/types";
import { locationListRequest } from "store/location/actions";
import { TermListSuccessPayload } from "store/term/types";
import { termListRequest } from "store/term/actions";
import {
  ILocation,
  ILocationPagePayloadValues,
} from "utils/interfaces/location";
import { ITerm, ITermPagePayloadValues } from "utils/interfaces/term";
import { useDispatch } from "react-redux";
import {
  SCOBlendedCourse,
  SCOCertificate,
} from "utils/enums/scheduled-offering";
import { OrderByOptions } from "utils/enums/sorting";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { IEnrolmentOptionItem } from "utils/interfaces/student-enrolment";

interface IScoFilterProps {
  filterBox: boolean;
  setFilterBox: any;
  handleApplyClick: any;
  filteredData?: IAddEditScheduledOffering;
  pageNo: number;
  setPageNo: any;
  setFilteredData: any;
  getScheduledOfferingList: any;
  setAppliedFilter: any;
}

interface IScoFilter {
  courseNumber: string;
  termId: number;
  locationId: number;
  certificatesIssued: number | null;
  emrEntered: number | null;
  status: number;
  year: number;
  isBlendedCourse: number | null;
}

const defaultValues: IScoFilter = {
  courseNumber: "",
  termId: 0,
  locationId: 0,
  certificatesIssued: null,
  emrEntered: null,
  status: 0,
  year: 0,
  isBlendedCourse: null,
};

const SCOFilter: React.FC<IScoFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getScheduledOfferingList,
  setAppliedFilter,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IAddEditScheduledOffering>({ defaultValues });
  const [locationList, setLocationList] = useState<ILocation[]>();
  const [termList, setTermList] = useState<ITerm[]>();
  const dispatch = useDispatch();

  const getYearError = (): string => {
    if (errors.year) {
      if (errors.year.type === "minLength") {
        return "Enter a valid 4-digit year";
      }
      if (errors.year.type === "maxLength") {
        return "Enter a valid 4-digit year";
      }
    }

    return "";
  };

  const onLocationListSuccess = (response: LocationListSuccessPayload) => {
    setLocationList(response.locationList);
  };

  const getLocationList = async () => {
    showLoader();
    const values: ILocationPagePayloadValues = getPagePayloadValues(
      "Location",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.location = "";

    const payload = {
      values,
      callback: onLocationListSuccess,
    };

    dispatch(locationListRequest(payload));
  };

  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
  };

  const getTermList = () => {
    showLoader();
    const values: ITermPagePayloadValues = getPagePayloadValues(
      "TermName",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.termName = "";

    const payload = {
      values,
      callback: onTermListSuccess,
    };

    dispatch(termListRequest(payload));
  };

  useEffect(() => {
    if (filterBox) {
      getLocationList();
      getTermList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBox]);

  useEffect(() => {
    if (filteredData) {
      setValue("courseNumber", filteredData?.courseNumber);
      setValue("terms", filteredData?.terms);
      setValue("locations", filteredData?.locations);
      setValue("certificatesIssued", filteredData?.certificatesIssued);
      setValue("emrEntered", filteredData?.emrEntered);
      setValue("status", filteredData?.status);
      setValue("isBlendedCourse", filteredData?.isBlendedCourse);
      setValue("year", filteredData?.year);
    }
  }, [filteredData, setValue, filterBox]);

  const onClearClick = () => {
    setFilterBox(false);
    setAppliedFilter(false);
    document.body.classList.toggle("filter-open");
    if (pageNo === DEFAULT_PAGE_NO) {
      getScheduledOfferingList();
    } else {
      setPageNo(1);
    }
    setFilteredData();
  };

  const renderSelectedValues = (
    selected: string[],
    optionList?: Array<IEnrolmentOptionItem>,
    isValueField?: boolean
  ) => {
    const selectedString: string[] = [];
    if (isValueField) {
      selected.forEach((item: string) => {
        const selectedItem = optionList?.find(
          (option) => option.value === item
        );
        if (selectedItem) {
          selectedString.push(selectedItem.label);
        }
      });
    } else {
      selected.forEach((item: string) => {
        const selectedItem = optionList?.find(
          (option) => option.label === item
        );
        if (selectedItem) {
          selectedString.push(selectedItem.label);
        }
      });
    }

    return selectedString.join(", ");
  };

  const handleFieldChange = (
    event: SelectChangeEvent<string[]>,
    field: keyof IAddEditScheduledOffering
  ) => {
    const { value } = event.target;
    setValue(field, value as string[]);
  };

  const termOptions = termList?.map((term) => ({
    value: term.id.toString(),
    label: term.termName,
  }));

  const locationOptions = locationList?.map((location) => ({
    value: location.id.toString(),
    label: location.isBlended
      ? `${location.location}${BLENDED_FACE_TO_FACE_ONLINE} `
      : location.location,
  }));

  return (
    <>
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setFilterBox(false);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            <TextField
              id="course-num"
              label="Course Number "
              fullWidth
              variant="outlined"
              {...register("courseNumber")}
              onKeyPress={(event) =>
                onTextFieldKeyPress(
                  event,
                  filteredData?.courseNumber
                    ? filteredData?.courseNumber.length
                    : 0
                )
              }
            />
            <TextField
              id="year-select"
              label={<>Year</>}
              fullWidth
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: !!watch("year"),
              }}
              error={!!errors.year}
              helperText={getYearError()}
              value={watch("year") === 0 ? "" : watch("year")}
              {...register("year", {
                maxLength: 4,
                minLength: 4,
              })}
              onKeyPress={onNumberFieldKeyPress}
            />
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Select Term</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Select Term"
                {...register("terms")}
                value={watch("terms") || []}
                onChange={(e) => handleFieldChange(e, "terms")}
                input={<OutlinedInput label="Select Term" />}
                renderValue={(selected) =>
                  renderSelectedValues(selected, termOptions, true)
                }
              >
                {termOptions?.map((term) => (
                  <MenuItem key={term.value} value={term.value}>
                    <Checkbox
                      checked={
                        (watch("terms") as unknown as string[]).indexOf(
                          term.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={term.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Select Location</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Select Location"
                {...register("locations")}
                value={watch("locations") || []}
                onChange={(e) => handleFieldChange(e, "locations")}
                input={<OutlinedInput label="Select Location" />}
                renderValue={(selected) =>
                  renderSelectedValues(selected, locationOptions, true)
                }
              >
                {locationOptions?.map((location) => (
                  <MenuItem key={location.value} value={location.value}>
                    <Checkbox
                      checked={
                        (watch("locations") as unknown as string[]).indexOf(
                          location.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={location.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel
                id="certi-issued-label"
                shrink={
                  watch("certificatesIssued") === SCOCertificate.No ||
                  watch("certificatesIssued") === SCOCertificate.Yes
                }
              >
                Certificates Issued
              </InputLabel>
              <Select
                labelId="certi-issued-label"
                id="certi-issued"
                label="Certificates Issued "
                value={watch("certificatesIssued")}
                {...register("certificatesIssued")}
              >
                {SCO_CERTIFICATE?.map((sco) => (
                  <MenuItem key={sco.value} value={sco.value}>
                    {sco.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel
                id="emr-entered-label"
                shrink={
                  watch("emrEntered") === SCOCertificate.No ||
                  watch("emrEntered") === SCOCertificate.Yes
                }
              >
                EMR Entered
              </InputLabel>
              <Select
                labelId="emr-entered-label"
                id="emr-entered"
                label="EMR Entered "
                value={watch("emrEntered")}
                {...register("emrEntered")}
              >
                {SCO_CERTIFICATE?.map((sco) => (
                  <MenuItem key={sco.value} value={sco.value}>
                    {sco.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel
                id="is-blended-course-label"
                shrink={
                  watch("isBlendedCourse") === SCOBlendedCourse.No ||
                  watch("isBlendedCourse") === SCOBlendedCourse.Yes
                }
              >
                Blended Course
              </InputLabel>
              <Select
                labelId="is-blended-course"
                id="is-blended-course"
                label="IsBlendedCourse"
                value={watch("isBlendedCourse")}
                {...register("isBlendedCourse")}
              >
                {SCO_BLENDED_COURSE?.map((sco) => (
                  <MenuItem key={sco.value} value={sco.value}>
                    {sco.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel
                id="status-select-label"
                shrink={watch("status") !== 0}
              >
                Status
              </InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                label="Status "
                value={watch("status")}
                {...register("status")}
              >
                {SCO_STATUS?.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-clear"
              onClick={onClearClick}
              disabled={
                !(
                  watch("courseNumber") ||
                  watch("terms") ||
                  watch("locations") ||
                  watch("certificatesIssued") === SCOCertificate.No ||
                  watch("certificatesIssued") === SCOCertificate.Yes ||
                  watch("emrEntered") === SCOCertificate.No ||
                  watch("emrEntered") === SCOCertificate.Yes ||
                  watch("status") ||
                  watch("year") ||
                  watch("isBlendedCourse") === SCOBlendedCourse.Yes ||
                  watch("isBlendedCourse") === SCOBlendedCourse.No
                )
              }
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default SCOFilter;
