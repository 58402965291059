/* eslint-disable import/no-cycle */
import {
  IForgotPassword,
  ILogin,
  ILoginResponse,
  IRefreshTokenResponse,
  IResetPassword,
} from "utils/interfaces/login";
import { IApiSuccessResponse } from "utils/interfaces/api-success-response";
import baseService from "services/base-service";
import { AxiosResponse } from "axios";
import { clearCookie } from "utils/helper";

const baseLoginUrl = "/api/Login";

const login = async (
  requestBody: ILogin
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  baseService.post<IApiSuccessResponse<ILoginResponse>>(
    `${baseLoginUrl}/login`,
    requestBody
  );

const refreshToken = async (
  token: string
): Promise<AxiosResponse<IApiSuccessResponse<IRefreshTokenResponse>>> =>
  baseService.post<IApiSuccessResponse<IRefreshTokenResponse>>(
    `${baseLoginUrl}/RefreshToken`,
    token
  );

const forgotPassword = async (
  requestBody: IForgotPassword
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  baseService.post<IApiSuccessResponse<ILoginResponse>>(
    `${baseLoginUrl}/ForgotPassword`,
    requestBody
  );

const resetPassword = async (
  requestBody: IResetPassword
): Promise<AxiosResponse<IApiSuccessResponse<ILoginResponse>>> =>
  baseService.post<IApiSuccessResponse<ILoginResponse>>(
    `${baseLoginUrl}/ForgotPassword`,
    requestBody
  );

const signOut = (): void => {
  localStorage.clear();
  sessionStorage.clear();
  clearCookie("auth_token");
  clearCookie("remember_me");
};

export default {
  login,
  refreshToken,
  forgotPassword,
  resetPassword,
  signOut,
};
