/* eslint-disable no-useless-escape */
import {
  checkIcon,
  eyeClose,
  eyeOpen,
  loginLogo,
  uncheckIcon,
} from "assets/images";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { AppRoutings } from "utils/enums/app-routings";

function CheckboxIcon() {
  return <img src={uncheckIcon} alt="checkbox" />;
}
function CheckedboxIcon() {
  return <img src={checkIcon} alt="checkbox" />;
}

const DesignerLogin: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  useEffect(() => {
    AOS.init({
      easing: "ease-in",
      duration: 400,
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <div className="login-wrapper">
        <Grid container className="login-container">
          <Grid xs={12} sm="auto">
            <a
              data-aos="fade-right"
              data-aos-delay="300"
              href="/"
              title="South Eastern Sydney Recovery and Wellbeing College"
              className="login-logo"
            >
              <img
                src={loginLogo}
                alt="South Eastern Sydney Recovery and Wellbeing College"
              />
            </a>
          </Grid>
          <Grid xs={12} sm="auto">
            <Card
              className="login-card"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              <CardContent>
                <Typography variant="h2" className="card-heading">
                  Login
                </Typography>
                <div className="login-content-form">
                  <TextField
                    id="email"
                    type="email"
                    label="Email"
                    fullWidth
                    variant="outlined"
                  />
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                      id="password"
                      className="with-icon"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            disableFocusRipple
                            disableRipple
                          >
                            {showPassword ? (
                              <img src={eyeOpen} alt="show" />
                            ) : (
                              <img src={eyeClose} alt="hide" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </div>
              </CardContent>
              <CardActions>
                <Box className="login-links">
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableFocusRipple
                        disableRipple
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckedboxIcon />}
                        defaultChecked
                      />
                    }
                    label="Remember me"
                    labelPlacement="end"
                  />
                  <a
                    href={AppRoutings.DesignerForgotPassword}
                    title="Forgot password"
                    className="forgot-pwd"
                  >
                    Forgot password?
                  </a>
                </Box>
                <Button
                  variant="contained"
                  title="Login"
                  href={AppRoutings.DesignerDashboard}
                  className="login-btn"
                  disableElevation
                >
                  Login
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DesignerLogin;
