import { saveIcon } from "assets/images";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { getPagePermissions, showLoader } from "utils/helper";
import { AppPages } from "utils/enums/app-pages";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  STUDENT_NOTE_EDIT_SUCCESS_MESSAGE,
  WHITE_SPACE_ERROR_SPACE,
} from "utils/constants/Messages";
import {
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  STUDENT_NOTE_MAX_LENGTH,
} from "utils/constants/constant";
import {
  EditStudentNoteSuccessPayload,
  StudentNoteSuccessPayload,
} from "../../../../store/student/types";

interface IStudentNoteProps {
  studnetNoteRequest: any;
  editstudentNoteRequest: any;
}
interface IEditStudentNoteProps {
  id: number;
  studentNote: string;
}

const StudentNote: React.FC<IStudentNoteProps> = ({
  studnetNoteRequest,
  editstudentNoteRequest,
}) => {
  const [studentNote, setStudentNote] = useState<string>("");
  const [studentName, setStudentName] = useState<string>("");
  const history = useHistory();
  const location = useLocation<{
    studentId: number;
    isDeleted?: boolean;
  }>();
  const user = useSelector((state: any) => state?.auth?.user);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<IEditStudentNoteProps>({
    defaultValues: {
      id: location?.state.studentId,
      studentNote,
    },
  });

  const getStudentNoteError = (): string => {
    if (errors.studentNote) {
      if (errors.studentNote.type === "maxLength") {
        return `Maximum length of student note should be ${STUDENT_NOTE_MAX_LENGTH}`;
      }
      if (errors.studentNote.type === "pattern") {
        return `Student note  ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const onStudentNoteSuccess = (response: StudentNoteSuccessPayload) => {
    setStudentNote(response?.studentNote.studentNote);
    setStudentName(response?.studentNote.studentName);
  };

  const getStudentNote = () => {
    if (studnetNoteRequest) {
      showLoader();
      const { studentId } = location.state;

      const payload = {
        values: { id: studentId },
        callback: onStudentNoteSuccess,
      };

      studnetNoteRequest(payload);
    }
  };
  const isConfirmationPreferenceChange = useSelector(
    (state: any) => state?.student?.isConfirmationPreferenceChange
  );
  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Student);
    if (location?.state.studentId) {
      if (permission.edit) {
        getStudentNote();
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmationPreferenceChange]);

  const handleCancelClick = () => {
    reset({ studentNote });
  };

  useEffect(() => {
    setValue("studentNote", studentNote);
  }, [setValue, studentNote]);

  const onEditStudentNoteSuccess = (
    response: EditStudentNoteSuccessPayload
  ) => {
    if (response.success) {
      toast.success(STUDENT_NOTE_EDIT_SUCCESS_MESSAGE);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const onSubmit = async (data: IEditStudentNoteProps) => {
    if (location.state.studentId && !location.state.isDeleted) {
      if (editstudentNoteRequest) {
        showLoader();
        const payload = {
          values: {
            id: data.id,
            studentNote: data.studentNote,
          },
          callback: onEditStudentNoteSuccess,
        };
        editstudentNoteRequest(payload);
      }
    }
  };

  return (
    <>
      <Card className="std-details-card card-first">
        <Box sx={{ display: "flex", marginBottom: "-10px" }}>
          <Typography variant="h4" className="sco-detail-title">
            Student Name: &nbsp;
          </Typography>
          <Typography variant="h4" className="sco-detail">
            {studentName}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="std-notes"
            className="std-notes-textarea"
            label="Student Notes"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: Boolean(watch("studentNote")),
            }}
            error={!!errors.studentNote}
            helperText={getStudentNoteError()}
            multiline
            {...register("studentNote", {
              maxLength: STUDENT_NOTE_MAX_LENGTH,
              pattern:
                INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
            })}
          />

          <Box className="btn-group">
            <Button
              variant="contained"
              disabled={location.state.isDeleted}
              className="btn-save"
              type="submit"
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Card>
    </>
  );
};
export default StudentNote;
