import {
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  LOCATION_LIST_FAILURE,
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAILURE,
  EDIT_LOCATION_REQUEST,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from "./actionTypes";

import { LocationActions, LocationState } from "./types";

const initialState: LocationState = {
  pending: false,
  locationList: [],
  error: null,
};

const reducers = (state = initialState, action: LocationActions): unknown => {
  switch (action.type) {
    case LOCATION_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case LOCATION_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        locationList: action.payload.locationList,
        error: null,
      };
    case LOCATION_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_LOCATION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_LOCATION_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_LOCATION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_LOCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_LOCATION_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_LOCATION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_LOCATION_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
