import {
  DOCUMENT_LIST_REQUEST,
  DOCUMENT_LIST_SUCCESS,
  DOCUMENT_LIST_FAILURE,
  ADD_DOCUMENT_REQUEST,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAILURE,
  EDIT_DOCUMENT_REQUEST,
  EDIT_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_FAILURE,
  DOCUMENT_BY_ID_REQUEST,
  DOCUMENT_BY_ID_SUCCESS,
  DOCUMENT_BY_ID_FAILURE,
  DELETE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_REQUEST,
} from "./actionTypes";
import { DocumentState, DocumentActions } from "./types";

const initialState: DocumentState = {
  pending: false,
  documentList: [],
  error: null,
};

const reducers = (state = initialState, action: DocumentActions): unknown => {
  switch (action.type) {
    case DOCUMENT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        documentList: action.payload.documentList,
        error: null,
      };
    case DOCUMENT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_DOCUMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_DOCUMENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_DOCUMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_DOCUMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_DOCUMENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DOCUMENT_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DOCUMENT_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        documentDetails: action.payload.documentDetails,
        error: null,
      };
    case DOCUMENT_BY_ID_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_DOCUMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
