import { Box } from "@mui/system";
import {
  Button,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { useForm } from "react-hook-form";
import { IAddEditEducator } from "utils/interfaces/educator";
import { useEffect } from "react";
import { EDUCATOR_STATUS, EDUCATOR_TYPES } from "utils/constants/educator";
import { IRole } from "utils/interfaces/role";
import { EducatorStatus } from "utils/enums/educator";
import { CLOSE_TOOLTIP_TITLE, DEFAULT_PAGE_NO } from "utils/constants/constant";

interface IEducatorFilterProps {
  filterBox: boolean;
  setFilterBox: any;
  handleApplyClick: any;
  filteredData?: IAddEditEducator;
  pageNo: number;
  setPageNo: any;
  setFilteredData: any;
  getEducatorList: any;
  setAppliedFilter: any;
  roleList: Array<IRole>;
  getStatisticsData: (
    filterData?: IAddEditEducator,
    filtered?: boolean
  ) => Promise<void>;
}

const EducatorFilter: React.FC<IEducatorFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getEducatorList,
  setAppliedFilter,
  roleList,
  getStatisticsData,
}) => {
  const { register, handleSubmit, reset, setValue, watch } =
    useForm<IAddEditEducator>({
      defaultValues: {
        educatorType: 0,
        status: 2,
        roleFilter: 0,
      },
    });

  useEffect(() => {
    if (filteredData) {
      setValue("educatorType", filteredData?.educatorType);
      setValue("status", filteredData?.status);
      setValue("roleFilter", filteredData?.roleFilter);
    } else {
      reset();
    }
  }, [filteredData, setValue, filterBox, reset]);

  const onClearClick = () => {
    reset();
    setFilterBox(false);
    setAppliedFilter(false);
    document.body.classList.toggle("filter-open");
    if (pageNo === DEFAULT_PAGE_NO) {
      getEducatorList();
      getStatisticsData();
    } else {
      setPageNo(1);
    }
    setFilteredData();
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setFilterBox(false);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="select">
              <InputLabel
                id="user-type-label"
                shrink={watch("educatorType") !== 0}
              >
                User Type
              </InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type"
                label="User Type"
                value={watch("educatorType")}
                {...register("educatorType")}
              >
                {EDUCATOR_TYPES.map((educator) => (
                  <MenuItem key={educator.value} value={educator.value}>
                    {educator.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel
                id="status-select-label"
                shrink={watch("status") !== 2}
              >
                Status
              </InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                label="Status"
                value={watch("status")}
                {...register("status")}
              >
                {EDUCATOR_STATUS?.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel
                id="role-select-label"
                shrink={watch("roleFilter") !== 0}
              >
                Role
              </InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                label="Role"
                value={watch("roleFilter")}
                {...register("roleFilter")}
              >
                {roleList?.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-clear"
              onClick={onClearClick}
              disabled={
                !(
                  watch("educatorType") ||
                  watch("status") === EducatorStatus.Inactive ||
                  watch("status") === EducatorStatus.Active ||
                  watch("roleFilter")
                )
              }
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default EducatorFilter;
