import { IManagerSettings } from "utils/interfaces/manager-setting";
import baseService from "services/base-service";

const profileBaseUrl = "/api/Profile";

const changePassword = async (payload: {
  userName: string;
  oldPassword: string;
  newPassword: string;
}) => baseService.post(`${profileBaseUrl}/ChangePassword`, payload);

const updateUserProfile = async (payload: {
  firstName: string;
  lastName: string;
  address: string;
}) => baseService.post(`${profileBaseUrl}/UpdateUserProfile`, payload);

const changeManagerSetting = async (payload: IManagerSettings) => {
  const headers = {
    contentType: "multipart/form-data",
  };

  return baseService.post(`${profileBaseUrl}/ChangeManagerSetting`, payload, {
    headers,
  });
};

export default {
  changePassword,
  updateUserProfile,
  changeManagerSetting,
};
