import { Box } from "@mui/system";
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DATE_VIEW_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  MAX_INT_VALUE,
  PAGE_SIZES,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import {
  ICoursesEnroled,
  IEducatorAttendCourse,
  IEducatorListMeta,
  IEnhancedTableProps,
} from "utils/interfaces/educator";
import {
  convertToDateTimeWithFormat,
  getPagePayloadValuesWithId,
  getPagePermissions,
  getPaginationDetailText,
  showLoader,
} from "utils/helper";
import NoRecords from "components/NoRecords";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  CoursesEnroledListSuccessPayload,
  SendReminderToEducatorSuccessPayload,
} from "store/educator/types";
import { useDispatch, useSelector } from "react-redux";
import {
  coursesEnroledListRequest,
  sendReminderToEducatorRequest,
} from "store/educator/actions";
import { toast } from "react-toastify";
import { REMINDER_SENT_SUCCESSFULLY } from "utils/constants/Messages";
import { AppPages } from "utils/enums/app-pages";
import { ExportToCsv } from "export-to-csv";
import EducatorReminderModal from "./EducatorReminderModal";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IEducatorAttendCourse) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

const CoursesAttendedAsStudent: React.FC = () => {
  const location = useLocation<{ educatorData: any }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [coursesAttendedList, setCoursesAttendedList] =
    useState<ICoursesEnroled[]>();
  const [coursesAttendedListMeta, setCoursesAttendedListMeta] =
    useState<IEducatorListMeta>();
  const { educatorData } = location?.state;
  const [page, setPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IEducatorAttendCourse>("course");
  const [reminder, setReminder] = React.useState(false);
  const [courseAttended, setCourseAttended] = React.useState("All");
  const user = useSelector((state: any) => state?.auth?.user);

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleCourseAttendedChange = (event: SelectChangeEvent) => {
    setCourseAttended(event.target.value as string);
  };

  const handleRequestSort = React.useCallback(
    (
      event: React.MouseEvent<unknown>,
      newOrderBy: keyof IEducatorAttendCourse
    ) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleReminder = () => setReminder(true);
  const handleReminderClose = () => setReminder(false);

  const onCoursesEnroledListSuccess = (
    response: CoursesEnroledListSuccessPayload
  ) => {
    setCoursesAttendedList(response.coursesEnroledList);
    setCoursesAttendedListMeta(response.coursesEnroledListPagination);
  };

  const getCoursesEnroledList = () => {
    showLoader();

    const payload = {
      values: {
        ...getPagePayloadValuesWithId(
          educatorData?.id,
          orderBy,
          order,
          pageNo,
          page
        ),
        course: courseAttended,
      },
      callback: onCoursesEnroledListSuccess,
    };

    dispatch(coursesEnroledListRequest(payload));
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.Educators
    );
    if (permission?.edit) {
      if (location?.state?.educatorData) {
        getCoursesEnroledList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location?.state?.educatorData,
    page,
    pageNo,
    order,
    orderBy,
    courseAttended,
  ]);

  const onSendReminderToEducatorSuccess = (
    response: SendReminderToEducatorSuccessPayload
  ) => {
    if (response.success) {
      toast.success(REMINDER_SENT_SUCCESSFULLY);
      handleReminderClose();
    }
  };
  const handleReminderYesClick = () => {
    showLoader();

    const payload = {
      values: {
        id: educatorData?.id,
      },
      callback: onSendReminderToEducatorSuccess,
    };

    dispatch(sendReminderToEducatorRequest(payload));
  };

  const attendedCourses =
    coursesAttendedList && coursesAttendedList.length > 0
      ? coursesAttendedList[0].attendedCount
      : 0;

  const remainingCourses =
    coursesAttendedList && coursesAttendedList.length > 0
      ? coursesAttendedList[0].remainingCount
      : 0;

  const csvFileHeaders = [
    "Course Session Name",
    "Course Start Date",
    "Course End Date",
    "Enrolment Status",
  ];

  const getCourseEnrolledData = (list: ICoursesEnroled[]) => {
    const data = list?.map((course: ICoursesEnroled) => {
      return {
        "Course Session Name": course.courseName,
        "Course Start Date": `${convertToDateTimeWithFormat(
          course.courseStartDate,
          DATE_VIEW_FORMAT
        )}, ${convertToDateTimeWithFormat(
          course.courseStartTime,
          TIME_VIEW_FORMAT
        )}`,
        "Course End Date": `${convertToDateTimeWithFormat(
          course.courseEndDate,
          DATE_VIEW_FORMAT
        )}, ${convertToDateTimeWithFormat(
          course.courseEndTime,
          TIME_VIEW_FORMAT
        )}`,
        "Enrolment Status":
          course.enrollmentStatus === "Approved"
            ? "Confirmed"
            : course.enrollmentStatus,
      };
    });

    return data;
  };

  const onExportCoursesEnroledListSuccess = (
    response: CoursesEnroledListSuccessPayload
  ) => {
    const modifiedCourseEnrolledData = getCourseEnrolledData(
      response.coursesEnroledList
    );
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeaders,
      filename: "Courses Attended As Student",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedCourseEnrolledData);
  };

  const getExportCourseEnrolled = () => {
    showLoader();

    const payload = {
      values: {
        ...getPagePayloadValuesWithId(
          educatorData?.id,
          orderBy,
          order,
          DEFAULT_PAGE_NO,
          MAX_INT_VALUE
        ),
        course: courseAttended,
      },
      callback: onExportCoursesEnroledListSuccess,
    };

    dispatch(coursesEnroledListRequest(payload));
  };

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Educator Details
        </Typography>
        <Box className="std-detail-btn-group">
          <Button
            variant="outlined"
            className="btn-back"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            className="btn-enrol-confirm"
            onClick={getExportCourseEnrolled}
          >
            Export
          </Button>
        </Box>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Card>
            <Box className="table-card-header">
              <Typography variant="h3">Courses Attended As Student</Typography>
              <Box>
                <Box className="counter-box success">
                  <div className="title">Attended</div>
                  <div className="counter">{attendedCourses}</div>
                </Box>
                <Box className="counter-box warning">
                  <div className="title">Remaining</div>
                  <div className="counter">{remainingCourses}</div>
                </Box>
                <FormControl fullWidth className="table-select course-attnd">
                  <InputLabel id="course-attend-label">
                    Course Attended
                  </InputLabel>
                  <Select
                    labelId="course-attend-label"
                    id="course-attend"
                    label="Course Attended"
                    value={courseAttended}
                    onChange={handleCourseAttendedChange}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Remaining">Remaining</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="outlined"
                  className="btn-view-all"
                  onClick={handleReminder}
                  disabled={remainingCourses === 0}
                >
                  Reminder
                </Button>
              </Box>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "course")}
                      fieldName="Course Session Name"
                      keyName="course"
                      sxStyle={{
                        maxWidth: "491px",
                        width: "491px",
                        minWidth: "491px",
                      }}
                    />
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "startDate")}
                      fieldName="Course Start Date"
                      keyName="startDate"
                      sxStyle={{
                        maxWidth: "214px",
                        width: "214px",
                        minWidth: "180px",
                      }}
                    />
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "endDate")}
                      fieldName="Course End Date"
                      keyName="endDate"
                      sxStyle={{
                        width: "245px",
                        minWidth: "180px",
                      }}
                    />
                    <TableCell
                      align="center"
                      sx={{ width: "197px", minWidth: "197px" }}
                    >
                      Enrolment Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coursesAttendedList?.map((row) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {row.courseName}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {convertToDateTimeWithFormat(
                            row.courseStartDate,
                            DATE_VIEW_FORMAT
                          )}
                          ,{" "}
                          {convertToDateTimeWithFormat(
                            row.courseStartTime,
                            TIME_VIEW_FORMAT
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">
                          {convertToDateTimeWithFormat(
                            row.courseEndDate,
                            DATE_VIEW_FORMAT
                          )}
                          ,{" "}
                          {convertToDateTimeWithFormat(
                            row.courseEndTime,
                            TIME_VIEW_FORMAT
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${row.enrollmentStatus.toLocaleLowerCase()} status`}
                      >
                        {row.enrollmentStatus === "Approved" ? (
                          <div>Confirmed</div>
                        ) : (
                          <div>{row.enrollmentStatus}</div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {coursesAttendedList && coursesAttendedList.length > 0 ? (
              <Box className="custom-pagination">
                <Box className="custom-rowperpage">
                  <Typography variant="body2" component="span">
                    Page:
                  </Typography>
                  <Select
                    id="pagination-select"
                    value={page}
                    onChange={handleChange}
                    MenuProps={{
                      className: "pagination-menu",
                    }}
                  >
                    {PAGE_SIZES?.map((pageSize) => (
                      <MenuItem
                        key={pageSize.value}
                        value={pageSize.value}
                        selected={pageSize?.selected}
                      >
                        {pageSize.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="body2" component="span">
                    {getPaginationDetailText(
                      coursesAttendedListMeta,
                      pageNo,
                      page
                    )}
                  </Typography>{" "}
                </Box>
                <Pagination
                  count={coursesAttendedListMeta?.totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={pageNo}
                  onChange={onPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                    />
                  )}
                />
              </Box>
            ) : (
              <NoRecords />
            )}
          </Card>
        </Grid>
      </Grid>

      {reminder && (
        <EducatorReminderModal
          handleReminderClose={handleReminderClose}
          handleYesClick={handleReminderYesClick}
          remainingCourses={remainingCourses}
        />
      )}
    </>
  );
};

export default CoursesAttendedAsStudent;
