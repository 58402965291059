import { connect, MapDispatchToProps } from "react-redux";
import {
  approveEnrolmentRequest,
  enrolmentOptionListRequest,
  getStudentEnrolmentDataForApproveRequest,
} from "store/studentEnrolment/actions";
import {
  IApproveRejectEnrolmentContainerDispatch,
  IApproveEnrolmentData,
} from "utils/interfaces/student-enrolment";

import StudentEnrolmentApprovalComponent, {
  StudentEnrolmentApprovalProps,
} from "pages/StudentEnrolments/ApproveRejectEnrolments";

const mapDispatchToProps: MapDispatchToProps<
  IApproveRejectEnrolmentContainerDispatch,
  IApproveEnrolmentData
> = {
  enrolmentOptionListRequest,
  approveEnrolmentRequest,
  getStudentEnrolmentDataForApproveRequest,
};

const connector = connect(null, mapDispatchToProps);

const StudentEnrolmentApproval: React.FC<StudentEnrolmentApprovalProps> = (
  props
) => {
  return <StudentEnrolmentApprovalComponent {...props} />;
};

export default connector(StudentEnrolmentApproval);
