import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  CLOSE_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  MAX_INT_VALUE,
} from "utils/constants/constant";
import {
  IEnrolmentOptionItem,
  IEnrolmentOptions,
} from "utils/interfaces/student-enrolment";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { OrderByOptions } from "utils/enums/sorting";
import {
  showLoaderForBatchApiCall,
  getPagePayloadValues,
  hideLoaderForBatchApiCall,
} from "utils/helper";
import { ITerm, ITermPagePayloadValues } from "utils/interfaces/term";
import { useDispatch } from "react-redux";
import { termListRequest } from "store/term/actions";
import { TermListSuccessPayload } from "store/term/types";
import { IStaffReportFilter } from "utils/interfaces/staff-report";

interface IStaffReportFilterProps {
  filterBox: boolean;
  setFilterBox: React.Dispatch<React.SetStateAction<boolean>>;
  handleApplyClick: (formData: IStaffReportFilter) => void;
  filteredData?: IStaffReportFilter;
  pageNo: number;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
  setFilteredData: React.Dispatch<
    React.SetStateAction<IStaffReportFilter | undefined>
  >;
  getReportList: (
    filterData?: IStaffReportFilter,
    pageNum?: number
  ) => Promise<void>;
  setAppliedFilter: React.Dispatch<React.SetStateAction<boolean>>;
  enrolmentOptionList?: IEnrolmentOptions;
}

interface IReportFilter {
  terms?: string[];
  employmentStatus?: string[];
  currentRole?: string[];
  workEnvironment?: string[];
  site?: string[];
}

const defaultValues: IReportFilter = {
  terms: [],
  employmentStatus: [],
  currentRole: [],
  workEnvironment: [],
  site: [],
};

const StaffReportFilter: React.FC<IStaffReportFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getReportList,
  setAppliedFilter,
  enrolmentOptionList,
}) => {
  const { register, handleSubmit, reset, setValue, watch } =
    useForm<IStaffReportFilter>({
      defaultValues,
    });
  const dispatch = useDispatch();
  const [termList, setTermList] = useState<ITerm[]>();
  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
    hideLoaderForBatchApiCall();
  };

  const getTermList = () => {
    showLoaderForBatchApiCall();

    const values: ITermPagePayloadValues = getPagePayloadValues(
      "TermName",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.termName = "";

    const payload = {
      values,
      callback: onTermListSuccess,
    };

    dispatch(termListRequest(payload));
  };

  useEffect(() => {
    if (filteredData) {
      setValue("currentRole", filteredData?.currentRole);
      setValue("employmentStatus", filteredData?.employmentStatus);
      setValue("site", filteredData?.site);
      setValue("workEnvironment", filteredData?.workEnvironment);
      setValue("terms", filteredData?.terms);
    } else {
      reset();
    }
    getTermList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, setValue, filterBox, reset]);

  const onClearClick = () => {
    if (
      !(
        !filteredData?.site?.length &&
        !filteredData?.employmentStatus?.length &&
        !filteredData?.currentRole?.length &&
        !filteredData?.workEnvironment?.length &&
        !filteredData?.terms?.length
      )
    ) {
      reset();
      setFilterBox(false);
      setAppliedFilter(false);
      document.body.classList.toggle("filter-open");
      if (pageNo === DEFAULT_PAGE_NO) {
        getReportList();
      } else {
        setPageNo(1);
      }
      setFilteredData(undefined);
    }
  };

  const renderSelectedValues = (
    selected: string[],
    optionList: Array<IEnrolmentOptionItem> | undefined,
    isValueField?: boolean
  ) => {
    const selectedString: string[] = [];
    if (isValueField) {
      selected.forEach((item: string) => {
        const selectedItem = optionList?.find(
          (option) => option.value === item
        );
        if (selectedItem) {
          selectedString.push(selectedItem.label);
        }
      });
    } else {
      selected.forEach((item: string) => {
        const selectedItem = optionList?.find(
          (option) => option.label === item
        );
        if (selectedItem) {
          selectedString.push(selectedItem.label);
        }
      });
    }

    return selectedString.join(", ");
  };

  const handleFieldChange = (
    event: SelectChangeEvent<string[]>,
    field: keyof IStaffReportFilter
  ) => {
    const { value } = event.target;
    setValue(field, value as string[]);
  };
  const termOptions: Array<IEnrolmentOptionItem> | undefined = termList?.map(
    (term) => ({
      value: term.id.toString(),
      label: term.termName,
    })
  );

  return (
    <>
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setFilterBox(false);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Term</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Term"
                {...register("terms")}
                value={watch("terms") || []}
                onChange={(e) => handleFieldChange(e, "terms")}
                input={<OutlinedInput label="Term" />}
                renderValue={(selected) =>
                  renderSelectedValues(selected, termOptions, true)
                }
              >
                {termOptions?.map((term) => (
                  <MenuItem key={term.value} value={term.value}>
                    <Checkbox
                      checked={
                        (watch("terms") as unknown as string[]).indexOf(
                          term.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={term.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Employment Status</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Employment Status"
                {...register("employmentStatus")}
                value={watch("employmentStatus") || []}
                onChange={(e) => handleFieldChange(e, "employmentStatus")}
                input={<OutlinedInput label="Employment Status" />}
                renderValue={(selected) =>
                  renderSelectedValues(
                    selected,
                    enrolmentOptionList?.staffEmploymentStatus
                  )
                }
              >
                {enrolmentOptionList?.staffEmploymentStatus?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      checked={
                        (
                          watch("employmentStatus") as unknown as string[]
                        ).indexOf(option.value) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={option.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Current Role</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Current Role"
                {...register("currentRole")}
                value={watch("currentRole") || []}
                onChange={(e) => handleFieldChange(e, "currentRole")}
                input={<OutlinedInput label="Current Role" />}
                renderValue={(selected) =>
                  renderSelectedValues(
                    selected,
                    enrolmentOptionList?.currentRole
                  )
                }
              >
                {enrolmentOptionList?.currentRole?.map((currentRole) => (
                  <MenuItem key={currentRole.value} value={currentRole.value}>
                    <Checkbox
                      checked={
                        (watch("currentRole") as unknown as string[]).indexOf(
                          currentRole.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={currentRole.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Work Environment</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Work Environment"
                {...register("workEnvironment")}
                value={watch("workEnvironment") || []}
                onChange={(e) => handleFieldChange(e, "workEnvironment")}
                input={<OutlinedInput label="Work Environment" />}
                renderValue={(selected) =>
                  renderSelectedValues(
                    selected,
                    enrolmentOptionList?.workEnvironment
                  )
                }
              >
                {enrolmentOptionList?.workEnvironment?.map(
                  (workEnvironment) => (
                    <MenuItem
                      key={workEnvironment.value}
                      value={workEnvironment.value}
                    >
                      <Checkbox
                        checked={
                          (
                            watch("workEnvironment") as unknown as string[]
                          ).indexOf(workEnvironment.value) > -1
                        }
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckedBoxIcon />}
                      />
                      <ListItemText primary={workEnvironment.value} />
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Site</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Site"
                {...register("site")}
                value={watch("site") || []}
                onChange={(e) => handleFieldChange(e, "site")}
                input={<OutlinedInput label="Site" />}
                renderValue={(selected) =>
                  renderSelectedValues(
                    selected,
                    enrolmentOptionList?.siteOrService
                  )
                }
              >
                {enrolmentOptionList?.siteOrService?.map((siteOrService) => (
                  <MenuItem
                    key={siteOrService.value}
                    value={siteOrService.value}
                  >
                    <Checkbox
                      checked={
                        (watch("site") as unknown as string[]).indexOf(
                          siteOrService.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={siteOrService.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-clear"
              onClick={onClearClick}
              disabled={
                !filteredData?.site?.length &&
                !filteredData?.employmentStatus?.length &&
                !filteredData?.currentRole?.length &&
                !filteredData?.workEnvironment?.length &&
                !filteredData?.terms?.length
              }
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default StaffReportFilter;
