import { createSelector } from "reselect";
import { get } from "lodash";

import { AuthState } from "store/rootReducer";

const getPending = (state: AuthState) => get(state, "auth.pending", false);

const getToken = (state: AuthState) => get(state, "auth.token");

const getError = (state: AuthState) => get(state, "auth.error");

const getUser = (state: AuthState) => get(state, "auth.user");

export const getAuthSelector = createSelector(getToken, (token) => token);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getUserSelector = createSelector(getUser, (user) => user);

export const checkIsAuthenticated = createSelector(
  getToken,
  (token) => !!token
);
