import baseService from "services/base-service";

const courseBaseUrl = `/api/Courses`;

const getCourseList = async (payload: {
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  name: string;
  courseNo?: string;
  stream?: string;
}) => baseService.post(`${courseBaseUrl}/GetCourses`, payload);

const addCourse = async (payload: {
  name: string;
  courseNo: string;
  stream: string;
  previousName: string;
  skills: string;
  knowledge: string;
  attitudes: string;
  courseAvailableInThisTerm: number;
  noOfSession: number;
  lengthOfSingleSession: number;
  description: string;
}) => baseService.post(courseBaseUrl, payload);

const editCourse = async (payload: {
  id: number;
  name: string;
  courseNo: string;
  stream: string;
  previousName: string;
  skills: string;
  knowledge: string;
  attitudes: string;
  courseAvailableInThisTerm: number;
  noOfSession: number;
  lengthOfSingleSession: number;
  description: string;
}) => baseService.put(courseBaseUrl, payload);

const deleteCourse = async (payload: { id: number }) =>
  baseService.delete(`${courseBaseUrl}?id=${payload.id}`);

const getCourseById = async (payload: { id: number }) =>
  baseService.get(`${courseBaseUrl}?id=${payload.id}`);

export default {
  getCourseList,
  addCourse,
  editCourse,
  deleteCourse,
  getCourseById,
};
