import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  checkIcon,
  closeIcon,
  courseListIcon,
  courseListLightIcon,
  deleteIcon,
  editIcon,
  filterLightIcon,
  filterPrimaryIcon,
  notificationCircle,
  plusLightIcon,
  saveIcon,
  uncheckIcon,
} from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";
import projectTheme from "app.theme";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

function CheckboxIcon() {
  return <img src={uncheckIcon} alt="checkbox" />;
}
function CheckedboxIcon() {
  return <img src={checkIcon} alt="checkbox" />;
}

interface EducatorsData {
  name: string;
  email: string;
  type: string;
  course: number;
  courseattnd: number;
  role: string;
  status: string;
}

function createEducatorData(
  name: string,
  email: string,
  type: string,
  course: number,
  courseattnd: number,
  role: string,
  status: string
): EducatorsData {
  return {
    name,
    email,
    type,
    course,
    courseattnd,
    role,
    status,
  };
}

const educatorsrow = [
  createEducatorData(
    "Shane Lincoin",
    "shanelincoin@gmail.com",
    "Peer Educator",
    7,
    7,
    "Educators",
    "active"
  ),
  createEducatorData(
    "Rachael Rogan",
    "rachaelrogan@gmail.com",
    "Clinical Educator",
    5,
    5,
    "Administartor, Educator, Peer Learning",
    "inactive"
  ),
  createEducatorData(
    "Michale Conte",
    "michaleconte@gmail.com",
    "Peer Educator",
    4,
    4,
    "Educators",
    "active"
  ),
  createEducatorData(
    "Katherina Stufano",
    "katherinastufano@gmail.com",
    "Peer Educator",
    6,
    6,
    "Educators",
    "inactive"
  ),
  createEducatorData(
    "Kamara Ducki",
    "Kamaraducki@email.com",
    "Peer Educator",
    7,
    7,
    "Educators",
    "inactive"
  ),
  createEducatorData(
    "Lydia Lin",
    "lydialin@email.com",
    "Peer Educator",
    3,
    3,
    "Educators",
    "inactive"
  ),
  createEducatorData(
    "Michale Conte",
    "michaleconte@gmail.com",
    "Peer Educator",
    4,
    4,
    "Educators, Administrator ",
    "inactive"
  ),
  createEducatorData(
    "Katherina Stufano",
    "katherinastufano@gmail.com",
    "Clinical Educator",
    7,
    7,
    "Educators",
    "active"
  ),
  createEducatorData(
    "Kamara Ducki",
    "Kamaraducki@email.com",
    "Clinical Educator",
    5,
    5,
    "Educators",
    "inactive"
  ),
  createEducatorData(
    "Lydia Lin",
    "lydialin@email.com",
    "Clinical Educator",
    3,
    3,
    "Educators",
    "inactive"
  ),
];

interface EducatorsDetailsData {
  coursename: string;
  course: string;
  term: string;
  enrolled: number;
  location: string;
  datetime: string;
}

function createEducatorDetailsData(
  coursename: string,
  course: string,
  term: string,
  enrolled: number,
  location: string,
  datetime: string
): EducatorsDetailsData {
  return {
    coursename,
    course,
    term,
    enrolled,
    location,
    datetime,
  };
}

const educatorsdetailsrow = [
  createEducatorDetailsData(
    "Life Beyond Trauma [2023 T1]- (E-17, WL-0,Max -25 - Open) at Recovery and wellbeing college, Kogarah BLENDED, Face to Face / Online 01 Feb 2023 10:00 AM to 4:00 PM",
    "S020017-ENG-Life Beyond Trauma",
    "2023 T1",
    17,
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createEducatorDetailsData(
    "Challenging Stigma [2023 T1]- (E-24, WL-0,Max -25 - Open) at Recovery and wellbeing college, Kogarah BLENDED, Face to Face / Online 01 Feb 2023 10:00 AM to 4:00 PM",
    "S020010-ENG-Challenging Stigma",
    "2023 T2",
    24,
    "151 Goswell Road London",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createEducatorDetailsData(
    "Challenging Stigma [2023 T1]- (E-24, WL-0,Max -25 - Open) at Recovery and wellbeing college, Kogarah BLENDED, Face to Face / Online 01 Feb 2023 10:00 AM to 4:00 PM",
    "S020010-ENG-Challenging Stigma",
    "2023 T2",
    4,
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createEducatorDetailsData(
    "Challenging Stigma [2023 T1]- (E-24, WL-0,Max -25 - Open) at Recovery and wellbeing college, Kogarah BLENDED, Face to Face / Online 01 Feb 2023 10:00 AM to 4:00 PM",
    "S020010-ENG-Challenging Stigma",
    "2023 T2",
    0,
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
];

interface StudentEducatorsDetailsData {
  coursename: string;
  startdate: string;
  enddate: string;
  enrolstatus: string;
}

function createStudentEducatorDetailsData(
  coursename: string,
  startdate: string,
  enddate: string,
  enrolstatus: string
): StudentEducatorsDetailsData {
  return {
    coursename,
    startdate,
    enddate,
    enrolstatus,
  };
}

const studenteducatorsdetailsrow = [
  createStudentEducatorDetailsData(
    "Life Beyond Trauma [2023 T1]- (E-17, WL-0,Max -25 - Open) at Recovery and wellbeing college, Kogarah BLENDED, Face to Face / Online 01 Feb 2023 10:00 AM to 4:00 PM",
    "12 Jan 2023, 12:00 PM",
    "12 Jan 2023, 12:00 PM",
    "confirmed"
  ),
  createStudentEducatorDetailsData(
    "Challenging Stigma [2023 T1]- (E-24, WL-0,Max -25 - Open) at Recovery and wellbeing college, Kogarah BLENDED, Face to Face / Online 01 Feb 2023 10:00 AM to 4:00 PM",
    "12 Jan 2023, 12:00 PM",
    "12 Jan 2023, 12:00 PM",
    "withdrawn"
  ),
  createStudentEducatorDetailsData(
    "Challenging Stigma [2023 T1]- (E-24, WL-0,Max -25 - Open) at Recovery and wellbeing college, Kogarah BLENDED, Face to Face / Online 01 Feb 2023 10:00 AM to 4:00 PM",
    "12 Jan 2023, 12:00 PM",
    "12 Jan 2023, 12:00 PM",
    "confirmed"
  ),
  createStudentEducatorDetailsData(
    "Challenging Stigma [2023 T1]- (E-24, WL-0,Max -25 - Open) at Recovery and wellbeing college, Kogarah BLENDED, Face to Face / Online 01 Feb 2023 10:00 AM to 4:00 PM",
    "12 Jan 2023, 12:00 PM",
    "12 Jan 2023, 12:00 PM",
    "withdrawn"
  ),
];

const roles = ["Educator", "Administrator", "Peer Educator"];
const educatorTypes = [
  "Clinical Educator",
  "Peer Educator",
  "Other (External Training Organization)",
];

const DesignerEducators: React.FC = () => {
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [usertype, setusertype] = React.useState("");

  const handleusertypeChange = (event: SelectChangeEvent) => {
    setusertype(event.target.value as string);
  };

  const [statusselect, setStatusselect] = React.useState("");

  const handleStatusselectChange = (event: SelectChangeEvent) => {
    setStatusselect(event.target.value as string);
  };

  const [statusselect2, setStatusselect2] = React.useState("2");

  const handleStatusselect2Change = (event: SelectChangeEvent) => {
    setStatusselect2(event.target.value as string);
  };

  const [serviceselect, setServiceselect] = React.useState("1");

  const handleServiceselectChange = (event: SelectChangeEvent) => {
    setServiceselect(event.target.value as string);
  };

  const [status, setstatus] = React.useState("");

  const handlestatusChange = (event: SelectChangeEvent) => {
    setstatus(event.target.value as string);
  };

  const [role, setrole] = React.useState("");

  const handleroleChange = (event: SelectChangeEvent) => {
    setrole(event.target.value as string);
  };

  const [langselect, setLangselect] = React.useState("");

  const handleLangselectChange = (event: SelectChangeEvent) => {
    setLangselect(event.target.value as string);
  };

  const [langselect2, setLangselect2] = React.useState("1");

  const handleLangselect2Change = (event: SelectChangeEvent) => {
    setLangselect2(event.target.value as string);
  };
  const [courseattd, setCourseattnd] = React.useState("1");

  const handlecourseattdChange = (event: SelectChangeEvent) => {
    setCourseattnd(event.target.value as string);
  };

  const [profilelink, setProfileLink] = React.useState("");

  const handleProfileLinkChange = (event: SelectChangeEvent) => {
    setProfileLink(event.target.value as string);
  };

  const [filterbox, setFilterbox] = React.useState(false);
  const [educators, setEducators] = React.useState(true);
  const [educatordetails, setEducatordetails] = React.useState(false);
  const [studenteducatordetails, setStudenteducatordetails] =
    React.useState(false);
  const [addeducatordetails, setAddeducatordetails] = React.useState(false);
  const [editeducatordetails, setEditeducatordetails] = React.useState(false);

  const [roleselect, setRoleselect] = React.useState<string[]>([]);

  const handleRoleselectChange = (
    event: SelectChangeEvent<typeof roleselect>
  ) => {
    const {
      target: { value },
    } = event;
    setRoleselect(typeof value === "string" ? value.split(",") : value);
  };

  const [roleselect2, setRoleselect2] = React.useState<string[]>([]);

  const handleRoleselect2Change = (
    event: SelectChangeEvent<typeof roleselect2>
  ) => {
    const {
      target: { value },
    } = event;
    setRoleselect2(typeof value === "string" ? value.split(",") : value);
  };

  const [educatortype, seteducatortype] = React.useState<string[]>([]);

  const handleEducatortypeChange = (
    event: SelectChangeEvent<typeof educatortype>
  ) => {
    const {
      target: { value },
    } = event;
    seteducatortype(typeof value === "string" ? value.split(",") : value);
  };

  const [educatortype2, seteducatortype2] = React.useState<string[]>([]);

  const handleEducatortype2Change = (
    event: SelectChangeEvent<typeof educatortype2>
  ) => {
    const {
      target: { value },
    } = event;
    seteducatortype2(typeof value === "string" ? value.split(",") : value);
  };

  const [reminder, setReminder] = React.useState(false);
  const handleReminder = () => setReminder(true);
  const handleReminderClose = () => setReminder(false);

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Box
        className="filter-overlay"
        onClick={() => {
          document.body.classList.toggle("filter-open");
          setFilterbox(false);
        }}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        {educators && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Educators
              </Typography>
              <Button
                variant="contained"
                className="btn-add"
                onClick={() => {
                  setAddeducatordetails(true);
                  setEducators(false);
                }}
              >
                <img src={plusLightIcon} alt="plus" />
                Add
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{ justifyContent: "space-between", width: "100%" }}
                      className="search-filter-inputs"
                    >
                      <TextField
                        id="search"
                        variant="outlined"
                        className="search-input"
                        placeholder="Search"
                      />

                      <Button
                        variant="outlined"
                        className="btn-filter"
                        onClick={() => {
                          setFilterbox(true);
                          document.body.classList.toggle("filter-open");
                        }}
                      >
                        <img
                          src={filterPrimaryIcon}
                          alt="filter"
                          className="default"
                        />
                        <img
                          src={filterLightIcon}
                          alt="filter"
                          className="active"
                        />
                        <span>Filter</span>
                      </Button>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              maxWidth: "173px",
                              width: "173px",
                              minWidth: "203px",
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "209px", minWidth: "209px" }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "161px", minWidth: "161px" }}
                          >
                            Type
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "102px", minWidth: "102px" }}
                          >
                            Courses Delivered
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "146px", minWidth: "146px" }}
                          >
                            Course Attended As Student
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "175px", minWidth: "175px" }}
                          >
                            Role
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "104px", minWidth: "104px" }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "122px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "112px",
                              },
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {educatorsrow.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" className="email">
                                {row.email}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.type}</TableCell>
                            <TableCell>
                              <Button
                                className="btn-course"
                                variant="outlined"
                                onClick={() => {
                                  setEducatordetails(true);
                                  setEducators(false);
                                }}
                              >
                                <img
                                  src={courseListIcon}
                                  alt="Course List"
                                  className="default"
                                />
                                <img
                                  src={courseListLightIcon}
                                  alt="Course List"
                                  className="active"
                                />
                                <Typography variant="body2">
                                  {row.course}
                                </Typography>
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Button
                                className="btn-course"
                                variant="outlined"
                                onClick={() => {
                                  setStudenteducatordetails(true);
                                  setEducators(false);
                                }}
                              >
                                <img
                                  src={courseListIcon}
                                  alt="Course List"
                                  className="default"
                                />
                                <img
                                  src={courseListLightIcon}
                                  alt="Course List"
                                  className="active"
                                />
                                <Typography variant="body2">
                                  {row.courseattnd}
                                </Typography>
                              </Button>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" className="role">
                                {row.role}
                              </Typography>
                            </TableCell>
                            <TableCell className={`${row.status} status`}>
                              <div>{row.status}</div>
                            </TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setEditeducatordetails(true);
                                    setEducators(false);
                                  }}
                                >
                                  <img src={editIcon} alt="edit" />
                                </IconButton>
                                <IconButton>
                                  <img src={deleteIcon} alt="delete" />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
        {educatordetails && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Educator Details
              </Typography>
              <Button
                variant="outlined"
                className="btn-back"
                onClick={() => {
                  setEducatordetails(false);
                  setEducators(true);
                }}
              >
                Back
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Typography variant="h3">Courses Delivered</Typography>
                    <Box>
                      <Typography variant="h5">
                        Name: <span>Shane Lincoin</span>
                      </Typography>
                      <Typography variant="h5">
                        Email:{" "}
                        <span className="mail-add">shanelincoin@gmail.com</span>
                      </Typography>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              maxWidth: "491px",
                              width: "491px",
                              minWidth: "491px",
                            }}
                          >
                            Course Session Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "136px", minWidth: "136px" }}
                          >
                            Course
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "120px", minWidth: "120px" }}
                          >
                            Term
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "115px", minWidth: "115px" }}
                          >
                            No. Enrolled
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "163px", minWidth: "163px" }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "167px", minWidth: "167px" }}
                          >
                            <Typography
                              variant="body2"
                              className="first-session"
                            >
                              Date & Time of First Session
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {educatorsdetailsrow.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.coursename}
                            </TableCell>
                            <TableCell>{row.course}</TableCell>
                            <TableCell>{row.term}</TableCell>
                            <TableCell>{row.enrolled}</TableCell>
                            <TableCell>{row.location}</TableCell>
                            <TableCell>{row.datetime}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
        {studenteducatordetails && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Educator Details
              </Typography>
              <Button
                variant="outlined"
                className="btn-back"
                onClick={() => {
                  setStudenteducatordetails(false);
                  setEducators(true);
                }}
              >
                Back
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Typography variant="h3">
                      Courses Attended As Student
                    </Typography>
                    <Box>
                      <Box className="counter-box success">
                        <div className="title">Attended</div>
                        <div className="counter">16</div>
                      </Box>
                      <Box className="counter-box warning">
                        <div className="title">Remaining</div>
                        <div className="counter">12</div>
                      </Box>
                      <FormControl
                        fullWidth
                        className="table-select course-attnd"
                      >
                        <InputLabel id="course-attend-label">
                          Course Attended
                        </InputLabel>
                        <Select
                          labelId="course-attend-label"
                          id="course-attend"
                          label="Course Attended"
                          value={courseattd}
                          onChange={handlecourseattdChange}
                        >
                          <MenuItem value={1}>All</MenuItem>
                          <MenuItem value={2}>Remaining</MenuItem>
                        </Select>
                      </FormControl>
                      <Button
                        variant="outlined"
                        className="btn-view-all"
                        onClick={handleReminder}
                      >
                        Reminder
                      </Button>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              maxWidth: "491px",
                              width: "491px",
                              minWidth: "491px",
                            }}
                          >
                            Course Session Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "234px", minWidth: "180px" }}
                          >
                            Course Start Date
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "270px", minWidth: "180px" }}
                          >
                            Course End Date
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "197px", minWidth: "197px" }}
                          >
                            Enrolment Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {studenteducatorsdetailsrow.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.coursename}
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">
                                {row.startdate}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">
                                {row.enddate}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={`${row.enrolstatus} status status-left`}
                            >
                              <div>{row.enrolstatus}</div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
        {addeducatordetails && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Add Educator Details{" "}
              </Typography>
              <Button
                variant="outlined"
                className="btn-back"
                onClick={() => {
                  setAddeducatordetails(false);
                  setEducators(true);
                }}
              >
                Back
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Grid
                    container
                    columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                  >
                    <Grid item xs={12} lg={5}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="first-name"
                            label="Firstname"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="last-name"
                            label="Lastname"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="email"
                            type="email"
                            label="Email"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="secondary-email"
                            type="email"
                            label="Secondary Email"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                          labelId="role-select-label"
                          id="role-select"
                          multiple
                          label="Role"
                          value={roleselect}
                          onChange={handleRoleselectChange}
                          input={<OutlinedInput label="Role" />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {roles.map((selectrole) => (
                            <MenuItem key={selectrole} value={selectrole}>
                              <Checkbox
                                checked={roleselect.indexOf(selectrole) > -1}
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckedboxIcon />}
                              />
                              <ListItemText primary={selectrole} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                              labelId="status-label"
                              id="status"
                              label="Status"
                              value={statusselect}
                              onChange={handleStatusselectChange}
                            >
                              <MenuItem value={1}>Active</MenuItem>
                              <MenuItem value={2}>Inactive</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="educator-type-label">
                              Educator Type
                            </InputLabel>
                            <Select
                              labelId="educator-type-label"
                              id="educator-type"
                              multiple
                              label="Educator Type"
                              value={educatortype}
                              onChange={handleEducatortypeChange}
                              input={<OutlinedInput label="Educator Type" />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={{
                                className: "educator-type-select",
                              }}
                            >
                              {educatorTypes.map((selecteducator) => (
                                <MenuItem
                                  key={selecteducator}
                                  value={selecteducator}
                                >
                                  <Checkbox
                                    checked={
                                      educatortype.indexOf(selecteducator) > -1
                                    }
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckedboxIcon />}
                                  />
                                  <ListItemText primary={selecteducator} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <TextField
                        id="street-add"
                        label="Street Address"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="suburb"
                            label="Suburb"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="postcode"
                            label="Postcode"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="state"
                            label="State"
                            defaultValue="NSW"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <TextField
                        id="street-add-2"
                        label="Street Address 2"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="city"
                            label="city"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="state"
                            label="State"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="zip"
                            label="Zip"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="service-label">Service</InputLabel>
                        <Select
                          labelId="service-label"
                          id="service"
                          label="Service"
                          value={serviceselect}
                          onChange={handleServiceselectChange}
                        >
                          <MenuItem value={1}>First Choice</MenuItem>
                          <MenuItem value={2}>Second Choice</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id="other-wrkspace"
                        label="Other Workplace"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id="buss-phone"
                        label="Daytime / Business Phone"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id="prsnl-phone"
                        label="After Hours / Personal Phone"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="availability"
                        label="Availability"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="emergency-ctnt"
                        label="Emergency Contact"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="emergency-ctnt-rln"
                        label="Emergency Contact Relationship"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="emergency-ctnt-phone"
                        label="Emergency Contact Phone"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="skills"
                        className="skills-textarea"
                        label="Skills/ Experience/ Interests"
                        fullWidth
                        variant="outlined"
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="lang-label">
                          Course Delivery Language
                        </InputLabel>
                        <Select
                          labelId="lang-label"
                          id="lang"
                          label="Course Delivery Language"
                          value={langselect}
                          onChange={handleLangselectChange}
                        >
                          <MenuItem value={1}>First Choice</MenuItem>
                          <MenuItem value={2}>Second Choice</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="profile-link-label">
                          Student Profile Link
                        </InputLabel>
                        <Select
                          labelId="profile-link-label"
                          id="profile-link"
                          label="Student Profile Link"
                          value={profilelink}
                          onChange={handleProfileLinkChange}
                        >
                          <MenuItem value={1}>Melissa Nakhle</MenuItem>
                          <MenuItem value={2}>Katherina Stufano</MenuItem>
                          <MenuItem value={3}>Rachael Rogan</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box className="btn-group">
                    <Button
                      variant="contained"
                      className="btn-save"
                      onClick={() => {
                        setAddeducatordetails(false);
                        setEducators(true);
                      }}
                    >
                      <img src={saveIcon} alt="save" />
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      className="btn-cancel"
                      onClick={() => {
                        setAddeducatordetails(false);
                        setEducators(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
        {editeducatordetails && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Add Educator Details{" "}
              </Typography>
              <Button
                variant="outlined"
                className="btn-back"
                onClick={() => {
                  setEditeducatordetails(false);
                  setEducators(true);
                }}
              >
                Back
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Grid
                    container
                    columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                  >
                    <Grid item xs={12} lg={5}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="first-name"
                            label="Firstname"
                            fullWidth
                            defaultValue="Shane "
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="last-name"
                            label="Lastname"
                            fullWidth
                            defaultValue="Lincoin"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="email"
                            type="email"
                            label="Email"
                            fullWidth
                            defaultValue="shanelincoin@gmail.com"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="secondary-email"
                            type="email"
                            label="Secondary Email"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                          labelId="role-select-label"
                          id="role-select"
                          multiple
                          label="Role"
                          value={roleselect2}
                          onChange={handleRoleselect2Change}
                          input={<OutlinedInput label="Role" />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {roles.map((selectrole) => (
                            <MenuItem key={selectrole} value={selectrole}>
                              <Checkbox
                                checked={roleselect2.indexOf(selectrole) > -1}
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckedboxIcon />}
                              />
                              <ListItemText primary={selectrole} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                              labelId="status-label"
                              id="status"
                              label="Status"
                              value={statusselect2}
                              onChange={handleStatusselect2Change}
                            >
                              <MenuItem value={1}>Active</MenuItem>
                              <MenuItem value={2}>Inactive</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="educator-type-label">
                              Educator Type
                            </InputLabel>
                            <Select
                              labelId="educator-type-label"
                              id="educator-type"
                              multiple
                              label="Educator Type"
                              value={educatortype2}
                              onChange={handleEducatortype2Change}
                              input={<OutlinedInput label="Educator Type" />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={{
                                className: "educator-type-select",
                              }}
                            >
                              {educatorTypes.map((selecteducator) => (
                                <MenuItem
                                  key={selecteducator}
                                  value={selecteducator}
                                >
                                  <Checkbox
                                    checked={
                                      educatortype2.indexOf(selecteducator) > -1
                                    }
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckedboxIcon />}
                                  />
                                  <ListItemText primary={selecteducator} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <TextField
                        id="street-add"
                        label="Street Address"
                        fullWidth
                        defaultValue="13/15 Cambridge Street"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="suburb"
                            label="Suburb"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="postcode"
                            label="Postcode"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="state"
                            label="State"
                            defaultValue="NSW"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <TextField
                        id="street-add-2"
                        label="Street Address 2"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Grid
                        container
                        columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="city"
                            label="city"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="state"
                            label="State"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            id="zip"
                            label="Zip"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="service-label">Service</InputLabel>
                        <Select
                          labelId="service-label"
                          id="service"
                          label="Service"
                          value={serviceselect}
                          onChange={handleServiceselectChange}
                        >
                          <MenuItem value={1}>First Choice</MenuItem>
                          <MenuItem value={2}>Second Choice</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id="other-wrkspace"
                        label="Other Workplace"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id="buss-phone"
                        label="Daytime / Business Phone"
                        fullWidth
                        defaultValue="9120128009"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id="prsnl-phone"
                        label="After Hours / Personal Phone"
                        fullWidth
                        defaultValue="9120128009"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="availability"
                        label="Availability"
                        fullWidth
                        variant="outlined"
                        defaultValue="Friday Only"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="emergency-ctnt"
                        label="Emergency Contact"
                        fullWidth
                        variant="outlined"
                        defaultValue="Refer to Service"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextField
                        id="emergency-ctnt-rln"
                        label="Emergency Contact Relationship"
                        fullWidth
                        variant="outlined"
                        defaultValue="Refer to Service"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        id="emergency-ctnt-phone"
                        label="Emergency Contact Phone"
                        fullWidth
                        variant="outlined"
                        defaultValue="Refer to Service"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="skills"
                        className="skills-textarea"
                        label="Skills/ Experience/ Interests"
                        fullWidth
                        variant="outlined"
                        defaultValue="Anxiety , Depression, Personality"
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="lang-label">
                          Course Delivery Language
                        </InputLabel>
                        <Select
                          labelId="lang-label"
                          id="lang"
                          label="Course Delivery Language"
                          value={langselect2}
                          onChange={handleLangselect2Change}
                        >
                          <MenuItem value={1}>First Choice</MenuItem>
                          <MenuItem value={2}>Second Choice</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="profile-link-label">
                          Student Profile Link
                        </InputLabel>
                        <Select
                          labelId="profile-link-label"
                          id="profile-link"
                          label="Student Profile Link"
                          value={profilelink}
                          onChange={handleProfileLinkChange}
                        >
                          <MenuItem value={1}>Melissa Nakhle</MenuItem>
                          <MenuItem value={2}>Katherina Stufano</MenuItem>
                          <MenuItem value={3}>Rachael Rogan</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box className="btn-group">
                    <Button
                      variant="contained"
                      className="btn-save"
                      onClick={() => {
                        setEditeducatordetails(false);
                        setEducators(true);
                      }}
                    >
                      <img src={saveIcon} alt="save" />
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      className="btn-cancel"
                      onClick={() => {
                        setEditeducatordetails(false);
                        setEducators(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box className="course-details">
                    <Box>
                      <Typography variant="body1">Courses Delivered</Typography>
                      <Button
                        className="btn-course-large btn-course"
                        variant="outlined"
                        onClick={() => {
                          setEducatordetails(true);
                          setEducators(false);
                          setEditeducatordetails(false);
                        }}
                      >
                        <img
                          src={courseListIcon}
                          alt="Course List"
                          className="default"
                        />
                        <img
                          src={courseListLightIcon}
                          alt="Course List"
                          className="active"
                        />
                        <Typography variant="body2">7</Typography>
                      </Button>
                    </Box>
                    <Box>
                      <Typography variant="body1">
                        Courses Attended As Student
                      </Typography>
                      <Button
                        className="btn-course-large btn-course"
                        variant="outlined"
                        onClick={() => {
                          setStudenteducatordetails(true);
                          setEducators(false);
                          setEditeducatordetails(false);
                        }}
                      >
                        <img
                          src={courseListIcon}
                          alt="Course List"
                          className="default"
                        />
                        <img
                          src={courseListLightIcon}
                          alt="Course List"
                          className="active"
                        />
                        <Typography variant="body2">5</Typography>
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
      </main>

      {filterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="select">
              <InputLabel id="user-type-label">User Type</InputLabel>
              <Select
                labelId="user-type-label"
                id="user-type"
                label="User Type"
                value={usertype}
                onChange={handleusertypeChange}
              >
                <MenuItem value={1}>Peer Educator</MenuItem>
                <MenuItem value={2}>Clinical Educator</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                label="Status"
                value={status}
                onChange={handlestatusChange}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>Inactive</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                label="Role"
                value={role}
                onChange={handleroleChange}
              >
                <MenuItem value={1}>Educator</MenuItem>
                <MenuItem value={2}>Administrator</MenuItem>
                <MenuItem value={3}>Peer Learning</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}

      <Modal open={reminder} onClose={handleReminderClose}>
        <Box className="common-modal reminder-modal">
          <Box className="modal-header">
            <IconButton onClick={handleReminderClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <div className="reminder-icon">
              <img src={notificationCircle} alt="bell-icon" />
            </div>
            <Typography variant="body2" className="reminder-text">
              12 Courses Remaining
            </Typography>
            <Typography variant="body1" className="reminder-question">
              Do you want to send reminder to attend the remaining courses?{" "}
            </Typography>
            <Box className="modal-footer">
              <Button
                variant="contained"
                className="btn-yes"
                onClick={handleReminderClose}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                className="btn-no"
                onClick={handleReminderClose}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DesignerEducators;
