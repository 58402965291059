import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosError } from "axios";
import {
  IEnrollmentSummaryListPagePayloadValues,
  IStudentListPagePayloadValues,
} from "utils/interfaces/student";
import {
  confirmationPreferenceFailure,
  confirmationPreferenceSuccess,
  deleteStudentFailure,
  deleteStudentSuccess,
  editConfirmationPreferenceFailure,
  editConfirmationPreferenceSuccess,
  editStudentDetailFailure,
  editStudentDetailSuccess,
  editstudentNoteFailure,
  editstudentNoteSuccess,
  researchProfileDetailsFailure,
  researchProfileDetailsSuccess,
  studentDetailsFailure,
  studentDetailsSuccess,
  studentListFailure,
  studentListSuccess,
  enrollmentSummaryListFailure,
  enrollmentSummaryListSuccess,
  studnetNoteFailure,
  studnetNoteSuccess,
  editResearchProfileDetailSuccess,
  editResearchProfileDetailFailure,
  staffDetailsFailure,
  staffDetailsSuccess,
  mediaConsentSuccess,
  mediaConsentFailure,
  editMediaConsentFailure,
  editMediaConsentSuccess,
  enrollmentAndAttendanceHistoryListSuccess,
  enrollmentAndAttendanceHistoryListFailure,
  studnetAttendanceListFailure,
  studnetAttendanceListSuccess,
  getPendingEnrolmentListSuccess,
  getPendingEnrolmentListFailure,
  getStudentEnrolmentForConfirmationSuccess,
  getStudentEnrolmentForConfirmationFailure,
  approveEnrolmentByEmailSMSSuccess,
  approveEnrolmentByEmailSMSFailure,
  addNewEnrolmentFromStudentFailure,
  getCurrentCoursesForAddEnrolmentSuccess,
  getCurrentCoursesForAddEnrolmentFailure,
  addNewEnrolmentFromStudentSuccess,
  reSendConfirmationMailSuccess,
  reSendConfirmationMailFailure,
  studentEnrolmentWithdrawnSuccess,
  studentEnrolmentWithdrawnFailure,
  generateCertificateSuccess,
  generateCertificateFailure,
  studentProfileDetailsFailure,
  studentProfileDetailsSuccess,
  printEnrolmentSummaryFailure,
  printEnrolmentSummarySuccess,
} from "./action";
import {
  ConfirmationPreferenceResponse,
  DeleteStudentResponse,
  EnrollmentSummaryListResponse,
  EditConfirmationPreferenceResponse,
  EditStudentDetailsResponse,
  EditStudentNoteResponse,
  ResearchProfileDetailsResponse,
  StudentListResponse,
  StudentNoteResponse,
  StudnetDetailsResponse,
  EditResearchProfileDetailsResponse,
  StaffDetailsResponse,
  EditStaffDetailsResponse,
  MediaConsentResponse,
  EditMediaConsentResponse,
  EnrollmentAndAttendanceHistoryListResponse,
  StudentAttendanceListResponse,
  GetPendingEnrolmentListResponse,
  GetStudentEnrolmentForConfirmationResponse,
  ApproveEnrolmentByEmailSMSResponse,
  GetCurrentCoursesForAddEnrolmentResponse,
  AddNewEnrolmentFromStudentResponse,
  ReSendConfirmationMailResponse,
  StudentEnrolmentWithdrawnResponse,
  GenerateCertificateResponse,
  EditStudentProfileDetailsResponse,
  StudentProfileDetailsResponse,
  EditStudentProfileDetailsRequest,
  StudentProfileDetailsRequest,
  EnrollmentAndAttendanceHistoryListRequest,
  StudentListRequest,
  PrintEnrolmentSummaryRequest,
  PrintEnrolmentSummaryResponse,
} from "./types";
import studentService from "./service";
import studentEnrolmentService from "../studentEnrolment/service";
import {
  DELETE_STUDENT_REQUEST,
  EDIT_CONFIRMATION_PREFERENCE_REQUEST,
  EDIT_STUDENTNOTE_REQUEST,
  EDIT_STUDENT_DETAILS_REQUEST,
  GET_CONFIRMATION_PREFERENCE_REQUEST,
  GET_RESEARCH_PROFILE_DETAILS_REQUEST,
  GET_STUDENTNOTE_REQUEST,
  GET_STUDENT_DETAILS_REQUEST,
  STUDENT_LIST_REQUEST,
  ENROLLMENT_SUMMARY_LIST_REQUEST,
  EDIT_RESEARCH_PROFILE_DETAILS_REQUEST,
  GET_STAFF_DETAILS_REQUEST,
  EDIT_STAFF_DETAILS_REQUEST,
  GET_MEDIA_CONSENT_REQUEST,
  EDIT_MEDIA_CONSENT_REQUEST,
  ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_REQUEST,
  STUDENT_ATTENDANCE_LIST_REQUEST,
  GET_PENDING_ENROLLMENT_LIST_REQUEST,
  GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_REQUEST,
  APPROVE_ENROLMENT_BY_EMAIL_SMS_REQUEST,
  GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_REQUEST,
  ADD_NEW_ENROLMENT_FROM_STUDENT_REQUEST,
  RE_SEND_CONFIRMATION_MAIL_REQUEST,
  STUDENT_ENROLMENT_WITHDRAWN_REQUEST,
  GENERATE_CERTIFICATE_REQUEST,
  EDIT_STUDENT_PROFILE_DETAILS_REQUEST,
  GET_STUDENT_PROFILE_DETAILS_REQUEST,
  PRINT_ENROLMENT_SUMMARY_REQUEST,
} from "./actionTypes";

function* studentListSaga(action: StudentListRequest) {
  try {
    const payload: IStudentListPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      name: action.payload.values.name,
      emailAddress: action.payload.values.emailAddress,
      phoneNumber: action.payload.values.phoneNumber,
      dateOfBirth: action.payload.values.dateOfBirth,
      startDate: action.payload.values.startDate,
      endDate: action.payload.values.endDate,
    };

    const response: StudentListResponse = yield call(
      studentService.getStudentList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };
    yield put(
      studentListSuccess({
        studentList: response.item.records,
        studentListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      studentList: response.item.records,
      studentListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      studentListFailure({
        error: e.message,
      })
    );
  }
}

function* deleteStudentSaga(action: any) {
  try {
    const response: DeleteStudentResponse = yield call(
      studentService.deleteStudent,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      deleteStudentSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteStudentFailure({
        error: e.message,
      })
    );
  }
}
function* studentNoteSaga(action: any) {
  try {
    const response: StudentNoteResponse = yield call(
      studentService.getStudentNote,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      studnetNoteSuccess({
        studentNote: response.item,
      })
    );
    action.payload.callback({
      studentNote: response.item,
    });
  } catch (e: any) {
    yield put(
      studnetNoteFailure({
        error: e.message,
      })
    );
  }
}

function* editStudentNoteSaga(action: any) {
  try {
    const response: EditStudentNoteResponse = yield call(
      studentService.editStudentNote,
      {
        id: action.payload.values.id,
        studentNote: action.payload.values.studentNote,
      }
    );

    yield put(
      editstudentNoteSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editstudentNoteFailure({
        error: e.message,
      })
    );
  }
}

function* confirmationPreferenceSaga(action: any) {
  try {
    const response: ConfirmationPreferenceResponse = yield call(
      studentService.getConfirmationPreference,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      confirmationPreferenceSuccess({
        confirmationPreference: response.item,
      })
    );
    action.payload.callback({
      confirmationPreference: response.item,
    });
  } catch (e: any) {
    yield put(
      confirmationPreferenceFailure({
        error: e.message,
      })
    );
  }
}

function* editConfirmationPreferenceSaga(action: any) {
  try {
    const response: EditConfirmationPreferenceResponse = yield call(
      studentService.editConfirmationPreference,
      {
        id: action.payload.values.id,
        enrollmentConfirmationPreference:
          action.payload.values.enrollmentConfirmationPreference,
      }
    );
    yield put(
      editConfirmationPreferenceSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editConfirmationPreferenceFailure({
        error: e.message,
      })
    );
  }
}

function* studentDetailsSaga(action: any) {
  try {
    const response: StudnetDetailsResponse = yield call(
      studentService.getStudentDetails,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      studentDetailsSuccess({
        studentDetails: response.item,
      })
    );
    action.payload.callback({
      studentDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      studentDetailsFailure({
        error: e.message,
      })
    );
  }
}

function* editStudentDetailsSaga(action: any) {
  try {
    const response: EditStudentDetailsResponse = yield call(
      studentService.editStudentDetails,
      {
        id: action.payload.values.id,
        firstName: action.payload.values.firstName,
        lastName: action.payload.values.lastName,
        emailAddress: action.payload.values.emailAddress,
        dateOfBirth: action.payload.values.dateOfBirth,
        phoneNumber: action.payload.values.phoneNumber,
        streetAddress: action.payload.values.streetAddress,
        suburb: action.payload.values.suburb,
        state: action.payload.values.state,
        postCode: action.payload.values.postCode,
        permanentInactivation: action.payload.values.permanentInactivation,
        deceased: action.payload.values.deceased,
        mhEncounter: action.payload.values.mhEncounter,
        feeForService: action.payload.values.feeForService,
        studentEnrolment: {
          emergencyContactName:
            action.payload.values.studentEnrolment.emergencyContactName,
          emergencyContactRelationship:
            action.payload.values.studentEnrolment.emergencyContactRelationship,
          emergencyContactNumber:
            action.payload.values.studentEnrolment.emergencyContactNumber,
          information: action.payload.values.studentEnrolment.information,
          enrolmentType: action.payload.values.studentEnrolment.enrolmentType,
          enrollmentConfirmationPreference:
            action.payload.values.studentEnrolment
              .enrollmentConfirmationPreference,
          sector: action.payload.values.studentEnrolment.sector,
          mrn: action.payload.values.studentEnrolment.mrn,
        },
      }
    );

    yield put(
      editStudentDetailSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editStudentDetailFailure({
        error: e.message,
      })
    );
  }
}

function* researchProfileDetailsSaga(action: any) {
  try {
    const response: ResearchProfileDetailsResponse = yield call(
      studentService.getResearchProfileDetails,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      researchProfileDetailsSuccess({
        ResearchProfileDetails: response.item,
      })
    );
    action.payload.callback({
      ResearchProfileDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      researchProfileDetailsFailure({
        error: e.message,
      })
    );
  }
}

function* editReserachProfileDetailsSaga(action: any) {
  try {
    const response: EditResearchProfileDetailsResponse = yield call(
      studentService.editResearchProfileDetails,
      {
        id: action.payload.values.id,
        eligibilityCriteria: action.payload.values.eligibilityCriteria,
        hearAboutRC: action.payload.values.hearAboutRC,
        atsiIdentity: action.payload.values.atsiIdentity,
        disability: action.payload.values.disability,
        countryOfBirth: action.payload.values.countryOfBirth,
        languageAtHome: action.payload.values.languageAtHome,
        ethnicity: action.payload.values.ethnicity,
        genderIdentity: action.payload.values.genderIdentity,
        currentServiceProviders: action.payload.values.currentServiceProviders,
        researchCode: action.payload.values.researchCode,
        mrn: action.payload.values.mrn,
        activeCommunityEMR: action.payload.values.activeCommunityEMR,
      }
    );

    yield put(
      editResearchProfileDetailSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editResearchProfileDetailFailure({
        error: e.message,
      })
    );
  }
}

function* staffDetailsSaga(action: any) {
  try {
    const response: StaffDetailsResponse = yield call(
      studentService.getStaffDetails,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      staffDetailsSuccess({
        StaffDetails: response.item,
      })
    );
    action.payload.callback({
      StaffDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      staffDetailsFailure({
        error: e.message,
      })
    );
  }
}

function* editStaffDetailsSaga(action: any) {
  try {
    const response: EditStaffDetailsResponse = yield call(
      studentService.editStaffDetails,
      {
        studentId: action.payload.values.studentId,
        employmentStatus: action.payload.values.employmentStatus,
        currentRole: action.payload.values.currentRole,
        workEnvironment: action.payload.values.workEnvironment,
        site: action.payload.values.site,
        managerName: action.payload.values.managerName,
        managerEmail: action.payload.values.managerEmail,
        employmentNumber: action.payload.values.employmentNumber,
      }
    );

    yield put(
      editResearchProfileDetailSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editResearchProfileDetailFailure({
        error: e.message,
      })
    );
  }
}

function* mediaConsentSaga(action: any) {
  try {
    const response: MediaConsentResponse = yield call(
      studentService.getMediaConsent,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      mediaConsentSuccess({
        MediaConsent: response.item,
      })
    );
    action.payload.callback({
      MediaConsent: response.item,
    });
  } catch (e: any) {
    yield put(
      mediaConsentFailure({
        error: e.message,
      })
    );
  }
}

function* editMediaConsentSaga(action: any) {
  try {
    const response: EditMediaConsentResponse = yield call(
      studentService.editMediaConsent,
      {
        studentId: action.payload.values.studentId,
        isMediaConsent: action.payload.values.isMediaConsent,
        mediaConsentedTo: action.payload.values.mediaConsentedTo,
        consentDate: action.payload.values.consentDate,
        fileName: action.payload.values.fileName,
        document: action.payload.values.document,
      }
    );

    yield put(
      editMediaConsentSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editMediaConsentFailure({
        error: e.message,
      })
    );
  }
}

function* enrollmentSummaryListSaga(action: any) {
  try {
    const payload: any = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      id: action.payload.values.id,
    };

    if (action.payload.values.locations) {
      payload.locations = action.payload.values.locations;
    }
    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }
    if (action.payload.values.status !== null) {
      payload.status = action.payload.values.status;
    }

    const response: EnrollmentSummaryListResponse = yield call(
      studentService.getStudentEnrollmentSummary,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };
    yield put(
      enrollmentSummaryListSuccess({
        enrollmentSummaryList: response.item.records,
        enrollmentSummaryListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      enrollmentSummaryList: response.item.records,
      enrollmentSummaryListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      enrollmentSummaryListFailure({
        error: e.message,
      })
    );
  }
}

function* getPendingEnrolmentListSaga(action: any) {
  try {
    const response: GetPendingEnrolmentListResponse = yield call(
      studentService.getStudentPendingEnrolmentList,
      {
        id: action.payload.values.id,
      }
    );
    yield put(
      getPendingEnrolmentListSuccess({
        pendingCourseList: response.item,
      })
    );
    action.payload.callback({
      pendingCourseList: response.item,
    });
  } catch (e: any) {
    yield put(
      getPendingEnrolmentListFailure({
        error: e.message,
      })
    );
  }
}

function* enrollmentAndAttendanceHistoryListSaga(
  action: EnrollmentAndAttendanceHistoryListRequest
) {
  try {
    const response: EnrollmentAndAttendanceHistoryListResponse = yield call(
      studentService.getEnrolmentAndAttendanceHistoryList,
      {
        id: action.payload.values.id,
        search: action.payload.values.search,
        sortBy: action.payload.values.sortBy,
        sortColumn: action.payload.values.sortColumn,
        pageNo: action.payload.values.pageNo,
        pageSize: action.payload.values.pageSize,
      }
    );
    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };
    yield put(
      enrollmentAndAttendanceHistoryListSuccess({
        enrollmentAndAttendanceHistoryList: response.item.records,
        enrollmentAndAttendanceHistoryListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      enrollmentAndAttendanceHistoryList: response.item.records,
      enrollmentAndAttendanceHistoryListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      enrollmentAndAttendanceHistoryListFailure({
        error: e.message,
      })
    );
  }
}

function* studentAttendanceListSaga(action: any) {
  try {
    const payload: any = {
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      id: action.payload.values.id,
      name: action.payload.values.name,
    };

    const response: StudentAttendanceListResponse = yield call(
      studentService.getStudentAttendanceList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };
    yield put(
      studnetAttendanceListSuccess({
        studentAttendanceList: response.item.records,
        studentAttendanceListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      studentAttendanceList: response.item.records,
      studentAttendanceListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      studnetAttendanceListFailure({
        error: e.message,
      })
    );
  }
}

function* getStudentEnrolmentForConfirmationSaga(action: any) {
  try {
    const response: GetStudentEnrolmentForConfirmationResponse = yield call(
      studentService.getStudentEnrolmentForConfirmation,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      getStudentEnrolmentForConfirmationSuccess({
        enrolmentDataForConfirmation: response.item,
      })
    );
    action.payload.callback({
      enrolmentDataForConfirmation: response.item,
    });
  } catch (e: any) {
    yield put(
      getStudentEnrolmentForConfirmationFailure({
        error: e.message,
      })
    );
  }
}

function* approveEnrolmentByEmailSMSSaga(action: any) {
  try {
    const response: ApproveEnrolmentByEmailSMSResponse = yield call(
      studentService.approveEnrolmentByEmailSMS,
      {
        studentId: action.payload.values.studentId,
        sector: action.payload.values.sector,
        mrn: action.payload.values.mrn,
        researchCode: action.payload.values.researchCode,
        activeCommunityEMR: action.payload.values.activeCommunityEMR,
        studentCourseEnrolmentIds:
          action.payload.values.studentCourseEnrolmentIds,
        enrolmentConfirmationPreference:
          action.payload.values.enrolmentConfirmationPreference,
      }
    );

    yield put(
      approveEnrolmentByEmailSMSSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
        WaitListedCourseList: response.item,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
      WaitListedCourseList: response.item,
    });
  } catch (e: any) {
    yield put(
      approveEnrolmentByEmailSMSFailure({
        error: e.message,
      })
    );
  }
}

function* getCurrentCourseForAddEnrolmentSaga(action: any) {
  try {
    const response: GetCurrentCoursesForAddEnrolmentResponse = yield call(
      studentService.getCurrentCoursesForAddEnrolment,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      getCurrentCoursesForAddEnrolmentSuccess({
        currentCourses: response.item,
      })
    );
    action.payload.callback({
      currentCourses: response.item,
    });
  } catch (e: any) {
    yield put(
      getCurrentCoursesForAddEnrolmentFailure({
        error: e.message,
      })
    );
  }
}

function* addNewEnrolmentFromStudentSaga(action: any) {
  try {
    const response: AddNewEnrolmentFromStudentResponse = yield call(
      studentService.addNewEnrolmentFromStudent,
      {
        studentId: action.payload.values.studentId,
        preferredCourseDelivery: action.payload.values.preferredCourseDelivery,
      }
    );

    yield put(
      addNewEnrolmentFromStudentSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addNewEnrolmentFromStudentFailure({
        error: e.message,
      })
    );
  }
}

function* reSendConfirmationMailSaga(action: any) {
  try {
    const response: ReSendConfirmationMailResponse = yield call(
      studentService.reSendConfirmationMail,
      {
        studentCourseEnrolmentId:
          action.payload.values.studentCourseEnrolmentId,
        courseName: action.payload.values.courseName,
        isEmailConfirmation: action.payload.values.isEmailConfirmation,
      }
    );
    yield put(
      reSendConfirmationMailSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      reSendConfirmationMailFailure({
        error: e.message,
      })
    );
  }
}
function* cancelSessionDaySaga(action: any) {
  try {
    const response: StudentEnrolmentWithdrawnResponse = yield call(
      studentEnrolmentService.studentEnrolmentWithdrawn,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      studentEnrolmentWithdrawnSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      studentEnrolmentWithdrawnFailure({
        error: e.message,
      })
    );
  }
}

function* generateCertificateSaga(action: any) {
  try {
    const response: GenerateCertificateResponse = yield call(
      studentService.generateCertificate,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      generateCertificateSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      generateCertificateFailure({
        error: e.message,
      })
    );
  }
}

function* editStudentProfileDetailsSaga(
  action: EditStudentProfileDetailsRequest
) {
  try {
    const response: EditStudentProfileDetailsResponse = yield call(
      studentService.editStudentProfileDetails,
      {
        id: action.payload.values.id,
        firstName: action.payload.values.firstName,
        lastName: action.payload.values.lastName,
        emailAddress: action.payload.values.emailAddress,
        dateOfBirth: action.payload.values.dateOfBirth,
        phoneNumber: action.payload.values.phoneNumber,
        streetAddress: action.payload.values.streetAddress,
        suburb: action.payload.values.suburb,
        state: action.payload.values.state,
        postCode: action.payload.values.postCode,
        studentProfileEnrolment: {
          emergencyContactName:
            action.payload.values.studentProfileEnrolment.emergencyContactName,
          emergencyContactRelationship:
            action.payload.values.studentProfileEnrolment
              .emergencyContactRelationship,
          emergencyContactNumber:
            action.payload.values.studentProfileEnrolment
              .emergencyContactNumber,
          information:
            action.payload.values.studentProfileEnrolment.information,
          enrollmentConfirmationPreference:
            action.payload.values.studentProfileEnrolment
              .enrollmentConfirmationPreference,
        },
      }
    );

    yield put(
      editStudentDetailSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e) {
    yield put(
      editStudentDetailFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* studentProfileDetailsSaga(action: StudentProfileDetailsRequest) {
  try {
    const response: StudentProfileDetailsResponse = yield call(
      studentService.getStudentProfileDetails,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      studentProfileDetailsSuccess({
        studentDetails: response.item,
      })
    );
    action.payload.callback({
      studentDetails: response.item,
    });
  } catch (e) {
    yield put(
      studentProfileDetailsFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* printEnrolmentSummarySaga(action: PrintEnrolmentSummaryRequest) {
  try {
    const payload: IEnrollmentSummaryListPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      id: action.payload.values.id,
    };
    if (action.payload.values.locations) {
      payload.locations = action.payload.values.locations;
    }
    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }
    if (action.payload.values.status !== null) {
      payload.status = action.payload.values.status;
    }
    const response: PrintEnrolmentSummaryResponse = yield call(
      studentService.printEnrolmentSummary,
      payload
    );

    yield put(
      printEnrolmentSummarySuccess({
        item: response.item,
      })
    );
    action.payload.callback({
      item: response.item,
    });
  } catch (e) {
    yield put(
      printEnrolmentSummaryFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* studentSaga() {
  yield all([takeLatest(STUDENT_LIST_REQUEST, studentListSaga)]);
  yield all([takeLatest(DELETE_STUDENT_REQUEST, deleteStudentSaga)]);
  yield all([takeLatest(GET_STUDENTNOTE_REQUEST, studentNoteSaga)]);
  yield all([takeLatest(EDIT_STUDENTNOTE_REQUEST, editStudentNoteSaga)]);
  yield all([
    takeLatest(GET_CONFIRMATION_PREFERENCE_REQUEST, confirmationPreferenceSaga),
  ]);
  yield all([
    takeLatest(
      EDIT_CONFIRMATION_PREFERENCE_REQUEST,
      editConfirmationPreferenceSaga
    ),
  ]);
  yield all([takeLatest(GET_STUDENT_DETAILS_REQUEST, studentDetailsSaga)]);
  yield all([takeLatest(EDIT_STUDENT_DETAILS_REQUEST, editStudentDetailsSaga)]);
  yield all([
    takeLatest(
      GET_RESEARCH_PROFILE_DETAILS_REQUEST,
      researchProfileDetailsSaga
    ),
  ]);
  yield all([
    takeLatest(
      EDIT_RESEARCH_PROFILE_DETAILS_REQUEST,
      editReserachProfileDetailsSaga
    ),
  ]);
  yield all([takeLatest(GET_STAFF_DETAILS_REQUEST, staffDetailsSaga)]);
  yield all([takeLatest(EDIT_STAFF_DETAILS_REQUEST, editStaffDetailsSaga)]);
  yield all([takeLatest(GET_MEDIA_CONSENT_REQUEST, mediaConsentSaga)]);
  yield all([takeLatest(EDIT_MEDIA_CONSENT_REQUEST, editMediaConsentSaga)]);
  yield all([
    takeLatest(ENROLLMENT_SUMMARY_LIST_REQUEST, enrollmentSummaryListSaga),
  ]);
  yield all([
    takeLatest(
      ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_REQUEST,
      enrollmentAndAttendanceHistoryListSaga
    ),
  ]);
  yield all([
    takeLatest(STUDENT_ATTENDANCE_LIST_REQUEST, studentAttendanceListSaga),
  ]);
  yield all([
    takeLatest(
      GET_PENDING_ENROLLMENT_LIST_REQUEST,
      getPendingEnrolmentListSaga
    ),
  ]);
  yield all([
    takeLatest(
      GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_REQUEST,
      getStudentEnrolmentForConfirmationSaga
    ),
  ]);
  yield all([
    takeLatest(
      APPROVE_ENROLMENT_BY_EMAIL_SMS_REQUEST,
      approveEnrolmentByEmailSMSSaga
    ),
  ]);
  yield all([
    takeLatest(
      GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_REQUEST,
      getCurrentCourseForAddEnrolmentSaga
    ),
  ]);
  yield all([
    takeLatest(
      ADD_NEW_ENROLMENT_FROM_STUDENT_REQUEST,
      addNewEnrolmentFromStudentSaga
    ),
  ]);
  yield all([
    takeLatest(RE_SEND_CONFIRMATION_MAIL_REQUEST, reSendConfirmationMailSaga),
  ]);
  yield all([
    takeLatest(STUDENT_ENROLMENT_WITHDRAWN_REQUEST, cancelSessionDaySaga),
  ]);
  yield all([
    takeLatest(GENERATE_CERTIFICATE_REQUEST, generateCertificateSaga),
  ]);
  yield all([
    takeLatest(
      EDIT_STUDENT_PROFILE_DETAILS_REQUEST,
      editStudentProfileDetailsSaga
    ),
  ]);
  yield all([
    takeLatest(GET_STUDENT_PROFILE_DETAILS_REQUEST, studentProfileDetailsSaga),
  ]);
  yield all([
    takeLatest(PRINT_ENROLMENT_SUMMARY_REQUEST, printEnrolmentSummarySaga),
  ]);
}

export default studentSaga;
