export const COURSE_LIST_REQUEST = "COURSE_LIST_REQUEST";
export const COURSE_LIST_SUCCESS = "COURSE_LIST_SUCCESS";
export const COURSE_LIST_FAILURE = "COURSE_LIST_FAILURE";

export const ADD_COURSE_REQUEST = "ADD_COURSE_REQUEST";
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_FAILURE = "ADD_COURSE_FAILURE";

export const EDIT_COURSE_REQUEST = "EDIT_COURSE_REQUEST";
export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS";
export const EDIT_COURSE_FAILURE = "EDIT_COURSE_FAILURE";

export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";

export const COURSE_BY_ID_REQUEST = "COURSE_BY_ID_REQUEST";
export const COURSE_BY_ID_SUCCESS = "COURSE_BY_ID_SUCCESS";
export const COURSE_BY_ID_FAILURE = "COURSE_BY_ID_FAILURE";
