export const ROLE_LIST_REQUEST = "ROLE_LIST_REQUEST";
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS";
export const ROLE_LIST_FAILURE = "ROLE_LIST_FAILURE";

export const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILURE = "ADD_ROLE_FAILURE";

export const EDIT_ROLE_REQUEST = "EDIT_ROLE_REQUEST";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE_FAILURE = "EDIT_ROLE_FAILURE";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const ROLE_PERMISSIONS_REQUEST = "ROLE_PERMISSIONS_REQUEST";
export const ROLE_PERMISSIONS_SUCCESS = "ROLE_PERMISSIONS_SUCCESS";
export const ROLE_PERMISSIONS_FAILURE = "ROLE_PERMISSIONS_FAILURE";

export const MODULE_LIST_REQUEST = "MODULE_LIST_REQUEST";
export const MODULE_LIST_SUCCESS = "MODULE_LIST_SUCCESS";
export const MODULE_LIST_FAILURE = "MODULE_LIST_FAILURE";
