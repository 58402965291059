import { connect, MapDispatchToProps } from "react-redux";
import ResetPasswordComponent, {
  ResetPasswordProps,
} from "pages/ResetPassword";
import {
  resetPasswordRequest,
  validateResetPasswordUrlRequest,
} from "store/auth/actions";
import {
  IResetPasswordContainerDispatch,
  IResetPassword,
} from "utils/interfaces/login";

const mapDispatchToProps: MapDispatchToProps<
  IResetPasswordContainerDispatch,
  IResetPassword
> = {
  validateResetPasswordUrlRequest,
  resetPasswordRequest,
};

const connector = connect(null, mapDispatchToProps);

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  return <ResetPasswordComponent {...props} />;
};

export default connector(ResetPassword);
