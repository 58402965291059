import { successfullIcon } from "assets/images";
import { Box, Typography } from "@mui/material";

const FormSubmitSuccess = () => {
  return (
    <Box className="finished-container">
      <div className="icon">
        <img src={successfullIcon} alt="" />
      </div>
      <Typography variant="h2" className="greeting">
        Your enrolment request has been successfully submitted.
      </Typography>
      <Typography variant="h6" className="greeting-para">
        The Recovery & Wellbeing College will contact you soon with your
        enrolment confirmation
      </Typography>
      <Typography variant="h6" className="greeting-para">
        Should you have any questions, please contact the college during
        business hours 8.30am-4.30pm on 9113 2981
      </Typography>
    </Box>
  );
};

export default FormSubmitSuccess;
