import { Typography } from "@mui/material";
import React from "react";

const DesignerFooter: React.FC = () => {
  return (
    <footer>
      <Typography variant="body2">
        ©2023 South Eastern Sydney Recovery College. All rights reserved.
      </Typography>
    </footer>
  );
};

export default DesignerFooter;
