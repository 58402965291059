import { Box } from "@mui/system";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface IRejectConfirmationModalProps {
  isOpenRejectConfirmationModal: boolean;
  handleRejectConfirmationModalClose: any;
  handleYesClick: any;
}

const RejectConfirmationModal: React.FC<IRejectConfirmationModalProps> = ({
  isOpenRejectConfirmationModal,
  handleRejectConfirmationModalClose,
  handleYesClick,
}) => {
  return (
    <Modal
      open={isOpenRejectConfirmationModal}
      onClose={handleRejectConfirmationModalClose}
    >
      <Box className="common-modal rejection-modal delete-modal">
        <Box className="modal-header">
          <Typography variant="h2">Reject Confirmation</Typography>
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={CLOSE_TOOLTIP_TITLE}
            arrow
          >
            <IconButton onClick={handleRejectConfirmationModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="modal-body">
          <Typography variant="h5">
            Are you sure you want to reject student enrolment ?
          </Typography>
        </Box>
        <Box className="modal-footer">
          <Button
            variant="contained"
            className="btn-yes"
            onClick={handleYesClick}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            className="btn-no"
            onClick={handleRejectConfirmationModalClose}
          >
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RejectConfirmationModal;
