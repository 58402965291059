import { Box } from "@mui/system";
import {
  Button,
  IconButton,
  Modal,
  Typography,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Fade,
  Tooltip,
} from "@mui/material";
import { closeIcon, eyeClose, eyeOpen, saveIcon } from "assets/images";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { IChangePassword } from "utils/interfaces/change-password";
import { PasswordPattern } from "utils/helper";
import { connect, useDispatch } from "react-redux";
import { changePasswordRequest } from "store/profile/actions";
import { ChangePasswordSuccessPayload } from "store/profile/types";
import { toast } from "react-toastify";
import { AuthState } from "store/rootReducer";
import { getUserSelector } from "store/auth/selector";
import { ILoginResponse } from "utils/interfaces/login";
import { PASSWORD_CHANGED_SUCCESS_MSG } from "utils/constants/Messages";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface IChangePasswordModalProps {
  isOpenChangePasswordModal: boolean;
  handleChangePasswordModalClose: any;
}

export type ChangePasswordProps = {
  user: ILoginResponse;
} & IChangePasswordModalProps;

const ChangePasswordModal = ({ user, ...props }: ChangePasswordProps) => {
  const { isOpenChangePasswordModal, handleChangePasswordModalClose } = props;
  const {
    register,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<IChangePassword>();
  const dispatch = useDispatch();
  const password = useRef({});
  const currentPassword = useRef({});
  password.current = watch("newPassword", "");
  currentPassword.current = watch("currentPassword", "");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);
  const handleMouseDownCurrentPassword = (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
  };

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
  };

  const getCurrentPasswordError = (): string => {
    if (errors.currentPassword) {
      if (errors.currentPassword.type === "required") {
        return "Current password is required";
      }
    }

    return "";
  };

  const getNewPasswordError = (): string => {
    if (errors.newPassword) {
      if (errors.newPassword.type === "required") {
        return "New password is required";
      }
      if (errors.newPassword.type === "pattern") {
        return "New password must have at least 8 characters that include at least one uppercase character, one number, and  one special character.";
      }
      if (errors.newPassword.type === "validate") {
        return "New Password should be different than current";
      }
    }

    return "";
  };

  const onModalClose = () => {
    reset();
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    handleChangePasswordModalClose();
  };

  const onChangePasswordSuccess = async (
    response: ChangePasswordSuccessPayload
  ) => {
    if (response.success) {
      onModalClose();
      toast.success(PASSWORD_CHANGED_SUCCESS_MSG);
    }
  };

  const handleSaveClick = (formData: IChangePassword) => {
    const payload = {
      values: {
        userName: user?.emailAddress,
        oldPassword: formData.currentPassword,
        newPassword: formData.newPassword,
      },
      callback: onChangePasswordSuccess,
    };

    dispatch(changePasswordRequest(payload));
  };

  useEffect(() => {
    if (watch("currentPassword") && watch("newPassword")) {
      if (watch("currentPassword") !== watch("newPassword")) {
        clearErrors("newPassword");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("currentPassword")]);

  return (
    <>
      <Modal open={isOpenChangePasswordModal} onClose={onModalClose}>
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <Box className="common-modal reset-modal">
            <Box className="modal-header">
              <Typography variant="h4">Change Password</Typography>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={CLOSE_TOOLTIP_TITLE}
                arrow
              >
                <IconButton onClick={onModalClose}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="modal-body">
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  htmlFor="currentPassword"
                  error={!!errors.currentPassword}
                >
                  Current Password <span className="color-red">*</span>
                </InputLabel>
                <OutlinedInput
                  id="current-pwd"
                  className="with-icon"
                  type={showCurrentPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownCurrentPassword}
                        edge="end"
                        disableFocusRipple
                        disableRipple
                      >
                        {showCurrentPassword ? (
                          <img src={eyeOpen} alt="show" />
                        ) : (
                          <img src={eyeClose} alt="hide" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="CurrentPassword"
                  error={!!errors.currentPassword}
                  {...register("currentPassword", {
                    required: true,
                  })}
                />
                {!!errors.currentPassword && (
                  <FormHelperText error>
                    {getCurrentPasswordError()}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="newPassword" error={!!errors.newPassword}>
                  New Password <span className="color-red">*</span>
                </InputLabel>
                <OutlinedInput
                  id="newPassword"
                  className="with-icon"
                  type={showNewPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownNewPassword}
                        edge="end"
                        disableFocusRipple
                        disableRipple
                      >
                        {showNewPassword ? (
                          <img src={eyeOpen} alt="show" />
                        ) : (
                          <img src={eyeClose} alt="hide" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="NewPassword"
                  error={!!errors.newPassword}
                  {...register("newPassword", {
                    required: true,
                    pattern: PasswordPattern,
                    validate: (value) => value !== currentPassword.current,
                  })}
                />
                {!!errors.newPassword && (
                  <FormHelperText error>{getNewPasswordError()}</FormHelperText>
                )}
              </FormControl>
              <FormControl variant="outlined" fullWidth>
                <InputLabel
                  htmlFor="confirmPassword"
                  error={!!errors.confirmPassword}
                >
                  Confirm Password <span className="color-red">*</span>
                </InputLabel>
                <OutlinedInput
                  id="confirm-pwd"
                  className="with-icon"
                  type={showConfirmPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                        disableFocusRipple
                        disableRipple
                      >
                        {showConfirmPassword ? (
                          <img src={eyeOpen} alt="show" />
                        ) : (
                          <img src={eyeClose} alt="hide" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  error={!!errors.confirmPassword}
                  {...register("confirmPassword", {
                    required: "Confirm password is required",
                    validate: (value) =>
                      value === password.current || "Passwords do not match",
                  })}
                />
                {!!errors.confirmPassword && (
                  <FormHelperText error>
                    {errors.confirmPassword.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box className="modal-footer">
              <Button variant="contained" className="btn-save" type="submit">
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={onModalClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: AuthState) => {
  return {
    user: getUserSelector(state),
  };
};

export default connect(mapStateToProps)(ChangePasswordModal);
