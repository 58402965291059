import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_FAILURE,
  COURSE_LIST_SUCCESS,
  ADD_COURSE_REQUEST,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_FAILURE,
  EDIT_COURSE_REQUEST,
  EDIT_COURSE_SUCCESS,
  EDIT_COURSE_FAILURE,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILURE,
  COURSE_BY_ID_REQUEST,
  COURSE_BY_ID_SUCCESS,
  COURSE_BY_ID_FAILURE,
} from "./actionTypes";
import {
  CourseListPayload,
  CourseListRequest,
  CourseListSuccess,
  CourseListSuccessPayload,
  CourseListFailure,
  CourseListFailurePayload,
  DeleteCoursePayload,
  DeleteCourseRequest,
  DeleteCourseSuccessPayload,
  DeleteCourseSuccess,
  DeleteCourseFailurePayload,
  DeleteCourseFailure,
  AddEditCoursePayload,
  AddCourseRequest,
  AddEditCourseSuccessPayload,
  AddCourseSuccess,
  AddEditCourseFailurePayload,
  AddCourseFailure,
  EditCourseSuccess,
  EditCourseRequest,
  EditCourseFailure,
  CourseByIdPayload,
  CourseByIdRequest,
  CourseByIdSuccessPayload,
  CourseByIdSuccess,
  CourseByIdFailurePayload,
  CourseByIdFailure,
} from "./types";

export const courseListRequest = (
  payload: CourseListPayload
): CourseListRequest => ({
  type: COURSE_LIST_REQUEST,
  payload,
});

export const courseListSuccess = (
  payload: CourseListSuccessPayload
): CourseListSuccess => ({
  type: COURSE_LIST_SUCCESS,
  payload,
});

export const courseListFailure = (
  payload: CourseListFailurePayload
): CourseListFailure => ({
  type: COURSE_LIST_FAILURE,
  payload,
});

export const addCourseRequest = (
  payload: AddEditCoursePayload
): AddCourseRequest => ({
  type: ADD_COURSE_REQUEST,
  payload,
});

export const addCourseSuccess = (
  payload: AddEditCourseSuccessPayload
): AddCourseSuccess => ({
  type: ADD_COURSE_SUCCESS,
  payload,
});

export const addCourseFailure = (
  payload: AddEditCourseFailurePayload
): AddCourseFailure => ({
  type: ADD_COURSE_FAILURE,
  payload,
});

export const editCourseRequest = (
  payload: AddEditCoursePayload
): EditCourseRequest => ({
  type: EDIT_COURSE_REQUEST,
  payload,
});

export const editCourseSuccess = (
  payload: AddEditCourseSuccessPayload
): EditCourseSuccess => ({
  type: EDIT_COURSE_SUCCESS,
  payload,
});

export const editCourseFailure = (
  payload: AddEditCourseFailurePayload
): EditCourseFailure => ({
  type: EDIT_COURSE_FAILURE,
  payload,
});

export const deleteCourseRequest = (
  payload: DeleteCoursePayload
): DeleteCourseRequest => ({
  type: DELETE_COURSE_REQUEST,
  payload,
});

export const deleteCourseSuccess = (
  payload: DeleteCourseSuccessPayload
): DeleteCourseSuccess => ({
  type: DELETE_COURSE_SUCCESS,
  payload,
});

export const deleteCourseFailure = (
  payload: DeleteCourseFailurePayload
): DeleteCourseFailure => ({
  type: DELETE_COURSE_FAILURE,
  payload,
});

export const courseByIdRequest = (
  payload: CourseByIdPayload
): CourseByIdRequest => ({
  type: COURSE_BY_ID_REQUEST,
  payload,
});

export const courseByIdSuccess = (
  payload: CourseByIdSuccessPayload
): CourseByIdSuccess => ({
  type: COURSE_BY_ID_SUCCESS,
  payload,
});

export const courseByIdFailure = (
  payload: CourseByIdFailurePayload
): CourseByIdFailure => ({
  type: COURSE_BY_ID_FAILURE,
  payload,
});
