import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosError } from "axios";
import { ITermPagePayloadValues } from "utils/interfaces/term";
import {
  addTermFailure,
  addTermSuccess,
  deleteTermFailure,
  deleteTermSuccess,
  editTermFailure,
  editTermSuccess,
  termByIdFailure,
  termByIdSuccess,
  termListFailure,
  termListSuccess,
} from "./actions";
import {
  TERM_LIST_REQUEST,
  ADD_TERM_REQUEST,
  EDIT_TERM_REQUEST,
  DELETE_TERM_REQUEST,
  TERM_BY_ID_REQUEST,
} from "./actionTypes";
import termService from "./service";
import {
  AddEditTermResponse,
  AddTermRequest,
  DeleteTermResponse,
  TermByIdResponse,
  TermListRequest,
  TermListResponse,
} from "./types";

function* termListSaga(action: TermListRequest) {
  try {
    const payload: ITermPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      termName: action.payload.values.termName,
    };
    if (action.payload.values.term) {
      payload.term = action.payload.values.term;
    }
    if (action.payload.values.year) {
      payload.year = action.payload.values.year;
    }
    if (action.payload.values.status) {
      payload.status = action.payload.values.status;
    }
    if (action.payload.values.startDate) {
      payload.startDate = action.payload.values.startDate;
    }
    if (action.payload.values.endDate) {
      payload.endDate = action.payload.values.endDate;
    }
    if (action.payload.values.isList) {
      payload.isList = action.payload.values.isList;
    }
    const response: TermListResponse = yield call(
      termService.getTermList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      termListSuccess({
        termList: response.item.records,
        termListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      termList: response.item.records,
      termListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      termListFailure({
        error: e.message,
      })
    );
  }
}

function* addTermSaga(action: AddTermRequest) {
  try {
    const response: AddEditTermResponse = yield call(termService.addTerm, {
      termName: action.payload.values.termName,
      term: action.payload.values.term,
      year: action.payload.values.year,
      startDate: action.payload.values.startDate,
      endDate: action.payload.values.endDate,
      status: action.payload.values.status,
    });

    yield put(
      addTermSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addTermFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* editTermSaga(action: any) {
  try {
    const response: AddEditTermResponse = yield call(termService.editTerm, {
      id: action.payload.values.id,
      termName: action.payload.values.termName,
      term: action.payload.values.term,
      year: action.payload.values.year,
      startDate: action.payload.values.startDate,
      endDate: action.payload.values.endDate,
      status: action.payload.values.status,
    });

    yield put(
      editTermSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e) {
    yield put(
      editTermFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* deleteTermSaga(action: any) {
  try {
    const response: DeleteTermResponse = yield call(termService.deleteTerm, {
      id: action.payload.values.id,
    });

    yield put(
      deleteTermSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteTermFailure({
        error: e.message,
      })
    );
  }
}

function* termByIdSaga(action: any) {
  try {
    const response: TermByIdResponse = yield call(termService.getTermById, {
      id: action.payload.values.id,
    });

    yield put(
      termByIdSuccess({
        termDetails: response.item,
      })
    );
    action.payload.callback({
      termDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      termByIdFailure({
        error: e.message,
      })
    );
  }
}

function* termSaga() {
  yield all([takeLatest(TERM_LIST_REQUEST, termListSaga)]);
  yield all([takeLatest(ADD_TERM_REQUEST, addTermSaga)]);
  yield all([takeLatest(EDIT_TERM_REQUEST, editTermSaga)]);
  yield all([takeLatest(DELETE_TERM_REQUEST, deleteTermSaga)]);
  yield all([takeLatest(TERM_BY_ID_REQUEST, termByIdSaga)]);
}

export default termSaga;
