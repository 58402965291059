import { connect, MapDispatchToProps } from "react-redux";
import SCODetailsComponent, { SCODetailsProps } from "pages/SCO/SCODetails";
import {
  IScoDetailsContainerDispatch,
  IScoDetails,
} from "utils/interfaces/scheduled-offering";
import {
  addScoEducatorRequest,
  addSessionDayRequest,
  cancelSessionDayRequest,
  deleteDocumentRequest,
  deleteScoEducatorRequest,
  deleteSessionDayRequest,
  downloadDocumentRequest,
  editScheduledOfferingRequest,
  editSessionDayRequest,
  enroledStudentListRequest,
  enrolmentCountRequest,
  fillGenerateCertificateRequest,
  fillStudentAttendanceRequest,
  getScheduledOfferingByIdRequest,
  SCOGenerateCertificateListRequest,
  SCOStudentAttendanceListRequest,
  SCOStudentWhoAttendedListRequest,
  sessionListByScoIdRequest,
  uploadDocumentRequest,
  printCourseEnrolmentsRequest,
  getEducatorReflectionFormRequest,
} from "store/scheduledOffering/actions";
import {
  changeEnrolmentStatusRequest,
  enrolmentOptionListRequest,
} from "store/studentEnrolment/actions";
import { downloadCertificateRequest } from "store/studentDashboard/actions";

const mapDispatchToProps: MapDispatchToProps<
  IScoDetailsContainerDispatch,
  IScoDetails
> = {
  getScheduledOfferingByIdRequest,
  sessionListByScoIdRequest,
  downloadDocumentRequest,
  uploadDocumentRequest,
  deleteDocumentRequest,
  editScheduledOfferingRequest,
  addSessionDayRequest,
  editSessionDayRequest,
  deleteSessionDayRequest,
  cancelSessionDayRequest,
  addScoEducatorRequest,
  deleteScoEducatorRequest,
  enroledStudentListRequest,
  enrolmentOptionListRequest,
  SCOStudentAttendanceListRequest,
  fillStudentAttendanceRequest,
  SCOStudentWhoAttendedListRequest,
  enrolmentCountRequest,
  fillGenerateCertificateRequest,
  SCOGenerateCertificateListRequest,
  changeEnrolmentStatusRequest,
  printCourseEnrolmentsRequest,
  downloadCertificateRequest,
  getEducatorReflectionFormRequest,
};

const connector = connect(null, mapDispatchToProps);

const SCODetails: React.FC<SCODetailsProps> = (props) => {
  return <SCODetailsComponent {...props} />;
};

export default connector(SCODetails);
