import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  courseListSecondaryIcon,
  filterLightIcon,
  filterPrimaryIcon,
} from "assets/images";
import React, { useEffect, useState } from "react";
import projectTheme from "app.theme";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  BLENDED_FACE_TO_FACE_ONLINE,
  DEFAULT_PAGE_NO,
  MAX_INT_VALUE,
  VIEW_TOOLTIP_TITLE,
} from "utils/constants/constant";
import {
  ICurrentCourse,
  ICurrentCourseFilter,
  ICurrentCoursesDetails,
  IEducatorHomeContainerDispatch,
  IEducatorHomeContainerState,
  IEnhancedTableProps,
} from "utils/interfaces/educator-home";
import { toast } from "react-toastify";
import {
  getPagePayloadValuesWithId,
  getPagePermissions,
  hideLoaderForBatchApiCall,
  showLoaderForBatchApiCall,
} from "utils/helper";
import { Order, OrderByOptions } from "utils/enums/sorting";
import { IRoleModulePermission } from "store/role/types";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { CurrentCourseListSuccessPayload } from "store/educatorHome/types";
import NoRecords from "components/NoRecords";
import { AppRoutings } from "utils/enums/app-routings";
import { SELECT_MIN_ONE_FIELD } from "utils/constants/Messages";
import { AppPages } from "utils/enums/app-pages";
import OverlayBox from "components/OverlayBox";
import CurrentCoursesFilter from "./CurrentCoursesFilter";
import PastCoursesList from "./PastCourses";
import MissingAttendanceCourseList from "./MissingAttendanceCoures";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof ICurrentCoursesDetails) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type EducatorsHomeProps = IEducatorHomeContainerState &
  IEducatorHomeContainerDispatch;

const EducatorsHome: React.FC<EducatorsHomeProps> = (props) => {
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const [currentCoursesList, setCurrentCoursesList] =
    useState<ICurrentCourse[]>();
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] =
    useState<keyof ICurrentCoursesDetails>("course");
  const [filterBox, setFilterBox] = useState(false);
  const [filteredData, setFilteredData] = useState<ICurrentCourseFilter>();
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();

  const handleRequestSort = React.useCallback(
    (
      event: React.MouseEvent<unknown>,
      newOrderBy: keyof ICurrentCoursesDetails
    ) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onCurrentCoursesListSuccess = (
    response: CurrentCourseListSuccessPayload
  ) => {
    setCurrentCoursesList(response.currentCourseList);
    hideLoaderForBatchApiCall();
  };

  const getCurrentCoursesList = async (filterData?: ICurrentCourseFilter) => {
    const { currentCourseListRequest } = props;

    if (currentCourseListRequest) {
      showLoaderForBatchApiCall();

      const values: any = getPagePayloadValuesWithId(
        user?.id,
        orderBy,
        order,
        pageNo,
        MAX_INT_VALUE
      );

      if (filterData?.course) {
        values.course = filterData?.course;
      }
      if (filterData?.termId) {
        values.termId = filterData?.termId;
      }

      if (filterData) {
        setFilteredData(filterData);
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onCurrentCoursesListSuccess,
      };

      currentCourseListRequest(payload);
    }
  };

  const handleFilterApplyClick = (formData: ICurrentCourseFilter) => {
    if (!(formData.course || formData.termId)) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      if (pageNo === DEFAULT_PAGE_NO) {
        getCurrentCoursesList(formData);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      document.body.classList.toggle("filter-open");
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.EducatorHome
    );
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData) {
        getCurrentCoursesList(filteredData);
      } else {
        getCurrentCoursesList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, order, orderBy]);

  return (
    <div>
      <>
        {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Educator Home
          </Typography>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Card>
              <Box className="table-card-header">
                <Box
                  sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h3">My Current Courses</Typography>
                  <Button
                    variant="outlined"
                    className="btn-filter"
                    onClick={() => {
                      setFilterBox(true);
                      document.body.classList.toggle("filter-open");
                    }}
                  >
                    <img
                      src={filterPrimaryIcon}
                      alt="filter"
                      className="default"
                    />
                    <img
                      src={filterLightIcon}
                      alt="filter"
                      className="active"
                    />
                    Filter
                  </Button>
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "course")}
                        fieldName="Course"
                        keyName="course"
                        sxStyle={{
                          width: "302px",
                          minWidth: "302px",
                        }}
                      />
                      <TableCell
                        align="left"
                        sx={{ width: "100px", minWidth: "80px" }}
                      >
                        Term
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "105px", minWidth: "105px" }}
                      >
                        No. Enrolled
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ maxWidth: "382px", width: "382px" }}
                      >
                        Location
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ width: "170px", minWidth: "170px" }}
                      >
                        <Typography variant="body2">
                          Date & Time of First Session
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: "88px",
                          [projectTheme.breakpoints.down("sm")]: {
                            width: "80px",
                          },
                        }}
                      >
                        Details
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentCoursesList?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="body2"
                            className="sco-list-word-wrap"
                          >
                            {row.courseName}
                          </Typography>
                        </TableCell>
                        <TableCell>{row.term}</TableCell>
                        <TableCell align="center">{row.noOfEnrolled}</TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            className="location sco-list-word-wrap"
                          >
                            {row.location}
                            {row.isBlended && BLENDED_FACE_TO_FACE_ONLINE}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {`${row.startDate} ${row.startTime} to ${row.endTime}`}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <div className="table-actions">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={VIEW_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  history.push({
                                    pathname: AppRoutings.EducatorsHomeDetails,
                                    state: { scoId: row.id },
                                  })
                                }
                                disabled={!pagePermission?.edit}
                                className={
                                  !pagePermission?.edit ? "disabled-icon" : ""
                                }
                              >
                                <img src={courseListSecondaryIcon} alt="list" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {(!currentCoursesList || currentCoursesList.length === 0) && (
                <NoRecords />
              )}
            </Card>
          </Grid>

          <PastCoursesList
            {...props}
            userId={user?.id}
            pagePermission={pagePermission}
          />

          <MissingAttendanceCourseList
            {...props}
            userId={user?.id}
            pagePermission={pagePermission}
          />
        </Grid>
      </>

      {filterBox && (
        <CurrentCoursesFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          getCurrentCoursesList={getCurrentCoursesList}
        />
      )}
    </div>
  );
};

export default EducatorsHome;
