import { connect, MapDispatchToProps } from "react-redux";
import StudentEnrolmentsComponent, {
  StudentEnrolmentsProps,
} from "pages/StudentEnrolments";
import {
  approveEnrolmentRequest,
  enrolmentOptionListRequest,
  rejectEnrolmentRequest,
  studentEnrolmentDetailRequest,
  studentEnrolmentListRequest,
} from "store/studentEnrolment/actions";
import {
  IStudentEnrolmentsContainerDispatch,
  IStudentEnrolments,
} from "utils/interfaces/student-enrolment";

const mapDispatchToProps: MapDispatchToProps<
  IStudentEnrolmentsContainerDispatch,
  IStudentEnrolments
> = {
  enrolmentOptionListRequest,
  studentEnrolmentListRequest,
  studentEnrolmentDetailRequest,
  rejectEnrolmentRequest,
  approveEnrolmentRequest,
};

const connector = connect(null, mapDispatchToProps);

const StudentEnrolments: React.FC<StudentEnrolmentsProps> = (props) => {
  return <StudentEnrolmentsComponent {...props} />;
};

export default connector(StudentEnrolments);
