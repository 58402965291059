import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  ResetPasswordSuccessPayload,
  ValidateResetPasswordUrlSuccessPayload,
} from "store/auth/types";
import { PasswordPattern, showLoader } from "utils/helper";
import {
  IResetPassword,
  IResetPasswordContainerDispatch,
  IResetPasswordContainerState,
} from "utils/interfaces/login";
import { useHistory } from "react-router-dom";
import { eyeClose, eyeOpen, loginLogo } from "assets/images";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { AppRoutings } from "utils/enums/app-routings";
import {
  INVALID_URL_OR_EXPIRED,
  PASSWORD_RESET_SUCCESS_MSG,
} from "utils/constants/Messages";

export type ResetPasswordProps = IResetPasswordContainerState &
  IResetPasswordContainerDispatch;

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IResetPassword>();

  const history = useHistory();
  const password = React.useRef({});
  password.current = watch("password", "");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
  };

  const onValidateResetPasswordUrlSuccess = (
    response: ValidateResetPasswordUrlSuccessPayload
  ) => {
    if (response.success) {
      setDisableForm(false);
    } else {
      toast.error(INVALID_URL_OR_EXPIRED);
    }
  };

  const validateUrl = () => {
    const { validateResetPasswordUrlRequest } = props;

    if (validateResetPasswordUrlRequest) {
      showLoader();
      const payload = {
        values: {
          url: window.location.href,
        },
        callback: onValidateResetPasswordUrlSuccess,
      };

      validateResetPasswordUrlRequest(payload);
    }
  };

  useEffect(() => {
    if (disableForm) {
      validateUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableForm]);

  const getPasswordError = (): string => {
    if (errors.password) {
      if (errors.password.type === "required") {
        return "Password is required";
      }
      if (errors.password.type === "pattern") {
        return "Password must have at least 8 characters that include at least one uppercase character, one number, and  one special character.";
      }
    }

    return "";
  };

  const onResetPasswordSuccess = (response: ResetPasswordSuccessPayload) => {
    if (response.success) {
      history.push(AppRoutings.LogIn);
      toast.success(PASSWORD_RESET_SUCCESS_MSG);
    }
  };

  const onSubmit = async (data: IResetPassword) => {
    const { resetPasswordRequest } = props;

    if (resetPasswordRequest) {
      showLoader();
      const payload = {
        values: {
          url: window.location.href,
          password: data.password,
        },
        callback: onResetPasswordSuccess,
      };

      resetPasswordRequest(payload);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="login-wrapper">
        <Grid container className="login-container">
          <Grid xs={12} sm="auto">
            <a
              href="/"
              title="South Eastern Sydney Recovery and Wellbeing College"
              className="login-logo"
            >
              <img
                src={loginLogo}
                alt="South Eastern Sydney Recovery and Wellbeing College"
              />
            </a>
          </Grid>
          <Grid xs={12} sm="auto">
            <Card className="login-card">
              <CardContent>
                <Typography variant="h2" className="card-heading">
                  Reset Password
                </Typography>
                <div className="login-content-form">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="newPassword" error={!!errors.password}>
                      New Password <span className="color-red">*</span>
                    </InputLabel>
                    <OutlinedInput
                      id="newPassword"
                      className="with-icon"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            disableFocusRipple
                            disableRipple
                            disabled={disableForm}
                          >
                            {showPassword ? (
                              <img src={eyeOpen} alt="show" />
                            ) : (
                              <img src={eyeClose} alt="hide" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      disabled={disableForm}
                      error={!!errors.password}
                      {...register("password", {
                        required: true,
                        pattern: PasswordPattern,
                      })}
                    />
                    {!!errors.password && (
                      <FormHelperText error>
                        {getPasswordError()}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel
                      htmlFor="confirmPassword"
                      error={!!errors.confirmPassword}
                    >
                      Confirm Password <span className="color-red">*</span>
                    </InputLabel>
                    <OutlinedInput
                      id="confirmPassword"
                      className="with-icon"
                      type={showConfirmPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                            disableFocusRipple
                            disableRipple
                            disabled={disableForm}
                          >
                            {showConfirmPassword ? (
                              <img src={eyeOpen} alt="show" />
                            ) : (
                              <img src={eyeClose} alt="hide" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      disabled={disableForm}
                      error={!!errors.confirmPassword}
                      {...register("confirmPassword", {
                        required: "Confirm password is required",
                        validate: (value) =>
                          value === password.current ||
                          "Passwords do not match",
                      })}
                    />
                    {!!errors.confirmPassword && (
                      <FormHelperText error>
                        {errors.confirmPassword.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  title="Submit"
                  type="submit"
                  className="login-btn"
                  disableElevation
                  disabled={disableForm}
                >
                  Submit
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default ResetPassword;
