import { connect, MapDispatchToProps } from "react-redux";
import LocationComponent, { LocationProps } from "pages/Location";
import {
  addLocationRequest,
  deleteLocationRequest,
  editLocationRequest,
  locationListRequest,
} from "store/location/actions";
import {
  ILocationContainerDispatch,
  ILocation,
} from "utils/interfaces/location";

const mapDispatchToProps: MapDispatchToProps<
  ILocationContainerDispatch,
  ILocation
> = {
  locationListRequest,
  addLocationRequest,
  editLocationRequest,
  deleteLocationRequest,
};

const connector = connect(null, mapDispatchToProps);

const Location: React.FC<LocationProps> = (props) => {
  return <LocationComponent {...props} />;
};

export default connector(Location);
