import { Box } from "@mui/system";
import {
  Button,
  Card,
  Grid,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  ICoursesDelivered,
  IEducatorListMeta,
  IEducatorsInfo,
  IEnhancedTableProps,
} from "utils/interfaces/educator";
import {
  convertToDateTimeWithFormat,
  getPagePayloadValuesWithId,
  getPaginationDetailText,
  showLoader,
} from "utils/helper";
import { CoursesDeliveredListSuccessPayload } from "store/educator/types";
import { Order, OrderByOptions } from "utils/enums/sorting";
import { coursesDeliveredListRequest } from "store/educator/actions";
import {
  BLENDED_FACE_TO_FACE_ONLINE,
  DATE_VIEW_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  MAX_INT_VALUE,
  PAGE_SIZES,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import NoRecords from "components/NoRecords";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { ExportToCsv } from "export-to-csv";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IEducatorsInfo) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

const CoursesDelivered: React.FC = () => {
  const history = useHistory();
  const location = useLocation<{ educatorData: any }>();
  const dispatch = useDispatch();
  const [coursesDeliveredList, setCoursesDeliveredList] =
    useState<ICoursesDelivered[]>();
  const [coursesDeliveredListMeta, setCoursesDeliveredListMeta] =
    useState<IEducatorListMeta>();
  const [page, setPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IEducatorsInfo>("course");
  const { educatorData } = location?.state;

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IEducatorsInfo) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onCoursesDeliveredListSuccess = (
    response: CoursesDeliveredListSuccessPayload
  ) => {
    setCoursesDeliveredList(response.coursesDeliveredList);
    setCoursesDeliveredListMeta(response.coursesDeliveredListPagination);
  };

  const getCoursesDeliveredList = () => {
    showLoader();

    const payload = {
      values: getPagePayloadValuesWithId(
        educatorData?.id,
        orderBy,
        order,
        pageNo,
        page
      ),
      callback: onCoursesDeliveredListSuccess,
    };

    dispatch(coursesDeliveredListRequest(payload));
  };
  const csvFileHeaders = [
    "Course Session Name",
    "Course",
    "Term",
    "No. Enrolled",
    "Location",
    "Date & Time of First Session",
  ];

  const getCourseDeliveredData = (list: ICoursesDelivered[]) => {
    const data = list?.map((course: ICoursesDelivered) => {
      return {
        "Course Session Name": course.courseOfferingFullName,
        Course: `${course.courseNo}-${course.courseName}`,
        Term: course.term,
        "No. Enrolled": course.noOfEnrolled,
        Location: `${course.location}${
          course.isBlended ? BLENDED_FACE_TO_FACE_ONLINE : ""
        }`,
        "Date & Time of First Session": `${convertToDateTimeWithFormat(
          course.startDate,
          DATE_VIEW_FORMAT
        )} ${convertToDateTimeWithFormat(
          course.startTime,
          TIME_VIEW_FORMAT
        )} To ${convertToDateTimeWithFormat(course.endTime, TIME_VIEW_FORMAT)}`,
      };
    });

    return data;
  };

  const onGetExportCourseDeliveredSuccess = (
    response: CoursesDeliveredListSuccessPayload
  ) => {
    const modifiedCourseDeliveredData = getCourseDeliveredData(
      response.coursesDeliveredList
    );
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeaders,
      filename: "Courses Delivered",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedCourseDeliveredData);
  };

  const getExportCourseDelivered = () => {
    showLoader();

    const payload = {
      values: getPagePayloadValuesWithId(
        educatorData?.id,
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      ),
      callback: onGetExportCourseDeliveredSuccess,
    };

    dispatch(coursesDeliveredListRequest(payload));
  };

  useEffect(() => {
    if (location?.state?.educatorData) {
      getCoursesDeliveredList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.educatorData, page, pageNo, order, orderBy]);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Educator Details
        </Typography>
        <Box className="std-detail-btn-group">
          <Button
            variant="outlined"
            className="btn-back"
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            className="btn-enrol-confirm"
            onClick={getExportCourseDelivered}
          >
            Export
          </Button>
        </Box>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Card>
            <Box className="table-card-header">
              <Typography variant="h3">Courses Delivered</Typography>
              <Box>
                <Typography variant="h5">
                  Name: <span className="name-add">{educatorData?.name}</span>
                </Typography>
                <Typography variant="h5">
                  Email:{" "}
                  <span className="mail-add">{educatorData?.emailAddress}</span>
                </Typography>
              </Box>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "course")}
                      fieldName="Course Session Name"
                      keyName="course"
                      sxStyle={{
                        maxWidth: "460px",
                        width: "460px",
                        minWidth: "460px",
                      }}
                    />
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "courseNo")}
                      fieldName="Course"
                      keyName="courseNo"
                      sxStyle={{
                        width: "136px",
                        minWidth: "136px",
                      }}
                    />
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "term")}
                      fieldName="Term"
                      keyName="term"
                      sxStyle={{
                        width: "120px",
                        minWidth: "120px",
                      }}
                    />
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "noEnrolled")}
                      fieldName="No. Enrolled"
                      keyName="noEnrolled"
                      sxStyle={{
                        width: "115px",
                        minWidth: "115px",
                        textAlign: "center",
                      }}
                    />
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "location")}
                      fieldName="Location"
                      keyName="location"
                      sxStyle={{
                        width: "163px",
                        minWidth: "163px",
                      }}
                    />
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(e) => handleRequestSort(e, "startDate")}
                      fieldName={
                        <>
                          <Typography variant="body2" className="first-session">
                            Date & Time of First Session
                          </Typography>
                        </>
                      }
                      keyName="startDate"
                      sxStyle={{
                        width: "240px",
                        minWidth: "240px",
                      }}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coursesDeliveredList?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.courseOfferingFullName}
                      </TableCell>
                      <TableCell>
                        {row.courseNo}-{row.courseName}
                      </TableCell>
                      <TableCell>{row.term}</TableCell>
                      <TableCell align="center"> {row.noOfEnrolled}</TableCell>
                      <TableCell>
                        {row.location}
                        {row.isBlended && BLENDED_FACE_TO_FACE_ONLINE}
                      </TableCell>
                      <TableCell>
                        {convertToDateTimeWithFormat(
                          row.startDate,
                          DATE_VIEW_FORMAT
                        )}{" "}
                        {convertToDateTimeWithFormat(
                          row.startTime,
                          TIME_VIEW_FORMAT
                        )}{" "}
                        {"To"}{" "}
                        {convertToDateTimeWithFormat(
                          row.endTime,
                          TIME_VIEW_FORMAT
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {coursesDeliveredList && coursesDeliveredList.length > 0 ? (
              <Box className="custom-pagination">
                <Box className="custom-rowperpage">
                  <Typography variant="body2" component="span">
                    Page:
                  </Typography>
                  <Select
                    id="pagination-select"
                    value={page}
                    onChange={handleChange}
                    MenuProps={{
                      className: "pagination-menu",
                    }}
                  >
                    {PAGE_SIZES?.map((pageSize) => (
                      <MenuItem
                        key={pageSize.value}
                        value={pageSize.value}
                        selected={pageSize?.selected}
                      >
                        {pageSize.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="body2" component="span">
                    {getPaginationDetailText(
                      coursesDeliveredListMeta,
                      pageNo,
                      page
                    )}
                  </Typography>{" "}
                </Box>
                <Pagination
                  count={coursesDeliveredListMeta?.totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={pageNo}
                  onChange={onPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                    />
                  )}
                />
              </Box>
            ) : (
              <NoRecords />
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CoursesDelivered;
