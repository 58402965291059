export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const CHANGE_MANAGER_SETTING_REQUEST = "CHANGE_MANAGER_SETTING_REQUEST";
export const CHANGE_MANAGER_SETTING_SUCCESS = "CHANGE_MANAGER_SETTING_SUCCESS";
export const CHANGE_MANAGER_SETTING_FAILURE = "CHANGE_MANAGER_SETTING_FAILURE";
