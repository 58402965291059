import { connect, MapDispatchToProps } from "react-redux";
import EducatorsHomeDetailsComponent, {
  EducatorsHomeDetailsProps,
} from "pages/EducatorsHome/EducatorsHomeDetails";
import {
  addCourseReflectionFormRequest,
  editCourseReflectionFormRequest,
  enroledStudentListForEducatorHomeRequest,
  scheduledOfferingDetailsRequest,
} from "store/educatorHome/actions";
import {
  IEducatorHomeDetailsContainerDispatch,
  IEducatorHomeScoDetails,
} from "utils/interfaces/educator-home";
import {
  fillStudentAttendanceRequest,
  SCOStudentAttendanceListRequest,
} from "store/scheduledOffering/actions";

const mapDispatchToProps: MapDispatchToProps<
  IEducatorHomeDetailsContainerDispatch,
  IEducatorHomeScoDetails
> = {
  scheduledOfferingDetailsRequest,
  addCourseReflectionFormRequest,
  editCourseReflectionFormRequest,
  enroledStudentListForEducatorHomeRequest,
  SCOStudentAttendanceListRequest,
  fillStudentAttendanceRequest,
};

const connector = connect(null, mapDispatchToProps);

const EducatorsHomeDetails: React.FC<EducatorsHomeDetailsProps> = (props) => {
  return <EducatorsHomeDetailsComponent {...props} />;
};

export default connector(EducatorsHomeDetails);
