import {
  AttendanceFillRequest,
  GenerateCertificateFillRequest,
  IAddEditScheduledOffering,
  ICourseCancel,
  IEditScheduledOffering,
} from "utils/interfaces/scheduled-offering";
import baseService from "services/base-service";
import { SCOCertificate } from "utils/enums/scheduled-offering";

const scheduledOfferingBaseUrl = `/api/CourseOffering`;

const getScheduledOfferingList = async (payload: {
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  courseName: string;
  courseNumber?: string;
  term?: Array<number>;
  locations?: Array<number>;
  isCertificateIssued?: boolean;
  isEMREntered?: boolean;
  status?: number;
  isBlendedCourse?: boolean;
  isDashboard?: boolean;
}) =>
  baseService.post(`${scheduledOfferingBaseUrl}/GetCourseOfferings`, payload);

const addScheduledOffering = async (payload: IAddEditScheduledOffering) => {
  const formData = new FormData();
  formData.append("courseId", payload.courseId.toString());
  formData.append("locationId", payload.locationId.toString());
  formData.append("termId", payload.termId.toString());
  payload.educatorIds?.forEach((item, index) =>
    formData.append(`educatorIds[${index}]`, item.toString())
  );
  formData.append(
    "maxEnrollments",
    payload.maxEnrollments ? payload.maxEnrollments.toString() : ""
  );
  formData.append(
    "isCertificateIssued",
    payload.certificatesIssued === SCOCertificate.No ||
      payload.certificatesIssued === SCOCertificate.Yes
      ? JSON.stringify(Boolean(payload.certificatesIssued))
      : JSON.stringify(false)
  );
  formData.append(
    "isEMREntered",
    payload.emrEntered === SCOCertificate.No ||
      payload.emrEntered === SCOCertificate.Yes
      ? JSON.stringify(Boolean(payload.emrEntered))
      : JSON.stringify(false)
  );
  formData.append(
    "documentName",
    payload.blankEvaluationFormDoc
      ? payload.blankEvaluationFormDoc.toString()
      : ""
  );
  formData.append("document", payload.blankEvaluationForm);
  formData.append("startDate", payload.startDateOfFirstSession);
  formData.append("startTime", payload.startTimeOfFirstSession);
  formData.append("endTime", payload.endTimeOfFirstSession);
  formData.append("endDate", payload.endDateOfFirstSession);
  formData.append("isAllDay", JSON.stringify(payload.isAllDay));
  formData.append("isRepeat", JSON.stringify(payload.isRepeat));
  formData.append(
    "courseOfferingRepeatSettings",
    JSON.stringify(payload.courseOfferingRepeatSettings)
  );
  formData.append(
    "courseOfferingSessions",
    JSON.stringify(payload.courseOfferingSessions)
  );

  return baseService.post(scheduledOfferingBaseUrl, formData);
};

const editScheduledOffering = async (payload: IEditScheduledOffering) =>
  baseService.put(scheduledOfferingBaseUrl, payload);

const deleteScheduledOffering = async (payload: { id: number }) =>
  baseService.delete(`${scheduledOfferingBaseUrl}?id=${payload.id}`);

const getScoDaysList = async (payload: {
  startDateOfFirstSession: string;
  repeatType: number;
  occurenceStart: number;
  repeatDays: string;
  endType: number;
  occurenceEnd: number;
  endOn: any;
}) =>
  baseService.post(
    `${scheduledOfferingBaseUrl}/CalculateCourseOfferingSessionDays`,
    payload
  );

const getScheduledOfferingById = async (payload: { id: number }) =>
  baseService.get(`${scheduledOfferingBaseUrl}?id=${payload.id}`);

const getSessionListByScoId = async (payload: { id: number }) =>
  baseService.get(
    `${scheduledOfferingBaseUrl}/GetCourseOfferingSessions?id=${payload.id}`
  );

const addSessionDay = async (payload: {
  dayNumber: number;
  sessionStartDateTime: any;
  sessionEndDateTime: string;
  courseOfferingID: number;
}) =>
  baseService.post(
    `${scheduledOfferingBaseUrl}/AddCourseOfferingSessionDay`,
    payload
  );

const editSessionDay = async (payload: {
  id: number;
  dayNumber: number;
  sessionStartDateTime: any;
  sessionEndDateTime: string;
  courseOfferingID: number;
}) =>
  baseService.put(
    `${scheduledOfferingBaseUrl}/EditCourseOfferingSessionDay`,
    payload
  );

const deleteSessionDay = async (payload: { id: number }) =>
  baseService.delete(
    `${scheduledOfferingBaseUrl}/DeleteCourseOfferingSessionDay?id=${payload.id}`
  );

const downloadDocument = async (payload: {
  name: string;
  type: number;
  courseOfferingID: number;
}) => baseService.post(`${scheduledOfferingBaseUrl}/DownloadDocument`, payload);

const uploadDocument = async (payload: {
  name: string;
  type: number;
  courseOfferingID: number;
  document: any;
}) => {
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("type", payload.type.toString());
  formData.append("courseOfferingID", payload.courseOfferingID.toString());
  formData.append("document", payload.document);

  return baseService.post(
    `${scheduledOfferingBaseUrl}/UploadDocument`,
    formData
  );
};

const deleteDocument = async (payload: {
  id: number;
  type: number;
  courseOfferingID: number;
}) =>
  baseService.delete(
    `${scheduledOfferingBaseUrl}/DeleteDocument?id=${payload.id}&type=${payload.type}&courseOfferingID=${payload.courseOfferingID}`
  );

const cancelSessionDay = async (payload: ICourseCancel) =>
  baseService.put(
    `${scheduledOfferingBaseUrl}/CancelCourseOfferingSessionDay`,
    payload
  );

const addScoEducator = async (payload: {
  courseOfferingId: number;
  educatorIds: any;
}) =>
  baseService.post(
    `${scheduledOfferingBaseUrl}/AddCourseOfferingEducators`,
    payload
  );

const deleteScoEducator = async (payload: { id: number }) =>
  baseService.delete(
    `${scheduledOfferingBaseUrl}/DeleteCourseOfferingEducator?id=${payload.id}`
  );

const getEnroledStudentList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  name?: string;
  number?: number;
  mrnNo?: string;
  type?: string;
  enrolmentStatus?: string;
}) =>
  baseService.post(
    `${scheduledOfferingBaseUrl}/GetEnrolledStudents?id=${payload.id}`,
    payload
  );

const getSCOStudentAttendanceList = async (payload: {
  courseOfferingId: number;
  sessionId?: number;
}) =>
  baseService.get(
    `${scheduledOfferingBaseUrl}/GetSCOStudentAttendanceList?courseOfferingId=${payload.courseOfferingId}&sessionId=${payload.sessionId}`
  );

const fillStudentAttendance = async (payload: AttendanceFillRequest) =>
  baseService.put(`${scheduledOfferingBaseUrl}/UpdateAttendance`, payload);

const getSCOStudentWhoAttendedList = async (payload: {
  courseOfferingId: number;
  sessionId?: number;
}) =>
  baseService.get(
    `${scheduledOfferingBaseUrl}/GetSCOStudentWhoAttendedList?courseOfferingId=${payload.courseOfferingId}&sessionId=${payload.sessionId}`
  );

const getEnrolmentCount = async (payload: { id: number }) =>
  baseService.get(
    `${scheduledOfferingBaseUrl}/GetEnrollmentCount?id=${payload.id}`
  );

const getSCOGenerateCertificateList = async (payload: {
  courseOfferingId: number;
}) =>
  baseService.get(
    `${scheduledOfferingBaseUrl}/GetStudentForGeneratingCertificate?id=${payload.courseOfferingId}`
  );

const fillGenerateCertificate = async (
  payload: GenerateCertificateFillRequest
) =>
  baseService.put(`${scheduledOfferingBaseUrl}/GenerateCertificate`, payload);

const printCourseEnrolments = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  name?: string;
  number?: number;
  mrnNo?: string;
  type?: string;
  enrolmentStatus?: string;
}) =>
  baseService.post(
    `${scheduledOfferingBaseUrl}/PrintCourseEnrolments?id=${payload.id}`,
    payload
  );

const getEducatorReflectionForm = async (payload: {
  educatorId: number;
  courseOfferingId: number;
}) =>
  baseService.get(
    `${scheduledOfferingBaseUrl}/GetEducatorReflectionForm?educatorId=${payload.educatorId}&courseOfferingId=${payload.courseOfferingId}`
  );

export default {
  getScheduledOfferingList,
  addScheduledOffering,
  editScheduledOffering,
  deleteScheduledOffering,
  getScoDaysList,
  getScheduledOfferingById,
  getSessionListByScoId,
  addSessionDay,
  editSessionDay,
  deleteSessionDay,
  downloadDocument,
  uploadDocument,
  deleteDocument,
  cancelSessionDay,
  addScoEducator,
  deleteScoEducator,
  getEnroledStudentList,
  getSCOStudentAttendanceList,
  fillStudentAttendance,
  getSCOStudentWhoAttendedList,
  getEnrolmentCount,
  getSCOGenerateCertificateList,
  fillGenerateCertificate,
  printCourseEnrolments,
  getEducatorReflectionForm,
};
