import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  userListSuccess,
  userListFailure,
  addUserSuccess,
  addUserFailure,
  editUserSuccess,
  editUserFailure,
  userByIdSuccess,
  userByIdFailure,
  deleteUserFailure,
  deleteUserSuccess,
} from "./action";
import {
  USER_LIST_REQUEST,
  ADD_USER_REQUEST,
  EDIT_USER_REQUEST,
  USER_BY_ID_REQUEST,
  DELETE_USER_REQUEST,
} from "./actionTypes";
import {
  UserListResponse,
  AddEditUserResponse,
  UserByIdResponse,
  DeleteUserResponse,
} from "./types";
import userService from "./service";

function* userListSaga(action: any) {
  try {
    const payload: any = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      name: action.payload.values.name,
    };
    const response: UserListResponse = yield call(
      userService.getUserList,
      payload
    );
    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };
    yield put(
      userListSuccess({
        userList: response.item.records,
        userListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      userList: response.item.records,
      userListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      userListFailure({
        error: e.message,
      })
    );
  }
}

function* addUserSaga(action: any) {
  try {
    const response: AddEditUserResponse = yield call(userService.addUser, {
      id: action.payload.values.id,
      firstName: action.payload.values.firstName,
      lastName: action.payload.values.lastName,
      email: action.payload.values.email,
      address: action.payload.values.address,
      roleIds: action.payload.values.roleIds,
    });

    yield put(
      addUserSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addUserFailure({
        error: e.message,
      })
    );
  }
}

function* editUserSaga(action: any) {
  try {
    const response: AddEditUserResponse = yield call(userService.editUser, {
      id: action.payload.values.id,
      firstName: action.payload.values.firstName,
      lastName: action.payload.values.lastName,
      email: action.payload.values.email,
      address: action.payload.values.address,
      roleIds: action.payload.values.roleIds,
    });

    yield put(
      editUserSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editUserFailure({
        error: e.message,
      })
    );
  }
}

function* userByIdSaga(action: any) {
  try {
    const response: UserByIdResponse = yield call(userService.getUserById, {
      id: action.payload.values.id,
    });

    yield put(
      userByIdSuccess({
        userDetails: response.item,
      })
    );
    action.payload.callback({
      userDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      userByIdFailure({
        error: e.message,
      })
    );
  }
}

function* deleteUserSaga(action: any) {
  try {
    const response: DeleteUserResponse = yield call(userService.deleteUser, {
      id: action.payload.values.id,
    });

    yield put(
      deleteUserSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteUserFailure({
        error: e.message,
      })
    );
  }
}

function* userSaga() {
  yield all([takeLatest(USER_LIST_REQUEST, userListSaga)]);
  yield all([takeLatest(ADD_USER_REQUEST, addUserSaga)]);
  yield all([takeLatest(EDIT_USER_REQUEST, editUserSaga)]);
  yield all([takeLatest(USER_BY_ID_REQUEST, userByIdSaga)]);
  yield all([takeLatest(DELETE_USER_REQUEST, deleteUserSaga)]);
}

export default userSaga;
