/* eslint-disable react/jsx-boolean-value */
import {
  Card,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FormSteps } from "utils/enums/student-enrolment";
import { Controller, useForm } from "react-hook-form";
import { EXISTING_STUDENT_OPTIONS } from "utils/constants/student-enrolment";
import {
  IEnrolmentOptionItem,
  IStep1Details,
} from "utils/interfaces/student-enrolment";
import { useEffect } from "react";
import RadioCheckBoxIcon from "components/RadioCheckBoxIcon";
import RadioCheckedBoxIcon from "components/RadioCheckedBoxIcon";
import { useHistory } from "react-router-dom";
import { AppRoutings } from "utils/enums/app-routings";
import FormStepButtons from "../FormStepButtons";

interface IFormStep1Props {
  moveNext: (step: number, data: any, isNext: boolean) => void;
  stepDetails: { data: IStep1Details };
  eligibilityCriteriaList: Array<IEnrolmentOptionItem> | undefined;
}

const FormStep1: React.FC<IFormStep1Props> = (props) => {
  const { moveNext, stepDetails, eligibilityCriteriaList } = props;
  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IStep1Details>({
    defaultValues: {
      isExistingStudent: 0,
      eligiBilityCriteria: null,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setFormValues = (data: any) => {
    reset((formValues) => ({
      ...formValues,
      isExistingStudent: data.isExistingStudent,
      eligiBilityCriteria: data.eligiBilityCriteria,
    }));
  };

  useEffect(() => {
    if (stepDetails && stepDetails.data) {
      setFormValues(stepDetails.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleBack = () => {};

  const onSubmit = (data: any) => {
    const stepData = {
      data,
    };

    moveNext(FormSteps.StudentDetails, stepData, true);
  };

  const getEligibilityCriteriaError = () => {
    if (errors.eligiBilityCriteria) {
      if (errors.eligiBilityCriteria.type === "required") {
        return "Please select an option";
      }
    }

    return "";
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="stepper-card step-1">
        <Typography
          variant="body2"
          className="instruction"
          sx={{ marginBottom: "12px !important" }}
        >
          Follow the instructions below in order to register to attend a
          Recovery & Wellbeing College Courses
        </Typography>
        <Typography variant="body2" className="instruction">
          The South Eastern Sydney Recovery & Wellbeing College is currently
          FREE of charge and open to people 16 years of age and over in our
          community.
        </Typography>
        <Typography variant="h4" className="questions">
          Are you an existing student of the Recovery and Wellbeing college?
        </Typography>
        <Controller
          control={control}
          {...register("isExistingStudent", {
            required: true,
          })}
          render={({ field }) => (
            <RadioGroup row {...field}>
              {EXISTING_STUDENT_OPTIONS.map((option) => (
                <FormControlLabel
                  key={option.value}
                  onClick={
                    option.value === 1
                      ? () =>
                          history.push({
                            pathname: AppRoutings.LogIn,
                            state: { isEnrolmentLoaded: true },
                          })
                      : undefined
                  }
                  value={option.value}
                  control={
                    <Radio
                      icon={<RadioCheckBoxIcon />}
                      checkedIcon={<RadioCheckedBoxIcon />}
                    />
                  }
                  label={option.label}
                />
              ))}
            </RadioGroup>
          )}
        />
        <Typography
          variant="body2"
          className="instruction"
          sx={{ lineHeight: "18px !important" }}
        >
          If you have previously enrolled to attend a course with the Recovery
          and Wellbeing College, choose <span>&#39;Yes&#39;</span> or you have
          never enrolled, choose <span>&#39;No&#39;</span>
        </Typography>
        <Typography
          variant="h4"
          className="questions"
          sx={{ marginBottom: "2px !important" }}
        >
          Eligibility Criteria <span className="required">*</span>
        </Typography>
        <Typography
          variant="body2"
          className="instruction"
          sx={{ marginBottom: "12px !important" }}
        >
          Please select the option below which describes your situation
        </Typography>
        <Controller
          control={control}
          {...register("eligiBilityCriteria", {
            required: true,
          })}
          render={({ field }) => (
            <>
              <RadioGroup {...field}>
                {eligibilityCriteriaList?.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={
                      <Radio
                        icon={<RadioCheckBoxIcon />}
                        checkedIcon={<RadioCheckedBoxIcon />}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </RadioGroup>
              {!!errors.eligiBilityCriteria && (
                <FormHelperText error>
                  {getEligibilityCriteriaError()}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Card>
      <Typography variant="body2" className="know-more">
        Want to find out more about our courses before you enrol?
      </Typography>
      <Typography variant="body2" className="know-more">
        View our latest course guide here :{"  "}
        <a href="https://www.seslhd.health.nsw.gov.au/recovery-wellbeing-college">
          Recovery & Wellbeing College Homepage
        </a>
      </Typography>
      <FormStepButtons handleBack={handleBack} isPrevDisabled={true} />
    </form>
  );
};

export default FormStep1;
