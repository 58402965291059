import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  CLOSE_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  MAX_INT_VALUE,
} from "utils/constants/constant";
import { IEnrolmentReportFilter } from "utils/interfaces/report";
import {
  IEnrolmentOptionItem,
  IEnrolmentOptions,
} from "utils/interfaces/student-enrolment";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { OrderByOptions } from "utils/enums/sorting";
import {
  showLoaderForBatchApiCall,
  getPagePayloadValues,
  hideLoaderForBatchApiCall,
} from "utils/helper";
import { ITerm, ITermPagePayloadValues } from "utils/interfaces/term";
import { useDispatch } from "react-redux";
import { termListRequest } from "store/term/actions";
import { TermListSuccessPayload } from "store/term/types";

interface IReportFilterProps {
  filterBox: boolean;
  setFilterBox: React.Dispatch<React.SetStateAction<boolean>>;
  handleApplyClick: (formData: IEnrolmentReportFilter) => void;
  filteredData?: IEnrolmentReportFilter;
  pageNo: number;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
  setFilteredData: React.Dispatch<
    React.SetStateAction<IEnrolmentReportFilter | undefined>
  >;
  getReportList: (
    filterData?: IEnrolmentReportFilter,
    filtered?: boolean,
    pageNum?: number
  ) => Promise<void>;
  setAppliedFilter: React.Dispatch<React.SetStateAction<boolean>>;
  enrolmentOptionList?: IEnrolmentOptions;
}

interface IReportFilter {
  enrolmentType?: string[];
  sector?: string[];
  age?: string[];
  gender?: string[];
  aTSI?: string[];
  employmentStatus?: string[];
  studyLoad?: string[];
  terms?: string[];
}

const defaultValues: IReportFilter = {
  enrolmentType: [],
  sector: [],
  age: [],
  gender: [],
  aTSI: [],
  employmentStatus: [],
  studyLoad: [],
  terms: [],
};

const ReportFilter: React.FC<IReportFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getReportList,
  setAppliedFilter,
  enrolmentOptionList,
}) => {
  const { register, handleSubmit, reset, setValue, watch } =
    useForm<IEnrolmentReportFilter>({
      defaultValues,
    });
  const dispatch = useDispatch();
  const [termList, setTermList] = useState<ITerm[]>();
  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
    hideLoaderForBatchApiCall();
  };

  const getTermList = () => {
    showLoaderForBatchApiCall();

    const values: ITermPagePayloadValues = getPagePayloadValues(
      "TermName",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.termName = "";

    const payload = {
      values,
      callback: onTermListSuccess,
    };

    dispatch(termListRequest(payload));
  };

  useEffect(() => {
    if (filteredData) {
      setValue("enrolmentType", filteredData?.enrolmentType);
      setValue("sector", filteredData?.sector);
      setValue("age", filteredData?.age);
      setValue("gender", filteredData?.gender);
      setValue("aTSI", filteredData?.aTSI);
      setValue("employmentStatus", filteredData?.employmentStatus);
      setValue("studyLoad", filteredData?.studyLoad);
      setValue("terms", filteredData?.terms);
    } else {
      reset();
    }
    getTermList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, setValue, filterBox, reset]);

  const onClearClick = () => {
    if (
      !(
        !filteredData?.enrolmentType?.length &&
        !filteredData?.sector?.length &&
        !filteredData?.age?.length &&
        !filteredData?.gender?.length &&
        !filteredData?.aTSI?.length &&
        !filteredData?.employmentStatus?.length &&
        !filteredData?.studyLoad?.length &&
        !filteredData?.terms?.length
      )
    ) {
      reset();
      setFilterBox(false);
      setAppliedFilter(false);
      document.body.classList.toggle("filter-open");
      if (pageNo === DEFAULT_PAGE_NO) {
        getReportList();
      } else {
        setPageNo(1);
      }
      setFilteredData(undefined);
    }
  };

  const renderSelectedValues = (
    selected: string[],
    optionList: Array<IEnrolmentOptionItem> | undefined,
    isValueField?: boolean
  ) => {
    const selectedString: string[] = [];
    if (isValueField) {
      selected.forEach((item: string) => {
        const selectedItem = optionList?.find(
          (option) => option.value === item
        );
        if (selectedItem) {
          selectedString.push(selectedItem.label);
        }
      });
    } else {
      selected.forEach((item: string) => {
        const selectedItem = optionList?.find(
          (option) => option.label === item
        );
        if (selectedItem) {
          selectedString.push(selectedItem.label);
        }
      });
    }

    return selectedString.join(", ");
  };

  const handleFieldChange = (
    event: SelectChangeEvent<string[]>,
    field: keyof IReportFilter
  ) => {
    const { value } = event.target;
    setValue(field, value as string[]);
  };
  const termOptions: Array<IEnrolmentOptionItem> | undefined = termList?.map(
    (term) => ({
      value: term.id.toString(),
      label: term.termName,
    })
  );

  return (
    <>
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setFilterBox(false);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Term</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Term"
                {...register("terms")}
                value={watch("terms") || []}
                onChange={(e) => handleFieldChange(e, "terms")}
                input={<OutlinedInput label="Term" />}
                renderValue={(selected) =>
                  renderSelectedValues(selected, termOptions, true)
                }
              >
                {termOptions?.map((term) => (
                  <MenuItem key={term.value} value={term.value}>
                    <Checkbox
                      checked={
                        (watch("terms") as unknown as string[]).indexOf(
                          term.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={term.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Enrolment Type</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Enrolment Type"
                {...register("enrolmentType")}
                value={watch("enrolmentType") || []}
                onChange={(e) => handleFieldChange(e, "enrolmentType")}
                input={<OutlinedInput label="Enrolment Type" />}
                renderValue={(selected) =>
                  renderSelectedValues(
                    selected,
                    enrolmentOptionList?.enrolmentType
                  )
                }
              >
                {enrolmentOptionList?.enrolmentType?.map((enrolmentType) => (
                  <MenuItem
                    key={enrolmentType.value}
                    value={enrolmentType.value}
                  >
                    <Checkbox
                      checked={
                        (watch("enrolmentType") as unknown as string[]).indexOf(
                          enrolmentType.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={enrolmentType.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Sector</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Sector"
                {...register("sector")}
                value={watch("sector") || []}
                onChange={(e) => handleFieldChange(e, "sector")}
                input={<OutlinedInput label="Sector" />}
                renderValue={(selected) =>
                  renderSelectedValues(selected, enrolmentOptionList?.sector)
                }
              >
                {enrolmentOptionList?.sector?.map((sector) => (
                  <MenuItem key={sector.value} value={sector.value}>
                    <Checkbox
                      checked={
                        (watch("sector") as unknown as string[]).indexOf(
                          sector.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={sector.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Age</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Age"
                {...register("age")}
                value={watch("age") || []}
                onChange={(e) => handleFieldChange(e, "age")}
                input={<OutlinedInput label="Age" />}
                renderValue={(selected) =>
                  renderSelectedValues(
                    selected,
                    enrolmentOptionList?.studentAgeGroup
                  )
                }
              >
                {enrolmentOptionList?.studentAgeGroup?.map((age) => (
                  <MenuItem key={age.value} value={age.value}>
                    <Checkbox
                      checked={
                        (watch("age") as unknown as string[]).indexOf(
                          age.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={age.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Gender</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Gender"
                {...register("gender")}
                value={watch("gender") || []}
                onChange={(e) => handleFieldChange(e, "gender")}
                input={<OutlinedInput label="Gender" />}
                renderValue={(selected) =>
                  renderSelectedValues(
                    selected,
                    enrolmentOptionList?.genderIdentity
                  )
                }
              >
                {enrolmentOptionList?.genderIdentity?.map((gender) => (
                  <MenuItem key={gender.value} value={gender.value}>
                    <Checkbox
                      checked={
                        (watch("gender") as unknown as string[]).indexOf(
                          gender.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={gender.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">
                Aboriginal/Torres Strait Islander Identity
              </InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Aboriginal/Torres Strait Islander Identity"
                {...register("aTSI")}
                value={watch("aTSI") || []}
                onChange={(e) => handleFieldChange(e, "aTSI")}
                input={
                  <OutlinedInput label="Aboriginal/Torres Strait Islander Identity" />
                }
                renderValue={(selected) =>
                  renderSelectedValues(
                    selected,
                    enrolmentOptionList?.atsiIdentity
                  )
                }
              >
                {enrolmentOptionList?.atsiIdentity?.map((atsi) => (
                  <MenuItem key={atsi.value} value={atsi.value}>
                    <Checkbox
                      checked={
                        (watch("aTSI") as unknown as string[]).indexOf(
                          atsi.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={atsi.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">
                Current Employment Status
              </InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Current Employment Status"
                {...register("employmentStatus")}
                value={watch("employmentStatus") || []}
                onChange={(e) => handleFieldChange(e, "employmentStatus")}
                input={<OutlinedInput label="Current Employment Status" />}
                MenuProps={{
                  className:
                    "filter-select-menu filter-select-menu-employment-status",
                }}
                renderValue={(selected) =>
                  renderSelectedValues(
                    selected,
                    enrolmentOptionList?.employmentStatus,
                    true
                  )
                }
              >
                {enrolmentOptionList?.employmentStatus?.map(
                  (employmentStatus) => (
                    <MenuItem
                      key={employmentStatus.value}
                      value={employmentStatus.value}
                    >
                      <Checkbox
                        checked={
                          (
                            watch("employmentStatus") as unknown as string[]
                          ).indexOf(employmentStatus.value) > -1
                        }
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckedBoxIcon />}
                      />
                      <ListItemText primary={employmentStatus.label} />
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Study Load</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Study Load"
                {...register("studyLoad")}
                value={watch("studyLoad") || []}
                onChange={(e) => handleFieldChange(e, "studyLoad")}
                input={<OutlinedInput label="Study Load" />}
                renderValue={(selected) =>
                  renderSelectedValues(selected, enrolmentOptionList?.studyLoad)
                }
              >
                {enrolmentOptionList?.studyLoad?.map((studyLoad) => (
                  <MenuItem key={studyLoad.value} value={studyLoad.value}>
                    <Checkbox
                      checked={
                        (watch("studyLoad") as unknown as string[]).indexOf(
                          studyLoad.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={studyLoad.value} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-clear"
              onClick={onClearClick}
              disabled={
                !filteredData?.enrolmentType?.length &&
                !filteredData?.sector?.length &&
                !filteredData?.age?.length &&
                !filteredData?.gender?.length &&
                !filteredData?.aTSI?.length &&
                !filteredData?.employmentStatus?.length &&
                !filteredData?.studyLoad?.length &&
                !filteredData?.terms?.length
              }
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ReportFilter;
