import baseService from "services/base-service";

const locationBaseUrl = `/api/Location`;

const getLocationList = async (payload: {
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  location: string;
}) => baseService.post(`${locationBaseUrl}/GetLocations`, payload);

const addLocation = async (payload: { location: string; isBlended: boolean }) =>
  baseService.post(locationBaseUrl, payload);

const editLocation = async (payload: {
  id: number;
  location: string;
  isBlended: boolean;
}) => baseService.put(locationBaseUrl, payload);

const deleteLocation = async (payload: { id: number }) =>
  baseService.delete(`${locationBaseUrl}?id=${payload.id}`);

export default {
  getLocationList,
  addLocation,
  editLocation,
  deleteLocation,
};
