import {
  UPCOMING_COURSE_LIST_REQUEST,
  UPCOMING_COURSE_LIST_SUCCESS,
  UPCOMING_COURSE_LIST_FAILURE,
  PAST_STUDENT_COURSE_LIST_FAILURE,
  PAST_STUDENT_COURSE_LIST_REQUEST,
  PAST_STUDENT_COURSE_LIST_SUCCESS,
  COURSE_OFFERING_LIST_REQUEST,
  COURSE_OFFERING_LIST_SUCCESS,
  COURSE_OFFERING_LIST_FAILURE,
  STUDENT_DASHBOARD_COURSE_DETAILS_REQUEST,
  STUDENT_DASHBOARD_COURSE_DETAILS_SUCCESS,
  STUDENT_DASHBOARD_COURSE_DETAILS_FAILURE,
  APPLY_OR_WITHDRAW_COURSE_REQUEST,
  APPLY_OR_WITHDRAW_COURSE_SUCCESS,
  APPLY_OR_WITHDRAW_COURSE_FAILURE,
  MY_ENROLMENT_LIST_REQUEST,
  MY_ENROLMENT_LIST_SUCCESS,
  MY_ENROLMENT_LIST_FAILURE,
  DOWNLOAD_CERTIFICATE_REQUEST,
  DOWNLOAD_CERTIFICATE_SUCCESS,
  DOWNLOAD_CERTIFICATE_FAILURE,
  CHANGE_COURSE_PREFERENCE_REQUEST,
  CHANGE_COURSE_PREFERENCE_SUCCESS,
  CHANGE_COURSE_PREFERENCE_FAILURE,
  STUDENT_DOCUMENTS_FAILURE,
  STUDENT_DOCUMENTS_REQUEST,
  STUDENT_DOCUMENTS_SUCCESS,
} from "./actionTypes";
import {
  PastCourseListPayload,
  PastCourseListRequest,
  PastCourseListSuccess,
  PastCourseListSuccessPayload,
  PastCourseListFailure,
  PastCourseListFailurePayload,
  UpcomingCourseListPayload,
  UpcomingCourseListRequest,
  UpcomingCourseListSuccessPayload,
  UpcomingCourseListSuccess,
  UpcomingCourseListFailurePayload,
  UpcomingCourseListFailure,
  CourseOfferingListPayload,
  CourseOfferingListRequest,
  CourseOfferingListSuccessPayload,
  CourseOfferingListSuccess,
  CourseOfferingListFailurePayload,
  CourseOfferingListFailure,
  StudentDashboardCourseDetailByIdRequest,
  StudentDashboardCourseDetailByIdPayload,
  StudentDashboardCourseDetailByIdSuccessPayload,
  StudentDashboardCourseDetailByIdSuccess,
  StudentDashboardCourseDetailByIdFailurePayload,
  StudentDashboardCourseDetailByIdFailure,
  ApplyOrWithdrawCourseRequest,
  ApplyOrWithdrawCoursePayload,
  ApplyOrWithdrawCourseSuccess,
  ApplyOrWithdrawCourseSuccessPayload,
  ApplyOrWithdrawCourseFailurePayload,
  ApplyOrWithdrawCourseFailure,
  MyEnrolmentListRequest,
  MyEnrolmentListSuccess,
  MyEnrolmentListFailure,
  MyEnrolmentListPayload,
  MyEnrolmentListSuccessPayload,
  MyEnrolmentListFailurePayload,
  DownloadCertificatePayload,
  DownloadCertificateRequest,
  DownloadCertificateSuccess,
  DownloadCertificateFailurePayload,
  DownloadCertificateFailure,
  DownloadCertificateSuccessPayload,
  ChangeCoursePreferencePayload,
  ChangeCoursePreferenceSuccessPayload,
  ChangeCoursePreferenceFailurePayload,
  ChangeCoursePreferenceRequest,
  ChangeCoursePreferenceFailure,
  ChangeCoursePreferenceSuccess,
  StudentDocumentsPayload,
  StudentDocumentsRequest,
  StudentDocumentsSuccessPayload,
  StudentDocumentsSuccess,
  StudentDocumentsFailurePayload,
  StudentDocumentsFailure,
} from "./types";

export const upcomingCourseListRequest = (
  payload: UpcomingCourseListPayload
): UpcomingCourseListRequest => ({
  type: UPCOMING_COURSE_LIST_REQUEST,
  payload,
});

export const upcomingCourseListSuccess = (
  payload: UpcomingCourseListSuccessPayload
): UpcomingCourseListSuccess => ({
  type: UPCOMING_COURSE_LIST_SUCCESS,
  payload,
});

export const upcomingCourseListFailure = (
  payload: UpcomingCourseListFailurePayload
): UpcomingCourseListFailure => ({
  type: UPCOMING_COURSE_LIST_FAILURE,
  payload,
});

export const pastCourseListRequest = (
  payload: PastCourseListPayload
): PastCourseListRequest => ({
  type: PAST_STUDENT_COURSE_LIST_REQUEST,
  payload,
});

export const pastCourseListSuccess = (
  payload: PastCourseListSuccessPayload
): PastCourseListSuccess => ({
  type: PAST_STUDENT_COURSE_LIST_SUCCESS,
  payload,
});

export const pastCourseListFailure = (
  payload: PastCourseListFailurePayload
): PastCourseListFailure => ({
  type: PAST_STUDENT_COURSE_LIST_FAILURE,
  payload,
});

export const courseOfferingListRequest = (
  payload: CourseOfferingListPayload
): CourseOfferingListRequest => ({
  type: COURSE_OFFERING_LIST_REQUEST,
  payload,
});

export const courseOfferingListSuccess = (
  payload: CourseOfferingListSuccessPayload
): CourseOfferingListSuccess => ({
  type: COURSE_OFFERING_LIST_SUCCESS,
  payload,
});

export const courseOfferingListFailure = (
  payload: CourseOfferingListFailurePayload
): CourseOfferingListFailure => ({
  type: COURSE_OFFERING_LIST_FAILURE,
  payload,
});

export const studentDashboardCourseDetailByIdRequest = (
  payload: StudentDashboardCourseDetailByIdPayload
): StudentDashboardCourseDetailByIdRequest => ({
  type: STUDENT_DASHBOARD_COURSE_DETAILS_REQUEST,
  payload,
});

export const studentDashboardCourseDetailByIdSuccess = (
  payload: StudentDashboardCourseDetailByIdSuccessPayload
): StudentDashboardCourseDetailByIdSuccess => ({
  type: STUDENT_DASHBOARD_COURSE_DETAILS_SUCCESS,
  payload,
});

export const studentDashboardCourseDetailByIdFailure = (
  payload: StudentDashboardCourseDetailByIdFailurePayload
): StudentDashboardCourseDetailByIdFailure => ({
  type: STUDENT_DASHBOARD_COURSE_DETAILS_FAILURE,
  payload,
});

export const applyOrWithdrawCourseRequest = (
  payload: ApplyOrWithdrawCoursePayload
): ApplyOrWithdrawCourseRequest => ({
  type: APPLY_OR_WITHDRAW_COURSE_REQUEST,
  payload,
});

export const applyOrWithdrawCourseSuccess = (
  payload: ApplyOrWithdrawCourseSuccessPayload
): ApplyOrWithdrawCourseSuccess => ({
  type: APPLY_OR_WITHDRAW_COURSE_SUCCESS,
  payload,
});

export const applyOrWithdrawCourseFailure = (
  payload: ApplyOrWithdrawCourseFailurePayload
): ApplyOrWithdrawCourseFailure => ({
  type: APPLY_OR_WITHDRAW_COURSE_FAILURE,
  payload,
});

export const myEnrolmentListRequest = (
  payload: MyEnrolmentListPayload
): MyEnrolmentListRequest => ({
  type: MY_ENROLMENT_LIST_REQUEST,
  payload,
});

export const myEnrolmentListSuccess = (
  payload: MyEnrolmentListSuccessPayload
): MyEnrolmentListSuccess => ({
  type: MY_ENROLMENT_LIST_SUCCESS,
  payload,
});

export const myEnrolmentListFailure = (
  payload: MyEnrolmentListFailurePayload
): MyEnrolmentListFailure => ({
  type: MY_ENROLMENT_LIST_FAILURE,
  payload,
});

export const downloadCertificateRequest = (
  payload: DownloadCertificatePayload
): DownloadCertificateRequest => ({
  type: DOWNLOAD_CERTIFICATE_REQUEST,
  payload,
});

export const downloadCertificateSuccess = (
  payload: DownloadCertificateSuccessPayload
): DownloadCertificateSuccess => ({
  type: DOWNLOAD_CERTIFICATE_SUCCESS,
  payload,
});

export const downloadCertificateFailure = (
  payload: DownloadCertificateFailurePayload
): DownloadCertificateFailure => ({
  type: DOWNLOAD_CERTIFICATE_FAILURE,
  payload,
});

export const changeCoursePreferenceRequest = (
  payload: ChangeCoursePreferencePayload
): ChangeCoursePreferenceRequest => ({
  type: CHANGE_COURSE_PREFERENCE_REQUEST,
  payload,
});

export const changeCoursePreferenceSuccess = (
  payload: ChangeCoursePreferenceSuccessPayload
): ChangeCoursePreferenceSuccess => ({
  type: CHANGE_COURSE_PREFERENCE_SUCCESS,
  payload,
});

export const changeCoursePreferenceFailure = (
  payload: ChangeCoursePreferenceFailurePayload
): ChangeCoursePreferenceFailure => ({
  type: CHANGE_COURSE_PREFERENCE_FAILURE,
  payload,
});

export const studentDocumentsRequest = (
  payload: StudentDocumentsPayload
): StudentDocumentsRequest => ({
  type: STUDENT_DOCUMENTS_REQUEST,
  payload,
});

export const studentDocumentsSuccess = (
  payload: StudentDocumentsSuccessPayload
): StudentDocumentsSuccess => ({
  type: STUDENT_DOCUMENTS_SUCCESS,
  payload,
});

export const studentDocumentsFailure = (
  payload: StudentDocumentsFailurePayload
): StudentDocumentsFailure => ({
  type: STUDENT_DOCUMENTS_FAILURE,
  payload,
});
