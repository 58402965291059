/* eslint-disable no-case-declarations */
import { headerLogo } from "assets/images";
import {
  AppBar,
  Box,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from "@mui/material";
import { AppRoutings } from "utils/enums/app-routings";
import React, { useEffect, useReducer, useState } from "react";
import { FormSteps } from "utils/enums/student-enrolment";
import { PROCEED_DATA, PROCEED_STEP } from "utils/constants/student-enrolment";
import {
  ICurrentCourseItem,
  IEnrolmentOptions,
  IReviewStepDetails,
  IStudentEnrolmentFormContainerDispatch,
  IStudentEnrolmentFormContainerState,
} from "utils/interfaces/student-enrolment";
import {
  AddEnrolmentSuccessPayload,
  CheckValidEnrolmentSuccessPayload,
  CurrentCourseListSuccessPayload,
  EnrolmentOptionListSuccessPayload,
} from "store/studentEnrolment/types";
import { convertToDateTime, showLoader } from "utils/helper";
import ConfirmModal from "components/ConfirmModal";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import FormSubmitSuccess from "./FormSubmitSuccess";
import ReCaptchaModal from "./ReCaptchaModal";
import ReviewStep from "./ReviewStep";
import NoCourseOfferingStep from "./NoCourseOfferingStep";

const steps = [
  FormSteps.CollegeForm,
  FormSteps.StudentDetails,
  FormSteps.StudentSupports,
  FormSteps.CourseSelection,
];

const initialState = {
  activeStep: FormSteps.CollegeForm,
  stepData: Array(4).fill(null),
};

const reducer = (state: any, action: any) => {
  const { activeStep, stepData } = state;
  const { step, data } = action.payload;
  switch (action.type) {
    case PROCEED_STEP:
      stepData[activeStep] = data;

      return { activeStep: step, stepData };
    case PROCEED_DATA:
      stepData[activeStep] = data;

      return { activeStep, stepData };
    default:
      return state;
  }
};
export type StudentEnrolmentFormProps = IStudentEnrolmentFormContainerState &
  IStudentEnrolmentFormContainerDispatch;

const StudentEnrolmentForm: React.FC<StudentEnrolmentFormProps> = (props) => {
  const [stepState, dispatchStep] = useReducer(reducer, initialState);
  const [enrolmentOptionList, setEnrolmentOptionList] =
    useState<IEnrolmentOptions>();
  const [isValidReCaptcha, setIsValidReCaptcha] = useState<boolean>(false);
  const [isReviewStep, setIsReviewStep] = useState<boolean>(false);
  const [isOpenReCaptchaModal, setIsOpenReCaptchaModal] =
    useState<boolean>(false);
  const [isOpenConfirmSubmitModal, setIsOpenConfirmSubmitModal] =
    useState<boolean>(false);
  const [finishStep, setFinishStep] = React.useState<number>(0);
  const [formDetails, setFormDetails] = React.useState<IReviewStepDetails>();
  const [isCoursesAvailable, setIsCoursesAvailable] = useState<boolean>(true);
  const [currentCourseList, setCurrentCourseList] =
    useState<ICurrentCourseItem[]>();
  const { currentCourseListRequest } = props;
  const { activeStep, stepData } = stepState;

  const getStepTitle = () => {
    switch (activeStep) {
      case FormSteps.CollegeForm:
        return "Recovery College Online Enrolment Form";
      case FormSteps.StudentDetails:
        return "Student details";
      case FormSteps.StudentSupports:
        return "Student Supports and Emergency Contact Details";
      case FormSteps.CourseSelection:
        return "Course selection";
      default:
        return "";
    }
  };

  const getFormattedDate = (date: string) => {
    const stringDate = new Date(date?.toString());
    const formattedDate = convertToDateTime(stringDate);

    return formattedDate;
  };

  const onAddEnrolmentSuccess = (response: AddEnrolmentSuccessPayload) => {
    if (response.success) {
      dispatchStep({
        type: PROCEED_STEP,
        payload: { step: activeStep + 1 },
      });
      setFinishStep(1);
      setIsValidReCaptcha(true);
    } else if (response.message) {
      // toast.error(response.message);
    }
  };

  const getPayloadValues = () => {
    const initialData = stepData[0].data;
    const studentDetails = stepData[1].data;
    const studentEnrolmentData = stepData[2].data;
    const coursesData = stepData[3].data;

    const payloadValues: any = {
      isStudentExists: Boolean(initialData.isExistingStudent),
      eligibilityCriteria: initialData.eligiBilityCriteria,
      firstName: studentDetails.firstName,
      lastName: studentDetails.lastName,
      dateOfBirth: getFormattedDate(studentDetails.dateOfBirth),
      phoneNumber: studentDetails.phoneNumber,
      emailAddress: studentDetails.emailAddress,
      streetAddress: studentDetails.streetAddress,
      suburb: studentDetails.suburb,
      state: studentDetails.state,
      postCode: Number(studentDetails.postCode),
      preferredCourseDelivery: coursesData.preferredCourseDelivery,
      studentEnrolment: {
        currentServiceProviders:
          Number(studentEnrolmentData.staffEnrolment) === 1
            ? ""
            : studentEnrolmentData.currentServiceProviders,
        emergencyContactName: studentEnrolmentData.emergencyContactName,
        emergencyContactRelationship:
          studentEnrolmentData.emergencyContactRelationship,
        emergencyContactNumber: studentEnrolmentData.emergencyContactNumber,
        information: studentEnrolmentData.comment,
        enrolmentType: studentEnrolmentData.enrolmentType,
        isStaff: Boolean(Number(studentEnrolmentData.staffEnrolment)),
      },
      selectedCourses: coursesData.courseIds,
      selectedCoursesNames: coursesData.courseNames,
    };

    if (payloadValues.studentEnrolment.isStaff) {
      payloadValues.staffEnrolment = {
        employmentStatus: studentEnrolmentData.staffEmploymentStatus,
        currentRole: studentEnrolmentData.currentRole,
        workEnvironment: studentEnrolmentData.workEnvironment,
        site: studentEnrolmentData.site,
        managerName: studentEnrolmentData.nameOfManager?.trim(),
        managerEmail: studentEnrolmentData.emailOfManager?.trim(),
        staffLinkId: studentEnrolmentData.staffLinkId || null,
      };
    } else {
      payloadValues.studentProfileDetails = {
        hearAboutRC: studentEnrolmentData.whereAboutUs,
        atsiIdentity: studentEnrolmentData.identity,
        disability: studentEnrolmentData.disability,
        countryOfBirth: studentEnrolmentData.countryOfBirth,
        languageAtHome: studentEnrolmentData.languageAtHome,
        ethnicity: studentEnrolmentData.ethnicity,
        genderIdentity: studentEnrolmentData.genderIdentity,
        completedQualifications: studentEnrolmentData.qualifications,
        employmentStatus: studentEnrolmentData.employmentStatus,
        highestCompletedSchoolLevel:
          studentEnrolmentData.highestCompletedSchoolLevel,
        isStuding: studentEnrolmentData.currentlyStudying,
        studyLoad: studentEnrolmentData.studyLoad,
      };
    }

    return payloadValues;
  };

  const addNewEnrolment = () => {
    const { addEnrolmentRequest } = props;
    const values = getPayloadValues();
    values.preferredCourseDelivery = values.preferredCourseDelivery.filter(
      (x: any) => values.selectedCourses.includes(x.courseOfferingId)
    );
    if (addEnrolmentRequest) {
      showLoader();

      const payload = {
        values,
        callback: onAddEnrolmentSuccess,
      };
      addEnrolmentRequest(payload);
    }
  };

  const onCheckValidEnrolmentSuccess = (
    response: CheckValidEnrolmentSuccessPayload
  ) => {
    if (response.success) {
      dispatchStep({
        type: PROCEED_STEP,
        payload: { step: activeStep + 1, data: stepData[1] },
      });
    }
  };

  const checkExistingStudent = () => {
    const { checkValidEnrolmentRequest } = props;
    if (checkValidEnrolmentRequest) {
      showLoader();

      const studentDetails = stepData[1].data;
      const payload = {
        values: {
          firstName: studentDetails.firstName,
          lastName: studentDetails.lastName,
          dateOfBirth: getFormattedDate(studentDetails.dateOfBirth),
          emailAddress: studentDetails.emailAddress,
        },
        callback: onCheckValidEnrolmentSuccess,
      };
      checkValidEnrolmentRequest(payload);
    }
  };

  const moveStep = (step: number, data: any, isNext: boolean) => {
    if (step === 2 && isNext) {
      dispatchStep({
        type: PROCEED_DATA,
        payload: { step, data },
      });
      checkExistingStudent();
    } else if (step === 4 && isNext) {
      dispatchStep({
        type: PROCEED_DATA,
        payload: { step, data },
      });
      setFormDetails(getPayloadValues());
      setIsReviewStep(true);
      setFinishStep(0);
    } else {
      dispatchStep({
        type: PROCEED_STEP,
        payload: { step, data },
      });
      setIsReviewStep(false);
    }
  };

  const onEnrolmentOptionListSuccess = (
    response: EnrolmentOptionListSuccessPayload
  ) => {
    setEnrolmentOptionList(response.enrolmentOptionList);
  };

  const getEnrolmentOptionList = () => {
    const { enrolmentOptionListRequest } = props;

    if (enrolmentOptionListRequest) {
      showLoader();

      const payload = {
        values: {},
        callback: onEnrolmentOptionListSuccess,
      };

      enrolmentOptionListRequest(payload);
    }
  };

  const handleConfirmCloseModal = () => {
    setIsOpenConfirmSubmitModal(false);
  };

  const handleReCaptchaCloseModal = () => {
    setIsOpenReCaptchaModal(false);
  };

  const handleYesSubmit = () => {
    setIsOpenReCaptchaModal(true);
  };

  const handleReCaptchaSubmit = () => {
    addNewEnrolment();
  };

  const onCurrentCourseListSuccess = (
    response: CurrentCourseListSuccessPayload
  ) => {
    setIsCoursesAvailable(!!response?.currentCourseList?.length);
    setCurrentCourseList(response.currentCourseList);
  };

  const getCurrentCourseList = () => {
    if (currentCourseListRequest) {
      showLoader();

      const payload = {
        values: {},
        callback: onCurrentCourseListSuccess,
      };

      currentCourseListRequest(payload);
    }
  };
  useEffect(() => {
    getCurrentCourseList();
    getEnrolmentOptionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrapper">
      <AppBar position="static" className="student-header">
        <Toolbar
          disableGutters
          sx={{ flexWrap: "wrap" }}
          className="header-container-top"
        >
          <a
            href={AppRoutings.Dashboard}
            className="header-logo"
            title="South Eastern Sydney Recovery and Wellbeing College"
          >
            <img
              src={headerLogo}
              alt="South Eastern Sydney Recovery and Wellbeing College"
              className="desktop"
            />
            <img
              src={headerLogo}
              alt="South Eastern Sydney Recovery and Wellbeing College"
              className="mobile"
            />
          </a>
          <Typography variant="h2" className="header-title">
            Student Enrolment
          </Typography>
        </Toolbar>
        {isCoursesAvailable && (
          <>
            {activeStep !== steps.length && !isReviewStep ? (
              <Toolbar
                disableGutters
                sx={{ flexWrap: "wrap" }}
                className="header-container-bottom"
              >
                <Typography variant="h3" className="header-subtitle">
                  {getStepTitle()}
                </Typography>

                <Stepper activeStep={activeStep}>
                  {steps.map((index) => {
                    const stepProps: { completed?: boolean } = {};

                    return (
                      <Step key={index} {...stepProps}>
                        <StepLabel>{index + 1}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Toolbar>
            ) : (
              !isValidReCaptcha && (
                <Toolbar
                  disableGutters
                  sx={{ flexWrap: "wrap" }}
                  className="header-container-bottom finished"
                >
                  <Typography variant="h3" className="header-subtitle">
                    Review Your Enrolment Details
                  </Typography>
                </Toolbar>
              )
            )}
          </>
        )}
      </AppBar>
      {isCoursesAvailable ? (
        <>
          <Box>
            {activeStep === steps.length || isReviewStep ? (
              <Box className="finished-step">
                {finishStep === 0 && (
                  <ReviewStep
                    movePrev={moveStep}
                    moveNext={setIsOpenConfirmSubmitModal}
                    currentCourseListRequest={currentCourseListRequest}
                    stepDetails={formDetails}
                    enrolmentOptionList={enrolmentOptionList}
                  />
                )}
                {finishStep === 1 && isValidReCaptcha && <FormSubmitSuccess />}
              </Box>
            ) : (
              <Box className="stepper-container">
                <Box className="stepper-content-container">
                  {activeStep === FormSteps.CollegeForm && (
                    <FormStep1
                      moveNext={moveStep}
                      stepDetails={stepData[activeStep]}
                      eligibilityCriteriaList={
                        enrolmentOptionList?.eligibilityCriteria
                      }
                    />
                  )}
                  {activeStep === FormSteps.StudentDetails && (
                    <FormStep2
                      movePrev={moveStep}
                      moveNext={moveStep}
                      stepDetails={stepData[activeStep]}
                    />
                  )}
                  {activeStep === FormSteps.StudentSupports && (
                    <FormStep3
                      movePrev={moveStep}
                      moveNext={moveStep}
                      stepDetails={stepData[activeStep]}
                      enrolmentOptionList={enrolmentOptionList}
                    />
                  )}
                  {activeStep === FormSteps.CourseSelection && (
                    <FormStep4
                      movePrev={moveStep}
                      moveNext={moveStep}
                      stepDetails={stepData[activeStep]}
                      currentCourseList={currentCourseList}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <NoCourseOfferingStep />
      )}
      {isOpenConfirmSubmitModal && (
        <ConfirmModal
          handleSubmitConfirmationModalClose={handleConfirmCloseModal}
          submitConfirmationMessage="Do you want to submit these details?"
          handleYesClick={handleYesSubmit}
        />
      )}

      <ReCaptchaModal
        isOpenReCaptchaModal={isOpenReCaptchaModal}
        handleReCaptchaModalClose={handleReCaptchaCloseModal}
        handleYesClick={handleReCaptchaSubmit}
        props={props}
      />
    </div>
  );
};

export default StudentEnrolmentForm;
