export const DOCUMENT_LIST_REQUEST = "DOCUMENT_LIST_REQUEST";
export const DOCUMENT_LIST_SUCCESS = "DOCUMENT_LIST_SUCCESS";
export const DOCUMENT_LIST_FAILURE = "DOCUMENT_LIST_FAILURE";

export const ADD_DOCUMENT_REQUEST = "ADD_DOCUMENT_REQUEST";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAILURE = "ADD_DOCUMENT_FAILURE";

export const EDIT_DOCUMENT_REQUEST = "EDIT_DOCUMENT_REQUEST";
export const EDIT_DOCUMENT_SUCCESS = "EDIT_DOCUMENT_SUCCESS";
export const EDIT_DOCUMENT_FAILURE = "EDIT_DOCUMENT_FAILURE";

export const DOCUMENT_BY_ID_REQUEST = "DOCUMENT_BY_ID_REQUEST";
export const DOCUMENT_BY_ID_SUCCESS = "DOCUMENT_BY_ID_SUCCESS";
export const DOCUMENT_BY_ID_FAILURE = "DOCUMENT_BY_ID_FAILURE";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";
