import {
  IStaffReportDetailsPayloadValues,
  IStaffReportPagePayloadValues,
} from "utils/interfaces/staff-report";
import baseService from "services/base-service";

const staffReportBaseUrl = `/api/Reports`;

const getStaffReportList = async (payload: IStaffReportPagePayloadValues) =>
  baseService.post(`${staffReportBaseUrl}/GetStaffReport`, payload);

const printStaffReport = async (payload: IStaffReportPagePayloadValues) =>
  baseService.post(`${staffReportBaseUrl}/PrintStaffReport`, payload);

const getStaffReportDetails = async (
  payload: IStaffReportDetailsPayloadValues
) => baseService.post(`${staffReportBaseUrl}/ExportStaffDetails`, payload);

export default {
  getStaffReportList,
  printStaffReport,
  getStaffReportDetails,
};
