import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  closeIcon,
  filterLightIcon,
  filterPrimaryIcon,
} from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

interface StudentData {
  name: string;
  courseEnrol: number;
  courseAttnd: number;
}

function createStaffData(
  name: string,
  courseEnrol: number,
  courseAttnd: number
): StudentData {
  return {
    name,
    courseEnrol,
    courseAttnd,
  };
}

const educatorsrow = [
  createStaffData("Shane Lincoin", 7, 1),
  createStaffData("Rachael Rogan", 9, 1),
  createStaffData("Michale Conte", 10, 1),
  createStaffData("Katherina Stufano", 9, 2),
  createStaffData("Kamara Ducki", 10, 2),
  createStaffData("Lydia Lin", 10, 3),
  createStaffData("Michale Conte", 10, 4),
  createStaffData("Katherina Stufano", 10, 2),
  createStaffData("Kamara Ducki", 10, 1),
  createStaffData("Hope Stothers", 10, 4),
  createStaffData("Michale Conte", 10, 4),
];

const DesignerReportStaff: React.FC = () => {
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [empStatus, setEmpStatus] = React.useState("");
  const [team, setTeam] = React.useState("");
  const [currentRole, setCurrentRole] = React.useState("");
  const [workEnv, setWorkEnv] = React.useState("");
  const [site, setSite] = React.useState("");

  const [filterbox, setFilterbox] = React.useState(false);

  const [exportMenu, setExportMenu] = React.useState<null | HTMLElement>(null);
  const exportOpen = Boolean(exportMenu);
  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenu(event.currentTarget);
  };
  const handleExportClose = () => {
    setExportMenu(null);
  };

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Box
        className="filter-overlay"
        onClick={() => {
          document.body.classList.toggle("filter-open");
          setFilterbox(false);
        }}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        <div className="main-content">
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Report - Staff
            </Typography>
            <Box sx={{ display: "flex", columnGap: "15px" }}>
              <Button
                id="export-btn"
                aria-controls={exportOpen ? "export-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={exportOpen ? "true" : undefined}
                onClick={handleExportClick}
                disableElevation
                variant="outlined"
                className="btn-export"
              >
                Export
              </Button>
              <Menu
                id="export-menu"
                anchorEl={exportMenu}
                open={exportOpen}
                onClose={handleExportClose}
                MenuListProps={{
                  "aria-labelledby": "export-button",
                }}
                className="export-menu"
              >
                <MenuItem onClick={handleExportClose} title="Excel">
                  <Button>Excel</Button>
                </MenuItem>
                <MenuItem onClick={handleExportClose} title="PDF">
                  <Button>PDF</Button>
                </MenuItem>
              </Menu>
              <Button
                variant="outlined"
                className="btn-filter report-filter"
                onClick={() => {
                  setFilterbox(true);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={filterPrimaryIcon} alt="filter" className="default" />
                <img src={filterLightIcon} alt="filter" className="active" />
                <span className="text">Filter</span>
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            width: "370px",
                            minWidth: "240px",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "206px", minWidth: "136px" }}
                        >
                          Course Enrolled
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "206px", minWidth: "160px" }}
                        >
                          Course Attended
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {educatorsrow.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell>{row.courseEnrol}</TableCell>
                          <TableCell>{row.courseAttnd}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                    <Typography variant="body2" component="span">
                      Showing 10 - 20 of 50 entries
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={5}
                    variant="outlined"
                    shape="rounded"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
          <DesignerFooter />
        </div>
      </main>

      {filterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="select">
              <InputLabel id="empStatus-select-label">
                Employment Status
              </InputLabel>
              <Select
                labelId="empStatus-select-label"
                id="empStatus-select"
                label="Employment Status"
                value={empStatus}
                onChange={(event: SelectChangeEvent) => {
                  setEmpStatus(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Full-time</MenuItem>
                <MenuItem value={2}>Part-time</MenuItem>
                <MenuItem value={3}>Student</MenuItem>
                <MenuItem value={4}>Contractor</MenuItem>
                <MenuItem value={5}>Volunteer</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="team-select-label">Team</InputLabel>
              <Select
                labelId="team-select-label"
                id="team-select"
                label="Team"
                value={team}
                onChange={(event: SelectChangeEvent) => {
                  setTeam(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Sutherland Hospital</MenuItem>
                <MenuItem value={2}>POW Hospital</MenuItem>
                <MenuItem value={3}>St George Hospital</MenuItem>
                <MenuItem value={4}>Mental Health District</MenuItem>
                <MenuItem value={5}>
                  Eastern Suburbs Mental Health Service
                </MenuItem>
                <MenuItem value={6}>Sutherland Mental Health Service</MenuItem>
                <MenuItem value={7}>St George Mental Health Service</MenuItem>
                <MenuItem value={8}>
                  Eastern Suburbs Community Mental Health
                </MenuItem>
                <MenuItem value={9}>
                  Sutherland Community Mental Health
                </MenuItem>
                <MenuItem value={10}>
                  St George Community Mental Health
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="currentRole-select-label">
                Current Role
              </InputLabel>
              <Select
                labelId="currentRole-select-label"
                id="currentRole-select"
                label="Current Role"
                value={currentRole}
                onChange={(event: SelectChangeEvent) => {
                  setCurrentRole(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Staff from Partner Service</MenuItem>
                <MenuItem value={2}>SESLHD Clinical/Manager</MenuItem>
                <MenuItem value={3}>SESLHD Consumer/Peer Worker</MenuItem>
                <MenuItem value={4}>SESLHD Other</MenuItem>
                <MenuItem value={5}>SESLHD Recovery College</MenuItem>
                <MenuItem value={6}>SESLHD Administration</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="workEnv-select-label">
                Work Environment
              </InputLabel>
              <Select
                labelId="workEnv-select-label"
                id="workEnv-select"
                label="Work Environment"
                value={workEnv}
                onChange={(event: SelectChangeEvent) => {
                  setWorkEnv(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>Community</MenuItem>
                <MenuItem value={2}>Partner Service</MenuItem>
                <MenuItem value={3}>Inpatient - Rehabilitation</MenuItem>
                <MenuItem value={4}>Inpatient - Acute</MenuItem>
                <MenuItem value={5}>Acute Team Community</MenuItem>
                <MenuItem value={6}>Recovery College</MenuItem>
                <MenuItem value={7}>Older Adult MH</MenuItem>
                <MenuItem value={8}>Child & Adolescent MH</MenuItem>
                <MenuItem value={9}>Inpatient - General</MenuItem>
                <MenuItem value={10}>Drug & Alcohol</MenuItem>
                <MenuItem value={11}>Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="site-select-label">Site</InputLabel>
              <Select
                labelId="site-select-label"
                id="site-select"
                label="Site"
                value={site}
                onChange={(event: SelectChangeEvent) => {
                  setSite(event.target.value as string);
                }}
                MenuProps={{
                  className: "filter-select-menu",
                }}
              >
                <MenuItem value={1}>STGH/St George Community</MenuItem>
                <MenuItem value={2}>POWH/Eastern Suburbs Community</MenuItem>
                <MenuItem value={3}>TSH/Sutherland Community</MenuItem>
                <MenuItem value={4}>Partner Service</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default DesignerReportStaff;
