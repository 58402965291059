/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import {
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  IAddEditEducator,
  IEducatorContainerDispatch,
  IEducatorContainerState,
} from "utils/interfaces/educator";
import {
  courseListIcon,
  courseListLightIcon,
  courseListSecondaryIcon,
  saveIcon,
} from "assets/images";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  EmailPattern,
  checkEmailEqualSecondaryEmail,
  getPagePermissions,
  handlePasteNumberField,
  onNumberFieldKeyPress,
  showLoader,
} from "utils/helper";
import {
  ALPHABETIC_INPUT_REGEX,
  EDUCATOR_ROLE_ID,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  STUDENT_ROLE_ID,
} from "utils/constants/constant";
import {
  COURSE_DELIVERY_LANGUAGE,
  EDUCATOR_AVAILABILITY_MAX_LENGTH,
  EDUCATOR_CITY_MAX_LENGTH,
  EDUCATOR_EMAIL_MAX_LENGTH,
  EDUCATOR_EMERGENCY_CONTACT_MAX_LENGTH,
  EDUCATOR_EMERGENCY_CONTACT_RELATION_MAX_LENGTH,
  EDUCATOR_FIRST_NAME_MAX_LENGTH,
  EDUCATOR_LAST_NAME_MAX_LENGTH,
  EDUCATOR_OTHER_WORKPLACE_MAX_LENGTH,
  EDUCATOR_PHONE_MAX_LENGTH,
  EDUCATOR_SERVICE,
  EDUCATOR_SKILLS_MAX_LENGTH,
  EDUCATOR_STATE_MAX_LENGTH,
  EDUCATOR_STATUS,
  EDUCATOR_STREET_ADDRESS_MAX_LENGTH,
  EDUCATOR_SUBURB_MAX_LENGTH,
  EDUCATOR_TYPES,
  STUDENT_PROFILE,
} from "utils/constants/educator";
import {
  AddEditEducatorSuccessPayload,
  EducatorByIdSuccessPayload,
} from "store/educator/types";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { EducatorStatus } from "utils/enums/educator";
import { AppRoutings } from "utils/enums/app-routings";
import {
  ALPHABETIC_VALIDATION_MSG,
  EDUCATOR_ADD_SUCCESS_MSG,
  EDUCATOR_EDIT_SUCCESS_MSG,
  SECONDARY_EMAIL_SHOULD_NOT_BE_EQUAL_TO_EMAIL,
  WHITE_SPACE_ERROR_SPACE,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER,
} from "utils/constants/Messages";
import { AppPages } from "utils/enums/app-pages";

export type AddEditEducatorProps = IEducatorContainerState &
  IEducatorContainerDispatch;

const AddEditEducator: React.FC<AddEditEducatorProps> = (props) => {
  const location = useLocation<{
    educatorId: number;
    courseDelivered: number;
    courseAttended: number;
  }>();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm<IAddEditEducator>({
    defaultValues: {
      roleIds: [EDUCATOR_ROLE_ID],
      status: null,
      educatorType: null,
      service: null,
      courseDeliveryLanguage: null,
      studentProfileLink: null,
    },
  });

  const setFormValues = (educator: any) => {
    reset((formValues) => ({
      ...formValues,
      id: educator.id,
      userID: educator.userID,
      firstName: educator.firstName,
      lastName: educator.lastName,
      emailAddress: educator.emailAddress,
      secondaryEmail: educator.secondaryEmail,
      roleIds: educator.roleIds,
      status: educator.status,
      educatorType: educator.educatorType,
      streetAddress: educator.streetAddress,
      suburb: educator.suburb,
      postCode: educator.postCode,
      state: educator.state,
      streetAddress2Line1: educator.streetAddress2Line1,
      streetAddress2Line2: educator.streetAddress2Line2,
      city: educator.city,
      state2: educator.state2,
      zip: educator.zip,
      service: educator.service,
      otherWorkplace: educator.otherWorkplace,
      dayTimeBusinessPhone: educator.dayTimeBusinessPhone,
      afterHoursPersonalPhone: educator.afterHoursPersonalPhone,
      availability: educator.availability,
      emergencyContact: educator.emergencyContact,
      emergencyContactRelationship: educator.emergencyContactRelationship,
      emergencyContactNumber: educator.emergencyContactNumber,
      skillsExperienceInterest: educator.skillsExperienceInterest,
      courseDeliveryLanguage: educator.courseDeliveryLanguage,
      studentProfileLink: educator.studentProfileLink,
    }));
  };

  const onEducatorByIdSuccess = (response: EducatorByIdSuccessPayload) => {
    setFormValues(response.educatorDetails);
  };

  const getEducatorDetails = () => {
    const { educatorByIdRequest } = props;

    if (educatorByIdRequest) {
      showLoader();
      const { educatorId } = location.state;

      const payload = {
        values: { id: educatorId },
        callback: onEducatorByIdSuccess,
      };

      educatorByIdRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.Educators
    );

    if (location.state?.educatorId) {
      if (permission?.edit) {
        getEducatorDetails();
      } else {
        history.goBack();
      }
    }
    if (
      (window.location.pathname.includes("add") && !permission?.add) ||
      (window.location.pathname.includes("edit") && !permission?.edit)
    ) {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (watch("roleIds").length > 0) {
      trigger("roleIds");
    }
    if (
      watch("status") === EducatorStatus.Inactive ||
      watch("status") === EducatorStatus.Active
    ) {
      trigger("status");
    }
    if (watch("educatorType")) {
      trigger("educatorType");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("roleIds"), watch("status"), watch("educatorType")]);

  const getFirstNameError = (): string => {
    if (errors.firstName) {
      if (errors.firstName.type === "required") {
        return "First name is required";
      }
      if (errors.firstName.type === "maxLength") {
        return `Maximum length of first name should be ${EDUCATOR_FIRST_NAME_MAX_LENGTH}`;
      }
      if (errors.firstName.type === "pattern") {
        return `First name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getLastNameError = (): string => {
    if (errors.lastName) {
      if (errors.lastName.type === "required") {
        return "Last name is required";
      }
      if (errors.lastName.type === "maxLength") {
        return `Maximum length of last name should be ${EDUCATOR_LAST_NAME_MAX_LENGTH}`;
      }
      if (errors.lastName.type === "pattern") {
        return `Last name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getEmailAddressError = (): string => {
    if (errors.emailAddress) {
      if (errors.emailAddress.type === "required") {
        return "Email is required";
      }
      if (errors.emailAddress.type === "pattern") {
        return "Enter valid email";
      }
      if (errors.emailAddress.type === "maxLength") {
        return `Maximum length of email should be ${EDUCATOR_EMAIL_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getSecondaryEmailError = (): string => {
    if (errors.secondaryEmail) {
      if (errors.secondaryEmail.type === "pattern") {
        return "Enter valid email";
      }
      if (errors.secondaryEmail.type === "maxLength") {
        return `Maximum length of secondary email should be ${EDUCATOR_EMAIL_MAX_LENGTH}`;
      }
      if (errors.secondaryEmail.type === "validate") {
        return SECONDARY_EMAIL_SHOULD_NOT_BE_EQUAL_TO_EMAIL;
      }
    }

    return "";
  };

  const getStatusError = (): string => {
    if (errors.status) {
      if (errors.status.type === "required") {
        return "Status is required";
      }
    }

    return "";
  };

  const getEducatorTypeError = (): string => {
    if (errors.educatorType) {
      if (errors.educatorType.type === "required") {
        return "Educator type is required";
      }
    }

    return "";
  };

  const getStreetAddressError = (): string => {
    if (errors.streetAddress) {
      if (errors.streetAddress.type === "maxLength") {
        return `Maximum length of street address should be ${EDUCATOR_STREET_ADDRESS_MAX_LENGTH}`;
      }
      if (errors.streetAddress.type === "pattern") {
        return `Street address ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getSuburbError = (): string => {
    if (errors.suburb) {
      if (errors.suburb.type === "maxLength") {
        return `Maximum length of suburb should be ${EDUCATOR_SUBURB_MAX_LENGTH}`;
      }
      if (errors.suburb.type === "pattern") {
        return `Suburb ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getStateError = (): string => {
    if (errors.state) {
      if (errors.state.type === "maxLength") {
        return `Maximum length of state should be ${EDUCATOR_STATE_MAX_LENGTH}`;
      }
      if (errors.state.type === "pattern") {
        return `State ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getStreetAddress2Line1Error = (): string => {
    if (errors.streetAddress2Line1) {
      if (errors.streetAddress2Line1.type === "maxLength") {
        return `Maximum length of street address should be ${EDUCATOR_STREET_ADDRESS_MAX_LENGTH}`;
      }
      if (errors.streetAddress2Line1.type === "pattern") {
        return `Street address ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getStreetAddress2Line2Error = (): string => {
    if (errors.streetAddress2Line2) {
      if (errors.streetAddress2Line2.type === "maxLength") {
        return `Maximum length of street address 2 should be ${EDUCATOR_STREET_ADDRESS_MAX_LENGTH}`;
      }
      if (errors.streetAddress2Line2.type === "pattern") {
        return `Street address 2 ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getCityError = (): string => {
    if (errors.city) {
      if (errors.city.type === "maxLength") {
        return `Maximum length of city should be ${EDUCATOR_CITY_MAX_LENGTH}`;
      }
      if (errors.city.type === "pattern") {
        return `City ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getState2Error = (): string => {
    if (errors.state2) {
      if (errors.state2.type === "maxLength") {
        return `Maximum length of state should be ${EDUCATOR_STATE_MAX_LENGTH}`;
      }
      if (errors.state2.type === "pattern") {
        return `State ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getOtherWorkplaceError = (): string => {
    if (errors.otherWorkplace) {
      if (errors.otherWorkplace.type === "maxLength") {
        return `Maximum length of other workplace should be ${EDUCATOR_OTHER_WORKPLACE_MAX_LENGTH}`;
      }
      if (errors.otherWorkplace.type === "pattern") {
        return `Other workplace ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getDayTimeBusinessPhoneError = (): string => {
    if (errors.dayTimeBusinessPhone) {
      if (errors.dayTimeBusinessPhone.type === "maxLength") {
        return `Maximum length of daytime / business phone should be ${EDUCATOR_PHONE_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getAfterHoursPersonalPhoneError = (): string => {
    if (errors.afterHoursPersonalPhone) {
      if (errors.afterHoursPersonalPhone.type === "maxLength") {
        return `Maximum length of after hours / personal phone should be ${EDUCATOR_PHONE_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getAvailabilityError = (): string => {
    if (errors.availability) {
      if (errors.availability.type === "maxLength") {
        return `Maximum length of availability should be ${EDUCATOR_AVAILABILITY_MAX_LENGTH}`;
      }
      if (errors.availability.type === "pattern") {
        return `Availability ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getEmergencyContactError = (): string => {
    if (errors.emergencyContact) {
      if (errors.emergencyContact.type === "maxLength") {
        return `Maximum length of emergency contact should be ${EDUCATOR_EMERGENCY_CONTACT_MAX_LENGTH}`;
      }
      if (errors.emergencyContact.type === "pattern") {
        return `Name of contact person ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getEmergencyContactRelationshipError = (): string => {
    if (errors.emergencyContactRelationship) {
      if (errors.emergencyContactRelationship.type === "maxLength") {
        return `Maximum length of emergency contact relationship should be ${EDUCATOR_EMERGENCY_CONTACT_RELATION_MAX_LENGTH}`;
      }
      if (errors.emergencyContactRelationship.type === "pattern") {
        return `Relationship ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getEmergencyContactNumberError = (): string => {
    if (errors.emergencyContactNumber) {
      if (errors.emergencyContactNumber.type === "maxLength") {
        return `Maximum length of emergency contact phone should be ${EDUCATOR_PHONE_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getSkillsError = (): string => {
    if (errors.skillsExperienceInterest) {
      if (errors.skillsExperienceInterest.type === "maxLength") {
        return `Maximum length of skills/ experience/ interests should be ${EDUCATOR_SKILLS_MAX_LENGTH}`;
      }
      if (errors.skillsExperienceInterest.type === "pattern") {
        return `skills/ experience/ interests ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const onAddEditEducatorSuccess = (
    response: AddEditEducatorSuccessPayload
  ) => {
    if (response.success) {
      if (location.state?.educatorId) {
        toast.success(EDUCATOR_EDIT_SUCCESS_MSG);
      } else {
        toast.success(EDUCATOR_ADD_SUCCESS_MSG);
      }

      history.push(AppRoutings.Educators);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const getPayloadValues = (data: IAddEditEducator) => {
    return {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      emailAddress: data.emailAddress.trim(),
      secondaryEmail: data.secondaryEmail,
      roleIds: location.state?.educatorId ? data.roleIds : [EDUCATOR_ROLE_ID],
      status: Number(data.status),
      educatorType: Number(data.educatorType),
      streetAddress: data.streetAddress,
      suburb: data.suburb,
      postCode: data.postCode ? Number(data.postCode) : null,
      state: data.state,
      streetAddress2Line1: data.streetAddress2Line1,
      streetAddress2Line2: data.streetAddress2Line2,
      city: data.city,
      state2: data.state2,
      zip: data.zip ? Number(data.zip) : null,
      service: data.service ? Number(data.service) : null,
      otherWorkplace: data.otherWorkplace,
      dayTimeBusinessPhone: data.dayTimeBusinessPhone,
      afterHoursPersonalPhone: data.afterHoursPersonalPhone,
      availability: data.availability,
      emergencyContact: data.emergencyContact,
      emergencyContactRelationship: data.emergencyContactRelationship,
      emergencyContactNumber: data.emergencyContactNumber,
      skillsExperienceInterest: data.skillsExperienceInterest,
      courseDeliveryLanguage: data.courseDeliveryLanguage
        ? Number(data.courseDeliveryLanguage)
        : null,
      studentProfileLink: data.studentProfileLink
        ? Number(data.studentProfileLink)
        : null,
    };
  };

  const onSubmit = async (data: IAddEditEducator) => {
    if (location.state?.educatorId) {
      const { editEducatorRequest } = props;
      if (editEducatorRequest) {
        showLoader();
        const editPayload = getPayloadValues(data);
        const payload = {
          values: {
            id: data.id,
            userID: data.userID,
            ...editPayload,
          },
          callback: onAddEditEducatorSuccess,
        };
        editEducatorRequest(payload);
      }
    } else {
      const { addEducatorRequest } = props;
      if (addEducatorRequest) {
        showLoader();
        const payload = {
          values: getPayloadValues(data),
          callback: onAddEditEducatorSuccess,
        };
        addEducatorRequest(payload);
      }
    }
  };

  const onClickCourseDelivered = () => {
    history.push({
      pathname: AppRoutings.CoursesDelivered,
      state: {
        educatorData: {
          id: Number(watch("id")),
          name: `${watch("firstName")} ${watch("lastName")}`,
          emailAddress: watch("emailAddress"),
        },
      },
    });
  };

  const hasStudentRole = watch("roleIds").includes(STUDENT_ROLE_ID);

  const onClickCourseAttended = () => {
    history.push({
      pathname: AppRoutings.CoursesAttendedAsStudent,
      state: {
        educatorData: {
          id: Number(watch("userID")),
          name: `${watch("firstName")} ${watch("lastName")}`,
          emailAddress: watch("emailAddress"),
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            {" "}
            {location.state?.educatorId
              ? "Edit Educator Details"
              : "Add Educator Details"}
          </Typography>
          <Button
            variant="outlined"
            className="btn-back"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Card>
              <Grid
                container
                columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
              >
                <Grid item xs={12} lg={5}>
                  <Grid
                    container
                    columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                  >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="first-name"
                        label={
                          <>
                            First Name <span className="color-red">*</span>
                          </>
                        }
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: Boolean(watch("firstName")),
                        }}
                        error={!!errors.firstName}
                        helperText={getFirstNameError()}
                        {...register("firstName", {
                          required: true,
                          maxLength: EDUCATOR_FIRST_NAME_MAX_LENGTH,
                          pattern: ALPHABETIC_INPUT_REGEX,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="last-name"
                        label={
                          <>
                            Last Name <span className="color-red">*</span>
                          </>
                        }
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: Boolean(watch("lastName")),
                        }}
                        error={!!errors.lastName}
                        helperText={getLastNameError()}
                        {...register("lastName", {
                          required: true,
                          maxLength: EDUCATOR_LAST_NAME_MAX_LENGTH,
                          pattern: ALPHABETIC_INPUT_REGEX,
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <Grid
                    container
                    columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                  >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="email"
                        label={
                          <>
                            Email <span className="color-red">*</span>
                          </>
                        }
                        fullWidth
                        variant="outlined"
                        disabled={!!location.state?.educatorId}
                        InputLabelProps={{
                          shrink: Boolean(watch("emailAddress")),
                        }}
                        error={!!errors.emailAddress}
                        helperText={getEmailAddressError()}
                        {...register("emailAddress", {
                          required: true,
                          pattern: EmailPattern,
                          maxLength: EDUCATOR_EMAIL_MAX_LENGTH,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="secondary-email"
                        label="Secondary Email"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: Boolean(watch("secondaryEmail")),
                        }}
                        error={!!errors.secondaryEmail}
                        helperText={getSecondaryEmailError()}
                        {...register("secondaryEmail", {
                          pattern: EmailPattern,
                          maxLength: EDUCATOR_EMAIL_MAX_LENGTH,
                          validate: (value) =>
                            !checkEmailEqualSecondaryEmail(
                              watch("emailAddress"),
                              value
                            ),
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <TextField
                    id="role"
                    label="Role"
                    fullWidth
                    variant="outlined"
                    disabled
                    value="Educator"
                  />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <Grid
                    container
                    columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                  >
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth className="select">
                        <InputLabel
                          id="status-label"
                          shrink={
                            watch("status") === EducatorStatus.Inactive ||
                            watch("status") === EducatorStatus.Active
                          }
                        >
                          Status <span className="color-red">*</span>
                        </InputLabel>
                        <Select
                          labelId="status-label"
                          id="status"
                          label="Status"
                          value={watch("status")}
                          error={!!errors.status}
                          {...register("status", {
                            required: true,
                          })}
                        >
                          {EDUCATOR_STATUS?.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors.status && (
                          <FormHelperText error>
                            {getStatusError()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth className="select">
                        <InputLabel
                          id="educator-type-label"
                          shrink={!!watch("educatorType")}
                        >
                          Educator Type <span className="color-red">*</span>
                        </InputLabel>
                        <Select
                          labelId="educator-type-label"
                          id="educator-type"
                          label="Educator Type"
                          input={<OutlinedInput label="Educator Type" />}
                          value={watch("educatorType")}
                          error={!!errors.educatorType}
                          {...register("educatorType", {
                            required: true,
                          })}
                          MenuProps={{
                            className: "educator-type-select",
                          }}
                        >
                          {EDUCATOR_TYPES.map((educator) => (
                            <MenuItem
                              key={educator.value}
                              value={educator.value}
                            >
                              {educator.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors.educatorType && (
                          <FormHelperText error>
                            {getEducatorTypeError()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <TextField
                    id="street-add"
                    label="Street Address"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("streetAddress")),
                    }}
                    error={!!errors.streetAddress}
                    helperText={getStreetAddressError()}
                    {...register("streetAddress", {
                      maxLength: EDUCATOR_STREET_ADDRESS_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <Grid
                    container
                    columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                  >
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="suburb"
                        label="Suburb"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: Boolean(watch("suburb")),
                        }}
                        error={!!errors.suburb}
                        helperText={getSuburbError()}
                        {...register("suburb", {
                          maxLength: EDUCATOR_SUBURB_MAX_LENGTH,
                          pattern:
                            INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="postcode"
                        label="Postcode"
                        type="number"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: Boolean(watch("postCode")),
                        }}
                        onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                        onPaste={handlePasteNumberField}
                        {...register("postCode")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="state"
                        label="State"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: Boolean(watch("state")),
                        }}
                        error={!!errors.state}
                        helperText={getStateError()}
                        {...register("state", {
                          maxLength: EDUCATOR_STATE_MAX_LENGTH,
                          pattern:
                            INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" className="address-line-2">
                    Address Line 2
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="street-add-2"
                    label="Street Address"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("streetAddress2Line1")),
                    }}
                    error={!!errors.streetAddress2Line1}
                    helperText={getStreetAddress2Line1Error()}
                    {...register("streetAddress2Line1", {
                      maxLength: EDUCATOR_STREET_ADDRESS_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id="street-add-2"
                    label="Street Address 2"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("streetAddress2Line2")),
                    }}
                    error={!!errors.streetAddress2Line2}
                    helperText={getStreetAddress2Line2Error()}
                    {...register("streetAddress2Line2", {
                      maxLength: EDUCATOR_STREET_ADDRESS_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Grid
                    container
                    columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                  >
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="city"
                        label="City"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: Boolean(watch("city")),
                        }}
                        error={!!errors.city}
                        helperText={getCityError()}
                        {...register("city", {
                          maxLength: EDUCATOR_CITY_MAX_LENGTH,
                          pattern:
                            INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="state"
                        label="State"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: Boolean(watch("state2")),
                        }}
                        error={!!errors.state2}
                        helperText={getState2Error()}
                        {...register("state2", {
                          maxLength: EDUCATOR_STATE_MAX_LENGTH,
                          pattern:
                            INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id="zip"
                        label="Zip"
                        type="number"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: Boolean(watch("zip")),
                        }}
                        onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                        onPaste={handlePasteNumberField}
                        {...register("zip")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="service-label" shrink={!!watch("service")}>
                      Service
                    </InputLabel>
                    <Select
                      labelId="service-label"
                      id="service"
                      label="Service"
                      value={watch("service")}
                      {...register("service")}
                    >
                      {EDUCATOR_SERVICE?.map((service) => (
                        <MenuItem key={service.value} value={service.value}>
                          {service.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    id="other-wrkspace"
                    label="Other Workplace"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("otherWorkplace")),
                    }}
                    error={!!errors.otherWorkplace}
                    helperText={getOtherWorkplaceError()}
                    {...register("otherWorkplace", {
                      maxLength: EDUCATOR_OTHER_WORKPLACE_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    id="buss-phone"
                    label="Daytime / Business Phone"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("dayTimeBusinessPhone")),
                    }}
                    onKeyPress={onNumberFieldKeyPress}
                    onPaste={handlePasteNumberField}
                    error={!!errors.dayTimeBusinessPhone}
                    helperText={getDayTimeBusinessPhoneError()}
                    {...register("dayTimeBusinessPhone", {
                      maxLength: EDUCATOR_PHONE_MAX_LENGTH,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    id="prsnl-phone"
                    label="After Hours / Personal Phone"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("afterHoursPersonalPhone")),
                    }}
                    onKeyPress={onNumberFieldKeyPress}
                    onPaste={handlePasteNumberField}
                    error={!!errors.afterHoursPersonalPhone}
                    helperText={getAfterHoursPersonalPhoneError()}
                    {...register("afterHoursPersonalPhone", {
                      maxLength: EDUCATOR_PHONE_MAX_LENGTH,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="availability"
                    label="Availability"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("availability")),
                    }}
                    error={!!errors.availability}
                    helperText={getAvailabilityError()}
                    {...register("availability", {
                      maxLength: EDUCATOR_AVAILABILITY_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="emergency-ctnt"
                    label="Emergency Contact"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("emergencyContact")),
                    }}
                    error={!!errors.emergencyContact}
                    helperText={getEmergencyContactError()}
                    {...register("emergencyContact", {
                      maxLength: EDUCATOR_EMERGENCY_CONTACT_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="emergency-ctnt-rln"
                    label="Emergency Contact Relationship"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("emergencyContactRelationship")),
                    }}
                    error={!!errors.emergencyContactRelationship}
                    helperText={getEmergencyContactRelationshipError()}
                    {...register("emergencyContactRelationship", {
                      maxLength: EDUCATOR_EMERGENCY_CONTACT_RELATION_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="emergency-ctnt-phone"
                    label="Emergency Contact Phone"
                    type="number"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("emergencyContactNumber")),
                    }}
                    onKeyPress={onNumberFieldKeyPress}
                    onPaste={handlePasteNumberField}
                    error={!!errors.emergencyContactNumber}
                    helperText={getEmergencyContactNumberError()}
                    {...register("emergencyContactNumber", {
                      maxLength: EDUCATOR_PHONE_MAX_LENGTH,
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="skills"
                    className="skills-textarea"
                    label="Skills/ Experience/ Interests"
                    fullWidth
                    variant="outlined"
                    multiline
                    InputLabelProps={{
                      shrink: Boolean(watch("skillsExperienceInterest")),
                    }}
                    error={!!errors.skillsExperienceInterest}
                    helperText={getSkillsError()}
                    {...register("skillsExperienceInterest", {
                      maxLength: EDUCATOR_SKILLS_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth className="select">
                    <InputLabel
                      id="lang-label"
                      shrink={!!watch("courseDeliveryLanguage")}
                    >
                      Course Delivery Language
                    </InputLabel>
                    <Select
                      labelId="lang-label"
                      id="lang"
                      label="Course Delivery Language"
                      value={watch("courseDeliveryLanguage")}
                      {...register("courseDeliveryLanguage")}
                    >
                      {COURSE_DELIVERY_LANGUAGE?.map((language) => (
                        <MenuItem key={language.value} value={language.value}>
                          {language.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} sx={{ display: "none" }}>
                  <FormControl fullWidth className="select">
                    <InputLabel
                      id="profile-link-label"
                      shrink={!!watch("studentProfileLink")}
                    >
                      Student Profile Link
                    </InputLabel>
                    <Select
                      labelId="profile-link-label"
                      id="profile-link"
                      label="Student Profile Link"
                      value={watch("studentProfileLink")}
                      {...register("studentProfileLink")}
                    >
                      {STUDENT_PROFILE?.map((profile: any) => (
                        <MenuItem key={profile?.value} value={profile?.value}>
                          {profile?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box className="btn-group">
                <Button variant="contained" className="btn-save" type="submit">
                  <img src={saveIcon} alt="save" />
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="btn-cancel"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Box>
              {location.state?.educatorId && (
                <Box className="course-details">
                  <Box>
                    <Typography variant="body1">Courses Delivered</Typography>
                    <Button
                      className="btn-course-large btn-course"
                      variant="outlined"
                      onClick={onClickCourseDelivered}
                    >
                      <img
                        src={courseListIcon}
                        alt="Course List"
                        className="default"
                      />
                      <img
                        src={courseListLightIcon}
                        alt="Course List"
                        className="active"
                      />
                      <Typography variant="body2">
                        {location?.state?.courseDelivered}
                      </Typography>
                    </Button>
                  </Box>
                  <Box>
                    <Typography variant="body1">
                      Courses Attended As Student
                    </Typography>
                    <Button
                      className="btn-course-large btn-course"
                      variant="outlined"
                      onClick={
                        hasStudentRole ? onClickCourseAttended : undefined
                      }
                      disabled={!hasStudentRole}
                    >
                      <img
                        src={
                          hasStudentRole
                            ? courseListIcon
                            : courseListSecondaryIcon
                        }
                        alt="Course List"
                        className="default"
                      />
                      <img
                        src={courseListLightIcon}
                        alt="Course List"
                        className="active"
                      />
                      <Typography variant="body2">
                        {location?.state?.courseAttended}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default AddEditEducator;
