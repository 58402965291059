import { Box } from "@mui/system";
import {
  Button,
  TextField,
  Typography,
  Card,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { saveIcon } from "assets/images";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  IAddEditCourse,
  ICourseContainerDispatch,
  ICourseContainerState,
} from "utils/interfaces/course";
import {
  createEditorStateFromHTML,
  getHTMLFromDraftEditor,
  getPagePermissions,
  handlePasteNumberField,
  onNumberFieldKeyPress,
  showLoader,
} from "utils/helper";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  COURSE_AVAILABILITY,
  COURSE_NAME_MAX_LENGTH,
  COURSE_NO_MAX_LENGTH,
  COURSE_STREAM,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
} from "utils/constants/constant";
import {
  AddEditCourseSuccessPayload,
  CourseByIdSuccessPayload,
} from "store/course/types";
import { toast } from "react-toastify";
import { AppRoutings } from "utils/enums/app-routings";
import {
  COURSE_ADD_SUCCESS_MSG,
  COURSE_EDIT_SUCCESS_MSG,
  WHITE_SPACE_ERROR_SPACE,
} from "utils/constants/Messages";
import { AppPages } from "utils/enums/app-pages";

export type AddEditCourseProps = ICourseContainerState &
  ICourseContainerDispatch;

const AddEditCourse: React.FC<AddEditCourseProps> = (props) => {
  const location = useLocation<{ courseId: number }>();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm<IAddEditCourse>({
    defaultValues: {
      stream: "",
      courseAvailableInThisTerm: 2,
    },
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [htmlDesc, setHtmlDesc] = useState<string>("");

  const getCourseNoError = (): string => {
    if (errors.courseNo) {
      if (errors.courseNo.type === "required") {
        return "Course no is required";
      }

      if (errors.courseNo.type === "maxLength") {
        return `Maximum length of course no should be ${COURSE_NO_MAX_LENGTH}`;
      }

      if (errors.courseNo.type === "pattern") {
        return `Course no. ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getCourseNameError = (): string => {
    if (errors.name) {
      if (errors.name.type === "required") {
        return "Course name is required";
      }

      if (errors.name.type === "maxLength") {
        return `Maximum length of course name should be ${COURSE_NAME_MAX_LENGTH}`;
      }

      if (errors.name.type === "pattern") {
        return `Course name ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getStreamError = (): string => {
    if (errors.stream) {
      if (errors.stream.type === "required") {
        return "Stream is required";
      }
    }

    return "";
  };

  const getPreviousNameError = (): string => {
    if (errors.previousName) {
      if (errors.previousName.type === "pattern") {
        return `Previous name ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getSkillError = (): string => {
    if (errors.skills) {
      if (errors.skills.type === "pattern") {
        return `Skill ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getKnowledgeError = (): string => {
    if (errors.knowledge) {
      if (errors.knowledge.type === "pattern") {
        return `Knowledge ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getAttitudesError = (): string => {
    if (errors.attitudes) {
      if (errors.attitudes.type === "pattern") {
        return `Attitudes ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const setFormValues = (course: any) => {
    let formattedDesc = EditorState.createEmpty();
    if (course?.description) {
      formattedDesc = createEditorStateFromHTML(course.description);
    }
    reset((formValues) => ({
      ...formValues,
      id: course.id,
      courseNo: course.courseNo,
      name: course.name,
      previousName: course.previousName,
      skills: course.skills,
      knowledge: course.knowledge,
      attitudes: course.attitudes,
      stream: course.stream,
      courseAvailableInThisTerm: course.courseAvailableInThisTerm,
      description: formattedDesc,
    }));

    if (course.noOfSession) {
      reset((formValues) => ({
        ...formValues,
        noOfSession: Number(course.noOfSession),
      }));
    }

    if (course.lengthOfSingleSession) {
      reset((formValues) => ({
        ...formValues,
        lengthOfSingleSession: Number(course.lengthOfSingleSession),
      }));
    }

    setEditorState(formattedDesc);
  };

  const onCourseByIdSuccess = (response: CourseByIdSuccessPayload) => {
    setFormValues(response.courseDetails);
  };

  const getCourseDetails = () => {
    const { courseByIdRequest } = props;

    if (courseByIdRequest) {
      showLoader();
      const { courseId } = location.state;

      const payload = {
        values: { id: courseId },
        callback: onCourseByIdSuccess,
      };

      courseByIdRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Courses);

    if (location.state?.courseId) {
      if (permission?.edit) {
        getCourseDetails();
      } else {
        history.goBack();
      }
    } else if (permission?.add && !window.location.pathname.includes("edit")) {
      //
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editorState) {
      const htmlText = getHTMLFromDraftEditor(editorState);
      setHtmlDesc(htmlText.toString());
    }
  }, [editorState]);

  useEffect(() => {
    if (watch("stream")) {
      trigger("stream");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("stream")]);

  const onAddEditCourseSuccess = (response: AddEditCourseSuccessPayload) => {
    if (response.success) {
      if (location.state?.courseId) {
        toast.success(COURSE_EDIT_SUCCESS_MSG);
      } else {
        toast.success(COURSE_ADD_SUCCESS_MSG);
      }

      history.push(AppRoutings.Courses);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const onSubmit = async (data: IAddEditCourse) => {
    if (location.state?.courseId) {
      const { editCourseRequest } = props;
      if (editCourseRequest) {
        showLoader();
        const payload = {
          values: {
            id: data.id,
            courseNo: data.courseNo.trim(),
            name: data.name.trim(),
            stream: data.stream,
            previousName: data.previousName,
            skills: data.skills,
            knowledge: data.knowledge,
            attitudes: data.attitudes,
            courseAvailableInThisTerm: data.courseAvailableInThisTerm,
            noOfSession: Number(data.noOfSession),
            lengthOfSingleSession: Number(data.lengthOfSingleSession),
            description: htmlDesc,
          },
          callback: onAddEditCourseSuccess,
        };
        editCourseRequest(payload);
      }
    } else {
      const { addCourseRequest } = props;
      if (addCourseRequest) {
        showLoader();
        const payload = {
          values: {
            courseNo: data.courseNo.trim(),
            name: data.name.trim(),
            stream: data.stream,
            previousName: data.previousName,
            skills: data.skills,
            knowledge: data.knowledge,
            attitudes: data.attitudes,
            courseAvailableInThisTerm: data.courseAvailableInThisTerm,
            noOfSession: Number(data.noOfSession),
            lengthOfSingleSession: Number(data.lengthOfSingleSession),
            description: htmlDesc,
          },
          callback: onAddEditCourseSuccess,
        };
        addCourseRequest(payload);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            {location.state?.courseId
              ? "Edit Course Details"
              : "Add Course Details"}
          </Typography>
          <Button
            variant="outlined"
            className="btn-back"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Card>
              <Grid
                container
                columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
              >
                <Grid item xs={12} sm={6} lg={4} xl={2}>
                  <TextField
                    id="course-num"
                    label={
                      <>
                        Course No. <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("courseNo")),
                    }}
                    error={!!errors.courseNo}
                    helperText={getCourseNoError()}
                    {...register("courseNo", {
                      required: true,
                      maxLength: COURSE_NO_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={5}>
                  <TextField
                    id="course"
                    label={
                      <>
                        Course <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("name")),
                    }}
                    error={!!errors.name}
                    helperText={getCourseNameError()}
                    {...register("name", {
                      required: true,
                      maxLength: COURSE_NAME_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} lg={4} xl={5}>
                  <TextField
                    id="prev-name"
                    label="Previous Name (If Any)"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("previousName")),
                    }}
                    error={!!errors.previousName}
                    helperText={getPreviousNameError()}
                    {...register("previousName", {
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="skills"
                    className="skills-textarea"
                    label="Skills"
                    fullWidth
                    variant="outlined"
                    multiline
                    InputLabelProps={{
                      shrink: Boolean(watch("skills")),
                    }}
                    {...register("skills", {
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                    helperText={getSkillError()}
                    error={!!errors.skills}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    id="knowledge"
                    className="skills-textarea"
                    label="Knowledge"
                    fullWidth
                    variant="outlined"
                    multiline
                    InputLabelProps={{
                      shrink: Boolean(watch("knowledge")),
                    }}
                    {...register("knowledge", {
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                    helperText={getKnowledgeError()}
                    error={!!errors.knowledge}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    id="attitudes"
                    className="skills-textarea"
                    label="Attitudes"
                    fullWidth
                    variant="outlined"
                    multiline
                    InputLabelProps={{
                      shrink: Boolean(watch("attitudes")),
                    }}
                    {...register("attitudes", {
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                    helperText={getAttitudesError()}
                    error={!!errors.attitudes}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="stream-label">
                      Stream <span className="color-red">*</span>
                    </InputLabel>
                    <Select
                      labelId="stream-label"
                      id="stream"
                      label="Stream"
                      value={watch("stream")}
                      error={!!errors.stream}
                      {...register("stream", {
                        required: true,
                      })}
                    >
                      {COURSE_STREAM?.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.stream && (
                      <FormHelperText error>{getStreamError()}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="available-label">
                      Course Available This term
                    </InputLabel>
                    <Select
                      labelId="available-label"
                      id="available"
                      label="Course Available This term"
                      value={watch("courseAvailableInThisTerm")}
                      {...register("courseAvailableInThisTerm")}
                    >
                      {COURSE_AVAILABILITY?.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    type="number"
                    id="course-num-2"
                    label="Usual No. Of Sessions"
                    fullWidth
                    variant="outlined"
                    onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                    onPaste={handlePasteNumberField}
                    InputLabelProps={{
                      shrink: Boolean(watch("noOfSession")),
                    }}
                    {...register("noOfSession")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <TextField
                    type="number"
                    id="session-len"
                    label="Length Of Single Session (Hrs.)"
                    fullWidth
                    variant="outlined"
                    onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                    onPaste={handlePasteNumberField}
                    InputLabelProps={{
                      shrink: Boolean(watch("lengthOfSingleSession")),
                    }}
                    {...register("lengthOfSingleSession")}
                  />
                </Grid>
              </Grid>
              <Editor
                editorState={editorState}
                onEditorStateChange={setEditorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                placeholder="Course Description"
              />

              <Box className="btn-group" sx={{ paddingTop: "30px" }}>
                <Button variant="contained" className="btn-save" type="submit">
                  <img src={saveIcon} alt="save" />
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="btn-cancel"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default AddEditCourse;
