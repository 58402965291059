import { Box } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { arrowRightIcon, closeIcon } from "assets/images";
import { convertToDateTimeWithFormat, showLoader } from "utils/helper";
// eslint-disable-next-line import/no-cycle
import {
  EnrolmentOptionListPayload,
  EnrolmentOptionListSuccessPayload,
  StudentEnrolmentDetailPayload,
  StudentEnrolmentDetailSuccessPayload,
} from "store/studentEnrolment/types";
import {
  IEnrolmentOptions,
  IStudentDetailData,
} from "utils/interfaces/student-enrolment";
import {
  CLOSE_TOOLTIP_TITLE,
  DATE_PICKER_FORMAT,
} from "utils/constants/constant";

interface IStudentDetailsModalProps {
  handleStudentDetailsModalClose: any;
  handleApprovedClick: any;
  studentId: number;
  studentEnrolmentDetailRequest: (payload: StudentEnrolmentDetailPayload) => {};
  enrolmentOptionListRequest: (payload: EnrolmentOptionListPayload) => {};
}

const StudentDetailsModal: React.FC<IStudentDetailsModalProps> = ({
  handleStudentDetailsModalClose,
  handleApprovedClick,
  studentId,
  studentEnrolmentDetailRequest,
  enrolmentOptionListRequest,
}) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [studentDetail, setStudentDetail] = useState<IStudentDetailData>();
  const [enrolmentOptionList, setEnrolmentOptionList] =
    useState<IEnrolmentOptions>();

  const handleAccordChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const onEnrolmentOptionListSuccess = (
    response: EnrolmentOptionListSuccessPayload
  ) => {
    setEnrolmentOptionList(response.enrolmentOptionList);
  };

  const getEnrolmentOptionList = () => {
    if (enrolmentOptionListRequest) {
      showLoader();

      const payload = {
        values: {},
        callback: onEnrolmentOptionListSuccess,
      };

      enrolmentOptionListRequest(payload);
    }
  };

  const onStudentEnrolmentDetailSuccess = (
    response: StudentEnrolmentDetailSuccessPayload
  ) => {
    setStudentDetail(response.studentDetail);
  };
  const getStudentEnrolmentDetail = (studentDetailId: number) => {
    if (studentEnrolmentDetailRequest) {
      showLoader();
      const payload = {
        values: {
          id: studentDetailId,
        },
        callback: onStudentEnrolmentDetailSuccess,
      };
      studentEnrolmentDetailRequest(payload);
    }
  };
  useEffect(() => {
    getStudentEnrolmentDetail(studentId);
    getEnrolmentOptionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open onClose={handleStudentDetailsModalClose}>
      <Box className="common-modal std-details-modal delete-modal">
        <Box className="modal-header">
          <Typography variant="h2">Student Details</Typography>
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={CLOSE_TOOLTIP_TITLE}
            arrow
          >
            <IconButton onClick={handleStudentDetailsModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="modal-body">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography variant="h3">Student Details</Typography>
              <img src={arrowRightIcon} alt="Arrow" />
            </AccordionSummary>
            <AccordionDetails>
              <Box className="acc-details-container">
                <Box className="eligibility">
                  <Typography variant="h4">Enrolment Eligibility</Typography>
                  <Typography variant="h6">
                    {
                      enrolmentOptionList?.eligibilityCriteria.find(
                        (option) =>
                          option.value === studentDetail?.eligibilityCriteria
                      )?.label
                    }
                  </Typography>
                </Box>
                <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                  personal details
                </Typography>
                <Box className="terms-read-only">
                  <Grid
                    container
                    columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                    rowSpacing={{ xs: "16px", lg: "20px" }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Student Name
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {studentDetail?.firstName} {studentDetail?.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Date Of Birth
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {convertToDateTimeWithFormat(
                          studentDetail?.dateOfBirth,
                          DATE_PICKER_FORMAT
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Phone Number
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {studentDetail?.phoneNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Email Address
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {studentDetail?.emailAddress}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Street Address
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {studentDetail?.streetAddress}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Suburb
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {studentDetail?.suburb}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        State
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {studentDetail?.state}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Post Code
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {studentDetail?.postCode}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography variant="h3">
                Student Supports and Emergency Contact Details
              </Typography>
              <img src={arrowRightIcon} alt="Arrow" />
            </AccordionSummary>
            <AccordionDetails>
              <Box className="acc-details-container">
                <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                  Emergency details
                </Typography>
                <Box className="terms-read-only">
                  <Grid
                    container
                    columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                    rowSpacing={{ xs: "16px", lg: "20px" }}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Contact Name
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {
                          studentDetail?.studentEnrolmentDTO
                            ?.emergencyContactName
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Contact Relationship
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {
                          studentDetail?.studentEnrolmentDTO
                            ?.emergencyContactRelationship
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="body2" className="detail-name">
                        Contact Phone
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {
                          studentDetail?.studentEnrolmentDTO
                            ?.emergencyContactNumber
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" className="detail-name">
                        Information to help us support you
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {studentDetail?.studentEnrolmentDTO?.information || "-"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" className="detail-name">
                        Enrolment type
                      </Typography>
                      <Typography variant="body1" className="detail-score">
                        {studentDetail?.studentEnrolmentDTO?.enrolmentType}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                {studentDetail?.studentEnrolmentDTO?.isStaff ? (
                  <>
                    <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                      Staff details
                    </Typography>
                    <Box className="terms-read-only">
                      <Grid
                        container
                        columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                        rowSpacing={{ xs: "16px", lg: "20px" }}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body2"
                            className="detail-name"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            Employment Status
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.staffEnrolmentDTO?.employmentStatus}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Current Role
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.staffEnrolmentDTO?.currentRole}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Work Environment
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.staffEnrolmentDTO?.workEnvironment}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Site/Service
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.staffEnrolmentDTO?.site}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Manager&#39;s Name
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.staffEnrolmentDTO?.managerName ||
                              "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Manager&#39;s Email
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.staffEnrolmentDTO?.managerEmail ||
                              "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                      Questionnaire
                    </Typography>
                    <Box className="terms-read-only">
                      <Grid
                        container
                        columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                        rowSpacing={{ xs: "16px", lg: "20px" }}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body2"
                            className="detail-name"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            How did you hear about the Recovery & Wellbeing
                            College?*
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {
                              studentDetail?.studentProfileDetailsDTO
                                ?.hearAboutRC
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" className="detail-name">
                            Aboriginal/Torres strait islander identity
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {
                              studentDetail?.studentProfileDetailsDTO
                                ?.atsiIdentity
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Disability
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {
                              studentDetail?.studentProfileDetailsDTO
                                ?.disability
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Country of birth
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {
                              studentDetail?.studentProfileDetailsDTO
                                ?.countryOfBirth
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Language at home
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.studentProfileDetailsDTO
                              ?.languageAtHome || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Ethnicity
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.studentProfileDetailsDTO
                              ?.ethnicity || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Gender identity
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {
                              studentDetail?.studentProfileDetailsDTO
                                ?.genderIdentity
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Completed Qualifications
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {
                              studentDetail?.studentProfileDetailsDTO
                                ?.completedQualifications
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Employment status
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {
                              enrolmentOptionList?.employmentStatus.find(
                                (option) =>
                                  option.value ===
                                  studentDetail?.studentProfileDetailsDTO
                                    ?.employmentStatus
                              )?.label
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Highest completed school level
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {
                              studentDetail?.studentProfileDetailsDTO
                                ?.highestCompletedSchoolLevel
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Currently Studying
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.studentProfileDetailsDTO?.isStuding}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Typography variant="body2" className="detail-name">
                            Study load
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            {studentDetail?.studentProfileDetailsDTO?.studyLoad}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography variant="h3">Student selected Courses</Typography>
              <img src={arrowRightIcon} alt="Arrow" />
            </AccordionSummary>
            <AccordionDetails>
              <Box className="acc-details-container">
                <List>
                  {studentDetail?.studentCourseEnrolmentDTO.map(
                    (courseDetail) => (
                      <ListItem
                        key={courseDetail.courseOfferingId}
                        disableGutters
                        dense
                        disablePadding
                      >
                        <ListItemText
                          primary={courseDetail.courseOfferingName}
                        />
                      </ListItem>
                    )
                  )}
                </List>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="modal-footer">
          <Button
            variant="contained"
            className="btn-continue"
            onClick={handleApprovedClick}
          >
            Approved
          </Button>
          <Button
            variant="outlined"
            className="btn-cancel"
            onClick={handleStudentDetailsModalClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default StudentDetailsModal;
