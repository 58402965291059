import { useEffect } from "react";
import { showLoader } from "utils/helper";
import { toast } from "react-toastify";
import { StudentDocumentsSuccessPayload } from "store/studentDashboard/types";
import { studentDocumentsRequest } from "store/studentDashboard/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppRoutings } from "utils/enums/app-routings";

const EducatorManual: React.FC = () => {
  const history = useHistory();
  const onEducatorManualPreviewSuccess = (
    response: StudentDocumentsSuccessPayload
  ) => {
    if (response.document !== null) {
      const url = response.document;
      window.open(url, "_blank");
      history.push(AppRoutings.EducatorsHome);
    } else {
      toast.success("Manual will be available soon");
    }
  };
  const dispatch = useDispatch();
  const handleEducatorManualPreview = () => {
    if (studentDocumentsRequest) {
      showLoader();
      const payload = {
        values: {
          type: "EducatorManual",
        },
        callback: onEducatorManualPreviewSuccess,
      };
      dispatch(studentDocumentsRequest(payload));
    }
  };
  useEffect(() => {
    handleEducatorManualPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default EducatorManual;
