import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  CHANGE_MANAGER_SETTING_FAILURE,
  CHANGE_MANAGER_SETTING_REQUEST,
  CHANGE_MANAGER_SETTING_SUCCESS,
} from "./actionTypes";

import {
  ChangePasswordPayload,
  ChangePasswordRequest,
  ChangePasswordSuccessPayload,
  ChangePasswordSuccess,
  ChangePasswordFailurePayload,
  ChangePasswordFailure,
  UpdateUserProfilePayload,
  UpdateUserProfileRequest,
  UpdateUserProfileSuccessPayload,
  UpdateUserProfileSuccess,
  UpdateUserProfileFailurePayload,
  UpdateUserProfileFailure,
  ChangeManagerSettingFailure,
  ChangeManagerSettingFailurePayload,
  ChangeManagerSettingPayload,
  ChangeManagerSettingRequest,
  ChangeManagerSettingSuccess,
  ChangeManagerSettingSuccessPayload,
} from "./types";

export const changePasswordRequest = (
  payload: ChangePasswordPayload
): ChangePasswordRequest => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
});

export const changePasswordSuccess = (
  payload: ChangePasswordSuccessPayload
): ChangePasswordSuccess => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordFailure = (
  payload: ChangePasswordFailurePayload
): ChangePasswordFailure => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload,
});

export const updateUserProfileRequest = (
  payload: UpdateUserProfilePayload
): UpdateUserProfileRequest => ({
  type: UPDATE_USER_PROFILE_REQUEST,
  payload,
});

export const updateUserProfileSuccess = (
  payload: UpdateUserProfileSuccessPayload
): UpdateUserProfileSuccess => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload,
});

export const updateUserProfileFailure = (
  payload: UpdateUserProfileFailurePayload
): UpdateUserProfileFailure => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  payload,
});

export const changeManagerSettingRequest = (
  payload: ChangeManagerSettingPayload
): ChangeManagerSettingRequest => ({
  type: CHANGE_MANAGER_SETTING_REQUEST,
  payload,
});

export const changeManagerSettingSuccess = (
  payload: ChangeManagerSettingSuccessPayload
): ChangeManagerSettingSuccess => ({
  type: CHANGE_MANAGER_SETTING_SUCCESS,
  payload,
});

export const changeManagerSettingFailure = (
  payload: ChangeManagerSettingFailurePayload
): ChangeManagerSettingFailure => ({
  type: CHANGE_MANAGER_SETTING_FAILURE,
  payload,
});
