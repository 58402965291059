export const STUDENT_LIST_REQUEST = "STUDENT_LIST_REQUEST";
export const STUDENT_LIST_SUCCESS = "STUDENT_LIST_SUCCESS";
export const STUDENT_LIST_FAILURE = "STUDENT_LIST_FAILURE";

export const DELETE_STUDENT_REQUEST = "DELETE_STUDENT_REQUEST";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FAILURE = "DELETE_STUDENT_FAILURE";

export const GET_STUDENTNOTE_REQUEST = "GET_STUDENTNOTE_REQUEST";
export const GET_STUDENTNOTE_SUCCESS = "GET_STUDENTNOTE_SUCCESS";
export const GET_STUDENTNOTE_FAILURE = "GET_STUDENTNOTE_FAILURE";

export const EDIT_STUDENTNOTE_REQUEST = "EDIT_STUDENTNOTE_REQUEST";
export const EDIT_STUDENTNOTE_SUCCESS = "EDIT_STUDENTNOTE_SUCCESS";
export const EDIT_STUDENTNOTE_FAILURE = "EDIT_STUDENTNOTE_FAILURE";

export const GET_CONFIRMATION_PREFERENCE_REQUEST =
  "GET_CONFIRMATION_PREFERENCE_REQUEST";
export const GET_CONFIRMATION_PREFERENCE_SUCCESS =
  "GET_CONFIRMATION_PREFERENCE_SUCCESS";
export const GET_CONFIRMATION_PREFERENCE_FAILURE =
  "GET_CONFIRMATION_PREFERENCE_FAILURE";

export const EDIT_CONFIRMATION_PREFERENCE_REQUEST =
  "EDIT_CONFIRMATION_PREFERENCE_REQUEST";
export const EDIT_CONFIRMATION_PREFERENCE_SUCCESS =
  "EDIT_CONFIRMATION_PREFERENCE_SUCCESS";
export const EDIT_CONFIRMATION_PREFERENCE_FAILURE =
  "EDIT_CONFIRMATION_PREFERENCE_FAILURE";

export const GET_STUDENT_DETAILS_REQUEST = "GET_STUDENT_DETAILS_REQUEST";
export const GET_STUDENT_DETAILS_SUCCESS = "GET_STUDENT_DETAILS_SUCCESS";
export const GET_STUDENT_DETAILS_FAILURE = "GET_STUDENT_DETAILS_FAILURE";

export const EDIT_STUDENT_DETAILS_REQUEST = "EDIT_STUDENT_DETAILS_REQUEST";
export const EDIT_STUDENT_DETAILS_SUCCESS = "EDIT_STUDENT_DETAILS_SUCCESS";
export const EDIT_STUDENT_DETAILS_FAILURE = "EDIT_STUDENT_DETAILS_FAILURE";

export const GET_RESEARCH_PROFILE_DETAILS_REQUEST =
  "GET_RESEARCH_PROFILE_DETAILS_REQUEST";
export const GET_RESEARCH_PROFILE_DETAILS_SUCCESS =
  "GET_RESEARCH_PROFILE_DETAILS_SUCCESS";
export const GET_RESEARCH_PROFILE_DETAILS_FAILURE =
  "GET_RESEARCH_PROFILE_DETAILS_FAILURE";

export const EDIT_RESEARCH_PROFILE_DETAILS_REQUEST =
  "EDIT_RESEARCH_PROFILE_DETAILS_REQUEST";
export const EDIT_RESEARCH_PROFILE_DETAILS_SUCCESS =
  "EDIT_RESEARCH_PROFILE_DETAILS_SUCCESS";
export const EDIT_RESEARCH_PROFILE_DETAILS_FAILURE =
  "EDIT_RESEARCH_PROFILE_DETAILS_FAILURE";

export const GET_STAFF_DETAILS_REQUEST = "GET_STAFF_DETAILS_REQUEST";
export const GET_STAFF_DETAILS_SUCCESS = "GET_STAFF_DETAILS_SUCCESS";
export const GET_STAFF_DETAILS_FAILURE = "GET_STAFF_DETAILS_FAILURE";

export const EDIT_STAFF_DETAILS_REQUEST = "EDIT_STAFF_DETAILS_REQUEST";
export const EDIT_STAFF_DETAILS_SUCCESS = "EDIT_STAFF_DETAILS_SUCCESS";
export const EDIT_STAFF_DETAILS_FAILURE = "EDIT_STAFF_DETAILS_FAILURE";

export const GET_MEDIA_CONSENT_REQUEST = "GET_MEDIA_CONSENT_REQUEST";
export const GET_MEDIA_CONSENT_SUCCESS = "GET_MEDIA_CONSENT_SUCCESS";
export const GET_MEDIA_CONSENT_FAILURE = "GET_MEDIA_CONSENT_FAILURE";

export const EDIT_MEDIA_CONSENT_REQUEST = "EDIT_MEDIA_CONSENT_REQUEST";
export const EDIT_MEDIA_CONSENT_SUCCESS = "EDIT_MEDIA_CONSENT_SUCCESS";
export const EDIT_MEDIA_CONSENT_FAILURE = "EDIT_MEDIA_CONSENT_FAILURE";

export const ENROLLMENT_SUMMARY_LIST_REQUEST =
  "ENROLLMENT_SUMMARY_LIST_REQUEST";
export const ENROLLMENT_SUMMARY_LIST_SUCCESS =
  "ENROLLMENT_SUMMARY_LIST_SUCCESS";
export const ENROLLMENT_SUMMARY_LIST_FAILURE =
  "ENROLLMENT_SUMMARY_LIST_FAILURE";

export const ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_REQUEST =
  "ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_REQUEST";
export const ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_SUCCESS =
  "ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_SUCCESS";
export const ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_FAILURE =
  "ENROLMENT_AND_ATTENDANCE_HISTORY_LIST_FAILURE";

export const STUDENT_ATTENDANCE_LIST_REQUEST =
  "STUDENT_ATTENDANCE_LIST_REQUEST";
export const STUDENT_ATTENDANCE_LIST_SUCCESS =
  "STUDENT_ATTENDANCE_LIST_SUCCESS";
export const STUDENT_ATTENDANCE_LIST_FAILURE =
  "STUDENT_ATTENDANCE_LIST_FAILURE";

export const GET_PENDING_ENROLLMENT_LIST_REQUEST =
  "GET_PENDING_ENROLLMENT_LIST_REQUEST";
export const GET_PENDING_ENROLLMENT_LIST_SUCCESS =
  "GET_PENDING_ENROLLMENT_LIST_SUCCESS";
export const GET_PENDING_ENROLLMENT_LIST_FAILURE =
  "GET_PENDING_ENROLLMENT_LIST_FAILURE";

export const GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_REQUEST =
  "GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_REQUEST";
export const GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_SUCCESS =
  "GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_SUCCESS";
export const GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_FAILURE =
  "GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_FAILURE";

export const APPROVE_ENROLMENT_BY_EMAIL_SMS_REQUEST =
  "APPROVE_ENROLMENT_BY_EMAIL_SMS_REQUEST";
export const APPROVE_ENROLMENT_BY_EMAIL_SMS_SUCCESS =
  "APPROVE_ENROLMENT_BY_EMAIL_SMS_SUCCESS";
export const APPROVE_ENROLMENT_BY_EMAIL_SMS_FAILURE =
  "APPROVE_ENROLMENT_BY_EMAIL_SMS_FAILURE ";

export const GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_REQUEST =
  "GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_REQUEST";
export const GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_SUCCESS =
  "GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_SUCCESS";
export const GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_FAILURE =
  "GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_FAILURE ";

export const ADD_NEW_ENROLMENT_FROM_STUDENT_REQUEST =
  "ADD_NEW_ENROLMENT_FROM_STUDENT_REQUEST";
export const ADD_NEW_ENROLMENT_FROM_STUDENT_SUCCESS =
  "ADD_NEW_ENROLMENT_FROM_STUDENT_SUCCESS";
export const ADD_NEW_ENROLMENT_FROM_STUDENT_FAILURE =
  "ADD_NEW_ENROLMENT_FROM_STUDENT_FAILURE ";

export const RE_SEND_CONFIRMATION_MAIL_REQUEST =
  "RE_SEND_CONFIRMATION_MAIL_REQUEST";
export const RE_SEND_CONFIRMATION_MAIL_SUCCESS =
  "RE_SEND_CONFIRMATION_MAIL_SUCCESS";
export const RE_SEND_CONFIRMATION_MAIL_FAILURE =
  "RE_SEND_CONFIRMATION_MAIL_FAILURE";

export const STUDENT_ENROLMENT_WITHDRAWN_REQUEST =
  "STUDENT_ENROLMENT_WITHDRAWN_REQUEST";
export const STUDENT_ENROLMENT_WITHDRAWN_SUCCESS =
  "STUDENT_ENROLMENT_WITHDRAWN_SUCCESS";
export const STUDENT_ENROLMENT_WITHDRAWN_FAILURE =
  "STUDENT_ENROLMENT_WITHDRAWN_FAILURE";

export const CHANGE_CONFIRMATION_PREFERENCE_REQUEST =
  "CHANGE_CONFIRMATION_PREFERENCE_REQUEST";

export const GENERATE_CERTIFICATE_REQUEST = "GENERATE_CERTIFICATE_REQUEST";
export const GENERATE_CERTIFICATE_SUCCESS = "GENERATE_CERTIFICATE_SUCCESS";
export const GENERATE_CERTIFICATE_FAILURE = "GENERATE_CERTIFICATE_FAILURE";

export const EDIT_STUDENT_PROFILE_DETAILS_REQUEST =
  "EDIT_STUDENT_PROFILE_DETAILS_REQUEST";
export const EDIT_STUDENT_PROFILE_DETAILS_SUCCESS =
  "EDIT_STUDENT_PROFILE_DETAILS_SUCCESS";
export const EDIT_STUDENT_PROFILE_DETAILS_FAILURE =
  "EDIT_STUDENT_PROFILE_DETAILS_FAILURE";

export const GET_STUDENT_PROFILE_DETAILS_REQUEST =
  "GET_STUDENT_PROFILE_DETAILS_REQUEST";
export const GET_STUDENT_PROFILE_DETAILS_SUCCESS =
  "GET_STUDENT_PROFILE_DETAILS_SUCCESS";
export const GET_STUDENT_PROFILE_DETAILS_FAILURE =
  "GET_STUDENT_PROFILE_DETAILS_FAILURE";

export const PRINT_ENROLMENT_SUMMARY_REQUEST =
  "PRINT_ENROLMENT_SUMMARY_REQUEST";
export const PRINT_ENROLMENT_SUMMARY_SUCCESS =
  "PRINT_ENROLMENT_SUMMARY_SUCCESS";
export const PRINT_ENROLMENT_SUMMARY_FAILURE =
  "PRINT_ENROLMENT_SUMMARY_FAILURE";
