import { closeIcon } from "assets/images";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { convertToDateTimeWithFormat, showLoader } from "utils/helper";
import { GetPendingEnrolmentListListSuccessPayload } from "store/student/types";
import { IPendingCourseList } from "utils/interfaces/student";
import { useLocation } from "react-router-dom";
import {
  CLOSE_TOOLTIP_TITLE,
  DATE_VIEW_FORMAT,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import NoRecords from "components/NoRecords";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import EnrolmentDetailsModal from "../EnrolmentDetailsModal";

interface ISendEmailSMSConfirmationProps {
  modalTitle: string;
  modalSubTitle: string;
  handleConfirmationModalClose: any;
  getPendingEnrolmentListRequest: any;
  SendViaEmailOrSMS: string;
  enrolmentOptionListRequest: any;
  getStudentEnrolmentForConfirmationRequest: any;
  approveEnrolmentByEmailSMSRequest: any;
  setStudentDetailChange: any;
  setAttendanceHistoryChange: any;
}

const SendEmailSMSConfirmationModal: React.FC<ISendEmailSMSConfirmationProps> =
  ({
    handleConfirmationModalClose,
    SendViaEmailOrSMS,
    getStudentEnrolmentForConfirmationRequest,
    approveEnrolmentByEmailSMSRequest,
    enrolmentOptionListRequest,
    getPendingEnrolmentListRequest,
    modalTitle,
    modalSubTitle,
    setStudentDetailChange,
    setAttendanceHistoryChange,
  }) => {
    const location = useLocation<{ studentId: number }>();
    const [isOpenEnrolmentDetailModal, setIsOpenEnrolmentDetailModal] =
      useState<boolean>(false);

    const [pendingEnrolmentList, setPendingEnrolmentList] =
      useState<IPendingCourseList[]>();

    const [selectedCourse, setSelectedCourse] = useState<number[]>([]);

    const [courseError, setCourseError] = useState<string>("");

    const handleCloseEnrolmentModal = () => {
      setIsOpenEnrolmentDetailModal(false);
    };

    const onStudentPendingEnrolmentSuccess = (
      response: GetPendingEnrolmentListListSuccessPayload
    ) => {
      setPendingEnrolmentList(response.pendingCourseList);
    };

    const getStudentPendingEnrolments = () => {
      if (getPendingEnrolmentListRequest) {
        showLoader();

        const payload = {
          values: { id: location?.state?.studentId },
          callback: onStudentPendingEnrolmentSuccess,
        };

        getPendingEnrolmentListRequest(payload);
      }
    };

    useEffect(() => {
      getStudentPendingEnrolments();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckboxChange = (courseEnrolmentId: number) => {
      setCourseError("");
      const isSelected = selectedCourse.includes(courseEnrolmentId);

      if (isSelected) {
        setSelectedCourse((prevSelectedCourse) =>
          prevSelectedCourse.filter((id) => id !== courseEnrolmentId)
        );
      } else {
        setSelectedCourse((prevSelectedCourse) => [
          ...prevSelectedCourse,
          courseEnrolmentId,
        ]);
      }
    };

    const handleYesClick = () => {
      if (selectedCourse.length === 0) {
        setCourseError("Please select at least one course");
      } else {
        setIsOpenEnrolmentDetailModal(true);
      }
    };

    return (
      <>
        <>
          <Modal open onClose={handleConfirmationModalClose}>
            <Box className="common-modal delete-modal send-sms-modal">
              <Box className="modal-header">
                <Typography variant="h2">{modalTitle}</Typography>
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={CLOSE_TOOLTIP_TITLE}
                  arrow
                >
                  <IconButton onClick={handleConfirmationModalClose}>
                    <img src={closeIcon} alt="close" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box className="modal-body">
                <Typography variant="h5" className="text-w-table">
                  {modalSubTitle}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{ width: "188px", minWidth: "188px" }}
                        >
                          Course
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "184px", minWidth: "184px" }}
                        >
                          Location
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "244px", minWidth: "244px" }}
                        >
                          Date & Time of First Session
                        </TableCell>
                        <TableCell align="center" sx={{ width: "152px" }}>
                          Enrolment Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pendingEnrolmentList?.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <FormControlLabel
                              value={row?.id}
                              control={
                                <Checkbox
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckedBoxIcon />}
                                  onChange={() => handleCheckboxChange(row?.id)}
                                  className="stepper-check-course"
                                />
                              }
                              defaultChecked={false}
                              label={row.courseName}
                              title={row.courseName}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography title={row.location} variant="body2">
                              {row.location}{" "}
                              {row.isBlended &&
                                `- ${row.preferredCourseDelivery}`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {" "}
                            {convertToDateTimeWithFormat(
                              row.startDateOfFirstSession,
                              DATE_VIEW_FORMAT
                            )}{" "}
                            {convertToDateTimeWithFormat(
                              row.startTimeOfFirstSession,
                              TIME_VIEW_FORMAT
                            )}{" "}
                            {"To"}{" "}
                            {convertToDateTimeWithFormat(
                              row.endTimeOfFirstSession,
                              TIME_VIEW_FORMAT
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${row.enrollmentStatus.toLocaleLowerCase()} status`}
                          >
                            <div>{row.enrollmentStatus}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {courseError && (
                  <FormHelperText error>{courseError}</FormHelperText>
                )}
              </Box>
              {pendingEnrolmentList && pendingEnrolmentList.length > 0 ? (
                <Box className="modal-footer">
                  <Button
                    variant="contained"
                    className="btn-yes"
                    onClick={() => handleYesClick()}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    className="btn-no"
                    onClick={handleConfirmationModalClose}
                  >
                    No
                  </Button>
                </Box>
              ) : (
                <NoRecords />
              )}
            </Box>
          </Modal>
        </>

        {isOpenEnrolmentDetailModal && (
          <EnrolmentDetailsModal
            enrolmentOptionListRequest={enrolmentOptionListRequest}
            handleEnrolmentDetailsModalClose={handleCloseEnrolmentModal}
            getStudentEnrolmentForConfirmationRequest={
              getStudentEnrolmentForConfirmationRequest
            }
            selectedCourseIds={selectedCourse}
            approveEnrolmentByEmailSMSRequest={
              approveEnrolmentByEmailSMSRequest
            }
            sendViaEmailOrSMS={SendViaEmailOrSMS}
            handleConfirmationModalClose={handleConfirmationModalClose}
            setAttendanceHistoryChange={setAttendanceHistoryChange}
            setStudentDetailChange={setStudentDetailChange}
          />
        )}
      </>
    );
  };

export default SendEmailSMSConfirmationModal;
