import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  addLocationFailure,
  addLocationSuccess,
  deleteLocationFailure,
  deleteLocationSuccess,
  editLocationFailure,
  editLocationSuccess,
  locationListFailure,
  locationListSuccess,
} from "./actions";
import {
  LOCATION_LIST_REQUEST,
  ADD_LOCATION_REQUEST,
  EDIT_LOCATION_REQUEST,
  DELETE_LOCATION_REQUEST,
} from "./actionTypes";
import locationService from "./service";
import {
  AddEditLocationResponse,
  DeleteLocationResponse,
  LocationListResponse,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* locationListSaga(action: any) {
  try {
    const response: LocationListResponse = yield call(
      locationService.getLocationList,
      {
        sortColumn: action.payload.values.sortColumn,
        sortBy: action.payload.values.sortBy,
        pageNo: action.payload.values.pageNo,
        pageSize: action.payload.values.pageSize,
        location: action.payload.values.location,
      }
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      locationListSuccess({
        locationList: response.item.records,
        locationListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      locationList: response.item.records,
      locationListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      locationListFailure({
        error: e.message,
      })
    );
  }
}

function* addLocationSaga(action: any) {
  try {
    const response: AddEditLocationResponse = yield call(
      locationService.addLocation,
      {
        location: action.payload.values.location,
        isBlended: action.payload.values.isBlended,
      }
    );

    yield put(
      addLocationSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addLocationFailure({
        error: e.message,
      })
    );
  }
}

function* editLocationSaga(action: any) {
  try {
    const response: AddEditLocationResponse = yield call(
      locationService.editLocation,
      {
        id: action.payload.values.id,
        location: action.payload.values.location,
        isBlended: action.payload.values.isBlended,
      }
    );

    yield put(
      editLocationSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editLocationFailure({
        error: e.message,
      })
    );
  }
}

function* deleteLocationSaga(action: any) {
  try {
    const response: DeleteLocationResponse = yield call(
      locationService.deleteLocation,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      deleteLocationSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteLocationFailure({
        error: e.message,
      })
    );
  }
}

function* locationSaga() {
  yield all([takeLatest(LOCATION_LIST_REQUEST, locationListSaga)]);
  yield all([takeLatest(ADD_LOCATION_REQUEST, addLocationSaga)]);
  yield all([takeLatest(EDIT_LOCATION_REQUEST, editLocationSaga)]);
  yield all([takeLatest(DELETE_LOCATION_REQUEST, deleteLocationSaga)]);
}

export default locationSaga;
