import { saveIcon } from "assets/images";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { AppPages } from "utils/enums/app-pages";
import {
  EmailPattern,
  getPagePermissions,
  onTextFieldKeyPress,
  onNumberFieldKeyPress,
  showLoader,
} from "utils/helper";
import {
  EditStaffDetailsSuccessPayload,
  StaffDetailsSuccessPayload,
} from "store/student/types";
import { useForm } from "react-hook-form";
import { IStaffDetails } from "utils/interfaces/student";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import {
  STAFF_DETAIL_EDIT_SUCCESS_MESSAGE,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER,
} from "utils/constants/Messages";
import { toast } from "react-toastify";
import {
  STUDENT_MANAGER_EMAIL_MAX_LENGTH,
  STUDENT_MANAGER_NAME_MAX_LENGTH,
  STUDENT_ENROLMENT_STAFF_LINK_ID_MAX_LENGTH,
} from "utils/constants/student-enrolment";
import { INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX } from "utils/constants/constant";

interface IStaffDetailsProps {
  staffDetailsRequest: any;
  editStaffDetailRequest: any;
  enrolmentOptionList: IEnrolmentOptions | undefined;
}

const StaffDetails: React.FC<IStaffDetailsProps> = ({
  staffDetailsRequest,
  editStaffDetailRequest,
  enrolmentOptionList,
}) => {
  const location = useLocation<{ studentId: number; isDeleted?: boolean }>();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const [staffData, setStaffData] = useState<IStaffDetails>();
  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IStaffDetails>({
    defaultValues: {
      employmentStatus: "",
      currentRole: "",
      workEnvironment: "",
      site: "",
      employmentNumber: undefined,
    },
  });

  const getManagersEmailError = (): string => {
    if (errors.managerEmail) {
      if (errors.managerEmail.type === "pattern") {
        return "Enter valid email";
      }
      if (errors.managerEmail.type === "maxLength") {
        return `Maximum length of email should be ${STUDENT_MANAGER_EMAIL_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getManagerNameError = (): string => {
    if (errors.managerName) {
      if (errors.managerName.type === "maxLength") {
        return `Maximum length of name should be ${STUDENT_MANAGER_NAME_MAX_LENGTH}`;
      }
      if (errors.managerName.type === "pattern") {
        return `Manager name ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getStaffLinkIdError = (): string => {
    if (errors.employmentNumber) {
      if (errors.employmentNumber.type === "max") {
        return "Max value of Staff Link Id can be '2147483647'";
      }
      if (errors.employmentNumber.type === "maxLength") {
        return `Max value of Staff Link Id can be ${STUDENT_ENROLMENT_STAFF_LINK_ID_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const setStudentFormValues = (researchProfileDetails: any) => {
    reset((formValues) => ({
      ...formValues,
      employmentStatus: researchProfileDetails.employmentStatus,
      currentRole: researchProfileDetails.currentRole,
      workEnvironment: researchProfileDetails.workEnvironment,
      site: researchProfileDetails.site,
      managerName: researchProfileDetails.managerName,
      managerEmail: researchProfileDetails.managerEmail,
      employmentNumber: researchProfileDetails.employmentNumber,
      isActive: researchProfileDetails.isActive,
    }));
  };

  const onResearchProfileDataSuccess = (
    response: StaffDetailsSuccessPayload
  ) => {
    setStudentFormValues(response.StaffDetails);
    setStaffData(response.StaffDetails);
  };

  const getResearchProfileData = () => {
    if (staffDetailsRequest) {
      showLoader();
      const { studentId } = location.state;

      const payload = {
        values: { id: studentId },
        callback: onResearchProfileDataSuccess,
      };

      staffDetailsRequest(payload);
    }
  };

  const handleCancelClick = () => {
    setStudentFormValues(staffData);
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Student);
    if (location?.state.studentId && permission.edit) {
      getResearchProfileData();
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditStaffDetailSuccess = (
    response: EditStaffDetailsSuccessPayload
  ) => {
    if (response.success) {
      toast.success(STAFF_DETAIL_EDIT_SUCCESS_MESSAGE);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const onSubmit = async (data: IStaffDetails) => {
    if (location.state.studentId && !location.state.isDeleted) {
      if (editStaffDetailRequest) {
        showLoader();
        const payload = {
          values: {
            studentId: location.state.studentId,
            employmentStatus: data.employmentStatus,
            currentRole: data.currentRole,
            workEnvironment: data.workEnvironment,
            site: data.site,
            managerName: data.managerName,
            managerEmail: data.managerEmail,
            employmentNumber: data.employmentNumber || null,
          },
          callback: onEditStaffDetailSuccess,
        };
        editStaffDetailRequest(payload);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="std-details-card">
        <Typography variant="h3" className="attnd-heading">
          Staff Details
        </Typography>
        <Grid
          container
          columnSpacing={{
            sm: "16px",
            md: " 20px",
            xl: "24px",
          }}
        >
          <Grid item xs={12} sm={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="empStatus-label"
                shrink={!!watch("employmentStatus")}
              >
                Employment Status <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="empStatus-label"
                id="empStatus"
                label={
                  <>
                    Employment Status <span className="color-red">*</span>
                  </>
                }
                value={watch("employmentStatus")}
                error={!!errors.employmentStatus}
                {...register("employmentStatus", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.staffEmploymentStatus?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel id="currntRole-label" shrink={!!watch("currentRole")}>
                Current Role <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="currntRole-label"
                id="currntRole"
                label={
                  <>
                    Current Role <span className="color-red">*</span>
                  </>
                }
                value={watch("currentRole")}
                error={!!errors.currentRole}
                {...register("currentRole", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.currentRole?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel
                id="workEnv-label"
                shrink={!!watch("workEnvironment")}
              >
                Work Environment <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="workEnv-label"
                id="workEnv"
                label={
                  <>
                    Work Environment <span className="color-red">*</span>
                  </>
                }
                value={watch("workEnvironment")}
                error={!!errors.workEnvironment}
                {...register("workEnvironment", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.workEnvironment?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FormControl fullWidth className="select">
              <InputLabel id="siteServ-label" shrink={!!watch("site")}>
                Site/Service <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="siteServ-label"
                id="siteServ"
                label={
                  <>
                    Site/Service <span className="color-red">*</span>
                  </>
                }
                value={watch("site")}
                error={!!errors.site}
                {...register("site", {
                  required: true,
                })}
              >
                {enrolmentOptionList?.siteOrService?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="mang-name"
              label="Manager's Name"
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("managerName")),
              }}
              error={!!errors.managerName}
              onKeyPress={(event) =>
                onTextFieldKeyPress(event, watch("managerName")?.length)
              }
              helperText={getManagerNameError()}
              {...register("managerName", {
                maxLength: STUDENT_MANAGER_NAME_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              id="mang-email"
              label="Manager's Email"
              fullWidth
              variant="outlined"
              defaultValue="johndoe@gmail.com"
              InputLabelProps={{
                shrink: Boolean(watch("managerEmail")),
              }}
              error={!!errors.managerEmail}
              helperText={getManagersEmailError()}
              {...register("managerEmail", {
                pattern: EmailPattern,
                maxLength: STUDENT_MANAGER_EMAIL_MAX_LENGTH,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              type="number"
              id="staff-link-id"
              label="Staff Link ID"
              fullWidth
              onKeyPress={onNumberFieldKeyPress}
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("employmentNumber")),
              }}
              error={!!errors.employmentNumber}
              helperText={getStaffLinkIdError()}
              {...register("employmentNumber", {
                maxLength: STUDENT_ENROLMENT_STAFF_LINK_ID_MAX_LENGTH,
                max: 2147483647,
              })}
              value={watch("employmentNumber") || ""}
            />
          </Grid>
        </Grid>
        <Box className="btn-group">
          <Button
            disabled={location.state.isDeleted}
            variant="contained"
            className="btn-save"
            type="submit"
          >
            <img src={saveIcon} alt="save" />
            Save
          </Button>
          <Button
            variant="outlined"
            className="btn-cancel"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default StaffDetails;
