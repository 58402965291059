import { ITermPagePayloadValues } from "utils/interfaces/term";
import baseService from "services/base-service";

const termBaseUrl = `/api/Terms`;

const getTermList = async (payload: ITermPagePayloadValues) =>
  baseService.post(`${termBaseUrl}/GetTerms`, payload);

const addTerm = async (payload: {
  termName: string;
  term: number;
  year: number;
  startDate: string;
  endDate: string;
  status: number;
}) => baseService.post(termBaseUrl, payload);

const editTerm = async (payload: {
  id: number;
  termName: string;
  term: number;
  year: number;
  startDate: string;
  endDate: string;
  status: number;
}) => baseService.put(termBaseUrl, payload);

const deleteTerm = async (payload: { id: number }) =>
  baseService.delete(`${termBaseUrl}?id=${payload.id}`);

const getTermById = async (payload: { id: number }) =>
  baseService.get(`${termBaseUrl}?id=${payload.id}`);

export default {
  getTermList,
  addTerm,
  editTerm,
  deleteTerm,
  getTermById,
};
