import { connect, MapDispatchToProps } from "react-redux";
import ProfileComponent, { ProfileProps } from "pages/Profile";
import { updateUserProfileRequest } from "store/profile/actions";
import { IProfileContainerDispatch, IProfile } from "utils/interfaces/profile";

const mapDispatchToProps: MapDispatchToProps<
  IProfileContainerDispatch,
  IProfile
> = {
  updateUserProfileRequest,
};

const connector = connect(null, mapDispatchToProps);

const Profile: React.FC<ProfileProps> = (props) => {
  return <ProfileComponent {...props} />;
};

export default connector(Profile);
