import { Box } from "@mui/system";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  ICourseReflectionsForm,
  IEducatorHomeScoDetails,
} from "utils/interfaces/educator-home";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  DATE_PICKER_FORMAT,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
} from "utils/constants/constant";
import {
  ENTER_REFLECTION_FORM_FIELD_MSG,
  WHITE_SPACE_ERROR_SPACE,
} from "utils/constants/Messages";
import CalendarIcon from "components/CalendarIcon";

interface ICourseReflectionsFormProps {
  scoDetails?: IEducatorHomeScoDetails;
  onAddEditCourseReflectionForm: any;
}

const CourseReflectionsForm: React.FC<ICourseReflectionsFormProps> = ({
  scoDetails,
  onAddEditCourseReflectionForm,
}) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ICourseReflectionsForm>({
    defaultValues: {
      formFillingDate: dayjs(),
    },
  });

  const getWhatWentWellError = (): string => {
    if (errors.whatWentWell) {
      if (errors.whatWentWell.type === "pattern") {
        return `What Went Well ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getHowCanWeContinueToDoThisError = (): string => {
    if (errors.howCanWeContinueToDoThis) {
      if (errors.howCanWeContinueToDoThis.type === "pattern") {
        return `How Can We Continue To Do This ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getWhatDidNotGoSoWellError = (): string => {
    if (errors.whatDidNotGoSoWell) {
      if (errors.whatDidNotGoSoWell.type === "pattern") {
        return `What Did Not Go So Well ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };
  const getWhatIdeasDoYouHaveToImproveThisError = (): string => {
    if (errors.whatIdeasDoYouHaveToImproveThis) {
      if (errors.whatIdeasDoYouHaveToImproveThis.type === "pattern") {
        return `What Ideas Do You Have To Improve This ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  useEffect(() => {
    if (scoDetails?.educatorCourseReflections) {
      const editData = scoDetails?.educatorCourseReflections;
      setValue("id", editData.id);
      setValue("formFillingDate", dayjs(editData.date));
      setValue("whatWentWell", editData.whatWentWell);
      setValue("howCanWeContinueToDoThis", editData.howCanWeContinueToDoThis);
      setValue("whatDidNotGoSoWell", editData.whatDidNotGoSoWell);
      setValue(
        "whatIdeasDoYouHaveToImproveThis",
        editData.whatIdeasDoYouHaveToImproveThis
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoDetails]);

  const onSubmit = async (data: ICourseReflectionsForm) => {
    if (
      !(
        data.whatWentWell ||
        data.howCanWeContinueToDoThis ||
        data.whatDidNotGoSoWell ||
        data.whatIdeasDoYouHaveToImproveThis
      )
    ) {
      toast.error(ENTER_REFLECTION_FORM_FIELD_MSG);
    } else {
      onAddEditCourseReflectionForm(data);
    }
  };

  return (
    <Grid item xs={12}>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h3" className="attnd-heading">
            Educator Course Reflections Form
          </Typography>
          <Typography variant="body1" className="course-info-para">
            As with any new initiative, we are all learning and making changes
            as we go. We genuinely want to ensure that the college is responsive
            to feedback form everyone involved. As an educator involved with the
            development and facilitation of a Recovery college course, we would
            appreciate you completing this form and uploading it to recovery
            college database.(Ideally to be done within 15 mins of course
            completion). In the spirit of co- production, we encourage you to
            reflect on your experience together.
          </Typography>
          <Typography variant="body1" className="course-info-para">
            Your response will be only shared between Recovery college team
            members to assist us in reviewing the course and processes of the
            Recovery College.
          </Typography>
          <Grid container columnSpacing="24px" sx={{ marginTop: "24px" }}>
            <Grid item xs={12} md={6}>
              <TextField
                id="courseName"
                label="Course Name"
                fullWidth
                variant="outlined"
                value={scoDetails?.courseName}
                disabled
                InputLabelProps={{
                  shrink: Boolean(scoDetails?.courseName),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Grid container columnSpacing="24px">
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="termName"
                    label="Term"
                    fullWidth
                    variant="outlined"
                    value={scoDetails?.termName}
                    disabled
                    InputLabelProps={{
                      shrink: Boolean(scoDetails?.termName),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="year"
                    label="Year"
                    fullWidth
                    variant="outlined"
                    value={scoDetails?.termYear}
                    disabled
                    InputLabelProps={{
                      shrink: Boolean(scoDetails?.termYear),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DesktopDatePicker"]}>
                  <Controller
                    control={control}
                    {...register("formFillingDate")}
                    render={({ field }) => (
                      <DesktopDatePicker
                        {...field}
                        className="date-picker disabled-picker-text-field"
                        disableFuture
                        format={DATE_PICKER_FORMAT}
                        label="Form Filling Date"
                        value={watch("formFillingDate")}
                        slotProps={{
                          textField: { fullWidth: true, disabled: true },
                        }}
                        slots={{
                          openPickerIcon: CalendarIcon,
                        }}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="feedback"
                label="What Went Well?"
                multiline
                className="suggestion-textarea"
                fullWidth
                error={!!errors.whatWentWell}
                helperText={getWhatWentWellError()}
                variant="outlined"
                InputLabelProps={{
                  shrink: Boolean(watch("whatWentWell")),
                }}
                {...register("whatWentWell", {
                  pattern:
                    INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="suggestion"
                label="How Can We Continue To Do This?"
                multiline
                className="suggestion-textarea"
                fullWidth
                error={!!errors.howCanWeContinueToDoThis}
                helperText={getHowCanWeContinueToDoThisError()}
                variant="outlined"
                InputLabelProps={{
                  shrink: Boolean(watch("howCanWeContinueToDoThis")),
                }}
                {...register("howCanWeContinueToDoThis", {
                  pattern:
                    INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="critics"
                label="What Didn’t Go So Well?"
                multiline
                className="suggestion-textarea"
                fullWidth
                error={!!errors.whatDidNotGoSoWell}
                helperText={getWhatDidNotGoSoWellError()}
                variant="outlined"
                InputLabelProps={{
                  shrink: Boolean(watch("whatDidNotGoSoWell")),
                }}
                {...register("whatDidNotGoSoWell", {
                  pattern:
                    INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="suggestion2"
                label="What Ideas Do You Have To Improve This?"
                multiline
                className="suggestion-textarea"
                fullWidth
                error={!!errors.whatIdeasDoYouHaveToImproveThis}
                helperText={getWhatIdeasDoYouHaveToImproveThisError()}
                variant="outlined"
                InputLabelProps={{
                  shrink: Boolean(watch("whatIdeasDoYouHaveToImproveThis")),
                }}
                {...register("whatIdeasDoYouHaveToImproveThis", {
                  pattern:
                    INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                })}
              />
            </Grid>
          </Grid>
          <Box className="btn-group">
            <Button variant="contained" className="btn-cancel" type="submit">
              Submit
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Card>
    </Grid>
  );
};

export default CourseReflectionsForm;
