import RadioCheckBoxIcon from "components/RadioCheckBoxIcon";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ACTIVE_COMMUNITY_EMR_DEFAULT } from "utils/constants/student-enrolment";
import { useEffect, useState } from "react";
import {
  AppliedCourses,
  IApproveEnrolmentData,
  IApproveRejectEnrolmentContainerDispatch,
  IApproveRejectEnrolmentContainerState,
  IEnrolmentDetails,
  IEnrolmentOptions,
  SelectedCourse,
} from "utils/interfaces/student-enrolment";
import { checkIcon, uncheckIcon } from "assets/images";
import { Controller, useForm } from "react-hook-form";
import RadioCheckedBoxIcon from "components/RadioCheckedBoxIcon";
import { useHistory, useLocation } from "react-router-dom";
import { showLoader } from "utils/helper";
import {
  ApproveEnrolmentSuccessPayload,
  EnrolmentOptionListSuccessPayload,
  GetStudentEnrolmentDataForApproveSuccessPayload,
} from "store/studentEnrolment/types";
import { toast } from "react-toastify";
import {
  COURSE_STATUS,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  MAX_INT_VALUE,
} from "utils/constants/constant";
import { WHITE_SPACE_ERROR_SPACE } from "utils/constants/Messages";
import ApprovedModal from "../ApprovedModal";

export interface IRejectComment {
  course: number;
  isValidate: boolean;
}

export type StudentEnrolmentApprovalProps =
  IApproveRejectEnrolmentContainerState &
    IApproveRejectEnrolmentContainerDispatch;

function CheckboxIcon() {
  return <img src={uncheckIcon} alt="checkbox" />;
}
function CheckedboxIcon() {
  return <img src={checkIcon} alt="checkbox" />;
}

const StudentEnrolmentApproval: React.FC<StudentEnrolmentApprovalProps> = (
  props
) => {
  const {
    enrolmentOptionListRequest,
    getStudentEnrolmentDataForApproveRequest,
  } = props;
  const location = useLocation<{ studentId: number }>();
  const history = useHistory();
  const [enrolmentOptionList, setEnrolmentOptionList] =
    useState<IEnrolmentOptions>();
  const [requestedCourses, setRequestedCourses] = useState<AppliedCourses[]>();
  const [selectedCourses, setSelectedCourses] = useState<SelectedCourse[]>([]);
  const [currentsStudentName, setCurrentStudentName] = useState<string>("");

  const [isWaitListed, setIsWaitListed] = useState<boolean>(false);
  const [enrolmentStatus, setEnrolmentStatus] = useState<string>("Approved");
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [student, setStudent] = useState<any>();

  const handleApproveModalClose = () => history.goBack();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    trigger,
    setValue,
    control,
    formState: { errors },
  } = useForm<IEnrolmentDetails>({
    defaultValues: {
      activeCommunityEmr: ACTIVE_COMMUNITY_EMR_DEFAULT,
    },
  });
  const { studentId } = location.state;

  const setFormValues = (enrolment: IApproveEnrolmentData) => {
    if (enrolment.activeCommunityEMR != null) {
      reset((formValues) => ({
        ...formValues,
        activeCommunityEmr:
          enrolment?.activeCommunityEMR === null
            ? ACTIVE_COMMUNITY_EMR_DEFAULT
            : enrolment?.activeCommunityEMR,
        enrolmentConfirmPref: enrolment.enrolmentConfirmPref,
        sector: enrolment?.sector,
        researchCode: enrolment?.researchCode,
        mrn: enrolment?.mrn,
      }));
    }
    setValue("researchCode", enrolment?.researchCode);
    setRequestedCourses(enrolment.courses);
    setCurrentStudentName(`${enrolment.firstName} ${enrolment.lastName}`);
  };

  const onGetStudentEnrolmentDataForApproveSuccess = (
    response: GetStudentEnrolmentDataForApproveSuccessPayload
  ) => {
    setFormValues(response.studentApproveEnrolmentData);
  };

  const getStudentEnrolmentDetails = () => {
    if (getStudentEnrolmentDataForApproveRequest) {
      showLoader();
      const payload = {
        values: { id: studentId },
        callback: onGetStudentEnrolmentDataForApproveSuccess,
      };

      getStudentEnrolmentDataForApproveRequest(payload);
    }
  };

  const onEnrolmentOptionListSuccess = (
    response: EnrolmentOptionListSuccessPayload
  ) => {
    setEnrolmentOptionList(response.enrolmentOptionList);
  };

  const getEnrolmentOptionList = () => {
    if (enrolmentOptionListRequest) {
      showLoader();

      const payload = {
        values: {},
        callback: onEnrolmentOptionListSuccess,
      };

      enrolmentOptionListRequest(payload);
    }
  };

  useEffect(() => {
    getEnrolmentOptionList();
    getStudentEnrolmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestedCourses) {
      const initialSelectedCourses = requestedCourses.map((course) => ({
        courseOfferingId: course.courseOfferingId,
        IsChecked: true,
        rejectionMessage: null,
      }));
      setSelectedCourses(initialSelectedCourses);
    }
  }, [requestedCourses]);

  useEffect(() => {
    if (watch("enrolmentConfirmPref")) {
      trigger("enrolmentConfirmPref");
    }
    if (watch("sector")) {
      trigger("sector");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("enrolmentConfirmPref"), watch("sector")]);

  const handleSelectedCourses = (
    event: React.ChangeEvent<HTMLInputElement>,
    course: AppliedCourses
  ) => {
    const courseId = course.courseOfferingId;
    const isChecked = event.target.checked;

    setSelectedCourses((prevSelectedCourses) => {
      const updatedCourses = prevSelectedCourses.map((selectedCourse) =>
        selectedCourse.courseOfferingId === courseId
          ? { ...selectedCourse, IsChecked: isChecked }
          : selectedCourse
      );

      return updatedCourses;
    });
  };

  const getEnrolmentConfirmPrefError = (): string => {
    if (errors.enrolmentConfirmPref) {
      if (errors.enrolmentConfirmPref.type === "required") {
        return "Enrolment confirmation preference is required";
      }
    }

    return "";
  };

  const getResearchCodeError = (): string => {
    if (errors.researchCode) {
      if (errors.researchCode.type === "required") {
        return "Research code is required";
      }
      if (errors.researchCode.type === "maxLength") {
        return `Maximum length of Research Code should be 10`;
      }
      if (errors.researchCode.type === "max") {
        return `Max value of Research Code can be '${MAX_INT_VALUE}'`;
      }
    }

    return "";
  };

  const onAddEnrollmentSuccess = (response: ApproveEnrolmentSuccessPayload) => {
    if (response.success) {
      setStudent(response.student);
      setApproveModal(true);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const handleApprovedClick = async (data: IEnrolmentDetails) => {
    const { approveEnrolmentRequest } = props;
    if (approveEnrolmentRequest) {
      showLoader();
      const payload = {
        values: {
          studentId: location?.state?.studentId,
          enrolmentConfirmPref: data.enrolmentConfirmPref,
          researchCode: data.researchCode,
          mrn: data.mrn,
          sector: data.sector,
          activeCommunityEmr: data.activeCommunityEmr,
          status: enrolmentStatus,
          course: selectedCourses.map((course) => ({
            courseOfferingId: course.courseOfferingId,
            IsChecked: course.IsChecked,
          })),
        },
        callback: onAddEnrollmentSuccess,
      };

      approveEnrolmentRequest(payload);
    }
  };

  const getSectorError = (): string => {
    if (errors.sector) {
      if (errors.sector.type === "required") {
        return "Sector is required";
      }
    }

    return "";
  };

  const getMRNError = (): string => {
    if (errors.mrn) {
      if (errors.mrn.type === "pattern") {
        return `MRN ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  return (
    <>
      <>
        <form onSubmit={handleSubmit(handleApprovedClick)}>
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Student Confirmation
            </Typography>
          </Box>

          <Card>
            <Box className="stdenrol-detail-content">
              <Grid
                container
                columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
              >
                <Grid item xs={12} sm={6} xl={3}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="enrol-confirm-label">
                      Enrolment confirmation preference
                      <span className="color-red">*</span>
                    </InputLabel>
                    <Select
                      labelId="enrol-confirm-label"
                      id="enrol-confirm"
                      label="Enrolment confirmation preference"
                      value={watch("enrolmentConfirmPref") || ""}
                      error={!!errors.enrolmentConfirmPref}
                      {...register("enrolmentConfirmPref", {
                        required: true,
                      })}
                    >
                      {enrolmentOptionList?.enrolmentConfirmationPreference?.map(
                        (option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {!!errors.enrolmentConfirmPref && (
                      <FormHelperText error>
                        {getEnrolmentConfirmPrefError()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="sector-select-label">
                      Sector<span className="color-red">*</span>
                    </InputLabel>
                    <Select
                      labelId="sector-select-label"
                      id="sector-select"
                      label="Sector"
                      value={watch("sector") || ""}
                      error={!!errors.sector}
                      {...register("sector", {
                        required: true,
                      })}
                    >
                      {enrolmentOptionList?.sector?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.sector && (
                      <FormHelperText error>{getSectorError()}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <TextField
                    id="resch-code"
                    label={
                      <>
                        Research Code<span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    type="number"
                    value={watch("researchCode") || ""}
                    variant="outlined"
                    error={!!errors.researchCode}
                    helperText={getResearchCodeError()}
                    {...register("researchCode", {
                      required: true,
                      maxLength: 10,
                      max: MAX_INT_VALUE,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <TextField
                    id="mrn"
                    value={watch("mrn") || ""}
                    label="MRN (If Known)"
                    fullWidth
                    variant="outlined"
                    {...register("mrn", {
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                    error={!!errors.mrn}
                    helperText={getMRNError()}
                  />
                </Grid>
              </Grid>
              <Box className="stdenrol-staff">
                <Typography variant="h5">
                  Active community eMR &#40;at enrolment&#41;
                </Typography>
                <Controller
                  control={control}
                  {...register("activeCommunityEmr")}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      {enrolmentOptionList?.activeCommunityEMR?.map(
                        (option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={
                              <Radio
                                icon={<RadioCheckBoxIcon />}
                                checkedIcon={<RadioCheckedBoxIcon />}
                              />
                            }
                            label={option.label}
                          />
                        )
                      )}
                    </RadioGroup>
                  )}
                />
              </Box>
              <Box className="stdenrol-course">
                <Typography variant="h5">Selected Courses</Typography>
                <Box>
                  <Controller
                    control={control}
                    {...register("course")}
                    render={() => (
                      <>
                        {requestedCourses?.map((course) => (
                          <div key={course.courseOfferingId}>
                            <div className="selected-courses-box">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={selectedCourses.some(
                                      (selectedCourse) =>
                                        selectedCourse.courseOfferingId ===
                                          course.courseOfferingId &&
                                        selectedCourse.IsChecked
                                    )}
                                    onChange={(e) =>
                                      handleSelectedCourses(e, course)
                                    }
                                    disableFocusRipple
                                    disableRipple
                                    icon={<CheckboxIcon />}
                                    checkedIcon={<CheckedboxIcon />}
                                  />
                                }
                                label={course.courseName}
                                labelPlacement="end"
                              />
                              <div
                                className={
                                  course.status === COURSE_STATUS
                                    ? "status-course-waitlisted"
                                    : "status-course-pending"
                                }
                              >
                                {course.status}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </Box>
              </Box>
              <Box className="btn-group">
                <Button
                  variant="contained"
                  className="btn-continue"
                  type="submit"
                >
                  Approved
                </Button>
                <Button
                  variant="contained"
                  className="btn-continue"
                  type="submit"
                  onClick={() => {
                    setIsWaitListed(true);
                    setEnrolmentStatus("Waitlisted");
                  }}
                >
                  Waitlisted
                </Button>
                <Button
                  variant="contained"
                  className="btn-continue"
                  type="submit"
                  onClick={() => {
                    setEnrolmentStatus("Rejected");
                  }}
                >
                  Rejected
                </Button>
                <Button
                  variant="outlined"
                  className="btn-cancel"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      </>
      <ApprovedModal
        isOpenApprovedModal={approveModal}
        handleApprovedModalClose={handleApproveModalClose}
        studentName={currentsStudentName}
        isWaitListed={isWaitListed}
        studentId={studentId}
        student={student}
        isRejectedCourses={enrolmentStatus === "Rejected"}
      />
    </>
  );
};

export default StudentEnrolmentApproval;
