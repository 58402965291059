import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  closeIcon,
  deleteIcon,
  editIcon,
  filterLightIcon,
  filterPrimaryIcon,
  plusLightIcon,
  saveIcon,
} from "assets/images";
import React, { useState } from "react";
import DesignerFooter from "components/designerFooter";
import projectTheme from "app.theme";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

interface CoursesData {
  coursename: string;
  coursenum: string;
  stream: string;
  offerings: number;
}

function createCoursesData(
  coursename: string,
  coursenum: string,
  stream: string,
  offerings: number
): CoursesData {
  return {
    coursename,
    coursenum,
    stream,
    offerings,
  };
}

const educatorsrow = [
  createCoursesData("Life Beyond Trauma", "S020017-ENG", "Event", 0),
  createCoursesData("Mindfulnes workshop", "S020017-ENG", "1", 0),
  createCoursesData("Creativity for recovery", "S020017-ENG", "1", 0),
  createCoursesData(
    "An introduction to recovery & the strength of approch",
    "S020017-ENG",
    "1",
    1
  ),
  createCoursesData("Making and keeping connections", "S020017-ENG", "3", 0),
  createCoursesData("Exploring psychosis", "S020017-ENG", "2", 0),
  createCoursesData("Exploring trauma for women", "S020017-ENG", "1", 0),
  createCoursesData("Taking control of your life", "S020017-ENG", "1", 2),
  createCoursesData("Physical health & welbeing", "S020017-ENG", "1", 1),
  createCoursesData("Yoga for recovery", "S020017-ENG", "1", 1),
];

const DesignerCourses: React.FC = () => {
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [available, setAvailable] = React.useState("1");

  const handleAvailableChange = (event: SelectChangeEvent) => {
    setAvailable(event.target.value as string);
  };

  const [stream, setstream] = React.useState("");

  const handlestreamChange = (event: SelectChangeEvent) => {
    setstream(event.target.value as string);
  };

  const [filterbox, setFilterbox] = React.useState(false);
  const [courses, setCourses] = React.useState(true);
  const [addcoursedetails, setAddcoursedetails] = React.useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Box
        className="filter-overlay"
        onClick={() => {
          document.body.classList.toggle("filter-open");
          setFilterbox(false);
        }}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        {courses && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Courses
              </Typography>
              <Box sx={{ display: "flex", columnGap: "15px" }}>
                <Button
                  variant="contained"
                  className="btn-add"
                  onClick={() => {
                    setAddcoursedetails(true);
                    setCourses(false);
                  }}
                >
                  <img src={plusLightIcon} alt="plus" />
                  Add
                </Button>
                <Button variant="outlined" className="btn-export">
                  Export
                </Button>
              </Box>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{ justifyContent: "space-between", width: "100%" }}
                      className="search-filter-inputs"
                    >
                      <TextField
                        id="search"
                        variant="outlined"
                        className="search-input"
                        placeholder="Search"
                      />

                      <Button
                        variant="outlined"
                        className="btn-filter"
                        onClick={() => {
                          setFilterbox(true);
                          document.body.classList.toggle("filter-open");
                        }}
                      >
                        <img
                          src={filterPrimaryIcon}
                          alt="filter"
                          className="default"
                        />
                        <img
                          src={filterLightIcon}
                          alt="filter"
                          className="active"
                        />
                        <span>Filter</span>
                      </Button>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              width: "423px",
                              minWidth: "423px",
                            }}
                          >
                            Course Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "263px", minWidth: "180px" }}
                          >
                            Course Number
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "135px", minWidth: "135px" }}
                          >
                            Stream
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "217px", minWidth: "217px" }}
                          >
                            Offerings In Current Term
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "154px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "112px",
                              },
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {educatorsrow.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.coursename}
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2" className="email">
                                {row.coursenum}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.stream}</TableCell>
                            <TableCell>{row.offerings}</TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setAddcoursedetails(true);
                                    setCourses(false);
                                  }}
                                >
                                  <img src={editIcon} alt="edit" />
                                </IconButton>
                                <IconButton>
                                  <img src={deleteIcon} alt="delete" />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
        {addcoursedetails && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Add Course Details
              </Typography>
              <Button
                variant="outlined"
                className="btn-back"
                onClick={() => {
                  setAddcoursedetails(false);
                  setCourses(true);
                }}
              >
                Back
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Grid
                    container
                    columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                  >
                    <Grid item xs={12} sm={6} lg={4} xl={2}>
                      <TextField
                        id="course-num"
                        label="Course No."
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={5}>
                      <TextField
                        id="course"
                        label="Course"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={4} xl={5}>
                      <TextField
                        id="prev-name"
                        label="Previous Name (If Any)"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        id="skills"
                        className="skills-textarea"
                        label="Skills"
                        fullWidth
                        variant="outlined"
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        id="knowledge"
                        className="skills-textarea"
                        label="Knowledge"
                        fullWidth
                        variant="outlined"
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        id="attitudes"
                        className="skills-textarea"
                        label="Attitudes"
                        fullWidth
                        variant="outlined"
                        multiline
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="stream-label">Stream</InputLabel>
                        <Select
                          labelId="stream-label"
                          id="stream"
                          label="Stream"
                          value={stream}
                          onChange={handlestreamChange}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="available-label">
                          Course Available This term
                        </InputLabel>
                        <Select
                          labelId="available-label"
                          id="available"
                          label="Course Available This term"
                          value={available}
                          onChange={handleAvailableChange}
                        >
                          <MenuItem value={1}>Yes</MenuItem>
                          <MenuItem value={2}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id="course-num-2"
                        label="Usual No. Of Sessions"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id="session-len"
                        label="Length Of Single Session (Hrs.)"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    placeholder="Course Description"
                  />
                  <Box className="btn-group" sx={{ paddingTop: "30px" }}>
                    <Button
                      variant="contained"
                      className="btn-save"
                      onClick={() => {
                        setAddcoursedetails(false);
                        setCourses(true);
                      }}
                    >
                      <img src={saveIcon} alt="save" />
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      className="btn-cancel"
                      onClick={() => {
                        setAddcoursedetails(false);
                        setCourses(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
      </main>

      {filterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <TextField
              id="course-name"
              label="Course Name "
              fullWidth
              variant="outlined"
            />
            <TextField
              id="course-num"
              label="Course Number"
              fullWidth
              variant="outlined"
            />
            <FormControl fullWidth className="select">
              <InputLabel id="stream-select-label">Stream </InputLabel>
              <Select
                labelId="stream-select-label"
                id="stream-select"
                label="Stream "
                value={stream}
                onChange={handlestreamChange}
              >
                <MenuItem value={1}>Event</MenuItem>
                <MenuItem value={2}>1</MenuItem>
                <MenuItem value={3}>2</MenuItem>
                <MenuItem value={4}>3</MenuItem>
                <MenuItem value={5}>4</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default DesignerCourses;
