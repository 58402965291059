export const UPCOMING_COURSE_LIST_REQUEST = "UPCOMING_COURSE_LIST_REQUEST";
export const UPCOMING_COURSE_LIST_SUCCESS = "UPCOMING_COURSE_LIST_SUCCESS";
export const UPCOMING_COURSE_LIST_FAILURE = "UPCOMING_COURSE_LIST_FAILURE";

export const PAST_STUDENT_COURSE_LIST_REQUEST =
  "PAST_STUDENT_COURSE_LIST_REQUEST";
export const PAST_STUDENT_COURSE_LIST_SUCCESS =
  "PAST_STUDENT_COURSE_LIST_SUCCESS";
export const PAST_STUDENT_COURSE_LIST_FAILURE =
  "PAST_STUDENT_COURSE_LIST_FAILURE";

export const COURSE_OFFERING_LIST_REQUEST = "COURSE_OFFERING_LIST_REQUEST";
export const COURSE_OFFERING_LIST_SUCCESS = "COURSE_OFFERING_LIST_SUCCESS";
export const COURSE_OFFERING_LIST_FAILURE = "COURSE_OFFERING_LIST_FAILURE";

export const STUDENT_DASHBOARD_COURSE_DETAILS_REQUEST =
  "STUDENT_DASHBOARD_COURSE_DETAILS_REQUEST";
export const STUDENT_DASHBOARD_COURSE_DETAILS_SUCCESS =
  "STUDENT_DASHBOARD_COURSE_DETAILS_SUCCESS";
export const STUDENT_DASHBOARD_COURSE_DETAILS_FAILURE =
  "STUDENT_DASHBOARD_COURSE_DETAILS_FAILURE";

export const APPLY_OR_WITHDRAW_COURSE_REQUEST =
  "APPLY_OR_WITHDRAW_COURSE_REQUEST";
export const APPLY_OR_WITHDRAW_COURSE_SUCCESS =
  "APPLY_OR_WITHDRAW_COURSE_SUCCESS";
export const APPLY_OR_WITHDRAW_COURSE_FAILURE =
  "APPLY_OR_WITHDRAW_COURSE_FAILURE";

export const MY_ENROLMENT_LIST_REQUEST = "MY_ENROLMENT_LIST_REQUEST";
export const MY_ENROLMENT_LIST_SUCCESS = "MY_ENROLMENT_LIST_SUCCESS";
export const MY_ENROLMENT_LIST_FAILURE = "MY_ENROLMENT_LIST_FAILURE";

export const DOWNLOAD_CERTIFICATE_REQUEST = "DOWNLOAD_CERTIFICATE_REQUEST";
export const DOWNLOAD_CERTIFICATE_SUCCESS = "DOWNLOAD_CERTIFICATE_SUCCESS";
export const DOWNLOAD_CERTIFICATE_FAILURE = "DOWNLOAD_CERTIFICATE_FAILURE";

export const CHANGE_COURSE_PREFERENCE_REQUEST =
  "CHANGE_COURSE_PREFERENCE_REQUEST";
export const CHANGE_COURSE_PREFERENCE_SUCCESS =
  "CHANGE_COURSE_PREFERENCE_SUCCESS";
export const CHANGE_COURSE_PREFERENCE_FAILURE =
  "CHANGE_COURSE_PREFERENCE_FAILURE";

export const STUDENT_DOCUMENTS_REQUEST = "STUDENT_DOCUMENTS_REQUEST";
export const STUDENT_DOCUMENTS_SUCCESS = "STUDENT_DOCUMENTS_SUCCESS";
export const STUDENT_DOCUMENTS_FAILURE = "STUDENT_DOCUMENTS_FAILURE";
