export const loginLogo = require("./logo-login.svg").default;
export const eyeOpen = require("./ic-eye-off.svg").default;
export const eyeClose = require("./ic-eye-on.svg").default;
export const checkIcon = require("./ic-checked.svg").default;
export const uncheckIcon = require("./ic-uncheck.svg").default;
export const headerLogo = require("./logo-header.svg").default;
export const headerLogo1 = require("./header-logo1.png");
export const mobileLogo1 = require("./header-logo1.png");
export const mobileLogo = require("./header-logo.svg").default;
export const arrowDownIcon = require("./ic-arrow-down.svg").default;
export const arrowProfileIcon = require("./ic-arrow-profile.svg").default;
export const coursesIcon = require("./ic-courses.svg").default;
export const dashboardIcon = require("./ic-dashboard.svg").default;
export const editIcon = require("./ic-edit.svg").default;
export const deleteIcon = require("./ic-delete.svg").default;
export const educatorsIcon = require("./ic-educators.svg").default;
export const educatorhomeIcon = require("./ic-educator-home.svg").default;
export const locationIcon = require("./ic-location.svg").default;
export const logoutIcon = require("./ic-logout.svg").default;
export const lockIcon = require("./ic-lock.svg").default;
export const offeringIcon = require("./ic-offering.svg").default;
export const profileIcon = require("./ic-profile.svg").default;
export const closeIcon = require("./ic-close.svg").default;
export const saveIcon = require("./ic-save.svg").default;
export const rolesIcon = require("./ic-roles.svg").default;
export const plusLightIcon = require("./ic-plus-light.svg").default;
export const plusPrimaryIcon = require("./ic-plus-primary.svg").default;
export const searchIcon = require("./ic-search.svg").default;
export const searchSecondaryIcon = require("./ic-search-secondary.svg").default;
export const termsIcon = require("./ic-terms.svg").default;
export const dropboxLogo = require("./logo-dropbox.svg").default;
export const mailchimpLogo = require("./logo-mailchimp.svg").default;
export const nswLogo = require("./logo-nsw.svg").default;
export const MenuIcon = require("./ic-menubar.svg").default;
export const educatorsTotalIcon = require("./ic-educators-total.svg").default;
export const offeringTotalIcon = require("./ic-offering-total.svg").default;
export const coursesTotalIcon = require("./ic-courses-total.svg").default;
export const arrowBackwardIcon = require("./ic-arrow-backward.svg").default;
export const arrowForwardIcon = require("./ic-arrow-forward.svg").default;
export const filterPrimaryIcon = require("./ic-filter-primary.svg").default;
export const filterLightIcon = require("./ic-filter-light.svg").default;
export const calendarIcon = require("./ic-calendar.svg").default;
export const attendanceIcon = require("./ic-attendance.svg").default;
export const pdfIcon = require("./ic-pdf.svg").default;
export const userIcon = require("./ic-user.svg").default;
export const courseListIcon = require("./ic-course-list.svg").default;
export const cloudPrimaryIcon = require("./ic-cloud-primary.svg").default;
export const cloudLightIcon = require("./ic-cloud-light.svg").default;
export const userSmallIcon = require("./ic-user-small.svg").default;
export const userSmallLightIcon = require("./ic-user-small-light.svg").default;
export const courseListSecondaryIcon =
  require("./ic-course-list-secondary.svg").default;
export const notificationCircle = require("./notifications-circle.svg").default;
export const courseListLightIcon =
  require("./ic-course-list-light.svg").default;
export const calendarFilledDarkIcon =
  require("./ic-calendar-filled-dark.svg").default;
export const calendarFilledLightIcon =
  require("./ic-calendar-filled-light.svg").default;
export const profile = require("./profile.png");
export const settingIcon = require("./ic-setting.svg").default;
export const cloudSecondaryIcon = require("./ic-cloud-secondary.svg").default;
export const cancelIcon = require("./ic-cancel.svg").default;
export const prevIcon = require("./ic-prev.svg").default;
export const prevIconDisabled = require("./ic-prev-disabled.svg").default;
export const prevIconLight = require("./ic-prev-light.svg").default;
export const nextIcon = require("./ic-next.svg").default;
export const radioIcon = require("./ic-radio.svg").default;
export const radioCheckedIcon = require("./ic-radio-checked.svg").default;
export const successfullIcon = require("./ic-successfull.svg").default;
export const studentIcon = require("./ic-student.svg").default;
export const menuVerticalIcon = require("./ic-menu-vertical.svg").default;
export const studentEnrolIcon = require("./ic-student-enroll.svg").default;
export const studentDashboardIcon =
  require("./ic-student-dashboard.svg").default;
export const addEnrolIcon = require("./ic-add-enrol.svg").default;
export const addOldEnrolIcon = require("./ic-add-old-enrol.svg").default;
export const enrolSummIcon = require("./ic-enrol-summ.svg").default;
export const sendMailIcon = require("./ic-send-mail.svg").default;
export const sendSMSIcon = require("./ic-send-sms.svg").default;
export const removeIcon = require("./ic-remove.svg").default;
export const approvedIcon = require("./ic-approved.svg").default;
export const backLoginIcon = require("./ic-login-back.svg").default;
export const arrowRightIcon = require("./ic-arrow-right.svg").default;
export const uploadIcon = require("./ic-upload.svg").default;
export const mailIcon = require("./ic-mail.svg").default;
export const mailSentIcon = require("./ic-mail-sent-already.svg").default;
export const userSidebarIcon = require("./ic-user-sidebar.svg").default;
export const documentSidebarIcon = require("./ic-document-list.svg").default;
export const documentPreviewIcon = require("./ic-document-preview.svg").default;
export const ArrowBackButtonIcon = require("./ic-arrow-back.svg").default;
export const confirmSubmitIcon = require("./ic-confirm-submit.svg").default;
export const downloadIcon = require("./ic-download-arrow.svg").default;
export const withdrawnIcon = require("./ic-withdrawn.svg").default;
export const nswIcon = require("./NSW.svg").default;
export const rcNewLogo = require("./RC_NEW_LOGO_large.png");
export const generateCertificate =
  require("./ic-generate-certificate.svg").default;
export const handbookIcon = require("./ic-handbook.svg").default;
export const courseGuideIcon = require("./ic-course-guide.svg").default;
export const termTimetableIcon = require("./ic-term-timetable.svg").default;
export const printIcon = require("./ic-print.svg").default;
export const certificateSettings =
  require("./ic-certificate-settings.svg").default;
export const callIcon = require("./ic-call.svg").default;
export const emailIcon = require("./ic-email.svg").default;
export const reportsIcon = require("./ic-reports.svg").default;
export const chevronDownIcon = require("./ic-chevron-down.svg").default;
export const sentSMSIcon = require("./ic-sms-sent.svg").default;
export const noDataIcon = require("./ic-no-data.svg").default;
export const manualIcon = require("./ic-manual-light.svg").default;
