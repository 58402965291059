import baseService from "services/base-service";
import { IRolePermission } from "./types";

const rolePermissionBaseUrl = `/api/RolePermission`;

const getRoleList = async (payload: {
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  role: string;
}) => baseService.post(`${rolePermissionBaseUrl}/GetRoles`, payload);

const addRole = async (payload: {
  role: string;
  permissions: Array<IRolePermission>;
}) => baseService.post(rolePermissionBaseUrl, payload);

const editRole = async (payload: {
  id: number;
  role: string;
  permissions: Array<IRolePermission>;
}) => baseService.put(rolePermissionBaseUrl, payload);

const deleteRole = async (payload: { id: number }) =>
  baseService.delete(`${rolePermissionBaseUrl}?id=${payload.id}`);

const getRolePermissions = async (payload: { id: number }) =>
  baseService.get(`${rolePermissionBaseUrl}?id=${payload.id}`);

const getModuleList = async () =>
  baseService.get(`${rolePermissionBaseUrl}/GetModules`);

export default {
  getRoleList,
  addRole,
  editRole,
  deleteRole,
  getRolePermissions,
  getModuleList,
};
