import ArrowForwardIcon from "components/ArrowForwardIcon";
import {
  Box,
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  IStudentAttendanceData,
  IStudentAttendanceDispatch,
  IStudentAttendanceListMeta,
  IStudentAttendanceListPagePayloadValues,
  IStudentAttendanceState,
} from "utils/interfaces/student";
import ArrowBackIcon from "components/ArrowBackIcon";
import { useEffect, useState } from "react";
import {
  BLENDED_FACE_TO_FACE_ONLINE,
  CLEAR_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
} from "utils/constants/constant";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import {
  allowSearch,
  base64ToArrayBuffer,
  getPagePermissions,
  getPaginationDetailText,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import {
  GenerateCertificateSuccessPayload,
  StudentAttendanceListSuccessPayload,
} from "store/student/types";
import { AppPages } from "utils/enums/app-pages";
import NoRecords from "components/NoRecords";
import { closeIcon, generateCertificate, printIcon } from "assets/images";
import { toast } from "react-toastify";
import { CERTIFICATE_GENERATED } from "utils/constants/Messages";
import { DownloadCertificateSuccessPayload } from "store/studentDashboard/types";
import GenerateCertificatePopUp from "./GenerateCertificateModal";

export type StudentAttendanceProps = IStudentAttendanceState &
  IStudentAttendanceDispatch;

const StudentAttendance: React.FC<StudentAttendanceProps> = (props) => {
  const [studentAttendanceList, setStudentAttendanceList] =
    useState<IStudentAttendanceData[]>();
  const [studentAttendanceListMeta, setStudentAttendanceListMeta] =
    useState<IStudentAttendanceListMeta>();

  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [searchText, setSearchText] = useState<string>("");
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const location = useLocation<{ studentId: number; studentName: string }>();
  const id = location?.state?.studentId;
  const studentName = location?.state?.studentName;
  const [studentCourseEnrolmentId, setStudentCourseEnrolmentId] =
    useState<number>(0);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
    useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const onStudentAttendanceListSuccess = (
    response: StudentAttendanceListSuccessPayload
  ) => {
    setStudentAttendanceList(response.studentAttendanceList);
    setStudentAttendanceListMeta(response.studentAttendanceListPagination);
  };

  const getStudentAttendanceList = async (filtered?: boolean) => {
    const { studnetAttendanceListRequest } = props;

    if (studnetAttendanceListRequest) {
      showLoader();
      const values: IStudentAttendanceListPagePayloadValues = {
        id: Number(id),
        pageNo: Number(pageNo),
        pageSize: Number(page),
        name: filtered ? "" : searchText.trim(),
      };
      const payload = {
        values,
        callback: onStudentAttendanceListSuccess,
      };
      studnetAttendanceListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Student);
    if (permission?.view) {
      getStudentAttendanceList();
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo]);

  const handleStudentSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getStudentAttendanceList(true);
  };

  const handleStudentSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getStudentAttendanceList();
    }
  };

  const handleGenerateCertificateModal = (enId: number) => {
    setStudentCourseEnrolmentId(enId);
    setIsOpenConfirmationModal(true);
  };

  const handleGenerateCertificateModalClose = () => {
    setIsOpenConfirmationModal(false);
    setStudentCourseEnrolmentId(0);
  };

  const onGenerateCertificateSuccess = (
    response: GenerateCertificateSuccessPayload
  ) => {
    if (response) {
      setIsOpenConfirmationModal(false);
      setStudentAttendanceList((prevList) =>
        prevList?.map((item) =>
          item.id === studentCourseEnrolmentId
            ? {
                ...item,
                isCertificateGenerated: item.isCertificateGenerated
                  ? item.isCertificateGenerated
                  : !item.isCertificateGenerated,
              }
            : item
        )
      );
      toast.success(CERTIFICATE_GENERATED);
    }
  };

  const handleApplyCourseYesClick = () => {
    const { generateCertificateRequest } = props;

    if (generateCertificateRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(studentCourseEnrolmentId),
        },
        callback: onGenerateCertificateSuccess,
      };

      generateCertificateRequest(payload);
    }
    setIsOpenConfirmationModal(false);
  };

  const onDownloadCertificateSuccess = (
    response: DownloadCertificateSuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.item);
    const file = new Blob([item], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.setProperty("print", "fit-to-print");
    iframe.src = fileURL;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      const win = iframe.contentWindow;
      if (win) {
        win.print();
      }
    };
  };

  const onDownloadCertificate = (courseEnrolmentId: number) => {
    const { downloadCertificateRequest } = props;

    if (downloadCertificateRequest) {
      showLoader();

      const payload = {
        values: { id: courseEnrolmentId },
        callback: onDownloadCertificateSuccess,
      };

      downloadCertificateRequest(payload);
    }
  };

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Attendance record
        </Typography>
        <Button
          variant="outlined"
          className="btn-back"
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </Button>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Card>
            <Box className="table-card-header">
              <Box
                sx={{ justifyContent: "space-between", width: "100%" }}
                className="search-filter-inputs"
              >
                <TextField
                  id="search-box"
                  variant="outlined"
                  className="search-input"
                  placeholder="Search"
                  onChange={handleStudentSearch}
                  onKeyDown={handleStudentSearchKeyDown}
                  value={searchText}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        sx={{ position: "absolute", right: "0" }}
                        position="end"
                      >
                        {searchText && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={CLEAR_TOOLTIP_TITLE}
                            arrow
                          >
                            <IconButton edge="end" onClick={handleClearSearch}>
                              <img src={closeIcon} alt="close" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) =>
                    onTextFieldKeyPress(event, searchText?.length)
                  }
                />

                <Typography variant="h5">
                  Student Name : <span className="mail-add">{studentName}</span>
                </Typography>
              </Box>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ width: "192px", minWidth: "192px" }}
                    >
                      Course
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "110px", minWidth: "110px" }}
                    >
                      Term
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "232px", minWidth: "232px" }}
                    >
                      Location
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "164px", minWidth: "164px" }}
                    >
                      Date of First Session
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "179px", minWidth: "179px" }}
                    >
                      Time and duration of each session
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "123px", minWidth: "123px" }}
                    >
                      No. Of Session in this Course
                    </TableCell>
                    <TableCell align="center" sx={{ width: "127px" }}>
                      Attendance
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ minWidth: "127px", width: "127px" }}
                    >
                      <span style={{ marginLeft: "10px" }}>Action</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentAttendanceList?.map((row) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body2"
                          className="sco-list-word-wrap"
                        >
                          {row?.course}
                        </Typography>
                      </TableCell>
                      <TableCell>{row?.term}</TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className="sco-list-word-wrap"
                        >
                          {row?.location}
                          {row.isBlended && BLENDED_FACE_TO_FACE_ONLINE}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {moment(row?.dateOfFirstSession).format("DD MMM YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(row?.startTimeOfSession).format("h:mm A")} to{" "}
                        {moment(row?.endTimeOfSession).format("h:mm A")}
                      </TableCell>
                      <TableCell align="center">{row?.noOfSession}</TableCell>
                      <TableCell
                        align="center"
                        className={`${row?.attendance.toLowerCase()} status`}
                      >
                        <div>{row.attendance}</div>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${row?.attendance.toLowerCase()} status`}
                      >
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={
                            row?.isCertificateGenerated
                              ? "Certificate Generated"
                              : "Generate Certificate"
                          }
                          arrow
                        >
                          <IconButton
                            onClick={() =>
                              row?.isCertificateGenerated
                                ? null
                                : handleGenerateCertificateModal(row.id)
                            }
                            className={
                              row?.isCertificateGenerated
                                ? "disabled-icon"
                                : "download-btn"
                            }
                          >
                            <img
                              src={generateCertificate}
                              alt="generate-certificated"
                            />
                          </IconButton>
                        </Tooltip>
                        {row.isCertificateGenerated && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Print Certificate"
                            arrow
                          >
                            <IconButton
                              onClick={() => onDownloadCertificate(row.id)}
                              className="download-btn"
                            >
                              <img src={printIcon} alt="print" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {studentAttendanceList && studentAttendanceList.length > 0 ? (
              <>
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                    <Typography variant="body2" component="span">
                      {getPaginationDetailText(
                        studentAttendanceListMeta,
                        pageNo,
                        page
                      )}
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={studentAttendanceListMeta?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={pageNo}
                    onChange={onPageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
                <Typography
                  variant="body2"
                  className="note"
                  sx={{ marginTop: { xs: "20px", lg: "41px" } }}
                >
                  Note: Successful attendance is defined as attending at least
                  75% of the course (by duration).
                </Typography>
              </>
            ) : (
              <NoRecords />
            )}
          </Card>
          <GenerateCertificatePopUp
            handleYesClick={handleApplyCourseYesClick}
            isOpenConfirmationModal={isOpenConfirmationModal}
            handleGenerateCertificateModalClose={
              handleGenerateCertificateModalClose
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StudentAttendance;
