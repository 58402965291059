import { Box } from "@mui/system";
import {
  Button,
  Card,
  Checkbox,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  calendarFilledDarkIcon,
  calendarFilledLightIcon,
  closeIcon,
  deleteIcon,
  editIcon,
} from "assets/images";
import React, { useEffect, useState } from "react";
import {
  DeleteScheduledOfferingSuccessPayload,
  ScheduledOfferingListSuccessPayload,
} from "store/scheduledOffering/types";
import {
  ISCOPageDetails,
  IScheduledOffering,
  IEnhancedTableProps,
  IScheduledOfferingListMeta,
  IScoPagePayloadValues,
} from "utils/interfaces/scheduled-offering";
import {
  allowSearch,
  getPagePayloadValues,
  getPaginationDetailText,
  hideLoaderForBatchApiCall,
  onTextFieldKeyPress,
  showLoader,
  showLoaderForBatchApiCall,
} from "utils/helper";
import { useDispatch } from "react-redux";
import {
  deleteScheduledOfferingRequest,
  scheduledOfferingListRequest,
} from "store/scheduledOffering/actions";
import {
  CLEAR_TOOLTIP_TITLE,
  DEFAULT_TEN_PAGE_SIZE,
  DEFAULT_PAGE_NO,
  DELETE_TOOLTIP_TITLE,
  EDIT_TOOLTIP_TITLE,
  MAX_INT_VALUE,
  PAGE_SIZES,
  SCO_CERTIFICATE,
  SCO_STATUS,
} from "utils/constants/constant";
import { useHistory } from "react-router-dom";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import { toast } from "react-toastify";
import { TermListSuccessPayload } from "store/term/types";
import { termListRequest } from "store/term/actions";
import { IRoleModulePermission } from "store/role/types";
import NoRecords from "components/NoRecords";
import { AppRoutings } from "utils/enums/app-routings";
import { SCO_DELETE_SUCCESS_MSG } from "utils/constants/Messages";
import { Order, OrderByOptions } from "utils/enums/sorting";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { ITerm, ITermPagePayloadValues } from "utils/interfaces/term";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof ISCOPageDetails) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

interface IScheduledOfferingSectionProps {
  scoPagePermission: IRoleModulePermission;
  coursePagePermission?: IRoleModulePermission;
}

const ScheduledOfferingSection: React.FC<IScheduledOfferingSectionProps> = ({
  scoPagePermission,
  coursePagePermission,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const [scheduledOfferingList, setScheduledOfferingList] =
    useState<IScheduledOffering[]>();
  const [scheduledOfferingListMeta, setScheduledOfferingListMeta] =
    useState<IScheduledOfferingListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_TEN_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [termList, setTermList] = useState<ITerm[]>();
  const [isOpenScoDeleteModal, setIsOpenScoDeleteModal] = useState(false);
  const [deleteScoId, setDeleteScoId] = useState<number>();
  const [terms, setTerms] = useState<string[]>([]);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof ISCOPageDetails>("courseNo");
  const handleTermChange = (event: SelectChangeEvent<typeof terms>) => {
    const {
      target: { value },
    } = event;
    setTerms(typeof value === "string" ? value.split(",") : value);
  };

  const termRenderValue = (selected: any) => {
    const selectedString: string[] = [];
    selected.forEach((item: number) => {
      const termItem = termList?.find((term) => term.id === item);
      if (termItem) {
        selectedString.push(termItem.termName);
      }
    });

    return selectedString.join(", ");
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof ISCOPageDetails) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onScheduledOfferingListSuccess = (
    response: ScheduledOfferingListSuccessPayload
  ) => {
    setScheduledOfferingList(response.scheduledOfferingList);
    setScheduledOfferingListMeta(response.scheduledOfferingListPagination);
    hideLoaderForBatchApiCall();
  };

  const getScheduledOfferingList = async (
    termIds?: Array<string>,
    filtered?: boolean
  ) => {
    showLoaderForBatchApiCall();

    const values: IScoPagePayloadValues = getPagePayloadValues(
      orderBy,
      order,
      pageNo,
      page
    );
    values.courseName = filtered ? "" : searchText.trim();

    if (termIds?.length) {
      values.terms = termIds.map((id) => +id);
    }

    const payload = {
      values: { ...values, isDashboard: true },
      callback: onScheduledOfferingListSuccess,
    };
    dispatch(scheduledOfferingListRequest(payload));
  };

  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
  };

  const getTermList = () => {
    showLoaderForBatchApiCall();

    const values: ITermPagePayloadValues = getPagePayloadValues(
      "TermName",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.termName = "";

    const payload = {
      values,
      callback: onTermListSuccess,
    };

    dispatch(termListRequest(payload));
  };

  useEffect(() => {
    if (scoPagePermission?.view) {
      if (terms?.length) {
        getScheduledOfferingList(terms);
      } else {
        getTermList();
        getScheduledOfferingList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoPagePermission, terms, order, orderBy, page, pageNo]);

  const handleScoSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getScheduledOfferingList(terms, true);
  };

  const handleScoSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      if (terms.length) {
        getScheduledOfferingList(terms);
      } else {
        getScheduledOfferingList();
      }
    }
  };

  const getStatusLabel = (status: number) => {
    let statusString = "";
    SCO_STATUS?.forEach((item) => {
      if (item.value === status) {
        statusString = item.label;
      }
    });

    return statusString;
  };

  const getCertificateAndEmrLabel = (value: number) => {
    let label = "";
    SCO_CERTIFICATE?.forEach((item) => {
      if (item.value === value) {
        label = item.label;
      }
    });

    return label;
  };

  const handleScoDeleteModal = (scoId: number) => {
    setDeleteScoId(scoId);
    setIsOpenScoDeleteModal(true);
  };

  const handleScoDeleteCloseModal = () => {
    setDeleteScoId(0);
    setIsOpenScoDeleteModal(false);
  };

  const onDeleteScoSuccess = (
    response: DeleteScheduledOfferingSuccessPayload
  ) => {
    if (response.success) {
      toast.success(SCO_DELETE_SUCCESS_MSG);
      handleScoDeleteCloseModal();
      getScheduledOfferingList();
    }
  };

  const handleDeleteSco = () => {
    showLoader();
    const payload = {
      values: {
        id: Number(deleteScoId),
      },
      callback: onDeleteScoSuccess,
    };

    dispatch(deleteScheduledOfferingRequest(payload));
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <Box className="table-card-header">
            <Typography variant="h3">Scheduled Offering</Typography>
            <Box>
              <TextField
                id="search-box"
                variant="outlined"
                className="search-input"
                placeholder="Search"
                value={searchText}
                onChange={handleScoSearch}
                onKeyDown={handleScoSearchKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{ position: "absolute", right: "0" }}
                      position="end"
                    >
                      {searchText && (
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={CLEAR_TOOLTIP_TITLE}
                          arrow
                        >
                          <IconButton edge="end" onClick={handleClearSearch}>
                            <img src={closeIcon} alt="close" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(event) =>
                  onTextFieldKeyPress(event, searchText?.length)
                }
              />
              <FormControl fullWidth className="table-select select">
                <InputLabel id="term-select-label">Select Term</InputLabel>
                <Select
                  labelId="term-select-label"
                  id="term-select"
                  multiple
                  label="Select Term"
                  value={terms}
                  onChange={handleTermChange}
                  input={<OutlinedInput label="Select Term" />}
                  renderValue={(selected) => termRenderValue(selected)}
                >
                  {termList?.map((term) => (
                    <MenuItem key={term.id} value={term.id}>
                      <Checkbox
                        checked={
                          (terms as unknown as Number[]).indexOf(term.id) > -1
                        }
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckedBoxIcon />}
                      />
                      <ListItemText primary={term.termName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                className="btn-view-all"
                onClick={() =>
                  history.push({
                    pathname: AppRoutings.ScheduledOfferings,
                  })
                }
              >
                View All
              </Button>
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: React.MouseEvent<unknown>) =>
                      handleRequestSort(e, "courseNo")
                    }
                    fieldName="Course#"
                    keyName="courseNo"
                    sxStyle={{
                      maxWidth: "115px",
                      width: "115px",
                      minWidth: "115px",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: React.MouseEvent<unknown>) =>
                      handleRequestSort(e, "course")
                    }
                    fieldName="Course"
                    keyName="course"
                    sxStyle={{
                      width: "154px",
                      minWidth: "154px",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: React.MouseEvent<unknown>) =>
                      handleRequestSort(e, "term")
                    }
                    fieldName="Term"
                    keyName="term"
                    sxStyle={{
                      width: "82px",
                      minWidth: "82px",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: React.MouseEvent<unknown>) =>
                      handleRequestSort(e, "location")
                    }
                    fieldName="Location"
                    keyName="location"
                    sxStyle={{
                      width: "146px",
                      minWidth: "146px",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: React.MouseEvent<unknown>) =>
                      handleRequestSort(e, "firstSession")
                    }
                    fieldName="First Session"
                    keyName="firstSession"
                    sxStyle={{
                      width: "150px",
                      minWidth: "150px",
                    }}
                  />
                  <TableCell
                    align="center"
                    sx={{ width: "120px", minWidth: "120px" }}
                  >
                    No. Of Session Days
                  </TableCell>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: React.MouseEvent<unknown>) =>
                      handleRequestSort(e, "certificateIssued")
                    }
                    fieldName="Certificates Issued?"
                    keyName="certificateIssued"
                    sxStyle={{
                      width: "103px",
                      minWidth: "103px",
                      textAlign: "center",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: React.MouseEvent<unknown>) =>
                      handleRequestSort(e, "EMREntered")
                    }
                    fieldName="EMR Entered"
                    keyName="EMREntered"
                    sxStyle={{
                      width: "90px",
                      minWidth: "90px",
                      textAlign: "center",
                    }}
                  />
                  <TableCell
                    align="center"
                    sx={{ width: "78px", minWidth: "78px" }}
                  >
                    No. Enrolled
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ width: "112px", minWidth: "112px" }}
                  >
                    Sessions with unsubmitted attendance
                  </TableCell>
                  <TableCell sx={{ width: "112px", minWidth: "112px" }}>
                    Educators
                  </TableCell>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: React.MouseEvent<unknown>) =>
                      handleRequestSort(e, "status")
                    }
                    fieldName="Status"
                    keyName="status"
                    sxStyle={{ width: "102px", textAlign: "center" }}
                  />
                  <TableCell align="center" sx={{ width: "92px" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scheduledOfferingList?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.courseDTO?.courseNo}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          if (coursePagePermission?.edit) {
                            history.push({
                              pathname: AppRoutings.EditCourse,
                              state: {
                                courseId: Number(row.courseID),
                              },
                            });
                          }
                        }}
                      >
                        <Typography
                          variant="body2"
                          className="sco-list-word-wrap"
                        >
                          {row.courseDTO?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {row.termsDTO?.termName}
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        className="sco-list-word-wrap"
                      >
                        {row.locationDTO?.location}
                        {row.locationDTO.isBlended &&
                          " - Blended (Face To Face / Online)"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {row.firstSession ? (
                          <>
                            {row.firstSession.split(",")[0]}
                            {row.firstSession.split(",")[1]}
                          </>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        disabled={!scoPagePermission?.edit}
                        className={
                          !scoPagePermission?.edit
                            ? "btn-course disabled-icon"
                            : "btn-course"
                        }
                        variant="outlined"
                        onClick={() =>
                          history.push({
                            pathname: AppRoutings.ScheduledOfferingsDetails,
                            state: {
                              scoId: Number(row.id),
                              autoScrollToSessions: true,
                            },
                          })
                        }
                      >
                        <img
                          src={calendarFilledDarkIcon}
                          alt="Course List"
                          className="default"
                        />
                        <img
                          src={calendarFilledLightIcon}
                          alt="Course List"
                          className="active"
                        />
                        <Typography variant="body2">
                          {row.noOfSessions}
                        </Typography>
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      {getCertificateAndEmrLabel(
                        Number(row.isCertificateIssued)
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {getCertificateAndEmrLabel(Number(row.isEMREntered))}
                    </TableCell>
                    <TableCell align="center">{row.noEnrolled}</TableCell>
                    <TableCell align="center">
                      {row.daysWithUnsubmittedAttendance}
                    </TableCell>
                    <TableCell sx={{ wordWrap: "break-word" }}>
                      {row?.educators || "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${getStatusLabel(
                        row.status
                      ).toLowerCase()} status`}
                    >
                      <div>{getStatusLabel(row.status)}</div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="table-actions">
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={EDIT_TOOLTIP_TITLE}
                          arrow
                        >
                          <IconButton
                            disabled={!scoPagePermission?.edit}
                            className={
                              !scoPagePermission?.edit ? "disabled-icon" : ""
                            }
                            onClick={() =>
                              history.push({
                                pathname: AppRoutings.ScheduledOfferingsDetails,
                                state: {
                                  scoId: Number(row.id),
                                  autoScrollToSessions: false,
                                },
                              })
                            }
                          >
                            <img src={editIcon} alt="edit" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={DELETE_TOOLTIP_TITLE}
                          arrow
                        >
                          <IconButton
                            disabled={!scoPagePermission?.delete}
                            className={
                              !scoPagePermission?.delete ? "disabled-icon" : ""
                            }
                            onClick={() => handleScoDeleteModal(row?.id)}
                          >
                            <img src={deleteIcon} alt="delete" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {scheduledOfferingList && scheduledOfferingList.length ? (
            <Box className="custom-pagination">
              <Box className="custom-rowperpage">
                <Typography variant="body2" component="span">
                  Page:
                </Typography>
                <Select
                  id="pagination-select"
                  value={page}
                  onChange={handleChange}
                  MenuProps={{
                    className: "pagination-menu",
                  }}
                >
                  {PAGE_SIZES?.map((pageSize) => (
                    <MenuItem
                      key={pageSize.value}
                      value={pageSize.value}
                      selected={pageSize?.selected}
                    >
                      {pageSize.label}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="body2" component="span">
                  {getPaginationDetailText(
                    scheduledOfferingListMeta,
                    pageNo,
                    page
                  )}
                </Typography>{" "}
              </Box>
              <Pagination
                count={scheduledOfferingListMeta?.totalPages}
                variant="outlined"
                shape="rounded"
                page={pageNo}
                onChange={onPageChange}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                  />
                )}
              />
            </Box>
          ) : (
            <NoRecords />
          )}
        </Card>
      </Grid>
      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenScoDeleteModal}
        handleDeleteConfirmationModalClose={handleScoDeleteCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete scheduled course offering?"
        handleYesClick={handleDeleteSco}
      />
    </>
  );
};

export default ScheduledOfferingSection;
