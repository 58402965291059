import {
  arrowProfileIcon,
  closeIcon,
  eyeClose,
  eyeOpen,
  headerLogo,
  lockIcon,
  logoutIcon,
  MenuIcon,
  mobileLogo,
  profile,
  profileIcon,
  saveIcon,
} from "assets/images";
import {
  AppBar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { AppRoutings } from "utils/enums/app-routings";

const DesignerHeader: React.FC = () => {
  const [profilemenu, setProfilemenu] = React.useState<null | HTMLElement>(
    null
  );
  const profileOpen = Boolean(profilemenu);
  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfilemenu(event.currentTarget);
  };
  const handleProfileClose = () => {
    setProfilemenu(null);
  };

  const [reset, setReset] = React.useState(false);
  const handleReset = () => setReset(true);
  const handleResetClose = () => setReset(false);

  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <AppBar position="static" className="header">
      <Toolbar
        disableGutters
        sx={{ flexWrap: "wrap" }}
        className="header-container"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="large"
            onClick={() => document.body.classList.toggle("sidebar-toggle")}
            // sx={{ display: { xs: "flex", md: "none" } }}
            className="btn-menu"
          >
            <img src={MenuIcon} alt="menu" />
          </IconButton>
          <a
            href={AppRoutings.DesignerDashboard}
            className="header-logo"
            title="South Eastern Sydney Recovery and Wellbeing College"
          >
            <img
              src={headerLogo}
              alt="South Eastern Sydney Recovery and Wellbeing College"
              className="desktop"
            />
            <img
              src={mobileLogo}
              alt="South Eastern Sydney Recovery and Wellbeing College"
              className="mobile"
            />
          </a>
        </Box>
        <Button
          id="profile-button"
          aria-controls={profileOpen ? "profile-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={profileOpen ? "true" : undefined}
          onClick={handleProfileClick}
          className="btn-profile"
          disableElevation
        >
          <img src={profile} alt="profile" />
          <Box className="profile-info">
            <Box>
              <Typography variant="h5">James Henry</Typography>
              <Typography variant="h6">Admin</Typography>
            </Box>
            <img src={arrowProfileIcon} alt="arrow" />
          </Box>
        </Button>
        <Menu
          id="profile-menu"
          anchorEl={profilemenu}
          open={profileOpen}
          onClose={handleProfileClose}
          MenuListProps={{
            "aria-labelledby": "profile-button",
          }}
          className="profile-menu"
        >
          <MenuItem
            onClick={handleProfileClose}
            sx={{ display: { xs: "flex", md: "none" } }}
            className="profile-info"
          >
            <Typography variant="h5">James Henry</Typography>
            <Typography variant="h6">Admin</Typography>
          </MenuItem>
          <MenuItem onClick={handleProfileClose} title="Profile">
            <Button href={AppRoutings.DesignerProfile}>
              <img src={profileIcon} alt="Profile" />
              <span>Profile</span>
            </Button>
          </MenuItem>
          <MenuItem onClick={handleProfileClose} title="Profile">
            <Button onClick={handleReset}>
              <img src={lockIcon} alt="Change password" />
              <span>Change Password</span>
            </Button>
          </MenuItem>
          <MenuItem onClick={handleProfileClose} title="Logout">
            <Button href={AppRoutings.DesignerLogin}>
              <img src={logoutIcon} alt="Logout" />
              <span>Logout</span>
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>

      <Modal open={reset} onClose={handleResetClose}>
        <Box className="common-modal reset-modal">
          <Box className="modal-header">
            <Typography variant="h4">Change Password</Typography>
            <IconButton onClick={handleResetClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <TextField
              id="current-pwd"
              type="password"
              label="Current Password"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="new-pwd"
              type="password"
              label="New Password"
              fullWidth
              variant="outlined"
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="password">Confirm Password</InputLabel>
              <OutlinedInput
                id="confirm-pwd"
                className="with-icon"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      disableFocusRipple
                      disableRipple
                    >
                      {showPassword ? (
                        <img src={eyeOpen} alt="show" />
                      ) : (
                        <img src={eyeClose} alt="hide" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleResetClose}
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleResetClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </AppBar>
  );
};

export default DesignerHeader;
