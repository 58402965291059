import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";
import { rootSaga } from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "main-root",
  storage,
  blacklist: [
    "location",
    "role",
    "term",
    "profile",
    "course",
    "educator",
    "scheduledOffering",
    "dashboard",
    "educatorHome",
    "studentEnrolment",
    "student",
    "user",
    "document",
    "studentDashboard",
    "report",
    "staffReport",
    "courseReport",
  ],
};

const persistedReducer: any = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;

export const persistor = persistStore(store);
