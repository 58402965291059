export enum NODE_ENV_TYPES {
  Development = "development",
  Staging = "staging",
  Production = "production",
}

const Config = {
  env1: process.env,
  env: {
    SiteKey: process.env.REACT_APP_SITE_KEY as string,
    // SecretKey: "6LekQTEpAAAAAFonyNp2uV_4fW-8TEJvm-NZwnEZ",
    NodeEnv: process.env.NODE_ENV,
    // BaseUrl: "https://api.knacksystem.web2.anasource.com",
    BaseUrl: process.env.REACT_APP_BASE_URL,
  },
};
export default Config;
