import {
  DELETE_STUDENT_FAILURE,
  DELETE_STUDENT_REQUEST,
  DELETE_STUDENT_SUCCESS,
  EDIT_CONFIRMATION_PREFERENCE_FAILURE,
  EDIT_CONFIRMATION_PREFERENCE_REQUEST,
  EDIT_CONFIRMATION_PREFERENCE_SUCCESS,
  EDIT_RESEARCH_PROFILE_DETAILS_FAILURE,
  EDIT_RESEARCH_PROFILE_DETAILS_REQUEST,
  EDIT_RESEARCH_PROFILE_DETAILS_SUCCESS,
  EDIT_STUDENTNOTE_FAILURE,
  EDIT_STUDENTNOTE_REQUEST,
  EDIT_STUDENTNOTE_SUCCESS,
  EDIT_STUDENT_DETAILS_FAILURE,
  EDIT_STUDENT_DETAILS_REQUEST,
  EDIT_STUDENT_DETAILS_SUCCESS,
  GET_CONFIRMATION_PREFERENCE_FAILURE,
  GET_CONFIRMATION_PREFERENCE_REQUEST,
  GET_CONFIRMATION_PREFERENCE_SUCCESS,
  GET_RESEARCH_PROFILE_DETAILS_FAILURE,
  GET_RESEARCH_PROFILE_DETAILS_REQUEST,
  GET_RESEARCH_PROFILE_DETAILS_SUCCESS,
  GET_STUDENTNOTE_FAILURE,
  GET_STUDENTNOTE_REQUEST,
  GET_STUDENTNOTE_SUCCESS,
  GET_STUDENT_DETAILS_FAILURE,
  GET_STUDENT_DETAILS_REQUEST,
  GET_STUDENT_DETAILS_SUCCESS,
  ENROLLMENT_SUMMARY_LIST_FAILURE,
  ENROLLMENT_SUMMARY_LIST_REQUEST,
  ENROLLMENT_SUMMARY_LIST_SUCCESS,
  STUDENT_LIST_FAILURE,
  STUDENT_LIST_REQUEST,
  STUDENT_LIST_SUCCESS,
  GET_STAFF_DETAILS_REQUEST,
  GET_STAFF_DETAILS_SUCCESS,
  GET_STAFF_DETAILS_FAILURE,
  EDIT_STAFF_DETAILS_REQUEST,
  EDIT_STAFF_DETAILS_SUCCESS,
  EDIT_STAFF_DETAILS_FAILURE,
  GET_MEDIA_CONSENT_REQUEST,
  GET_MEDIA_CONSENT_SUCCESS,
  GET_MEDIA_CONSENT_FAILURE,
  EDIT_MEDIA_CONSENT_REQUEST,
  EDIT_MEDIA_CONSENT_SUCCESS,
  EDIT_MEDIA_CONSENT_FAILURE,
  GET_PENDING_ENROLLMENT_LIST_REQUEST,
  GET_PENDING_ENROLLMENT_LIST_SUCCESS,
  GET_PENDING_ENROLLMENT_LIST_FAILURE,
  GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_FAILURE,
  GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_REQUEST,
  GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_SUCCESS,
  APPROVE_ENROLMENT_BY_EMAIL_SMS_REQUEST,
  APPROVE_ENROLMENT_BY_EMAIL_SMS_SUCCESS,
  APPROVE_ENROLMENT_BY_EMAIL_SMS_FAILURE,
  GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_FAILURE,
  GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_REQUEST,
  GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_SUCCESS,
  ADD_NEW_ENROLMENT_FROM_STUDENT_REQUEST,
  ADD_NEW_ENROLMENT_FROM_STUDENT_SUCCESS,
  ADD_NEW_ENROLMENT_FROM_STUDENT_FAILURE,
  RE_SEND_CONFIRMATION_MAIL_REQUEST,
  RE_SEND_CONFIRMATION_MAIL_SUCCESS,
  RE_SEND_CONFIRMATION_MAIL_FAILURE,
  STUDENT_ENROLMENT_WITHDRAWN_FAILURE,
  STUDENT_ENROLMENT_WITHDRAWN_REQUEST,
  STUDENT_ENROLMENT_WITHDRAWN_SUCCESS,
  CHANGE_CONFIRMATION_PREFERENCE_REQUEST,
  GENERATE_CERTIFICATE_REQUEST,
  GENERATE_CERTIFICATE_SUCCESS,
  GENERATE_CERTIFICATE_FAILURE,
  EDIT_STUDENT_PROFILE_DETAILS_FAILURE,
  EDIT_STUDENT_PROFILE_DETAILS_REQUEST,
  EDIT_STUDENT_PROFILE_DETAILS_SUCCESS,
  GET_STUDENT_PROFILE_DETAILS_FAILURE,
  GET_STUDENT_PROFILE_DETAILS_REQUEST,
  GET_STUDENT_PROFILE_DETAILS_SUCCESS,
  PRINT_ENROLMENT_SUMMARY_FAILURE,
  PRINT_ENROLMENT_SUMMARY_REQUEST,
  PRINT_ENROLMENT_SUMMARY_SUCCESS,
} from "./actionTypes";
import { StudentActions, StudentState } from "./types";

const initialState: StudentState = {
  pending: false,
  studentList: [],
  error: null,
  isConfirmationPreferenceChange: false,
};

const reducers = (state = initialState, action: StudentActions): unknown => {
  switch (action.type) {
    case STUDENT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case STUDENT_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        studentList: action.payload.studentList,
        error: null,
      };
    case STUDENT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_STUDENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_STUDENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ENROLLMENT_SUMMARY_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ENROLLMENT_SUMMARY_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        courseList: action.payload.enrollmentSummaryList,
        error: null,
      };
    case ENROLLMENT_SUMMARY_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_STUDENTNOTE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENTNOTE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_STUDENTNOTE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_STUDENTNOTE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_STUDENTNOTE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_STUDENTNOTE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_CONFIRMATION_PREFERENCE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_CONFIRMATION_PREFERENCE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_CONFIRMATION_PREFERENCE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_CONFIRMATION_PREFERENCE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_CONFIRMATION_PREFERENCE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_CONFIRMATION_PREFERENCE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_STUDENT_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_STUDENT_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_STUDENT_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_STUDENT_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_RESEARCH_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_RESEARCH_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_RESEARCH_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_RESEARCH_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_RESEARCH_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_RESEARCH_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_STAFF_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_STAFF_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_STAFF_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_STAFF_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case GET_MEDIA_CONSENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_MEDIA_CONSENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_MEDIA_CONSENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_MEDIA_CONSENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_MEDIA_CONSENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_MEDIA_CONSENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case GET_PENDING_ENROLLMENT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_PENDING_ENROLLMENT_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_PENDING_ENROLLMENT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_STUDENT_ENROLMENT_FOR_CONFIRMATION_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case APPROVE_ENROLMENT_BY_EMAIL_SMS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_ENROLMENT_BY_EMAIL_SMS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case APPROVE_ENROLMENT_BY_EMAIL_SMS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_CURRENT_COURSES_FOR_ADD_ENROLMENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case ADD_NEW_ENROLMENT_FROM_STUDENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_NEW_ENROLMENT_FROM_STUDENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_NEW_ENROLMENT_FROM_STUDENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case RE_SEND_CONFIRMATION_MAIL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case RE_SEND_CONFIRMATION_MAIL_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case RE_SEND_CONFIRMATION_MAIL_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case STUDENT_ENROLMENT_WITHDRAWN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case STUDENT_ENROLMENT_WITHDRAWN_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case STUDENT_ENROLMENT_WITHDRAWN_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case CHANGE_CONFIRMATION_PREFERENCE_REQUEST:
      return {
        ...state,
        pending: false,
        isConfirmationPreferenceChange:
          action.payload.isConfirmationPreferenceChange,
      };
    case GENERATE_CERTIFICATE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GENERATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GENERATE_CERTIFICATE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_STUDENT_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_STUDENT_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_STUDENT_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_STUDENT_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENT_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case GET_STUDENT_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case PRINT_ENROLMENT_SUMMARY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PRINT_ENROLMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        pending: false,
        item: action.payload.item,
        error: null,
      };
    case PRINT_ENROLMENT_SUMMARY_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
