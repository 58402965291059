import {
  COURSE_REPORT_LIST_REQUEST,
  COURSE_REPORT_LIST_SUCCESS,
  COURSE_REPORT_LIST_FAILURE,
  PRINT_COURSE_REPORT_REQUEST,
  PRINT_COURSE_REPORT_SUCCESS,
  PRINT_COURSE_REPORT_FAILURE,
} from "./actionTypes";
import {
  CourseReportListPayload,
  CourseReportListRequest,
  CourseReportListSuccessPayload,
  CourseReportListSuccess,
  CourseReportListFailurePayload,
  CourseReportListFailure,
  PrintCourseReportPayload,
  PrintCourseReportRequest,
  PrintCourseReportSuccessPayload,
  PrintCourseReportSuccess,
  PrintCourseReportFailurePayload,
  PrintCourseReportFailure,
} from "./types";

export const courseReportListRequest = (
  payload: CourseReportListPayload
): CourseReportListRequest => ({
  type: COURSE_REPORT_LIST_REQUEST,
  payload,
});

export const courseReportListSuccess = (
  payload: CourseReportListSuccessPayload
): CourseReportListSuccess => ({
  type: COURSE_REPORT_LIST_SUCCESS,
  payload,
});

export const courseReportListFailure = (
  payload: CourseReportListFailurePayload
): CourseReportListFailure => ({
  type: COURSE_REPORT_LIST_FAILURE,
  payload,
});

export const printCourseReportRequest = (
  payload: PrintCourseReportPayload
): PrintCourseReportRequest => ({
  type: PRINT_COURSE_REPORT_REQUEST,
  payload,
});

export const printCourseReportSuccess = (
  payload: PrintCourseReportSuccessPayload
): PrintCourseReportSuccess => ({
  type: PRINT_COURSE_REPORT_SUCCESS,
  payload,
});

export const printCourseReportFailure = (
  payload: PrintCourseReportFailurePayload
): PrintCourseReportFailure => ({
  type: PRINT_COURSE_REPORT_FAILURE,
  payload,
});
