import {
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_FAILURE,
  LOCATION_LIST_SUCCESS,
  ADD_LOCATION_REQUEST,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAILURE,
  EDIT_LOCATION_REQUEST,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_FAILURE,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAILURE,
} from "./actionTypes";
import {
  LocationListPayload,
  LocationListRequest,
  LocationListSuccess,
  LocationListSuccessPayload,
  LocationListFailure,
  LocationListFailurePayload,
  DeleteLocationPayload,
  DeleteLocationRequest,
  DeleteLocationSuccessPayload,
  DeleteLocationSuccess,
  DeleteLocationFailurePayload,
  DeleteLocationFailure,
  AddEditLocationPayload,
  AddLocationRequest,
  AddEditLocationSuccessPayload,
  AddLocationSuccess,
  AddEditLocationFailurePayload,
  AddLocationFailure,
  EditLocationSuccess,
  EditLocationRequest,
  EditLocationFailure,
} from "./types";

export const locationListRequest = (
  payload: LocationListPayload
): LocationListRequest => ({
  type: LOCATION_LIST_REQUEST,
  payload,
});

export const locationListSuccess = (
  payload: LocationListSuccessPayload
): LocationListSuccess => ({
  type: LOCATION_LIST_SUCCESS,
  payload,
});

export const locationListFailure = (
  payload: LocationListFailurePayload
): LocationListFailure => ({
  type: LOCATION_LIST_FAILURE,
  payload,
});

export const addLocationRequest = (
  payload: AddEditLocationPayload
): AddLocationRequest => ({
  type: ADD_LOCATION_REQUEST,
  payload,
});

export const addLocationSuccess = (
  payload: AddEditLocationSuccessPayload
): AddLocationSuccess => ({
  type: ADD_LOCATION_SUCCESS,
  payload,
});

export const addLocationFailure = (
  payload: AddEditLocationFailurePayload
): AddLocationFailure => ({
  type: ADD_LOCATION_FAILURE,
  payload,
});

export const editLocationRequest = (
  payload: AddEditLocationPayload
): EditLocationRequest => ({
  type: EDIT_LOCATION_REQUEST,
  payload,
});

export const editLocationSuccess = (
  payload: AddEditLocationSuccessPayload
): EditLocationSuccess => ({
  type: EDIT_LOCATION_SUCCESS,
  payload,
});

export const editLocationFailure = (
  payload: AddEditLocationFailurePayload
): EditLocationFailure => ({
  type: EDIT_LOCATION_FAILURE,
  payload,
});

export const deleteLocationRequest = (
  payload: DeleteLocationPayload
): DeleteLocationRequest => ({
  type: DELETE_LOCATION_REQUEST,
  payload,
});

export const deleteLocationSuccess = (
  payload: DeleteLocationSuccessPayload
): DeleteLocationSuccess => ({
  type: DELETE_LOCATION_SUCCESS,
  payload,
});

export const deleteLocationFailure = (
  payload: DeleteLocationFailurePayload
): DeleteLocationFailure => ({
  type: DELETE_LOCATION_FAILURE,
  payload,
});
