/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import {
  Button,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { closeIcon, saveIcon } from "assets/images";
import { useForm } from "react-hook-form";
import {
  CLOSE_TOOLTIP_TITLE,
  DOCUMENT_DESCRIPTION_MAX_LENGTH,
  DOCUMENT_NAME_MAX_LENGTH,
  DOCUMENT_TYPE,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  MAX_SIZE_IN_BYTES,
  RESTRICTED_FILE_TYPES,
  WARNING_DOCUMENT_TYPES,
} from "utils/constants/constant";
import { IAddEditDocumentRequest, IDocument } from "utils/interfaces/document";
import { useDropzone } from "react-dropzone";
import { onTextFieldKeyPress } from "utils/helper";
import { toast } from "react-toastify";
import {
  FILE_SIZE_EXCEEDED,
  ONLY_ONE_DOCUMENT_IS_ALLOWED_TO_UPLOAD,
  UNSUPPORTED_DOCUMENT,
  WHITE_SPACE_ERROR_SPACE,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER,
} from "utils/constants/Messages";

interface IAddEditDocumentProps {
  isEditDocument: boolean;
  isOpenDocumentModal: boolean;
  handleDocumentClose: any;
  handleSaveClick: any;
  editDocumentData?: IDocument;
}

const AddEditDocument: React.FC<IAddEditDocumentProps> = ({
  isEditDocument,
  isOpenDocumentModal,
  handleDocumentClose,
  handleSaveClick,
  editDocumentData,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IAddEditDocumentRequest>();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [showLink, setShowLink] = useState(true);
  const [showDrop, setShowDrop] = useState(true);
  const [showFileName, setShowFileName] = useState(false);
  const [warningMessageForFile, setWarningMessageForFile] =
    useState<boolean>(false);
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      const isSingleDocument = acceptedFiles.length === 1;
      if (!isSingleDocument)
        toast.error(ONLY_ONE_DOCUMENT_IS_ALLOWED_TO_UPLOAD);
      const isFileNotValid = RESTRICTED_FILE_TYPES.includes(
        acceptedFiles[0].type
      );
      if (acceptedFiles[0]?.size > MAX_SIZE_IN_BYTES) {
        toast.error(FILE_SIZE_EXCEEDED);

        return;
      }
      if (isFileNotValid) toast.error(UNSUPPORTED_DOCUMENT);
      if (!isFileNotValid && isSingleDocument) {
        setUploadedFiles(acceptedFiles);
        setValue("document", acceptedFiles[0]);
        setShowFileName(true);
      }
    },
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (isEditDocument && editDocumentData) {
      setValue("name", editDocumentData?.name);
      setValue("description", editDocumentData?.description);
      setValue("type", editDocumentData?.type);
    } else {
      reset();
    }

    if (!isEditDocument) {
      setValue("name", "");
      setValue("description", "");
      setValue("type", "");
    }
  }, [editDocumentData, isEditDocument, setValue, reset]);

  useEffect(() => {
    if (isEditDocument && editDocumentData) {
      reset({
        name: editDocumentData?.name,
        description: editDocumentData?.description,
        type: editDocumentData?.type,
      });
    }
  }, [isOpenDocumentModal, editDocumentData, isEditDocument, reset]);

  const getNameError = (): string => {
    if (errors.name) {
      if (errors.name.type === "required") {
        return "Document Name is required";
      }

      if (errors.name.type === "maxLength") {
        return `Maximum length of Document name should be ${DOCUMENT_NAME_MAX_LENGTH}`;
      }

      if (errors.name.type === "pattern") {
        return `Document Name ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER}`;
      }
    }

    return "";
  };

  const getTypeError = (): string => {
    const selectedType = watch("type");
    if (!selectedType || selectedType === "") {
      return "Document Type is required";
    }

    return "";
  };

  const getFileOrLinkError = (): string => {
    const file = watch("document");
    const val = watch("link");
    if (!isEditDocument) {
      if (errors?.link?.type === "required") {
        if (file === undefined && val === "") {
          return "Document or Link is required";
        }
      }
      if (errors?.link?.type === "pattern" && val !== "") {
        return `Document Link ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getDescriptionError = (): string => {
    if (errors.description) {
      if (errors.description.type === "maxLength") {
        return `Maximum length of description should be ${DOCUMENT_DESCRIPTION_MAX_LENGTH}`;
      }
      if (errors.description.type === "pattern") {
        return `Description should be ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const onModalClose = () => {
    setShowDrop(true);
    setShowLink(true);
    setUploadedFiles([]);
    setShowFileName(false);
    handleDocumentClose();
    reset();
  };

  useEffect(() => {
    if (WARNING_DOCUMENT_TYPES.includes(watch("type"))) {
      setWarningMessageForFile(true);
    } else {
      setWarningMessageForFile(false);
    }
    if (!isEditDocument) {
      const file = uploadedFiles[0];
      const val = watch("link");
      if (val !== "" && val !== undefined && file === undefined) {
        setShowLink(true);
        setShowDrop(false);
      } else if (file !== undefined && (val === "" || val === null)) {
        setShowLink(false);
        setShowDrop(true);
      } else {
        setShowDrop(true);
        setShowLink(true);
      }
      if (!isOpenDocumentModal) {
        setUploadedFiles([]);
      }
    }
  }, [
    watch("link"),
    watch("document"),
    editDocumentData,
    isOpenDocumentModal,
    watch("type"),
  ]);

  const files = uploadedFiles.map((file) => {
    return <li key={file.name.toString()}>{file?.name}</li>;
  });

  return (
    <>
      <Modal open={isOpenDocumentModal} onClose={onModalClose}>
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <Box className="common-modal documents-modal">
            <Box className="modal-header">
              <Typography variant="h2">
                {isEditDocument ? "Edit Document" : "Add Document"}
              </Typography>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={CLOSE_TOOLTIP_TITLE}
                arrow
              >
                <IconButton onClick={onModalClose}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="modal-body">
              <Grid container columnSpacing="24px">
                <Grid item xs={12} md={6} sm={12}>
                  <TextField
                    id="first-name"
                    label={
                      <>
                        Document Name <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("name")),
                    }}
                    error={!!errors.name}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, watch("name")?.length)
                    }
                    helperText={getNameError()}
                    {...register("name", {
                      required: true,
                      maxLength: DOCUMENT_NAME_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="term-status-label">
                      <>
                        Document Type
                        <span className="color-red"> *</span>
                      </>
                    </InputLabel>
                    <Select
                      labelId="term-status-label"
                      id="type"
                      label="type"
                      error={!!errors.type}
                      value={watch("type") || ""}
                      {...register("type", {
                        required: true,
                      })}
                    >
                      {DOCUMENT_TYPE?.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.type && (
                      <FormHelperText error>{getTypeError()}</FormHelperText>
                    )}
                    {warningMessageForFile && (
                      <FormHelperText className="document-type-text-warning">
                        Selecting{" "}
                        {
                          DOCUMENT_TYPE.find(
                            (doc) => doc.value === watch("type")
                          )?.label
                        }{" "}
                        may replace the existing one.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  {showLink && !isEditDocument && (
                    <TextField
                      id="link"
                      label="Document Link"
                      fullWidth
                      variant="outlined"
                      helperText={getFileOrLinkError()}
                      error={!!errors.link}
                      InputLabelProps={{
                        shrink: Boolean(watch("link")),
                      }}
                      {...register("link", {
                        required: showLink,
                        pattern:
                          INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                      })}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="description"
                    label="Description"
                    className="textarea-add"
                    fullWidth
                    multiline
                    InputLabelProps={{
                      shrink: Boolean(watch("description")),
                    }}
                    variant="outlined"
                    error={!!errors.description}
                    helperText={getDescriptionError()}
                    {...register("description", {
                      maxLength: DOCUMENT_DESCRIPTION_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
              </Grid>
              {showDrop && !isEditDocument && (
                <Grid item xs={12} sm={12} className="dropbox-container">
                  <Box className="dropbox-file-upload">
                    <Box>
                      <Box {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="document-modal-text">
                          Drag & Drop Your File Here
                        </p>
                        <p className="document-modal-or">OR</p>
                        <Box className="upload-document-btn-container">
                          <Button
                            className="upload-document-btn"
                            variant="outlined"
                            type="button"
                            onClick={open}
                          >
                            Browse Files
                          </Button>
                        </Box>
                      </Box>
                      {showFileName && (
                        <Box className="document-file-name">
                          <h4>File :</h4>
                          <ul> {files}</ul>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {!!errors.link && (
                    <FormHelperText error>
                      {getFileOrLinkError()}
                    </FormHelperText>
                  )}
                </Grid>
              )}
            </Box>
            <Box className="modal-footer">
              <Button variant="contained" className="btn-save" type="submit">
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                type="button"
                variant="outlined"
                className="btn-cancel"
                onClick={onModalClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default AddEditDocument;
