import {
  IStaffEnrolment,
  IStudentEnrolmentData,
  IStudentProfileDetails,
  ISwitchCourse,
  SelectedCourse,
} from "utils/interfaces/student-enrolment";
import baseService from "services/base-service";

const studentEnrolmentBaseUrl = `/api/StudentEnrolment`;

const getEnrolmentOptionList = async () =>
  baseService.get(`${studentEnrolmentBaseUrl}/GetEnrolmentOptions`);

const getCurrentCourseList = async () =>
  baseService.get(`${studentEnrolmentBaseUrl}/GetCurrentCourses`);

const addEnrolment = async (payload: {
  isStudentExists: boolean;
  eligibilityCriteria: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  phoneNumber: string;
  emailAddress: string;
  streetAddress: string;
  suburb: string;
  state: string;
  postCode: string;
  studentEnrolment: IStudentEnrolmentData;
  staffEnrolment?: IStaffEnrolment;
  studentProfileDetails?: IStudentProfileDetails;
  selectedCourses: Array<number>;
  preferredCourseDelivery: ISwitchCourse[];
}) => baseService.post(studentEnrolmentBaseUrl, payload);

const checkValidEnrolment = async (payload: {
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  emailAddress?: string;
}) =>
  baseService.post(`${studentEnrolmentBaseUrl}/CheckIsStudentValid`, payload);

const getStudentEnrolmentList = async (payload: {
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  name: string;
}) => baseService.post(`${studentEnrolmentBaseUrl}/GetEnrolments`, payload);

const getStudentEnrolmentDetail = async (payload: { id: number }) =>
  baseService.get(`${studentEnrolmentBaseUrl}?id=${payload.id}`);

const rejectEnrolment = async (payload: {
  studentId: number;
  rejectionMessage: string;
}) => baseService.put(`${studentEnrolmentBaseUrl}/RejectEnrolments`, payload);

const approveEnrollment = async (payload: {
  studentId: number;
  enrolmentConfirmPref: string;
  sector: string;
  researchCode: string;
  mrn: string;
  activeCommunityEmr: string;
  status: string;
  course: Array<SelectedCourse>;
}) => baseService.put(`${studentEnrolmentBaseUrl}/ApproveEnrolments`, payload);

const getStudentEnrolmentDataForApprove = async (payload: { id: number }) =>
  baseService.get(
    `${studentEnrolmentBaseUrl}/GetDataForApproval/?id=${payload.id}`
  );

const checkValidReCaptcha = async (payload: { reCaptchaToken: string }) =>
  baseService.post(`${studentEnrolmentBaseUrl}/CheckIsReCaptchaValid`, payload);

const studentEnrolmentWithdrawn = async (payload: { id: number }) =>
  baseService.put(
    `${studentEnrolmentBaseUrl}/WithdrawEnrolment?id=${payload.id}`
  );

const changeEnrolmentStatus = async (payload: {
  id: number;
  enrolmentStatus: number;
}) =>
  baseService.put(
    `${studentEnrolmentBaseUrl}/ChangeEnrolmentStatus?id=${payload.id}&enrolmentStatus=${payload.enrolmentStatus}`
  );

export default {
  getEnrolmentOptionList,
  getCurrentCourseList,
  addEnrolment,
  checkValidEnrolment,
  getStudentEnrolmentList,
  getStudentEnrolmentDetail,
  rejectEnrolment,
  approveEnrollment,
  getStudentEnrolmentDataForApprove,
  checkValidReCaptcha,
  studentEnrolmentWithdrawn,
  changeEnrolmentStatus,
};
