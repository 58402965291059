export const ISMEDIACONSENT = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const APPROVE_BY_EMAIL_TITLE = "Send Email approval";
export const APPROVE_BY_SMS_TITLE = "Send SMS approval";

export const APPROVE_BY_EMAIL_SUB_TITLE =
  "Are you sure want to send Email approval for below courses?";
export const APPROVE_BY_SMS_SUB_TITLE =
  "Are you sure want to send SMS approval for below courses?";
