import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Modal,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  attendanceIcon,
  calendarIcon,
  checkIcon,
  closeIcon,
  courseListSecondaryIcon,
  filterLightIcon,
  filterPrimaryIcon,
  pdfIcon,
  saveIcon,
  uncheckIcon,
  userIcon,
} from "assets/images";
import React, { useEffect } from "react";
import DesignerFooter from "components/designerFooter";
import AOS from "aos";
import "aos/dist/aos.css";
import projectTheme from "app.theme";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

function CalendarIcon() {
  return (
    <div className="calendar-icon">
      <img src={calendarIcon} alt="calendar" />
    </div>
  );
}

function CheckboxIcon() {
  return <img src={uncheckIcon} alt="checkbox" />;
}
function CheckedboxIcon() {
  return <img src={checkIcon} alt="checkbox" />;
}

interface CurrentCoursesData {
  course: string;
  term: string;
  enrollnum: number;
  location: string;
  datetime: string;
}

function createCurrentCoursesData(
  course: string,
  term: string,
  enrollnum: number,
  location: string,
  datetime: string
): CurrentCoursesData {
  return {
    course,
    term,
    enrollnum,
    location,
    datetime,
  };
}

const currentCoursesrow = [
  createCurrentCoursesData(
    "Education for Recovery: Foundations",
    "2023 T1",
    12,
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023 10:00 AM to 4:00 PM"
  ),
  createCurrentCoursesData(
    "Skills for Co-facilitations",
    "2023 T2",
    10,
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM to 2:00 PM"
  ),
];

interface pastCoursesData {
  sessname: string;
  term: string;
  location: string;
  datetime: string;
}

function createPastCoursesData(
  sessname: string,
  term: string,
  location: string,
  datetime: string
): pastCoursesData {
  return {
    sessname,
    term,
    location,
    datetime,
  };
}

const pastCoursesrow = [
  createPastCoursesData(
    "Challenging Stigma",
    "2023 T1",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    " 01 Feb 2023 10:00 AM to 4:00 PM"
  ),
  createPastCoursesData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createPastCoursesData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createPastCoursesData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createPastCoursesData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createPastCoursesData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
  createPastCoursesData(
    "Committee Work",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM - 2:00 PM"
  ),
];

interface enrollsData {
  stdname: string;
  mobile: string;
  emrcontact: string;
}

function createEnrollsData(
  stdname: string,
  mobile: string,
  emrcontact: string
): enrollsData {
  return {
    stdname,
    mobile,
    emrcontact,
  };
}

const enrollrows = [
  createEnrollsData("Rachael Rogan", "826384427", "8263827"),
  createEnrollsData("Michale Conte", "826384427", "9826382"),
  createEnrollsData("Katherina Stufano", "826384427", "6563827"),
  createEnrollsData("Katherina Stufano", "826384427", "6563827"),
  createEnrollsData("Katherina Stufano", "826384427", "6563827"),
];

interface attendanceData {
  student: string;
}

function createAttendanceData(student: string): attendanceData {
  return {
    student,
  };
}

const attendancerows = [
  createAttendanceData("Shane Lincoin"),
  createAttendanceData("Rachael Rogan"),
  createAttendanceData("Michale Conte"),
  createAttendanceData("Katherina Stufano"),
  createAttendanceData("Kamara Ducki"),
  createAttendanceData("Lydia Lin"),
];

const DesignerEducatorsHome: React.FC = () => {
  useEffect(() => {
    AOS.init({
      easing: "ease-in",
      duration: 500,
      startEvent: "load",
      offset: 50,
      once: true,
    });
    AOS.refresh();
  }, []);

  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [term, setterm] = React.useState("");

  const handletermChange = (event: SelectChangeEvent) => {
    setterm(event.target.value as string);
  };

  const [term1, setterm1] = React.useState("");

  const handleterm1Change = (event: SelectChangeEvent) => {
    setterm1(event.target.value as string);
  };

  const [year, setyear] = React.useState("");

  const handleyearChange = (event: SelectChangeEvent) => {
    setyear(event.target.value as string);
  };

  const [coursename, setCoursename] = React.useState("");

  const handlecoursenameChange = (event: SelectChangeEvent) => {
    setCoursename(event.target.value as string);
  };

  const [filterbox, setFilterbox] = React.useState(false);
  const [educatorshome, setEducatorshome] = React.useState(true);
  const [educatorsdetails, setEducatorsdetails] = React.useState(false);

  const [attendance, setAttendance] = React.useState(false);
  const handleAttendance = () => setAttendance(true);
  const handleAttendanceClose = () => setAttendance(false);

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Box
        className="filter-overlay"
        onClick={() => {
          document.body.classList.toggle("filter-open");
          setFilterbox(false);
        }}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        {educatorshome && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Educator Home
              </Typography>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h3">My Current Courses</Typography>
                      <Button
                        variant="outlined"
                        className="btn-filter"
                        onClick={() => {
                          setFilterbox(true);
                          document.body.classList.toggle("filter-open");
                        }}
                      >
                        <img
                          src={filterPrimaryIcon}
                          alt="filter"
                          className="default"
                        />
                        <img
                          src={filterLightIcon}
                          alt="filter"
                          className="active"
                        />
                        Filter
                      </Button>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              width: "302px",
                              minWidth: "302px",
                            }}
                          >
                            Course
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "110px", minWidth: "80px" }}
                          >
                            Term
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "110px", minWidth: "110px" }}
                          >
                            No. Enrolled
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ maxWidth: "392px", width: "392px" }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "170px", minWidth: "170px" }}
                          >
                            <Typography variant="body2">
                              Date & Time of First Session
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "108px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "80px",
                              },
                            }}
                          >
                            Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentCoursesrow.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.course}
                            </TableCell>
                            <TableCell>{row.term}</TableCell>
                            <TableCell>{row.enrollnum}</TableCell>
                            <TableCell>
                              <Typography variant="body2" className="location">
                                {row.location}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {row.datetime}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setEducatorsdetails(true);
                                    setEducatorshome(false);
                                  }}
                                >
                                  <img
                                    src={courseListSecondaryIcon}
                                    alt="list"
                                  />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <Typography variant="h3">My Past Courses</Typography>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              width: "302px",
                              minWidth: "302px",
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "210px",
                                minWidth: "210px",
                              },
                            }}
                          >
                            Session Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              width: "110px",
                              minWidth: "110px",
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "94px",
                                minWidth: "94px",
                              },
                            }}
                          >
                            Term
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "392px", minWidth: "392px" }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              width: "280px",
                              minWidth: "280px",
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "254px",
                                minWidth: "254px",
                              },
                            }}
                          >
                            Date & Time of First Session
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "108px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "90px",
                              },
                            }}
                          >
                            Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pastCoursesrow.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.sessname}
                            </TableCell>
                            <TableCell>{row.term}</TableCell>
                            <TableCell>
                              <Typography variant="body2" className="location">
                                {row.location}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.datetime}</TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setEducatorsdetails(true);
                                    setEducatorshome(false);
                                  }}
                                >
                                  <img
                                    src={courseListSecondaryIcon}
                                    alt="list"
                                  />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
        {educatorsdetails && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Educator Home Details
              </Typography>
              <Button
                variant="outlined"
                className="btn-back"
                onClick={() => {
                  setEducatorsdetails(false);
                  setEducatorshome(true);
                }}
              >
                Back
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                >
                  <Grid item xs={12} lg={6}>
                    <Card>
                      <Typography variant="h3" className="sco-heading">
                        Scheduled Course Offering Details
                      </Typography>
                      <Grid
                        container
                        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Course
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            320605_ENG Education for Recovery: Foundations
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Location
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            Recovery & wellbeing college, Kogarah BLENDED - Face
                            to Face/Online
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Number Enrolled
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            1
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Number Waitlisted
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            1
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Date & Time Of First Session
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            12 Jan 2023, 12:00 PM - 2:00 PM
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Sessions
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            1
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Term
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            2023 T1
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Status
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            Confirmed
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                    >
                      <Grid item xs={12}>
                        <Card>
                          <Typography variant="h3" className="attnd-heading">
                            Take Attendance
                          </Typography>
                          <Box className="attendance-group">
                            <Button
                              variant="outlined"
                              className="btn-attendance success btn-w-info"
                              onClick={handleAttendance}
                            >
                              <span className="data">
                                <span className="label">Session 1</span>
                                <span className="value">2 May 2023</span>
                              </span>
                              <img src={attendanceIcon} alt="icon" />
                            </Button>
                            <Button
                              variant="outlined"
                              className="btn-attendance warning btn-w-info"
                              onClick={handleAttendance}
                            >
                              <span className="data">
                                <span className="label">Session 2</span>
                                <span className="value">2 May 2023</span>
                              </span>
                              <img src={attendanceIcon} alt="icon" />
                            </Button>
                            <Button
                              variant="outlined"
                              className="btn-attendance"
                              onClick={handleAttendance}
                            >
                              Day 3
                              <img src={attendanceIcon} alt="icon" />
                            </Button>
                          </Box>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <List className="form-list">
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Completed Evaluation Forms" />
                                <img src={pdfIcon} alt="pdf" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Completed Learning Outcomes forms" />
                                <img src={pdfIcon} alt="pdf" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Blank Evaluation Forms" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Upload Completed Evaluation Forms" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Researcher’s Summary(Student evaluations - Qualitative Research)" />
                                <img src={pdfIcon} alt="pdf" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemText primary="Researcher’s Summary(Learning Outcomes - Quantitative Research)" />
                              </ListItemButton>
                            </ListItem>
                          </List>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Typography variant="h3" className="attnd-heading">
                        Educators
                      </Typography>
                      <Box className="educators-card-group">
                        <div role="link" className="educators-card">
                          <img src={userIcon} alt="user" />
                          <Box>
                            <Typography variant="body2" className="name">
                              Alsie Blayney
                            </Typography>
                            <Typography variant="body2" className="designation">
                              Peer Educator
                            </Typography>
                            <Link
                              type="email"
                              href="mailto: alsie.blayney@health.nsw.gov.au"
                            >
                              alsie.blayney@health.nsw.gov.au
                            </Link>
                          </Box>
                        </div>
                        <div role="link" className="educators-card">
                          <img src={userIcon} alt="user" />
                          <Box>
                            <Typography variant="body2" className="name">
                              Michelle McGerrell
                            </Typography>
                            <Typography variant="body2" className="designation">
                              Clinical Educator
                            </Typography>
                            <Link
                              type="email"
                              href="mailto: michelle.mcgerrell@health.nsw.gov.au"
                            >
                              michelle.mcgerrell@health.nsw.gov.au
                            </Link>
                          </Box>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Typography variant="h3" className="attnd-heading">
                        Educator Course Reflections Form
                      </Typography>
                      <Typography variant="body1" className="course-info-para">
                        As with any new initiative, we are all learning and
                        making changes as we go. We genuinely want to ensure
                        that the college is responsive to feedback form everyone
                        involved. As an educator involved with the development
                        and facilitation of a Recovery college course, we would
                        appreciate you completing this form and uploading it to
                        recovery college database.(Ideally to be done within 15
                        mins of course completion). In the spirit of co-
                        production, we encourage you to reflect on your
                        experience together.
                      </Typography>
                      <Typography variant="body1" className="course-info-para">
                        Your response will be only shared between Recovery
                        college team members to assist us in reviewing the
                        course and processes of the Recovery College.
                      </Typography>
                      <Grid
                        container
                        columnSpacing="24px"
                        sx={{ marginTop: "24px" }}
                      >
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="name-select-label">
                              Course Name
                            </InputLabel>
                            <Select
                              labelId="name-select-label"
                              id="name-select"
                              label="Course Name "
                              value={coursename}
                              onChange={handlecoursenameChange}
                            >
                              <MenuItem value={1}>
                                Education for Recovery: Foundations
                              </MenuItem>
                              <MenuItem value={2}>
                                Skills for Co-facilitations
                              </MenuItem>
                              <MenuItem value={3}>
                                An introduction to recovery & the strength of
                                approch
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                          <Grid container columnSpacing="24px">
                            <Grid item xs={12} sm={6}>
                              <FormControl fullWidth className="select">
                                <InputLabel id="term1-select-label">
                                  Term
                                </InputLabel>
                                <Select
                                  labelId="term1-select-label"
                                  id="term1-select"
                                  label="Term"
                                  value={term1}
                                  onChange={handleterm1Change}
                                >
                                  <MenuItem value={1}>2023 T1</MenuItem>
                                  <MenuItem value={2}>2023 T2</MenuItem>
                                  <MenuItem value={3}>2023 T3</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl fullWidth className="select">
                                <InputLabel id="year-select-label">
                                  Year
                                </InputLabel>
                                <Select
                                  labelId="year-select-label"
                                  id="year-select"
                                  label="Year"
                                  value={year}
                                  onChange={handleyearChange}
                                >
                                  <MenuItem value={1}>2021</MenuItem>
                                  <MenuItem value={2}>2022</MenuItem>
                                  <MenuItem value={3}>2023</MenuItem>
                                  <MenuItem value={3}>2024</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopDatePicker"]}>
                              <DesktopDatePicker
                                className="date-picker"
                                label="Form Filling Date"
                                slotProps={{
                                  textField: { fullWidth: true },
                                }}
                                slots={{
                                  openPickerIcon: CalendarIcon,
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="feedback"
                            label="What Went Well?"
                            multiline
                            className="suggestion-textarea"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="suggestion"
                            label="How Can We Continue  To Do This?"
                            multiline
                            className="suggestion-textarea"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="critics"
                            label="What Didn’t Go So Well?"
                            multiline
                            className="suggestion-textarea"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="suggestion2"
                            label="What Ideas Do You Have To Improve This?"
                            multiline
                            className="suggestion-textarea"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Box className="btn-group">
                        <Button variant="contained" className="btn-cancel">
                          Submit
                        </Button>
                        <Button variant="outlined" className="btn-cancel">
                          Cancel
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Box className="table-card-header">
                        <Box
                          sx={{
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography variant="h3">Enrollments</Typography>
                        </Box>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "153px",
                                  minWidth: "153px",
                                }}
                              >
                                Student Name
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ width: "144px", minWidth: "144px" }}
                              >
                                Mobile
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ width: "172px", minWidth: "172px" }}
                              >
                                Emergency Contact
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ maxWidth: "723px", minWidth: "350px" }}
                              >
                                Supports Required / Specific Learning Needs{" "}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {enrollrows.map((row) => (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {row.stdname}
                                </TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>{row.emrcontact}</TableCell>
                                <TableCell />
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box className="custom-pagination">
                        <Box className="custom-rowperpage">
                          <Typography variant="body2" component="span">
                            Page:
                          </Typography>
                          <Select
                            id="pagination-select"
                            value={page}
                            onChange={handleChange}
                            MenuProps={{
                              className: "pagination-menu",
                            }}
                          >
                            <MenuItem value={5} selected>
                              5
                            </MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                          </Select>
                          <Typography variant="body2" component="span">
                            Showing 10 - 20 of 50 entries
                          </Typography>{" "}
                        </Box>
                        <Pagination
                          count={5}
                          variant="outlined"
                          shape="rounded"
                          renderItem={(item) => (
                            <PaginationItem
                              slots={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                        />
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
      </main>
      <Modal open={attendance} onClose={handleAttendanceClose}>
        <Box className="common-modal attendance-modal">
          <Box className="modal-header">
            <Typography variant="h4">Enter Attendance</Typography>
            <IconButton onClick={handleAttendanceClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ maxWidth: "646px", minWidth: "200px" }}
                    >
                      Student
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "128px", minWidth: "128px" }}
                    >
                      Fill Attendance
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendancerows.map((row) => {
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {row.student}
                        </TableCell>
                        <TableCell align="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableFocusRipple
                                disableRipple
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckedboxIcon />}
                              />
                            }
                            label={undefined}
                            labelPlacement="end"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleAttendanceClose}
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleAttendanceClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      {filterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <TextField
              id="search"
              variant="outlined"
              className="search-input"
              placeholder="Search"
              fullWidth
            />
            <TextField
              id="course"
              label="Course "
              fullWidth
              variant="outlined"
            />
            <FormControl fullWidth className="select">
              <InputLabel id="term-select-label">Term</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                label="Term "
                value={term}
                onChange={handletermChange}
              >
                <MenuItem value={1}>2023 T1</MenuItem>
                <MenuItem value={2}>2023 T2</MenuItem>
                <MenuItem value={3}>2023 T3</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default DesignerEducatorsHome;
