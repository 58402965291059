import { Box } from "@mui/system";
import { Button, Card, Fade, Tooltip, Typography } from "@mui/material";
import { attendanceIcon } from "assets/images";
import {
  IEducatorHomeScoDetails,
  IHomeScoSession,
} from "utils/interfaces/educator-home";
import { DATE_VIEW_FORMAT } from "utils/constants/constant";
import { convertToDateTime } from "utils/helper";

interface ITakeAttendanceSectionProps {
  sessionsList: Array<IHomeScoSession>;
  handleAttendance: any;
  scoDetails: IEducatorHomeScoDetails | undefined;
}

const TakeAttendanceSection: React.FC<ITakeAttendanceSectionProps> = ({
  sessionsList,
  handleAttendance,
  scoDetails,
}) => {
  return (
    <Card>
      <Typography variant="h3" className="attnd-heading">
        Take Attendance
      </Typography>
      <Box className="attendance-group">
        {sessionsList?.map((session: IHomeScoSession) => (
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title="Enter Attendance"
            arrow
          >
            <Button
              variant="outlined"
              className={`btn-attendance btn-w-info ${
                // eslint-disable-next-line no-nested-ternary
                new Date(session.sessionStartDateTime) < new Date() &&
                scoDetails?.noEnrolled !== 0
                  ? session.isAttendanceFilled
                    ? "success"
                    : "warning"
                  : ""
              }`}
              onClick={() => handleAttendance(session.id)}
              key={session.id}
              disabled={new Date(session.sessionStartDateTime) > new Date()}
            >
              <span className="data">
                <span className="label"> Session {session.dayNumber}</span>
                <span className="value">
                  {" "}
                  {convertToDateTime(
                    session?.sessionStartDateTime,
                    DATE_VIEW_FORMAT
                  )}
                </span>
              </span>

              <img src={attendanceIcon} alt="icon" />
            </Button>
          </Tooltip>
        ))}
      </Box>
    </Card>
  );
};

export default TakeAttendanceSection;
