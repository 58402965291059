import {
  STATISTICS_DATA_REQUEST,
  STATISTICS_DATA_SUCCESS,
  STATISTICS_DATA_FAILURE,
} from "./actionTypes";
import { DashboardActions, DashboardState } from "./types";

const initialState: DashboardState = {
  pending: false,
  statisticsData: {},
  error: null,
};

const reducers = (state = initialState, action: DashboardActions): unknown => {
  switch (action.type) {
    case STATISTICS_DATA_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case STATISTICS_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        statisticsData: action.payload.statisticsData,
        error: null,
      };
    case STATISTICS_DATA_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default reducers;
