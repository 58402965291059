import { cloudSecondaryIcon, deleteIcon, pdfIcon } from "assets/images";
import {
  Button,
  Card,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import {
  SCO_DOCUMENT_TYPE,
  SCO_DOC_SUPPORTED_TYPES,
} from "utils/constants/constant";
import { IScoDocument } from "utils/interfaces/scheduled-offering";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { UPLOAD_PDF_OR_WORD_FILE } from "utils/constants/Messages";
import { getScoDocAcceptedTypes } from "utils/helper";

interface IDocumentsListProps {
  documentsList: Array<IScoDocument>;
  onDownloadDocument: any;
  onUploadDocument: any;
  courseOfferingId?: number;
  handleDeleteDocModal: any;
}

const DocumentsList: React.FC<IDocumentsListProps> = ({
  documentsList,
  onDownloadDocument,
  onUploadDocument,
  courseOfferingId,
  handleDeleteDocModal,
}) => {
  const [scoDocuments, setScoDocuments] = useState<any[]>(SCO_DOCUMENT_TYPE);

  useEffect(() => {
    if (documentsList && documentsList.length > 0) {
      const updatedDocList = scoDocuments.map((scoDoc) => {
        const uploadedDoc = documentsList.find((doc) => doc.type === scoDoc.id);

        if (uploadedDoc) {
          scoDoc = {
            ...scoDoc,
            docId: uploadedDoc.id,
            docName: uploadedDoc.name,
            courseOfferingID: uploadedDoc.courseOfferingID,
            type: uploadedDoc.type,
          };
        } else {
          scoDoc = SCO_DOCUMENT_TYPE.find((doc) => doc.id === scoDoc.id);
        }

        return scoDoc;
      });

      setScoDocuments(updatedDocList);
    } else {
      setScoDocuments(SCO_DOCUMENT_TYPE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsList]);

  const handleUploadForm = async (event: React.ChangeEvent<any>, doc: any) => {
    const { files } = event.target;
    const file = files[0];

    if (!SCO_DOC_SUPPORTED_TYPES.includes(file?.type)) {
      toast.error(UPLOAD_PDF_OR_WORD_FILE);
    } else {
      onUploadDocument(file.name, doc.id, courseOfferingId, file);
    }
  };

  return (
    <Card>
      <List className="form-list">
        {scoDocuments?.map((doc) => (
          <ListItem key={doc.id}>
            <ListItemButton>
              <ListItemText
                primary={doc.name}
                secondary={doc.docName ? `(${doc.docName})` : ""}
              />

              {doc.docId ? (
                <>
                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Download Document"
                    arrow
                  >
                    <IconButton
                      className="upload-icon"
                      onClick={() =>
                        onDownloadDocument(
                          doc.docName,
                          doc.type,
                          doc.courseOfferingID
                        )
                      }
                    >
                      <img src={pdfIcon} alt="pdf" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Delete Document"
                    arrow
                  >
                    <IconButton
                      className="upload-icon"
                      onClick={() =>
                        handleDeleteDocModal({
                          docId: doc.docId,
                          type: doc.type,
                          courseOfferingID: doc.courseOfferingID,
                        })
                      }
                    >
                      <img src={deleteIcon} alt="delete" />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title="Upload Document"
                  arrow
                >
                  <Button component="label" className="btn-doc-upload">
                    <img src={cloudSecondaryIcon} alt="upload" />
                    <input
                      hidden
                      accept={getScoDocAcceptedTypes()}
                      type="file"
                      onChange={(e: React.ChangeEvent<any>) =>
                        handleUploadForm(e, doc)
                      }
                    />
                  </Button>
                </Tooltip>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default DocumentsList;
