import { Box } from "@mui/system";
import { Fade, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import { closeIcon } from "assets/images";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface ICancelCommentModalProps {
  isOpenCancelCommentModal: boolean;
  handleCancelCommentModalClose: () => void;
  comment: string;
}

const CancelCourseCommentModal: React.FC<ICancelCommentModalProps> = ({
  isOpenCancelCommentModal,
  handleCancelCommentModalClose,
  comment,
}) => {
  const handleModalClose = () => {
    handleCancelCommentModalClose();
  };

  return (
    <>
      <Modal
        open={isOpenCancelCommentModal}
        onClose={handleCancelCommentModalClose}
      >
        <Box className="common-modal delete-modal rejection-modal">
          <Box className="modal-header">
            <Typography variant="h4">Course Cancellation Comment</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body">
            <Typography
              variant="h5"
              sx={{
                paddingTop: "5px",
                paddingBottom: "15px",
              }}
            >
              {comment}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CancelCourseCommentModal;
