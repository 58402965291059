import { Grid, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  MY_ENROLMENT_COURSES,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
} from "utils/constants/constant";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  allowSearch,
  getPagePayloadValuesWithId,
  getPagePermissions,
  hideLoaderForBatchApiCall,
  showLoaderForBatchApiCall,
  toggleFilter,
} from "utils/helper";
import { useHistory } from "react-router-dom";
import {
  ICourseOfferingFilter,
  IMyEnrolmentContainerDispatch,
  IMyEnrolmentCourse,
  IMyEnrolmentFilter,
  IMyEnrolmentPagePayloadValues,
  IPastCourses,
  IStudentDashboardListMeta,
} from "utils/interfaces/student-dashboard";
import {
  MyEnrolmentListSuccessPayload,
  MyEnrolmentState,
} from "store/studentDashboard/types";
import { useSelector } from "react-redux";
import StudentCourseTable from "components/StudentCoursesTable";
import { IRoleModulePermission } from "store/role/types";
import { AppPages } from "utils/enums/app-pages";
import { SELECT_MIN_ONE_FIELD } from "utils/constants/Messages";
import { toast } from "react-toastify";
import OverlayBox from "components/OverlayBox";
import MyEnrolmentFilter from "./MyEnrolmentFilter";

export type MyEnrolmentProps = MyEnrolmentState & IMyEnrolmentContainerDispatch;

const MyEnrolmentComponent: React.FC<MyEnrolmentProps> = (props) => {
  const history = useHistory();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const user = useSelector((state: any) => state?.auth?.user);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IPastCourses>("courseName");
  const [filteredData, setFilteredData] = useState<ICourseOfferingFilter>();
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [myEnrolmentList, setMyEnrolmentList] =
    useState<IMyEnrolmentCourse[]>();
  const [filterBox, setFilterBox] = useState(false);
  const [courseOfferingListMeta, setCourseOfferingListMeta] =
    useState<IStudentDashboardListMeta>();
  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: any) => {
    setSearchText(event?.target?.value);
    setFilteredData((prevState: any) => ({
      ...prevState,
      name: event?.target?.value,
    }));
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event?.target?.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IPastCourses) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onCourseOfferingListSuccess = (
    response: MyEnrolmentListSuccessPayload
  ) => {
    setMyEnrolmentList(response?.myEnrolmentList);
    setCourseOfferingListMeta(response?.myEnrolmentListPagination);
    hideLoaderForBatchApiCall();
  };

  const getMyEnrolmentsList = (filterData?: IMyEnrolmentFilter) => {
    const { myEnrolmentListRequest } = props;
    if (myEnrolmentListRequest) {
      showLoaderForBatchApiCall();
      const values: IMyEnrolmentPagePayloadValues = getPagePayloadValuesWithId(
        user?.studentId,
        orderBy,
        order,
        pageNo,
        page
      );
      if (filterData?.name) {
        values.name = filterData?.name;
      }
      if (filterData?.term) {
        values.term = filterData?.term;
      }
      if (filterData?.locationName) {
        values.locationName = filterData?.locationName;
      }
      if (filterData?.status != null) {
        values.status = filterData?.status;
      }

      if (filterData) {
        setFilteredData(filterData);
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onCourseOfferingListSuccess,
      };
      myEnrolmentListRequest(payload);
    }
  };

  const handleSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getMyEnrolmentsList(filteredData);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.MyEnrolment,
      "student"
    );
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData) {
        getMyEnrolmentsList(filteredData);
      } else {
        getMyEnrolmentsList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, order, orderBy, page]);

  const handleFilter = () => {
    setFilterBox(true);
    toggleFilter();
  };

  const handleFilterApplyClick = (formData: IMyEnrolmentFilter) => {
    if (
      !(
        formData.name ||
        formData.term ||
        formData.locationName ||
        formData.status !== null
      )
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      setSearchText(formData?.name);
      if (pageNo === DEFAULT_PAGE_NO) {
        getMyEnrolmentsList(formData);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      toggleFilter();
    }
  };

  const clearSearchText = () => {
    setSearchText("");
    setFilteredData((prevFilteredData: any) => ({
      ...prevFilteredData,
      name: "",
    }));
    getMyEnrolmentsList({
      name: "",
      locationName: filteredData?.locationName ? filteredData?.locationName : 0,
      term: filteredData?.term ? filteredData?.term : 0,
    });
  };

  const clearFilter = () => {
    setSearchText("");
    setFilteredData((prevFilteredData: any) => ({
      ...prevFilteredData,
      name: "",
    }));
  };

  return (
    <>
      {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
      <Grid item xs={12}>
        <StudentCourseTable
          coursesList={myEnrolmentList}
          pagePermission={pagePermission}
          courseListMeta={courseOfferingListMeta}
          handleRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          handleChange={handleChange}
          onPageChange={onPageChange}
          page={page}
          pageNo={pageNo}
          courseListType={MY_ENROLMENT_COURSES}
          handleFilter={handleFilter}
          searchText={searchText}
          handleSearch={handleSearch}
          handleSearchKeyDown={handleSearchKeyDown}
          handleClearSearch={clearSearchText}
        />
        {filterBox && (
          <MyEnrolmentFilter
            filterBox={filterBox}
            setFilterBox={setFilterBox}
            handleApplyClick={handleFilterApplyClick}
            filteredData={filteredData}
            pageNo={pageNo}
            setPageNo={setPageNo}
            setFilteredData={setFilteredData}
            getUpcomingCoursesList={getMyEnrolmentsList}
            clearSearchText={clearFilter}
          />
        )}
      </Grid>
    </>
  );
};

export default MyEnrolmentComponent;
