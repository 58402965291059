import { Box } from "@mui/system";
import {
  Modal,
  Grid,
  IconButton,
  Typography,
  Fade,
  Tooltip,
} from "@mui/material";
import { closeIcon, downloadIcon } from "assets/images";
import { convertToDateTimeWithFormat } from "utils/helper";
import { DATE_VIEW_FORMAT } from "utils/constants/constant";
import dayjs from "dayjs";
import {
  IScoDetails,
  ICourseReflectionsForm,
} from "utils/interfaces/scheduled-offering";

interface IEducatorCourseReflectionFormProps {
  isEducatorDetailsOpen: boolean;
  handleEducatorDetailsClose: () => void;
  scheduledOfferingDetails: IScoDetails;
  reflectionsFormData?: ICourseReflectionsForm;
  handlePrintEducatorReflectionForm: () => void;
}

const EducatorCourseReflectionForm: React.FC<IEducatorCourseReflectionFormProps> =
  ({
    isEducatorDetailsOpen,
    handleEducatorDetailsClose,
    scheduledOfferingDetails,
    reflectionsFormData,
    handlePrintEducatorReflectionForm,
  }) => {
    return (
      <>
        <Modal
          open={isEducatorDetailsOpen}
          onClose={handleEducatorDetailsClose}
        >
          <Box className="common-modal educators-details-modal">
            <Box className="modal-header">
              <Typography variant="h4">Educator Course Reflections</Typography>
              <Box>
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title="Export"
                  arrow
                >
                  <IconButton onClick={handlePrintEducatorReflectionForm}>
                    <img src={downloadIcon} alt="download" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title="Close"
                  arrow
                >
                  <IconButton onClick={handleEducatorDetailsClose}>
                    <img src={closeIcon} alt="close" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box className="modal-body">
              <Grid
                container
                columnSpacing={{ xs: "16px", lg: "24px" }}
                rowSpacing="16px"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="title">
                    Course Name
                  </Typography>
                  <Typography variant="body1" className="value">
                    {scheduledOfferingDetails?.courseDTO.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="title">
                    Form Filling Date
                  </Typography>
                  <Typography variant="body1" className="value">
                    {!reflectionsFormData?.date
                      ? "-"
                      : convertToDateTimeWithFormat(
                          reflectionsFormData?.date,
                          DATE_VIEW_FORMAT
                        )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="title">
                    Term
                  </Typography>
                  <Typography variant="body1" className="value">
                    {scheduledOfferingDetails?.termsDTO.termName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="title">
                    Year
                  </Typography>
                  <Typography variant="body1" className="value">
                    {dayjs(
                      scheduledOfferingDetails.startDateOfFirstSession
                    ).format("YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="title">
                    What Went Well?
                  </Typography>
                  <Typography variant="body1" className="value">
                    {reflectionsFormData?.whatWentWell || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="title">
                    How Can We Continue To Do This?
                  </Typography>
                  <Typography variant="body1" className="value">
                    {reflectionsFormData?.howCanWeContinueToDoThis || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="title">
                    What Didn’t Go So Well?
                  </Typography>
                  <Typography variant="body1" className="value">
                    {reflectionsFormData?.whatDidNotGoSoWell || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className="title">
                    What Ideas Do You Have To Improve This?
                  </Typography>
                  <Typography variant="body1" className="value">
                    {reflectionsFormData?.whatIdeasDoYouHaveToImproveThis ||
                      "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </>
    );
  };

export default EducatorCourseReflectionForm;
