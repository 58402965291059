import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  closeIcon,
  deleteIcon,
  editIcon,
  filterLightIcon,
  filterPrimaryIcon,
  plusLightIcon,
} from "assets/images";
import React, { useEffect, useState } from "react";
import projectTheme from "app.theme";
import { Order, OrderByOptions } from "utils/enums/sorting";
import { IRoleModulePermission } from "store/role/types";
import {
  IAddEditTerm,
  IEnhancedTableProps,
  ITerm,
  ITermContainerDispatch,
  ITermContainerState,
  ITermListMeta,
  ITermsDetails,
  ITermPagePayloadValues,
} from "utils/interfaces/term";
import {
  CLEAR_TOOLTIP_TITLE,
  DATE_VIEW_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DELETE_TOOLTIP_TITLE,
  EDIT_TOOLTIP_TITLE,
  MAX_INT_VALUE,
  PAGE_SIZES,
  TERM_STATUS,
} from "utils/constants/constant";
import {
  allowSearch,
  checkEndDateLessThanStart,
  convertToDateTime,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AddEditTermSuccessPayload,
  DeleteTermSuccessPayload,
  TermByIdSuccessPayload,
  TermListSuccessPayload,
} from "store/term/types";
import { toast } from "react-toastify";
import {
  END_DATE_SHOULD_GREATER_MSG,
  SELECT_MIN_ONE_FIELD,
  SELECT_TERM_DATE_MSG,
  TERM_ADD_SUCCESS_MSG,
  TERM_DELETE_SUCCESS_MSG,
  TERM_EDIT_SUCCESS_MSG,
} from "utils/constants/Messages";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import NoRecords from "components/NoRecords";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { AppPages } from "utils/enums/app-pages";
import { ExportToCsv } from "export-to-csv";
import AddEditTerm from "./AddEditTerm";
import TermFilter from "./TermFilter";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof ITermsDetails) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type TermProps = ITermContainerState & ITermContainerDispatch;

const Terms: React.FC<TermProps> = (props) => {
  const [termList, setTermList] = useState<ITerm[]>();
  const [termListMeta, setTermListMeta] = useState<ITermListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof ITermsDetails>("termName");
  const [isOpenTermModal, setIsOpenTermModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [isEditTerm, setIsEditTerm] = useState<boolean>(false);
  const [editTermData, setEditTermData] = useState<ITerm>();
  const [isOpenTermDeleteModal, setIsOpenTermDeleteModal] = useState(false);
  const [deleteTermId, setDeleteTermId] = useState<number>();
  const [filterBox, setFilterBox] = useState(false);
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [filteredData, setFilteredData] = useState<IAddEditTerm>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
  const user = useSelector((state: any) => state?.auth?.user);
  const history = useHistory();

  const getFormattedDate = (date: any) => {
    const stringDate = new Date(date?.toString());
    const formattedDate = convertToDateTime(stringDate);

    return formattedDate;
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof ITermsDetails) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
    setTermListMeta(response.termListPagination);
  };

  const getTermList = async (
    filterData?: IAddEditTerm,
    filtered?: boolean,
    pageNum?: number
  ) => {
    const { termListRequest } = props;

    if (termListRequest) {
      showLoader();

      const values: ITermPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.termName = filtered ? "" : searchText.trim();
      values.isList = true;

      if (filterData?.term) {
        values.term = filterData?.term;
      }
      if (filterData?.year) {
        values.year = filterData?.year;
      }
      if (filterData?.status) {
        values.status = filterData?.status;
      }
      if (filterData?.startDate && filterData?.startDate !== "Invalid date") {
        values.startDate = getFormattedDate(filterData?.startDate);
      }
      if (filterData?.endDate && filterData?.endDate !== "Invalid date") {
        values.endDate = getFormattedDate(filterData?.endDate);
      }
      if (filterData) {
        const filter = {
          ...filterData,
          startDate: getFormattedDate(filterData?.startDate),
          endDate: getFormattedDate(filterData?.endDate),
        };

        setFilteredData(filter);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onTermListSuccess,
      };

      termListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Terms);
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getTermList(filteredData, true, 1);
      } else if (filteredData) {
        getTermList(filteredData);
      } else {
        getTermList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const handleTermSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getTermList(filteredData, true, 1);
  };

  const handleTermSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getTermList(filteredData);
    }
  };

  const onGetTermSuccess = (response: TermByIdSuccessPayload) => {
    if (response.termDetails) {
      setEditTermData(response.termDetails);
    }
  };

  const handleGetTerm = (id: number) => {
    const { termByIdRequest } = props;

    if (termByIdRequest) {
      showLoader();
      const payload = {
        values: {
          id,
        },
        callback: onGetTermSuccess,
      };

      termByIdRequest(payload);
    }
  };

  const handleTerm = (isEdit: boolean, editData: ITerm | null) => {
    setIsEditTerm(isEdit);
    if (editData) {
      setEditTermData(editData);
      handleGetTerm(editData.id);
    } else {
      setEditTermData(undefined);
    }
    setIsOpenTermModal(true);
  };

  const handleTermClose = () => setIsOpenTermModal(false);

  const handleTermDeleteModal = (termId: number) => {
    setDeleteTermId(termId);
    setIsOpenTermDeleteModal(true);
  };
  const handleTermDeleteCloseModal = () => {
    setDeleteTermId(0);
    setIsOpenTermDeleteModal(false);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const onAddTermSuccess = (response: AddEditTermSuccessPayload) => {
    if (response.success) {
      toast.success(TERM_ADD_SUCCESS_MSG);
      handleTermClose();
      getTermList();
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleAddTerm = (formData: IAddEditTerm) => {
    const { addTermRequest } = props;

    if (addTermRequest) {
      showLoader();
      const payload = {
        values: {
          termName: formData.termName.trim(),
          term: formData.term,
          year: formData.year,
          startDate: getFormattedDate(formData.startDate),
          endDate: getFormattedDate(formData.endDate),
          status: formData.status,
        },
        callback: onAddTermSuccess,
      };

      addTermRequest(payload);
    }
  };

  const onEditTermSuccess = (response: AddEditTermSuccessPayload) => {
    if (response.success) {
      toast.success(TERM_EDIT_SUCCESS_MSG);
      handleTermClose();
      getTermList();
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleEditTerm = (formData: IAddEditTerm) => {
    const { editTermRequest } = props;

    if (editTermRequest) {
      showLoader();
      const payload = {
        values: {
          id: editTermData?.id,
          termName: formData.termName.trim(),
          term: formData.term,
          year: formData.year,
          startDate: getFormattedDate(formData.startDate),
          endDate: getFormattedDate(formData.endDate),
          status: formData.status,
        },
        callback: onEditTermSuccess,
      };

      editTermRequest(payload);
    }
  };

  const onDeleteTermSuccess = (response: DeleteTermSuccessPayload) => {
    if (response.success) {
      toast.success(TERM_DELETE_SUCCESS_MSG);
      handleTermDeleteCloseModal();
      getTermList();
    }
  };

  const handleDeleteTerm = () => {
    const { deleteTermRequest } = props;

    if (deleteTermRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(deleteTermId),
        },
        callback: onDeleteTermSuccess,
      };

      deleteTermRequest(payload);
    }
  };

  const handleFilterApplyClick = (formData: IAddEditTerm) => {
    if (
      !(
        formData.term ||
        formData.year ||
        formData.status ||
        formData.startDate ||
        formData.endDate
      )
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else if (
      (formData.startDate && !formData.endDate) ||
      (!formData.startDate && formData.endDate)
    ) {
      toast.error(SELECT_TERM_DATE_MSG);
    } else {
      const endDateError = checkEndDateLessThanStart(
        formData.startDate,
        formData.endDate
      );

      if (endDateError) {
        toast.error(END_DATE_SHOULD_GREATER_MSG);
      } else {
        setFilteredData(formData);
        setSearchText("");
        if (pageNo === DEFAULT_PAGE_NO) {
          getTermList(formData, true, 1);
        } else {
          setPageNo(1);
        }
        setFilterBox(false);
        document.body.classList.toggle("filter-open");
      }
    }
  };

  const getStatusText = (statusId: number) => {
    let statusText = "";
    TERM_STATUS?.forEach((status) => {
      if (status.value === statusId) {
        const split = status.label.split(" ");
        statusText = split?.[0]?.toLowerCase();
      }
    });

    return statusText;
  };

  const csvFileHeaders = [
    "Term Name",
    "Term",
    "Year",
    "Term Dates",
    "Term Status",
    "Courses Delivered",
    "Avg. Number Enrolled Per Course",
    "Avg. Attendance Per Course",
  ];

  const getTermModifiedData = (list: ITerm[]) => {
    const data = list?.map((term: ITerm) => {
      return {
        "Term Name": term.termName,
        Term: term.term,
        Year: term.year,
        "Term Dates": `${convertToDateTime(
          term.startDate,
          DATE_VIEW_FORMAT
        )} to ${convertToDateTime(term.endDate, DATE_VIEW_FORMAT)}`,
        "Term Status": getStatusText(term.status),
        "Courses Delivered": term.coursesDelivered,
        "Avg. Number Enrolled Per Course": term.avgEnrolledPerCourse,
        "Avg. Attendance Per Course": term.avgAttendancePerCourse,
      };
    });

    return data;
  };

  const onExportTermListSuccess = (response: TermListSuccessPayload) => {
    const modifiedTermData = getTermModifiedData(response.termList);
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeaders,
      filename: "Terms",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedTermData);
  };

  const getExportTerms = () => {
    const { termListRequest } = props;

    if (termListRequest) {
      showLoader();

      const values: ITermPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );
      values.termName = searchText.trim();
      values.isList = true;
      if (filteredData?.term) {
        values.term = filteredData?.term;
      }
      if (filteredData?.year) {
        values.year = filteredData?.year;
      }
      if (filteredData?.status) {
        values.status = filteredData?.status;
      }
      if (
        filteredData?.startDate &&
        filteredData?.startDate !== "Invalid date"
      ) {
        values.startDate = getFormattedDate(filteredData?.startDate);
      }
      if (filteredData?.endDate && filteredData?.endDate !== "Invalid date") {
        values.endDate = getFormattedDate(filteredData?.endDate);
      }

      const payload = {
        values,
        callback: onExportTermListSuccess,
      };

      termListRequest(payload);
    }
  };

  return (
    <div>
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Box
        className="filter-overlay"
        onClick={() => {
          document.body.classList.toggle("filter-open");
          setFilterBox(false);
        }}
      />

      <>
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Terms
          </Typography>

          <Box sx={{ display: "flex", columnGap: "15px" }}>
            <Button
              variant="contained"
              onClick={() => handleTerm(false, null)}
              className="btn-add"
              disabled={!pagePermission?.add}
            >
              <img src={plusLightIcon} alt="plus" />
              Add
            </Button>
            <Button
              variant="outlined"
              className="btn-export"
              onClick={getExportTerms}
            >
              Export
            </Button>
          </Box>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Card>
              <Box className="table-card-header">
                <Box
                  sx={{ justifyContent: "space-between", width: "100%" }}
                  className="search-filter-inputs"
                >
                  <TextField
                    id="search-box"
                    variant="outlined"
                    className="search-input"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleTermSearch}
                    onKeyDown={handleTermSearchKeyDown}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ position: "absolute", right: "0" }}
                          position="end"
                        >
                          {searchText && (
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={CLEAR_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                edge="end"
                                onClick={handleClearSearch}
                              >
                                <img src={closeIcon} alt="close" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, searchText?.length)
                    }
                  />

                  <Button
                    variant="outlined"
                    className="btn-filter"
                    onClick={() => {
                      setFilterBox(true);
                      document.body.classList.toggle("filter-open");
                    }}
                  >
                    <img
                      src={filterPrimaryIcon}
                      alt="filter"
                      className="default"
                    />
                    <img
                      src={filterLightIcon}
                      alt="filter"
                      className="active"
                    />
                    <span>Filter</span>
                  </Button>
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "termName")}
                        fieldName="Term Name"
                        keyName="termName"
                        sxStyle={{
                          maxWidth: "116px",
                          width: "116px",
                          minWidth: "116px",
                        }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "term")}
                        fieldName="Term"
                        keyName="term"
                        sxStyle={{
                          width: "79px",
                          minWidth: "79px",
                          textAlign: "center",
                        }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "year")}
                        fieldName="Year"
                        keyName="year"
                        sxStyle={{
                          width: "80px",
                          minWidth: "80px",
                          textAlign: "center",
                        }}
                      />
                      <TableCell
                        align="left"
                        sx={{ width: "230px", minWidth: "230px" }}
                      >
                        Term Dates
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ width: "137px", minWidth: "137px" }}
                      >
                        Term Status
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "97px", minWidth: "97px" }}
                      >
                        Courses Delivered
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "170px", minWidth: "170px" }}
                      >
                        Avg. Number Enrolled Per Course
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "142px", minWidth: "142px" }}
                      >
                        Avg. Attendance Per Course
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          width: "100px",
                          [projectTheme.breakpoints.down("sm")]: {
                            width: "112px",
                          },
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {termList?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="ellipsis-text"
                        >
                          {row.termName}
                        </TableCell>
                        <TableCell align="center">{row.term}</TableCell>
                        <TableCell align="center">{row.year}</TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {convertToDateTime(row.startDate, DATE_VIEW_FORMAT)}{" "}
                            to{" "}
                            {convertToDateTime(row.endDate, DATE_VIEW_FORMAT)}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={`${getStatusText(row.status)} status`}
                        >
                          <div>{getStatusText(row.status)} Term</div>
                        </TableCell>
                        <TableCell align="center">
                          {row.coursesDelivered}
                        </TableCell>
                        <TableCell align="center">
                          {row.avgEnrolledPerCourse}
                        </TableCell>
                        <TableCell align="center">
                          {row.avgAttendancePerCourse}
                        </TableCell>
                        <TableCell align="center">
                          <div className="table-actions">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={EDIT_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                onClick={() => handleTerm(true, row)}
                                disabled={!pagePermission?.edit}
                                className={
                                  !pagePermission?.edit ? "disabled-icon" : ""
                                }
                              >
                                <img src={editIcon} alt="edit" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={DELETE_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                onClick={() => handleTermDeleteModal(row?.id)}
                                disabled={!pagePermission?.delete}
                                className={
                                  !pagePermission?.delete ? "disabled-icon" : ""
                                }
                              >
                                <img src={deleteIcon} alt="delete" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {termList && termList.length > 0 ? (
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      {PAGE_SIZES?.map((pageSize) => (
                        <MenuItem
                          key={pageSize.value}
                          value={pageSize.value}
                          selected={pageSize?.selected}
                        >
                          {pageSize.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" component="span">
                      {getPaginationDetailText(termListMeta, pageNo, page)}
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={termListMeta?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={pageNo}
                    onChange={onPageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              ) : (
                <NoRecords />
              )}
            </Card>
          </Grid>
        </Grid>
      </>

      <AddEditTerm
        isEditTerm={isEditTerm}
        isOpenTermModal={isOpenTermModal}
        handleTermClose={handleTermClose}
        handleSaveClick={isEditTerm ? handleEditTerm : handleAddTerm}
        editTermData={editTermData}
      />

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenTermDeleteModal}
        handleDeleteConfirmationModalClose={handleTermDeleteCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete term?"
        handleYesClick={handleDeleteTerm}
      />

      {filterBox && (
        <TermFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          getTermList={getTermList}
          setAppliedFilter={setAppliedFilter}
        />
      )}
    </div>
  );
};

export default Terms;
