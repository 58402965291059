import { connect, MapDispatchToProps } from "react-redux";
import AddEditEducatorComponent, {
  AddEditEducatorProps,
} from "pages/Educators/AddEditEducator";
import {
  addEducatorRequest,
  deleteEducatorRequest,
  editEducatorRequest,
  educatorListRequest,
  educatorByIdRequest,
  educatorStatisticsDataRequest,
} from "store/educator/actions";
import {
  IEducatorContainerDispatch,
  IEducator,
} from "utils/interfaces/educator";

const mapDispatchToProps: MapDispatchToProps<
  IEducatorContainerDispatch,
  IEducator
> = {
  educatorListRequest,
  addEducatorRequest,
  editEducatorRequest,
  deleteEducatorRequest,
  educatorByIdRequest,
  educatorStatisticsDataRequest,
};

const connector = connect(null, mapDispatchToProps);

const AddEditEducator: React.FC<AddEditEducatorProps> = (props) => {
  return <AddEditEducatorComponent {...props} />;
};

export default connector(AddEditEducator);
