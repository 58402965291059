import {
  ENROLMENT_OPTION_LIST_REQUEST,
  ENROLMENT_OPTION_LIST_SUCCESS,
  ENROLMENT_OPTION_LIST_FAILURE,
  CURRENT_ENROL_COURSE_LIST_REQUEST,
  CURRENT_ENROL_COURSE_LIST_SUCCESS,
  CURRENT_ENROL_COURSE_LIST_FAILURE,
  ADD_ENROLMENT_REQUEST,
  ADD_ENROLMENT_SUCCESS,
  ADD_ENROLMENT_FAILURE,
  CHECK_VALID_ENROLMENT_REQUEST,
  CHECK_VALID_ENROLMENT_SUCCESS,
  CHECK_VALID_ENROLMENT_FAILURE,
  STUDENT_ENROLMENT_LIST_REQUEST,
  STUDENT_ENROLMENT_LIST_SUCCESS,
  STUDENT_ENROLMENT_LIST_FAILURE,
  STUDENT_DETAIL_REQUEST,
  STUDENT_DETAIL_SUCCESS,
  STUDENT_DETAIL_FAILURE,
  REJECT_ENROLMENT_REQUEST,
  REJECT_ENROLMENT_SUCCESS,
  REJECT_ENROLMENT_FAILURE,
  APPROVE_ENROLMENT_REQUEST,
  APPROVE_ENROLMENT_SUCCESS,
  APPROVE_ENROLMENT_FAILURE,
  GET_STUDENT_ENROLMENT_FOR_APPROVAL_FAILURE,
  GET_STUDENT_ENROLMENT_FOR_APPROVAL_REQUEST,
  GET_STUDENT_ENROLMENT_FOR_APPROVAL_SUCCESS,
  CHECK_VALID_RECAPTCHA_FAILURE,
  CHECK_VALID_RECAPTCHA_REQUEST,
  CHECK_VALID_RECAPTCHA_SUCCESS,
  CHANGE_ENROLMENT_STATUS_FAILURE,
  CHANGE_ENROLMENT_STATUS_REQUEST,
  CHANGE_ENROLMENT_STATUS_SUCCESS,
} from "./actionTypes";
import { StudentEnrolmentActions, StudentEnrolmentState } from "./types";

const initialState: StudentEnrolmentState = {
  pending: false,
  enrolmentOptionList: [],
  error: null,
};

const reducers = (
  state = initialState,
  action: StudentEnrolmentActions
): unknown => {
  switch (action.type) {
    case ENROLMENT_OPTION_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ENROLMENT_OPTION_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        enrolmentOptionList: action.payload.enrolmentOptionList,
        error: null,
      };
    case ENROLMENT_OPTION_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case CURRENT_ENROL_COURSE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CURRENT_ENROL_COURSE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        currentCourseList: action.payload.currentCourseList,
        error: null,
      };
    case CURRENT_ENROL_COURSE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_ENROLMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_ENROLMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_ENROLMENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case CHECK_VALID_ENROLMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHECK_VALID_ENROLMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case CHECK_VALID_ENROLMENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case STUDENT_ENROLMENT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case STUDENT_ENROLMENT_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        studentEnrolmentList: action.payload.studentEnrolmentList,
        error: null,
      };
    case STUDENT_ENROLMENT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case STUDENT_DETAIL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case STUDENT_DETAIL_SUCCESS:
      return {
        ...state,
        pending: false,
        studentDetail: action.payload.studentDetail,
        error: null,
      };
    case STUDENT_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case REJECT_ENROLMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REJECT_ENROLMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case REJECT_ENROLMENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case APPROVE_ENROLMENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case APPROVE_ENROLMENT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case APPROVE_ENROLMENT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_STUDENT_ENROLMENT_FOR_APPROVAL_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_STUDENT_ENROLMENT_FOR_APPROVAL_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload.studentApproveEnrolmentData,
        error: null,
      };
    case GET_STUDENT_ENROLMENT_FOR_APPROVAL_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case CHECK_VALID_RECAPTCHA_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHECK_VALID_RECAPTCHA_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case CHECK_VALID_RECAPTCHA_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case CHANGE_ENROLMENT_STATUS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_ENROLMENT_STATUS_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case CHANGE_ENROLMENT_STATUS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
