import { Box } from "@mui/system";
import {
  Button,
  Fade,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  CLOSE_TOOLTIP_TITLE,
  DATE_PICKER_FORMAT,
  DEFAULT_PAGE_NO,
} from "utils/constants/constant";
import { IStudentFilter } from "utils/interfaces/student";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CalendarIcon from "components/CalendarIcon";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { onNumberFieldKeyPress, onTextFieldKeyPress } from "utils/helper";

interface IStudentFilterProps {
  filterBox: boolean;
  setFilterBox: any;
  handleApplyClick: any;
  filteredData?: IStudentFilter;
  pageNo: number;
  setPageNo: any;
  setFilteredData: any;
  getStudentList: any;
}
const defaultValues: IStudentFilter = {
  email: "",
  mobile: "",
  dateOfBirth: undefined,
  startDate: undefined,
  endDate: undefined,
};

const StudentFilter: React.FC<IStudentFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getStudentList,
}) => {
  const { register, handleSubmit, reset, control, setValue, watch } =
    useForm<IStudentFilter>({ defaultValues });

  useEffect(() => {
    if (filteredData) {
      setValue("email", filteredData?.email);
      setValue("mobile", filteredData?.mobile);
      if (filteredData?.dateOfBirth !== "Invalid date") {
        setValue("dateOfBirth", filteredData?.dateOfBirth);
      }
      if (filteredData?.startDate !== "Invalid date") {
        setValue("startDate", filteredData?.startDate);
      }
      if (filteredData?.endDate !== "Invalid date") {
        setValue("endDate", filteredData?.endDate);
      }
    } else {
      const defaultValue: IStudentFilter = {
        email: "",
        mobile: "",
        dateOfBirth: undefined,
        startDate: undefined,
        endDate: undefined,
      };

      reset(defaultValue);
    }
  }, [filteredData, setValue, filterBox, reset]);

  const onClearClick = () => {
    reset();
    setFilterBox(false);
    document.body.classList.toggle("filter-open");
    if (pageNo === DEFAULT_PAGE_NO) {
      getStudentList();
    } else {
      setPageNo(1);
    }
    setFilteredData();
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setFilterBox(false);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            <TextField
              id="email"
              label="Email"
              fullWidth
              variant="outlined"
              {...register("email")}
              onKeyPress={(event) =>
                onTextFieldKeyPress(
                  event,
                  filteredData ? filteredData?.email?.length : 0
                )
              }
            />
            <TextField
              id="mobile"
              label="Mobile "
              type="number"
              fullWidth
              variant="outlined"
              {...register("mobile")}
              onKeyPress={onNumberFieldKeyPress}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <Controller
                  control={control}
                  {...register("dateOfBirth")}
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      className="date-picker disabled-picker-text-field w-100"
                      format={DATE_PICKER_FORMAT}
                      disableFuture
                      label="Date Of Birth"
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                      slotProps={{
                        textField: {
                          disabled: true,
                        },
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Grid container columnSpacing="24px">
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <Controller
                      control={control}
                      {...register("startDate")}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          className="date-picker disabled-picker-text-field"
                          format={DATE_PICKER_FORMAT}
                          disableFuture
                          label="Start Date"
                          slots={{
                            openPickerIcon: CalendarIcon,
                          }}
                          slotProps={{
                            textField: {
                              disabled: true,
                            },
                          }}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <Controller
                      control={control}
                      {...register("endDate")}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          className="date-picker disabled-picker-text-field"
                          format={DATE_PICKER_FORMAT}
                          disableFuture
                          label="End Date"
                          slots={{
                            openPickerIcon: CalendarIcon,
                          }}
                          slotProps={{
                            textField: {
                              disabled: true,
                            },
                          }}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-clear"
              onClick={onClearClick}
              disabled={
                !(
                  watch("email") ||
                  watch("mobile") ||
                  watch("dateOfBirth") ||
                  watch("startDate") ||
                  watch("endDate")
                )
              }
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default StudentFilter;
