import { Box } from "@mui/system";
import { toggleFilter } from "utils/helper";

interface IOverlayBoxProps {
  setFilterBox?: any;
}

const OverlayBox: React.FC<IOverlayBoxProps> = ({ setFilterBox }) => {
  return (
    <>
      <Box className="overlay" onClick={toggleFilter} />
      <Box
        className="filter-overlay"
        onClick={() => {
          toggleFilter();
          setFilterBox(false);
        }}
      />
    </>
  );
};

export default OverlayBox;
