/* eslint-disable react/jsx-boolean-value */
import {
  Card,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FormSteps } from "utils/enums/student-enrolment";
import {
  ICurrentCourseItem,
  IStep4Details,
} from "utils/interfaces/student-enrolment";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { uncheckIcon } from "assets/images";
import projectTheme from "app.theme";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import RadioCheckBoxIcon from "components/RadioCheckBoxIcon";
import RadioCheckedBoxIcon from "components/RadioCheckedBoxIcon";
import { FACE_TO_FACE } from "utils/constants/constant";
import FormStepButtons from "../FormStepButtons";

interface IFormStep4Props {
  isExistingStudent?: boolean;
  movePrev: (step: number, data: any, isNext: boolean) => void;
  moveNext: (step: number, data: any, isNext: boolean) => void;
  stepDetails: { data: IStep4Details };
  currentCourseList?: ICurrentCourseItem[];
}

const FormStep4: React.FC<IFormStep4Props> = (props) => {
  const { moveNext, stepDetails, movePrev, currentCourseList } = props;

  const {
    register,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IStep4Details>({
    defaultValues: {
      courseIds: [],
      courseNames: [],
      preferredCourseDelivery: [],
    },
  });

  function CheckboxIcon() {
    return <img src={uncheckIcon} alt="checkbox" />;
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    courseOfferingId: number,
    courseName: string
  ) => {
    const checkedCourses = watch("preferredCourseDelivery");
    const courseIndex = checkedCourses.findIndex(
      (x) => x.courseOfferingId === courseOfferingId
    );
    if (courseIndex === -1) {
      checkedCourses.push({
        courseOfferingId,
        preferredCourseDelivery: event.currentTarget.value,
        courseName,
      });
    } else {
      checkedCourses[courseIndex].preferredCourseDelivery =
        event.currentTarget.value;
    }
    setValue("preferredCourseDelivery", checkedCourses);
  };

  const setFormValues = (data: any) => {
    reset((formValues) => ({
      ...formValues,
      courseIds: data.courseIds,
      courseNames: data.courseNames,
      preferredCourseDelivery: data.preferredCourseDelivery,
    }));
  };

  useEffect(() => {
    if (stepDetails && stepDetails.data) {
      setFormValues(stepDetails.data);
    }
    if (!(stepDetails && stepDetails.data)) {
      const switchCourses = currentCourseList?.length
        ? currentCourseList?.map((course) => ({
            courseOfferingId: course.id,
            preferredCourseDelivery: course.isBlended ? "Face To Face" : null,
            courseName: course.courseName,
          }))
        : [];
      setValue("preferredCourseDelivery", switchCourses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    const stepData = {
      data: getValues(),
    };

    movePrev(FormSteps.StudentSupports, stepData, false);
  };

  const onSubmit = (data: any) => {
    const stepData = {
      data,
    };

    moveNext(4, stepData, true);
  };

  const handleSelectedCourses = (e: any) => {
    const courseIds = watch("courseIds");
    const currentIndex = courseIds.indexOf(Number(e.target.value));
    const newCourseIds = [...courseIds];

    if (currentIndex === -1) {
      newCourseIds.push(Number(e.target.value));
    } else {
      newCourseIds.splice(currentIndex, 1);
    }

    const courseNames = currentCourseList
      ?.filter((course) => newCourseIds.includes(course.id))
      .map((course) => course.courseName) as string[];

    setValue("courseNames", courseNames);
    setValue("courseIds", newCourseIds);
    setValue("preferredCourseDelivery", watch("preferredCourseDelivery"));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="stepper-card step-4">
        <Typography variant="h4" className="questions">
          Our current Courses <span className="required">*</span>
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    [projectTheme.breakpoints.down("lg")]: {
                      minWidth: "442px",
                    },
                    [projectTheme.breakpoints.down("sm")]: {
                      minWidth: "242px",
                    },
                  }}
                >
                  Course
                </TableCell>
                <TableCell
                  className="course-del"
                  align="center"
                  sx={{
                    width: "280px",
                    maxWidth: "280px",
                    minWidth: "280px",
                  }}
                >
                  Preferred Course Delivery
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentCourseList?.map((course) => {
                return (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Controller
                        control={control}
                        {...register("courseIds", {
                          required: true,
                        })}
                        render={() => (
                          <>
                            <FormControlLabel
                              value={course?.id}
                              control={
                                <Checkbox
                                  checked={
                                    watch("courseIds")?.indexOf(course.id) > -1
                                  }
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckedBoxIcon />}
                                  onChange={(e) => handleSelectedCourses(e)}
                                  className="stepper-check-course"
                                />
                              }
                              label={course.courseName}
                              labelPlacement="end"
                            />
                          </>
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <span className="prefer-course-del">
                        Preferred Course Delivery
                      </span>
                      {course.isBlended ? (
                        <Controller
                          control={control}
                          {...register("preferredCourseDelivery")}
                          render={({ field }) => (
                            <RadioGroup
                              row
                              {...field}
                              onChange={(e) =>
                                watch("courseIds")?.indexOf(course.id) > -1
                                  ? handleChange(
                                      e,
                                      course.id,
                                      course.courseName
                                    )
                                  : null
                              }
                            >
                              <FormControlLabel
                                checked={
                                  watch("courseIds")?.indexOf(course.id) > -1 &&
                                  watch("preferredCourseDelivery")?.find(
                                    (x) => x.courseOfferingId === course.id
                                  )?.preferredCourseDelivery === "Face To Face"
                                }
                                value="Face To Face"
                                control={
                                  <Radio
                                    icon={<RadioCheckBoxIcon />}
                                    checkedIcon={<RadioCheckedBoxIcon />}
                                  />
                                }
                                label={FACE_TO_FACE}
                              />
                              <FormControlLabel
                                checked={
                                  watch("courseIds")?.indexOf(course.id) > -1 &&
                                  watch("preferredCourseDelivery")?.find(
                                    (x) => x.courseOfferingId === course.id
                                  )?.preferredCourseDelivery === "Online"
                                }
                                value="Online"
                                control={
                                  <Radio
                                    icon={<RadioCheckBoxIcon />}
                                    checkedIcon={<RadioCheckedBoxIcon />}
                                  />
                                }
                                label="Online"
                              />
                            </RadioGroup>
                          )}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {!!errors.courseIds && (
                <FormHelperText error>Please select a course</FormHelperText>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <FormStepButtons handleBack={handleBack} isSubmitBtn={true} />
    </form>
  );
};

export default FormStep4;
