import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  convertToDateTimeWithFormat,
  getPagePermissions,
  showLoader,
} from "utils/helper";
import { useSelector } from "react-redux";
import { AppPages } from "utils/enums/app-pages";
import {
  CourseSession,
  ICourseOfferingDetail,
  IStudentDashboardCourseDetailsContainerDispatch,
} from "utils/interfaces/student-dashboard";
import {
  ApplyOrWithdrawCourseSuccessPayload,
  ChangeCoursePreferenceSuccessPayload,
  StudentDashboardCourseDetailByIdSuccessPayload,
  StudentDashboardCourseDetailsState,
} from "store/studentDashboard/types";
import { editIcon, plusLightIcon } from "assets/images";
import { useHistory, useLocation } from "react-router-dom";
import {
  BLENDED_FACE_TO_FACE_ONLINE,
  DATE_VIEW_FORMAT,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import ConfirmModal from "components/ConfirmModal";
import { toast } from "react-toastify";
import { AppRoutings } from "utils/enums/app-routings";
import { CHANGE_COURSE_PREFERENCE_SUCCESS } from "utils/constants/Messages";
import ApplyCoursePreferencePopUp from "./ApplyCoursePreferenceModal";

export type CourseOfferingDetailProps = StudentDashboardCourseDetailsState &
  IStudentDashboardCourseDetailsContainerDispatch;

const CourseOfferingDetail: React.FC<CourseOfferingDetailProps> = (props) => {
  const location = useLocation<{
    courseOfferingId: number;
    studentId: number;
    studentCourseEnrolmentId: number;
    isUpComingCourse: boolean;
  }>();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const [courseDetails, setCourseDetails] = useState<ICourseOfferingDetail>();
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
    useState<boolean>(false);
  const [
    isOpenWithdrawnConfirmationModal,
    setIsOpenWithdrawnConfirmationModal,
  ] = useState<boolean>(false);
  const [
    isOpenApplyCoursePreferenceModal,
    SetIsOpenApplyCoursePreferenceModal,
  ] = useState<boolean>(false);
  const [courseDeliveryPreference, setCourseDeliveryPreference] =
    useState<string>("");

  const [applyForCourse, setApplyForCourse] = useState<string>("");
  const courseOfferingId = location?.state?.courseOfferingId;
  const studentId = location?.state?.studentId;
  const isUpComingCourse = location?.state?.isUpComingCourse;
  const studentCourseEnrolmentId = location?.state?.studentCourseEnrolmentId;
  const handleSubmitConfirmationModalClose = (isNoClick?: boolean) => {
    setIsOpenConfirmationModal(false);
    if (isNoClick) {
      setCourseDeliveryPreference(courseDetails?.preferredCourseDelivery ?? "");
    }
  };

  const handleApplyCourseYesClick = (data: string) => {
    SetIsOpenApplyCoursePreferenceModal(false);
    setCourseDeliveryPreference(data);
    setIsOpenConfirmationModal(true);
  };

  const ApplyCoursePreferenceModalClose = () => {
    SetIsOpenApplyCoursePreferenceModal(false);
  };

  const { enrolmentOptionListRequest } = props;

  const onCourseOfferingDetailsByIdSuccess = (
    response: StudentDashboardCourseDetailByIdSuccessPayload
  ) => {
    setCourseDetails(response.courseOfferingDetails);
    setCourseDeliveryPreference(
      response.courseOfferingDetails?.preferredCourseDelivery ?? ""
    );
  };

  const getCourseOfferingDetails = () => {
    const { studentDashboardCourseDetailByIdRequest } = props;

    if (studentDashboardCourseDetailByIdRequest) {
      showLoader();

      const payload = {
        values: {
          courseOfferingId,
          studentId,
        },
        callback: onCourseOfferingDetailsByIdSuccess,
      };

      studentDashboardCourseDetailByIdRequest(payload);
    }
  };

  const onApplyCourseSuccess = (
    response: ApplyOrWithdrawCourseSuccessPayload
  ) => {
    if (response.success) {
      toast.success(response.message);
      history.push(AppRoutings.StudentDashboard);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const handleConfirmationModalSubmitClick = () => {
    const { applyOrWithdrawCourseRequest } = props;
    if (applyOrWithdrawCourseRequest) {
      showLoader();
      const payload = {
        values: {
          courseOfferingId,
          studentId,
          preferredCourseDelivery: courseDeliveryPreference,
          isWithdraw: false,
        },
        callback: onApplyCourseSuccess,
      };
      applyOrWithdrawCourseRequest(payload);
    }
  };

  const handleWithdrawnConfirmationModalSubmitClick = () => {
    const { applyOrWithdrawCourseRequest } = props;
    if (applyOrWithdrawCourseRequest) {
      showLoader();
      const payload = {
        values: {
          courseOfferingId,
          studentId,
          preferredCourseDelivery: courseDeliveryPreference,
          isWithdraw: true,
          studentCourseEnrolmentId,
        },
        callback: onApplyCourseSuccess,
      };
      applyOrWithdrawCourseRequest(payload);
    }
  };
  const onCoursePreferenceChangeSuccess = (
    response: ChangeCoursePreferenceSuccessPayload
  ) => {
    if (response.success) {
      getCourseOfferingDetails();
      toast.success(CHANGE_COURSE_PREFERENCE_SUCCESS);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const handleConfirmationModalSubmitClickForCoursePreference = () => {
    if (courseDeliveryPreference === courseDetails?.preferredCourseDelivery) {
      toast.success(CHANGE_COURSE_PREFERENCE_SUCCESS);
    } else {
      const { changeCoursePreferenceRequest } = props;
      if (changeCoursePreferenceRequest) {
        showLoader();
        const payload = {
          values: {
            courseOfferingId,
            studentId,
            preferredCourseDelivery: courseDeliveryPreference,
          },
          callback: onCoursePreferenceChangeSuccess,
        };
        changeCoursePreferenceRequest(payload);
      }
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.StudentDashboard
    );

    if (location?.state?.courseOfferingId && location?.state?.studentId) {
      if (permission?.view) {
        getCourseOfferingDetails();
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <>
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Course Details
          </Typography>

          <Box sx={{ display: "flex", columnGap: "15px" }}>
            <Button
              variant="outlined"
              className="btn-back"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
            {courseDetails?.enrollmentStatus === "Approved" &&
              isUpComingCourse && (
                <Button
                  variant="contained"
                  className="btn-apply-enrol-filled"
                  onClick={() => setIsOpenWithdrawnConfirmationModal(true)}
                >
                  Withdraw
                </Button>
              )}
            {(courseDetails?.enrollmentStatus === "" ||
              courseDetails?.enrollmentStatus === "Rejected" ||
              courseDetails?.enrollmentStatus === "Withdrawn") &&
              new Date(courseDetails.startDateOfFirstSession) > new Date() && (
                <Button
                  variant="contained"
                  className="btn-apply-enrol-filled"
                  onClick={() =>
                    courseDetails.isBlended
                      ? SetIsOpenApplyCoursePreferenceModal(true)
                      : setIsOpenConfirmationModal(true)
                  }
                >
                  <img src={plusLightIcon} alt="plus" />
                  Apply
                </Button>
              )}
          </Box>
        </Box>
        <Grid item xs={12} className="std-details">
          <Grid container spacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
            <Grid item xs={12} lg={6}>
              <Card>
                <Typography variant="h3" className="sco-heading">
                  Scheduled Course Offering Details
                </Typography>
                <Grid
                  container
                  spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                >
                  <Grid item xs={12}>
                    <Typography variant="body1" className="sco-detail-title">
                      Course
                    </Typography>
                    <Typography variant="body1" className="sco-detail">
                      {courseDetails?.courseName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" className="sco-detail-title">
                      Location
                    </Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {courseDetails?.enrollmentStatus === "" ||
                      courseDetails?.enrollmentStatus === "Rejected" ? (
                        <Typography variant="body1" className="sco-detail">
                          {courseDetails?.location}
                          {courseDetails?.isBlended &&
                            BLENDED_FACE_TO_FACE_ONLINE}
                        </Typography>
                      ) : (
                        <Typography variant="body1" className="sco-detail">
                          {courseDetails?.isBlended &&
                          courseDetails?.enrollmentStatus !== "" &&
                          courseDetails.enrollmentStatus !== "Withdrawn" &&
                          courseDetails.enrollmentStatus !== "Rejected"
                            ? `${courseDetails?.location} - Blended (${courseDeliveryPreference})`
                            : courseDetails?.location}
                        </Typography>
                      )}
                      {courseDetails?.isBlended &&
                        courseDetails?.startDateOfFirstSession &&
                        courseDetails?.enrollmentStatus !== "" &&
                        courseDetails.enrollmentStatus !== "Withdrawn" &&
                        courseDetails.enrollmentStatus !== "Rejected" &&
                        new Date(courseDetails.startDateOfFirstSession) >
                          new Date() && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Change Course Delivery Preference"
                            arrow
                          >
                            <IconButton
                              onClick={() => {
                                setApplyForCourse("changeCoursePreference");
                                SetIsOpenApplyCoursePreferenceModal(true);
                              }}
                            >
                              <img src={editIcon} alt="edit" />
                            </IconButton>
                          </Tooltip>
                        )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" className="sco-detail-title">
                      Number Enrolled
                    </Typography>
                    <Typography variant="body1" className="sco-detail">
                      {courseDetails?.numberEnrolled}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" className="sco-detail-title">
                      Date & Time Of First Session
                    </Typography>
                    <Typography variant="body1" className="sco-detail">
                      {convertToDateTimeWithFormat(
                        courseDetails?.startDateOfFirstSession,
                        DATE_VIEW_FORMAT
                      )}{" "}
                      {convertToDateTimeWithFormat(
                        courseDetails?.startTimeOfFirstSession,
                        TIME_VIEW_FORMAT
                      )}{" "}
                      {"To"}{" "}
                      {convertToDateTimeWithFormat(
                        courseDetails?.endTimeOfFirstSession,
                        TIME_VIEW_FORMAT
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" className="sco-detail-title">
                      Sessions
                    </Typography>
                    <Typography variant="body1" className="sco-detail">
                      {courseDetails?.sessions?.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" className="sco-detail-title">
                      Term
                    </Typography>
                    <Typography variant="body1" className="sco-detail">
                      {courseDetails?.term}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" className="sco-detail-title">
                      Status
                    </Typography>
                    <Typography variant="body1" className="sco-detail">
                      {courseDetails?.courseOfferingStatus}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card>
                <Typography variant="h3" className="attnd-heading">
                  Sessions
                </Typography>
                <Box className="attnd-body-wrapper">
                  <Grid
                    container
                    spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                  >
                    {courseDetails?.sessions?.map((session: CourseSession) => (
                      <Grid item xs={12} sm={6} md={4} key={session.id}>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={`${
                            // eslint-disable-next-line no-nested-ternary
                            new Date(session.sessionStartDateTime) <
                              new Date() &&
                            courseDetails.enrollmentStatus === "Approved" &&
                            session.isPresent !== null
                              ? session.isPresent
                                ? "Present"
                                : "Absent"
                              : "Remaining"
                          }`}
                          arrow
                        >
                          <Button
                            variant="outlined"
                            className={`btn-session btn-student-attendance btn-w-info ${
                              // eslint-disable-next-line no-nested-ternary
                              new Date(session.sessionStartDateTime) <
                                new Date() &&
                              courseDetails.enrollmentStatus === "Approved" &&
                              session.isPresent !== null
                                ? session.isPresent
                                  ? "present"
                                  : "absent"
                                : ""
                            }`}
                          >
                            <Typography
                              variant="h5"
                              component="span"
                              className="day-num"
                            >
                              {`Day ${session.dayNumber}`}
                            </Typography>
                            <Typography
                              variant="h6"
                              component="span"
                              className="date"
                            >
                              {convertToDateTimeWithFormat(
                                session?.sessionStartDateTime,
                                DATE_VIEW_FORMAT
                              )}
                            </Typography>
                          </Button>
                        </Tooltip>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </>

      {isOpenApplyCoursePreferenceModal && (
        <ApplyCoursePreferencePopUp
          ApplyCoursePreferenceModalClose={ApplyCoursePreferenceModalClose}
          enrolmentOptionListRequest={enrolmentOptionListRequest}
          handleYesClick={handleApplyCourseYesClick}
          selectedPreference={courseDeliveryPreference}
        />
      )}
      {isOpenConfirmationModal && (
        <ConfirmModal
          handleSubmitConfirmationModalClose={
            handleSubmitConfirmationModalClose
          }
          submitConfirmationMessage={
            applyForCourse === "changeCoursePreference"
              ? "Do you want to change the course preference?"
              : "Do you want to apply for this course?"
          }
          handleYesClick={
            applyForCourse === "changeCoursePreference"
              ? handleConfirmationModalSubmitClickForCoursePreference
              : handleConfirmationModalSubmitClick
          }
        />
      )}
      {isOpenWithdrawnConfirmationModal && (
        <ConfirmModal
          handleSubmitConfirmationModalClose={() =>
            setIsOpenWithdrawnConfirmationModal(false)
          }
          submitConfirmationMessage="Are you sure you want to withdraw from this course?"
          handleYesClick={handleWithdrawnConfirmationModalSubmitClick}
        />
      )}
    </>
  );
};

export default CourseOfferingDetail;
