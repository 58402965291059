import { AppPages } from "utils/enums/app-pages";

export const AdministratorAppPages = [
  AppPages.StudentEnrolment,
  AppPages.Reports,
  AppPages.Documents,
  AppPages.Users,
  AppPages.Student,
  AppPages.Roles,
  AppPages.Educators,
  AppPages.Terms,
  AppPages.Courses,
  AppPages.Locations,
  AppPages.ScheduledOfferings,
  AppPages.Dashboard,
];
