import {
  closeIcon,
  editIcon,
  mailIcon,
  mailSentIcon,
  sendSMSIcon,
  sentSMSIcon,
} from "assets/images";
import {
  Box,
  Card,
  Fade,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import NoRecords from "components/NoRecords";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  allowSearch,
  getPagePayloadValuesWithId,
  getPagePermissions,
  getPaginationDetailText,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  EnrollmentAndAttendanceHistoryListSuccessPayload,
  ReSendConfirmationMailSuccessPayload,
} from "store/student/types";
import { AppPages } from "utils/enums/app-pages";
import {
  IEnhancedTablePropsForEnrolmentAndAttendanceHistory,
  IEnrolmentAndAttendanceData,
  IEnrolmentAndAttendanceHistoryDetailKeys,
  IEnrolmentAndAttendanceHistoryListMeta,
  IEnrolmentAndAttendanceHistoryPagePayloadValues,
} from "utils/interfaces/student";
import {
  CLEAR_TOOLTIP_TITLE,
  DATE_VIEW_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_TEN_PAGE_SIZE,
  ENROLMENT_STATUS,
  PAGE_SIZES,
  TERM_STATUS,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import ConfirmModal from "components/ConfirmModal";
import { toast } from "react-toastify";
import {
  CHANGE_COURSE_PREFERENCE_SUCCESS,
  RESEND_MAIL_SUCCESS,
  RESEND_SMS_SUCCESS,
} from "utils/constants/Messages";
import ApplyCoursePreferencePopUp from "pages/StudentDashboard/CourseOfferingDetails/ApplyCoursePreferenceModal";
import { ChangeCoursePreferenceSuccessPayload } from "store/studentDashboard/types";
import { AppRoutings } from "utils/enums/app-routings";
import { Order, OrderByOptions } from "utils/enums/sorting";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { EnrolmentStatus } from "utils/enums/student-dashboard";

const EnhancedTableHead = (
  props: IEnhancedTablePropsForEnrolmentAndAttendanceHistory
) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IEnrolmentAndAttendanceHistoryDetailKeys) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};
interface IEnrolmentAndAttendanceHistoryProps {
  enrollmentAndAttendanceHistoryListRequest: any;
  attendanceHistoryChange: boolean;
  reSendConfirmationMailRequest: any;
  enrolmentOptionListRequest: any;
  changeCoursePreferenceRequest: any;
}

const EnrolmentAndAttendanceHistory: React.FC<IEnrolmentAndAttendanceHistoryProps> =
  ({
    enrollmentAndAttendanceHistoryListRequest,
    attendanceHistoryChange,
    reSendConfirmationMailRequest,
    enrolmentOptionListRequest,
    changeCoursePreferenceRequest,
  }) => {
    const location = useLocation<{ studentId: number; isDeleted?: boolean }>();
    const history = useHistory();
    const user = useSelector((state: any) => state?.auth?.user);
    const [searchText, setSearchText] = useState<string>("");
    const [enrolmentAndAttendanceList, setEnrolmentAndAttendanceList] =
      useState<IEnrolmentAndAttendanceData[]>();
    const [enrolmentAndAttendanceListMeta, setEnrolmentAndAttendanceListMeta] =
      useState<IEnrolmentAndAttendanceHistoryListMeta>();
    const [page, setPage] = useState<string>(DEFAULT_TEN_PAGE_SIZE);
    const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
    const [studentCourseEnrolmentId, setStudentCourseEnrolmentId] =
      useState<number>();
    const [courseDeliveryPreference, setCourseDeliveryPreference] =
      useState<string>("");
    const [courseName, setCourseName] = useState<string>();
    const [isEmailConfirmation, setIsEmailConfirmation] =
      useState<boolean>(true);
    const [isSamePreference, setIsSamePreference] = useState<boolean>(false);
    const [scoId, setSCOId] = useState<number>();

    const [
      isOpenReSendConfirmationMailModal,
      setIsOpenReSendConfirmationMailModal,
    ] = useState<boolean>(false);

    const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
      useState<boolean>(false);
    const [
      courseDeliveryPreferenceChangeModal,
      setCourseDeliveryPreferenceChangeModal,
    ] = useState<boolean>(false);
    const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
    const [orderBy, setOrderBy] =
      useState<keyof IEnrolmentAndAttendanceHistoryDetailKeys>("termStatus");
    const handleChangeConfirmationPreferenceYesClick = (data: string) => {
      setCourseDeliveryPreferenceChangeModal(false);
      if (data === courseDeliveryPreference) {
        setIsSamePreference(true);
      }
      setCourseDeliveryPreference(data);
      setIsOpenConfirmationModal(true);
    };

    const changeConfirmationPreferenceModalClose = () => {
      setCourseDeliveryPreferenceChangeModal(false);
    };

    const onEnrolmentAndAttendanceHistoryListSuccess = (
      response: EnrollmentAndAttendanceHistoryListSuccessPayload
    ) => {
      setEnrolmentAndAttendanceList(
        response.enrollmentAndAttendanceHistoryList
      );
      setEnrolmentAndAttendanceListMeta(
        response.enrollmentAndAttendanceHistoryListPagination
      );
    };

    const getEnrolmentAndAttendanceHistoryList = (filtered?: boolean) => {
      if (enrollmentAndAttendanceHistoryListRequest) {
        showLoader();
        const { studentId } = location.state;
        const values: IEnrolmentAndAttendanceHistoryPagePayloadValues =
          getPagePayloadValuesWithId(studentId, orderBy, order, pageNo, page);
        values.search = filtered ? "" : searchText.trim();

        const payload = {
          values,
          callback: onEnrolmentAndAttendanceHistoryListSuccess,
        };
        enrollmentAndAttendanceHistoryListRequest(payload);
      }
    };

    useEffect(() => {
      if (attendanceHistoryChange) {
        const permission = getPagePermissions(
          user?.permissions,
          AppPages.Student
        );
        if (permission?.edit) {
          getEnrolmentAndAttendanceHistoryList();
        } else {
          history.goBack();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendanceHistoryChange, page, pageNo, order, orderBy]);

    const handleStudentSearch = (event: any) => {
      setSearchText(event.target.value);
    };

    const handleClearSearch = () => {
      setSearchText("");
      getEnrolmentAndAttendanceHistoryList(true);
    };

    const handleStudentSearchKeyDown = (e: any) => {
      if (allowSearch(e)) {
        getEnrolmentAndAttendanceHistoryList();
      }
    };

    const handleSubmitConfirmationModalClose = () => {
      setIsOpenReSendConfirmationMailModal(false);
      setIsOpenConfirmationModal(false);
    };

    const handleSendConfirmation = (
      id: number,
      name: string,
      isEmail: boolean
    ) => {
      setIsOpenReSendConfirmationMailModal(true);
      setStudentCourseEnrolmentId(id);
      setCourseName(name);
      setIsEmailConfirmation(isEmail);
    };

    const onReSendConfirmationMailSuccess = (
      response: ReSendConfirmationMailSuccessPayload
    ) => {
      if (response.success) {
        if (isEmailConfirmation) {
          toast.success(RESEND_MAIL_SUCCESS);
          setEnrolmentAndAttendanceList((prevList) =>
            prevList?.map((item) =>
              item.id === studentCourseEnrolmentId
                ? {
                    ...item,
                    isEmailSent: item.isEmailSent
                      ? item.isEmailSent
                      : !item.isEmailSent,
                  }
                : item
            )
          );
        } else {
          toast.success(RESEND_SMS_SUCCESS);
          setEnrolmentAndAttendanceList((prevList) =>
            prevList?.map((item) =>
              item.id === studentCourseEnrolmentId
                ? {
                    ...item,
                    isSMSSent: item.isSMSSent
                      ? item.isSMSSent
                      : !item.isSMSSent,
                  }
                : item
            )
          );
        }
        handleSubmitConfirmationModalClose();
      } else {
        toast.error(response.message);
      }
    };

    const handleConfirmationModalSubmitClick = () => {
      if (reSendConfirmationMailRequest) {
        showLoader();
        const payload = {
          values: {
            studentCourseEnrolmentId,
            courseName,
            isEmailConfirmation,
          },
          callback: onReSendConfirmationMailSuccess,
        };
        reSendConfirmationMailRequest(payload);
      }
    };

    const onCoursePreferenceChangeSuccess = (
      response: ChangeCoursePreferenceSuccessPayload
    ) => {
      if (response.success) {
        toast.success(CHANGE_COURSE_PREFERENCE_SUCCESS);
        getEnrolmentAndAttendanceHistoryList();
      } else if (response.message) {
        toast.error(response.message);
      }
    };
    const handleConfirmationModalSubmitClickForCoursePreference = () => {
      if (isSamePreference) {
        toast.success(CHANGE_COURSE_PREFERENCE_SUCCESS);
      } else if (changeCoursePreferenceRequest) {
        showLoader();
        const { studentId } = location.state;
        const payload = {
          values: {
            courseOfferingId: scoId,
            studentId,
            preferredCourseDelivery: courseDeliveryPreference,
          },
          callback: onCoursePreferenceChangeSuccess,
        };
        changeCoursePreferenceRequest(payload);
      }
    };

    const handleRequestSort = useCallback(
      (
        event: React.MouseEvent<unknown>,
        newOrderBy: keyof IEnrolmentAndAttendanceHistoryDetailKeys
      ) => {
        const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
        const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
        setOrder(toggledOrder);
        setOrderBy(newOrderBy);
      },
      [order, orderBy]
    );

    const onPageChange = (e: React.ChangeEvent<unknown>, value: number) => {
      setPageNo(value);
    };

    const handleChange = (event: SelectChangeEvent) => {
      setPage(event.target.value as string);
      setPageNo(1);
    };
    const getStatusText = (statusId: number) => {
      let statusText = "";
      TERM_STATUS?.forEach((status) => {
        if (status.value === statusId) {
          statusText = status.label;
        }
      });

      return statusText;
    };

    const getStatusLabel = (status: number) => {
      let statusString = "";
      ENROLMENT_STATUS?.forEach((item) => {
        if (item.value === status) {
          statusString = item.label;
        }
      });

      return statusString;
    };

    return (
      <>
        <Card>
          <Box className="table-card-header">
            <Box
              sx={{
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                flexWrap: "wrap",
                rowGap: "16px",
              }}
            >
              <Typography variant="h3">
                Enrolment and Attendance History
              </Typography>
              <TextField
                variant="outlined"
                id="search-box"
                className="search-input"
                placeholder="Search"
                onChange={handleStudentSearch}
                onKeyDown={handleStudentSearchKeyDown}
                value={searchText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{ position: "absolute", right: "0" }}
                      position="end"
                    >
                      {searchText && (
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={CLEAR_TOOLTIP_TITLE}
                          arrow
                        >
                          <IconButton edge="end" onClick={handleClearSearch}>
                            <img src={closeIcon} alt="close" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
                onKeyPress={(event) =>
                  onTextFieldKeyPress(event, searchText?.length)
                }
              />
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e) => handleRequestSort(e, "termStatus")}
                    fieldName="Term Status"
                    keyName="termStatus"
                    sxStyle={{
                      maxWidth: "117px",
                      width: "117px",
                      minWidth: "117px",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e) => handleRequestSort(e, "term")}
                    fieldName="Term"
                    keyName="term"
                    sxStyle={{
                      width: "75px",
                      minWidth: "75px",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e) =>
                      handleRequestSort(e, "courseStartDate")
                    }
                    fieldName="Course start Date"
                    keyName="courseStartDate"
                    sxStyle={{
                      width: "121px",
                      minWidth: "121px",
                      textAlign: "center",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e) => handleRequestSort(e, "dateEnrolled")}
                    fieldName="Date Enrolled"
                    keyName="dateEnrolled"
                    sxStyle={{
                      width: "114px",
                      minWidth: "114px",
                      textAlign: "center",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e) => handleRequestSort(e, "location")}
                    fieldName="Location"
                    keyName="location"
                    sxStyle={{
                      width: "144px",
                      minWidth: "144px",
                      maxWidth: "144px",
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e) => handleRequestSort(e, "courseSession")}
                    fieldName="Course Session"
                    keyName="courseSession"
                    sxStyle={{
                      width: "145px",
                      minWidth: "145px",
                    }}
                  />
                  <TableCell sx={{ width: "150px", minWidth: "150px" }}>
                    Enrolled by
                  </TableCell>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e) => handleRequestSort(e, "timeOfSession")}
                    fieldName="Time of Session"
                    keyName="timeOfSession"
                    sxStyle={{
                      width: "145px",
                      minWidth: "145px",
                    }}
                  />
                  <TableCell
                    align="center"
                    sx={{ width: "108px", minWidth: "108px" }}
                  >
                    Attendance Rate
                  </TableCell>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e) => handleRequestSort(e, "blendedCourse")}
                    fieldName="Blended Course"
                    keyName="blendedCourse"
                    sxStyle={{
                      width: "153px",
                      minWidth: "153px",
                      textAlign: "center",
                    }}
                  />
                  <TableCell
                    align="center"
                    sx={{ width: "143px", minWidth: "132px" }}
                  >
                    Enrolment Status
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: "155px",
                      minWidth: "155px",
                      maxWidth: "155px",
                    }}
                  >
                    Send Confirmation Email
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: "155px",
                      minWidth: "155px",
                      maxWidth: "155px",
                    }}
                  >
                    Send Confirmation SMS
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {enrolmentAndAttendanceList?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={`${getStatusText(
                        row?.termStatus
                      ).toLowerCase()} status`}
                    >
                      <div>{getStatusText(row?.termStatus)}</div>
                    </TableCell>
                    <TableCell align="center">{row?.term}</TableCell>
                    <TableCell align="center">
                      {moment(row?.courseStartDate).format(DATE_VIEW_FORMAT)}
                    </TableCell>
                    <TableCell align="center">
                      {moment(row?.dateEnrolled).format(DATE_VIEW_FORMAT)}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {row?.locationName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push({
                            pathname: AppRoutings.ScheduledOfferingsDetails,
                            state: {
                              scoId: Number(row.courseOfferingId),
                              autoScrollToSessions: true,
                            },
                          })
                        }
                      >
                        <Typography variant="body2">
                          {row?.courseSession}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{row?.enrolledBy || "-"}</TableCell>
                    <TableCell>
                      {moment(row?.startTimeOfSession).format(TIME_VIEW_FORMAT)}{" "}
                      to{" "}
                      {moment(row?.endTimeOfSession).format(TIME_VIEW_FORMAT)}
                    </TableCell>
                    <TableCell align="center">{row.attendanceRate}</TableCell>
                    <TableCell align="center">
                      {row.isBlended && row.blendedCourse !== undefined ? (
                        <>
                          {row.blendedCourse}
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Change Course Delivery Preference"
                            arrow
                          >
                            <IconButton
                              disabled={
                                row.enrollmentStatus !==
                                EnrolmentStatus.Confirmed
                              }
                              className={
                                row.enrollmentStatus !==
                                EnrolmentStatus.Confirmed
                                  ? "disabled-icon"
                                  : ""
                              }
                              onClick={() => {
                                if (
                                  row.enrollmentStatus ===
                                  EnrolmentStatus.Confirmed
                                ) {
                                  setCourseDeliveryPreference(
                                    row.blendedCourse
                                  );
                                  setSCOId(row.courseOfferingId);
                                  setCourseDeliveryPreferenceChangeModal(true);
                                }
                              }}
                            >
                              <img src={editIcon} alt="edit" />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${getStatusLabel(
                        row?.enrollmentStatus
                      ).toLocaleLowerCase()} status`}
                    >
                      {getStatusLabel(row?.enrollmentStatus) === "Approved" ? (
                        <div>Confirmed</div>
                      ) : (
                        <div>{getStatusLabel(row?.enrollmentStatus)}</div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <div className="table-actions">
                        {row.enrollmentStatus === 1 && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={`${
                              row?.isEmailSent
                                ? "Email already sent"
                                : "Send confirmation email"
                            }`}
                            arrow
                          >
                            <IconButton
                              disabled={location.state.isDeleted}
                              onClick={() =>
                                location.state.isDeleted
                                  ? null
                                  : handleSendConfirmation(
                                      row?.id,
                                      row?.courseSession,
                                      true
                                    )
                              }
                            >
                              <img
                                src={row?.isEmailSent ? mailSentIcon : mailIcon}
                                alt="mail"
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="table-actions">
                        {row.enrollmentStatus === 1 && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={`${
                              row?.isSMSSent
                                ? "SMS already sent"
                                : "Send confirmation SMS"
                            }`}
                            arrow
                          >
                            <IconButton
                              disabled={location.state.isDeleted}
                              onClick={() =>
                                location.state.isDeleted
                                  ? null
                                  : handleSendConfirmation(
                                      row?.id,
                                      row?.courseSession,
                                      false
                                    )
                              }
                            >
                              <img
                                src={row?.isSMSSent ? sentSMSIcon : sendSMSIcon}
                                alt="sms"
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {enrolmentAndAttendanceList &&
          enrolmentAndAttendanceList.length > 0 ? (
            <Box className="custom-pagination">
              <Box className="custom-rowperpage">
                <Typography variant="body2" component="span">
                  Page:
                </Typography>
                <Select
                  id="pagination-select"
                  value={page}
                  onChange={handleChange}
                  MenuProps={{
                    className: "pagination-menu",
                  }}
                >
                  {PAGE_SIZES?.map((pageSize) => (
                    <MenuItem
                      key={pageSize.value}
                      value={pageSize.value}
                      selected={pageSize?.selected}
                    >
                      {pageSize.label}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="body2" component="span">
                  {getPaginationDetailText(
                    enrolmentAndAttendanceListMeta,
                    pageNo,
                    page
                  )}
                </Typography>{" "}
              </Box>
              <Pagination
                count={enrolmentAndAttendanceListMeta?.totalPages}
                variant="outlined"
                shape="rounded"
                page={pageNo}
                onChange={onPageChange}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                  />
                )}
              />
            </Box>
          ) : (
            <NoRecords />
          )}
          <Typography
            variant="body2"
            className="note"
            sx={{ marginTop: { xs: "20px", lg: "21px" } }}
          >
            Note: Successful attendance is defined as attending at least 75% of
            the course (by duration).
          </Typography>
        </Card>
        {isOpenReSendConfirmationMailModal && (
          <ConfirmModal
            handleSubmitConfirmationModalClose={
              handleSubmitConfirmationModalClose
            }
            submitConfirmationMessage={
              isEmailConfirmation
                ? "Are you sure you want to resend confirmation email?"
                : "Are you sure you want to resend confirmation sms?"
            }
            handleYesClick={handleConfirmationModalSubmitClick}
          />
        )}
        {courseDeliveryPreferenceChangeModal && !location.state.isDeleted && (
          <ApplyCoursePreferencePopUp
            ApplyCoursePreferenceModalClose={
              changeConfirmationPreferenceModalClose
            }
            enrolmentOptionListRequest={enrolmentOptionListRequest}
            handleYesClick={handleChangeConfirmationPreferenceYesClick}
            selectedPreference={courseDeliveryPreference}
          />
        )}
        {isOpenConfirmationModal && (
          <ConfirmModal
            handleSubmitConfirmationModalClose={
              handleSubmitConfirmationModalClose
            }
            submitConfirmationMessage="Do you want to change the course preference?"
            handleYesClick={
              handleConfirmationModalSubmitClickForCoursePreference
            }
          />
        )}
      </>
    );
  };

export default EnrolmentAndAttendanceHistory;
