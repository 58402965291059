import { Box } from "@mui/system";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  Fade,
  Tooltip,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { useEffect } from "react";
import {
  CLOSE_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  ENROLMENT_STATUS,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
} from "utils/constants/constant";
import { useForm } from "react-hook-form";
import {
  WHITE_SPACE_ERROR_SPACE,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER,
} from "utils/constants/Messages";
import { IEnroledStudentFilter } from "utils/interfaces/scheduled-offering";
import { toggleFilter } from "utils/helper";
import OverlayBox from "components/OverlayBox";
import { EnrolmentStatus } from "utils/enums/student-dashboard";

interface IEnrollmentFilterProps {
  setEnrollFilterBox: any;
  handleApplyClick: any;
  filteredData?: IEnroledStudentFilter;
  filterBox: boolean;
  setFilteredData: any;
  setPageNo: any;
  pageNo: number;
  enroledStudentListRequest: any;
  enrolmentOptionList: any;
}

const EnrollmentFilter: React.FC<IEnrollmentFilterProps> = ({
  setEnrollFilterBox,
  handleApplyClick,
  filteredData,
  filterBox,
  setFilteredData,
  setPageNo,
  enroledStudentListRequest,
  pageNo,
  enrolmentOptionList,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IEnroledStudentFilter>({
    defaultValues: {
      name: "",
      mrnNo: "",
      type: "",
    },
  });

  const getNameError = (): string => {
    if (errors.name) {
      if (errors.name.type === "pattern") {
        return `Name ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getMRNError = (): string => {
    if (errors.mrnNo) {
      if (errors.mrnNo.type === "pattern") {
        return `MrnNo ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  useEffect(() => {
    if (filteredData) {
      setValue("name", filteredData?.name);
      setValue("type", filteredData?.type);
      setValue("mrnNo", filteredData?.mrnNo);
      setValue("enrolmentStatus", filteredData?.enrolmentStatus);
    } else {
      reset();
    }
  }, [filteredData, setValue, filterBox, reset, filterBox]);

  const onClearClick = () => {
    if (pageNo === DEFAULT_PAGE_NO) {
      enroledStudentListRequest();
    } else {
      setPageNo(1);
    }
    reset();
    setEnrollFilterBox(false);
    setFilteredData();
    toggleFilter();
  };

  return (
    <>
      {filterBox && <OverlayBox setFilterBox={setEnrollFilterBox} />}
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setEnrollFilterBox(false);
                  toggleFilter();
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            <TextField
              id="std-num"
              label="Student Name"
              fullWidth
              variant="outlined"
              error={!!errors.name}
              helperText={getNameError()}
              value={watch("name")}
              {...register("name", {
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
            <TextField
              id="mrn"
              label="MRN"
              fullWidth
              variant="outlined"
              error={!!errors.mrnNo}
              helperText={getMRNError()}
              value={watch("mrnNo")}
              {...register("mrnNo", {
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
            <FormControl fullWidth className="select">
              <InputLabel id="enrol-type-label">Enrolment Type</InputLabel>
              <Select
                labelId="enrol-type-label"
                id="enrol-type"
                label="Enrolment Type"
                value={watch("type")}
                {...register("type")}
              >
                {enrolmentOptionList?.enrolmentType?.map((option: any) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="status3-label">Status</InputLabel>
              <Select
                labelId="status3-label"
                id="status3"
                label="Status"
                value={watch("enrolmentStatus") ?? ""}
                {...register("enrolmentStatus")}
              >
                {ENROLMENT_STATUS?.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-clear"
              onClick={onClearClick}
              disabled={
                !(
                  watch("mrnNo") !== "" ||
                  watch("name") !== "" ||
                  watch("type") !== "" ||
                  watch("enrolmentStatus") ||
                  watch("enrolmentStatus") === EnrolmentStatus.Pending
                )
              }
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default EnrollmentFilter;
