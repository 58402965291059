import { connect, MapDispatchToProps } from "react-redux";
import StudentDashboardComponent, {
  StudentDashboardProps,
} from "pages/StudentDashboard";
import {
  IStudentDashboardContainerDispatch,
  IUpcomingCourse,
} from "utils/interfaces/student-dashboard";
import {
  courseOfferingListRequest,
  downloadCertificateRequest,
  pastCourseListRequest,
  studentDocumentsRequest,
  upcomingCourseListRequest,
} from "store/studentDashboard/actions";

const mapDispatchToProps: MapDispatchToProps<
  IStudentDashboardContainerDispatch,
  IUpcomingCourse
> = {
  upcomingCourseListRequest,
  pastCourseListRequest,
  courseOfferingListRequest,
  downloadCertificateRequest,
  studentDocumentsRequest,
};

const connector = connect(null, mapDispatchToProps);

const StudentDashboard: React.FC<StudentDashboardProps> = (props) => {
  return <StudentDashboardComponent {...props} />;
};

export default connector(StudentDashboard);
