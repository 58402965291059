import { Box } from "@mui/system";
import {
  Button,
  Card,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  FormControlLabel,
  Checkbox,
  Fade,
  Tooltip,
} from "@mui/material";
import projectTheme from "app.theme";
import {
  attendanceIcon,
  cancelIcon,
  closeIcon,
  courseListSecondaryIcon,
  deleteIcon,
  editIcon,
  saveIcon,
  userSmallIcon,
  userSmallLightIcon,
} from "assets/images";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import {
  AttendanceStatus,
  ICourseCancel,
  IScoSession,
  ISCOStudentAttendanceListData,
  ISCOStudentWhoAttendedListData,
} from "utils/interfaces/scheduled-offering";
import {
  checkEndDateLessThanOrEqualToStart,
  convertToDateTimeWithFormat,
  hideLoaderForBatchApiCall,
  showLoader,
  showLoaderForBatchApiCall,
} from "utils/helper";
import {
  CLOSE_TOOLTIP_TITLE,
  DATE_VIEW_FORMAT,
  DELETE_TOOLTIP_TITLE,
  EDIT_TOOLTIP_TITLE,
  SESSION_DAY_STATUS,
  VIEW_CANCELLATION_COMMENT_TOOLTIP_TITLE,
} from "utils/constants/constant";
import {
  FillStudentAttendanceSuccessPayload,
  GetSCOStudentAttendanceListSuccessPayload,
  GetSCOStudentWhoAttendedListSuccessPayload,
} from "store/scheduledOffering/types";
import { SessionDayStatus } from "utils/enums/scheduled-offering";
import dayjs from "dayjs";
import NoRecords from "components/NoRecords";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { ATTENDANCE_FILLED_SUCCESS_MESSAGE } from "utils/constants/Messages";
import { useSelector, useDispatch } from "react-redux";
import { saveAttendanceRequest } from "store/scheduledOffering/actions";
import CancelDayConfirmationModal from "./CancelDayConfirmationModal";
import CancelCourseCommentModal from "./CancelCommentModal";

interface ISessionDaysProps {
  sessionsList: Array<IScoSession>;
  handleAddEditSessionDayModal: any;
  handleDeleteSessionDay: any;
  handleCancelSessionDay: any;
  autoScrollToSessions: boolean;
  SCOStudentAttendanceListRequest: any;
  SCOStudentWhoAttendedListRequest: any;
  fillStudentAttendanceRequest: any;
  getCourseOfferingSessionsList: any;
}

const SessionDaysAndAttendance: React.FC<ISessionDaysProps> = ({
  sessionsList,
  handleAddEditSessionDayModal,
  handleDeleteSessionDay,
  handleCancelSessionDay,
  autoScrollToSessions,
  SCOStudentAttendanceListRequest,
  SCOStudentWhoAttendedListRequest,
  fillStudentAttendanceRequest,
  getCourseOfferingSessionsList,
}) => {
  const [stdList, setStdList] = useState(false);

  const handleStdListModalClose = () => setStdList(false);

  const [isOpenDayDeleteModal, setIsOpenDayDeleteModal] = useState(false);
  const [deleteDayId, setDeleteDayId] = useState<number>();
  const [isOpenDayCancelModal, setIsOpenDayCancelModal] =
    useState<boolean>(false);
  const [cancelDayId, setCancelDayId] = useState<number>();
  const [attendance, setAttendance] = useState<boolean>(false);
  const [attendanceStatus, setAttendanceStatus] = useState<AttendanceStatus[]>(
    []
  );
  const [attendanceRows, setAttendanceRows] = useState<
    ISCOStudentAttendanceListData[]
  >([]);
  const [stdWhoAttendedRows, setStdWhoAttendedRows] = useState<
    ISCOStudentWhoAttendedListData[]
  >([]);
  const [courseOfferingId, setCourseOfferingId] = useState<number>();
  const [sessionId, setSessionId] = useState<number>();
  const [cancelComment, setCancelComment] = useState<string>("");
  const [isOpenCommentModal, setIsOpenCommentModal] = useState<boolean>(false);
  const isSaveAttendanceChange = useSelector(
    (state: any) => state?.scheduledOffering?.isSaveAttendance
  );
  const dispatch = useDispatch();

  const onSCOStudentAttendanceListSuccess = (
    response: GetSCOStudentAttendanceListSuccessPayload
  ) => {
    setAttendanceRows(response.studentAttendanceList);
    hideLoaderForBatchApiCall();
  };

  const onSCOStudentWhoAttendedListSuccess = (
    response: GetSCOStudentWhoAttendedListSuccessPayload
  ) => {
    setStdWhoAttendedRows(response.studentWhoAttendedList);
    hideLoaderForBatchApiCall();
  };

  const handleSaveAttendanceClick = () => {
    dispatch(
      saveAttendanceRequest({ isSaveAttendance: !isSaveAttendanceChange })
    );
  };

  const getSCOStudentAttendanceList = (
    studentAttendanceSessionId: number,
    studentAttendanceCourseOfferingId: number
  ) => {
    if (SCOStudentAttendanceListRequest) {
      showLoaderForBatchApiCall();
      const values = {
        sessionId: studentAttendanceSessionId,
        courseOfferingId: studentAttendanceCourseOfferingId,
      };
      const payload = {
        values,
        callback: onSCOStudentAttendanceListSuccess,
      };
      SCOStudentAttendanceListRequest(payload);
    }
  };

  const getSCOStudentWhoAttendedList = (
    stdWhoAttendedSessionId: number,
    stdWhoAttendedCourseOfferingId: number
  ) => {
    if (SCOStudentWhoAttendedListRequest) {
      showLoaderForBatchApiCall();
      const values = {
        stdWhoAttendedSessionId,
        stdWhoAttendedCourseOfferingId,
      };
      const payload = {
        values,
        callback: onSCOStudentWhoAttendedListSuccess,
      };
      SCOStudentWhoAttendedListRequest(payload);
    }
  };

  const handleStdWhoAttendedList = (
    CourseOfferingSessionId: number,
    CourseOfferingId: number
  ) => {
    setCourseOfferingId(CourseOfferingId);
    setSessionId(CourseOfferingSessionId);
    getSCOStudentWhoAttendedList(CourseOfferingSessionId, CourseOfferingId);
    setStdList(true);
  };

  const handleAttendance = (
    CourseOfferingSessionId: number,
    CourseOfferingId: number
  ) => {
    setCourseOfferingId(CourseOfferingId);
    setSessionId(CourseOfferingSessionId);
    getSCOStudentAttendanceList(CourseOfferingSessionId, CourseOfferingId);
    setAttendance(true);
  };
  const handleAttendanceClose = () => setAttendance(false);

  const sessionRef = useRef<null | HTMLDivElement>(null);

  const handleDayDeleteModal = (dayId: number) => {
    setDeleteDayId(dayId);
    setIsOpenDayDeleteModal(true);
  };

  const handleDayDeleteCloseModal = () => {
    setDeleteDayId(0);
    setIsOpenDayDeleteModal(false);
  };

  const handleDeleteDay = () => {
    handleDayDeleteCloseModal();
    handleDeleteSessionDay(deleteDayId);
  };

  const getSessionDayStatusLabel = (status: number) => {
    let statusString = "";
    SESSION_DAY_STATUS?.forEach((item) => {
      if (item.value === status) {
        statusString = item.label;
      }
    });

    return statusString;
  };

  const handleDayCancelModal = (dayId: number) => {
    setCancelDayId(dayId);
    setIsOpenDayCancelModal(true);
  };

  const handleDayCancelCloseModal = () => {
    setCancelDayId(0);
    setIsOpenDayCancelModal(false);
  };

  const handleCancelDay = (data: ICourseCancel) => {
    handleDayCancelCloseModal();
    const payload = {
      id: cancelDayId,
      cancellationComment: data.cancellationComment,
    };
    handleCancelSessionDay(payload);
  };

  const executeScroll = () => {
    if (sessionRef.current) {
      sessionRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (autoScrollToSessions) {
      executeScroll();
    }
  }, [autoScrollToSessions]);

  useEffect(() => {
    const updatedStatus = attendanceRows.map((row) => ({
      studentCourseEnrolmentId: row?.studentCourseEnrolmentId,
      isPresent: row?.isPresent || false,
    }));
    setAttendanceStatus(updatedStatus);
  }, [attendanceRows]);

  const handleCheckboxChange = (index: number) => {
    setAttendanceStatus((prevStatus) => {
      const updatedStatus = [...prevStatus];
      updatedStatus[index].isPresent = !updatedStatus[index].isPresent;

      return updatedStatus;
    });
  };

  const onFillStudentAttendanceSuccess = (
    response: FillStudentAttendanceSuccessPayload
  ) => {
    if (response.success) {
      toast.success(ATTENDANCE_FILLED_SUCCESS_MESSAGE);
      handleAttendanceClose();
      getCourseOfferingSessionsList();
      handleSaveAttendanceClick();
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleSaveAttendance = () => {
    if (fillStudentAttendanceRequest) {
      showLoader();
      const payload = {
        values: {
          courseOfferingId,
          sessionId,
          attendanceLists: attendanceStatus,
        },
        callback: onFillStudentAttendanceSuccess,
      };
      fillStudentAttendanceRequest(payload);
    }
  };

  const handleCancelCommentModal = (comment: string) => {
    setCancelComment(comment);
    setIsOpenCommentModal(true);
  };

  const handleCancelCommentCloseModal = () => {
    setCancelComment("");
    setIsOpenCommentModal(false);
  };

  return (
    <>
      <Grid item xs={12} ref={sessionRef}>
        <Card>
          <Box className="table-card-header">
            <Box
              sx={{
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                flexWrap: "wrap",
                rowGap: "16px",
              }}
            >
              <Typography variant="h3">Session Days And Attendance</Typography>

              <Button
                variant="outlined"
                className="btn-ascod"
                sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                onClick={() => handleAddEditSessionDayModal(false, null)}
              >
                Add Schedule Course Offering Days
              </Button>
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{
                      width: "90px",
                      minWidth: "90px",
                    }}
                  >
                    Day Num
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      width: "185px",
                      minWidth: "185px",
                    }}
                  >
                    Students Who Attended
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      width: "160px",
                      minWidth: "160px",
                    }}
                  >
                    Date of Offering Day
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    sx={{
                      width: "135px",
                      minWidth: "135px",
                    }}
                  >
                    Num Attended
                  </TableCell> */}
                  <TableCell
                    align="left"
                    sx={{
                      width: "177px",
                      minWidth: "177px",
                    }}
                  >
                    Attendance Submitted
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      width: "100px",
                      minWidth: "100px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: "144px",
                      minWidth: "144px",
                    }}
                  >
                    Enter Attendance
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: "92px",
                      [projectTheme.breakpoints.down("sm")]: {
                        width: "80px",
                      },
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessionsList?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.dayNumber}
                    </TableCell>
                    <TableCell>
                      <Button
                        className="btn-course"
                        variant="outlined"
                        onClick={() =>
                          handleStdWhoAttendedList(
                            row?.id,
                            row?.courseOfferingID
                          )
                        }
                      >
                        <img
                          src={userSmallIcon}
                          alt="Course List"
                          className="default"
                        />
                        <img
                          src={userSmallLightIcon}
                          alt="Course List"
                          className="active"
                        />
                        <Typography variant="body2">
                          {row.studentWhoAttended}
                        </Typography>
                      </Button>
                    </TableCell>
                    <TableCell>
                      {convertToDateTimeWithFormat(
                        row.sessionStartDateTime,
                        DATE_VIEW_FORMAT
                      )}
                    </TableCell>
                    {/* <TableCell>{row.studentWhoAttended}</TableCell> */}
                    <TableCell>
                      {row.attendanceSubmitted ? "Yes" : "No"}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={`${getSessionDayStatusLabel(
                        row.status
                      ).toLowerCase()}-session status`}
                    >
                      <div>{getSessionDayStatusLabel(row.status)}</div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="table-actions">
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title="Enter Attendance"
                          arrow
                        >
                          <IconButton
                            disabled={
                              !checkEndDateLessThanOrEqualToStart(
                                new Date(),
                                dayjs(row.sessionStartDateTime).toDate()
                              ) ||
                              getSessionDayStatusLabel(row.status) ===
                                "Cancelled"
                            }
                            onClick={() =>
                              checkEndDateLessThanOrEqualToStart(
                                new Date(),
                                dayjs(row.sessionStartDateTime).toDate()
                              ) &&
                              getSessionDayStatusLabel(row.status) !==
                                "Cancelled"
                                ? handleAttendance(
                                    row?.id,
                                    row?.courseOfferingID
                                  )
                                : null
                            }
                          >
                            <img
                              src={attendanceIcon}
                              className={
                                !checkEndDateLessThanOrEqualToStart(
                                  new Date(),
                                  dayjs(row.sessionStartDateTime).toDate()
                                ) ||
                                getSessionDayStatusLabel(row.status) ===
                                  "Cancelled"
                                  ? "disabled-icon"
                                  : ""
                              }
                              alt="edit"
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className="table-actions">
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={EDIT_TOOLTIP_TITLE}
                          arrow
                        >
                          <IconButton
                            disabled={row.dayNumber === 1}
                            className={
                              row.dayNumber === 1 ? "disabled-icon" : ""
                            }
                            onClick={() =>
                              row.dayNumber === 1
                                ? null
                                : handleAddEditSessionDayModal(true, row)
                            }
                          >
                            <img src={editIcon} alt="edit" />
                          </IconButton>
                        </Tooltip>
                        {row.status === 2 && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={VIEW_CANCELLATION_COMMENT_TOOLTIP_TITLE}
                            arrow
                          >
                            <IconButton
                              onClick={() => {
                                handleCancelCommentModal(
                                  row.cancellationComment
                                );
                              }}
                            >
                              <img
                                src={courseListSecondaryIcon}
                                className="action-course-list"
                                alt="comment"
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={DELETE_TOOLTIP_TITLE}
                          arrow
                        >
                          <IconButton
                            disabled={row.dayNumber === 1}
                            onClick={() =>
                              row.dayNumber === 1
                                ? null
                                : handleDayDeleteModal(row?.id)
                            }
                            className={
                              row.dayNumber === 1 ? "disabled-icon" : ""
                            }
                          >
                            <img src={deleteIcon} alt="delete" />
                          </IconButton>
                        </Tooltip>
                        {Number(row.status) !== SessionDayStatus.Cancelled && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Cancel"
                            arrow
                          >
                            <IconButton
                              disabled={row.dayNumber === 1}
                              onClick={() =>
                                row.dayNumber === 1
                                  ? null
                                  : handleDayCancelModal(row.id)
                              }
                              className={
                                row.dayNumber === 1 ? "disabled-icon" : ""
                              }
                            >
                              <img src={cancelIcon} alt="cancel" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {(!sessionsList || sessionsList.length === 0) && <NoRecords />}
        </Card>
      </Grid>

      <Modal open={attendance} onClose={handleAttendanceClose}>
        <Box className="common-modal attendance-modal">
          <Box className="modal-header">
            <Typography variant="h4">Enter Attendance</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleAttendanceClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ maxWidth: "646px", minWidth: "195px" }}
                    >
                      Student
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "128px", minWidth: "128px" }}
                    >
                      Fill Attendance
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendanceRows?.map((row, index) => {
                    return (
                      <TableRow key={row.studentCourseEnrolmentId}>
                        <TableCell component="th" scope="row">
                          {row.studentName}
                        </TableCell>
                        <TableCell align="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableFocusRipple
                                disableRipple
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckedBoxIcon />}
                                checked={
                                  attendanceStatus[index]?.isPresent || false
                                }
                                onChange={() => handleCheckboxChange(index)}
                              />
                            }
                            label={undefined}
                            labelPlacement="end"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {(!attendanceRows || attendanceRows.length === 0) && <NoRecords />}
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleSaveAttendance}
              disabled={!attendanceRows || attendanceRows.length === 0}
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleAttendanceClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={stdList} onClose={handleStdListModalClose}>
        <Box className="common-modal std-attended-modal">
          <Box className="modal-header">
            <Typography variant="h4">Students Attended</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleStdListModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ width: "340px", minWidth: "340px" }}
                    >
                      Students Who Attended
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stdWhoAttendedRows?.map((row) => {
                    return (
                      <TableRow key={row.studentName}>
                        <TableCell component="th" scope="row">
                          {row.toString()}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {(!stdWhoAttendedRows || stdWhoAttendedRows.length === 0) && (
              <NoRecords />
            )}
          </Box>
        </Box>
      </Modal>

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenDayDeleteModal}
        handleDeleteConfirmationModalClose={handleDayDeleteCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete session day?"
        handleYesClick={handleDeleteDay}
      />

      <CancelDayConfirmationModal
        isOpenCancelDayConfirmationModal={isOpenDayCancelModal}
        handleCancelDayConfirmationModalClose={handleDayCancelCloseModal}
        handleYesClick={handleCancelDay}
      />

      <CancelCourseCommentModal
        isOpenCancelCommentModal={isOpenCommentModal}
        handleCancelCommentModalClose={handleCancelCommentCloseModal}
        comment={cancelComment}
      />
    </>
  );
};

export default SessionDaysAndAttendance;
