import {
  IEnhancedTablePropsForEnrollmentSummary,
  IEnrollmentSummary,
  IEnrollmentSummaryDetailKeys,
  IEnrollmentSummaryDispatch,
  IEnrollmentSummaryListMeta,
  IEnrollmentSummaryListPagePayloadValues,
  IEnrolmentSummaryFilter,
} from "utils/interfaces/student";
import React, { useEffect, useState } from "react";
import {
  APPROVED_STATUS,
  DATE_VIEW_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  MAX_INT_VALUE,
  PAGE_SIZES,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import { Order, OrderByOptions } from "utils/enums/sorting";
import OverlayBox from "components/OverlayBox";
import {
  EnrollmentSummaryListSuccessPayload,
  PrintEnrolmentSummarySuccessPayload,
} from "store/student/types";
import {
  DisabledEnrolmentStatuses,
  EnabledEnrolmentStatuses,
  base64ToArrayBuffer,
  convertToDateTimeWithFormat,
  getPagePayloadValuesWithId,
  getPagePermissions,
  getPaginationDetailText,
  showLoader,
} from "utils/helper";
import {
  Box,
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import projectTheme from "app.theme";
import { toast } from "react-toastify";
import NoRecords from "components/NoRecords";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppPages } from "utils/enums/app-pages";
import {
  filterLightIcon,
  filterPrimaryIcon,
  withdrawnIcon,
} from "assets/images";
import ConfirmModal from "components/ConfirmModal";
import {
  SELECT_MIN_ONE_FIELD,
  STUDENT_ENROLMENT_WITHDRAWN_SUCCESS_MSG,
} from "utils/constants/Messages";
import { StudentEnrolmentWithdrawnSuccessPayload } from "../../../store/student/types";
import EnrolmentSummaryFilter from "./Filter";

const EnhancedTableHead = (props: IEnhancedTablePropsForEnrollmentSummary) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IEnrollmentSummaryDetailKeys) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};
export type EnrollmentSummaryProps = IEnrollmentSummaryDispatch &
  IEnrollmentSummaryDispatch;

const EnrollmentSummary: React.FC<EnrollmentSummaryProps> = (props) => {
  const [enrollmentSummaryList, setEnrollmentSummaryList] =
    useState<IEnrollmentSummary[]>();
  const [enrollmentSummaryListMeta, setEnrollmentSummaryListMeta] =
    useState<IEnrollmentSummaryListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] =
    useState<keyof IEnrollmentSummaryDetailKeys>("course");
  const [enrolmentWithdrawnId, setEnrolmentWithdrawnId] = useState<number>(0);
  const [isOpenEnrolmentWithdrawnModal, setIsOpenEnrolmentWithdrawnModal] =
    useState<boolean>(false);
  const [filterBox, setFilterBox] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<IEnrolmentSummaryFilter>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
  const filterToggle = () => {
    document.body.classList.toggle("filter-open");
  };

  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const location = useLocation<{ studentId: number }>();

  const id = location?.state?.studentId;
  const onEnrollmentSummaryListSuccess = (
    response: EnrollmentSummaryListSuccessPayload
  ) => {
    setEnrollmentSummaryList(response.enrollmentSummaryList);
    setEnrollmentSummaryListMeta(response.enrollmentSummaryListPagination);
  };

  const getEnrollmentSummaryList = async (
    filterData?: IEnrolmentSummaryFilter,
    pageNum?: number
  ) => {
    const { enrollmentSummaryListRequest } = props;
    if (enrollmentSummaryListRequest) {
      showLoader();

      const values: IEnrollmentSummaryListPagePayloadValues =
        getPagePayloadValuesWithId(id, orderBy, order, pageNo, page);

      if (filterData?.locations) {
        values.locations = filterData?.locations;
      }
      if (filterData?.terms) {
        values.terms = filterData?.terms;
      }
      if (filterData?.status !== null && filterData?.status !== "") {
        values.status = filterData?.status;
      }

      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onEnrollmentSummaryListSuccess,
      };
      enrollmentSummaryListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Student);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getEnrollmentSummaryList(filteredData, 1);
      } else if (filteredData) {
        getEnrollmentSummaryList(filteredData);
      } else {
        getEnrollmentSummaryList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleRequestSort = React.useCallback(
    (
      event: React.MouseEvent<unknown>,
      newOrderBy: keyof IEnrollmentSummaryDetailKeys
    ) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const onStudentEnrolmentWithdrawnSuccess = (
    response: StudentEnrolmentWithdrawnSuccessPayload
  ) => {
    if (response.success) {
      toast.success(STUDENT_ENROLMENT_WITHDRAWN_SUCCESS_MSG);
      getEnrollmentSummaryList();
    }
  };

  const handleStudentEnrolmentWithdrawn = (enrolmentId: number) => {
    const { studentEnrolmentWithdrawnRequest } = props;

    if (studentEnrolmentWithdrawnRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(enrolmentId),
        },
        callback: onStudentEnrolmentWithdrawnSuccess,
      };

      studentEnrolmentWithdrawnRequest(payload);
    }
  };

  const handleEnrolmentWithdrawnModal = (enrolmentId: number) => {
    setEnrolmentWithdrawnId(enrolmentId);
    setIsOpenEnrolmentWithdrawnModal(true);
  };

  const handleEnrolmentWithdrawnCloseModal = () => {
    setEnrolmentWithdrawnId(0);
    setIsOpenEnrolmentWithdrawnModal(false);
  };
  const handleEnrolmentWithdrawn = () => {
    handleEnrolmentWithdrawnCloseModal();
    handleStudentEnrolmentWithdrawn(enrolmentWithdrawnId);
  };

  const onPrintEnrolmentSummarySuccess = (
    response: PrintEnrolmentSummarySuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.item);
    const file = new Blob([item], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.setProperty("print", "fit-to-print");
    iframe.src = fileURL;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      const win = iframe.contentWindow;
      if (win) {
        win.print();
      }
    };
  };

  const getPrintEnrolmentSummary = () => {
    const { printEnrolmentSummaryRequest } = props;

    if (printEnrolmentSummaryRequest) {
      showLoader();

      const values: IEnrollmentSummaryListPagePayloadValues =
        getPagePayloadValuesWithId(
          id,
          orderBy,
          order,
          DEFAULT_PAGE_NO,
          MAX_INT_VALUE
        );

      if (filteredData?.locations) {
        values.locations = filteredData?.locations;
      }
      if (filteredData?.terms) {
        values.terms = filteredData?.terms;
      }
      if (filteredData?.status !== null && filteredData?.status !== "") {
        values.status = filteredData?.status;
      }
      const payload = {
        values,
        callback: onPrintEnrolmentSummarySuccess,
      };

      printEnrolmentSummaryRequest(payload);
    }
  };

  const handleFilterApplyClick = (formData: IEnrolmentSummaryFilter) => {
    if (
      !formData.terms?.length &&
      !formData.locations?.length &&
      !formData.status === null
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      if (pageNo === DEFAULT_PAGE_NO) {
        getEnrollmentSummaryList(formData, 1);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      document.body.classList.toggle("filter-open");
    }
  };

  return (
    <>
      <>
        {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Enrolment Summary
          </Typography>
          <Box className="std-detail-btn-group">
            <Button
              variant="outlined"
              className="btn-back"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
            <Box sx={{ display: "flex", columnGap: "15px" }}>
              <Button
                variant="outlined"
                className="btn-export"
                onClick={getPrintEnrolmentSummary}
                disabled={!enrollmentSummaryList?.length}
              >
                Print
              </Button>
            </Box>
          </Box>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Card>
              <Box className="table-card-header">
                <Box>
                  <Box className="table-card-texts">
                    <Typography variant="body1">Dear Student,</Typography>
                    <Typography variant="body1" sx={{ marginTop: "12px" }}>
                      Welcome to the SOUTH EASTERN SYDNEY RECOVERY COLLEGE, We
                      hope becoming a student with us will take you closer to
                      achieving your goals and ambitions and enable you to
                      develop the knowledge and skills to support yourself and
                      others.
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: "12px" }}>
                      Please see below enrolment requests
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      className="btn-filter"
                      onClick={() => {
                        setFilterBox(true);
                        filterToggle();
                      }}
                    >
                      <img
                        src={filterPrimaryIcon}
                        alt="filter"
                        className="default"
                      />
                      <img
                        src={filterLightIcon}
                        alt="filter"
                        className="active"
                      />
                      <span>Filter</span>
                    </Button>
                  </Box>
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "course")}
                        fieldName="Course"
                        keyName="course"
                        sxStyle={{ width: "345px", minWidth: "345px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "term")}
                        fieldName="Term"
                        keyName="term"
                        sxStyle={{ width: "185px", minWidth: "185px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "location")}
                        fieldName="Location"
                        keyName="location"
                        sxStyle={{ width: "405px", minWidth: "325px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "startDate")}
                        fieldName="Date & Time of First Session"
                        keyName="startDate"
                        sxStyle={{ width: "274px", minWidth: "274px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) =>
                          handleRequestSort(e, "enrollmentStatus")
                        }
                        fieldName="Enrolment Status"
                        keyName="enrollmentStatus"
                        sxStyle={{
                          width: "172px",
                          minWidth: "172px",
                          textAlign: "center",
                        }}
                      />
                      <TableCell
                        align="center"
                        sx={{
                          width: "92px",
                          [projectTheme.breakpoints.down("sm")]: {
                            width: "80px",
                          },
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {enrollmentSummaryList?.map((row) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {row.courseName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.term}
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {row.location}{" "}
                            {row.isBlended &&
                              `- ${row.preferredCourseDelivery}`}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {convertToDateTimeWithFormat(
                            row.startDateOfFirstSession,
                            DATE_VIEW_FORMAT
                          )}{" "}
                          {convertToDateTimeWithFormat(
                            row.startTimeOfFirstSession,
                            TIME_VIEW_FORMAT
                          )}{" "}
                          {"To"}{" "}
                          {convertToDateTimeWithFormat(
                            row.endTimeOfFirstSession,
                            TIME_VIEW_FORMAT
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${row.enrollmentStatus.toLocaleLowerCase()} status`}
                        >
                          {row.enrollmentStatus === APPROVED_STATUS ? (
                            <div>Confirmed</div>
                          ) : (
                            <div>{row.enrollmentStatus}</div>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <div className="table-actions">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Withdraw"
                              arrow
                            >
                              <IconButton
                                disabled={DisabledEnrolmentStatuses.includes(
                                  row.enrollmentStatus
                                )}
                                onClick={() =>
                                  EnabledEnrolmentStatuses.includes(
                                    row.enrollmentStatus
                                  )
                                    ? handleEnrolmentWithdrawnModal(row.id)
                                    : null
                                }
                              >
                                <img
                                  src={withdrawnIcon}
                                  className={
                                    DisabledEnrolmentStatuses.includes(
                                      row.enrollmentStatus
                                    )
                                      ? "disabled-icon"
                                      : ""
                                  }
                                  alt="cancel"
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {enrollmentSummaryList && enrollmentSummaryList.length > 0 ? (
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      {PAGE_SIZES?.map((pageSize) => (
                        <MenuItem
                          key={pageSize.value}
                          value={pageSize.value}
                          selected={pageSize?.selected}
                        >
                          {pageSize.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" component="span">
                      {getPaginationDetailText(
                        enrollmentSummaryListMeta,
                        pageNo,
                        page
                      )}
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={enrollmentSummaryListMeta?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={pageNo}
                    onChange={onPageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              ) : (
                <NoRecords />
              )}
            </Card>
          </Grid>
        </Grid>
        {isOpenEnrolmentWithdrawnModal && (
          <ConfirmModal
            handleSubmitConfirmationModalClose={() =>
              setIsOpenEnrolmentWithdrawnModal(false)
            }
            submitConfirmationMessage="Are you sure you want to withdraw enrolment"
            handleYesClick={handleEnrolmentWithdrawn}
          />
        )}
        {filterBox && (
          <EnrolmentSummaryFilter
            filterBox={filterBox}
            setFilterBox={setFilterBox}
            handleApplyClick={handleFilterApplyClick}
            pageNo={pageNo}
            setPageNo={setPageNo}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            getEnrolmentSummaryList={getEnrollmentSummaryList}
            setAppliedFilter={setAppliedFilter}
          />
        )}
      </>
    </>
  );
};
export default EnrollmentSummary;
