import { Box } from "@mui/system";
import {
  Button,
  Card,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { filterLightIcon, filterPrimaryIcon } from "assets/images";
import React, { useCallback, useEffect, useState } from "react";
import {
  PrintCourseReportSuccessPayload,
  CourseReportListSuccessPayload,
} from "store/courseReport/types";
import {
  base64ToArrayBuffer,
  convertToDateTimeWithFormat,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  hideLoaderForBatchApiCall,
  showLoader,
  showLoaderForBatchApiCall,
} from "utils/helper";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  DATE_VIEW_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  MAX_INT_VALUE,
  PAGE_SIZES,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import { Order, OrderByOptions } from "utils/enums/sorting";
import { IRoleModulePermission } from "store/role/types";
import NoRecords from "components/NoRecords";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { AppPages } from "utils/enums/app-pages";
import {
  ICourseReport,
  ICourseReportContainerState,
  ICourseReportContainerDispatch,
  ICourseReportListMeta,
  ICourseReportPagePayloadValues,
  IEnhancedTableProps,
  ICourseReportFilter,
} from "utils/interfaces/course-report";
import { toast } from "react-toastify";
import { SELECT_MIN_ONE_FIELD } from "utils/constants/Messages";
import OverlayBox from "components/OverlayBox";
import { ExportToCsv } from "export-to-csv";
import CourseReportFilter from "./Filter";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof ICourseReport) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type CourseReportProps = ICourseReportContainerState &
  ICourseReportContainerDispatch;

const CourseReport: React.FC<CourseReportProps> = (props) => {
  const [courseReportList, setCourseReportList] = useState<ICourseReport[]>();
  const [courseReportListMeta, setCourseReportListMeta] =
    useState<ICourseReportListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof ICourseReport>("course");
  const [filterBox, setFilterBox] = useState(false);
  const [filteredData, setFilteredData] = useState<ICourseReportFilter>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [exportMenu, setExportMenu] = React.useState<null | HTMLElement>(null);
  const exportOpen = Boolean(exportMenu);
  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportMenu(event.currentTarget);
  };
  const handleExportClose = () => {
    setExportMenu(null);
  };
  const user = useSelector((state: any) => state?.auth?.user);
  const history = useHistory();

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof ICourseReport) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onCourseReportListSuccess = (
    response: CourseReportListSuccessPayload
  ) => {
    setCourseReportList(response.courseReportList);
    setCourseReportListMeta(response.courseReportListPagination);
    hideLoaderForBatchApiCall();
  };

  const getCourseReportList = async (
    filterData?: ICourseReportFilter,
    pageNum?: number
  ) => {
    const { courseReportListRequest } = props;
    if (courseReportListRequest) {
      showLoaderForBatchApiCall();
      const values: ICourseReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );

      if (filterData?.terms) {
        values.terms = filterData?.terms;
      }
      if (filterData?.locations) {
        values.locations = filterData?.locations;
      }
      if (filterData?.year) {
        values.year = filterData?.year;
      }

      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onCourseReportListSuccess,
      };

      courseReportListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Reports);
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getCourseReportList(filteredData, 1);
      } else if (filteredData) {
        getCourseReportList(filteredData);
      } else {
        getCourseReportList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const onPageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
  };

  const handleFilterApplyClick = (formData: ICourseReportFilter) => {
    if (
      !formData.terms?.length &&
      !formData.locations?.length &&
      !formData.year
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      if (pageNo === DEFAULT_PAGE_NO) {
        getCourseReportList(formData, 1);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      document.body.classList.toggle("filter-open");
    }
  };
  const csvFileHeadersCourseReport = [
    "Course",
    "Term",
    "Location",
    "Year",
    "Date & Time of First Session",
    "No. Enrolled",
  ];

  const getEnrolmentsModifiedData = (list: ICourseReport[]) => {
    const data = list?.map((report: ICourseReport) => {
      return {
        Course: report.course,
        Term: report.term,
        Location: report.location,
        Year: report.year,
        "Date & Time of First Session": `${convertToDateTimeWithFormat(
          report.startDateOfFirstSession,
          DATE_VIEW_FORMAT
        )} ${convertToDateTimeWithFormat(
          report.startDateOfFirstSession,
          TIME_VIEW_FORMAT
        )} To ${convertToDateTimeWithFormat(
          report.endTimeOfFirstSession,
          TIME_VIEW_FORMAT
        )}`,
        "No. Enrolled": report.noEnrolled,
      };
    });

    return data;
  };

  const onExportExcelCourseReportListSuccess = (
    response: CourseReportListSuccessPayload
  ) => {
    const modifiedEnrolmentsData = getEnrolmentsModifiedData(
      response.courseReportList
    );
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeadersCourseReport,
      filename: "Course Report",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedEnrolmentsData);
  };

  const getExportExcelCourseReport = () => {
    const { courseReportListRequest } = props;
    if (courseReportListRequest) {
      showLoader();
      const values: ICourseReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );

      if (filteredData?.locations) {
        values.locations = filteredData?.locations;
      }
      if (filteredData?.terms) {
        values.terms = filteredData?.terms;
      }
      if (filteredData?.year) {
        values.year = filteredData?.year;
      }

      const payload = {
        values,
        callback: onExportExcelCourseReportListSuccess,
      };

      courseReportListRequest(payload);
    }
  };

  const onPrintCourseReportSuccess = (
    response: PrintCourseReportSuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.item);
    const file = new Blob([item], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.setProperty("print", "fit-to-print");
    iframe.src = fileURL;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      const win = iframe.contentWindow;
      if (win) {
        win.print();
      }
    };
  };

  const getPrintCourseReport = () => {
    const { printCourseReportRequest } = props;
    if (printCourseReportRequest) {
      showLoader();
      const values: ICourseReportPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );

      if (filteredData?.locations) {
        values.locations = filteredData?.locations;
      }
      if (filteredData?.terms) {
        values.terms = filteredData?.terms;
      }
      if (filteredData?.year) {
        values.year = filteredData?.year;
      }

      const payload = {
        values,
        callback: onPrintCourseReportSuccess,
      };

      printCourseReportRequest(payload);
    }
  };

  return (
    <>
      <>
        <div className="wrapper">
          <Box
            className="overlay"
            onClick={() => document.body.classList.toggle("sidebar-toggle")}
          />
          <Box
            className="filter-overlay"
            onClick={() => {
              document.body.classList.toggle("filter-open");
              setFilterBox(false);
            }}
          />
          {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Report - Courses
            </Typography>
            <Box sx={{ display: "flex", columnGap: "15px" }}>
              <Button
                id="export-btn"
                aria-controls={exportOpen ? "export-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={exportOpen ? "true" : undefined}
                onClick={handleExportClick}
                disableElevation
                variant="outlined"
                className="btn-export"
              >
                Export
              </Button>
              <Menu
                id="export-menu"
                anchorEl={exportMenu}
                open={exportOpen}
                onClose={handleExportClose}
                MenuListProps={{
                  "aria-labelledby": "export-button",
                }}
                className="export-menu"
              >
                <MenuItem
                  disabled={!pagePermission?.view}
                  onClick={getExportExcelCourseReport}
                  title="Excel"
                >
                  <Button>Excel</Button>
                </MenuItem>
                <MenuItem
                  disabled={!pagePermission?.view}
                  onClick={getPrintCourseReport}
                  title="PDF"
                >
                  <Button>PDF</Button>
                </MenuItem>
              </Menu>
              <Button
                variant="outlined"
                className="btn-filter report-filter"
                onClick={() => {
                  setFilterBox(true);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={filterPrimaryIcon} alt="filter" className="default" />
                <img src={filterLightIcon} alt="filter" className="active" />
                <span className="text">Filter</span>
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Course"
                          keyName="course"
                          sxStyle={{
                            maxWidth: "156px",
                            width: "156px",
                            minWidth: "156px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Term"
                          keyName="term"
                          sxStyle={{
                            maxWidth: "116px",
                            width: "116px",
                            minWidth: "116px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Location"
                          keyName="location"
                          sxStyle={{
                            maxWidth: "156px",
                            width: "156px",
                            minWidth: "156px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Year"
                          keyName="year"
                          sxStyle={{
                            maxWidth: "116px",
                            width: "116px",
                            minWidth: "116px",
                            textAlign: "center",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="Date & Time of First Session"
                          keyName="startDateOfFirstSession"
                          sxStyle={{
                            maxWidth: "156px",
                            width: "156px",
                            minWidth: "156px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          fieldName="No. Enrolled"
                          keyName="noEnrolled"
                          sxStyle={{
                            maxWidth: "136px",
                            width: "136px",
                            minWidth: "136px",
                            textAlign: "center",
                          }}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courseReportList?.map((row) => (
                        <TableRow key={row?.studentId}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row?.course}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row?.term}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row?.location}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                            align="center"
                          >
                            {row?.year}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {convertToDateTimeWithFormat(
                              row.startDateOfFirstSession,
                              DATE_VIEW_FORMAT
                            )}{" "}
                            {convertToDateTimeWithFormat(
                              row.startDateOfFirstSession,
                              TIME_VIEW_FORMAT
                            )}{" "}
                            {"to"}{" "}
                            {convertToDateTimeWithFormat(
                              row.endTimeOfFirstSession,
                              TIME_VIEW_FORMAT
                            )}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                            align="center"
                          >
                            {row?.noEnrolled}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {courseReportList && courseReportList.length > 0 ? (
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        {PAGE_SIZES?.map((pageSize) => (
                          <MenuItem
                            key={pageSize.value}
                            value={pageSize.value}
                            selected={pageSize?.selected}
                          >
                            {pageSize.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="body2" component="span">
                        {getPaginationDetailText(
                          courseReportListMeta,
                          pageNo,
                          page
                        )}
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={courseReportListMeta?.totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={pageNo}
                      onChange={onPageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <NoRecords />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </>
      {filterBox && (
        <CourseReportFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          setAppliedFilter={setAppliedFilter}
          getReportList={getCourseReportList}
        />
      )}
    </>
  );
};

export default CourseReport;
