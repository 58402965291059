import { useEffect } from "react";
import { showLoader } from "utils/helper";
import { toast } from "react-toastify";
import { StudentDocumentsSuccessPayload } from "store/studentDashboard/types";
import { studentDocumentsRequest } from "store/studentDashboard/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppRoutings } from "utils/enums/app-routings";
import { DOCUMENT_PATH, STUDENT_DOCUMENT_PATH } from "utils/constants/constant";

const StudentManual: React.FC = () => {
  const history = useHistory();
  const onStudentManualPreviewSuccess = (
    response: StudentDocumentsSuccessPayload
  ) => {
    if (response.document !== null) {
      const url = response.document.replace(
        DOCUMENT_PATH,
        STUDENT_DOCUMENT_PATH
      );
      window.open(url, "_blank");
      history.push(AppRoutings.StudentDashboard);
    } else {
      toast.success("Manual will be available soon");
    }
  };
  const dispatch = useDispatch();
  const handleStudentManualPreview = () => {
    if (studentDocumentsRequest) {
      showLoader();
      const payload = {
        values: {
          type: "StudentManual",
        },
        callback: onStudentManualPreviewSuccess,
      };
      dispatch(studentDocumentsRequest(payload));
    }
  };
  useEffect(() => {
    handleStudentManualPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default StudentManual;
