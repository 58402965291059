import {
  TERM_LIST_REQUEST,
  TERM_LIST_SUCCESS,
  TERM_LIST_FAILURE,
  ADD_TERM_REQUEST,
  ADD_TERM_SUCCESS,
  ADD_TERM_FAILURE,
  EDIT_TERM_REQUEST,
  EDIT_TERM_SUCCESS,
  EDIT_TERM_FAILURE,
  DELETE_TERM_REQUEST,
  DELETE_TERM_SUCCESS,
  DELETE_TERM_FAILURE,
  TERM_BY_ID_SUCCESS,
  TERM_BY_ID_FAILURE,
  TERM_BY_ID_REQUEST,
} from "./actionTypes";

import { TermActions, TermState } from "./types";

const initialState: TermState = {
  pending: false,
  termList: [],
  error: null,
};

const reducers = (state = initialState, action: TermActions): unknown => {
  switch (action.type) {
    case TERM_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case TERM_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        termList: action.payload.termList,
        error: null,
      };
    case TERM_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_TERM_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_TERM_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_TERM_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_TERM_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_TERM_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_TERM_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_TERM_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_TERM_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_TERM_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case TERM_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case TERM_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        termDetails: action.payload.termDetails,
        error: null,
      };
    case TERM_BY_ID_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
