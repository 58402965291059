import {
  EDUCATOR_LIST_REQUEST,
  EDUCATOR_LIST_SUCCESS,
  EDUCATOR_LIST_FAILURE,
  ADD_EDUCATOR_REQUEST,
  ADD_EDUCATOR_SUCCESS,
  ADD_EDUCATOR_FAILURE,
  EDIT_EDUCATOR_REQUEST,
  EDIT_EDUCATOR_SUCCESS,
  EDIT_EDUCATOR_FAILURE,
  DELETE_EDUCATOR_REQUEST,
  DELETE_EDUCATOR_SUCCESS,
  DELETE_EDUCATOR_FAILURE,
  EDUCATOR_BY_ID_REQUEST,
  EDUCATOR_BY_ID_SUCCESS,
  EDUCATOR_BY_ID_FAILURE,
  COURSES_DELIVERED_LIST_REQUEST,
  COURSES_DELIVERED_LIST_SUCCESS,
  COURSES_DELIVERED_LIST_FAILURE,
  COURSES_ENROLED_LIST_REQUEST,
  COURSES_ENROLED_LIST_SUCCESS,
  COURSES_ENROLED_LIST_FAILURE,
  EDUCATOR_STATISTICS_DATA_FAILURE,
  EDUCATOR_STATISTICS_DATA_REQUEST,
  EDUCATOR_STATISTICS_DATA_SUCCESS,
} from "./actionTypes";

import { EducatorActions, EducatorState } from "./types";

const initialState: EducatorState = {
  pending: false,
  educatorList: [],
  error: null,
};

const reducers = (state = initialState, action: EducatorActions): unknown => {
  switch (action.type) {
    case EDUCATOR_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDUCATOR_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        educatorList: action.payload.educatorList,
        error: null,
      };
    case EDUCATOR_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_EDUCATOR_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_EDUCATOR_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_EDUCATOR_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_EDUCATOR_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_EDUCATOR_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_EDUCATOR_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_EDUCATOR_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_EDUCATOR_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_EDUCATOR_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDUCATOR_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDUCATOR_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        educatorDetails: action.payload.educatorDetails,
        error: null,
      };
    case EDUCATOR_BY_ID_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case COURSES_DELIVERED_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case COURSES_DELIVERED_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        coursesDeliveredList: action.payload.coursesDeliveredList,
        error: null,
      };
    case COURSES_DELIVERED_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case COURSES_ENROLED_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case COURSES_ENROLED_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        coursesEnroledList: action.payload.coursesEnroledList,
        error: null,
      };
    case COURSES_ENROLED_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDUCATOR_STATISTICS_DATA_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDUCATOR_STATISTICS_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        statisticsData: action.payload.statisticsData,
        error: null,
      };
    case EDUCATOR_STATISTICS_DATA_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
