import { noDataIcon } from "assets/images";
import { Box, Card, Typography } from "@mui/material";

const NoCourseOfferingStep = () => {
  return (
    <div className="wrapper">
      <Box className="stepper-container">
        <Box className="stepper-content-container">
          <Card className="empty-container">
            <div>
              <img src={noDataIcon} alt="No data" />
              <Typography variant="h4">
                No Course Offerings Available
              </Typography>
            </div>
          </Card>
        </Box>
      </Box>
    </div>
  );
};

export default NoCourseOfferingStep;
