import { connect, MapDispatchToProps } from "react-redux";
import ForgotPasswordComponent, {
  ForgotPasswordProps,
} from "pages/ForgotPassword";
import { forgotPasswordRequest } from "store/auth/actions";
import {
  IForgotPasswordContainerDispatch,
  IForgotPassword,
} from "utils/interfaces/login";

const mapDispatchToProps: MapDispatchToProps<
  IForgotPasswordContainerDispatch,
  IForgotPassword
> = {
  forgotPasswordRequest,
};

const connector = connect(null, mapDispatchToProps);

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  return <ForgotPasswordComponent {...props} />;
};

export default connector(ForgotPassword);
