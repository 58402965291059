export const CURRENT_COURSE_LIST_REQUEST = "CURRENT_COURSE_LIST_REQUEST";
export const CURRENT_COURSE_LIST_SUCCESS = "CURRENT_COURSE_LIST_SUCCESS";
export const CURRENT_COURSE_LIST_FAILURE = "CURRENT_COURSE_LIST_FAILURE";

export const PAST_COURSE_LIST_REQUEST = "PAST_COURSE_LIST_REQUEST";
export const PAST_COURSE_LIST_SUCCESS = "PAST_COURSE_LIST_SUCCESS";
export const PAST_COURSE_LIST_FAILURE = "PAST_COURSE_LIST_FAILURE";

export const SCHEDULED_OFFERING_DETAILS_REQUEST =
  "SCHEDULED_OFFERING_DETAILS_REQUEST";
export const SCHEDULED_OFFERING_DETAILS_SUCCESS =
  "SCHEDULED_OFFERING_DETAILS_SUCCESS";
export const SCHEDULED_OFFERING_DETAILS_FAILURE =
  "SCHEDULED_OFFERING_DETAILS_FAILURE";

export const ADD_COURSE_REFLECTION_FORM_REQUEST =
  "ADD_COURSE_REFLECTION_FORM_REQUEST";
export const ADD_COURSE_REFLECTION_FORM_SUCCESS =
  "ADD_COURSE_REFLECTION_FORM_SUCCESS";
export const ADD_COURSE_REFLECTION_FORM_FAILURE =
  "ADD_COURSE_REFLECTION_FORM_FAILURE";

export const EDIT_COURSE_REFLECTION_FORM_REQUEST =
  "EDIT_COURSE_REFLECTION_FORM_REQUEST";
export const EDIT_COURSE_REFLECTION_FORM_SUCCESS =
  "EDIT_COURSE_REFLECTION_FORM_SUCCESS";
export const EDIT_COURSE_REFLECTION_FORM_FAILURE =
  "EDIT_COURSE_REFLECTION_FORM_FAILURE";

export const ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_REQUEST =
  "ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_REQUEST";
export const ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_SUCCESS =
  "ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_SUCCESS";
export const ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_FAILURE =
  "ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_FAILURE";

export const MISSING_ATTENDANCE_COURSE_LIST_REQUEST =
  "MISSING_ATTENDANCE_COURSE_LIST_REQUEST";
export const MISSING_ATTENDANCE_COURSE_LIST_SUCCESS =
  "MISSING_ATTENDANCE_COURSE_LIST_SUCCESS";
export const MISSING_ATTENDANCE_COURSE_LIST_FAILURE =
  "MISSING_ATTENDANCE_COURSE_LIST_FAILURE";
