import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import { saveIcon } from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";

const DesignerProfile: React.FC = () => {
  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        <div className="main-content">
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Profile
            </Typography>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card className="profile-card">
                <Box className="profile-card-container">
                  <Grid container columnSpacing="24px">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="first-name"
                        label="Firstname"
                        fullWidth
                        defaultValue="James"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="last-name"
                        label="Lastname"
                        fullWidth
                        defaultValue="Henry"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="role"
                        label="Role"
                        fullWidth
                        disabled
                        defaultValue="Admin"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="email"
                        type="email"
                        label="Email"
                        fullWidth
                        disabled
                        defaultValue="james.henry@email.com"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="address"
                        label="Address"
                        className="textarea-add"
                        fullWidth
                        multiline
                        rows={3}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Box className="btn-group">
                    <Button variant="contained" className="btn-save">
                      <img src={saveIcon} alt="save" />
                      Save
                    </Button>
                    <Button variant="outlined" className="btn-cancel">
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
          <DesignerFooter />
        </div>
      </main>
    </div>
  );
};

export default DesignerProfile;
