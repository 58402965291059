import {
  ICourseReportContainerDispatch,
  ICourseReport,
} from "utils/interfaces/course-report";
import CourseReportComponent, { CourseReportProps } from "pages/CourseReport";
import { connect, MapDispatchToProps } from "react-redux";
import {
  courseReportListRequest,
  printCourseReportRequest,
} from "store/courseReport/actions";

const mapDispatchToProps: MapDispatchToProps<
  ICourseReportContainerDispatch,
  ICourseReport
> = {
  courseReportListRequest,
  printCourseReportRequest,
};

const connector = connect(null, mapDispatchToProps);

const CourseReport: React.FC<CourseReportProps> = (props) => {
  return <CourseReportComponent {...props} />;
};

export default connector(CourseReport);
