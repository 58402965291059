import { toast, ToastOptions } from "react-toastify";

const useToast = () => {
  const errorToast = (message: string, toastOptions?: ToastOptions) => {
    toast.error(message, {
      ...toastOptions,
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return {
    errorToast,
  };
};

export default useToast;
