import {
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  COURSE_LIST_FAILURE,
  ADD_COURSE_REQUEST,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_FAILURE,
  EDIT_COURSE_REQUEST,
  EDIT_COURSE_SUCCESS,
  EDIT_COURSE_FAILURE,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILURE,
  COURSE_BY_ID_REQUEST,
  COURSE_BY_ID_SUCCESS,
  COURSE_BY_ID_FAILURE,
} from "./actionTypes";

import { CourseActions, CourseState } from "./types";

const initialState: CourseState = {
  pending: false,
  courseList: [],
  error: null,
};

const reducers = (state = initialState, action: CourseActions): unknown => {
  switch (action.type) {
    case COURSE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case COURSE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        courseList: action.payload.courseList,
        error: null,
      };
    case COURSE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_COURSE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_COURSE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_COURSE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_COURSE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_COURSE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_COURSE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_COURSE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_COURSE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_COURSE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case COURSE_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case COURSE_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        courseDetails: action.payload.courseDetails,
        error: null,
      };
    case COURSE_BY_ID_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
