import { Box } from "@mui/system";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import {
  CLOSE_TOOLTIP_TITLE,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  REJECTION_NOTE_MAX_LENGTH,
} from "utils/constants/constant";
import { ICourseCancel } from "utils/interfaces/scheduled-offering";
import { useForm } from "react-hook-form";
import { WHITE_SPACE_ERROR_SPACE } from "utils/constants/Messages";

interface ICancelDayConfirmationModalProps {
  isOpenCancelDayConfirmationModal: boolean;
  handleCancelDayConfirmationModalClose: () => void;
  handleYesClick: (data: ICourseCancel) => void;
}

const CancelDayConfirmationModal: React.FC<ICancelDayConfirmationModalProps> =
  ({
    isOpenCancelDayConfirmationModal,
    handleCancelDayConfirmationModalClose,
    handleYesClick,
  }) => {
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<ICourseCancel>();

    const getCommentError = (): string => {
      if (errors.cancellationComment) {
        if (errors.cancellationComment.type === "required") {
          return "Comment is required";
        }
        if (errors.cancellationComment.type === "maxLength") {
          return `Maximum length of comment should be ${REJECTION_NOTE_MAX_LENGTH}`;
        }
        if (errors.cancellationComment.type === "pattern") {
          return `Comment ${WHITE_SPACE_ERROR_SPACE}`;
        }
      }

      return "";
    };

    const handleModalClose = () => {
      reset();
      handleCancelDayConfirmationModalClose();
    };

    const handleCommentSubmit = (data: ICourseCancel) => {
      handleYesClick(data);
      reset();
    };

    return (
      <>
        <Modal
          open={isOpenCancelDayConfirmationModal}
          onClose={handleCancelDayConfirmationModalClose}
        >
          <Box className="common-modal delete-modal rejection-modal">
            <form onSubmit={handleSubmit(handleCommentSubmit)}>
              <Box className="modal-header">
                <Typography variant="h4">Cancel Day Confirmation</Typography>
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={CLOSE_TOOLTIP_TITLE}
                  arrow
                >
                  <IconButton onClick={handleModalClose}>
                    <img src={closeIcon} alt="close" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box className="modal-body">
                <Typography variant="h5">
                  Are you sure you want to cancel session day?
                </Typography>
                <TextField
                  id="reason-rejection"
                  label={
                    <>
                      Please enter comment for cancellation{" "}
                      <span className="color-red">*</span>
                    </>
                  }
                  fullWidth
                  variant="outlined"
                  multiline
                  className="skills-textarea"
                  error={!!errors.cancellationComment}
                  helperText={getCommentError()}
                  {...register("cancellationComment", {
                    required: true,
                    maxLength: REJECTION_NOTE_MAX_LENGTH,
                    pattern:
                      INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                  })}
                />
              </Box>
              <Box className="modal-footer">
                <Button variant="contained" className="btn-save" type="submit">
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  className="btn-cancel"
                  onClick={handleModalClose}
                >
                  No
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </>
    );
  };

export default CancelDayConfirmationModal;
