import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  addRoleFailure,
  addRoleSuccess,
  deleteRoleFailure,
  deleteRoleSuccess,
  editRoleFailure,
  editRoleSuccess,
  moduleListFailure,
  moduleListSuccess,
  roleListFailure,
  roleListSuccess,
  rolePermissionsFailure,
  rolePermissionsSuccess,
} from "./actions";
import {
  ROLE_LIST_REQUEST,
  DELETE_ROLE_REQUEST,
  ADD_ROLE_REQUEST,
  EDIT_ROLE_REQUEST,
  ROLE_PERMISSIONS_REQUEST,
  MODULE_LIST_REQUEST,
} from "./actionTypes";
import roleService from "./service";
import {
  AddEditRoleResponse,
  DeleteRoleResponse,
  ModuleListResponse,
  RoleListResponse,
  RolePermissionsResponse,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* roleListSaga(action: any) {
  try {
    const response: RoleListResponse = yield call(roleService.getRoleList, {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      role: action.payload.values.roleName,
    });

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      roleListSuccess({
        roleList: response.item.records,
        roleListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      roleList: response.item.records,
      roleListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      roleListFailure({
        error: e.message,
      })
    );
  }
}

function* addRoleSaga(action: any) {
  try {
    const response: AddEditRoleResponse = yield call(roleService.addRole, {
      role: action.payload.values.role,
      permissions: action.payload.values.permissions,
    });

    yield put(
      addRoleSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addRoleFailure({
        error: e.message,
      })
    );
  }
}

function* editRoleSaga(action: any) {
  try {
    const response: AddEditRoleResponse = yield call(roleService.editRole, {
      id: action.payload.values.id,
      role: action.payload.values.role,
      permissions: action.payload.values.permissions,
    });

    yield put(
      editRoleSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editRoleFailure({
        error: e.message,
      })
    );
  }
}

function* deleteRoleSaga(action: any) {
  try {
    const response: DeleteRoleResponse = yield call(roleService.deleteRole, {
      id: action.payload.values.id,
    });

    yield put(
      deleteRoleSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      deleteRoleFailure({
        error: e.message,
      })
    );
  }
}

function* rolePermissionsSaga(action: any) {
  try {
    const response: RolePermissionsResponse = yield call(
      roleService.getRolePermissions,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      rolePermissionsSuccess({
        rolePermissionList: response.item,
      })
    );
    action.payload.callback({
      rolePermissionList: response.item,
    });
  } catch (e: any) {
    yield put(
      rolePermissionsFailure({
        error: e.message,
      })
    );
  }
}

function* moduleListSaga(action: any) {
  try {
    const response: ModuleListResponse = yield call(roleService.getModuleList);

    yield put(
      moduleListSuccess({
        moduleList: response.item,
      })
    );
    action.payload.callback({
      moduleList: response.item,
    });
  } catch (e: any) {
    yield put(
      moduleListFailure({
        error: e.message,
      })
    );
  }
}

function* roleSaga() {
  yield all([takeLatest(ROLE_LIST_REQUEST, roleListSaga)]);
  yield all([takeLatest(ADD_ROLE_REQUEST, addRoleSaga)]);
  yield all([takeLatest(EDIT_ROLE_REQUEST, editRoleSaga)]);
  yield all([takeLatest(DELETE_ROLE_REQUEST, deleteRoleSaga)]);
  yield all([takeLatest(ROLE_PERMISSIONS_REQUEST, rolePermissionsSaga)]);
  yield all([takeLatest(MODULE_LIST_REQUEST, moduleListSaga)]);
}

export default roleSaga;
