import { all, call, put, takeLatest } from "redux-saga/effects";
import { IReportPagePayloadValues } from "utils/interfaces/report";
import { AxiosError } from "axios";
import {
  getEnrolmentReportStudentDetailsFailure,
  getEnrolmentReportStudentDetailsSuccess,
  printEnrolmentReportFailure,
  printEnrolmentReportSuccess,
  reportListFailure,
  reportListSuccess,
} from "./actions";
import {
  GET_ENROLMENT_REPORT_STUDENT_DETAILS_REQUEST,
  PRINT_ENROLMENT_REPORT_REQUEST,
  REPORT_LIST_REQUEST,
} from "./actionTypes";
import reportService from "./service";
import {
  GetEnrolmentReportStudentDetailsRequest,
  GetEnrolmentReportStudentDetailsResponse,
  PrintEnrolmentReportRequest,
  PrintEnrolmentReportResponse,
  ReportListRequest,
  ReportListResponse,
} from "./types";

function* reportListSaga(action: ReportListRequest) {
  try {
    const payload: IReportPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      termName: action.payload.values.termName,
    };
    if (action.payload.values.enrolmentType) {
      payload.enrolmentType = action.payload.values.enrolmentType;
    }
    if (action.payload.values.sector) {
      payload.sector = action.payload.values.sector;
    }
    if (action.payload.values.age) {
      payload.age = action.payload.values.age;
    }
    if (action.payload.values.gender) {
      payload.gender = action.payload.values.gender;
    }
    if (action.payload.values.aTSI) {
      payload.aTSI = action.payload.values.aTSI;
    }
    if (action.payload.values.employmentStatus) {
      payload.employmentStatus = action.payload.values.employmentStatus;
    }
    if (action.payload.values.studyLoad) {
      payload.studyLoad = action.payload.values.studyLoad;
    }
    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }

    const response: ReportListResponse = yield call(
      reportService.getReportList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      reportListSuccess({
        reportList: response.item.records,
        reportListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      reportList: response.item.records,
      reportListPagination: paginationDetails,
    });
  } catch (e) {
    yield put(
      reportListFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* printEnrolmentReportSaga(action: PrintEnrolmentReportRequest) {
  try {
    const payload: IReportPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      termName: action.payload.values.termName,
    };
    if (action.payload.values.enrolmentType) {
      payload.enrolmentType = action.payload.values.enrolmentType;
    }
    if (action.payload.values.sector) {
      payload.sector = action.payload.values.sector;
    }
    if (action.payload.values.age) {
      payload.age = action.payload.values.age;
    }
    if (action.payload.values.gender) {
      payload.gender = action.payload.values.gender;
    }
    if (action.payload.values.aTSI) {
      payload.aTSI = action.payload.values.aTSI;
    }
    if (action.payload.values.employmentStatus) {
      payload.employmentStatus = action.payload.values.employmentStatus;
    }
    if (action.payload.values.studyLoad) {
      payload.studyLoad = action.payload.values.studyLoad;
    }
    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }

    const response: PrintEnrolmentReportResponse = yield call(
      reportService.printEnrolmentReport,
      payload
    );

    yield put(
      printEnrolmentReportSuccess({
        item: response.item,
      })
    );
    action.payload.callback({
      item: response.item,
    });
  } catch (e) {
    yield put(
      printEnrolmentReportFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* getEnrolmentReportStudentDetailsSaga(
  action: GetEnrolmentReportStudentDetailsRequest
) {
  try {
    const payload: IReportPagePayloadValues = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      termName: action.payload.values.termName,
    };
    if (action.payload.values.enrolmentType) {
      payload.enrolmentType = action.payload.values.enrolmentType;
    }
    if (action.payload.values.sector) {
      payload.sector = action.payload.values.sector;
    }
    if (action.payload.values.age) {
      payload.age = action.payload.values.age;
    }
    if (action.payload.values.gender) {
      payload.gender = action.payload.values.gender;
    }
    if (action.payload.values.aTSI) {
      payload.aTSI = action.payload.values.aTSI;
    }
    if (action.payload.values.employmentStatus) {
      payload.employmentStatus = action.payload.values.employmentStatus;
    }
    if (action.payload.values.studyLoad) {
      payload.studyLoad = action.payload.values.studyLoad;
    }
    if (action.payload.values.terms) {
      payload.terms = action.payload.values.terms;
    }
    if (action.payload.values.termId) {
      payload.termId = action.payload.values.termId;
    }
    const response: GetEnrolmentReportStudentDetailsResponse = yield call(
      reportService.getEnrolmentReportStudentDetails,
      payload
    );
    yield put(
      getEnrolmentReportStudentDetailsSuccess({
        enrolmentReportStudentDetailList: response.item,
      })
    );
    action.payload.callback({
      enrolmentReportStudentDetailList: response.item,
    });
  } catch (e) {
    yield put(
      getEnrolmentReportStudentDetailsFailure({
        error: (e as AxiosError).message,
      })
    );
  }
}

function* reportSaga() {
  yield all([takeLatest(REPORT_LIST_REQUEST, reportListSaga)]);
  yield all([
    takeLatest(PRINT_ENROLMENT_REPORT_REQUEST, printEnrolmentReportSaga),
  ]);
  yield all([
    takeLatest(
      GET_ENROLMENT_REPORT_STUDENT_DETAILS_REQUEST,
      getEnrolmentReportStudentDetailsSaga
    ),
  ]);
}

export default reportSaga;
