import { Grid, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  COURSE_OFFERING_TYPE,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
} from "utils/constants/constant";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  allowSearch,
  getPagePayloadValuesWithId,
  getPagePermissions,
  hideLoaderForBatchApiCall,
  showLoaderForBatchApiCall,
  toggleFilter,
} from "utils/helper";
import { useHistory } from "react-router-dom";
import {
  ICourseOfferingFilter,
  ICoursesOfferings,
  IPastCourses,
  IStudentDashboardListMeta,
  IStudentDashboardPagePayloadValues,
} from "utils/interfaces/student-dashboard";
import { CourseOfferingListSuccessPayload } from "store/studentDashboard/types";
import { useSelector } from "react-redux";
import StudentCourseTable from "components/StudentCoursesTable";
import { IRoleModulePermission } from "store/role/types";
import { AppPages } from "utils/enums/app-pages";
import { SELECT_MIN_ONE_FIELD } from "utils/constants/Messages";
import { toast } from "react-toastify";
import { SCOBlendedCourse } from "utils/enums/scheduled-offering";
import StudentCoursesFilter from "../StudentCoursesFilter";

const CoursesOfferingList: React.FC<any> = (props) => {
  const history = useHistory();
  const [page, setPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const user = useSelector((state: any) => state?.auth?.user);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof ICoursesOfferings>("courseName");
  const [filteredData, setFilteredData] = useState<ICourseOfferingFilter>();
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [courseOfferingList, setCourseOfferingList] =
    useState<ICoursesOfferings[]>();
  const [filterBox, setFilterBox] = useState(false);
  const [courseOfferingListMeta, setCourseOfferingListMeta] =
    useState<IStudentDashboardListMeta>();
  const [searchText, setSearchText] = useState<string>("");

  const handleSearch = (event: any) => {
    setSearchText(event?.target?.value);
    setFilteredData((prevState: any) => ({
      ...prevState,
      name: event?.target?.value,
    }));
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event?.target?.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IPastCourses) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onCourseOfferingListSuccess = (
    response: CourseOfferingListSuccessPayload
  ) => {
    setCourseOfferingList(response?.courseOfferingList);
    setCourseOfferingListMeta(response?.courseOfferingListPagination);
    hideLoaderForBatchApiCall();
  };

  const getCourseOfferingsList = (filterData?: ICourseOfferingFilter) => {
    const { courseOfferingListRequest } = props;
    if (courseOfferingListRequest) {
      showLoaderForBatchApiCall();
      const values: IStudentDashboardPagePayloadValues =
        getPagePayloadValuesWithId(
          user?.studentId,
          orderBy,
          order,
          pageNo,
          page
        );
      if (filterData?.name) {
        values.name = filterData?.name;
      }
      if (filterData?.term) {
        values.term = filterData?.term;
      }
      if (filterData?.locationName) {
        values.locationName = filterData?.locationName;
      }
      if (
        filterData?.isBlendedCourse === SCOBlendedCourse.No ||
        filterData?.isBlendedCourse === SCOBlendedCourse.Yes
      ) {
        values.isBlendedCourse = filterData?.isBlendedCourse;
      }
      if (filterData) {
        setFilteredData(filterData);
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onCourseOfferingListSuccess,
      };
      courseOfferingListRequest(payload);
    }
  };

  const handleSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getCourseOfferingsList(filteredData);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.StudentDashboard,
      "student"
    );
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData) {
        getCourseOfferingsList(filteredData);
      } else {
        getCourseOfferingsList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, order, orderBy, page]);

  const handleFilter = () => {
    setFilterBox(true);
    toggleFilter();
  };

  const handleFilterApplyClick = (formData: ICourseOfferingFilter) => {
    if (
      !(
        formData.name ||
        formData.term ||
        formData.locationName ||
        formData.isBlendedCourse === SCOBlendedCourse.No ||
        formData.isBlendedCourse === SCOBlendedCourse.Yes
      )
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      setSearchText(formData?.name);
      if (pageNo === DEFAULT_PAGE_NO) {
        getCourseOfferingsList(formData);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      toggleFilter();
    }
  };

  const clearSearchText = () => {
    setSearchText("");
    setFilteredData((prevFilteredData: any) => ({
      ...prevFilteredData,
      name: "",
    }));
    getCourseOfferingsList({
      name: "",
      locationName: filteredData?.locationName ? filteredData?.locationName : 0,
      term: filteredData?.term ? filteredData?.term : 0,
      isBlendedCourse: filteredData?.isBlendedCourse
        ? filteredData?.isBlendedCourse
        : null,
    });
  };

  const clearFilter = () => {
    setSearchText("");
    setFilteredData((prevFilteredData: any) => ({
      ...prevFilteredData,
      name: "",
    }));
  };

  return (
    <Grid item xs={12}>
      <StudentCourseTable
        coursesList={courseOfferingList}
        pagePermission={pagePermission}
        courseListMeta={courseOfferingListMeta}
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        handleChange={handleChange}
        onPageChange={onPageChange}
        page={page}
        pageNo={pageNo}
        courseListType={COURSE_OFFERING_TYPE}
        handleFilter={handleFilter}
        searchText={searchText}
        handleSearch={handleSearch}
        handleSearchKeyDown={handleSearchKeyDown}
        handleClearSearch={clearSearchText}
      />
      {filterBox && (
        <StudentCoursesFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          getUpcomingCoursesList={getCourseOfferingsList}
          clearSearchText={clearFilter}
        />
      )}
    </Grid>
  );
};

export default CoursesOfferingList;
