export const ENROLMENT_OPTION_LIST_REQUEST = "ENROLMENT_OPTION_LIST_REQUEST";
export const ENROLMENT_OPTION_LIST_SUCCESS = "ENROLMENT_OPTION_LIST_SUCCESS";
export const ENROLMENT_OPTION_LIST_FAILURE = "ENROLMENT_OPTION_LIST_FAILURE";

export const CURRENT_ENROL_COURSE_LIST_REQUEST =
  "CURRENT_ENROL_COURSE_LIST_REQUEST";
export const CURRENT_ENROL_COURSE_LIST_SUCCESS =
  "CURRENT_ENROL_COURSE_LIST_SUCCESS";
export const CURRENT_ENROL_COURSE_LIST_FAILURE =
  "CURRENT_ENROL_COURSE_LIST_FAILURE";

export const ADD_ENROLMENT_REQUEST = "ADD_ENROLMENT_REQUEST";
export const ADD_ENROLMENT_SUCCESS = "ADD_ENROLMENT_SUCCESS";
export const ADD_ENROLMENT_FAILURE = "ADD_ENROLMENT_FAILURE";

export const CHECK_VALID_ENROLMENT_REQUEST = "CHECK_VALID_ENROLMENT_REQUEST";
export const CHECK_VALID_ENROLMENT_SUCCESS = "CHECK_VALID_ENROLMENT_SUCCESS";
export const CHECK_VALID_ENROLMENT_FAILURE = "CHECK_VALID_ENROLMENT_FAILURE";

export const STUDENT_ENROLMENT_LIST_REQUEST = "STUDENT_ENROLMENT_LIST_REQUEST";
export const STUDENT_ENROLMENT_LIST_SUCCESS = "STUDENT_ENROLMENT_LIST_SUCCESS";
export const STUDENT_ENROLMENT_LIST_FAILURE = "STUDENT_ENROLMENT_LIST_FAILURE";

export const STUDENT_DETAIL_REQUEST = "STUDENT_DETAIL_REQUEST";
export const STUDENT_DETAIL_SUCCESS = "STUDENT_DETAIL_SUCCESS";
export const STUDENT_DETAIL_FAILURE = "STUDENT_DETAIL_FAILURE";

export const REJECT_ENROLMENT_REQUEST = "REJECT_ENROLMENT_REQUEST";
export const REJECT_ENROLMENT_SUCCESS = "REJECT_ENROLMENT_SUCCESS";
export const REJECT_ENROLMENT_FAILURE = "REJECT_ENROLMENT_FAILURE";
export const APPROVE_ENROLMENT_REQUEST = "APPROVE_ENROLMENT_REQUEST";
export const APPROVE_ENROLMENT_SUCCESS = "APPROVE_ENROLMENT_SUCCESS";
export const APPROVE_ENROLMENT_FAILURE = "APPROVE_ENROLMENT_FAILURE";

export const GET_STUDENT_ENROLMENT_FOR_APPROVAL_REQUEST =
  "GET_STUDENT_ENROLMENT_FOR_APPROVAL_REQUEST";
export const GET_STUDENT_ENROLMENT_FOR_APPROVAL_SUCCESS =
  "GET_STUDENT_ENROLMENT_FOR_APPROVAL_SUCCESS";
export const GET_STUDENT_ENROLMENT_FOR_APPROVAL_FAILURE =
  "GET_STUDENT_ENROLMENT_FOR_APPROVAL_FAILURE";

export const CHECK_VALID_RECAPTCHA_REQUEST = "CHECK_VALID_RECAPTCHA_REQUEST";
export const CHECK_VALID_RECAPTCHA_SUCCESS = "CHECK_VALID_RECAPTCHA_SUCCESS";
export const CHECK_VALID_RECAPTCHA_FAILURE = "CHECK_VALID_RECAPTCHA_FAILURE";

export const CHANGE_ENROLMENT_STATUS_REQUEST =
  "CHANGE_ENROLMENT_STATUS_REQUEST";
export const CHANGE_ENROLMENT_STATUS_SUCCESS =
  "CHANGE_ENROLMENT_STATUS_SUCCESS";
export const CHANGE_ENROLMENT_STATUS_FAILURE =
  "CHANGE_ENROLMENT_STATUS_FAILURE";
