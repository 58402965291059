import { Box } from "@mui/system";
import {
  Card,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { courseListSecondaryIcon } from "assets/images";
import projectTheme from "app.theme";
import React, { useEffect, useState } from "react";
import {
  BLENDED_FACE_TO_FACE_ONLINE,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZES,
  VIEW_TOOLTIP_TITLE,
} from "utils/constants/constant";
import {
  IEducatorHomeListMeta,
  IEnhancedTableProps,
  IPastCourse,
  IPastCoursesDetails,
} from "utils/interfaces/educator-home";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  getPagePayloadValuesWithId,
  getPaginationDetailText,
  hideLoaderForBatchApiCall,
  showLoaderForBatchApiCall,
} from "utils/helper";
import { PastCourseListSuccessPayload } from "store/educatorHome/types";
import { useHistory } from "react-router-dom";
import NoRecords from "components/NoRecords";
import { AppRoutings } from "utils/enums/app-routings";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IPastCoursesDetails) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

const PastCoursesList: React.FC<any> = (props) => {
  const history = useHistory();
  const [page, setPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IPastCoursesDetails>("course");
  const [pastCoursesList, setPastCoursesList] = useState<IPastCourse[]>();
  const [pastCoursesListMeta, setPastCoursesListMeta] =
    useState<IEducatorHomeListMeta>();

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (
      event: React.MouseEvent<unknown>,
      newOrderBy: keyof IPastCoursesDetails
    ) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onPastCoursesListSuccess = (response: PastCourseListSuccessPayload) => {
    setPastCoursesList(response.pastCourseList);
    setPastCoursesListMeta(response.pastCourseListPagination);
    hideLoaderForBatchApiCall();
  };

  const getPastCoursesList = () => {
    const { pastCourseListRequest } = props;

    if (pastCourseListRequest) {
      showLoaderForBatchApiCall();

      const payload = {
        values: getPagePayloadValuesWithId(
          props?.userId,
          orderBy,
          order,
          pageNo,
          page
        ),
        callback: onPastCoursesListSuccess,
      };

      pastCourseListRequest(payload);
    }
  };

  useEffect(() => {
    getPastCoursesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  return (
    <Grid item xs={12}>
      <Card>
        <Box className="table-card-header">
          <Box sx={{ justifyContent: "space-between", width: "100%" }}>
            <Typography variant="h3">My Past Courses</Typography>
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(e) => handleRequestSort(e, "course")}
                  fieldName="Session Name"
                  keyName="course"
                  sxStyle={{
                    width: "302px",
                    minWidth: "302px",
                    [projectTheme.breakpoints.down("lg")]: {
                      width: "210px",
                      minWidth: "210px",
                    },
                  }}
                />
                <TableCell
                  align="left"
                  sx={{
                    width: "110px",
                    minWidth: "110px",
                    [projectTheme.breakpoints.down("lg")]: {
                      width: "94px",
                      minWidth: "94px",
                    },
                  }}
                >
                  Term
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ width: "382px", minWidth: "382px" }}
                >
                  Location
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    width: "255px",
                    minWidth: "255px",
                    [projectTheme.breakpoints.down("lg")]: {
                      width: "254px",
                      minWidth: "254px",
                    },
                  }}
                >
                  Date & Time of First Session
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: "98px",
                    [projectTheme.breakpoints.down("sm")]: {
                      width: "90px",
                    },
                  }}
                >
                  Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pastCoursesList?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Typography variant="body2" className="sco-list-word-wrap">
                      {row.courseName}
                    </Typography>
                  </TableCell>
                  <TableCell>{row.term}</TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      className="location sco-list-word-wrap"
                    >
                      {row.location}
                      {row.isBlended && BLENDED_FACE_TO_FACE_ONLINE}
                    </Typography>
                  </TableCell>
                  <TableCell>{`${row.startDate} ${row.startTime} to ${row.endTime}`}</TableCell>
                  <TableCell align="center">
                    <div className="table-actions">
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={VIEW_TOOLTIP_TITLE}
                        arrow
                      >
                        <IconButton
                          onClick={() =>
                            history.push({
                              pathname: AppRoutings.EducatorsHomeDetails,
                              state: { scoId: row.id },
                            })
                          }
                          disabled={!props?.pagePermission?.edit}
                          className={
                            !props?.pagePermission?.edit ? "disabled-icon" : ""
                          }
                        >
                          <img src={courseListSecondaryIcon} alt="list" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {pastCoursesList && pastCoursesList.length > 0 ? (
          <Box className="custom-pagination">
            <Box className="custom-rowperpage">
              <Typography variant="body2" component="span">
                Page:
              </Typography>
              <Select
                id="pagination-select"
                value={page}
                onChange={handleChange}
                MenuProps={{
                  className: "pagination-menu",
                }}
              >
                {PAGE_SIZES?.map((pageSize) => (
                  <MenuItem
                    key={pageSize.value}
                    value={pageSize.value}
                    selected={pageSize?.selected}
                  >
                    {pageSize.label}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" component="span">
                {getPaginationDetailText(pastCoursesListMeta, pageNo, page)}
              </Typography>{" "}
            </Box>
            <Pagination
              count={pastCoursesListMeta?.totalPages}
              variant="outlined"
              shape="rounded"
              page={pageNo}
              onChange={onPageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon,
                  }}
                  {...item}
                />
              )}
            />
          </Box>
        ) : (
          <NoRecords />
        )}
      </Card>
    </Grid>
  );
};

export default PastCoursesList;
