import React, { useEffect, useState } from "react";
import {
  IStudentProfileContainerDispatch,
  IStudentProfileContainerState,
  IStudentProfileDetailsData,
} from "utils/interfaces/student-profile";
import {
  EmailPattern,
  convertToDateTime,
  getPagePermissions,
  onNumberFieldKeyPress,
  showLoader,
} from "utils/helper";
import { calendarIcon, saveIcon } from "assets/images";
import {
  Card,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  ALPHABETIC_VALIDATION_MSG,
  WHITE_SPACE_ERROR_SPACE,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER,
  STUDENT_PROFILE_UPDATE_SUCCESS_MESSAGE,
  STUDENT_DETAIL_EDIT_SUCCESS_MESSAGE,
} from "utils/constants/Messages";
import {
  ALPHABETIC_INPUT_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
  DATE_PICKER_FORMAT,
  MAX_INT_VALUE,
} from "utils/constants/constant";
import {
  STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH,
  STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH,
  STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH,
  STUDENT_ENROLMENT_EMAIL_MAX_LENGTH,
  STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH,
  STUDENT_ENROLMENT_SUBURB_MAX_LENGTH,
  STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH,
  STUDENT_ENROLMENT_STATE_MAX_LENGTH,
  MINIMUM_STUDENT_AGE,
  STUDENT_EMERGENCY_CONTACT_NAME_MAX_LENGTH,
  STUDENT_EMERGENCY_CONTACT_RELATIONSHIP_MAX_LENGTH,
  STUDENT_EMERGENCY_CONTACT_NUMBER_MAX_LENGTH,
  STUDENT_ENROLMENT_COMMENT_MAX_LENGTH,
} from "utils/constants/student-enrolment";
import { AppPages } from "utils/enums/app-pages";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { updateUser } from "store/auth/actions";
import { changeConfirmationPreferenceRequest } from "store/student/action";
import {
  EditStudentProfileDetailsSuccessPayload,
  StudentProfileDetailsSuccessPayload,
} from "store/student/types";
import { UpdateUserPayload } from "store/auth/types";

export type StudentProfileProps = IStudentProfileContainerState &
  IStudentProfileContainerDispatch;

function CalendarIcon() {
  return (
    <div className="calendar-icon">
      <img src={calendarIcon} alt="calendar" />
    </div>
  );
}
interface IStudentData {
  id?: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  streetAddress: string;
  suburb: string;
  postCode: number;
  state: string;
  dateOfBirth: any;
  enrollmentConfirmationPreference: string;
  emergencyContactName: string;
  emergencyContactRelationship: string;
  emergencyContactNumber: string;
  information: string;
}
const StudentProfile: React.FC<StudentProfileProps> = ({
  studentProfileDetailsRequest,
  editStudentProfileDetailRequest,
}) => {
  const location = useLocation<{ studentId: number }>();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);
  const dispatch = useDispatch();
  const [studentData, setStudentData] = useState<IStudentProfileDetailsData>();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<IStudentData>({
    defaultValues: {
      enrollmentConfirmationPreference: "Email",
    },
  });

  const getFirstNameError = (): string => {
    if (errors.firstName) {
      if (errors.firstName.type === "required") {
        return "First Name is required";
      }
      if (errors.firstName.type === "maxLength") {
        return `Maximum length of first name should be ${STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH}`;
      }
      if (errors.firstName.type === "pattern") {
        return `First name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getLastNameError = (): string => {
    if (errors.lastName) {
      if (errors.lastName.type === "required") {
        return "Last Name is required";
      }
      if (errors.lastName.type === "maxLength") {
        return `Maximum length of last name should be ${STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH}`;
      }
      if (errors.lastName.type === "pattern") {
        return `Last name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getPhoneNumberError = (): string => {
    if (errors.phoneNumber) {
      if (errors.phoneNumber.type === "required") {
        return "Phone number is required";
      }
      if (errors.phoneNumber.type === "maxLength") {
        return `Maximum length of phone number should be ${STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getEmailAddressError = (): string => {
    if (errors.emailAddress) {
      if (errors.emailAddress.type === "required") {
        return "Email Address is required";
      }
      if (errors.emailAddress.type === "pattern") {
        return "Enter valid email";
      }
      if (errors.emailAddress.type === "maxLength") {
        return `Maximum length of email should be ${STUDENT_ENROLMENT_EMAIL_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getStreetAddressError = (): string => {
    if (errors.streetAddress) {
      if (errors.streetAddress.type === "required") {
        return "Street Address is required";
      }
      if (errors.streetAddress.type === "maxLength") {
        return `Maximum length of Street Address should be ${STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH}`;
      }
      if (errors.streetAddress.type === "pattern") {
        return `Street address ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getSuburbError = (): string => {
    if (errors.suburb) {
      if (errors.suburb.type === "required") {
        return "Suburb is required";
      }
      if (errors.suburb.type === "maxLength") {
        return `Maximum length of suburb should be ${STUDENT_ENROLMENT_SUBURB_MAX_LENGTH}`;
      }
      if (errors.suburb.type === "pattern") {
        return `Suburb ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getPostCodeError = (): string => {
    if (errors.postCode) {
      if (errors.postCode.type === "required") {
        return "Post Code is required";
      }
      if (errors.postCode.type === "maxLength") {
        return `Maximum length of post code should be ${STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH}`;
      }
      if (errors.postCode.type === "max") {
        return `Max value of Post Code can be '${MAX_INT_VALUE}'`;
      }
    }

    return "";
  };

  const getStateError = (): string => {
    if (errors.state) {
      if (errors.state.type === "required") {
        return "state is required";
      }
      if (errors.state.type === "maxLength") {
        return `Maximum length of state should be ${STUDENT_ENROLMENT_STATE_MAX_LENGTH}`;
      }
      if (errors.state.type === "pattern") {
        return `State ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getDateOfBirthError = (): string => {
    if (errors.dateOfBirth) {
      if (errors.dateOfBirth.type === "required") {
        return "Date of birth is required";
      }
      if (errors.dateOfBirth.type === "validate") {
        return `Minimum 'Age' should be ${MINIMUM_STUDENT_AGE}yrs`;
      }
    }

    return "";
  };

  const getEmergencyContactNameError = (): string => {
    if (errors.emergencyContactName) {
      if (errors.emergencyContactName.type === "required") {
        return "Emergency Contact Name is required";
      }
      if (errors.emergencyContactName.type === "maxLength") {
        return `Maximum length of name should be ${STUDENT_EMERGENCY_CONTACT_NAME_MAX_LENGTH}`;
      }
      if (errors.emergencyContactName.type === "pattern") {
        return `Name of contact person ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getEmergencyContactRelationshipError = (): string => {
    if (errors.emergencyContactRelationship) {
      if (errors.emergencyContactRelationship.type === "required") {
        return "Emergency Contact relation is required";
      }
      if (errors.emergencyContactRelationship.type === "maxLength") {
        return `Maximum length of relationship should be ${STUDENT_EMERGENCY_CONTACT_RELATIONSHIP_MAX_LENGTH}`;
      }
      if (errors.emergencyContactRelationship.type === "pattern") {
        return `Relationship ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER}`;
      }
    }

    return "";
  };

  const getEmergencyContactNumberError = (): string => {
    if (errors.emergencyContactNumber) {
      if (errors.emergencyContactNumber.type === "required") {
        return "Emergency Contact Number is required";
      }
      if (errors.emergencyContactNumber.type === "maxLength") {
        return `Maximum length of contact number should be ${STUDENT_EMERGENCY_CONTACT_NUMBER_MAX_LENGTH}`;
      }
    }

    return "";
  };

  const getInformationToHelpError = (): string => {
    if (errors.information) {
      if (errors.information.type === "maxLength") {
        return `Maximum length of information should be ${STUDENT_ENROLMENT_COMMENT_MAX_LENGTH}`;
      }
      if (errors.information.type === "pattern") {
        return `Information ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const getFormattedDate = (date: string) => {
    const stringDate = new Date(date?.toString());
    const formattedDate = convertToDateTime(stringDate);

    return formattedDate;
  };

  const setStudentFormValues = (studentDetails: IStudentProfileDetailsData) => {
    reset((formValues: IStudentData) => ({
      ...formValues,
      firstName: studentDetails.firstName,
      lastName: studentDetails.lastName,
      phoneNumber: studentDetails.phoneNumber,
      emailAddress: studentDetails.emailAddress,
      streetAddress: studentDetails.streetAddress,
      suburb: studentDetails.suburb,
      postCode: studentDetails.postCode,
      state: studentDetails.state,
      dateOfBirth: dayjs(studentDetails.dateOfBirth),
      enrollmentConfirmationPreference:
        studentDetails.studentProfileEnrolmentDTO
          .enrollmentConfirmationPreference,
      emergencyContactName:
        studentDetails.studentProfileEnrolmentDTO.emergencyContactName,
      emergencyContactNumber:
        studentDetails.studentProfileEnrolmentDTO.emergencyContactNumber,
      emergencyContactRelationship:
        studentDetails.studentProfileEnrolmentDTO.emergencyContactRelationship,
      information: studentDetails.studentProfileEnrolmentDTO.information,
    }));
  };

  const onStudentDataSuccess = (
    response: StudentProfileDetailsSuccessPayload
  ) => {
    setStudentFormValues(response.studentDetails);
    setStudentData(response.studentDetails);
  };

  const getStudentData = () => {
    if (studentProfileDetailsRequest) {
      showLoader();
      const { studentId } = location.state;

      const payload = {
        values: { id: studentId },
        callback: onStudentDataSuccess,
      };

      studentProfileDetailsRequest(payload);
    }
  };

  const handleCancelClick = () => {
    setStudentFormValues(studentData as IStudentProfileDetailsData);
    if (user?.studentId) {
      history.goBack();
    }
  };
  const isConfirmationPreferenceChange = useSelector(
    (state: any) => state?.student?.isConfirmationPreferenceChange
  );

  useEffect(() => {
    let permission;
    if (user?.studentId === null || user?.studentId === undefined) {
      permission = getPagePermissions(
        user?.permissions,
        AppPages.Student,
        "administrator"
      );
    } else {
      permission = getPagePermissions(
        user?.permissions,
        AppPages.StudentDashboard,
        "student"
      );
    }
    if (location?.state.studentId && permission.edit) {
      getStudentData();
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let permission;
    if (!user?.studentId) {
      permission = getPagePermissions(
        user?.permissions,
        AppPages.Student,
        "administrator"
      );
    } else {
      permission = getPagePermissions(
        user?.permissions,
        AppPages.StudentDashboard,
        "student"
      );
    }
    if (location?.state.studentId && permission.edit) {
      getStudentData();
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmationPreferenceChange]);

  const onEditStudentDetailSuccess = (
    response: EditStudentProfileDetailsSuccessPayload
  ) => {
    if (response.success) {
      dispatch(
        changeConfirmationPreferenceRequest({
          isConfirmationPreferenceChange: !isConfirmationPreferenceChange,
        })
      );
      toast.success(
        user?.studentId
          ? STUDENT_PROFILE_UPDATE_SUCCESS_MESSAGE
          : STUDENT_DETAIL_EDIT_SUCCESS_MESSAGE
      );
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const handleUpdateUser = (payloads: UpdateUserPayload) => {
    dispatch(updateUser(payloads));
  };

  const onSubmit = async (data: IStudentData) => {
    if (location.state.studentId) {
      if (editStudentProfileDetailRequest) {
        showLoader();
        const payload = {
          values: {
            id: location.state.studentId,
            firstName: data.firstName,
            lastName: data.lastName,
            emailAddress: data.emailAddress,
            dateOfBirth: getFormattedDate(data.dateOfBirth),
            phoneNumber: data.phoneNumber,
            streetAddress: data.streetAddress,
            suburb: data.suburb,
            state: data.state,
            postCode: data.postCode,
            studentProfileEnrolment: {
              emergencyContactName: data.emergencyContactName,
              emergencyContactRelationship: data.emergencyContactRelationship,
              emergencyContactNumber: data.emergencyContactNumber,
              information: data.information,
              enrollmentConfirmationPreference:
                data.enrollmentConfirmationPreference,
            },
          },
          callback: onEditStudentDetailSuccess,
        };
        editStudentProfileDetailRequest(payload);
        if (user?.studentId) {
          const updateUserPayload = {
            values: {
              firstName: payload.values.firstName,
              lastName: payload.values.lastName,
              address: payload.values.emailAddress,
            },
            callback: payload.callback,
          };
          handleUpdateUser(updateUserPayload);
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className="std-details-card">
        <Typography variant="h3" className="attnd-heading">
          {user?.studentId ? "Student Profile" : "Student Details"}
        </Typography>
        <Grid container columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="first-name1"
              label={
                <>
                  First Name <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("firstName")),
              }}
              error={!!errors.firstName}
              helperText={getFirstNameError()}
              {...register("firstName", {
                required: true,
                maxLength: STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH,
                pattern: ALPHABETIC_INPUT_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="last-name1"
              label={
                <>
                  Last Name <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("lastName")),
              }}
              helperText={getLastNameError()}
              error={!!errors.lastName}
              {...register("lastName", {
                required: true,
                maxLength: STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH,
                pattern: ALPHABETIC_INPUT_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="phone"
              label={
                <>
                  Phone <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              type="number"
              InputLabelProps={{
                shrink: Boolean(watch("phoneNumber")),
              }}
              helperText={getPhoneNumberError()}
              error={!!errors.phoneNumber}
              {...register("phoneNumber", {
                required: true,
                maxLength: STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH,
              })}
              onKeyPress={onNumberFieldKeyPress}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="email"
              disabled
              label={
                <>
                  Email <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emailAddress")),
              }}
              helperText={getEmailAddressError()}
              error={!!errors.emailAddress}
              {...register("emailAddress", {
                required: true,
                pattern: EmailPattern,
                maxLength: STUDENT_ENROLMENT_EMAIL_MAX_LENGTH,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="strt-add"
              label={
                <>
                  Street Address <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("streetAddress")),
              }}
              helperText={getStreetAddressError()}
              error={!!errors.streetAddress}
              {...register("streetAddress", {
                required: true,
                maxLength: STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="suburd"
              label={
                <>
                  Suburb <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("suburb")),
              }}
              helperText={getSuburbError()}
              error={!!errors.suburb}
              {...register("suburb", {
                required: true,
                maxLength: STUDENT_ENROLMENT_SUBURB_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="post-code"
              label={
                <>
                  Post Code <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              type="number"
              InputLabelProps={{
                shrink: Boolean(watch("postCode")),
              }}
              helperText={getPostCodeError()}
              error={!!errors.postCode}
              {...register("postCode", {
                required: true,
                maxLength: STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH,
                max: 2147483647,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="state"
              label={
                <>
                  State <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("state")),
              }}
              helperText={getStateError()}
              error={!!errors.state}
              {...register("state", {
                required: true,
                maxLength: STUDENT_ENROLMENT_STATE_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <Controller
                  control={control}
                  {...register("dateOfBirth", {
                    required: true,
                    validate: (value) => {
                      const selectedDate = dayjs(value);
                      const minDate = dayjs().subtract(
                        MINIMUM_STUDENT_AGE,
                        "year"
                      );

                      return !selectedDate.isAfter(minDate);
                    },
                  })}
                  render={({ field }) => (
                    <DesktopDatePicker
                      {...field}
                      className="date-picker"
                      format={DATE_PICKER_FORMAT}
                      label={
                        <>
                          Date Of Birth <span className="color-red">*</span>
                        </>
                      }
                      value={
                        watch("dateOfBirth")
                          ? watch("dateOfBirth")
                          : dayjs(new Date())
                      }
                      disableFuture
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.dateOfBirth,
                          helperText: getDateOfBirthError(),
                        },
                      }}
                    />
                  )}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <FormControl fullWidth className="select">
              <InputLabel id="enol-con-pref-label">
                Contact Method <span className="color-red">*</span>
              </InputLabel>
              <Select
                labelId="enol-con-pref-label"
                id="contact-method"
                label={
                  <>
                    Contact Method <span className="color-red">*</span>
                  </>
                }
                required
                className="std-details-input"
                value={watch("enrollmentConfirmationPreference")}
                {...register("enrollmentConfirmationPreference")}
              >
                <MenuItem key="Email" value="Email">
                  Email
                </MenuItem>
                <MenuItem key="SMS" value="SMS">
                  SMS
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="emrg-ctnt-name"
              label={
                <>
                  Emergency Contact Name <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emergencyContactName")),
              }}
              helperText={getEmergencyContactNameError()}
              error={!!errors.emergencyContactName}
              {...register("emergencyContactName", {
                required: true,
                maxLength: STUDENT_EMERGENCY_CONTACT_NAME_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="emrg-ctnt-rel"
              label={
                <>
                  Emergency Contact Relationship{" "}
                  <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: Boolean(watch("emergencyContactRelationship")),
              }}
              helperText={getEmergencyContactRelationshipError()}
              error={!!errors.emergencyContactRelationship}
              {...register("emergencyContactRelationship", {
                required: true,
                maxLength: STUDENT_EMERGENCY_CONTACT_RELATIONSHIP_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_AND_NUMBER_REGEX,
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <TextField
              id="emrg-ctnt-ph"
              label={
                <>
                  Emergency Contact Phone <span className="color-red">*</span>
                </>
              }
              fullWidth
              variant="outlined"
              onKeyPress={onNumberFieldKeyPress}
              InputLabelProps={{
                shrink: Boolean(watch("emergencyContactNumber")),
              }}
              helperText={getEmergencyContactNumberError()}
              error={!!errors.emergencyContactNumber}
              {...register("emergencyContactNumber", {
                required: true,
                maxLength: STUDENT_EMERGENCY_CONTACT_NUMBER_MAX_LENGTH,
              })}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="support-need"
              className="skills-textarea"
              label="Supports Required/Specific Learning Needs"
              fullWidth
              variant="outlined"
              multiline
              InputLabelProps={{
                shrink: Boolean(watch("information")),
              }}
              helperText={getInformationToHelpError()}
              error={!!errors.information}
              {...register("information", {
                maxLength: STUDENT_ENROLMENT_COMMENT_MAX_LENGTH,
                pattern:
                  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
              })}
            />
          </Grid>
        </Grid>
        <Box className="btn-group">
          <Button variant="contained" className="btn-save" type="submit">
            <img src={saveIcon} alt="save" />
            Save
          </Button>
          <Button
            variant="outlined"
            className="btn-cancel"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default StudentProfile;
