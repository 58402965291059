import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COURSE_REFLECTION_FORM_REQUEST,
  CURRENT_COURSE_LIST_REQUEST,
  EDIT_COURSE_REFLECTION_FORM_REQUEST,
  ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_REQUEST,
  MISSING_ATTENDANCE_COURSE_LIST_REQUEST,
  PAST_COURSE_LIST_REQUEST,
  SCHEDULED_OFFERING_DETAILS_REQUEST,
} from "./actionTypes";
import educatorHomeService from "./service";
import {
  AddEditCourseReflectionFormResponse,
  CurrentCourseListResponse,
  EnroledStudentListForEducatorHomeResponse,
  MissingAttendanceCourseListResponse,
  PastCourseListResponse,
  ScheduledOfferingDetailsResponse,
} from "./types";
import {
  addCourseReflectionFormFailure,
  addCourseReflectionFormSuccess,
  currentCourseListFailure,
  currentCourseListSuccess,
  editCourseReflectionFormFailure,
  editCourseReflectionFormSuccess,
  enroledStudentListForEducatorHomeFailure,
  enroledStudentListForEducatorHomeSuccess,
  missingAttendanceCourseListFailure,
  missingAttendanceCourseListSuccess,
  pastCourseListFailure,
  pastCourseListSuccess,
  scheduledOfferingDetailsFailure,
  scheduledOfferingDetailsSuccess,
} from "./actions";

function* currentCourseListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };
    if (action.payload.values.course) {
      payload.course = action.payload.values.course;
    }

    if (action.payload.values.termId) {
      payload.termId = action.payload.values.termId;
    }

    const response: CurrentCourseListResponse = yield call(
      educatorHomeService.getCurrentCourseList,
      payload
    );

    yield put(
      currentCourseListSuccess({
        currentCourseList: response.item.records,
      })
    );
    action.payload.callback({
      currentCourseList: response.item.records,
    });
  } catch (e: any) {
    yield put(
      currentCourseListFailure({
        error: e.message,
      })
    );
  }
}

function* pastCourseListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };

    const response: PastCourseListResponse = yield call(
      educatorHomeService.getPastCourseList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      pastCourseListSuccess({
        pastCourseList: response.item.records,
        pastCourseListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      pastCourseList: response.item.records,
      pastCourseListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      pastCourseListFailure({
        error: e.message,
      })
    );
  }
}

function* scheduledOfferingDetailsSaga(action: any) {
  try {
    const response: ScheduledOfferingDetailsResponse = yield call(
      educatorHomeService.getScheduledOfferingDetails,
      {
        id: action.payload.values.id,
        userId: action.payload.values.userId,
      }
    );

    yield put(
      scheduledOfferingDetailsSuccess({
        scheduledOfferingDetails: response.item,
      })
    );
    action.payload.callback({
      scheduledOfferingDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      scheduledOfferingDetailsFailure({
        error: e.message,
      })
    );
  }
}

function* addCourseReflectionFormSaga(action: any) {
  try {
    const response: AddEditCourseReflectionFormResponse = yield call(
      educatorHomeService.addCourseReflectionForm,
      {
        educatorId: action.payload.values.educatorId,
        courseOfferingID: action.payload.values.courseOfferingID,
        formFillingDate: action.payload.values.formFillingDate,
        whatWentWell: action.payload.values.whatWentWell,
        howCanWeContinueToDoThis:
          action.payload.values.howCanWeContinueToDoThis,
        whatDidNotGoSoWell: action.payload.values.whatDidNotGoSoWell,
        whatIdeasDoYouHaveToImproveThis:
          action.payload.values.whatIdeasDoYouHaveToImproveThis,
      }
    );

    yield put(
      addCourseReflectionFormSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addCourseReflectionFormFailure({
        error: e.message,
      })
    );
  }
}

function* editCourseReflectionFormSaga(action: any) {
  try {
    const response: AddEditCourseReflectionFormResponse = yield call(
      educatorHomeService.editCourseReflectionForm,
      {
        id: action.payload.values.id,
        educatorId: action.payload.values.educatorId,
        courseOfferingID: action.payload.values.courseOfferingID,
        formFillingDate: action.payload.values.formFillingDate,
        whatWentWell: action.payload.values.whatWentWell,
        howCanWeContinueToDoThis:
          action.payload.values.howCanWeContinueToDoThis,
        whatDidNotGoSoWell: action.payload.values.whatDidNotGoSoWell,
        whatIdeasDoYouHaveToImproveThis:
          action.payload.values.whatIdeasDoYouHaveToImproveThis,
      }
    );

    yield put(
      editCourseReflectionFormSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editCourseReflectionFormFailure({
        error: e.message,
      })
    );
  }
}

function* enroledStudentForEducatorHomeSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };

    const response: EnroledStudentListForEducatorHomeResponse = yield call(
      educatorHomeService.getEnroledStudentForEducatorHome,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      enroledStudentListForEducatorHomeSuccess({
        enroledStudentList: response.item.records,
        enroledStudentListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      enroledStudentList: response.item.records,
      enroledStudentListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      enroledStudentListForEducatorHomeFailure({
        error: e.message,
      })
    );
  }
}

function* missingAttendanceCourseListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };

    const response: MissingAttendanceCourseListResponse = yield call(
      educatorHomeService.getMissingAttendanceCourseList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      missingAttendanceCourseListSuccess({
        missingAttendanceCourseList: response.item.records,
        missingAttendanceCourseListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      missingAttendanceCourseList: response.item.records,
      missingAttendanceCourseListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      missingAttendanceCourseListFailure({
        error: e.message,
      })
    );
  }
}

function* educatorHomeSaga() {
  yield all([takeLatest(CURRENT_COURSE_LIST_REQUEST, currentCourseListSaga)]);
  yield all([takeLatest(PAST_COURSE_LIST_REQUEST, pastCourseListSaga)]);
  yield all([
    takeLatest(
      SCHEDULED_OFFERING_DETAILS_REQUEST,
      scheduledOfferingDetailsSaga
    ),
  ]);
  yield all([
    takeLatest(ADD_COURSE_REFLECTION_FORM_REQUEST, addCourseReflectionFormSaga),
  ]);
  yield all([
    takeLatest(
      EDIT_COURSE_REFLECTION_FORM_REQUEST,
      editCourseReflectionFormSaga
    ),
  ]);
  yield all([
    takeLatest(
      ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_REQUEST,
      enroledStudentForEducatorHomeSaga
    ),
  ]);
  yield all([
    takeLatest(
      MISSING_ATTENDANCE_COURSE_LIST_REQUEST,
      missingAttendanceCourseListSaga
    ),
  ]);
}

export default educatorHomeSaga;
