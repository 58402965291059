import { Box } from "@mui/system";
import {
  Modal,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  FormHelperText,
  Fade,
  Tooltip,
} from "@mui/material";
import { closeIcon, saveIcon } from "assets/images";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  CLOSE_TOOLTIP_TITLE,
  DATE_PICKER_FORMAT,
  DATE_VIEW_FORMAT,
  SCO_REPEAT,
  SCO_REPEAT_END,
} from "utils/constants/constant";
import {
  checkEndDateLessThanStart,
  convertToDateTimeWithFormat,
  handlePasteNumberField,
  onNumberFieldKeyPress,
} from "utils/helper";
import { Controller, useForm } from "react-hook-form";
import { IRepeat } from "utils/interfaces/scheduled-offering";
import { useEffect, useState } from "react";
import { SCORepeat, SCORepeatEnd } from "utils/enums/scheduled-offering";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import CalendarIcon from "components/CalendarIcon";
import dayjs from "dayjs";

interface IRepeatModalProps {
  isOpenRepeatModal: boolean;
  handleRepeatModalClose: any;
  handleSaveRepeatClick: any;
  startDate: any;
  repeatCourseOffering?: IRepeat;
  pickerMinDate: any;
  pickerMaxDate: any;
}

const RepeatModal: React.FC<IRepeatModalProps> = ({
  isOpenRepeatModal,
  handleRepeatModalClose,
  handleSaveRepeatClick,
  startDate,
  repeatCourseOffering,
  pickerMinDate,
  pickerMaxDate,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<IRepeat>({
    defaultValues: {
      repeatType: 2,
      endType: 0,
    },
  });
  const [repeatOnError, setRepeatOnError] = useState<string>("");

  enum SCO_REPEAT_ENUM {
    Daily = 1,
    Weekly = 2,
  }

  enum SCO_REPEAT_END_ENUM {
    After = 1,
    On = 3,
  }
  useEffect(() => {
    if (repeatCourseOffering && isOpenRepeatModal) {
      if (repeatCourseOffering?.repeatDays?.length > 0) {
        repeatCourseOffering.repeatDays += ",";
      }
      setValue(
        "repeatType",
        repeatCourseOffering.repeatType || SCO_REPEAT_ENUM.Weekly
      );
      setValue("occurenceStart", repeatCourseOffering.occurenceStart);
      setValue(
        "endType",
        repeatCourseOffering.endType || SCO_REPEAT_END_ENUM.After
      );
      setValue(
        "endOn",
        repeatCourseOffering.endOn && dayjs(repeatCourseOffering.endOn)
      );
      setValue("occurenceEnd", repeatCourseOffering.occurenceEnd);
      setValue("repeatDays", repeatCourseOffering.repeatDays);
      setRepeatOnError("");
    } else if (isOpenRepeatModal) {
      const defaultValue = {
        repeatType: 2,
        endType: 1,
      };
      reset(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeatCourseOffering, isOpenRepeatModal]);

  const onChangeCheckBox = (e: any, dayName: string) => {
    let dayNames = watch("repeatDays") || "";
    if (e.target.checked) {
      dayNames = dayNames.concat(",", dayName);
    } else {
      dayNames = dayNames.replace(/,$/, "");
      dayNames = dayNames.replace(`${dayName}`, "");
    }
    dayNames = dayNames.replace(/,{2,}/g, ",");
    dayNames = dayNames.replace(/^,+/, "");
    setValue("repeatDays", dayNames.trim());
    if (dayNames) {
      setRepeatOnError("");
    } else {
      setRepeatOnError("Select minimum one day");
    }
  };

  const getCheckBoxValue = (dayName: string) => {
    const repeatOnValue = watch("repeatDays");

    return repeatOnValue?.includes(dayName);
  };

  const getEveryError = (): string => {
    if (errors.occurenceStart) {
      if (errors.occurenceStart.type === "required") {
        return "Every is required";
      }
    }

    return "";
  };

  const getAfterOccurenceError = (): string => {
    if (errors.occurenceEnd) {
      if (errors.occurenceEnd.type === "required") {
        return "Occurence is required";
      }
    }

    return "";
  };

  const getEndDateError = (): string => {
    if (errors.endOn) {
      if (errors.endOn.type === "required") {
        return "Date is required";
      }
      if (errors.endOn.type === "validate") {
        return "End Date should be greater than Start Date";
      }
    }

    return "";
  };

  const saveRepeatClick = (data: IRepeat) => {
    if (watch("repeatType") === SCORepeat.Weekly && !watch("repeatDays")) {
      setRepeatOnError("Select minimum one day");
    } else {
      setRepeatOnError("");
      handleSaveRepeatClick(data);
    }
  };

  return (
    <Box className="child-modal-container">
      <Modal open={isOpenRepeatModal} onClose={handleRepeatModalClose}>
        <form onSubmit={handleSubmit(saveRepeatClick)}>
          <Box className="common-modal repeat-modal">
            <Box className="modal-header">
              <Typography variant="h4">Edit Repeat</Typography>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={CLOSE_TOOLTIP_TITLE}
                arrow
              >
                <IconButton onClick={handleRepeatModalClose}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="modal-body">
              <Grid
                container
                columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="repeat-label">
                      Repeat <span className="color-red">*</span>
                    </InputLabel>
                    <Select
                      labelId="repeat-label"
                      id="repeat"
                      label="Repeat "
                      value={watch("repeatType")}
                      {...register("repeatType")}
                    >
                      {SCO_REPEAT?.map((rep) => (
                        <MenuItem key={rep.value} value={rep.value}>
                          {rep.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <div className="week-repeat-div">
                    <TextField
                      type="number"
                      id="every"
                      label={
                        <>
                          Every <span className="color-red">*</span>
                        </>
                      }
                      fullWidth
                      variant="outlined"
                      onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                      onPaste={handlePasteNumberField}
                      error={!!errors.occurenceStart}
                      helperText={getEveryError()}
                      {...register("occurenceStart", {
                        required: true,
                      })}
                      value={watch("occurenceStart") || ""}
                    />
                    <Typography variant="body1" className="week-repeat-text">
                      {watch("repeatType") === SCORepeat.Daily
                        ? "Days"
                        : "Weeks"}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              {watch("repeatType") === SCORepeat.Weekly && (
                <>
                  <Typography variant="body1" className="date-label">
                    Repeat On <span className="color-red">*</span>
                  </Typography>
                  <Box className="checkbox-group">
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedBoxIcon />}
                          checked={getCheckBoxValue("Sun")}
                          onChange={(e) => onChangeCheckBox(e, "Sun")}
                        />
                      }
                      label="Sun"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedBoxIcon />}
                          checked={getCheckBoxValue("Mon")}
                          onChange={(e) => onChangeCheckBox(e, "Mon")}
                        />
                      }
                      label="Mon"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedBoxIcon />}
                          checked={getCheckBoxValue("Tue")}
                          onChange={(e) => onChangeCheckBox(e, "Tue")}
                        />
                      }
                      label="Tue"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedBoxIcon />}
                          checked={getCheckBoxValue("Wed")}
                          onChange={(e) => onChangeCheckBox(e, "Wed")}
                        />
                      }
                      label="Wed"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedBoxIcon />}
                          checked={getCheckBoxValue("Thu")}
                          onChange={(e) => onChangeCheckBox(e, "Thu")}
                        />
                      }
                      label="Thu"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedBoxIcon />}
                          checked={getCheckBoxValue("Fri")}
                          onChange={(e) => onChangeCheckBox(e, "Fri")}
                        />
                      }
                      label="Fri"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedBoxIcon />}
                          checked={getCheckBoxValue("Sat")}
                          onChange={(e) => onChangeCheckBox(e, "Sat")}
                        />
                      }
                      label="Sat"
                      labelPlacement="end"
                    />
                  </Box>
                  {repeatOnError && (
                    <FormHelperText error className="repeat-on-error-text">
                      {repeatOnError}
                    </FormHelperText>
                  )}
                </>
              )}
              <Grid container rowSpacing="14px">
                <Grid item xs={12}>
                  <Grid
                    container
                    columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                    className="repeat-time-labels"
                  >
                    <Grid item xs={3} sm="auto">
                      <Typography
                        variant="body1"
                        className="date-label"
                        sx={{ marginBottom: 0 }}
                      >
                        Starts On
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sm>
                      <Typography variant="body1">
                        {convertToDateTimeWithFormat(
                          startDate,
                          DATE_VIEW_FORMAT
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                    className="repeat-time-labels repeat-end-labels"
                    alignItems={{ sm: "center" }}
                  >
                    <Grid item xs={3} sm="auto">
                      <Typography
                        variant="body1"
                        className="date-label ends-label"
                        sx={{ marginBottom: 0 }}
                      >
                        Ends
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sm>
                      <Box className="end-datetime">
                        <FormControl fullWidth className="select">
                          <Select
                            labelId="end-label"
                            id="end"
                            value={watch("endType")}
                            {...register("endType")}
                          >
                            {SCO_REPEAT_END?.map((repeatEnd) => (
                              <MenuItem
                                key={repeatEnd.value}
                                value={repeatEnd.value}
                              >
                                {repeatEnd.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {watch("endType") === SCORepeatEnd.On && (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopDatePicker"]}>
                              <Controller
                                control={control}
                                {...register("endOn", {
                                  required:
                                    watch("endType") === SCORepeatEnd.On,
                                  validate: (value) =>
                                    watch("endType") === SCORepeatEnd.On &&
                                    !checkEndDateLessThanStart(
                                      startDate,
                                      value
                                    ),
                                })}
                                render={({ field }) => (
                                  <DesktopDatePicker
                                    {...field}
                                    className="date-picker disabled-picker-text-field"
                                    label={
                                      <>
                                        Date
                                        <span className="color-red">*</span>
                                      </>
                                    }
                                    format={DATE_PICKER_FORMAT}
                                    minDate={pickerMinDate}
                                    maxDate={pickerMaxDate}
                                    slots={{
                                      openPickerIcon: CalendarIcon,
                                    }}
                                    slotProps={{
                                      textField: {
                                        disabled: true,
                                        error: !!errors.endOn,
                                        helperText: getEndDateError(),
                                      },
                                    }}
                                  />
                                )}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        )}
                        {watch("endType") === SCORepeatEnd.After && (
                          <div className="occurence-div">
                            <TextField
                              type="number"
                              id="occurence"
                              label={
                                <>
                                  Occurence <span className="color-red">*</span>
                                </>
                              }
                              fullWidth
                              variant="outlined"
                              onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                              onPaste={handlePasteNumberField}
                              error={!!errors.occurenceEnd}
                              helperText={getAfterOccurenceError()}
                              {...register("occurenceEnd", {
                                required:
                                  watch("endType") === SCORepeatEnd.After,
                              })}
                            />
                          </div>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box className="modal-footer">
              <Button variant="contained" className="btn-save" type="submit">
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={handleRepeatModalClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </Box>
  );
};

export default RepeatModal;
