/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import {
  Button,
  Fade,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { closeIcon, saveIcon } from "assets/images";
import { useForm } from "react-hook-form";
import {
  CLOSE_TOOLTIP_TITLE,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  MAX_SIZE_IN_BYTES,
} from "utils/constants/constant";
import { useDropzone } from "react-dropzone";
import { onTextFieldKeyPress } from "utils/helper";
import { toast } from "react-toastify";
import {
  FILE_SIZE_EXCEEDED,
  ONLY_ONE_DOCUMENT_IS_ALLOWED_TO_UPLOAD,
  CERTIFICATE_SETTINGS_CHANGED_SUCCESSFULLY,
  WHITE_SPACE_ERROR_SPACE,
  ONLY_PNG_ACCEPTED,
} from "utils/constants/Messages";
import { IManagerSettings } from "utils/interfaces/manager-setting";
import { changeManagerSettingRequest } from "store/profile/actions";
import { ChangeManagerSettingSuccessPayload } from "store/profile/types";
import { useDispatch } from "react-redux";

interface IManagerSettingProps {
  isOpenSettingsModal: boolean;
  handleSettingClose: () => void;
}

const ManagerSettings: React.FC<IManagerSettingProps> = ({
  isOpenSettingsModal,
  handleSettingClose,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IManagerSettings>();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const [showFileName, setShowFileName] = useState<boolean>(false);
  const [showSignatureError, setShowSignatureError] = useState<boolean>(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      const isSingleDocument = acceptedFiles.length === 1;
      if (!isSingleDocument)
        toast.error(ONLY_ONE_DOCUMENT_IS_ALLOWED_TO_UPLOAD);
      const isFileNotValid = acceptedFiles[0].type !== "image/png";
      if (acceptedFiles[0]?.size > MAX_SIZE_IN_BYTES) {
        toast.error(FILE_SIZE_EXCEEDED);

        return;
      }
      if (isFileNotValid) toast.error(ONLY_PNG_ACCEPTED);
      if (!isFileNotValid && isSingleDocument) {
        setUploadedFiles(acceptedFiles);
        setValue("signature", acceptedFiles[0]);
        setShowFileName(true);
      }
    },
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (!watch("name") && errors.name) {
      setShowSignatureError(true);
    }
  }, [watch("name"), errors.name]);

  const getFileError = (): string => {
    const file = watch("signature");
    if (file === undefined) {
      return "Manager Signature is required";
    }

    return "";
  };

  const getNameError = (): string => {
    if (errors.name) {
      if (errors.name.type === "required") {
        return "Manager Name is required";
      }

      if (errors.name.type === "maxLength") {
        return `Maximum length of Manager name should be 50`;
      }

      if (errors.name.type === "pattern") {
        return `Manager Name ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }
    const file = watch("signature");
    if (file === undefined) {
      getFileError();
    }

    return "";
  };

  const onModalClose = () => {
    setUploadedFiles([]);
    setShowFileName(false);
    setShowSignatureError(false);
    handleSettingClose();
    reset();
  };

  const dispatch = useDispatch();
  const onChangeManagerSettingSuccess = async (
    response: ChangeManagerSettingSuccessPayload
  ) => {
    if (response.success) {
      onModalClose();
      setShowSignatureError(false);
      toast.success(CERTIFICATE_SETTINGS_CHANGED_SUCCESSFULLY);
    }
  };

  const handleSaveClick = (formData: IManagerSettings) => {
    if (uploadedFiles.length > 0) {
      const payload = {
        values: {
          name: formData.name,
          signature: formData.signature,
        },
        callback: onChangeManagerSettingSuccess,
      };
      dispatch(changeManagerSettingRequest(payload));
    } else {
      setShowSignatureError(true);
    }
  };

  useEffect(() => {
    if (!isOpenSettingsModal) {
      setUploadedFiles([]);
    }
  }, [watch("signature"), isOpenSettingsModal]);

  const files = uploadedFiles.map((file) => {
    return <li key={file.name.toString()}>{file?.name}</li>;
  });

  return (
    <>
      <Modal open={isOpenSettingsModal} onClose={onModalClose}>
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <Box className="common-modal documents-modal">
            <Box className="modal-header">
              <Typography variant="h2">Certificate Settings</Typography>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={CLOSE_TOOLTIP_TITLE}
                arrow
              >
                <IconButton onClick={onModalClose}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="modal-body">
              <Grid container columnSpacing="24px">
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    id="first-name"
                    label={
                      <>
                        Manager Name <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: Boolean(watch("name")),
                    }}
                    error={!!errors.name}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, watch("name")?.length)
                    }
                    helperText={getNameError()}
                    {...register("name", {
                      required: true,
                      maxLength: 50,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12} className="dropbox-container">
                <Box className="dropbox-file-upload">
                  <Box>
                    <Box {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Typography textAlign="center">
                        <p className="document-modal-text">
                          Drag & Drop Your Signature File Here
                        </p>
                      </Typography>
                      <p className="document-modal-or">OR</p>
                      <Box className="upload-document-btn-container">
                        <Button
                          className="upload-document-btn"
                          variant="outlined"
                          type="button"
                          onClick={open}
                        >
                          Browse Files
                        </Button>
                      </Box>
                    </Box>
                    {showFileName && (
                      <Box className="document-file-name">
                        <h4>File :</h4>
                        <ul> {files}</ul>
                      </Box>
                    )}
                  </Box>
                </Box>
                {showSignatureError && (
                  <FormHelperText error>{getFileError()}</FormHelperText>
                )}
              </Grid>
            </Box>
            <Box className="modal-footer">
              <Button variant="contained" className="btn-save" type="submit">
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                type="button"
                variant="outlined"
                className="btn-cancel"
                onClick={onModalClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
};

export default ManagerSettings;
