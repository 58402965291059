export const EDUCATOR_LIST_REQUEST = "EDUCATOR_LIST_REQUEST";
export const EDUCATOR_LIST_SUCCESS = "EDUCATOR_LIST_SUCCESS";
export const EDUCATOR_LIST_FAILURE = "EDUCATOR_LIST_FAILURE";

export const ADD_EDUCATOR_REQUEST = "ADD_EDUCATOR_REQUEST";
export const ADD_EDUCATOR_SUCCESS = "ADD_EDUCATOR_SUCCESS";
export const ADD_EDUCATOR_FAILURE = "ADD_EDUCATOR_FAILURE";

export const EDIT_EDUCATOR_REQUEST = "EDIT_EDUCATOR_REQUEST";
export const EDIT_EDUCATOR_SUCCESS = "EDIT_EDUCATOR_SUCCESS";
export const EDIT_EDUCATOR_FAILURE = "EDIT_EDUCATOR_FAILURE";

export const DELETE_EDUCATOR_REQUEST = "DELETE_EDUCATOR_REQUEST";
export const DELETE_EDUCATOR_SUCCESS = "DELETE_EDUCATOR_SUCCESS";
export const DELETE_EDUCATOR_FAILURE = "DELETE_EDUCATOR_FAILURE";

export const EDUCATOR_BY_ID_REQUEST = "EDUCATOR_BY_ID_REQUEST";
export const EDUCATOR_BY_ID_SUCCESS = "EDUCATOR_BY_ID_SUCCESS";
export const EDUCATOR_BY_ID_FAILURE = "EDUCATOR_BY_ID_FAILURE";

export const COURSES_DELIVERED_LIST_REQUEST = "COURSES_DELIVERED_LIST_REQUEST";
export const COURSES_DELIVERED_LIST_SUCCESS = "COURSES_DELIVERED_LIST_SUCCESS";
export const COURSES_DELIVERED_LIST_FAILURE = "COURSES_DELIVERED_LIST_FAILURE";

export const COURSES_ENROLED_LIST_REQUEST = "COURSES_ENROLED_LIST_REQUEST";
export const COURSES_ENROLED_LIST_SUCCESS = "COURSES_ENROLED_LIST_SUCCESS";
export const COURSES_ENROLED_LIST_FAILURE = "COURSES_ENROLED_LIST_FAILURE";

export const SEND_REMINDER_TO_EDUCATOR_REQUEST =
  "SEND_REMINDER_TO_EDUCATOR_REQUEST";
export const SEND_REMINDER_TO_EDUCATOR_SUCCESS =
  "SEND_REMINDER_TO_EDUCATOR_SUCCESS";
export const SEND_REMINDER_TO_EDUCATOR_FAILURE =
  "SEND_REMINDER_TO_EDUCATOR_FAILURE";

export const EDUCATOR_STATISTICS_DATA_REQUEST =
  "EDUCATOR_STATISTICS_DATA_REQUEST";
export const EDUCATOR_STATISTICS_DATA_SUCCESS =
  "EDUCATOR_STATISTICS_DATA_SUCCESS";
export const EDUCATOR_STATISTICS_DATA_FAILURE =
  "EDUCATOR_STATISTICS_DATA_FAILURE";
