import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getPagePermissions } from "utils/helper";
import { IRoleModulePermission } from "store/role/types";
import { StudentDashboardState } from "store/studentDashboard/types";
import { useSelector } from "react-redux";
import { AppPages } from "utils/enums/app-pages";

import { IStudentDashboardContainerDispatch } from "utils/interfaces/student-dashboard";
import PastCoursesList from "./PastCourses";
import UpcomingCoursesList from "./UpcomingCourses";
import CoursesOfferingList from "./CourseOffering";

export type StudentDashboardProps = StudentDashboardState &
  IStudentDashboardContainerDispatch;

const StudentDashboard: React.FC<StudentDashboardProps> = (props) => {
  const user = useSelector((state: any) => state?.auth?.user);
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.StudentDashboard,
      "student"
    );
    setPagePermission(permission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Student Dashboard
        </Typography>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <UpcomingCoursesList
            {...props}
            userId={user?.id}
            pagePermission={pagePermission}
          />
        </Grid>
        <Grid item xs={12}>
          <CoursesOfferingList
            {...props}
            userId={user?.id}
            pagePermission={pagePermission}
          />
        </Grid>
        <Grid item xs={12}>
          <PastCoursesList
            {...props}
            userId={user?.id}
            pagePermission={pagePermission}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StudentDashboard;
