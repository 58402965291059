import { connect, MapDispatchToProps } from "react-redux";
import EducatorComponent, { EducatorProps } from "pages/Educators";
import {
  addEducatorRequest,
  deleteEducatorRequest,
  editEducatorRequest,
  educatorListRequest,
  educatorByIdRequest,
  educatorStatisticsDataRequest,
} from "store/educator/actions";
import {
  IEducatorContainerDispatch,
  IEducator,
} from "utils/interfaces/educator";

const mapDispatchToProps: MapDispatchToProps<
  IEducatorContainerDispatch,
  IEducator
> = {
  educatorListRequest,
  addEducatorRequest,
  editEducatorRequest,
  deleteEducatorRequest,
  educatorByIdRequest,
  educatorStatisticsDataRequest,
};

const connector = connect(null, mapDispatchToProps);

const Educators: React.FC<EducatorProps> = (props) => {
  return <EducatorComponent {...props} />;
};

export default connector(Educators);
