import { Box } from "@mui/system";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon, confirmSubmitIcon } from "assets/images";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface IGenerateCertificateModalProps {
  handleGenerateCertificateModalClose: () => void;
  handleYesClick: () => void;
  isOpenConfirmationModal: boolean;
}

const GenerateCertificatePopUp: React.FC<IGenerateCertificateModalProps> = ({
  handleGenerateCertificateModalClose,
  handleYesClick,
  isOpenConfirmationModal,
}) => {
  const handleYes = () => {
    handleYesClick();
  };

  return (
    <>
      <Modal
        open={isOpenConfirmationModal}
        onClose={handleGenerateCertificateModalClose}
      >
        <Box className="common-modal delete-modal">
          <Box className="modal-header confirm-modal">
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleGenerateCertificateModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body">
            <Box className="confirm-modal-body">
              <img src={confirmSubmitIcon} alt="confirm submit" />
            </Box>
            <Typography
              align="center"
              className="modal-font-confirmation"
              sx={{
                font: "poppins",
                fontWeight: "500",
              }}
            >
              Are you sure?
            </Typography>
            <Typography variant="h5" align="center" sx={{ marginTop: "24px" }}>
              Do you want to generate certificate for this course?
            </Typography>
          </Box>
          <Box className="confirm-submit-footer">
            <Button variant="contained" onClick={handleYes}>
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={handleGenerateCertificateModalClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default GenerateCertificatePopUp;
