export const LOGGED_IN_SUCCESS_MSG = "Logged in successfully";
export const UPDATED_USER_PROFILE_SUCCESS_MSG =
  "Updated user profile successfully.";
export const PASSWORD_CHANGED_SUCCESS_MSG = "Password changed successfully.";
export const PASSWORD_RESET_LINK_SENT =
  "Password reset link sent successfully to your email id.";
export const PASSWORD_RESET_SUCCESS_MSG = "Password reset successfully.";
export const INVALID_URL_OR_EXPIRED = "Url is not valid or already expired.";
export const LOCATION_ADD_SUCCESS_MSG = "Location added successfully.";
export const LOCATION_EDIT_SUCCESS_MSG = "Location edited successfully.";
export const LOCATION_DELETE_SUCCESS_MSG = "Location deleted successfully.";
export const COURSE_ADD_SUCCESS_MSG = "Course added successfully.";
export const COURSE_EDIT_SUCCESS_MSG = "Course edited successfully.";
export const COURSE_DELETE_SUCCESS_MSG = "Course deleted successfully.";
export const SELECT_MIN_ONE_FIELD = "Select minimum one field";
export const TERM_ADD_SUCCESS_MSG = "Term added successfully.";
export const TERM_EDIT_SUCCESS_MSG = "Term edited successfully.";
export const TERM_DELETE_SUCCESS_MSG = "Term deleted successfully.";
export const SELECT_TERM_DATE_MSG = "Select terms start and terms end date";
export const END_DATE_SHOULD_GREATER_MSG =
  "End Date should be greater than Start Date";
export const ROLE_ADD_SUCCESS_MSG = "Role added successfully.";
export const ROLE_EDIT_SUCCESS_MSG = "Role edited successfully.";
export const ROLE_DELETE_SUCCESS_MSG = "Role deleted successfully.";
export const AT_LEAST_ONE_PERMISSION_MSG =
  "Please give at least one permission.";
export const EDUCATOR_ADD_SUCCESS_MSG = "Educator added successfully.";
export const EDUCATOR_EDIT_SUCCESS_MSG = "Educator edited successfully.";
export const EDUCATOR_DELETE_SUCCESS_MSG = "Educator deleted successfully.";
export const UPLOAD_PDF_OR_WORD_FILE = "Please upload pdf or word file.";
export const SCO_ADD_SUCCESS_MSG = "Scheduled offering added successfully.";
export const SCO_EDIT_SUCCESS_MSG = "Scheduled offering edited successfully.";
export const SCO_DELETE_SUCCESS_MSG =
  "Scheduled course offering deleted successfully.";
export const ADD_SCO_DAY_MSG = "Please add scheduled course offering day";
export const SESSION_DAY_ADD_SUCCESS_MSG = "Session day added successfully.";
export const SESSION_DAY_EDIT_SUCCESS_MSG = "Session day edited successfully.";
export const SESSION_DAY_DELETE_SUCCESS_MSG =
  "Session day deleted successfully.";
export const SESSION_DAY_CANCEL_SUCCESS_MSG =
  "Session day canceled successfully.";
export const DOC_UPLOAD_SUCCESS_MSG = "Document uploaded successfully.";
export const DOC_DELETE_SUCCESS_MSG = "Document deleted successfully.";
export const REFLECTION_FORM_ADD_SUCCESS_MSG =
  "Reflection form added successfully.";
export const REFLECTION_FORM_EDIT_SUCCESS_MSG =
  "Reflection form edited successfully.";
export const ENTER_REFLECTION_FORM_FIELD_MSG =
  "Enter at least one course reflection form field";
export const SCO_EDUCATOR_DELETE_SUCCESS_MSG = "Educator deleted successfully.";
export const SCO_EDUCATOR_ADD_SUCCESS_MSG = "Educators added successfully.";
export const USER_ADD_SUCCESS_MSG = "User added successfully.";
export const USER_EDIT_SUCCESS_MSG = "User edited successfully.";
export const USER_DELETE_SUCCESS_MSG = "User deleted successfully.";

export const STUDENT_DELETE_SUCCESS_MSG = "Student Deleted Successfully";
export const DELETE_STUDENT_CONFIRMATION =
  "Are you sure you want to delete student?";
export const STUDENT_NOTE_EDIT_SUCCESS_MESSAGE =
  "Student Note edited successfully";
export const STUDENT_DETAIL_EDIT_SUCCESS_MESSAGE =
  "Student Detail edited successfully";
export const STAFF_DETAIL_EDIT_SUCCESS_MESSAGE =
  "Staff Detail edited successfully";
export const RESEARCH_PROFILE_DETAIL_EDIT_SUCCESS_MESSAGE =
  "Research Profile edited successfully";
export const CONFIRMATION_PREFERENCE_EDIT_SUCCESS_MESSAGE =
  "Confirmation Preference edited successfully";
export const STUDENT_ENROLMENT_REJECTION_SUCCESS_MESSAGE =
  "Student rejected successfully";
export const MEDIA_CONSENT_EDIT_SUCCESS_MESSAGE =
  "Media Consent Edited Successfully";
export const DOCUMENT_ADD_SUCCESS_MSG = "Document added successfully.";
export const DOCUMENT_EDIT_SUCCESS_MSG = "Document edited successfully.";
export const DOCUMENT_DELETE_SUCCESS_MSG = "Document deleted successfully.";
export const DOCUMENT_DELETE_MODAL_MSG =
  "Are you sure you want to delete document?";
export const STUDENT_ENROLMENT_APPROVAL =
  "Student enrolment submitted successfully";
export const STUDENT_ENROLMENT_APPROVED =
  "Student enrolment approved successfully";
export const WAIT_LIST_MESSAGE = {
  WAITLIST_FULL: (course: string) =>
    `Unfortunately, ${course} enrolments are full, and you've been added to the waitList.`,
};

export const APPROVE_SUCCESS = "Your enrolment request has been approved.";

export const WAIT_LIST_SUCCESS =
  "Your enrolment request has been moved to the waitList.";
export const STUDENT_PROFILE_UPDATE_SUCCESS_MESSAGE =
  "Student Profile updated successfully";
export const UNSUPPORTED_DOCUMENT = "Uploaded file is not supported";
export const ONLY_ONE_DOCUMENT_IS_ALLOWED_TO_UPLOAD =
  "Only 1 document is allowed to upload";

export const ENROLMENT_ADDED = "Student course enrolment Added Successfully";

export const CHANGE_COURSE_PREFERENCE_SUCCESS =
  "Your course preference updated successfully";

export const ATTENDANCE_FILLED_SUCCESS_MESSAGE =
  "Attendance filled Successfully";

export const FILE_SIZE_EXCEEDED = "File size exceeded the limit";

export const ALPHABETIC_VALIDATION_MSG =
  "should not contain any special characters, space or numbers";

export const ALPHABETIC_VALIDATION_WITH_SPACE_MSG =
  "should not contain any special characters or numbers";

export const RESEND_MAIL_SUCCESS = "Confirmation email sent successfully";
export const RESEND_SMS_SUCCESS = "Confirmation SMS sent successfully";

export const STUDENT_ENROLMENT_WITHDRAWN_SUCCESS_MSG =
  "Student enrolment withdrawn successfully.";
export const REMINDER_SENT_SUCCESSFULLY =
  "Remaining courses reminder sent to educator successfully.";

export const GENERATE_CERTIFICATE_FILLED_SUCCESS_MESSAGE =
  "Student certificate status updated successfully.";

export const START_DATE_YEAR_SHOULD_BE_SAME_AS_TERM_YEAR =
  "Start date year should be same as term year";

export const SECONDARY_EMAIL_SHOULD_NOT_BE_EQUAL_TO_EMAIL =
  "Secondary Email should not be equal to Email";

export const WHITE_SPACE_ERROR_SPACE = "should not contain whitespace";

export const WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER =
  "should not contain whitespace or special character";

export const WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER_WITH_NUMBER =
  "should not contain any special characters, whitespace or numbers";

export const ENROLMENT_STATUS_CHANGED_SUCCESSFULLY =
  "Enrolment status changed successfully.";

export const CERTIFICATE_GENERATED =
  "Student certificate generated Successfully";

export const SESSION_START_DATE_SHOULD_BE_BETWEEN_TERM_DATES =
  "Session start date should be between Term start date and end date";

export const CERTIFICATE_SETTINGS_CHANGED_SUCCESSFULLY =
  "Certificate settings changed successfully";

export const ONLY_PNG_ACCEPTED = "Only PNG file is allowed";

export const SYSTEM_ADMIN = "System-Admin";
