import { useForm } from "react-hook-form";
import { ForgotPasswordSuccessPayload } from "store/auth/types";
import { EmailPattern, showLoader } from "utils/helper";
import {
  IForgotPassword,
  IForgotPasswordContainerDispatch,
  IForgotPasswordContainerState,
} from "utils/interfaces/login";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { loginLogo } from "assets/images";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AppRoutings } from "utils/enums/app-routings";
import { PASSWORD_RESET_LINK_SENT } from "utils/constants/Messages";

export type ForgotPasswordProps = IForgotPasswordContainerState &
  IForgotPasswordContainerDispatch;

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>();
  const history = useHistory();

  const getEmailError = (): string => {
    if (errors.email) {
      if (errors.email.type === "required") {
        return "Email is required";
      }
      if (errors.email.type === "pattern") {
        return "Enter valid email";
      }
    }

    return "";
  };

  const onForgotPasswordSuccess = (response: ForgotPasswordSuccessPayload) => {
    if (response.success) {
      history.push(AppRoutings.LogIn);
      toast.success(PASSWORD_RESET_LINK_SENT);
    }
  };

  const onSubmit = async (data: IForgotPassword) => {
    const { forgotPasswordRequest } = props;

    if (forgotPasswordRequest) {
      showLoader();
      const payload = {
        values: {
          email: data.email,
        },
        callback: onForgotPasswordSuccess,
      };

      forgotPasswordRequest(payload);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="login-wrapper">
        <Grid container className="login-container">
          <Grid xs={12} sm="auto">
            <a
              href="/"
              title="South Eastern Sydney Recovery and Wellbeing College"
              className="login-logo"
            >
              <img
                src={loginLogo}
                alt="South Eastern Sydney Recovery and Wellbeing College"
              />
            </a>
          </Grid>
          <Grid xs={12} sm="auto">
            <Card className="login-card">
              <CardContent>
                <Typography variant="h2" className="card-heading">
                  Forgot Password
                </Typography>
                <Typography variant="h6" className="card-subheading">
                  Please enter your email address.
                </Typography>
                <div className="login-content-form">
                  <TextField
                    id="email"
                    label={
                      <>
                        Email <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    error={!!errors.email}
                    helperText={getEmailError()}
                    {...register("email", {
                      required: true,
                      pattern: EmailPattern,
                    })}
                  />
                </div>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  title="Submit"
                  className="login-btn"
                  disableElevation
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  title=" Back to Login"
                  href={AppRoutings.LogIn}
                  className="login-btn"
                  disableElevation
                >
                  Back to Login
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default ForgotPassword;
