import { Box } from "@mui/system";
import {
  Button,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon, saveIcon } from "assets/images";
import { EnrolmentOptionListSuccessPayload } from "store/studentEnrolment/types";
import { useEffect, useState } from "react";
import { showLoader } from "utils/helper";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import { CLOSE_TOOLTIP_TITLE, FACE_TO_FACE } from "utils/constants/constant";

interface IApplyCoursePreferenceModalProps {
  ApplyCoursePreferenceModalClose: any;
  enrolmentOptionListRequest: any;
  handleYesClick: any;
  selectedPreference?: string;
}

const ApplyCoursePreferencePopUp: React.FC<IApplyCoursePreferenceModalProps> =
  ({
    ApplyCoursePreferenceModalClose,
    enrolmentOptionListRequest,
    handleYesClick,
    selectedPreference,
  }) => {
    const [enrolmentOptionList, setEnrolmentOptionList] =
      useState<IEnrolmentOptions>();

    const [coursePreference, setCoursePreference] = useState<string>(
      selectedPreference || FACE_TO_FACE
    );

    const handleYes = () => {
      handleYesClick(coursePreference);
    };
    const onEnrolmentOptionListSuccess = (
      response: EnrolmentOptionListSuccessPayload
    ) => {
      setEnrolmentOptionList(response.enrolmentOptionList);
    };

    const getEnrolmentOptionList = () => {
      if (enrolmentOptionListRequest) {
        showLoader();

        const payload = {
          values: {},
          callback: onEnrolmentOptionListSuccess,
        };

        enrolmentOptionListRequest(payload);
      }
    };

    useEffect(() => {
      getEnrolmentOptionList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event: SelectChangeEvent) => {
      setCoursePreference(event.target.value as string);
    };

    return (
      <>
        <Modal open onClose={ApplyCoursePreferenceModalClose}>
          <Box className="common-modal apply-course-modal">
            <Box className="modal-header">
              <Typography variant="h2">Course Delivery Preference</Typography>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={CLOSE_TOOLTIP_TITLE}
                arrow
              >
                <IconButton onClick={ApplyCoursePreferenceModalClose}>
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="modal-body">
              <FormControl fullWidth className="select">
                <InputLabel id="course-del-label">
                  Preferred Course Delivery
                  <span className="color-red"> *</span>
                </InputLabel>
                <Select
                  labelId="course-del-label"
                  id="course-del"
                  label="Preferred Course Delivery "
                  value={coursePreference}
                  onChange={handleChange}
                >
                  {enrolmentOptionList?.preferredCourseDelivery?.map(
                    (option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Box>
            <Box className="apply-course-modal-footer">
              <Button
                variant="contained"
                className="btn-save"
                onClick={handleYes}
              >
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={ApplyCoursePreferenceModalClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
    );
  };
export default ApplyCoursePreferencePopUp;
