import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  calendarIcon,
  closeIcon,
  deleteIcon,
  editIcon,
  filterLightIcon,
  filterPrimaryIcon,
  plusLightIcon,
  saveIcon,
} from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";
import projectTheme from "app.theme";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

function CalendarIcon() {
  return (
    <div className="calendar-icon">
      <img src={calendarIcon} alt="calendar" />
    </div>
  );
}

interface LocationData {
  termname: string;
  term: number;
  year: string;
  termdate: string;
  termstatus: string;
  delivered: number;
  avgnum: number;
  avgattd: number;
}

function createLocationData(
  termname: string,
  term: number,
  year: string,
  termdate: string,
  termstatus: string,
  delivered: number,
  avgnum: number,
  avgattd: number
): LocationData {
  return {
    termname,
    term,
    year,
    termdate,
    termstatus,
    delivered,
    avgnum,
    avgattd,
  };
}

const termsrows = [
  createLocationData(
    "2023 T1",
    1,
    "2023",
    "12 April 2023 to 30 June 2023",
    "future",
    0,
    0,
    0
  ),
  createLocationData(
    "2023 T2",
    1,
    "2023",
    "12 April 2023 to 30 June 2023",
    "past",
    32,
    32,
    32
  ),
  createLocationData(
    "2023 T3",
    1,
    "2023",
    "12 April 2023 to 30 June 2023",
    "current",
    32,
    32,
    32
  ),
  createLocationData(
    "2023 T4",
    2,
    "2023",
    "12 April 2023 to 30 June 2023",
    "past",
    22,
    22,
    22
  ),
  createLocationData(
    "2023 T2",
    2,
    "2022",
    "12 April 2023 to 30 June 2023",
    "past",
    33,
    33,
    33
  ),
  createLocationData(
    "2023 T3",
    3,
    "2022",
    "12 April 2023 to 30 June 2023",
    "past",
    32,
    32,
    32
  ),
  createLocationData(
    "2023 T2",
    4,
    "2023",
    "12 April 2023 to 30 June 2023",
    "past",
    32,
    32,
    32
  ),
  createLocationData(
    "2023 T1",
    2,
    "2022",
    "12 April 2023 to 30 June 2023",
    "future",
    24,
    24,
    24
  ),
  createLocationData(
    "2023 T2",
    1,
    "2022",
    "12 April 2023 to 30 June 2023",
    "past",
    35,
    35,
    35
  ),
  createLocationData(
    "2023 T3",
    4,
    "2022",
    "12 April 2023 to 30 June 2023",
    "current",
    15,
    15,
    15
  ),
];

const DesignerTerms: React.FC = () => {
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [terms, setTermsmodal] = React.useState(false);
  const handleTermsmodalClose = () => setTermsmodal(false);

  const [term, setterm] = React.useState("");

  const handletermChange = (event: SelectChangeEvent) => {
    setterm(event.target.value as string);
  };

  const [termname, settermname] = React.useState("");

  const handletermnameChange = (event: SelectChangeEvent) => {
    settermname(event.target.value as string);
  };

  const [year, setyear] = React.useState("");

  const handleyearChange = (event: SelectChangeEvent) => {
    setyear(event.target.value as string);
  };

  const [termstatus, settermstatus] = React.useState("");

  const handletermstatusChange = (event: SelectChangeEvent) => {
    settermstatus(event.target.value as string);
  };

  const [filterbox, setFilterbox] = React.useState(false);
  const [edittermdetails, setEdittermdetails] = React.useState(false);

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Box
        className="filter-overlay"
        onClick={() => {
          document.body.classList.toggle("filter-open");
          setFilterbox(false);
        }}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        <div className="main-content">
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Terms
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setTermsmodal(true);
                setEdittermdetails(false);
              }}
              className="btn-add"
            >
              <img src={plusLightIcon} alt="plus" />
              Add
            </Button>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card>
                <Box className="table-card-header">
                  <Box
                    sx={{ justifyContent: "space-between", width: "100%" }}
                    className="search-filter-inputs"
                  >
                    <TextField
                      id="search"
                      variant="outlined"
                      className="search-input"
                      placeholder="Search"
                    />

                    <Button
                      variant="outlined"
                      className="btn-filter"
                      onClick={() => {
                        setFilterbox(true);
                        document.body.classList.toggle("filter-open");
                      }}
                    >
                      <img
                        src={filterPrimaryIcon}
                        alt="filter"
                        className="default"
                      />
                      <img
                        src={filterLightIcon}
                        alt="filter"
                        className="active"
                      />
                      <span>Filter</span>
                    </Button>
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            maxWidth: "116px",
                            width: "116px",
                            minWidth: "116px",
                          }}
                        >
                          Term Name
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "79px", minWidth: "79px" }}
                        >
                          Term
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "95px", minWidth: "95px" }}
                        >
                          Year
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "240px", minWidth: "240px" }}
                        >
                          Term Dates
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "137px", minWidth: "137px" }}
                        >
                          Terms Status
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "97px", minWidth: "97px" }}
                        >
                          Courses Delivered
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "170px", minWidth: "170px" }}
                        >
                          Avg. Number Enrolled Per Course
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "142px", minWidth: "142px" }}
                        >
                          Avg. Attendance Per Course
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "116px",
                            [projectTheme.breakpoints.down("sm")]: {
                              width: "112px",
                            },
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {termsrows.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {row.termname}
                          </TableCell>
                          <TableCell>{row.term}</TableCell>
                          <TableCell>{row.year}</TableCell>
                          <TableCell>
                            <Typography variant="body2" className="termdate">
                              {row.termdate}
                            </Typography>
                          </TableCell>
                          <TableCell className={`${row.termstatus} status`}>
                            <div>{row.termstatus} Term</div>
                          </TableCell>
                          <TableCell>{row.delivered}</TableCell>
                          <TableCell>{row.avgnum}</TableCell>
                          <TableCell>{row.avgattd}</TableCell>
                          <TableCell align="center">
                            <div className="table-actions">
                              <IconButton
                                onClick={() => {
                                  setTermsmodal(true);
                                  setEdittermdetails(true);
                                }}
                              >
                                <img src={editIcon} alt="edit" />
                              </IconButton>
                              <IconButton>
                                <img src={deleteIcon} alt="delete" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                    <Typography variant="body2" component="span">
                      Showing 10 - 20 of 50 entries
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={5}
                    variant="outlined"
                    shape="rounded"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
          <DesignerFooter />
        </div>
      </main>
      <Modal open={terms} onClose={handleTermsmodalClose}>
        <Box className="common-modal terms-modal">
          <Box className="modal-header">
            <Typography variant="h4">Add terms</Typography>
            <IconButton onClick={handleTermsmodalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Grid container columnSpacing="24px">
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel id="termname-select-label">Term Name</InputLabel>
                  <Select
                    labelId="termname-select-label"
                    id="termname-select"
                    label="Term Name"
                    value={termname}
                    onChange={handletermnameChange}
                  >
                    <MenuItem value="1">2023 T1</MenuItem>
                    <MenuItem value="2">2023 T2</MenuItem>
                    <MenuItem value="3">2023 T3</MenuItem>
                    <MenuItem value="4">2022 T1</MenuItem>
                    <MenuItem value="5">2022 T2</MenuItem>
                    <MenuItem value="6">2022 T3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth className="select">
                  <InputLabel id="term-select-label">Term</InputLabel>
                  <Select
                    labelId="term-select-label"
                    id="term-select"
                    label="Term"
                    value={term}
                    onChange={handletermChange}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth className="select">
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    label="Year"
                    value={year}
                    onChange={handleyearChange}
                  >
                    <MenuItem value={1}>2022</MenuItem>
                    <MenuItem value={2}>2023</MenuItem>
                    <MenuItem value={3}>2024</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container columnSpacing="24px">
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DesktopDatePicker"]}>
                        <DesktopDatePicker
                          className="date-picker"
                          label="Term Start Date"
                          slots={{
                            openPickerIcon: CalendarIcon,
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DesktopDatePicker"]}>
                        <DesktopDatePicker
                          className="date-picker"
                          label="Term End Date"
                          slots={{
                            openPickerIcon: CalendarIcon,
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel id="term-status-label">Term Status</InputLabel>
                  <Select
                    labelId="term-status-label"
                    id="term-status"
                    label="Year"
                    value={termstatus}
                    onChange={handletermstatusChange}
                  >
                    <MenuItem value={1}>Current Term</MenuItem>
                    <MenuItem value={2}>Past Term</MenuItem>
                    <MenuItem value={3}>Future Term</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Box className="modal-footer">
              <Button
                variant="contained"
                className="btn-save"
                onClick={handleTermsmodalClose}
              >
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={handleTermsmodalClose}
              >
                Cancel
              </Button>
            </Box>
            {edittermdetails && (
              <Box className="terms-read-only">
                <Grid
                  container
                  columnSpacing={{ sm: "16px", md: "24px" }}
                  rowSpacing="16px"
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Courses Delivered
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      32
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Avg. Number Enrolled Per Course
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      3
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Avg. Attendance Per Course
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      0
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Total Student Course Enrolments
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      0
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      No. Of New Students
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      0
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      No. Of Course Completion
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      0
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Courses with 15+ Students Enrolled
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      0
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Overall No. Of Attendance
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      0
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Overall Students Numbers
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      0
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Dropout Rate
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      0%
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" className="detail-name">
                      Courses Cancelled
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      0
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      {filterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="select">
              <InputLabel id="term-select-label">Term</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                label="Term"
                value={term}
                onChange={handletermChange}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                label="Year"
                value={year}
                onChange={handleyearChange}
              >
                <MenuItem value={1}>2022</MenuItem>
                <MenuItem value={2}>2023</MenuItem>
                <MenuItem value={3}>2024</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="term-status-label">Term Status</InputLabel>
              <Select
                labelId="term-status-label"
                id="term-status"
                label="Year"
                value={termstatus}
                onChange={handletermstatusChange}
              >
                <MenuItem value={1}>Current Term</MenuItem>
                <MenuItem value={2}>Past Term</MenuItem>
                <MenuItem value={3}>Future Term</MenuItem>
              </Select>
            </FormControl>
            <Grid container columnSpacing="24px">
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <DesktopDatePicker
                      className="date-picker"
                      label="Term Start Date"
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <DesktopDatePicker
                      className="date-picker"
                      label="Term End Date"
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default DesignerTerms;
