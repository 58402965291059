import { connect, MapDispatchToProps } from "react-redux";
import EducatorsHomeComponent, {
  EducatorsHomeProps,
} from "pages/EducatorsHome";
import {
  currentCourseListRequest,
  missingAttendanceCourseListRequest,
  pastCourseListRequest,
} from "store/educatorHome/actions";
import {
  IEducatorHomeContainerDispatch,
  ICurrentCourse,
} from "utils/interfaces/educator-home";

const mapDispatchToProps: MapDispatchToProps<
  IEducatorHomeContainerDispatch,
  ICurrentCourse
> = {
  currentCourseListRequest,
  pastCourseListRequest,
  missingAttendanceCourseListRequest,
};

const connector = connect(null, mapDispatchToProps);

const EducatorsHome: React.FC<EducatorsHomeProps> = (props) => {
  return <EducatorsHomeComponent {...props} />;
};

export default connector(EducatorsHome);
