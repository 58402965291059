import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  forgotPasswordFailure,
  forgotPasswordSuccess,
  loginFailure,
  loginSuccess,
  refreshTokenFailure,
  refreshTokenSuccess,
  validateResetPasswordUrlFailure,
  validateResetPasswordUrlSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
} from "./actions";

import {
  FORGOT_PASSWORD_REQUEST,
  LOGIN_REQUEST,
  REFRESH_TOKEN_REQUEST,
  VALIDATE_RESET_PASSWORD_URL_REQUEST,
  RESET_PASSWORD_REQUEST,
} from "./actionTypes";
import loginService from "./service";
import {
  ForgotPasswordResponse,
  LoginResponse,
  RefreshTokenResponse,
  ValidateResetPasswordUrlResponse,
  ResetPasswordResponse,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loginSaga(action: any) {
  try {
    const response: LoginResponse = yield call(loginService.login, {
      username: action.payload.values.email,
      password: action.payload.values.password,
      rememberMe: action.payload.values.rememberMe,
    });
    const successPayload = {
      ...response.item,
      rememberMe: action.payload.values.rememberMe,
    };

    yield put(
      loginSuccess({
        token: response.item.token,
        user: successPayload,
      })
    );
    action.payload.callback({
      token: response.item.token,
      user: successPayload,
    });
  } catch (e: any) {
    yield put(
      loginFailure({
        error: e.message,
      })
    );
  }
}

function* forgotPasswordSaga(action: any) {
  try {
    const response: ForgotPasswordResponse = yield call(
      loginService.forgotPassword,
      {
        userName: action.payload.values.email,
      }
    );

    yield put(
      forgotPasswordSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      forgotPasswordFailure({
        error: e.message,
      })
    );
  }
}

function* refreshTokenSaga(action: any) {
  try {
    const response: RefreshTokenResponse = yield call(
      loginService.refreshToken,
      {
        token: action.payload.values.token,
      }
    );

    yield put(refreshTokenSuccess(response.item));
    action.payload.callback(response.item);
  } catch (e: any) {
    yield put(
      refreshTokenFailure({
        error: e.message,
      })
    );
  }
}

function* validateResetPasswordUrlSaga(action: any) {
  try {
    const response: ValidateResetPasswordUrlResponse = yield call(
      loginService.validateResetPasswordUrl,
      {
        url: action.payload.values.url,
      }
    );

    yield put(
      validateResetPasswordUrlSuccess({
        success: response.item.isUrlValid,
      })
    );
    action.payload.callback({
      success: response.item.isUrlValid,
    });
  } catch (e: any) {
    yield put(
      validateResetPasswordUrlFailure({
        error: e.message,
      })
    );
  }
}

function* resetPasswordSaga(action: any) {
  try {
    const response: ResetPasswordResponse = yield call(
      loginService.resetPassword,
      {
        url: action.payload.values.url,
        password: action.payload.values.password,
      }
    );

    yield put(
      resetPasswordSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      resetPasswordFailure({
        error: e.message,
      })
    );
  }
}

function* authSaga() {
  yield all([takeLatest(LOGIN_REQUEST, loginSaga)]);
  yield all([takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga)]);
  yield all([takeLatest(REFRESH_TOKEN_REQUEST, refreshTokenSaga)]);
  yield all([
    takeLatest(
      VALIDATE_RESET_PASSWORD_URL_REQUEST,
      validateResetPasswordUrlSaga
    ),
  ]);
  yield all([takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga)]);
}

export default authSaga;
