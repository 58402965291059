import { ICourseReportPagePayloadValues } from "utils/interfaces/course-report";
import baseService from "services/base-service";

const courseReportBaseUrl = `/api/Reports`;

const getCourseReportList = async (payload: ICourseReportPagePayloadValues) =>
  baseService.post(`${courseReportBaseUrl}/GetCourseReport`, payload);

const printCourseReport = async (payload: ICourseReportPagePayloadValues) =>
  baseService.post(`${courseReportBaseUrl}/PrintCourseReport`, payload);

export default {
  getCourseReportList,
  printCourseReport,
};
