import { connect, MapDispatchToProps } from "react-redux";
import StaffReportComponent, { StaffReportProps } from "pages/StaffReport";
import {
  getStaffReportDetailsRequest,
  printStaffReportRequest,
  staffReportListRequest,
} from "store/staffReport/actions";
import {
  IStaffReportContainerDispatch,
  IStaffReport,
} from "utils/interfaces/staff-report";
import { enrolmentOptionListRequest } from "store/studentEnrolment/actions";

const mapDispatchToProps: MapDispatchToProps<
  IStaffReportContainerDispatch,
  IStaffReport
> = {
  staffReportListRequest,
  getStaffReportDetailsRequest,
  printStaffReportRequest,
  enrolmentOptionListRequest,
};

const connector = connect(null, mapDispatchToProps);

const StaffReport: React.FC<StaffReportProps> = (props) => {
  return <StaffReportComponent {...props} />;
};

export default connector(StaffReport);
