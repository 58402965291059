export const LOCATION_LIST_REQUEST = "LOCATION_LIST_REQUEST";
export const LOCATION_LIST_SUCCESS = "LOCATION_LIST_SUCCESS";
export const LOCATION_LIST_FAILURE = "LOCATION_LIST_FAILURE";

export const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAILURE = "ADD_LOCATION_FAILURE";

export const EDIT_LOCATION_REQUEST = "EDIT_LOCATION_REQUEST";
export const EDIT_LOCATION_SUCCESS = "EDIT_LOCATION_SUCCESS";
export const EDIT_LOCATION_FAILURE = "EDIT_LOCATION_FAILURE";

export const DELETE_LOCATION_REQUEST = "DELETE_LOCATION_REQUEST";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAILURE = "DELETE_LOCATION_FAILURE";
