import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  closeIcon,
  deleteIcon,
  editIcon,
  filterLightIcon,
  filterPrimaryIcon,
  plusLightIcon,
} from "assets/images";
import React, { useEffect, useState } from "react";
import projectTheme from "app.theme";
import {
  IAddEditCourse,
  ICourse,
  ICourseContainerDispatch,
  ICourseContainerState,
  ICourseListMeta,
  ICoursesDetails,
  IEnhancedTableProps,
  ICoursePagePayloadValues,
} from "utils/interfaces/course";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  allowSearch,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import { IRoleModulePermission } from "store/role/types";
import {
  CourseListSuccessPayload,
  DeleteCourseSuccessPayload,
} from "store/course/types";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  CLEAR_TOOLTIP_TITLE,
  COURSE_AVAILABILITY,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DELETE_TOOLTIP_TITLE,
  EDIT_TOOLTIP_TITLE,
  MAX_INT_VALUE,
  PAGE_SIZES,
} from "utils/constants/constant";
import { toast } from "react-toastify";
import { ExportToCsv } from "export-to-csv";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import NoRecords from "components/NoRecords";
import { AppRoutings } from "utils/enums/app-routings";
import {
  COURSE_DELETE_SUCCESS_MSG,
  SELECT_MIN_ONE_FIELD,
} from "utils/constants/Messages";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { AppPages } from "utils/enums/app-pages";
import OverlayBox from "components/OverlayBox";
import CourseFilter from "./CourseFilter";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const {
    order,
    orderBy,
    onRequestSort,
    fieldName,
    keyName,
    sxStyle,
    alignCellCenter,
  } = props;
  const createSortHandler =
    (newOrderBy: keyof ICoursesDetails) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align={alignCellCenter ? "center" : "left"}
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type CourseProps = ICourseContainerState & ICourseContainerDispatch;

const Courses: React.FC<CourseProps> = (props) => {
  const [courseList, setCourseList] = useState<ICourse[]>();
  const [courseListMeta, setCourseListMeta] = useState<ICourseListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof ICoursesDetails>("name");
  const [searchText, setSearchText] = useState<string>("");
  const [filterBox, setFilterBox] = useState(false);
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [filteredData, setFilteredData] = useState<IAddEditCourse>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
  const [isOpenCourseDeleteModal, setIsOpenCourseDeleteModal] = useState(false);
  const [deleteCourseId, setDeleteCourseId] = useState<number>();

  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const handleCourseDeleteModal = (courseId: number) => {
    setDeleteCourseId(courseId);
    setIsOpenCourseDeleteModal(true);
  };

  const handleCourseDeleteCloseModal = () => {
    setDeleteCourseId(0);
    setIsOpenCourseDeleteModal(false);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof ICoursesDetails) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onCourseListSuccess = (response: CourseListSuccessPayload) => {
    setCourseList(response.courseList);
    setCourseListMeta(response.courseListPagination);
  };

  const getCourseList = async (
    filterData?: IAddEditCourse,
    filtered?: boolean,
    pageNum?: number
  ) => {
    const { courseListRequest } = props;

    if (courseListRequest) {
      showLoader();

      const values: ICoursePagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.name = filtered ? "" : searchText.trim();

      if (filterData?.courseNo) {
        values.courseNo = filterData?.courseNo;
      }
      if (filterData?.stream) {
        values.stream = filterData?.stream;
      }

      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onCourseListSuccess,
      };

      courseListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Courses);
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getCourseList(filteredData, true, 1);
      } else if (filteredData) {
        getCourseList(filteredData);
      } else {
        getCourseList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleCourseSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getCourseList(filteredData, true, 1);
  };

  const handleCourseSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getCourseList(filteredData);
    }
  };

  const handleFilterApplyClick = (formData: IAddEditCourse) => {
    if (!(formData.name || formData.courseNo || formData.stream)) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      setSearchText("");
      if (pageNo === DEFAULT_PAGE_NO) {
        getCourseList(formData, true, 1);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      document.body.classList.toggle("filter-open");
    }
  };

  const onDeleteCourseSuccess = (response: DeleteCourseSuccessPayload) => {
    if (response.success) {
      toast.success(COURSE_DELETE_SUCCESS_MSG);
      handleCourseDeleteCloseModal();
      getCourseList();
    }
  };

  const handleDeleteCourse = () => {
    const { deleteCourseRequest } = props;

    if (deleteCourseRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(deleteCourseId),
        },
        callback: onDeleteCourseSuccess,
      };

      deleteCourseRequest(payload);
    }
  };

  const csvFileHeaders = [
    "Course Number",
    "Course Name",
    "Stream",
    "Offerings In Current Term",
    "Previous Name",
    "Skills",
    "Knowledge",
    "Attitudes",
    "Course Available In This Term",
    "No Of Session",
    "Length Of Single Session",
    "Description",
  ];

  const getCourseAvailability = (value: number | undefined) => {
    let availability = "";

    COURSE_AVAILABILITY.forEach((courseAvail: any) => {
      if (courseAvail.value === value) {
        availability = courseAvail.label;
      }
    });

    return availability;
  };

  const getCoursesModifiedData = (list: ICourse[]) => {
    const data = list?.map((course: ICourse) => {
      return {
        "Course Number": course.courseNo,
        "Course Name": course.name,
        Stream: course.stream,
        "Offerings In Current Term": course.offeringsInCurrentTerm,
        "Previous Name": course.previousName,
        Skills: course.skills,
        Knowledge: course.knowledge,
        Attitudes: course.attitudes,
        "Course Available In This Term": getCourseAvailability(
          course.courseAvailableInThisTerm
        ),
        "No Of Session": Number(course.noOfSession),
        "Length Of Single Session": Number(course.lengthOfSingleSession),
        Description: course.description?.replace(/<[^>]+>/g, ""),
      };
    });

    return data;
  };

  const onExportCourseListSuccess = (response: CourseListSuccessPayload) => {
    const modifiedCourseData = getCoursesModifiedData(response.courseList);
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeaders,
      filename: "courses",
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedCourseData);
  };

  const getExportCourses = () => {
    const { courseListRequest } = props;

    if (courseListRequest) {
      showLoader();

      const values: ICoursePagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        DEFAULT_PAGE_NO,
        MAX_INT_VALUE
      );
      values.name = "";

      if (filteredData?.courseNo) {
        values.courseNo = filteredData?.courseNo;
      }
      if (filteredData?.stream) {
        values.stream = filteredData?.stream;
      }

      const payload = {
        values,
        callback: onExportCourseListSuccess,
      };

      courseListRequest(payload);
    }
  };

  return (
    <>
      <>
        <div>
          {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Courses
            </Typography>
            <Box sx={{ display: "flex", columnGap: "15px" }}>
              <Button
                variant="contained"
                className="btn-add"
                onClick={() => history.push(AppRoutings.AddCourse)}
                disabled={!pagePermission?.add}
              >
                <img src={plusLightIcon} alt="plus" />
                Add
              </Button>

              <Button
                variant="outlined"
                className="btn-export"
                onClick={getExportCourses}
              >
                Export
              </Button>
            </Box>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card>
                <Box className="table-card-header">
                  <Box
                    sx={{ justifyContent: "space-between", width: "100%" }}
                    className="search-filter-inputs"
                  >
                    <TextField
                      id="search-box"
                      variant="outlined"
                      className="search-input"
                      placeholder="Search"
                      value={searchText}
                      onChange={handleCourseSearch}
                      onKeyDown={handleCourseSearchKeyDown}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ position: "absolute", right: "0" }}
                            position="end"
                          >
                            {searchText && (
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={CLEAR_TOOLTIP_TITLE}
                                arrow
                              >
                                <IconButton
                                  edge="end"
                                  onClick={handleClearSearch}
                                >
                                  <img src={closeIcon} alt="close" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onKeyPress={(event) =>
                        onTextFieldKeyPress(event, searchText?.length)
                      }
                    />

                    <Button
                      variant="outlined"
                      className="btn-filter"
                      onClick={() => {
                        setFilterBox(true);
                        document.body.classList.toggle("filter-open");
                      }}
                    >
                      <img
                        src={filterPrimaryIcon}
                        alt="filter"
                        className="default"
                      />
                      <img
                        src={filterLightIcon}
                        alt="filter"
                        className="active"
                      />
                      <span>Filter</span>
                    </Button>
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "name")}
                          fieldName="Course Name"
                          keyName="name"
                          sxStyle={{
                            width: "423px",
                            minWidth: "423px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) =>
                            handleRequestSort(e, "courseNo")
                          }
                          fieldName="Course Number"
                          keyName="courseNo"
                          sxStyle={{
                            width: "243px",
                            minWidth: "180px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          alignCellCenter
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "stream")}
                          fieldName="Stream"
                          keyName="stream"
                          sxStyle={{
                            width: "125px",
                            minWidth: "125px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          alignCellCenter
                          orderBy={orderBy}
                          onRequestSort={(e) =>
                            handleRequestSort(e, "offeringsInCurrentTerm")
                          }
                          fieldName="Offerings In Current Term"
                          keyName="offeringsInCurrentTerm"
                          sxStyle={{ width: "217px", minWidth: "217px" }}
                        />
                        <TableCell
                          align="center"
                          sx={{
                            width: "144px",
                            [projectTheme.breakpoints.down("sm")]: {
                              width: "112px",
                            },
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courseList?.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            component="th"
                            scope="row"
                            className="ellipsis-text"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="body2"
                              className="email ellipsis-text"
                            >
                              {row.courseNo}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">{row.stream}</TableCell>
                          <TableCell align="center">
                            {row.offeringsInCurrentTerm}
                          </TableCell>
                          <TableCell align="center">
                            <div className="table-actions">
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={EDIT_TOOLTIP_TITLE}
                                arrow
                              >
                                <IconButton
                                  onClick={() =>
                                    history.push({
                                      pathname: AppRoutings.EditCourse,
                                      state: { courseId: row?.id },
                                    })
                                  }
                                  disabled={!pagePermission?.edit}
                                  className={
                                    !pagePermission?.edit ? "disabled-icon" : ""
                                  }
                                >
                                  <img src={editIcon} alt="edit" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={DELETE_TOOLTIP_TITLE}
                                arrow
                              >
                                <IconButton
                                  onClick={() =>
                                    handleCourseDeleteModal(Number(row?.id))
                                  }
                                  disabled={!pagePermission?.delete}
                                  className={
                                    !pagePermission?.delete
                                      ? "disabled-icon"
                                      : ""
                                  }
                                >
                                  <img src={deleteIcon} alt="delete" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {courseList && courseList.length > 0 ? (
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        {PAGE_SIZES?.map((pageSize) => (
                          <MenuItem
                            key={pageSize.value}
                            value={pageSize.value}
                            selected={pageSize?.selected}
                          >
                            {pageSize.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="body2" component="span">
                        {getPaginationDetailText(courseListMeta, pageNo, page)}
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={courseListMeta?.totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={pageNo}
                      onChange={onPageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <NoRecords />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </>

      {filterBox && (
        <CourseFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          getCourseList={getCourseList}
          setAppliedFilter={setAppliedFilter}
        />
      )}

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenCourseDeleteModal}
        handleDeleteConfirmationModalClose={handleCourseDeleteCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete course?"
        handleYesClick={handleDeleteCourse}
      />
    </>
  );
};

export default Courses;
