import {
  STAFF_REPORT_LIST_REQUEST,
  STAFF_REPORT_LIST_SUCCESS,
  STAFF_REPORT_LIST_FAILURE,
  GET_STAFF_REPORT_DETAILS_FAILURE,
  GET_STAFF_REPORT_DETAILS_REQUEST,
  GET_STAFF_REPORT_DETAILS_SUCCESS,
  PRINT_STAFF_REPORT_FAILURE,
  PRINT_STAFF_REPORT_REQUEST,
  PRINT_STAFF_REPORT_SUCCESS,
} from "./actionTypes";
import { StaffReportState, StaffReportActions } from "./types";

const initialState: StaffReportState = {
  pending: false,
  staffReportList: [],
  error: null,
};

const reducers = (
  state = initialState,
  action: StaffReportActions
): unknown => {
  switch (action.type) {
    case STAFF_REPORT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case STAFF_REPORT_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        staffReportList: action.payload.staffReportList,
        error: null,
      };
    case STAFF_REPORT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case PRINT_STAFF_REPORT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PRINT_STAFF_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        item: action.payload.item,
        error: null,
      };
    case PRINT_STAFF_REPORT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_STAFF_REPORT_DETAILS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_STAFF_REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        item: action.payload.staffReportDetailList,
        error: null,
      };
    case GET_STAFF_REPORT_DETAILS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
