import {
  STAFF_REPORT_LIST_REQUEST,
  STAFF_REPORT_LIST_FAILURE,
  STAFF_REPORT_LIST_SUCCESS,
  GET_STAFF_REPORT_DETAILS_FAILURE,
  GET_STAFF_REPORT_DETAILS_REQUEST,
  GET_STAFF_REPORT_DETAILS_SUCCESS,
  PRINT_STAFF_REPORT_FAILURE,
  PRINT_STAFF_REPORT_REQUEST,
  PRINT_STAFF_REPORT_SUCCESS,
} from "./actionTypes";
import {
  StaffReportListPayload,
  StaffReportListRequest,
  StaffReportListSuccessPayload,
  StaffReportListSuccess,
  StaffReportListFailurePayload,
  StaffReportListFailure,
  GetStaffReportDetailsFailure,
  GetStaffReportDetailsFailurePayload,
  GetStaffReportDetailsListSuccessPayload,
  GetStaffReportDetailsPayload,
  GetStaffReportDetailsRequest,
  GetStaffReportDetailsSuccess,
  PrintStaffReportFailure,
  PrintStaffReportFailurePayload,
  PrintStaffReportPayload,
  PrintStaffReportRequest,
  PrintStaffReportSuccess,
  PrintStaffReportSuccessPayload,
} from "./types";

export const staffReportListRequest = (
  payload: StaffReportListPayload
): StaffReportListRequest => ({
  type: STAFF_REPORT_LIST_REQUEST,
  payload,
});

export const staffReportListSuccess = (
  payload: StaffReportListSuccessPayload
): StaffReportListSuccess => ({
  type: STAFF_REPORT_LIST_SUCCESS,
  payload,
});

export const staffReportListFailure = (
  payload: StaffReportListFailurePayload
): StaffReportListFailure => ({
  type: STAFF_REPORT_LIST_FAILURE,
  payload,
});

export const printStaffReportRequest = (
  payload: PrintStaffReportPayload
): PrintStaffReportRequest => ({
  type: PRINT_STAFF_REPORT_REQUEST,
  payload,
});

export const printStaffReportSuccess = (
  payload: PrintStaffReportSuccessPayload
): PrintStaffReportSuccess => ({
  type: PRINT_STAFF_REPORT_SUCCESS,
  payload,
});

export const printStaffReportFailure = (
  payload: PrintStaffReportFailurePayload
): PrintStaffReportFailure => ({
  type: PRINT_STAFF_REPORT_FAILURE,
  payload,
});

export const getStaffReportDetailsRequest = (
  payload: GetStaffReportDetailsPayload
): GetStaffReportDetailsRequest => ({
  type: GET_STAFF_REPORT_DETAILS_REQUEST,
  payload,
});

export const getStaffReportDetailsSuccess = (
  payload: GetStaffReportDetailsListSuccessPayload
): GetStaffReportDetailsSuccess => ({
  type: GET_STAFF_REPORT_DETAILS_SUCCESS,
  payload,
});

export const getStaffReportDetailsFailure = (
  payload: GetStaffReportDetailsFailurePayload
): GetStaffReportDetailsFailure => ({
  type: GET_STAFF_REPORT_DETAILS_FAILURE,
  payload,
});
