import { Box } from "@mui/system";
import {
  Button,
  TextField,
  Typography,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import projectTheme from "app.theme";
import { saveIcon } from "assets/images";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  IAddEditRole,
  IRoleContainerDispatch,
  IRoleContainerState,
} from "utils/interfaces/role";
import { toast } from "react-toastify";
import { getPagePermissions, showLoader } from "utils/helper";
import {
  AddEditRoleSuccessPayload,
  IModuleResponse,
  IRolePermission,
  ModuleListSuccessPayload,
  RolePermissionsSuccessPayload,
} from "store/role/types";
import { useLocation, useHistory } from "react-router-dom";
import {
  EDUCATOR_ROLE_ID,
  EDUCATOR_ROLE_NAME,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_REGEX,
  ROLE_NAME_MAX_LENGTH,
  STUDENT_ROLE_ID,
  STUDENT_ROLE_NAME,
} from "utils/constants/constant";
import { useSelector } from "react-redux";
import { AppRoutings } from "utils/enums/app-routings";
import {
  AT_LEAST_ONE_PERMISSION_MSG,
  ROLE_ADD_SUCCESS_MSG,
  ROLE_EDIT_SUCCESS_MSG,
  WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER,
} from "utils/constants/Messages";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { AppPages } from "utils/enums/app-pages";

export type AddEditRoleProps = IRoleContainerState & IRoleContainerDispatch;

const AddEditRole: React.FC<AddEditRoleProps> = (props) => {
  const [roleData, setRoleData] = useState<any>();
  const location = useLocation<{ roleId: number }>();
  const [isEditDisabledRole, setEditIsDisabledRole] = useState<boolean>(false);
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IAddEditRole>();

  const onRolePermissionsListSuccess = (
    response: RolePermissionsSuccessPayload
  ) => {
    setRoleData(response.rolePermissionList);
    setValue("role", response.rolePermissionList?.role);
  };

  const getRolePermissionsList = async () => {
    const { rolePermissionsRequest } = props;
    if (rolePermissionsRequest) {
      showLoader();
      const payload = {
        values: {
          id: location.state?.roleId,
        },
        callback: onRolePermissionsListSuccess,
      };
      rolePermissionsRequest(payload);
    }
  };

  const onModuleListSuccess = (response: ModuleListSuccessPayload) => {
    const role = {
      role: "",
      moduleResponses: response.moduleList,
    };
    setRoleData(role);
  };

  const getModuleList = async () => {
    const { moduleListRequest } = props;
    if (moduleListRequest) {
      showLoader();
      const payload = {
        values: {},
        callback: onModuleListSuccess,
      };
      moduleListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Roles);
    if (location.state?.roleId) {
      if (permission?.edit) {
        getRolePermissionsList();
        if (
          location.state?.roleId === STUDENT_ROLE_ID ||
          location.state?.roleId === EDUCATOR_ROLE_ID
        )
          setEditIsDisabledRole(true);
      } else {
        history.goBack();
      }
    } else if (permission?.add && !window.location.pathname.includes("edit")) {
      getModuleList();
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoleNameError = (): string => {
    if (errors.role) {
      if (errors.role.type === "required") {
        return "Role is required";
      }

      if (errors.role.type === "maxLength") {
        return `Maximum length of role should be ${ROLE_NAME_MAX_LENGTH}`;
      }

      if (errors.role.type === "pattern") {
        return `Role ${WHITE_SPACE_ERROR_SPACE_WITHOUT_SPECIAL_CHARACTER}`;
      }
    }

    return "";
  };

  const onChangePermission = (
    moduleName: string,
    page: string,
    type: string
  ) => {
    const { moduleResponses } = roleData;
    const module = moduleResponses.find(
      (mod: any) => mod.moduleName === moduleName
    );
    const moduleIndex = moduleResponses.findIndex(
      (mod: any) => mod.moduleName === moduleName
    );

    const rolePermissionsRes = module?.rolePermissionsResponses;

    const editPage = rolePermissionsRes.find((per: any) => per.page === page);
    const editPageIndex = rolePermissionsRes.findIndex(
      (per: any) => per.page === page
    );

    if (type) {
      if (type === "add" || type === "edit" || type === "delete") {
        if (editPage.view === false && !editPage[type] === true) {
          editPage.view = true;
        }
      } else if (editPage.view === true) {
        editPage.add = false;
        editPage.edit = false;
        editPage.delete = false;
      }

      rolePermissionsRes[editPageIndex] = {
        ...editPage,
        [type]: !editPage[type],
      };
    } else {
      if (
        !editPage.view ||
        !editPage.add ||
        !editPage.edit ||
        !editPage.delete
      ) {
        editPage.view = true;
        editPage.add = true;
        editPage.edit = true;
        editPage.delete = true;
      } else {
        editPage.view = false;
        editPage.add = false;
        editPage.edit = false;
        editPage.delete = false;
      }

      rolePermissionsRes[editPageIndex] = { ...editPage };
    }

    moduleResponses[moduleIndex] = {
      ...module,
      rolePermissionsResponses: rolePermissionsRes,
    };

    const editedRoleData = { ...roleData, moduleResponses };
    setRoleData(editedRoleData);
  };

  const isFormValid = () => {
    let isValid = false;
    roleData.moduleResponses.forEach((module: any) => {
      const permissions = module.rolePermissionsResponses;

      permissions.forEach((permission: any) => {
        if (
          permission.view ||
          permission.add ||
          permission.edit ||
          permission.delete
        ) {
          isValid = true;
        }
      });
    });

    return isValid;
  };

  const onAddEditRoleSuccess = (response: AddEditRoleSuccessPayload) => {
    if (response.success) {
      if (location.state?.roleId) {
        toast.success(ROLE_EDIT_SUCCESS_MSG);
      } else {
        toast.success(ROLE_ADD_SUCCESS_MSG);
      }

      history.push(AppRoutings.Roles);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const onSubmit = async (data: IAddEditRole) => {
    if (isFormValid()) {
      setRoleData({ ...roleData, role: data.role });
      const permissions: Array<IRolePermission> = [];
      roleData.moduleResponses?.forEach((module: any) => {
        const permissionsArray = module.rolePermissionsResponses;
        permissionsArray?.map((permission: any) => {
          const item: IRolePermission = {
            pageName: permission.page,
            view: permission.view,
            add: permission.add,
            edit: permission.edit,
            delete: permission.delete,
            moduleID: module.moduleID,
          };
          if (location.state?.roleId) {
            item.id = permission?.rolePermissionID;
          }
          permissions.push(item);

          return permissions;
        });
      });

      if (location.state?.roleId) {
        const { editRoleRequest } = props;
        if (editRoleRequest) {
          showLoader();
          const payload = {
            values: {
              id: roleData.id,
              role: data.role.trim(),
              permissions,
            },
            callback: onAddEditRoleSuccess,
          };
          editRoleRequest(payload);
        }
      } else {
        const { addRoleRequest } = props;
        if (addRoleRequest) {
          showLoader();
          const payload = {
            values: {
              role: data.role.trim(),
              permissions,
            },
            callback: onAddEditRoleSuccess,
          };
          addRoleRequest(payload);
        }
      }
    } else {
      toast.error(AT_LEAST_ONE_PERMISSION_MSG);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Roles
        </Typography>
        <Button
          variant="outlined"
          className="btn-back"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Card>
            <Box className="table-card-header">
              <Box>
                <TextField
                  id="roles"
                  variant="outlined"
                  className="roles-input"
                  disabled={isEditDisabledRole}
                  label={
                    <>
                      Role <span className="color-red">*</span>
                    </>
                  }
                  InputLabelProps={{
                    shrink: !!roleData?.role || !!watch("role"),
                  }}
                  error={!!errors.role}
                  helperText={getRoleNameError()}
                  {...register("role", {
                    required: true,
                    maxLength: ROLE_NAME_MAX_LENGTH,
                    pattern:
                      INPUT_VALIDATION_NO_WHITE_SPACE_WITHOUT_SPECIAL_CHARACTER_REGEX,
                  })}
                />
                {isEditDisabledRole && (
                  <Typography
                    sx={{ color: "rgb(239, 142, 15)" }}
                    variant="body1"
                    alignContent="center"
                  >
                    (* This role is not editable)
                  </Typography>
                )}
              </Box>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        [projectTheme.breakpoints.down("lg")]: {
                          width: "236px",
                        },
                      }}
                    >
                      Permission
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        width: "173px",
                        [projectTheme.breakpoints.down("lg")]: {
                          minWidth: "112px",
                        },
                        [projectTheme.breakpoints.down("sm")]: {
                          minWidth: "88px",
                        },
                      }}
                    >
                      View
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        width: "173px",
                        [projectTheme.breakpoints.down("lg")]: {
                          minWidth: "112px",
                        },
                        [projectTheme.breakpoints.down("sm")]: {
                          minWidth: "88px",
                        },
                      }}
                    >
                      Add
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        width: "182px",
                        [projectTheme.breakpoints.down("lg")]: {
                          minWidth: "112px",
                        },
                        [projectTheme.breakpoints.down("sm")]: {
                          minWidth: "88px",
                        },
                      }}
                    >
                      Edit
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        width: "182px",
                        [projectTheme.breakpoints.down("lg")]: {
                          minWidth: "112px",
                        },
                        [projectTheme.breakpoints.down("sm")]: {
                          minWidth: "88px",
                        },
                      }}
                    >
                      Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roleData?.moduleResponses
                    ?.filter((module: IModuleResponse) => {
                      if (roleData?.role.toLowerCase() === STUDENT_ROLE_NAME) {
                        return module.moduleID === 3;
                      }
                      if (roleData?.role.toLowerCase() === EDUCATOR_ROLE_NAME) {
                        return module.moduleID === 2;
                      }

                      return module.moduleID !== 3;
                    })
                    .map((module: any) => {
                      return (
                        <React.Fragment key={module.moduleID}>
                          <TableRow
                            role="checkbox"
                            tabIndex={-1}
                            key={module.moduleID}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Typography>{module.moduleName}</Typography>
                            </TableCell>
                            <TableCell align="center" />
                            <TableCell align="center" />
                            <TableCell align="center" />
                            <TableCell align="center" />
                          </TableRow>
                          {module?.rolePermissionsResponses?.map((row: any) => {
                            return (
                              <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={row?.page}
                                className="table-sub-row"
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell scope="row" padding="none">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disableFocusRipple
                                        disableRipple
                                        disabled={isEditDisabledRole}
                                        icon={<CheckboxIcon />}
                                        checkedIcon={<CheckedBoxIcon />}
                                        value={
                                          row.view &&
                                          row.add &&
                                          row.edit &&
                                          row.delete
                                        }
                                        checked={
                                          row.view &&
                                          row.add &&
                                          row.edit &&
                                          row.delete
                                        }
                                        onChange={() =>
                                          onChangePermission(
                                            module.moduleName,
                                            row.page,
                                            ""
                                          )
                                        }
                                      />
                                    }
                                    label={row.page}
                                    labelPlacement="end"
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disableFocusRipple
                                        disableRipple
                                        disabled={isEditDisabledRole}
                                        icon={<CheckboxIcon />}
                                        checkedIcon={<CheckedBoxIcon />}
                                        value={row.view}
                                        checked={row.view}
                                        onChange={() =>
                                          onChangePermission(
                                            module.moduleName,
                                            row.page,
                                            "view"
                                          )
                                        }
                                      />
                                    }
                                    label={undefined}
                                    labelPlacement="end"
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disableFocusRipple
                                        disableRipple
                                        disabled={isEditDisabledRole}
                                        icon={<CheckboxIcon />}
                                        checkedIcon={<CheckedBoxIcon />}
                                        checked={row.add}
                                        onChange={() =>
                                          onChangePermission(
                                            module.moduleName,
                                            row.page,
                                            "add"
                                          )
                                        }
                                      />
                                    }
                                    label={undefined}
                                    labelPlacement="end"
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disableFocusRipple
                                        disableRipple
                                        disabled={isEditDisabledRole}
                                        icon={<CheckboxIcon />}
                                        checkedIcon={<CheckedBoxIcon />}
                                        checked={row.edit}
                                        onChange={() =>
                                          onChangePermission(
                                            module.moduleName,
                                            row.page,
                                            "edit"
                                          )
                                        }
                                      />
                                    }
                                    label={undefined}
                                    labelPlacement="end"
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disableFocusRipple
                                        disableRipple
                                        disabled={isEditDisabledRole}
                                        icon={<CheckboxIcon />}
                                        checkedIcon={<CheckedBoxIcon />}
                                        checked={row.delete}
                                        onChange={() =>
                                          onChangePermission(
                                            module.moduleName,
                                            row.page,
                                            "delete"
                                          )
                                        }
                                      />
                                    }
                                    label={undefined}
                                    labelPlacement="end"
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="btn-group" sx={{ paddingTop: "30px" }}>
              <Button
                variant="contained"
                disabled={isEditDisabledRole}
                className="btn-save"
                type="submit"
              >
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddEditRole;
