import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
  CHANGE_MANAGER_SETTING_FAILURE,
  CHANGE_MANAGER_SETTING_REQUEST,
  CHANGE_MANAGER_SETTING_SUCCESS,
} from "./actionTypes";

import { ProfileActions, ProfileState } from "./types";

const initialState: ProfileState = {
  pending: false,
  error: null,
};

const reducers = (state = initialState, action: ProfileActions): unknown => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case CHANGE_MANAGER_SETTING_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CHANGE_MANAGER_SETTING_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
      };
    case CHANGE_MANAGER_SETTING_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
