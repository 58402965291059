import { all, call, put, takeLatest } from "redux-saga/effects";
import { statisticsDataFailure, statisticsDataSuccess } from "./actions";
import { STATISTICS_DATA_REQUEST } from "./actionTypes";
import dashboardService from "./service";
import { StatisticsDataResponse } from "./types";

function* statisticsDataSaga(action: any) {
  try {
    const response: StatisticsDataResponse = yield call(
      dashboardService.getStatisticsData
    );

    yield put(
      statisticsDataSuccess({
        statisticsData: response.item,
      })
    );
    action.payload.callback({
      statisticsData: response.item,
    });
  } catch (e: any) {
    yield put(
      statisticsDataFailure({
        error: e.message,
      })
    );
  }
}

function* dashboardSaga() {
  yield all([takeLatest(STATISTICS_DATA_REQUEST, statisticsDataSaga)]);
}

export default dashboardSaga;
