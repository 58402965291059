import { Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  coursesTotalIcon,
  dropboxLogo,
  mailchimpLogo,
  nswLogo,
  offeringTotalIcon,
} from "assets/images";
import React, { useEffect, useState } from "react";
import {
  IDashboard,
  IDashboardContainerDispatch,
  IDashboardContainerState,
} from "utils/interfaces/dashboard";
import {
  getPagePermissions,
  hideLoaderForBatchApiCall,
  showLoaderForBatchApiCall,
} from "utils/helper";
import { StatisticsDataSuccessPayload } from "store/dashboard/types";
import { AuthState } from "store/rootReducer";
import { ILoginResponse } from "utils/interfaces/login";
import { getUserSelector } from "store/auth/selector";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { IRoleModulePermission } from "store/role/types";
import { AppPages } from "utils/enums/app-pages";
import ScheduledOfferingSection from "./ScheduledOfferingSection";

export type DashboardProps = IDashboardContainerState &
  IDashboardContainerDispatch;

export type DashboardCombinedProps = {
  user: ILoginResponse;
} & DashboardProps;

const Dashboard = ({ user, ...props }: DashboardCombinedProps) => {
  const history = useHistory();
  const [statisticsData, setStatisticsData] = useState<IDashboard>({
    totalScheduledCourseOfferings: 0,
    totalActiveCourses: 0,
    totalCancelledCourse: 0,
  });
  const [scoPagePermission, setScoPagePermission] =
    useState<IRoleModulePermission>();
  const [coursePagePermission, setCoursePagePermission] =
    useState<IRoleModulePermission>();

  const onStatisticsDataSuccess = (response: StatisticsDataSuccessPayload) => {
    setStatisticsData(response.statisticsData);
    if (!scoPagePermission?.view) {
      hideLoaderForBatchApiCall();
    }
  };

  const getStatisticsData = async () => {
    const { statisticsDataRequest } = props;
    if (statisticsDataRequest) {
      showLoaderForBatchApiCall();
      const payload = {
        values: {},
        callback: onStatisticsDataSuccess,
      };
      statisticsDataRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.Dashboard,
      "administrator"
    );
    if (permission?.view) {
      getStatisticsData();
      const scoPermission = getPagePermissions(
        user?.permissions,
        AppPages.ScheduledOfferings
      );
      setScoPagePermission(scoPermission);
      const coursePermission = getPagePermissions(
        user?.permissions,
        AppPages.Courses
      );
      setCoursePagePermission(coursePermission);
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Dashboard
        </Typography>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Grid container spacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
            <Grid item xs={12} md={4}>
              <Card className="counter-card">
                <Typography variant="h5">Total Scheduled Offering</Typography>
                <Typography variant="h1">
                  {statisticsData?.totalScheduledCourseOfferings}
                </Typography>
                <div className="counter-card-icon">
                  <img src={offeringTotalIcon} alt="Total Scheduled Offering" />
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className="counter-card">
                <Typography variant="h5">Total Active Courses</Typography>
                <Typography variant="h1">
                  {statisticsData?.totalActiveCourses}
                </Typography>
                <div className="counter-card-icon">
                  <img src={coursesTotalIcon} alt="Total Active Courses" />
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className="counter-card">
                <Typography variant="h5">
                  Total Cancelled Course Offerings
                </Typography>
                <Typography variant="h1">
                  {statisticsData?.totalCancelledCourse}
                </Typography>
                <div className="counter-card-icon">
                  <img src={coursesTotalIcon} alt="Total Cancelled Courses" />
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {scoPagePermission?.view && (
          <ScheduledOfferingSection
            scoPagePermission={scoPagePermission}
            coursePagePermission={coursePagePermission}
          />
        )}

        <Grid item xs={12} sx={{ display: "none" }}>
          <Grid container spacing={{ xs: "16px", sm: "12px", xl: "16px" }}>
            <Grid item xs={12} sm={4} lg>
              <a href="-" className="logo-card bg-nsw">
                <div className="logo-card-content">
                  <img
                    src={nswLogo}
                    alt="NSW Health Webmail"
                    className="nsw-img"
                  />
                  <Typography variant="h5">NSW Health Webmail</Typography>
                </div>
              </a>
            </Grid>
            <Grid item xs={12} sm={4} lg>
              <a href="-" className="logo-card bg-mailchimp">
                <div className="logo-card-content">
                  <img src={mailchimpLogo} alt="Mailchimp" />
                  <Typography variant="h5">Mailchimp</Typography>
                </div>
              </a>
            </Grid>
            <Grid item xs={12} sm={4} lg>
              <a href="-" className="logo-card bg-dropbox">
                <div className="logo-card-content">
                  <img src={dropboxLogo} alt="Dropbox" />
                  <Typography variant="h5">Dropbox</Typography>
                </div>
              </a>
            </Grid>
            <Grid item xs={12} sm={4} lg />
            <Grid item xs={12} sm={4} lg />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: AuthState) => {
  return {
    user: getUserSelector(state),
  };
};

export default connect(mapStateToProps)(Dashboard);
