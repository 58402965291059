import { connect, MapDispatchToProps } from "react-redux";
import TermComponent, { TermProps } from "pages/Terms";
import {
  addTermRequest,
  deleteTermRequest,
  editTermRequest,
  termListRequest,
  termByIdRequest,
} from "store/term/actions";
import { ITermContainerDispatch, ITerm } from "utils/interfaces/term";

const mapDispatchToProps: MapDispatchToProps<ITermContainerDispatch, ITerm> = {
  termListRequest,
  addTermRequest,
  editTermRequest,
  deleteTermRequest,
  termByIdRequest,
};

const connector = connect(null, mapDispatchToProps);

const Terms: React.FC<TermProps> = (props) => {
  return <TermComponent {...props} />;
};

export default connector(Terms);
