import {
  nextIcon,
  prevIcon,
  prevIconDisabled,
  prevIconLight,
} from "assets/images";
import { Box, Button } from "@mui/material";

interface IFormStepButtonsProps {
  isPrevDisabled?: boolean;
  isSubmitBtn?: boolean;
  isBackBtn?: boolean;
  handleBack: () => void;
}

const FormStepButtons: React.FC<IFormStepButtonsProps> = (props) => {
  const { isPrevDisabled, isSubmitBtn, handleBack, isBackBtn } = props;

  return (
    <Box className="stepper-btn-group">
      {!isPrevDisabled && (
        <Button
          disabled={isPrevDisabled}
          onClick={handleBack}
          className={isBackBtn ? "btn-back" : "btn-prev"}
          variant="outlined"
        >
          <img src={prevIcon} alt="Previous" className="active" />
          <img src={prevIconDisabled} alt="Previous" className="disabled" />
          <img src={prevIconLight} alt="Previous" className="hovered" />
          {isBackBtn ? "Back" : "  Previous"}
        </Button>
      )}

      <Button className="btn-next" variant="contained" type="submit">
        {isSubmitBtn ? "Submit" : "Next"}
        {!isBackBtn && <img src={nextIcon} alt="Next" />}
      </Button>
    </Box>
  );
};

export default FormStepButtons;
