import { Box } from "@mui/system";
import {
  Button,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  CLOSE_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  MAX_INT_VALUE,
  SCO_BLENDED_COURSE,
} from "utils/constants/constant";
import { TermListSuccessPayload } from "store/term/types";
import { getPagePayloadValues, showLoader, toggleFilter } from "utils/helper";
import { useDispatch } from "react-redux";
import { termListRequest } from "store/term/actions";
import { OrderByOptions } from "utils/enums/sorting";
import {
  IStudentDashboardPagePayloadValues,
  IUpcomingCourseFilter,
} from "utils/interfaces/student-dashboard";
import { ITerm } from "utils/interfaces/term";
import {
  ILocation,
  ILocationPagePayloadValues,
} from "utils/interfaces/location";
import { locationListRequest } from "store/location/actions";
import { LocationListSuccessPayload } from "store/location/types";
import OverlayBox from "components/OverlayBox";
import { WHITE_SPACE_ERROR_SPACE } from "utils/constants/Messages";
import { SCOBlendedCourse } from "utils/enums/scheduled-offering";

interface IUpcomingCoursesFilterProps {
  filterBox: boolean;
  setFilterBox: any;
  handleApplyClick: any;
  filteredData?: IUpcomingCourseFilter;
  pageNo: number;
  setPageNo: any;
  setFilteredData: any;
  getUpcomingCoursesList: any;
  clearSearchText: () => void;
}

const StudentCoursesFilter: React.FC<IUpcomingCoursesFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getUpcomingCoursesList,
  clearSearchText,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IUpcomingCourseFilter>({
    defaultValues: {
      name: filteredData?.name ? filteredData?.name : "",
      locationName: filteredData?.locationName ? filteredData?.locationName : 0,
      term: filteredData?.term ? filteredData?.term : 0,
      isBlendedCourse: filteredData?.isBlendedCourse
        ? filteredData?.isBlendedCourse
        : null,
    },
  });
  const [locationList, setLocationList] = useState<ILocation[]>();
  const [termList, setTermList] = useState<ITerm[]>();
  const dispatch = useDispatch();

  const getCourseError = (): string => {
    if (errors.name) {
      if (errors.name.type === "pattern") {
        return `Course ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  useEffect(() => {
    if (filteredData) {
      setValue("name", filteredData?.name);
      setValue("term", filteredData?.term);
      setValue("locationName", filteredData?.locationName);
      setValue("isBlendedCourse", filteredData?.isBlendedCourse);
    } else {
      reset();
    }
  }, [filteredData, setValue, filterBox, reset]);

  const onClearClick = () => {
    reset();
    setFilterBox(false);
    toggleFilter();
    if (pageNo === DEFAULT_PAGE_NO) {
      getUpcomingCoursesList();
    } else {
      setPageNo(1);
    }
    setFilteredData();
    clearSearchText();
  };

  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response?.termList);
  };

  const getTermList = () => {
    showLoader();
    const values: IStudentDashboardPagePayloadValues = getPagePayloadValues(
      "name",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.term = 0;
    const payload = {
      values,
      callback: onTermListSuccess,
    };
    dispatch(termListRequest(payload));
  };

  const onLocationListSuccess = (response: LocationListSuccessPayload) => {
    setLocationList(response?.locationList);
  };

  const getLocationList = () => {
    showLoader();
    const values: ILocationPagePayloadValues = getPagePayloadValues(
      "Location",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.location = "";
    const payload = {
      values,
      callback: onLocationListSuccess,
    };
    dispatch(locationListRequest(payload));
  };

  useEffect(() => {
    if (filterBox) {
      getTermList();
      getLocationList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBox]);

  return (
    <form onSubmit={handleSubmit(handleApplyClick)}>
      {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
      <Box className="filter-popover">
        <Box className="filter-header">
          <Typography variant="h4">Filter</Typography>
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={CLOSE_TOOLTIP_TITLE}
            arrow
          >
            <IconButton
              onClick={() => {
                setFilterBox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="filter-body">
          <TextField
            id="course"
            label="Course"
            fullWidth
            error={!!errors.name}
            helperText={getCourseError()}
            variant="outlined"
            {...register("name", {
              pattern:
                INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
            })}
          />
          <FormControl fullWidth className="select">
            <InputLabel
              id="location-select-label"
              shrink={!!watch("locationName")}
            >
              Select Location
            </InputLabel>
            <Select
              labelId="location-select-label"
              id="location-select"
              label="Select Location"
              value={watch("locationName") || 0}
              {...register("locationName")}
              MenuProps={{
                className: "filter-select-menu sco-location-menu",
              }}
            >
              {locationList?.map((loc) => (
                <MenuItem key={loc.id} value={loc.id}>
                  {loc.location}
                  {loc.isBlended && " - Blended (Face To Face / Online)"}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className="select">
            <InputLabel id="term-select-label" shrink={!!watch("term")}>
              Term
            </InputLabel>
            <Select
              labelId="term-select-label"
              id="term-select"
              label="Term "
              value={watch("term") || 0}
              {...register("term")}
              MenuProps={{
                className: "filter-select-menu",
              }}
            >
              {termList?.map((term) => (
                <MenuItem key={term.id} value={term.id}>
                  {term.termName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth className="select">
            <InputLabel
              id="is-blended-course-label"
              shrink={
                watch("isBlendedCourse") === SCOBlendedCourse.No ||
                watch("isBlendedCourse") === SCOBlendedCourse.Yes
              }
            >
              Blended Course
            </InputLabel>
            <Select
              labelId="is-blended-course"
              id="is-blended-course"
              label="IsBlendedCourse"
              value={watch("isBlendedCourse")}
              {...register("isBlendedCourse")}
            >
              {SCO_BLENDED_COURSE?.map((sco) => (
                <MenuItem key={sco.value} value={sco.value}>
                  {sco.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="filter-footer">
          <Button variant="contained" className="btn-apply" type="submit">
            Apply
          </Button>
          <Button
            variant="outlined"
            className="btn-clear"
            onClick={onClearClick}
            disabled={
              !(
                !!watch("locationName") ||
                !!watch("term") ||
                watch("name") !== "" ||
                watch("isBlendedCourse") === SCOBlendedCourse.Yes ||
                watch("isBlendedCourse") === SCOBlendedCourse.No
              )
            }
          >
            Clear
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default StudentCoursesFilter;
