import { IReportPagePayloadValues } from "utils/interfaces/report";
import baseService from "services/base-service";

const reportBaseUrl = `/api/Reports`;

const getReportList = async (payload: IReportPagePayloadValues) =>
  baseService.post(`${reportBaseUrl}/GetEnrolmentReport`, payload);

const printEnrolmentReport = async (payload: IReportPagePayloadValues) =>
  baseService.post(`${reportBaseUrl}/PrintEnrolmentReport`, payload);

const getEnrolmentReportStudentDetails = async (
  payload: IReportPagePayloadValues
) =>
  baseService.post(
    `${reportBaseUrl}/PrintEnrolmentReportStudentReport`,
    payload
  );

export default {
  getReportList,
  printEnrolmentReport,
  getEnrolmentReportStudentDetails,
};
