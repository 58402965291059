import { Box } from "@mui/system";
import {
  Card,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  approvedIcon,
  removeIcon,
  courseListSecondaryIcon,
  closeIcon,
} from "assets/images";
import React, { useEffect, useState } from "react";
import projectTheme from "app.theme";
import moment from "moment";
import {
  CLEAR_TOOLTIP_TITLE,
  DATE_OF_BIRTH_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZES,
  VIEW_TOOLTIP_TITLE,
} from "utils/constants/constant";
import {
  allowSearch,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import {
  IEnhancedTableProps,
  IStudentDetailKeys,
  IStudentEnrolment,
  IStudentEnrolmentListMeta,
  IStudentEnrolmentsContainerDispatch,
  IStudentEnrolmentsContainerState,
  IStudentPagePayloadValues,
} from "utils/interfaces/student-enrolment";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  RejectEnrolmentSuccessPayload,
  StudentEnrolmentListSuccessPayload,
} from "store/studentEnrolment/types";
import NoRecords from "components/NoRecords";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { NO_EMAIL_OF_STUDENT } from "utils/constants/student-enrolment";
import { useHistory } from "react-router-dom";
import { AppRoutings } from "utils/enums/app-routings";
import { toast } from "react-toastify";
import { STUDENT_ENROLMENT_REJECTION_SUCCESS_MESSAGE } from "utils/constants/Messages";
import { useSelector } from "react-redux";
import { AppPages } from "utils/enums/app-pages";
import { IRoleModulePermission } from "store/role/types";
import RejectConfirmationModal from "./RejectConfirmationModal";
import StudentDetailsModal from "./StudentDetailsModal";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IStudentDetailKeys) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type StudentEnrolmentsProps = IStudentEnrolmentsContainerState &
  IStudentEnrolmentsContainerDispatch;

const StudentEnrolments: React.FC<StudentEnrolmentsProps> = (props) => {
  const [studentEnrolmentsList, setStudentEnrolmentsList] =
    useState<IStudentEnrolment[]>();
  const [studentEnrolmentsListMeta, setStudentEnrolmentsListMeta] =
    useState<IStudentEnrolmentListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Desc);
  const [orderBy, setOrderBy] = useState<keyof IStudentDetailKeys>("appliedOn");
  const [searchText, setSearchText] = useState<string>("");
  const [stdDetailModal, setStdDetailModal] = useState(false);
  const [stdDetailId, setStdDetailId] = useState<number>(0);
  const [rejectModal, setRejectModal] = useState(false);
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();

  const { enrolmentOptionListRequest, studentEnrolmentDetailRequest } = props;
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const handleStdDetailModalClose = () => setStdDetailModal(false);

  const handleRejectModal = (id: number) => {
    setRejectModal(true);
    setStdDetailId(id);
  };
  const handleRejectModalClose = () => setRejectModal(false);

  const handleStdDetailsModal = (id: number) => {
    setStdDetailId(id);
    setStdDetailModal(true);
  };
  const onStudentEnrolmentListSuccess = (
    response: StudentEnrolmentListSuccessPayload
  ) => {
    setStudentEnrolmentsList(response.studentEnrolmentList);
    setStudentEnrolmentsListMeta(response.studentEnrolmentListPagination);
  };

  const getStudentEnrolmentsList = (filtered?: boolean) => {
    const { studentEnrolmentListRequest } = props;
    if (studentEnrolmentListRequest) {
      showLoader();

      const values: IStudentPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.name = filtered ? "" : searchText.trim();

      const payload = {
        values,
        callback: onStudentEnrolmentListSuccess,
      };

      studentEnrolmentListRequest(payload);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.StudentEnrolment
    );
    setPagePermission(permission);
    if (permission?.view) {
      getStudentEnrolmentsList();
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const onRejectEnrolmentSuccess = (
    response: RejectEnrolmentSuccessPayload
  ) => {
    if (response.success) {
      toast.success(STUDENT_ENROLMENT_REJECTION_SUCCESS_MESSAGE);
      getStudentEnrolmentsList();
      setRejectModal(false);
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const handleRejectEnrolment = (studentId: number) => {
    const { rejectEnrolmentRequest } = props;
    if (rejectEnrolmentRequest) {
      showLoader();
      const payload = {
        values: {
          id: studentId,
        },
        callback: onRejectEnrolmentSuccess,
      };
      rejectEnrolmentRequest(payload);
    }
  };

  const handleRequestSort = React.useCallback(
    (
      event: React.MouseEvent<unknown>,
      newOrderBy: keyof IStudentDetailKeys
    ) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleStudentSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    setPageNo(1);
    getStudentEnrolmentsList(true);
  };

  const handleStudentSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getStudentEnrolmentsList();
    }
  };

  const handleRejectionClick = () => {
    handleRejectEnrolment(stdDetailId);
  };

  return (
    <>
      <>
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Student Enrolments
          </Typography>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Card>
              <Box className="table-card-header">
                <Box>
                  <TextField
                    id="search-box"
                    variant="outlined"
                    className="search-input"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleStudentSearch}
                    onKeyDown={handleStudentSearchKeyDown}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ position: "absolute", right: "0" }}
                          position="end"
                        >
                          {searchText && (
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={CLEAR_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                edge="end"
                                onClick={handleClearSearch}
                              >
                                <img src={closeIcon} alt="close" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, searchText?.length)
                    }
                  />
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "name")}
                        fieldName="Student Name"
                        keyName="name"
                        sxStyle={{ width: "153px", minWidth: "153px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) =>
                          handleRequestSort(e, "dateOfBirth")
                        }
                        fieldName="Date Of Birth"
                        keyName="dateOfBirth"
                        sxStyle={{ width: "144px", minWidth: "130px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "mobile")}
                        fieldName="Phone"
                        keyName="mobile"
                        sxStyle={{ width: "123px", minWidth: "105px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "email")}
                        fieldName="Email"
                        keyName="email"
                        sxStyle={{ width: "268px", minWidth: "216px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "appliedOn")}
                        fieldName="Enrolled On"
                        keyName="appliedOn"
                        sxStyle={{ width: "144px", minWidth: "125px" }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e) => handleRequestSort(e, "address")}
                        fieldName="Address"
                        keyName="address"
                        sxStyle={{ width: "311px", minWidth: "176px" }}
                      />
                      <TableCell
                        align="center"
                        sx={{
                          width: "152px",
                          [projectTheme.breakpoints.down("sm")]: {
                            width: "112px",
                          },
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentEnrolmentsList?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ wordWrap: "break-word" }}
                        >
                          {row.firstName} {row.lastName}
                        </TableCell>
                        <TableCell>
                          {moment(row.dateOfBirth).format("D, MMM. YYYY")}
                        </TableCell>
                        <TableCell>{row.phoneNumber}</TableCell>
                        <TableCell>
                          <Typography variant="body2" className="email">
                            {row.emailAddress === NO_EMAIL_OF_STUDENT
                              ? "-"
                              : row.emailAddress}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(row.enrolledDate).format(
                            DATE_OF_BIRTH_FORMAT
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {row.streetAddress}, {row.suburb}, {row.state}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <div className="table-actions">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Approve"
                              arrow
                            >
                              <IconButton
                                disabled={!pagePermission?.edit}
                                onClick={() =>
                                  history.push({
                                    pathname: AppRoutings.EnrollmentApproval,
                                    state: { studentId: row?.id },
                                  })
                                }
                              >
                                <img src={approvedIcon} alt="Approved" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={VIEW_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                disabled={!pagePermission?.view}
                                onClick={() => handleStdDetailsModal(row?.id)}
                              >
                                <img
                                  src={courseListSecondaryIcon}
                                  alt="Details"
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Reject"
                              arrow
                            >
                              <IconButton
                                disabled={!pagePermission?.delete}
                                onClick={() => handleRejectModal(row?.id)}
                              >
                                <img src={removeIcon} alt="Remove" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {studentEnrolmentsList && studentEnrolmentsList.length > 0 ? (
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      {PAGE_SIZES?.map((pageSize) => (
                        <MenuItem
                          key={pageSize.value}
                          value={pageSize.value}
                          selected={pageSize?.selected}
                        >
                          {pageSize.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" component="span">
                      {getPaginationDetailText(
                        studentEnrolmentsListMeta,
                        pageNo,
                        page
                      )}
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={studentEnrolmentsListMeta?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={pageNo}
                    onChange={onPageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              ) : (
                <NoRecords />
              )}
            </Card>
          </Grid>
        </Grid>
      </>

      {stdDetailModal && (
        <StudentDetailsModal
          handleStudentDetailsModalClose={handleStdDetailModalClose}
          handleApprovedClick={
            pagePermission?.edit
              ? () =>
                  history.push({
                    pathname: AppRoutings.EnrollmentApproval,
                    state: { studentId: stdDetailId },
                  })
              : undefined
          }
          studentId={stdDetailId}
          studentEnrolmentDetailRequest={studentEnrolmentDetailRequest}
          enrolmentOptionListRequest={enrolmentOptionListRequest}
        />
      )}

      <RejectConfirmationModal
        isOpenRejectConfirmationModal={rejectModal}
        handleRejectConfirmationModalClose={handleRejectModalClose}
        handleYesClick={handleRejectionClick}
      />
    </>
  );
};

export default StudentEnrolments;
