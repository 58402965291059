export const PROCEED_STEP = "PROCEED_STEP";
export const PROCEED_DATA = "PROCEED_DATA";
export const STUDENT_ENROLMENT_FIRST_NAME_MAX_LENGTH = 50;
export const STUDENT_ENROLMENT_LAST_NAME_MAX_LENGTH = 50;
export const STUDENT_ENROLMENT_PHONE_NUMBER_MAX_LENGTH = 11;
export const STUDENT_ENROLMENT_EMAIL_MAX_LENGTH = 255;
export const STUDENT_ENROLMENT_STREET_ADDRESS_MAX_LENGTH = 250;
export const STUDENT_ENROLMENT_SUBURB_MAX_LENGTH = 100;
export const STUDENT_ENROLMENT_STATE_MAX_LENGTH = 50;
export const STUDENT_ENROLMENT_POST_CODE_MAX_LENGTH = 10;

export const STUDENT_EMERGENCY_CONTACT_NAME_MAX_LENGTH = 50;
export const STUDENT_EMERGENCY_CONTACT_RELATIONSHIP_MAX_LENGTH = 50;
export const STUDENT_EMERGENCY_CONTACT_NUMBER_MAX_LENGTH = 11;
export const STUDENT_CURRENT_SERVICE_PROVIDER_MAX_LENGTH = 10;
export const STUDENT_MANAGER_NAME_MAX_LENGTH = 50;
export const STUDENT_MANAGER_EMAIL_MAX_LENGTH = 100;

export const STUDENT_ENROLMENT_CONTACT_PERSON_MAX_LENGTH = 50;
export const STUDENT_ENROLMENT_RELATIONSHIP_MAX_LENGTH = 50;
export const STUDENT_ENROLMENT_COMMENT_MAX_LENGTH = 250;
export const STUDENT_ENROLMENT_MANAGER_NAME_MAX_LENGTH = 50;
export const STUDENT_ENROLMENT_MANAGER_EMAIL_MAX_LENGTH = 100;
export const STUDENT_ENROLMENT_COB_MAX_LENGTH = 50;
export const STUDENT_ENROLMENT_LAH_MAX_LENGTH = 100;
export const STUDENT_ENROLMENT_ETHNICITY_MAX_LENGTH = 50;
export const STUDENT_ENROLMENT_STAFF_LINK_ID_MAX_LENGTH = 10;
export const MINIMUM_STUDENT_AGE = 16;

export const EXISTING_STUDENT_OPTIONS = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

export const ELIGIBILITY_CRITERIA = [
  {
    value: 1,
    label:
      "With a live experience of mental distress, or suicidal crisis and currently using the South Eastern Sydney Local Health District Mental Health Service",
  },
  {
    value: 2,
    label: "Family, carer or support person",
  },
  {
    value: 3,
    label:
      "Other residents of South Eastern Sydney Local Health District catchment area.",
  },
  {
    value: 4,
    label:
      "Staff or volunteers of the South Eastern Sydney Local Health District",
  },
  {
    value: 5,
    label: "Staff of the Recovery & Wellbeing College partner organisations.",
  },
];

export const SERVICE_PROVIDERS_OPTIONS = [
  {
    value: 1,
    label: "Option 1",
  },
  {
    value: 2,
    label: "Option 2",
  },
  {
    value: 3,
    label: "Option 3",
  },
];

export const ENROLMENT_TYPES_OPTIONS = [
  {
    value: 1,
    label: "Consumer",
  },
  {
    value: 2,
    label: "Consumer",
  },
  {
    value: 3,
    label: "Consumer",
  },
];

export const STAFF_ENROLMENT_OPTIONS = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 0,
    label: "No",
  },
];

export const EMPLOYMENT_STATUS_OPTIONS = [
  {
    value: 1,
    label: "Part-time",
  },
  {
    value: 2,
    label: "full-time",
  },
];

export const CURRENT_ROLE_OPTIONS = [
  {
    value: 1,
    label: "Staff from Partner Service",
  },
  {
    value: 2,
    label: "Staff from Partner Service",
  },
  {
    value: 3,
    label: "Staff from Partner Service",
  },
];

export const WORK_ENVIRONMENT_OPTIONS = [
  {
    value: 1,
    label: "Community",
  },
  {
    value: 2,
    label: "Community",
  },
  {
    value: 3,
    label: "Community",
  },
];

export const SITE_SERVICE_OPTIONS = [
  {
    value: 1,
    label: "STGH/St George Community",
  },
  {
    value: 2,
    label: "STGH/St George Community",
  },
  {
    value: 3,
    label: "STGH/St George Community",
  },
];

export const DUMMY_OPTIONS = [
  {
    value: 1,
    label: "Opition 1",
  },
  {
    value: 2,
    label: "Opition 1",
  },
  {
    value: 3,
    label: "Opition 1",
  },
];

export const ACTIVE_COMMUNITY_EMR_DEFAULT = "Unknown";

export const NO_EMAIL_OF_STUDENT = "EmailNotProvidedOfStudent";
