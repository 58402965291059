import {
  REPORT_LIST_REQUEST,
  REPORT_LIST_FAILURE,
  REPORT_LIST_SUCCESS,
  PRINT_ENROLMENT_REPORT_FAILURE,
  PRINT_ENROLMENT_REPORT_REQUEST,
  PRINT_ENROLMENT_REPORT_SUCCESS,
  GET_ENROLMENT_REPORT_STUDENT_DETAILS_FAILURE,
  GET_ENROLMENT_REPORT_STUDENT_DETAILS_REQUEST,
  GET_ENROLMENT_REPORT_STUDENT_DETAILS_SUCCESS,
} from "./actionTypes";
import {
  ReportListPayload,
  ReportListRequest,
  ReportListSuccess,
  ReportListSuccessPayload,
  ReportListFailure,
  ReportListFailurePayload,
  PrintEnrolmentReportFailure,
  PrintEnrolmentReportFailurePayload,
  PrintEnrolmentReportPayload,
  PrintEnrolmentReportRequest,
  PrintEnrolmentReportSuccess,
  PrintEnrolmentReportSuccessPayload,
  GetEnrolmentReportStudentDetailsFailure,
  GetEnrolmentReportStudentDetailsFailurePayload,
  GetEnrolmentReportStudentDetailsListSuccessPayload,
  GetEnrolmentReportStudentDetailsPayload,
  GetEnrolmentReportStudentDetailsRequest,
  GetEnrolmentReportStudentDetailsSuccess,
} from "./types";

export const reportListRequest = (
  payload: ReportListPayload
): ReportListRequest => ({
  type: REPORT_LIST_REQUEST,
  payload,
});

export const reportListSuccess = (
  payload: ReportListSuccessPayload
): ReportListSuccess => ({
  type: REPORT_LIST_SUCCESS,
  payload,
});

export const reportListFailure = (
  payload: ReportListFailurePayload
): ReportListFailure => ({
  type: REPORT_LIST_FAILURE,
  payload,
});

export const printEnrolmentReportRequest = (
  payload: PrintEnrolmentReportPayload
): PrintEnrolmentReportRequest => ({
  type: PRINT_ENROLMENT_REPORT_REQUEST,
  payload,
});

export const printEnrolmentReportSuccess = (
  payload: PrintEnrolmentReportSuccessPayload
): PrintEnrolmentReportSuccess => ({
  type: PRINT_ENROLMENT_REPORT_SUCCESS,
  payload,
});

export const printEnrolmentReportFailure = (
  payload: PrintEnrolmentReportFailurePayload
): PrintEnrolmentReportFailure => ({
  type: PRINT_ENROLMENT_REPORT_FAILURE,
  payload,
});

export const getEnrolmentReportStudentDetailsRequest = (
  payload: GetEnrolmentReportStudentDetailsPayload
): GetEnrolmentReportStudentDetailsRequest => ({
  type: GET_ENROLMENT_REPORT_STUDENT_DETAILS_REQUEST,
  payload,
});

export const getEnrolmentReportStudentDetailsSuccess = (
  payload: GetEnrolmentReportStudentDetailsListSuccessPayload
): GetEnrolmentReportStudentDetailsSuccess => ({
  type: GET_ENROLMENT_REPORT_STUDENT_DETAILS_SUCCESS,
  payload,
});

export const getEnrolmentReportStudentDetailsFailure = (
  payload: GetEnrolmentReportStudentDetailsFailurePayload
): GetEnrolmentReportStudentDetailsFailure => ({
  type: GET_ENROLMENT_REPORT_STUDENT_DETAILS_FAILURE,
  payload,
});
