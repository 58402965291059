import { Box } from "@mui/system";
import {
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import RadioCheckBoxIcon from "components/RadioCheckBoxIcon";
import RadioCheckedBoxIcon from "components/RadioCheckedBoxIcon";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { showLoader } from "utils/helper";
import { EnrolmentOptionListSuccessPayload } from "store/studentEnrolment/types";
import { ACTIVE_COMMUNITY_EMR_DEFAULT } from "utils/constants/student-enrolment";
import { useLocation } from "react-router-dom";
import {
  ApproveEnrolmentByEmailSMSSuccessPayload,
  GetStudentEnrolmentForConfirmationSuccessPayload,
} from "store/student/types";
import { IEnrolmentDataForConfirmation } from "utils/interfaces/student";
import { toast } from "react-toastify";
import {
  STUDENT_ENROLMENT_APPROVED,
  WAIT_LIST_MESSAGE,
} from "utils/constants/Messages";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface IEnrolmentDetailsModalProps {
  handleEnrolmentDetailsModalClose: any;
  enrolmentOptionListRequest: any;
  getStudentEnrolmentForConfirmationRequest: any;
  selectedCourseIds: Array<number>;
  approveEnrolmentByEmailSMSRequest: any;
  sendViaEmailOrSMS: string;
  handleConfirmationModalClose: any;
  setStudentDetailChange: any;
  setAttendanceHistoryChange: any;
}

const EnrolmentDetailsModal: React.FC<IEnrolmentDetailsModalProps> = ({
  handleEnrolmentDetailsModalClose,
  enrolmentOptionListRequest,
  getStudentEnrolmentForConfirmationRequest,
  approveEnrolmentByEmailSMSRequest,
  selectedCourseIds,
  sendViaEmailOrSMS,
  handleConfirmationModalClose,
  setStudentDetailChange,
  setAttendanceHistoryChange,
}) => {
  const location = useLocation<{ studentId: number }>();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<IEnrolmentDataForConfirmation>({
    defaultValues: {
      activeCommunityEMR: ACTIVE_COMMUNITY_EMR_DEFAULT,
    },
  });
  const [enrolmentOptionList, setEnrolmentOptionList] =
    useState<IEnrolmentOptions>();

  const handleModalClose = () => {
    reset();
    handleEnrolmentDetailsModalClose();
  };

  const getResearchCodeError = (): string => {
    if (errors.researchCode) {
      if (errors.researchCode.type === "required") {
        return "Research code is required";
      }
    }

    return "";
  };

  const getSectorError = (): string => {
    if (errors.sector) {
      if (errors.sector.type === "required") {
        return "Sector is required";
      }
    }

    return "";
  };

  const onEnrolmentOptionListSuccess = (
    response: EnrolmentOptionListSuccessPayload
  ) => {
    setEnrolmentOptionList(response.enrolmentOptionList);
  };

  const getEnrolmentOptionList = () => {
    if (enrolmentOptionListRequest) {
      showLoader();

      const payload = {
        values: {},
        callback: onEnrolmentOptionListSuccess,
      };

      enrolmentOptionListRequest(payload);
    }
  };

  const setFormValues = (enrolment: IEnrolmentDataForConfirmation) => {
    if (enrolment.activeCommunityEMR != null) {
      reset((formValues) => ({
        ...formValues,
        activeCommunityEmr:
          enrolment?.activeCommunityEMR === null
            ? ACTIVE_COMMUNITY_EMR_DEFAULT
            : enrolment?.activeCommunityEMR,
        sector: enrolment?.sector,
        researchCode: enrolment?.researchCode,
        mrn: enrolment?.mrn,
      }));
    }
  };

  const onStudentEnrolmentDetailSuccess = (
    response: GetStudentEnrolmentForConfirmationSuccessPayload
  ) => {
    setFormValues(response.enrolmentDataForConfirmation);
  };

  const getStudentEnrolmentDetail = () => {
    if (getStudentEnrolmentForConfirmationRequest) {
      showLoader();
      const payload = {
        values: { id: location?.state?.studentId },
        callback: onStudentEnrolmentDetailSuccess,
      };

      getStudentEnrolmentForConfirmationRequest(payload);
    }
  };

  useEffect(() => {
    getEnrolmentOptionList();
    getStudentEnrolmentDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onApproveEnrolmentByEmailSMSSuccess = (
    response: ApproveEnrolmentByEmailSMSSuccessPayload
  ) => {
    if (response.success) {
      if (
        response.WaitListedCourseList &&
        response.WaitListedCourseList.WaitListedCourseList &&
        response.WaitListedCourseList.WaitListedCourseList.length > 0
      ) {
        response.WaitListedCourseList.WaitListedCourseList.forEach((course) => {
          toast.success(WAIT_LIST_MESSAGE.WAITLIST_FULL(course));
        });
      } else {
        toast.success(STUDENT_ENROLMENT_APPROVED);
      }
      handleEnrolmentDetailsModalClose();
      handleConfirmationModalClose();
      setAttendanceHistoryChange(true);
      setStudentDetailChange(true);
    } else if (response.message) {
      toast.error(response.message);
    }
  };
  const handleApprovedClick = async (data: IEnrolmentDataForConfirmation) => {
    if (approveEnrolmentByEmailSMSRequest) {
      showLoader();
      const payload = {
        values: {
          studentId: location?.state?.studentId,
          researchCode: data.researchCode,
          mrn: data.mrn,
          sector: data.sector,
          activeCommunityEMR: data.activeCommunityEMR,
          studentCourseEnrolmentIds: selectedCourseIds,
          enrolmentConfirmationPreference: sendViaEmailOrSMS,
        },
        callback: onApproveEnrolmentByEmailSMSSuccess,
      };

      approveEnrolmentByEmailSMSRequest(payload);
    }
  };

  return (
    <Modal open onClose={handleModalClose}>
      <form onSubmit={handleSubmit(handleApprovedClick)}>
        <Box className="common-modal stdenrol-modal delete-modal">
          <Box className="modal-header">
            <Typography variant="h2">Student Enrolment</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body">
            <Grid
              container
              columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
            >
              <Grid item xs={12} sm={6} xl={6}>
                <FormControl fullWidth className="select">
                  <InputLabel id="sector-select-label">
                    Sector<span className="color-red">*</span>
                  </InputLabel>
                  <Select
                    labelId="sector-select-label"
                    id="sector-select"
                    label="Sector"
                    value={watch("sector") || ""}
                    error={!!errors.sector}
                    {...register("sector", {
                      required: true,
                    })}
                  >
                    {enrolmentOptionList?.sector?.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  {!!errors.sector && (
                    <FormHelperText error>{getSectorError()}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} xl={6}>
                <TextField
                  id="resch-code"
                  label={
                    <>
                      Research Code<span className="color-red">*</span>
                    </>
                  }
                  fullWidth
                  value={watch("researchCode") || ""}
                  variant="outlined"
                  error={!!errors.researchCode}
                  helperText={getResearchCodeError()}
                  {...register("researchCode", {
                    required: true,
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6} xl={6}>
                <TextField
                  id="mrn"
                  value={watch("mrn") || ""}
                  label="MRN (If Known)"
                  fullWidth
                  variant="outlined"
                  {...register("mrn")}
                />
              </Grid>
            </Grid>
            <Box className="stdenrol-staff">
              <Typography variant="h5">
                Active community eMR &#40;at enrolment&#41;
              </Typography>
              <Controller
                control={control}
                {...register("activeCommunityEMR")}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    {enrolmentOptionList?.activeCommunityEMR?.map((option) => (
                      <FormControlLabel
                        value={option.value}
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedBoxIcon />}
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            </Box>
          </Box>
          <Box className="modal-footer">
            <Button variant="contained" className="btn-continue" type="submit">
              Approved
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

export default EnrolmentDetailsModal;
