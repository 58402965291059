import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import locationReducer from "./location/reducer";
import roleReducer from "./role/reducer";
import termReducer from "./term/reducer";
import profileReducer from "./profile/reducer";
import courseReducer from "./course/reducer";
import educatorReducer from "./educator/reducer";
import scheduledOfferingReducer from "./scheduledOffering/reducer";
import dashboardReducer from "./dashboard/reducer";
import educatorHomeReducer from "./educatorHome/reducer";
import studentEnrolmentReducer from "./studentEnrolment/reducer";
import studentReducer from "./student/reducer";
import userReducer from "./user/reducer";
import documentReducer from "./document/reducer";
import studentDashboardReducer from "./studentDashboard/reducer";
import reportReducer from "./report/reducer";
import staffReportReducer from "./staffReport/reducer";
import courseReportReducer from "./courseReport/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  location: locationReducer,
  role: roleReducer,
  term: termReducer,
  profile: profileReducer,
  course: courseReducer,
  educator: educatorReducer,
  scheduledOffering: scheduledOfferingReducer,
  dashboard: dashboardReducer,
  educatorHome: educatorHomeReducer,
  studentEnrolment: studentEnrolmentReducer,
  student: studentReducer,
  user: userReducer,
  document: documentReducer,
  studentDashboard: studentDashboardReducer,
  report: reportReducer,
  staffReport: staffReportReducer,
  courseReport: courseReportReducer,
});

export type AuthState = ReturnType<typeof rootReducer>;
export type LocationState = ReturnType<typeof rootReducer>;
export type RoleState = ReturnType<typeof rootReducer>;
export type TermState = ReturnType<typeof rootReducer>;
export type ProfileState = ReturnType<typeof rootReducer>;
export type CourseState = ReturnType<typeof rootReducer>;
export type EducatorState = ReturnType<typeof rootReducer>;
export type ScheduledOfferingState = ReturnType<typeof rootReducer>;
export type DashboardState = ReturnType<typeof rootReducer>;
export type EducatorHomeState = ReturnType<typeof rootReducer>;
export type StudentEnrolmentState = ReturnType<typeof rootReducer>;
export type StudentState = ReturnType<typeof rootReducer>;
export type UserState = ReturnType<typeof rootReducer>;
export type DocumentState = ReturnType<typeof rootReducer>;
export type StudentDashboardState = ReturnType<typeof rootReducer>;
export type ReportState = ReturnType<typeof rootReducer>;
export type StaffReportState = ReturnType<typeof rootReducer>;
export type CourseReportState = ReturnType<typeof rootReducer>;

export default rootReducer;
