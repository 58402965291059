import {
  COURSE_REPORT_LIST_REQUEST,
  COURSE_REPORT_LIST_SUCCESS,
  COURSE_REPORT_LIST_FAILURE,
  PRINT_COURSE_REPORT_FAILURE,
  PRINT_COURSE_REPORT_REQUEST,
  PRINT_COURSE_REPORT_SUCCESS,
} from "./actionTypes";
import { CourseReportState, CourseReportActions } from "./types";

const initialState: CourseReportState = {
  pending: false,
  courseReportList: [],
  error: null,
};

const reducers = (
  state = initialState,
  action: CourseReportActions
): unknown => {
  switch (action.type) {
    case COURSE_REPORT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case COURSE_REPORT_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        courseReportList: action.payload.courseReportList,
        error: null,
      };
    case COURSE_REPORT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case PRINT_COURSE_REPORT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PRINT_COURSE_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        item: action.payload.item,
        error: null,
      };
    case PRINT_COURSE_REPORT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
