import { connect, MapDispatchToProps } from "react-redux";
import StudentDetailsComponent, {
  StudentDetailsProps,
} from "pages/Students/StudentDetails";
import {
  IStudentDetails,
  IStudentDetailsContainerDispatch,
} from "utils/interfaces/student";
import {
  approveEnrolmentByEmailSMSRequest,
  confirmationPreferenceRequest,
  deleteStudentRequest,
  editConfirmationPreferenceRequest,
  editMediaConsentRequest,
  editResearchProfileDetailRequest,
  editStaffDetailRequest,
  editStudentDetailRequest,
  editstudentNoteRequest,
  enrollmentAndAttendanceHistoryListRequest,
  getPendingEnrolmentListRequest,
  getStudentEnrolmentForConfirmationRequest,
  mediaConsentRequest,
  researchProfileDetailsRequest,
  staffDetailsRequest,
  studentDetailsRequest,
  studnetNoteRequest,
  getCurrentCoursesForAddEnrolmentRequest,
  addNewEnrolmentFromStudentRequest,
  reSendConfirmationMailRequest,
} from "store/student/action";
import { enrolmentOptionListRequest } from "store/studentEnrolment/actions";
import { changeCoursePreferenceRequest } from "store/studentDashboard/actions";

const mapDispatchToProps: MapDispatchToProps<
  IStudentDetailsContainerDispatch,
  IStudentDetails
> = {
  studnetNoteRequest,
  editstudentNoteRequest,
  confirmationPreferenceRequest,
  editConfirmationPreferenceRequest,
  studentDetailsRequest,
  editStudentDetailRequest,
  researchProfileDetailsRequest,
  editResearchProfileDetailRequest,
  staffDetailsRequest,
  editStaffDetailRequest,
  mediaConsentRequest,
  editMediaConsentRequest,
  enrolmentOptionListRequest,
  enrollmentAndAttendanceHistoryListRequest,
  deleteStudentRequest,
  getPendingEnrolmentListRequest,
  getStudentEnrolmentForConfirmationRequest,
  approveEnrolmentByEmailSMSRequest,
  getCurrentCoursesForAddEnrolmentRequest,
  addNewEnrolmentFromStudentRequest,
  reSendConfirmationMailRequest,
  changeCoursePreferenceRequest,
};

const connector = connect(null, mapDispatchToProps);
const StudentDetails: React.FC<StudentDetailsProps> = (props) => {
  return <StudentDetailsComponent {...props} />;
};

export default connector(StudentDetails);
