import {
  ROLE_LIST_REQUEST,
  ROLE_LIST_FAILURE,
  ROLE_LIST_SUCCESS,
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  EDIT_ROLE_REQUEST,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  ROLE_PERMISSIONS_REQUEST,
  ROLE_PERMISSIONS_FAILURE,
  ROLE_PERMISSIONS_SUCCESS,
  MODULE_LIST_REQUEST,
  MODULE_LIST_SUCCESS,
  MODULE_LIST_FAILURE,
} from "./actionTypes";
import {
  RoleListPayload,
  RoleListRequest,
  RoleListSuccess,
  RoleListSuccessPayload,
  RoleListFailure,
  RoleListFailurePayload,
  DeleteRolePayload,
  DeleteRoleRequest,
  DeleteRoleSuccessPayload,
  DeleteRoleSuccess,
  DeleteRoleFailurePayload,
  DeleteRoleFailure,
  AddEditRolePayload,
  AddRoleRequest,
  AddEditRoleSuccessPayload,
  AddRoleSuccess,
  AddEditRoleFailurePayload,
  AddRoleFailure,
  EditRoleSuccess,
  EditRoleRequest,
  EditRoleFailure,
  RolePermissionsPayload,
  RolePermissionsRequest,
  RolePermissionsSuccessPayload,
  RolePermissionsSuccess,
  RolePermissionsFailurePayload,
  RolePermissionsFailure,
  ModuleListPayload,
  ModuleListRequest,
  ModuleListSuccessPayload,
  ModuleListSuccess,
  ModuleListFailurePayload,
  ModuleListFailure,
} from "./types";

export const roleListRequest = (payload: RoleListPayload): RoleListRequest => ({
  type: ROLE_LIST_REQUEST,
  payload,
});

export const roleListSuccess = (
  payload: RoleListSuccessPayload
): RoleListSuccess => ({
  type: ROLE_LIST_SUCCESS,
  payload,
});

export const roleListFailure = (
  payload: RoleListFailurePayload
): RoleListFailure => ({
  type: ROLE_LIST_FAILURE,
  payload,
});

export const addRoleRequest = (
  payload: AddEditRolePayload
): AddRoleRequest => ({
  type: ADD_ROLE_REQUEST,
  payload,
});

export const addRoleSuccess = (
  payload: AddEditRoleSuccessPayload
): AddRoleSuccess => ({
  type: ADD_ROLE_SUCCESS,
  payload,
});

export const addRoleFailure = (
  payload: AddEditRoleFailurePayload
): AddRoleFailure => ({
  type: ADD_ROLE_FAILURE,
  payload,
});

export const editRoleRequest = (
  payload: AddEditRolePayload
): EditRoleRequest => ({
  type: EDIT_ROLE_REQUEST,
  payload,
});

export const editRoleSuccess = (
  payload: AddEditRoleSuccessPayload
): EditRoleSuccess => ({
  type: EDIT_ROLE_SUCCESS,
  payload,
});

export const editRoleFailure = (
  payload: AddEditRoleFailurePayload
): EditRoleFailure => ({
  type: EDIT_ROLE_FAILURE,
  payload,
});

export const deleteRoleRequest = (
  payload: DeleteRolePayload
): DeleteRoleRequest => ({
  type: DELETE_ROLE_REQUEST,
  payload,
});

export const deleteRoleSuccess = (
  payload: DeleteRoleSuccessPayload
): DeleteRoleSuccess => ({
  type: DELETE_ROLE_SUCCESS,
  payload,
});

export const deleteRoleFailure = (
  payload: DeleteRoleFailurePayload
): DeleteRoleFailure => ({
  type: DELETE_ROLE_FAILURE,
  payload,
});

export const rolePermissionsRequest = (
  payload: RolePermissionsPayload
): RolePermissionsRequest => ({
  type: ROLE_PERMISSIONS_REQUEST,
  payload,
});

export const rolePermissionsSuccess = (
  payload: RolePermissionsSuccessPayload
): RolePermissionsSuccess => ({
  type: ROLE_PERMISSIONS_SUCCESS,
  payload,
});

export const rolePermissionsFailure = (
  payload: RolePermissionsFailurePayload
): RolePermissionsFailure => ({
  type: ROLE_PERMISSIONS_FAILURE,
  payload,
});

export const moduleListRequest = (
  payload: ModuleListPayload
): ModuleListRequest => ({
  type: MODULE_LIST_REQUEST,
  payload,
});

export const moduleListSuccess = (
  payload: ModuleListSuccessPayload
): ModuleListSuccess => ({
  type: MODULE_LIST_SUCCESS,
  payload,
});

export const moduleListFailure = (
  payload: ModuleListFailurePayload
): ModuleListFailure => ({
  type: MODULE_LIST_FAILURE,
  payload,
});
