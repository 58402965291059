import {
  CURRENT_COURSE_LIST_REQUEST,
  CURRENT_COURSE_LIST_SUCCESS,
  CURRENT_COURSE_LIST_FAILURE,
  PAST_COURSE_LIST_REQUEST,
  PAST_COURSE_LIST_SUCCESS,
  PAST_COURSE_LIST_FAILURE,
  SCHEDULED_OFFERING_DETAILS_REQUEST,
  SCHEDULED_OFFERING_DETAILS_FAILURE,
  SCHEDULED_OFFERING_DETAILS_SUCCESS,
  ADD_COURSE_REFLECTION_FORM_REQUEST,
  ADD_COURSE_REFLECTION_FORM_SUCCESS,
  ADD_COURSE_REFLECTION_FORM_FAILURE,
  EDIT_COURSE_REFLECTION_FORM_REQUEST,
  EDIT_COURSE_REFLECTION_FORM_SUCCESS,
  EDIT_COURSE_REFLECTION_FORM_FAILURE,
  ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_REQUEST,
  ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_SUCCESS,
  ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_FAILURE,
  MISSING_ATTENDANCE_COURSE_LIST_REQUEST,
  MISSING_ATTENDANCE_COURSE_LIST_SUCCESS,
  MISSING_ATTENDANCE_COURSE_LIST_FAILURE,
} from "./actionTypes";
import {
  CurrentCourseListPayload,
  CurrentCourseListRequest,
  CurrentCourseListSuccess,
  CurrentCourseListSuccessPayload,
  CurrentCourseListFailure,
  CurrentCourseListFailurePayload,
  PastCourseListPayload,
  PastCourseListRequest,
  PastCourseListSuccess,
  PastCourseListSuccessPayload,
  PastCourseListFailure,
  PastCourseListFailurePayload,
  ScheduledOfferingDetailsPayload,
  ScheduledOfferingDetailsRequest,
  ScheduledOfferingDetailsSuccessPayload,
  ScheduledOfferingDetailsSuccess,
  ScheduledOfferingDetailsFailurePayload,
  ScheduledOfferingDetailsFailure,
  AddEditCourseReflectionFormPayload,
  AddCourseReflectionFormRequest,
  AddEditCourseReflectionFormSuccessPayload,
  AddCourseReflectionFormSuccess,
  AddEditCourseReflectionFormFailurePayload,
  AddCourseReflectionFormFailure,
  EditCourseReflectionFormRequest,
  EditCourseReflectionFormSuccess,
  EditCourseReflectionFormFailure,
  EnroledStudentListForEducatorHomePayload,
  EnroledStudentListForEducatorHomeSuccessPayload,
  EnroledStudentListForEducatorHomeRequest,
  EnroledStudentListForEducatorHomeSuccess,
  EnroledStudentListForEducatorHomeFailurePayload,
  EnroledStudentListForEducatorHomeFailure,
  MissingAttendanceCourseListPayload,
  MissingAttendanceCourseListRequest,
  MissingAttendanceCourseListSuccessPayload,
  MissingAttendanceCourseListSuccess,
  MissingAttendanceCourseListFailure,
  MissingAttendanceCourseListFailurePayload,
} from "./types";

export const currentCourseListRequest = (
  payload: CurrentCourseListPayload
): CurrentCourseListRequest => ({
  type: CURRENT_COURSE_LIST_REQUEST,
  payload,
});

export const currentCourseListSuccess = (
  payload: CurrentCourseListSuccessPayload
): CurrentCourseListSuccess => ({
  type: CURRENT_COURSE_LIST_SUCCESS,
  payload,
});

export const currentCourseListFailure = (
  payload: CurrentCourseListFailurePayload
): CurrentCourseListFailure => ({
  type: CURRENT_COURSE_LIST_FAILURE,
  payload,
});

export const pastCourseListRequest = (
  payload: PastCourseListPayload
): PastCourseListRequest => ({
  type: PAST_COURSE_LIST_REQUEST,
  payload,
});

export const pastCourseListSuccess = (
  payload: PastCourseListSuccessPayload
): PastCourseListSuccess => ({
  type: PAST_COURSE_LIST_SUCCESS,
  payload,
});

export const pastCourseListFailure = (
  payload: PastCourseListFailurePayload
): PastCourseListFailure => ({
  type: PAST_COURSE_LIST_FAILURE,
  payload,
});

export const scheduledOfferingDetailsRequest = (
  payload: ScheduledOfferingDetailsPayload
): ScheduledOfferingDetailsRequest => ({
  type: SCHEDULED_OFFERING_DETAILS_REQUEST,
  payload,
});

export const scheduledOfferingDetailsSuccess = (
  payload: ScheduledOfferingDetailsSuccessPayload
): ScheduledOfferingDetailsSuccess => ({
  type: SCHEDULED_OFFERING_DETAILS_SUCCESS,
  payload,
});

export const scheduledOfferingDetailsFailure = (
  payload: ScheduledOfferingDetailsFailurePayload
): ScheduledOfferingDetailsFailure => ({
  type: SCHEDULED_OFFERING_DETAILS_FAILURE,
  payload,
});

export const addCourseReflectionFormRequest = (
  payload: AddEditCourseReflectionFormPayload
): AddCourseReflectionFormRequest => ({
  type: ADD_COURSE_REFLECTION_FORM_REQUEST,
  payload,
});

export const addCourseReflectionFormSuccess = (
  payload: AddEditCourseReflectionFormSuccessPayload
): AddCourseReflectionFormSuccess => ({
  type: ADD_COURSE_REFLECTION_FORM_SUCCESS,
  payload,
});

export const addCourseReflectionFormFailure = (
  payload: AddEditCourseReflectionFormFailurePayload
): AddCourseReflectionFormFailure => ({
  type: ADD_COURSE_REFLECTION_FORM_FAILURE,
  payload,
});

export const editCourseReflectionFormRequest = (
  payload: AddEditCourseReflectionFormPayload
): EditCourseReflectionFormRequest => ({
  type: EDIT_COURSE_REFLECTION_FORM_REQUEST,
  payload,
});

export const editCourseReflectionFormSuccess = (
  payload: AddEditCourseReflectionFormSuccessPayload
): EditCourseReflectionFormSuccess => ({
  type: EDIT_COURSE_REFLECTION_FORM_SUCCESS,
  payload,
});

export const editCourseReflectionFormFailure = (
  payload: AddEditCourseReflectionFormFailurePayload
): EditCourseReflectionFormFailure => ({
  type: EDIT_COURSE_REFLECTION_FORM_FAILURE,
  payload,
});

export const enroledStudentListForEducatorHomeRequest = (
  payload: EnroledStudentListForEducatorHomePayload
): EnroledStudentListForEducatorHomeRequest => ({
  type: ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_REQUEST,
  payload,
});

export const enroledStudentListForEducatorHomeSuccess = (
  payload: EnroledStudentListForEducatorHomeSuccessPayload
): EnroledStudentListForEducatorHomeSuccess => ({
  type: ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_SUCCESS,
  payload,
});

export const enroledStudentListForEducatorHomeFailure = (
  payload: EnroledStudentListForEducatorHomeFailurePayload
): EnroledStudentListForEducatorHomeFailure => ({
  type: ENROLED_STUDENT_LIST_FOR_EDUCATOR_HOME_FAILURE,
  payload,
});

export const missingAttendanceCourseListRequest = (
  payload: MissingAttendanceCourseListPayload
): MissingAttendanceCourseListRequest => ({
  type: MISSING_ATTENDANCE_COURSE_LIST_REQUEST,
  payload,
});

export const missingAttendanceCourseListSuccess = (
  payload: MissingAttendanceCourseListSuccessPayload
): MissingAttendanceCourseListSuccess => ({
  type: MISSING_ATTENDANCE_COURSE_LIST_SUCCESS,
  payload,
});

export const missingAttendanceCourseListFailure = (
  payload: MissingAttendanceCourseListFailurePayload
): MissingAttendanceCourseListFailure => ({
  type: MISSING_ATTENDANCE_COURSE_LIST_FAILURE,
  payload,
});
