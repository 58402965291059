/* eslint-disable no-console */
import {
  INTERNAL_SERVER_ERROR,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED,
} from "utils/constants/constant";
import { HttpStatusCodes } from "utils/enums/http-status-codes";
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import Config, { NODE_ENV_TYPES } from "config/index";
import { getCookie, hideLoader, hideLoaderForBatchApiCall } from "utils/helper";
import { toast } from "react-toastify";
import useToast from "hooks/useToast";
import { AppRoutings } from "utils/enums/app-routings";

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    let isTokenRequired = true;
    if (config.url?.includes(AppRoutings.LogIn)) {
      isTokenRequired = false;
    }
    if (isTokenRequired === true) {
      const token = getCookie("auth_token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    if (config.headers.contentType) {
      config.headers["Content-Type"] = config.headers.contentType;
    }

    if (config.url) {
      config.url = Config.env.BaseUrl + config.url;
    }

    if (config.url) {
      config.headers["Cache-Control"] =
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0";
      config.headers.Pragma = "no-cache";
      config.headers.Expires = "0";
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    hideLoader();

    return response.data;
  },
  (error: AxiosError) => {
    const { errorToast } = useToast();
    switch (error.response?.status) {
      case HttpStatusCodes.Unauthorized:
        toast.error(UNAUTHORIZED);
        window.location.reload();
        break;
      case HttpStatusCodes.BadRequest:
        if (error?.config?.url?.includes("CourseOffering")) {
          errorToast(
            error.response?.data
              ? error.response?.data?.toString()
              : error.message
          );
        } else {
          toast.error(
            error.response?.data
              ? error.response?.data?.toString()
              : error.message
          );
        }
        break;
      case HttpStatusCodes.InternalServerError:
        if (Config.env.NodeEnv === NODE_ENV_TYPES.Development) {
          toast.error(INTERNAL_SERVER_ERROR);
        } else {
          toast.error(SOMETHING_WENT_WRONG);
        }
        break;
      case HttpStatusCodes.NotFound:
        toast.error(
          error.response?.data
            ? error.response?.data?.toString()
            : error.message
        );
        break;
      default:
        toast.error(
          error.response?.data
            ? error.response?.data?.toString()
            : error.message
        );
        break;
    }

    hideLoader();
    hideLoaderForBatchApiCall();

    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
