import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  addCourseFailure,
  addCourseSuccess,
  deleteCourseFailure,
  deleteCourseSuccess,
  editCourseFailure,
  editCourseSuccess,
  courseListFailure,
  courseListSuccess,
  courseByIdSuccess,
  courseByIdFailure,
} from "./actions";
import {
  COURSE_LIST_REQUEST,
  ADD_COURSE_REQUEST,
  EDIT_COURSE_REQUEST,
  DELETE_COURSE_REQUEST,
  COURSE_BY_ID_REQUEST,
} from "./actionTypes";
import courseService from "./service";
import {
  AddEditCourseResponse,
  DeleteCourseResponse,
  CourseListResponse,
  CourseByIdResponse,
} from "./types";

function* courseListSaga(action: any) {
  try {
    const payload: any = {
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
      name: action.payload.values.name,
    };
    if (action.payload.values.courseNo) {
      payload.courseNo = action.payload.values.courseNo;
    }
    if (action.payload.values.stream) {
      payload.stream = action.payload.values.stream;
    }

    const response: CourseListResponse = yield call(
      courseService.getCourseList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      courseListSuccess({
        courseList: response.item.records,
        courseListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      courseList: response.item.records,
      courseListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      courseListFailure({
        error: e.message,
      })
    );
  }
}

function* addCourseSaga(action: any) {
  try {
    const response: AddEditCourseResponse = yield call(
      courseService.addCourse,
      {
        name: action.payload.values.name,
        courseNo: action.payload.values.courseNo,
        stream: action.payload.values.stream,
        previousName: action.payload.values.previousName,
        skills: action.payload.values.skills,
        knowledge: action.payload.values.knowledge,
        attitudes: action.payload.values.attitudes,
        courseAvailableInThisTerm:
          action.payload.values.courseAvailableInThisTerm,
        noOfSession: action.payload.values.noOfSession,
        lengthOfSingleSession: action.payload.values.lengthOfSingleSession,
        description: action.payload.values.description,
      }
    );

    yield put(
      addCourseSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      addCourseFailure({
        error: e.message,
      })
    );
  }
}

function* editCourseSaga(action: any) {
  try {
    const response: AddEditCourseResponse = yield call(
      courseService.editCourse,
      {
        id: action.payload.values.id,
        name: action.payload.values.name,
        courseNo: action.payload.values.courseNo,
        stream: action.payload.values.stream,
        previousName: action.payload.values.previousName,
        skills: action.payload.values.skills,
        knowledge: action.payload.values.knowledge,
        attitudes: action.payload.values.attitudes,
        courseAvailableInThisTerm:
          action.payload.values.courseAvailableInThisTerm,
        noOfSession: action.payload.values.noOfSession,
        lengthOfSingleSession: action.payload.values.lengthOfSingleSession,
        description: action.payload.values.description,
      }
    );

    yield put(
      editCourseSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      editCourseFailure({
        error: e.message,
      })
    );
  }
}

function* deleteCourseSaga(action: any) {
  try {
    const response: DeleteCourseResponse = yield call(
      courseService.deleteCourse,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      deleteCourseSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      deleteCourseFailure({
        error: e.message,
      })
    );
  }
}

function* courseByIdSaga(action: any) {
  try {
    const response: CourseByIdResponse = yield call(
      courseService.getCourseById,
      {
        id: action.payload.values.id,
      }
    );

    yield put(
      courseByIdSuccess({
        courseDetails: response.item,
      })
    );
    action.payload.callback({
      courseDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      courseByIdFailure({
        error: e.message,
      })
    );
  }
}

function* courseSaga() {
  yield all([takeLatest(COURSE_LIST_REQUEST, courseListSaga)]);
  yield all([takeLatest(ADD_COURSE_REQUEST, addCourseSaga)]);
  yield all([takeLatest(EDIT_COURSE_REQUEST, editCourseSaga)]);
  yield all([takeLatest(DELETE_COURSE_REQUEST, deleteCourseSaga)]);
  yield all([takeLatest(COURSE_BY_ID_REQUEST, courseByIdSaga)]);
}

export default courseSaga;
