import { all, call, put, takeLatest } from "redux-saga/effects";
import { SCOBlendedCourse } from "utils/enums/scheduled-offering";
import {
  APPLY_OR_WITHDRAW_COURSE_REQUEST,
  CHANGE_COURSE_PREFERENCE_REQUEST,
  COURSE_OFFERING_LIST_REQUEST,
  DOWNLOAD_CERTIFICATE_REQUEST,
  MY_ENROLMENT_LIST_REQUEST,
  PAST_STUDENT_COURSE_LIST_REQUEST,
  STUDENT_DASHBOARD_COURSE_DETAILS_REQUEST,
  STUDENT_DOCUMENTS_REQUEST,
  UPCOMING_COURSE_LIST_REQUEST,
} from "./actionTypes";
import studentDashboardService from "./service";
import {
  ApplyOrWithdrawCourseRequest,
  ApplyOrWithdrawCourseResponse,
  ChangeCoursePreferenceResponse,
  CourseOfferingListResponse,
  DownloadCertificateResponse,
  MyEnrolmentListResponse,
  PastCourseListResponse,
  StudentDashboardCourseDetailByIdResponse,
  StudentDocumentsResponse,
  UpcomingCourseListResponse,
} from "./types";
import {
  applyOrWithdrawCourseFailure,
  applyOrWithdrawCourseSuccess,
  changeCoursePreferenceFailure,
  changeCoursePreferenceSuccess,
  courseOfferingListFailure,
  courseOfferingListSuccess,
  downloadCertificateFailure,
  downloadCertificateSuccess,
  myEnrolmentListSuccess,
  pastCourseListFailure,
  pastCourseListSuccess,
  studentDashboardCourseDetailByIdFailure,
  studentDashboardCourseDetailByIdSuccess,
  studentDocumentsFailure,
  studentDocumentsSuccess,
  upcomingCourseListFailure,
  upcomingCourseListSuccess,
} from "./actions";

function* upcomingCourseListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };
    if (action.payload.values.name) {
      payload.name = action.payload.values.name;
    }

    if (action.payload.values.term) {
      payload.term = action.payload.values.term;
    }

    if (action.payload.values.locationName) {
      payload.locationName = action.payload.values.locationName;
    }

    if (
      action.payload.values.isBlendedCourse === SCOBlendedCourse.No ||
      action.payload.values.isBlendedCourse === SCOBlendedCourse.Yes
    ) {
      payload.isBlendedCourse = Boolean(action.payload.values.isBlendedCourse);
    }

    const response: UpcomingCourseListResponse = yield call(
      studentDashboardService.getUpcomingCourseList,
      payload
    );
    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };
    yield put(
      upcomingCourseListSuccess({
        upcomingCourseList: response.item.records,
        upcomingListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      upcomingCourseList: response.item.records,
      upcomingListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      upcomingCourseListFailure({
        error: e.message,
      })
    );
  }
}

function* pastCourseListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };

    if (action.payload.values.name) {
      payload.name = action.payload.values.name;
    }

    if (action.payload.values.term) {
      payload.term = action.payload.values.term;
    }

    if (action.payload.values.locationName) {
      payload.locationName = action.payload.values.locationName;
    }

    if (
      action.payload.values.isBlendedCourse === SCOBlendedCourse.No ||
      action.payload.values.isBlendedCourse === SCOBlendedCourse.Yes
    ) {
      payload.isBlendedCourse = Boolean(action.payload.values.isBlendedCourse);
    }

    const response: PastCourseListResponse = yield call(
      studentDashboardService.getPastCourseList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      pastCourseListSuccess({
        pastCourseList: response.item.records,
        pastCourseListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      pastCourseList: response.item.records,
      pastCourseListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      pastCourseListFailure({
        error: e.message,
      })
    );
  }
}

function* courseOfferingListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };

    if (action.payload.values.name) {
      payload.name = action.payload.values.name;
    }

    if (action.payload.values.term) {
      payload.term = action.payload.values.term;
    }

    if (action.payload.values.locationName) {
      payload.locationName = action.payload.values.locationName;
    }

    if (
      action.payload.values.isBlendedCourse === SCOBlendedCourse.No ||
      action.payload.values.isBlendedCourse === SCOBlendedCourse.Yes
    ) {
      payload.isBlendedCourse = Boolean(action.payload.values.isBlendedCourse);
    }

    const response: CourseOfferingListResponse = yield call(
      studentDashboardService.getCourseOfferingList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      courseOfferingListSuccess({
        courseOfferingList: response.item.records,
        courseOfferingListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      courseOfferingList: response.item.records,
      courseOfferingListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      courseOfferingListFailure({
        error: e.message,
      })
    );
  }
}

function* studentDashboardCourseDetailsSaga(action: any) {
  try {
    const response: StudentDashboardCourseDetailByIdResponse = yield call(
      studentDashboardService.getStudentDashboardCourseDetail,
      {
        courseOfferingId: action.payload.values.courseOfferingId,
        studentId: action.payload.values.studentId,
      }
    );

    yield put(
      studentDashboardCourseDetailByIdSuccess({
        courseOfferingDetails: response.item,
      })
    );
    action.payload.callback({
      courseOfferingDetails: response.item,
    });
  } catch (e: any) {
    yield put(
      studentDashboardCourseDetailByIdFailure({
        error: e.message,
      })
    );
  }
}

function* applyCourseSaga(action: ApplyOrWithdrawCourseRequest) {
  try {
    const response: ApplyOrWithdrawCourseResponse = yield call(
      studentDashboardService.applyCourse,
      {
        courseOfferingId: action.payload.values.courseOfferingId,
        studentId: action.payload.values.studentId,
        preferredCourseDelivery: action.payload.values.preferredCourseDelivery,
        isWithdraw: action.payload.values.isWithdraw,
        studentCourseEnrolmentId:
          action.payload.values.studentCourseEnrolmentId,
      }
    );

    yield put(
      applyOrWithdrawCourseSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      applyOrWithdrawCourseFailure({
        error: e.message,
      })
    );
  }
}

function* myEnrolmentListSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
      sortColumn: action.payload.values.sortColumn,
      sortBy: action.payload.values.sortBy,
      pageNo: action.payload.values.pageNo,
      pageSize: action.payload.values.pageSize,
    };

    if (action.payload.values.name) {
      payload.name = action.payload.values.name;
    }

    if (action.payload.values.term) {
      payload.term = action.payload.values.term;
    }

    if (action.payload.values.locationName) {
      payload.locationName = action.payload.values.locationName;
    }

    if (action.payload.values.status != null) {
      payload.status = action.payload.values.status;
    }

    const response: MyEnrolmentListResponse = yield call(
      studentDashboardService.getMyEnrolmentList,
      payload
    );

    const paginationDetails = {
      nextPage: response.item.nextPage,
      prevPage: response.item.prevPage,
      totalCount: response.item.totalRecordCount,
      totalPages: response.item.pageCount,
    };

    yield put(
      myEnrolmentListSuccess({
        myEnrolmentList: response.item.records,
        myEnrolmentListPagination: paginationDetails,
      })
    );
    action.payload.callback({
      myEnrolmentList: response.item.records,
      myEnrolmentListPagination: paginationDetails,
    });
  } catch (e: any) {
    yield put(
      courseOfferingListFailure({
        error: e.message,
      })
    );
  }
}

function* downloadCertificateSaga(action: any) {
  try {
    const payload: any = {
      id: action.payload.values.id,
    };

    const response: DownloadCertificateResponse = yield call(
      studentDashboardService.downloadCertificate,
      payload
    );

    yield put(
      downloadCertificateSuccess({
        item: response.item,
      })
    );
    action.payload.callback({
      item: response.item,
    });
  } catch (e: any) {
    yield put(
      downloadCertificateFailure({
        error: e.message,
      })
    );
  }
}
function* changeCoursePreferenceSaga(action: any) {
  try {
    const response: ChangeCoursePreferenceResponse = yield call(
      studentDashboardService.ChangeCoursePreference,
      {
        courseOfferingId: action.payload.values.courseOfferingId,
        studentId: action.payload.values.studentId,
        preferredCourseDelivery: action.payload.values.preferredCourseDelivery,
      }
    );

    yield put(
      changeCoursePreferenceSuccess({
        success: response.isSuccessStatusCode,
        message: response.message,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
      message: response.message,
    });
  } catch (e: any) {
    yield put(
      changeCoursePreferenceFailure({
        error: e.message,
      })
    );
  }
}

function* studentDocumentsSaga(action: any) {
  try {
    const response: StudentDocumentsResponse = yield call(
      studentDashboardService.getStudentDocuments,
      {
        type: action.payload.values.type,
      }
    );
    yield put(
      studentDocumentsSuccess({
        document: response.item,
      })
    );
    action.payload.callback({
      document: response.item,
    });
  } catch (e: any) {
    yield put(
      studentDocumentsFailure({
        error: e.message,
      })
    );
  }
}

function* educatorHomeSaga() {
  yield all([takeLatest(UPCOMING_COURSE_LIST_REQUEST, upcomingCourseListSaga)]);
  yield all([takeLatest(PAST_STUDENT_COURSE_LIST_REQUEST, pastCourseListSaga)]);
  yield all([takeLatest(COURSE_OFFERING_LIST_REQUEST, courseOfferingListSaga)]);
  yield all([
    takeLatest(
      STUDENT_DASHBOARD_COURSE_DETAILS_REQUEST,
      studentDashboardCourseDetailsSaga
    ),
  ]);
  yield all([takeLatest(APPLY_OR_WITHDRAW_COURSE_REQUEST, applyCourseSaga)]);
  yield all([takeLatest(MY_ENROLMENT_LIST_REQUEST, myEnrolmentListSaga)]);
  yield all([
    takeLatest(DOWNLOAD_CERTIFICATE_REQUEST, downloadCertificateSaga),
  ]);
  yield all([
    takeLatest(CHANGE_COURSE_PREFERENCE_REQUEST, changeCoursePreferenceSaga),
  ]);
  yield all([takeLatest(STUDENT_DOCUMENTS_REQUEST, studentDocumentsSaga)]);
}

export default educatorHomeSaga;
