import { connect, MapDispatchToProps } from "react-redux";
import AddEditRoleComponent, {
  AddEditRoleProps,
} from "pages/Roles/AddEditRole";
import {
  addRoleRequest,
  deleteRoleRequest,
  editRoleRequest,
  moduleListRequest,
  roleListRequest,
  rolePermissionsRequest,
} from "store/role/actions";
import { IRoleContainerDispatch, IRole } from "utils/interfaces/role";

const mapDispatchToProps: MapDispatchToProps<IRoleContainerDispatch, IRole> = {
  roleListRequest,
  addRoleRequest,
  editRoleRequest,
  deleteRoleRequest,
  rolePermissionsRequest,
  moduleListRequest,
};

const connector = connect(null, mapDispatchToProps);

const AddEditRole: React.FC<AddEditRoleProps> = (props) => {
  return <AddEditRoleComponent {...props} />;
};

export default connector(AddEditRole);
