import {
  SCHEDULED_OFFERING_LIST_REQUEST,
  SCHEDULED_OFFERING_LIST_SUCCESS,
  SCHEDULED_OFFERING_LIST_FAILURE,
  ADD_SCHEDULED_OFFERING_REQUEST,
  ADD_SCHEDULED_OFFERING_SUCCESS,
  ADD_SCHEDULED_OFFERING_FAILURE,
  EDIT_SCHEDULED_OFFERING_REQUEST,
  EDIT_SCHEDULED_OFFERING_SUCCESS,
  EDIT_SCHEDULED_OFFERING_FAILURE,
  DELETE_SCHEDULED_OFFERING_REQUEST,
  DELETE_SCHEDULED_OFFERING_SUCCESS,
  DELETE_SCHEDULED_OFFERING_FAILURE,
  GET_SCHEDULED_OFFERING_BY_ID_REQUEST,
  GET_SCHEDULED_OFFERING_BY_ID_SUCCESS,
  GET_SCHEDULED_OFFERING_BY_ID_FAILURE,
  SCO_DAYS_LIST_REQUEST,
  SCO_DAYS_LIST_SUCCESS,
  SCO_DAYS_LIST_FAILURE,
  SESSION_LIST_BY_SCO_ID_REQUEST,
  SESSION_LIST_BY_SCO_ID_SUCCESS,
  SESSION_LIST_BY_SCO_ID_FAILURE,
  ADD_SESSION_DAY_REQUEST,
  ADD_SESSION_DAY_SUCCESS,
  ADD_SESSION_DAY_FAILURE,
  EDIT_SESSION_DAY_REQUEST,
  EDIT_SESSION_DAY_SUCCESS,
  EDIT_SESSION_DAY_FAILURE,
  DELETE_SESSION_DAY_REQUEST,
  DELETE_SESSION_DAY_SUCCESS,
  DELETE_SESSION_DAY_FAILURE,
  DOWNLOAD_DOC_REQUEST,
  DOWNLOAD_DOC_SUCCESS,
  DOWNLOAD_DOC_FAILURE,
  UPLOAD_DOC_REQUEST,
  UPLOAD_DOC_SUCCESS,
  UPLOAD_DOC_FAILURE,
  DELETE_DOC_REQUEST,
  DELETE_DOC_SUCCESS,
  DELETE_DOC_FAILURE,
  CANCEL_SESSION_DAY_REQUEST,
  CANCEL_SESSION_DAY_SUCCESS,
  CANCEL_SESSION_DAY_FAILURE,
  ADD_SCO_EDUCATOR_REQUEST,
  ADD_SCO_EDUCATOR_SUCCESS,
  ADD_SCO_EDUCATOR_FAILURE,
  DELETE_SCO_EDUCATOR_REQUEST,
  DELETE_SCO_EDUCATOR_SUCCESS,
  DELETE_SCO_EDUCATOR_FAILURE,
  ENROLED_STUDENT_LIST_REQUEST,
  ENROLED_STUDENT_LIST_SUCCESS,
  ENROLED_STUDENT_LIST_FAILURE,
  SCO_STUDENT_ATTENDANCE_LIST_REQUEST,
  SCO_STUDENT_ATTENDANCE_LIST_FAILURE,
  SCO_STUDENT_ATTENDANCE_LIST_SUCCESS,
  STUDENT_FILL_ATTENDANCE_REQUEST,
  STUDENT_FILL_ATTENDANCE_SUCCESS,
  STUDENT_FILL_ATTENDANCE_FAILURE,
  SCO_STUDENT_WHO_ATTENDED_LIST_FAILURE,
  SCO_STUDENT_WHO_ATTENDED_LIST_REQUEST,
  SCO_STUDENT_WHO_ATTENDED_LIST_SUCCESS,
  ENROLED_COUNT_REQUEST,
  ENROLED_COUNT_SUCCESS,
  ENROLED_COUNT_FAILURE,
  FILL_GENERATE_CERTIFICATE_FAILURE,
  FILL_GENERATE_CERTIFICATE_REQUEST,
  FILL_GENERATE_CERTIFICATE_SUCCESS,
  SCO_GENERATE_CERTIFICATE_LIST_FAILURE,
  SCO_GENERATE_CERTIFICATE_LIST_REQUEST,
  SCO_GENERATE_CERTIFICATE_LIST_SUCCESS,
  SAVE_ATTENDANCE_REQUEST,
  PRINT_COURSE_ENROLMENTS_FAILURE,
  PRINT_COURSE_ENROLMENTS_REQUEST,
  PRINT_COURSE_ENROLMENTS_SUCCESS,
  SAVE_GENERATE_CERTIFICATE_REQUEST,
  GET_EDUCATOR_REFLECTION_FORM_FAILURE,
  GET_EDUCATOR_REFLECTION_FORM_REQUEST,
  GET_EDUCATOR_REFLECTION_FORM_SUCCESS,
} from "./actionTypes";

import { ScheduledOfferingActions, ScheduledOfferingState } from "./types";

const initialState: ScheduledOfferingState = {
  pending: false,
  scheduledOfferingList: [],
  error: null,
  enroledStudentList: [],
  isSaveAttendance: false,
  isSaveCertificate: false,
};

const reducers = (
  state = initialState,
  action: ScheduledOfferingActions
): unknown => {
  switch (action.type) {
    case SCHEDULED_OFFERING_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SCHEDULED_OFFERING_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        scheduledOfferingList: action.payload.scheduledOfferingList,
        error: null,
      };
    case SCHEDULED_OFFERING_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_SCHEDULED_OFFERING_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_SCHEDULED_OFFERING_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_SCHEDULED_OFFERING_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_SCHEDULED_OFFERING_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_SCHEDULED_OFFERING_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_SCHEDULED_OFFERING_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_SCHEDULED_OFFERING_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_SCHEDULED_OFFERING_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_SCHEDULED_OFFERING_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case SCO_DAYS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SCO_DAYS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        scoDaysList: action.payload.scoDaysList,
        error: null,
      };
    case SCO_DAYS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case GET_SCHEDULED_OFFERING_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_SCHEDULED_OFFERING_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        scheduledOfferingById: action.payload,
        error: null,
      };
    case GET_SCHEDULED_OFFERING_BY_ID_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case SESSION_LIST_BY_SCO_ID_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SESSION_LIST_BY_SCO_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        sessionListByScoId: action.payload.sessionListByScoId,
        error: null,
      };
    case SESSION_LIST_BY_SCO_ID_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_SESSION_DAY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_SESSION_DAY_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_SESSION_DAY_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case EDIT_SESSION_DAY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case EDIT_SESSION_DAY_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case EDIT_SESSION_DAY_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_SESSION_DAY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_SESSION_DAY_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_SESSION_DAY_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DOWNLOAD_DOC_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DOWNLOAD_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
        document: action.payload.document,
        error: null,
      };
    case DOWNLOAD_DOC_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case UPLOAD_DOC_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPLOAD_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case UPLOAD_DOC_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_DOC_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_DOC_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_DOC_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case CANCEL_SESSION_DAY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CANCEL_SESSION_DAY_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case CANCEL_SESSION_DAY_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ADD_SCO_EDUCATOR_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ADD_SCO_EDUCATOR_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ADD_SCO_EDUCATOR_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case DELETE_SCO_EDUCATOR_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case DELETE_SCO_EDUCATOR_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case DELETE_SCO_EDUCATOR_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ENROLED_STUDENT_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ENROLED_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        enroledStudentList: action.payload.enroledStudentList,
        error: null,
      };
    case ENROLED_STUDENT_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case SCO_STUDENT_ATTENDANCE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SCO_STUDENT_ATTENDANCE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        studentAttendanceList: action.payload.studentAttendanceList,
        error: null,
      };
    case SCO_STUDENT_ATTENDANCE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case STUDENT_FILL_ATTENDANCE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case STUDENT_FILL_ATTENDANCE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case STUDENT_FILL_ATTENDANCE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case SCO_STUDENT_WHO_ATTENDED_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SCO_STUDENT_WHO_ATTENDED_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        studentAttendanceList: action.payload.studentWhoAttendedList,
        error: null,
      };
    case SCO_STUDENT_WHO_ATTENDED_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case ENROLED_COUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ENROLED_COUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case ENROLED_COUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case SCO_GENERATE_CERTIFICATE_LIST_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SCO_GENERATE_CERTIFICATE_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        studentGenerateCertificateList:
          action.payload.studentGenerateCertificateList,
        error: null,
      };
    case SCO_GENERATE_CERTIFICATE_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case FILL_GENERATE_CERTIFICATE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FILL_GENERATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: action.payload,
        error: null,
      };
    case FILL_GENERATE_CERTIFICATE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case SAVE_ATTENDANCE_REQUEST:
      return {
        ...state,
        pending: false,
        isSaveAttendance: action.payload.isSaveAttendance,
      };
    case PRINT_COURSE_ENROLMENTS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PRINT_COURSE_ENROLMENTS_SUCCESS:
      return {
        ...state,
        pending: false,
        item: action.payload.item,
        error: null,
      };
    case PRINT_COURSE_ENROLMENTS_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    case SAVE_GENERATE_CERTIFICATE_REQUEST:
      return {
        ...state,
        pending: false,
        isSaveCertificate: action.payload.isSaveCertificate,
      };
    case GET_EDUCATOR_REFLECTION_FORM_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case GET_EDUCATOR_REFLECTION_FORM_SUCCESS:
      return {
        ...state,
        pending: false,
        termDetails: action.payload.formDetails,
        error: null,
      };
    case GET_EDUCATOR_REFLECTION_FORM_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducers;
