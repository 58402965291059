import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Pagination,
  PaginationItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  approvedIcon,
  arrowBackwardIcon,
  arrowForwardIcon,
  removeIcon,
  closeIcon,
  courseListSecondaryIcon,
  radioIcon,
  radioCheckedIcon,
  successfullIcon,
  arrowRightIcon,
  uncheckIcon,
  checkIcon,
} from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";
import projectTheme from "app.theme";
import { AppRoutings } from "utils/enums/app-routings";
import moment from "moment";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

function RadioCheckBoxIcon() {
  return <img src={radioIcon} alt="checkbox" />;
}
function RadioCheckedboxIcon() {
  return <img src={radioCheckedIcon} alt="checkbox" />;
}

function CheckboxIcon() {
  return <img src={uncheckIcon} alt="checkbox" />;
}
function CheckedboxIcon() {
  return <img src={checkIcon} alt="checkbox" />;
}

interface StudentData {
  stdName: string;
  dob: string;
  mobile: string;
  email: string;
  addr: string;
}

function createStudentData(
  stdName: string,
  dob: string,
  mobile: string,
  email: string,
  addr: string
): StudentData {
  return {
    stdName,
    dob,
    mobile,
    email,
    addr,
  };
}

const studentrows = [
  createStudentData(
    "Rachael Rogan",
    "20, Dec. 1995",
    "826384427",
    "rachael.rogan@gmail.com",
    "Recovery & wellbeing college, Kogarah"
  ),
  createStudentData(
    "Michale Conte",
    "18, Sep. 1992",
    "988263827",
    "michael.cote@email.com",
    "151 Goswell Road London "
  ),
  createStudentData(
    "Katherina Stufano",
    "13, Dec. 1998",
    "998263827",
    "katherina.stufano@email.com",
    "Recovery & wellbeing college, Kogarah"
  ),
  createStudentData(
    "Katherina Stufano",
    "19, Aug. 2001",
    "998263827",
    "katherina.stufano@email.com",
    "Recovery & wellbeing college, Kogarah"
  ),
  createStudentData(
    "Katherina Stufano",
    "26, Nov. 1999",
    "998263827",
    "katherina.stufano@email.com",
    "151 Goswell Road London "
  ),
];

const DesignerStudentEnrol: React.FC = () => {
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [detailsRemoved, setDetailsRemoved] = React.useState(false);
  const handleDetailsRemoved = () => setDetailsRemoved(true);
  const handleDetailsRemovedClose = () => setDetailsRemoved(false);

  // const [stdEnrolModal, setStdEnrolModal] = React.useState(false);
  // const handleStdEnrolModal = () => setStdEnrolModal(true);
  // const handleStdEnrolModalClose = () => setStdEnrolModal(false);

  const [approveModal, setApproveModal] = React.useState(false);
  const handleApproveModalClose = () => setApproveModal(false);

  const [stdDetailModal, setStdDetailModal] = React.useState(false);
  const handleStdDetailsModal = () => setStdDetailModal(true);
  const handleStdDetailModalClose = () => setStdDetailModal(false);

  const [sector, setSector] = React.useState("");

  const handleSectorChange = (event: SelectChangeEvent) => {
    setSector(event.target.value as string);
  };

  const [enrolConfirm, setEnrolConfirm] = React.useState("");

  const handleEnrolConfirmChange = (event: SelectChangeEvent) => {
    setEnrolConfirm(event.target.value as string);
  };

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleAccordChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const [studentEnrol, setStudentEnrol] = React.useState(true);
  const [studentEnrolDetails, setStudentEnrolDetails] = React.useState(false);

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />

      <DesignerHeader />
      <main>
        <DesignerSidebar />
        {studentEnrol && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Student Enrolments
              </Typography>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box>
                      <TextField
                        id="search"
                        variant="outlined"
                        className="search-input"
                        placeholder="Search"
                      />
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{ width: "153px", minWidth: "153px" }}
                          >
                            Student Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "144px", minWidth: "118px" }}
                          >
                            Date Of Birth
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "123px", minWidth: "105px" }}
                          >
                            Mobile
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "278px", minWidth: "216px" }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "341px", minWidth: "176px" }}
                          >
                            Address
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "152px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "112px",
                              },
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {studentrows.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.stdName}
                            </TableCell>
                            <TableCell>
                              {moment(row.dob).format("D, MMM. YYYY")}
                            </TableCell>
                            <TableCell>{row.mobile}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {row.addr}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setStudentEnrol(false);
                                    setStudentEnrolDetails(true);
                                  }}
                                >
                                  <img src={approvedIcon} alt="Approved" />
                                </IconButton>
                                <IconButton onClick={handleStdDetailsModal}>
                                  <img
                                    src={courseListSecondaryIcon}
                                    alt="Details"
                                  />
                                </IconButton>
                                <IconButton onClick={handleDetailsRemoved}>
                                  <img src={removeIcon} alt="Remove" />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}

        {studentEnrolDetails && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Student Confirmation
              </Typography>
            </Box>
            <Card>
              <Box className="stdenrol-detail-content">
                <Grid
                  container
                  columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                >
                  <Grid item xs={12} sm={6} xl={3}>
                    <FormControl fullWidth className="select">
                      <InputLabel id="enrol-confirm-label">
                        Enrollment confirmation preference
                      </InputLabel>
                      <Select
                        labelId="enrol-confirm-label"
                        id="enrol-confirm"
                        label="Enrollment confirmation preference"
                        value={enrolConfirm}
                        onChange={handleEnrolConfirmChange}
                      >
                        <MenuItem value={1}>Option 1</MenuItem>
                        <MenuItem value={2}>Option 2</MenuItem>
                        <MenuItem value={3}>Option 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <FormControl fullWidth className="select">
                      <InputLabel id="sector-select-label">Sector</InputLabel>
                      <Select
                        labelId="sector-select-label"
                        id="sector-select"
                        label="Sector"
                        value={sector}
                        onChange={handleSectorChange}
                      >
                        <MenuItem value={1}>Item 1</MenuItem>
                        <MenuItem value={2}>Item 2</MenuItem>
                        <MenuItem value={3}>Item 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <TextField
                      id="resch-code"
                      label="Research Code"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} xl={3}>
                    <TextField
                      id="mrn"
                      label="MRN (If Known)"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Box className="stdenrol-staff">
                  <Typography variant="h5">
                    Active community eMR &#40;at enrolment&#41;
                  </Typography>
                  <RadioGroup row>
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          icon={<RadioCheckBoxIcon />}
                          checkedIcon={<RadioCheckedboxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          icon={<RadioCheckBoxIcon />}
                          checkedIcon={<RadioCheckedboxIcon />}
                        />
                      }
                      label="No"
                    />
                    <FormControlLabel
                      value="3"
                      control={
                        <Radio
                          icon={<RadioCheckBoxIcon />}
                          checkedIcon={<RadioCheckedboxIcon />}
                        />
                      }
                      label="Unknown"
                    />
                  </RadioGroup>
                </Box>
                <Box className="stdenrol-course">
                  <Typography variant="h5">Selected Courses</Typography>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked
                          disableFocusRipple
                          disableRipple
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedboxIcon />}
                        />
                      }
                      label="With a lived experience of mental distress, or  suicidal crisis and live in the south eastern sydney local health District (SESLHD) catchment area."
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked
                          disableFocusRipple
                          disableRipple
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedboxIcon />}
                        />
                      }
                      label="Exploring alcohol, Drug use & Wellbeing [2023 T2]-(E:0, WL:0, Max:25 - Open) at recovery & wellbeing college, kogarah BLENDED - face to face/onlie -02/05/2023 10:00am to  01:00pm."
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableFocusRipple
                          disableRipple
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedboxIcon />}
                        />
                      }
                      label="Exploring alcohol, Drug use & Wellbeing [2023 T2]-(E:0, WL:0, Max:25 - Open) at recovery & wellbeing college, kogarah BLENDED - face to face/onlie -02/05/2023 10:00am to  01:00pm."
                      labelPlacement="end"
                    />
                    <TextField
                      id="rejection"
                      className="skills-textarea"
                      label="Please enter reason for rejection"
                      fullWidth
                      variant="outlined"
                      multiline
                      required
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked
                          disableFocusRipple
                          disableRipple
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedboxIcon />}
                        />
                      }
                      label="With a lived experience of mental distress, or  suicidal crisis and live in the south eastern sydney local health District (SESLHD) catchment area."
                      labelPlacement="end"
                    />
                    <TextField
                      id="rejection"
                      className="skills-textarea"
                      label="Please enter reason for rejection"
                      fullWidth
                      variant="outlined"
                      multiline
                      required
                    />
                  </Box>
                </Box>
                <Box className="btn-group">
                  <Button
                    variant="contained"
                    className="btn-continue"
                    onClick={() => {
                      setStudentEnrol(true);
                      setStudentEnrolDetails(false);
                    }}
                  >
                    Approved
                  </Button>
                  <Button
                    variant="outlined"
                    className="btn-cancel"
                    onClick={() => {
                      setStudentEnrol(true);
                      setStudentEnrolDetails(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Card>

            <DesignerFooter />
          </div>
        )}
      </main>

      <Modal open={detailsRemoved} onClose={handleDetailsRemovedClose}>
        <Box className="common-modal rejection-modal delete-modal">
          <Box className="modal-header">
            <Typography variant="h2">Reject Confirmation</Typography>
            <IconButton onClick={handleDetailsRemovedClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Typography variant="h5">
              Are you sure you want to reject student enrolment ?
            </Typography>
            <TextField
              id="reason-rejection"
              label="Please enter reason for rejection"
              fullWidth
              variant="outlined"
              multiline
              required
              className="skills-textarea"
            />
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-yes"
              onClick={handleDetailsRemovedClose}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              className="btn-no"
              onClick={handleDetailsRemovedClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* <Modal open={stdEnrolModal} onClose={handleStdEnrolModalClose}>
        <Box className="common-modal stdenrol-modal delete-modal">
          <Box className="modal-header">
            <Typography variant="h2">Student Enrolment</Typography>
            <IconButton onClick={handleStdEnrolModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Box className="stdenrol-staff">
              <Typography variant="h5">Staff enrollment</Typography>
              <RadioGroup row>
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      icon={<RadioCheckBoxIcon />}
                      checkedIcon={<RadioCheckedboxIcon />}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      icon={<RadioCheckBoxIcon />}
                      checkedIcon={<RadioCheckedboxIcon />}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </Box>
            <Grid
              container
              columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
            >
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel id="enrol-confirm-label">
                    Enrollment confirmation preference
                  </InputLabel>
                  <Select
                    labelId="enrol-confirm-label"
                    id="enrol-confirm"
                    label="Enrollment confirmation preference"
                    value={enrolConfirm}
                    onChange={handleEnrolConfirmChange}
                  >
                    <MenuItem value={1}>Option 1</MenuItem>
                    <MenuItem value={2}>Option 2</MenuItem>
                    <MenuItem value={3}>Option 3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth className="select">
                  <InputLabel id="sector-select-label">Sector</InputLabel>
                  <Select
                    labelId="sector-select-label"
                    id="sector-select"
                    label="Sector"
                    value={sector}
                    onChange={handleSectorChange}
                  >
                    <MenuItem value={1}>Item 1</MenuItem>
                    <MenuItem value={2}>Item 2</MenuItem>
                    <MenuItem value={3}>Item 3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="resch-code"
                  label="Research Code"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="mrn"
                  label="MRN (If Known)"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box className="stdenrol-staff">
              <Typography variant="h5">
                Active community eMR &#40;at enrolment&#41;
              </Typography>
              <RadioGroup row>
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      icon={<RadioCheckBoxIcon />}
                      checkedIcon={<RadioCheckedboxIcon />}
                    />
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      icon={<RadioCheckBoxIcon />}
                      checkedIcon={<RadioCheckedboxIcon />}
                    />
                  }
                  label="No"
                />
                <FormControlLabel
                  value="3"
                  control={
                    <Radio
                      icon={<RadioCheckBoxIcon />}
                      checkedIcon={<RadioCheckedboxIcon />}
                    />
                  }
                  label="Unknown"
                />
              </RadioGroup>
            </Box>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-continue"
              onClick={() => {
                setApproveModal(true);
                setStdEnrolModal(false);
              }}
            >
              Approved
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleStdEnrolModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal> */}

      <Modal open={approveModal} onClose={handleApproveModalClose}>
        <Box className="common-modal approved-modal">
          <Box className="modal-header">
            <IconButton onClick={handleApproveModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <div className="icon">
              <img src={successfullIcon} alt="Checked" />
            </div>
            <Typography variant="h4" className="approval">
              Student enrolment request has been Approved.
            </Typography>
            <Typography variant="h6" className="std-name">
              Student Name : <span>Rachael Rogan</span>
            </Typography>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-continue"
              href={AppRoutings.DesignerStudent}
              onClick={handleApproveModalClose}
            >
              view details
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleApproveModalClose}
            >
              close
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={stdDetailModal} onClose={handleStdDetailModalClose}>
        <Box className="common-modal std-details-modal delete-modal">
          <Box className="modal-header">
            <Typography variant="h2">Student Details</Typography>
            <IconButton onClick={handleStdDetailModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography variant="h3">Student Details</Typography>
                <img src={arrowRightIcon} alt="Arrow" />
              </AccordionSummary>
              <AccordionDetails>
                <Box className="acc-details-container">
                  <Box className="eligibility">
                    <Typography variant="h4">Enrolment Eligibility</Typography>
                    <Typography variant="h6">
                      Staff, students and volunteers of the south eastern sydney
                      local health district.{" "}
                    </Typography>
                  </Box>
                  <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                    personal details
                  </Typography>
                  <Box className="terms-read-only">
                    <Grid
                      container
                      columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      rowSpacing={{ xs: "16px", lg: "20px" }}
                    >
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Student Name
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Rachael Rogan
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Date Of Birth
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          20/11/1998
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Phone Number
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          826384427
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Email Address
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          rachael.rogan@gmail.com
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Landline/Other Phone
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          28012487
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Street Address
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Recovery & wellbeing
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Suburb
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Alfords Point
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          State
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          NEW
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Post Code
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          32504
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleAccordChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography variant="h3">
                  Student Supports and Emergency Contact Details
                </Typography>
                <img src={arrowRightIcon} alt="Arrow" />
              </AccordionSummary>
              <AccordionDetails>
                <Box className="acc-details-container">
                  <Box className="terms-read-only">
                    <Typography variant="body2" className="detail-name">
                      Student Supports Questionnaire
                    </Typography>
                    <Typography variant="body1" className="detail-score">
                      Lorem ipsum dolor sit amet
                    </Typography>
                  </Box>
                  <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                    Emergency details
                  </Typography>
                  <Box className="terms-read-only">
                    <Grid
                      container
                      columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      rowSpacing={{ xs: "16px", lg: "20px" }}
                    >
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Contact Name
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Katherina Stufano
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Contact Relationship
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Contact Phone
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          826384427
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" className="detail-name">
                          Information to help us support you
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" className="detail-name">
                          Enrolment type
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Consumer
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                    Questionnaire
                  </Typography>
                  <Box className="terms-read-only">
                    <Grid
                      container
                      columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      rowSpacing={{ xs: "16px", lg: "20px" }}
                    >
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          className="detail-name"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          How did you hear about the Recovery & Wellbeing
                          College?*
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Katherina Stufano
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2" className="detail-name">
                          Aboriginal/Torres strait islander identity
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Disability
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Katherina Stufano
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Country of birth
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Language at home
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Ethnicity
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Katherina Stufano
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Gender identity
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Sexuality
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Are you intersex
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Katherina Stufano
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Completed Qualifications
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Employment status
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Highest completed school level
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Katherina Stufano
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Currently Studying
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Study load
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Brother
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                    Staff details
                  </Typography>
                  <Box className="terms-read-only">
                    <Grid
                      container
                      columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      rowSpacing={{ xs: "16px", lg: "20px" }}
                    >
                      <Grid item xs={12} md={6}>
                        <Typography
                          variant="body2"
                          className="detail-name"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          Employment Status
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Part-time
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2" className="detail-name">
                          Team
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Acute Care Team
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Current Role
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Staff from Partner Service
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Work Environment
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Community
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Site/Service
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          STGH/St George Community
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Manager&#39;s Name
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          John Doe
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="body2" className="detail-name">
                          Manager&#39;s Email
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          johndoe@gmail.com
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleAccordChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography variant="h3">Student selected Courses</Typography>
                <img src={arrowRightIcon} alt="Arrow" />
              </AccordionSummary>
              <AccordionDetails>
                <Box className="acc-details-container">
                  <List>
                    <ListItem disableGutters dense disablePadding>
                      <ListItemText primary="With a lived experience of mental distress, or  suicidal crisis and live in the south eastern sydney local health District (SESLHD) catchment area." />
                    </ListItem>
                    <ListItem disableGutters dense disablePadding>
                      <ListItemText primary="Exploring alcohol, Drug use & Wellbeing [2023 T2]-(E:0, WL:0, Max:25 - Open) at recovery & wellbeing college, kogarah BLENDED - face to face/onlie -02/05/2023 10:00am to  01:00pm." />
                    </ListItem>
                  </List>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-continue"
              onClick={() => {
                setStdDetailModal(false);
              }}
            >
              Approved
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleStdDetailModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DesignerStudentEnrol;
