import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  changeManagerSettingFailure,
  changeManagerSettingSuccess,
  changePasswordFailure,
  changePasswordSuccess,
  updateUserProfileFailure,
  updateUserProfileSuccess,
} from "./actions";

import {
  CHANGE_MANAGER_SETTING_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  UPDATE_USER_PROFILE_REQUEST,
} from "./actionTypes";
import profileService from "./service";
import {
  ChangeManagerSettingResponse,
  ChangePasswordResponse,
  UpdateUserProfileResponse,
} from "./types";

function* changePasswordSaga(action: any) {
  try {
    const response: ChangePasswordResponse = yield call(
      profileService.changePassword,
      {
        userName: action.payload.values.userName,
        oldPassword: action.payload.values.oldPassword,
        newPassword: action.payload.values.newPassword,
      }
    );

    yield put(
      changePasswordSuccess({
        success: response.isValid,
      })
    );
    action.payload.callback({
      success: response.isValid,
    });
  } catch (e: any) {
    yield put(
      changePasswordFailure({
        error: e.message,
      })
    );
  }
}

function* updateUserProfileSaga(action: any) {
  try {
    const response: UpdateUserProfileResponse = yield call(
      profileService.updateUserProfile,
      {
        firstName: action.payload.values.firstName,
        lastName: action.payload.values.lastName,
        address: action.payload.values.address,
      }
    );

    yield put(
      updateUserProfileSuccess({
        success: response.isValid,
      })
    );
    action.payload.callback({
      success: response.isValid,
    });
  } catch (e: any) {
    yield put(
      updateUserProfileFailure({
        error: e.message,
      })
    );
  }
}

function* changeManagerSettingSaga(action: any) {
  try {
    const response: ChangeManagerSettingResponse = yield call(
      profileService.changeManagerSetting,
      {
        name: action.payload.values.name,
        signature: action.payload.values.signature,
      }
    );

    yield put(
      changeManagerSettingSuccess({
        success: response.isSuccessStatusCode,
      })
    );
    action.payload.callback({
      success: response.isSuccessStatusCode,
    });
  } catch (e: any) {
    yield put(
      changeManagerSettingFailure({
        error: e.message,
      })
    );
  }
}

function* profileSaga() {
  yield all([takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordSaga)]);
  yield all([takeLatest(UPDATE_USER_PROFILE_REQUEST, updateUserProfileSaga)]);
  yield all([
    takeLatest(CHANGE_MANAGER_SETTING_REQUEST, changeManagerSettingSaga),
  ]);
}

export default profileSaga;
