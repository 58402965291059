import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import NoRecords from "components/NoRecords";
import {
  GenerateCertificateStatus,
  ISCOGenerateCertificateStudentListData,
} from "utils/interfaces/scheduled-offering";
import { useEffect, useState } from "react";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface IGenerateCertificateModalProps {
  handleGenerateCertificateClose: any;
  handleGenerateCertificateSave: any;
  handleCheckboxChange: any;
  generateCertificateData: ISCOGenerateCertificateStudentListData[];
  generateCertificateStatus: GenerateCertificateStatus[];
  handleSelectAll: any;
}

const GenerateCertificateModal: React.FC<IGenerateCertificateModalProps> = ({
  handleGenerateCertificateClose,
  handleGenerateCertificateSave,
  handleCheckboxChange,
  generateCertificateData,
  generateCertificateStatus,
  handleSelectAll,
}) => {
  const allCheckboxChecked = generateCertificateStatus.every(
    (item) => item.isCertificateGenerate
  );
  const [selectAll, setSelectAll] = useState<boolean>(allCheckboxChecked);

  const handleSelectAllButton = () => {
    handleSelectAll(!selectAll);
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectAll && !allCheckboxChecked) {
      setSelectAll(false);
    } else if (!selectAll && allCheckboxChecked) {
      setSelectAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateCertificateStatus]);

  return (
    <>
      <Modal open onClose={handleGenerateCertificateClose}>
        <Box className="common-modal generate-certificate-modal">
          <Box className="modal-header">
            <Typography variant="h4">Generate Certificate </Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleGenerateCertificateClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body generate-certificate-modal-body">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ maxWidth: "646px", minWidth: "195px" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            disableFocusRipple
                            disableRipple
                            icon={<CheckboxIcon />}
                            checkedIcon={<CheckedBoxIcon />}
                            checked={selectAll}
                            onChange={handleSelectAllButton}
                          />
                        }
                        label=""
                      />
                      Student
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "128px", minWidth: "128px" }}
                    >
                      Attendance
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateCertificateData?.map((row, index) => {
                    return (
                      <TableRow key={row.studentCourseEnrolmentId}>
                        <TableCell component="th" align="left" scope="row">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableFocusRipple
                                disableRipple
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckedBoxIcon />}
                                checked={
                                  generateCertificateStatus[index]
                                    ?.isCertificateGenerate
                                }
                                onChange={() => handleCheckboxChange(index)}
                              />
                            }
                            label={row.studentName}
                            labelPlacement="end"
                          />
                        </TableCell>
                        <TableCell align="center">
                          {row.attendanceRate}%
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {(!generateCertificateData ||
              generateCertificateData.length === 0) && <NoRecords />}
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleGenerateCertificateSave}
              disabled={
                !generateCertificateData || generateCertificateData.length === 0
              }
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleGenerateCertificateClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default GenerateCertificateModal;
