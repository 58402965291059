import { Box } from "@mui/system";
import { Card, Grid, Link, Typography } from "@mui/material";
import { userIcon } from "assets/images";
import { IHomeScoEducator } from "utils/interfaces/educator-home";
import { EDUCATOR_TYPES } from "utils/constants/educator";

interface IEducatorsListProps {
  educatorsList: Array<IHomeScoEducator>;
}

const EducatorsSection: React.FC<IEducatorsListProps> = ({ educatorsList }) => {
  const getEducatorType = (typeId: number) => {
    const typeItem = EDUCATOR_TYPES.find((type) => type.value === typeId);

    return typeItem?.label;
  };

  return (
    <Grid item xs={12}>
      <Card>
        <Typography variant="h3" className="attnd-heading">
          Educators
        </Typography>
        <Box className="educators-card-group">
          <Grid container spacing={{ xs: "16px", md: "20px", lg: "24px" }}>
            {educatorsList?.map((educator) => (
              <Grid item xs={12} sm={6} xl={3} key={educator.id}>
                <div className="educators-card">
                  <img src={userIcon} alt="user" />
                  <Box sx={{ maxWidth: "calc(100% - 54px);", width: "100%" }}>
                    <Typography variant="body2" className="name">
                      {educator?.educatorName}
                    </Typography>
                    <Typography variant="body2" className="designation">
                      {getEducatorType(educator?.educatorType)}
                    </Typography>
                    <Link
                      type="email"
                      href={`mailto: ${educator?.emailAddress}`}
                    >
                      {educator?.emailAddress}
                    </Link>
                  </Box>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};

export default EducatorsSection;
