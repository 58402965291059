import baseService from "services/base-service";
import {
  IAddEditDocumentRequest,
  IDocumentPagePayloadValues,
} from "utils/interfaces/document";

const documentBaseUrl = `/api/Document`;

const getDocumentList = async (payload: IDocumentPagePayloadValues) =>
  baseService.post(`${documentBaseUrl}/GetDocuments`, payload);

const addDocument = async (payload: IAddEditDocumentRequest) => {
  const headers = {
    contentType: "multipart/form-data",
  };

  return baseService.post(documentBaseUrl, payload, { headers });
};

const editDocument = async (payload: IAddEditDocumentRequest) =>
  baseService.put(documentBaseUrl, payload);

const getDocumentById = async (payload: { id: number }) =>
  baseService.get(`${documentBaseUrl}?id=${payload.id}`);

const deleteDocument = async (payload: { id: number }) =>
  baseService.delete(`${documentBaseUrl}?id=${payload.id}`);

export default {
  getDocumentList,
  addDocument,
  editDocument,
  getDocumentById,
  deleteDocument,
};
