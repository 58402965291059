import { Box } from "@mui/system";
import {
  Modal,
  Button,
  Grid,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
  Fade,
  Tooltip,
} from "@mui/material";
import { closeIcon, saveIcon } from "assets/images";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ICourseOfferingEducators,
  IScoEducator,
} from "utils/interfaces/scheduled-offering";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { EducatorStatus } from "utils/enums/educator";
import {
  educatorRenderValue,
  getPagePayloadValues,
  showLoader,
} from "utils/helper";
import {
  IEducator,
  IEducatorPagePayloadValues,
} from "utils/interfaces/educator";
import { EducatorListSuccessPayload } from "store/educator/types";
import { OrderByOptions } from "utils/enums/sorting";
import {
  CLOSE_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  MAX_INT_VALUE,
} from "utils/constants/constant";
import { educatorListRequest } from "store/educator/actions";
import { useDispatch } from "react-redux";

interface IAddEducatorsProps {
  addEducatorsModal: boolean;
  handleAddEducatorsModalClose: any;
  handleAddEducatorsClick: any;
  addedEducatorsList: Array<IScoEducator>;
}

const AddEducators: React.FC<IAddEducatorsProps> = ({
  addEducatorsModal,
  handleAddEducatorsModalClose,
  handleAddEducatorsClick,
  addedEducatorsList,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm<ICourseOfferingEducators>({
    defaultValues: {
      educatorIds: [],
    },
  });

  const dispatch = useDispatch();
  const [educatorList, setEducatorList] = useState<IEducator[]>();

  const onEducatorListSuccess = (response: EducatorListSuccessPayload) => {
    const allEducatorsList = response.educatorList;
    const availableEducators: any = [];

    allEducatorsList?.forEach((educator) => {
      const addedEducator = addedEducatorsList.find(
        (item) => item.educatorID === educator.id
      );

      if (!addedEducator && educator.status !== EducatorStatus.Inactive) {
        availableEducators.push(educator);
      }
    });

    setEducatorList(availableEducators);
  };

  const getEducatorList = () => {
    showLoader();
    const values: IEducatorPagePayloadValues = getPagePayloadValues(
      "Name",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.name = "";
    const payload = {
      values,
      callback: onEducatorListSuccess,
    };

    dispatch(educatorListRequest(payload));
  };

  useEffect(() => {
    if (addEducatorsModal) {
      reset();
      getEducatorList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEducatorsModal]);

  const getEducatorIdsError = (): string => {
    if (errors.educatorIds) {
      if (errors.educatorIds.type === "required") {
        return "Educator is required";
      }
    }

    return "";
  };

  useEffect(() => {
    if (watch("educatorIds").length > 0) {
      trigger("educatorIds");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("educatorIds")]);

  return (
    <>
      <Modal open={addEducatorsModal} onClose={handleAddEducatorsModalClose}>
        <Box className="common-modal addsco-modal addsco-offering-modal">
          <Box className="modal-header">
            <Typography variant="h4">Add Educators</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleAddEducatorsModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <form onSubmit={handleSubmit(handleAddEducatorsClick)}>
            <Box className="modal-body">
              <Grid
                container
                rowSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
              >
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                    rowSpacing="16px"
                  >
                    <Grid item xs={12}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="educator-type-label">
                          Educator <span className="color-red">*</span>
                        </InputLabel>
                        <Select
                          labelId="educator-type-label"
                          id="educator-type"
                          multiple
                          label="Educator"
                          input={<OutlinedInput label="Educator" />}
                          renderValue={(selected) =>
                            educatorRenderValue(selected, educatorList)
                          }
                          value={watch("educatorIds")}
                          error={!!errors.educatorIds}
                          {...register("educatorIds", {
                            required: true,
                          })}
                          MenuProps={{
                            className: "educator-type-select",
                          }}
                        >
                          {educatorList?.map((educator) => (
                            <MenuItem
                              key={educator.id}
                              value={educator.id}
                              disabled={
                                educator.status === EducatorStatus.Inactive
                              }
                            >
                              <Checkbox
                                checked={
                                  watch("educatorIds")?.indexOf(educator.id) >
                                  -1
                                }
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckedBoxIcon />}
                              />
                              <ListItemText primary={educator.name} />
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors.educatorIds && (
                          <FormHelperText error>
                            {getEducatorIdsError()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box className="modal-footer">
              <Button variant="contained" className="btn-save" type="submit">
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={handleAddEducatorsModalClose}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddEducators;
