import {
  calendarIcon,
  headerLogo1,
  mobileLogo1,
  nextIcon,
  prevIcon,
  prevIconDisabled,
  prevIconLight,
  radioCheckedIcon,
  radioIcon,
  successfullIcon,
} from "assets/images";
import {
  AppBar,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Step,
  // StepButton,
  StepLabel,
  Stepper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { AppRoutings } from "utils/enums/app-routings";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";

function CalendarIcon() {
  return (
    <div className="calendar-icon">
      <img src={calendarIcon} alt="calendar" />
    </div>
  );
}

const steps = [0, 1, 2, 3];

function RadioCheckBoxIcon() {
  return <img src={radioIcon} alt="checkbox" />;
}
function RadioCheckedboxIcon() {
  return <img src={radioCheckedIcon} alt="checkbox" />;
}

const DesignerStudentTemp: React.FC = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [finishStep, setfinishStep] = React.useState(0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const [state, setState] = React.useState("");

  const handleStateChange = (event: SelectChangeEvent) => {
    setState(event.target.value as string);
  };

  const [ques, setQues] = React.useState("");

  const handleQuesChange = (event: SelectChangeEvent) => {
    setQues(event.target.value as string);
  };

  const [enrolType, setEnrolType] = React.useState("1");

  const handleEnrolTypeChange = (event: SelectChangeEvent) => {
    setEnrolType(event.target.value as string);
  };

  const [whereabouts, setWhereabouts] = React.useState("");

  const handleWhereaboutsChange = (event: SelectChangeEvent) => {
    setWhereabouts(event.target.value as string);
  };

  const [identity, setIdentity] = React.useState("");

  const handleIdentityChange = (event: SelectChangeEvent) => {
    setIdentity(event.target.value as string);
  };

  const [disability, setDisability] = React.useState("");

  const handleDisabilityChange = (event: SelectChangeEvent) => {
    setDisability(event.target.value as string);
  };

  const [gender, setGender] = React.useState("");

  const handleGenderChange = (event: SelectChangeEvent) => {
    setGender(event.target.value as string);
  };

  const [sexuality, setSexuality] = React.useState("");

  const handleSexualityChange = (event: SelectChangeEvent) => {
    setSexuality(event.target.value as string);
  };

  const [intersex, setIntersex] = React.useState("");

  const handleIntersexChange = (event: SelectChangeEvent) => {
    setIntersex(event.target.value as string);
  };

  const [qualification, setQualification] = React.useState("");

  const handleQualificationChange = (event: SelectChangeEvent) => {
    setQualification(event.target.value as string);
  };

  const [empstatus, setEmpstatus] = React.useState("");

  const handleEmpstatusChange = (event: SelectChangeEvent) => {
    setEmpstatus(event.target.value as string);
  };

  const [schoollvl, setSchoollvl] = React.useState("");

  const handleSchoollvlChange = (event: SelectChangeEvent) => {
    setSchoollvl(event.target.value as string);
  };

  const [crrntStudy, setCrrntStudy] = React.useState("");

  const handleCrrntStudyChange = (event: SelectChangeEvent) => {
    setCrrntStudy(event.target.value as string);
  };

  const [studyload, setStudyload] = React.useState("");

  const handleStudyloadChange = (event: SelectChangeEvent) => {
    setStudyload(event.target.value as string);
  };

  const [staffEnroll, setStaffEnroll] = React.useState("");

  const handleStaffEnrollChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStaffEnroll((event.target as HTMLInputElement).value);
  };

  const [empStatus1, setEmpStatus1] = React.useState("1");

  const handleEmpStatus1Change = (event: SelectChangeEvent) => {
    setEmpStatus1(event.target.value as string);
  };

  const [team, setTeam] = React.useState("1");

  const handleTeamChange = (event: SelectChangeEvent) => {
    setTeam(event.target.value as string);
  };

  const [currntRole, setCurrntRole] = React.useState("1");

  const handleCurrntRoleChange = (event: SelectChangeEvent) => {
    setCurrntRole(event.target.value as string);
  };

  const [workEnv, setWorkEnv] = React.useState("1");

  const handleWorkEnvChange = (event: SelectChangeEvent) => {
    setWorkEnv(event.target.value as string);
  };

  const [siteServ, setSiteServ] = React.useState("1");

  const handleSiteServChange = (event: SelectChangeEvent) => {
    setSiteServ(event.target.value as string);
  };

  const [preferDel, setPreferDel] = React.useState("1");

  const handlePreferDelChange = (event: SelectChangeEvent) => {
    setPreferDel(event.target.value as string);
  };

  return (
    <div className="wrapper">
      <AppBar position="static" className="student-header">
        <Toolbar
          disableGutters
          sx={{ flexWrap: "wrap" }}
          className="header-container-top"
        >
          <a
            href={AppRoutings.DesignerDashboard}
            className="header-logo"
            title="South Eastern Sydney Recovery and Wellbeing College"
          >
            <img
              src={headerLogo1}
              alt="South Eastern Sydney Recovery and Wellbeing College"
              className="desktop"
            />
            <img
              src={mobileLogo1}
              alt="South Eastern Sydney Recovery and Wellbeing College"
              className="mobile"
            />
          </a>
          <Typography variant="h2" className="header-title">
            New Student Enrolment
          </Typography>
        </Toolbar>
        {activeStep !== steps.length && (
          <Toolbar
            disableGutters
            sx={{ flexWrap: "wrap" }}
            className="header-container-bottom"
          >
            {activeStep === 0 && (
              <Typography variant="h3" className="header-subtitle">
                Recovery College Online Enrolment Form
              </Typography>
            )}
            {activeStep === 1 && (
              <Typography variant="h3" className="header-subtitle">
                Personal Information
              </Typography>
            )}
            {activeStep === 2 && (
              <Typography variant="h3" className="header-subtitle">
                Student Supports and Emergency Contact Details
              </Typography>
            )}

            {activeStep === 3 && (
              <Typography variant="h3" className="header-subtitle">
                Course selection
              </Typography>
            )}

            <Stepper activeStep={activeStep}>
              {steps.map((index) => {
                const stepProps: { completed?: boolean } = {};

                return (
                  <Step key={index} {...stepProps}>
                    <StepLabel>{index + 1}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Toolbar>
        )}

        {/* ----------- 13/12/2023 ----------------- */}
        {finishStep === 0 && activeStep === steps.length && (
          <Toolbar
            disableGutters
            sx={{ flexWrap: "wrap" }}
            className="header-container-bottom finished"
          >
            <Typography variant="h3" className="header-subtitle">
              Student Details
            </Typography>
          </Toolbar>
        )}
        {/* ----------- 13/12/2023 ----------------- */}
      </AppBar>
      <Box>
        {activeStep === steps.length ? (
          // ---------13/12/2023--------------
          <Box className="finished-step">
            {finishStep === 0 && (
              <Box className="finished-details-container">
                <Typography variant="h4" className="title">
                  Review Your Enrolment Details
                </Typography>
                <Grid
                  container
                  rowSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                >
                  <Grid item xs={12}>
                    <Card className="stepper-card finished-details">
                      <Box className="eligibility">
                        <Typography variant="h4">
                          Eligibility Criteria
                        </Typography>
                        <Typography variant="h6">
                          With a lived experience of mental distress, or
                          suicidal crisis and currently using the South Eastern
                          Sydney Local Health District Mental Health Service
                        </Typography>
                      </Box>
                      <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                        personal details
                      </Typography>
                      <Box className="terms-read-only">
                        <Grid
                          container
                          columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                          rowSpacing={{ xs: "16px", lg: "20px" }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Student Name
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Rachael Rogan
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Date Of Birth
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              20/11/1998
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Phone Number
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              826384427
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Email Address
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              rachael.rogan@gmail.com
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Landline/Other Phone
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              28012487
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Street Address
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Recovery & wellbeing
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Suburb
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Alfords Point
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              State
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              NEW
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Post Code
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              32504
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="terms-read-only">
                        <Typography variant="body2" className="detail-name">
                          Current Service Provider
                        </Typography>
                        <Typography variant="body1" className="detail-score">
                          Lorem ipsum dolor sit amet
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card className="stepper-card finished-details">
                      <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                        Emergency Contact details
                      </Typography>
                      <Box className="terms-read-only">
                        <Grid
                          container
                          columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                          rowSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Contact Name
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Katherina Stufano
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Contact Relationship
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Brother
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Contact Phone
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              826384427
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" className="detail-name">
                              Information to help us support you
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Lorem ipsum dolor sit amet, consectetur
                              adipisicing elit, sed do eiusmod tempor incididunt
                              ut labore et dolore magna aliqua.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" className="detail-name">
                              Enrolment type
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Consumer
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card className="stepper-card finished-details">
                      <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                        Questionnaire
                      </Typography>
                      <Box className="terms-read-only">
                        <Grid
                          container
                          columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                          rowSpacing={{ xs: "16px", lg: "12px" }}
                        >
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="body2"
                              className="detail-name"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              How did you hear about the Recovery & Wellbeing
                              College?*
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Lorem ipsum dolor sit amet,
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="body2" className="detail-name">
                              Aboriginal/Torres strait islander identity
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Lorem ipsum dolor sit amet,
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Disability
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              No
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Country of birth
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Australia
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Language at home
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              English
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Ethnicity
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Prefer not to say
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Gender identity
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Female
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Sexuality
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Women
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Are you intersex
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Prefer not to say
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Completed Qualifications
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Yes
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Employment status
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              -
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Highest completed school level
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              B.Tech
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Currently Studying
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Master
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Study load
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              No
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card className="stepper-card finished-details">
                      <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                        Staff details
                      </Typography>
                      <Box className="terms-read-only">
                        <Grid
                          container
                          columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                          rowSpacing={{ xs: "16px", lg: "12px" }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography
                              variant="body2"
                              className="detail-name"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              Employment Status
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Part-time
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Team
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Acute Care Team
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Current Role
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Staff from Partner Service
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Work Environment
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              Community
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Site/Service
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              STGH/St George Community
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Manager&#39;s Name
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              John Doe
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Manager&#39;s Email
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              johndoe@gmail.com
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="body2" className="detail-name">
                              Staff Link ID
                            </Typography>
                            <Typography
                              variant="body1"
                              className="detail-score"
                            >
                              -
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card className="stepper-card finished-details">
                      <Box className="terms-read-only">
                        <Box className="prefer-del">
                          <Typography variant="body2" className="detail-name">
                            Preferred Course Delivery
                          </Typography>
                          <Typography variant="body1" className="detail-score">
                            Online
                          </Typography>
                        </Box>
                        <Typography variant="h4" sx={{ marginBottom: "12px" }}>
                          Selected Courses
                        </Typography>
                        <List>
                          <ListItem disableGutters dense disablePadding>
                            <ListItemText primary="With a lived experience of mental distress, or  suicidal crisis and live in the south eastern sydney local health District (SESLHD) catchment area." />
                          </ListItem>
                          <ListItem disableGutters dense disablePadding>
                            <ListItemText primary="With a lived experience of mental distress, or  suicidal crisis and live in the south eastern sydney local health District (SESLHD) catchment area." />
                          </ListItem>
                        </List>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
                <Box className="stepper-btn-group">
                  <Button
                    onClick={() => setActiveStep(3)}
                    className="btn-back"
                    variant="outlined"
                  >
                    <img src={prevIcon} alt="Previous" className="active" />
                    <img
                      src={prevIconLight}
                      alt="Previous"
                      className="hovered"
                    />
                    Back
                  </Button>

                  <Button
                    onClick={() => setfinishStep(1)}
                    className="btn-next"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            )}
            {finishStep === 1 && (
              <Box className="finished-container">
                <div className="icon">
                  <img src={successfullIcon} alt="" />
                </div>
                <Typography variant="h2" className="greeting">
                  Your enrolment request has been successfully submitted.
                </Typography>
                <Typography variant="h6" className="greeting-para">
                  The Recovery & Wellbeing College will contact you by email or
                  SMS within a few days to let you know if your place has been
                  confirmed.
                </Typography>
                <Typography variant="h6" className="greeting-para">
                  If the course you have requested to attend is within the next
                  week or if you have any questions, Please call us during our
                  business hours of 8.30am – 4.30pm on 9113 2981.
                </Typography>
                <Typography variant="h6" className="greeting-para">
                  Thank You.
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          // ---------13/12/2023--------------
          <Box className="stepper-container">
            <Box className="stepper-content-container">
              {activeStep === 0 && (
                <>
                  <Card className="stepper-card step-1">
                    <Typography variant="body2" className="instruction">
                      Follow the instructions below in order to register to
                      attend a Recovery & Wellbeing College Courses
                    </Typography>
                    <Typography variant="h4" className="questions">
                      Are you an existing student of the Recovery and Wellbeing
                      college?
                    </Typography>
                    <RadioGroup row>
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="2"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                    <Typography
                      variant="body2"
                      className="instruction"
                      sx={{ lineHeight: "18px !important" }}
                    >
                      If you have previously registered to attend a course with
                      the Recovery and Wellbeing College, then you are an
                      existing student. <br />
                      Choose <span>&#39;Yes&#39;</span> if you have never
                      registered with us, choose <span>&#39;No&#39;</span>
                    </Typography>
                    <Typography
                      variant="h4"
                      className="questions"
                      sx={{ marginBottom: "2px !important" }}
                    >
                      Eligibility Criteria <span className="required">*</span>
                    </Typography>
                    <Typography
                      variant="body2"
                      className="instruction"
                      sx={{ marginBottom: "12px !important" }}
                    >
                      Please select the option below which describes your
                      situation
                    </Typography>
                    <RadioGroup>
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        label="With a live experience of mental distress, or suicidal crisis and currently using the South Eastern Sydney Local Health District Mental Health Service"
                      />
                      <FormControlLabel
                        value="2"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        label="Family, carer or support person"
                      />
                      <FormControlLabel
                        value="3"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        label="Other residents of South Eastern Sydney Local Health District catchment area."
                      />
                      <FormControlLabel
                        value="4"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        label="Staff or volunteers of the South Eastern Sydney Local Health District"
                      />
                      <FormControlLabel
                        value="5"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        label="Staff of the Recovery & Wellbeing College partner organisations."
                      />
                    </RadioGroup>
                  </Card>
                  <Typography variant="body2" className="know-more">
                    Want to find out more about our courses before you enrol?
                  </Typography>
                  <Typography variant="body2" className="know-more">
                    View our latest course guide here :
                    <a href="-"> Recovery & Wellbeing College Homepage</a>
                  </Typography>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <Card className="stepper-card step-2">
                    <Typography variant="body2" className="instruction">
                      The South Eastern Sydney Recovery & Wellbeing College is
                      currently FREE of charge and open to people 16 years of
                      age and over in our community.
                    </Typography>
                    <Typography variant="h4" className="questions">
                      personal details
                    </Typography>
                    <Grid
                      container
                      columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                    >
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="first-name"
                          label="First Name"
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="last-name"
                          label="Last Name"
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DesktopDatePicker"]}>
                            <DesktopDatePicker
                              className="date-picker"
                              label="Date of Birth"
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  required: true,
                                },
                              }}
                              slots={{
                                openPickerIcon: CalendarIcon,
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="phone-num"
                          label="Phone Number"
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="emai-add"
                          label="Email Address"
                          fullWidth
                          variant="outlined"
                          type="email"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="landline"
                          label="Landline/Other Phone"
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="street-add"
                          label="Street Address"
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="suburb"
                          label="Suburb"
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <FormControl fullWidth className="select">
                          <InputLabel id="State-label">State*</InputLabel>
                          <Select
                            labelId="State-label"
                            id="state"
                            label="State*"
                            value={state}
                            onChange={handleStateChange}
                          >
                            <MenuItem value={1}>Confirmed</MenuItem>
                            <MenuItem value={2}>Withdrawn</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="post-code"
                          label="Post Code"
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <Card className="stepper-card step-3">
                    <Typography
                      variant="body2"
                      className="instruction"
                      sx={{ marginBottom: "8px !important" }}
                    >
                      Your Current Service Providers: Please choose at least one
                      option
                    </Typography>
                    <FormControl fullWidth className="select">
                      <InputLabel id="ques-label">
                        Current Service Providers*
                      </InputLabel>
                      <Select
                        labelId="ques-label"
                        id="ques"
                        label="Current Service Providers*"
                        value={ques}
                        onChange={handleQuesChange}
                      >
                        <MenuItem value={1}>Opition 1</MenuItem>
                        <MenuItem value={2}>Opition 2</MenuItem>
                        <MenuItem value={3}>Opition 2</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      variant="h4"
                      className="questions"
                      sx={{ marginBottom: "12px !important" }}
                    >
                      Emergency Contacts
                    </Typography>
                    <Grid
                      container
                      columnSpacing={{ sm: "16px", md: " 20px", xl: "24px" }}
                    >
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="name"
                          label="Name Of Contact Person"
                          fullWidth
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="relationship"
                          label="Relationship"
                          fullWidth
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} lg={4}>
                        <TextField
                          id="phone-num-2"
                          label="Phone Number"
                          fullWidth
                          variant="outlined"
                          required
                        />
                      </Grid>
                    </Grid>
                    <Typography variant="h4" className="questions">
                      Information to help us support you
                    </Typography>
                    <Typography variant="body2" className="instruction">
                      Do you have any specific learning or support requirement
                      of which you would like the college to be aware?
                      &#40;optional&#41;
                    </Typography>
                    <TextField
                      id="comment"
                      className="skills-textarea"
                      label="Comment"
                      fullWidth
                      variant="outlined"
                      multiline
                    />
                    <Box className="staff-enroll">
                      <FormControl fullWidth className="select">
                        <InputLabel id="enroltype-label">
                          Enrolment Type
                        </InputLabel>
                        <Select
                          labelId="enroltype-label"
                          id="enroltype"
                          label="Enrolment Type"
                          value={enrolType}
                          onChange={handleEnrolTypeChange}
                        >
                          <MenuItem value={1}>Consumer</MenuItem>
                          <MenuItem value={2}>Consumer</MenuItem>
                          <MenuItem value={3}>Consumer</MenuItem>
                        </Select>
                      </FormControl>
                      <Box>
                        <Typography variant="h5" className="confirmation">
                          Staff enrollment
                        </Typography>
                        <RadioGroup
                          row
                          value={staffEnroll}
                          onChange={handleStaffEnrollChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={
                              <Radio
                                icon={<RadioCheckBoxIcon />}
                                checkedIcon={<RadioCheckedboxIcon />}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={
                              <Radio
                                icon={<RadioCheckBoxIcon />}
                                checkedIcon={<RadioCheckedboxIcon />}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </Box>
                    </Box>

                    {staffEnroll === "no" && (
                      <>
                        <Typography
                          variant="h4"
                          className="questions"
                          sx={{ marginBottom: "4px !important" }}
                        >
                          Questionnaire
                        </Typography>
                        <Typography
                          variant="body2"
                          className="instruction"
                          sx={{ marginBottom: "4px !important" }}
                        >
                          The following questionnaire will assist us to ensure
                          the Recovery & Wellbeing College is accessible to the
                          diverse population groups in our community
                        </Typography>
                        <Typography
                          variant="body2"
                          className="instruction"
                          sx={{ marginBottom: "4px !important" }}
                        >
                          The information related to employment and education
                          will assist us with our service evaluation and in
                          applying for future funding.
                        </Typography>
                        <Typography
                          variant="body2"
                          className="instruction"
                          sx={{ marginBottom: "15px !important" }}
                        >
                          You can choose &#39;prefer not say&#39; for any
                          questions that you do not feel comfortable answering.
                        </Typography>
                        <Grid
                          container
                          columnSpacing={{
                            sm: "16px",
                            md: " 20px",
                            xl: "24px",
                          }}
                        >
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="whereabouts-label">
                                How Did You Hear About The Recovery & Wellbeing
                                College?*
                              </InputLabel>
                              <Select
                                labelId="whereabouts-label"
                                id="whereabouts"
                                label=" How Did You Hear About The Recovery & Wellbeing
                            College?*"
                                value={whereabouts}
                                onChange={handleWhereaboutsChange}
                                required
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="identity-label">
                                Aboriginal/Torres Strait Islander Identity*
                              </InputLabel>
                              <Select
                                labelId="identity-label"
                                id="identity"
                                label="Aboriginal/Torres Strait Islander Identity*"
                                value={identity}
                                onChange={handleIdentityChange}
                                required
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="disability-label">
                                Disability*
                              </InputLabel>
                              <Select
                                labelId="disability-label"
                                id="disability"
                                label="Disability*"
                                value={disability}
                                onChange={handleDisabilityChange}
                                required
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="cob"
                              label="Country Of Birth"
                              fullWidth
                              variant="outlined"
                              required
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="homelang"
                              label="Language At Home"
                              fullWidth
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="ethnicity"
                              label="Ethnicity"
                              fullWidth
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="gender-label">
                                Gender Identity*
                              </InputLabel>
                              <Select
                                labelId="gender-label"
                                id="gender"
                                label="Gender Identity*"
                                value={gender}
                                onChange={handleGenderChange}
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="sexuality-label">
                                Sexuality*
                              </InputLabel>
                              <Select
                                labelId="sexuality-label"
                                id="sexuality"
                                label="Sexuality*"
                                value={sexuality}
                                onChange={handleSexualityChange}
                                required
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="intersex-label">
                                Are You Intersex?
                              </InputLabel>
                              <Select
                                labelId="intersex-label"
                                id="intersex"
                                label="Are You Intersex?"
                                value={intersex}
                                onChange={handleIntersexChange}
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="qualification-label">
                                Completed Qualifications*
                              </InputLabel>
                              <Select
                                labelId="qualification-label"
                                id="qualification"
                                label="Completed Qualifications*"
                                value={qualification}
                                onChange={handleQualificationChange}
                                required
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="empstatus-label">
                                Employment Status*
                              </InputLabel>
                              <Select
                                labelId="empstatus-label"
                                id="empstatus"
                                label="Employment Status*"
                                value={empstatus}
                                onChange={handleEmpstatusChange}
                                required
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="schoollvel-label">
                                Highest Completed School Level*
                              </InputLabel>
                              <Select
                                labelId="schoollvel-label"
                                id="schoollvel"
                                label="Highest Completed School Level*"
                                value={schoollvl}
                                onChange={handleSchoollvlChange}
                                required
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="currentstudy-label">
                                Currently Studying*
                              </InputLabel>
                              <Select
                                labelId="currentstudy-label"
                                id="currentstudy"
                                label="Currently Studying*"
                                value={crrntStudy}
                                onChange={handleCrrntStudyChange}
                                required
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="studyload-label">
                                Study Load*
                              </InputLabel>
                              <Select
                                labelId="studyload-label"
                                id="studyload"
                                label="Study Load*"
                                value={studyload}
                                onChange={handleStudyloadChange}
                                required
                              >
                                <MenuItem value={1}>Opition 1</MenuItem>
                                <MenuItem value={2}>Opition 2</MenuItem>
                                <MenuItem value={3}>Opition 2</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {staffEnroll === "yes" && (
                      <>
                        <Grid
                          container
                          columnSpacing={{
                            sm: "16px",
                            md: " 20px",
                            xl: "24px",
                          }}
                        >
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="empStatus-label">
                                Employment Status*
                              </InputLabel>
                              <Select
                                labelId="empStatus-label"
                                id="empStatus"
                                label="Employment Status*"
                                value={empStatus1}
                                onChange={handleEmpStatus1Change}
                                required
                              >
                                <MenuItem value={1}>Part-time</MenuItem>
                                <MenuItem value={2}>full-time</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="team-label">Team*</InputLabel>
                              <Select
                                labelId="team-label"
                                id="team"
                                label="Team*"
                                value={team}
                                onChange={handleTeamChange}
                                required
                              >
                                <MenuItem value={1}>Acute Care Team</MenuItem>
                                <MenuItem value={2}>Acute Care Team</MenuItem>
                                <MenuItem value={3}>Acute Care Team</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="currntRole-label">
                                Current Role*
                              </InputLabel>
                              <Select
                                labelId="currntRole-label"
                                id="currntRole"
                                label="Current Role*"
                                value={currntRole}
                                onChange={handleCurrntRoleChange}
                                required
                              >
                                <MenuItem value={1}>
                                  Staff from Partner Service
                                </MenuItem>
                                <MenuItem value={2}>
                                  Staff from Partner Service
                                </MenuItem>
                                <MenuItem value={3}>
                                  Staff from Partner Service
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="workEnv-label">
                                Work Environment*
                              </InputLabel>
                              <Select
                                labelId="workEnv-label"
                                id="workEnv"
                                label="Work Environment*"
                                value={workEnv}
                                onChange={handleWorkEnvChange}
                                required
                              >
                                <MenuItem value={1}>Community</MenuItem>
                                <MenuItem value={2}>Community</MenuItem>
                                <MenuItem value={3}>Community</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="select">
                              <InputLabel id="siteServ-label">
                                Site/Service*
                              </InputLabel>
                              <Select
                                labelId="siteServ-label"
                                id="siteServ"
                                label="Site/Service*"
                                value={siteServ}
                                onChange={handleSiteServChange}
                                required
                              >
                                <MenuItem value={1}>
                                  STGH/St George Community
                                </MenuItem>
                                <MenuItem value={2}>
                                  STGH/St George Community
                                </MenuItem>
                                <MenuItem value={3}>
                                  STGH/St George Community
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="mang-name"
                              label="Manager's Name"
                              fullWidth
                              variant="outlined"
                              required
                              defaultValue="John Doe"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              id="mang-email"
                              label="Manager's Email"
                              fullWidth
                              variant="outlined"
                              defaultValue="johndoe@gmail.com"
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Card>
                </>
              )}
              {activeStep === 3 && (
                <>
                  {/* ----------- 13/12/2023 ----------------- */}
                  <Card className="stepper-card step-4">
                    <FormControl className="select">
                      <InputLabel id="course-del-label">
                        Preferred Course Delivery
                      </InputLabel>
                      <Select
                        labelId="course-del-label"
                        id="course-del"
                        label="Preferred Course Delivery"
                        value={preferDel}
                        onChange={handlePreferDelChange}
                        required
                      >
                        <MenuItem value={1}>Online</MenuItem>
                        <MenuItem value={2}>Offline</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography variant="h4" className="questions">
                      Our current Courses
                    </Typography>
                    <RadioGroup row>
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        defaultChecked={false}
                        label="With a lived experience of mental distress, or  suicidal crisis and live in the South Eastern Sydney Local Health District (SESLHD) catchment area."
                      />
                      <FormControlLabel
                        value="2"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        defaultChecked={false}
                        label="Exploring alcohol, Drug use & Wellbeing [2023 T2]-(E:0, WL:0, Max:25 - Open) at Recovery & Wellbeing College, Kogarah BLENDED - face to face/online -02/05/2023 10:00am to  01:00pm"
                      />
                      <FormControlLabel
                        value="3"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        defaultChecked={false}
                        label="With a lived experience of mental distress, or  suicidal crisis and live in the South Eastern Sydney Local Health District (SESLHD) catchment area."
                      />
                      <FormControlLabel
                        value="4"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        defaultChecked={false}
                        label="With a lived experience of mental distress, or  suicidal crisis and live in the South Eastern Sydney Local Health District (SESLHD) catchment area."
                      />
                      <FormControlLabel
                        value="5"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        defaultChecked={false}
                        label="With a lived experience of mental distress, or  suicidal crisis and live in the South Eastern Sydney Local Health District (SESLHD) catchment area."
                      />
                      <FormControlLabel
                        value="6"
                        control={
                          <Radio
                            icon={<RadioCheckBoxIcon />}
                            checkedIcon={<RadioCheckedboxIcon />}
                          />
                        }
                        defaultChecked={false}
                        label="Exploring alcohol, Drug use & Wellbeing [2023 T2]-(E:0, WL:0, Max:25 - Open) at Recovery & Wellbeing College, Kogarah BLENDED - face to face/online -02/05/2023 10:00am to  01:00pm"
                      />
                    </RadioGroup>
                  </Card>
                  {/* ----------- 13/12/2023 ----------------- */}
                </>
              )}
              <Box className="stepper-btn-group">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className="btn-prev"
                  variant="outlined"
                >
                  <img src={prevIcon} alt="Previous" className="active" />
                  <img
                    src={prevIconDisabled}
                    alt="Previous"
                    className="disabled"
                  />
                  <img src={prevIconLight} alt="Previous" className="hovered" />
                  Previous
                </Button>

                <Button
                  onClick={handleNext}
                  className="btn-next"
                  variant="contained"
                >
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  <img src={nextIcon} alt="Next" />
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default DesignerStudentTemp;
