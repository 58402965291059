import { connect, MapDispatchToProps } from "react-redux";
import CourseComponent, { CourseProps } from "pages/Courses";
import {
  addCourseRequest,
  deleteCourseRequest,
  editCourseRequest,
  courseListRequest,
  courseByIdRequest,
} from "store/course/actions";
import { ICourseContainerDispatch, ICourse } from "utils/interfaces/course";

const mapDispatchToProps: MapDispatchToProps<
  ICourseContainerDispatch,
  ICourse
> = {
  courseListRequest,
  addCourseRequest,
  editCourseRequest,
  deleteCourseRequest,
  courseByIdRequest,
};

const connector = connect(null, mapDispatchToProps);

const Courses: React.FC<CourseProps> = (props) => {
  return <CourseComponent {...props} />;
};

export default connector(Courses);
