export type Order = "asc" | "desc";

export enum OrderBy {
  Asc = 0,
  Desc = 1,
}

export enum OrderByOptions {
  Asc = "asc",
  Desc = "desc",
}
