import baseService from "services/base-service";
import { IAddEditUserRequest } from "utils/interfaces/user";

const userBaseUrl = `/api/User`;

const getUserList = async (payload: {
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  name: string;
}) => baseService.post(`${userBaseUrl}/GetUsers`, payload);

const addUser = async (payload: IAddEditUserRequest) =>
  baseService.post(userBaseUrl, payload);

const editUser = async (payload: IAddEditUserRequest) =>
  baseService.put(userBaseUrl, payload);

const getUserById = async (payload: { id: number }) =>
  baseService.get(`${userBaseUrl}?id=${payload.id}`);

const deleteUser = async (payload: { id: number }) =>
  baseService.delete(`${userBaseUrl}?id=${payload.id}`);

export default {
  getUserList,
  addUser,
  editUser,
  getUserById,
  deleteUser,
};
