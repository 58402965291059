import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  attendanceIcon,
  calendarFilledDarkIcon,
  calendarFilledLightIcon,
  calendarIcon,
  checkIcon,
  closeIcon,
  cloudLightIcon,
  cloudPrimaryIcon,
  courseListIcon,
  courseListLightIcon,
  courseListSecondaryIcon,
  deleteIcon,
  editIcon,
  filterLightIcon,
  filterPrimaryIcon,
  pdfIcon,
  plusLightIcon,
  saveIcon,
  uncheckIcon,
  userIcon,
  userSmallIcon,
  userSmallLightIcon,
} from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import projectTheme from "app.theme";
import { AppRoutings } from "utils/enums/app-routings";

function CalendarIcon() {
  return (
    <div className="calendar-icon">
      <img src={calendarIcon} alt="calendar" />
    </div>
  );
}

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

function CheckboxIcon() {
  return <img src={uncheckIcon} alt="checkbox" />;
}
function CheckedboxIcon() {
  return <img src={checkIcon} alt="checkbox" />;
}

interface Data {
  coursenum: string;
  course: string;
  term: string;
  location: string;
  days: number;
  issued: string;
  emr: string;
  enrollnum: number;
  attndday: number;
  status: string;
}

function createData(
  coursenum: string,
  course: string,
  term: string,
  location: string,
  days: number,
  issued: string,
  emr: string,
  enrollnum: number,
  attndday: number,
  status: string
): Data {
  return {
    coursenum,
    course,
    term,
    location,
    days,
    issued,
    emr,
    enrollnum,
    attndday,
    status,
  };
}

const rows = [
  createData(
    "S020017-ENG",
    "Life Beyond Trauma",
    "2022 T4",
    "151 Goswell Road London",
    3,
    "No",
    "No",
    7,
    7,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Mindfulnes workshop",
    "2022 T3",
    "151 Goswell Road London",
    4,
    "No",
    "No",
    9,
    9,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Creativity for recovery",
    "2022 T1",
    "143 Pinkville Road ",
    4,
    "Yes",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "An introduction to recovery & the strength of approach",
    "2022 T2",
    "Goswell Square II",
    3,
    "No",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Making and keeping connections",
    "2023 T1",
    "104 ShopMart Complex",
    3,
    "No",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Exploring psychosis ",
    "2022 T5",
    "122 Square III",
    3,
    "Yes",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Exploring trauma for women",
    "2022 T6",
    "St Rose Valley - 111",
    3,
    "No",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Taking control of your life",
    "2022 T2",
    "10-A  Plaza ltd.",
    3,
    "No",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Physical health & welbeing",
    "2022 T3",
    "Rose Valley 40B",
    3,
    "No",
    "No",
    10,
    10,
    "confirmed"
  ),
  createData(
    "S020017-ENG",
    "Yoga for recovery",
    "2022 T3",
    "Rose Valley 40B",
    3,
    "No",
    "No",
    10,
    10,
    "confirmed"
  ),
];

interface AddScheduleData {
  dayno: number;
  stdattend: number;
  dood: string;
  attendno: number;
  attendance: string;
}

function createAddScheduleData(
  dayno: number,
  stdattend: number,
  dood: string,
  attendno: number,
  attendance: string
): AddScheduleData {
  return {
    dayno,
    stdattend,
    dood,
    attendno,
    attendance,
  };
}

const addSchedulerow = [
  createAddScheduleData(1, 0, "03 Nov 2022", 7, "Yes"),
  createAddScheduleData(2, 0, "10 Nov 2022", 9, "Yes"),
  createAddScheduleData(3, 0, "17 Nov 2022", 10, "Yes"),
];

interface SessDaysandAttndData {
  dayno: number;
  stdattend: number;
  dood: string;
  attendno: number;
  attendance: string;
}

function createSessDaysandAttndData(
  dayno: number,
  stdattend: number,
  dood: string,
  attendno: number,
  attendance: string
): SessDaysandAttndData {
  return {
    dayno,
    stdattend,
    dood,
    attendno,
    attendance,
  };
}

const sessDaysandAttndrow = [
  createSessDaysandAttndData(1, 7, "03 Nov 2022", 7, "Yes"),
  createSessDaysandAttndData(2, 8, "10 Nov 2022", 9, "Yes"),
  createSessDaysandAttndData(3, 10, "17 Nov 2022", 10, "Yes"),
];

interface EnrollmentData {
  stdname: string;
  stdno: string;
  mrn: string;
  enrolldate: string;
  enrolltype: string;
  status: string;
  mobile: string;
  email: string;
}

function createEnrollmentData(
  stdname: string,
  stdno: string,
  mrn: string,
  enrolldate: string,
  enrolltype: string,
  status: string,
  mobile: string,
  email: string
): EnrollmentData {
  return {
    stdname,
    stdno,
    mrn,
    enrolldate,
    enrolltype,
    status,
    mobile,
    email,
  };
}

const enrollmentrow = [
  createEnrollmentData(
    "Rachael Rogan",
    "2337",
    "8263827",
    "03 Nov 2022, 9:06AM",
    "Consumer",
    "confirmed",
    "826384427",
    "rachael.rogan@gmail.com"
  ),
  createEnrollmentData(
    "Michale Conte",
    "732",
    "9826382",
    "10 Nov 2022, 6:00AM",
    "Consumer",
    "withdrawn",
    "988263827",
    "michael.cote@email.com"
  ),
  createEnrollmentData(
    "Katherina Stufano",
    "233",
    "6563827",
    "17 Nov 2022, 12:00PM",
    "Other",
    "confirmed",
    "998263827",
    "katherina.stufano@email.com"
  ),
  createEnrollmentData(
    "Katherina Stufano",
    "233",
    "6563827",
    "17 Nov 2022, 12:00PM",
    "Consumer",
    "confirmed",
    "998263827",
    "katherina.stufano@email.com"
  ),
  createEnrollmentData(
    "Katherina Stufano",
    "233",
    "6563827",
    "17 Nov 2022, 12:00PM",
    "Other",
    "withdrawn",
    "998263827",
    "katherina.stufano@email.com"
  ),
];

interface attendanceData {
  student: string;
}

function createAttendanceData(student: string): attendanceData {
  return {
    student,
  };
}

const attendancerows = [
  createAttendanceData("Shane Lincoin"),
  createAttendanceData("Rachael Rogan"),
  createAttendanceData("Michale Conte"),
  createAttendanceData("Katherina Stufano"),
  createAttendanceData("Kamara Ducki"),
  createAttendanceData("Lydia Lin"),
];

const educators = [
  "Melissa Nakhle",
  "Katherina Stufano",
  "Rachael Rogan",
  "Rachael Rogan",
];

function ChildModal() {
  const [addScoModal, setScoModal] = React.useState(false);
  const handleScoModal = () => setScoModal(true);
  const handleScoModalClose = () => setScoModal(false);

  const [repeat, setRepeat] = React.useState("2");

  const handleRepeatChange = (event: SelectChangeEvent) => {
    setRepeat(event.target.value as string);
  };

  const [every, setEvery] = React.useState("1");

  const handleEveryChange = (event: SelectChangeEvent) => {
    setEvery(event.target.value as string);
  };

  const [end, setEnd] = React.useState("1");

  const handleEndChange = (event: SelectChangeEvent) => {
    setEnd(event.target.value as string);
  };

  const [endValue, setEndValue] = React.useState<Dayjs | null>(
    dayjs("2022-11-20")
  );

  return (
    <Box className="child-modal-container">
      <Box className="checkbox-group">
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckboxIcon />}
              checkedIcon={<CheckedboxIcon />}
            />
          }
          label="All Day"
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckboxIcon />}
              checkedIcon={<CheckedboxIcon />}
            />
          }
          label="Repeat"
          labelPlacement="end"
          onClick={handleScoModal}
        />
      </Box>
      <Modal open={addScoModal} onClose={handleScoModalClose}>
        <Box className="common-modal repeat-modal">
          <Box className="modal-header">
            <Typography variant="h4">Edit Repeat</Typography>
            <IconButton onClick={handleScoModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Grid
              container
              columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth className="select">
                  <InputLabel id="repeat-label">Repeat</InputLabel>
                  <Select
                    labelId="repeat-label"
                    id="repeat"
                    label="Repeat "
                    value={repeat}
                    onChange={handleRepeatChange}
                  >
                    <MenuItem value={1}>Daily</MenuItem>
                    <MenuItem value={2}>Weekly</MenuItem>
                    <MenuItem value={3}>Once</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth className="select">
                  <InputLabel id="every-label">Every</InputLabel>
                  <Select
                    labelId="every-label"
                    id="every"
                    label="Every"
                    value={every}
                    onChange={handleEveryChange}
                  >
                    <MenuItem value={1}>1 week</MenuItem>
                    <MenuItem value={2}>2 weeks</MenuItem>
                    <MenuItem value={3}>3 weeks</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography variant="body1" className="date-label">
              Repeat On
            </Typography>
            <Box className="checkbox-group">
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedboxIcon />}
                  />
                }
                label="Sun"
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedboxIcon />}
                  />
                }
                label="Mon"
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedboxIcon />}
                  />
                }
                label="Tue"
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedboxIcon />}
                  />
                }
                label="Wed"
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedboxIcon />}
                  />
                }
                label="Thu"
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedboxIcon />}
                  />
                }
                label="Fri"
                labelPlacement="end"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checkedIcon={<CheckedboxIcon />}
                  />
                }
                label="Sat"
                labelPlacement="end"
              />
            </Box>
            <Grid container rowSpacing="14px">
              <Grid item xs={12}>
                <Grid
                  container
                  columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                  className="repeat-time-labels"
                >
                  <Grid item xs={3} sm="auto">
                    <Typography
                      variant="body1"
                      className="date-label"
                      sx={{ marginBottom: 0 }}
                    >
                      Starts On
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm>
                    <Typography variant="body1">7 Nov 2022</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                  className="repeat-time-labels"
                  alignItems={{ sm: "center" }}
                >
                  <Grid item xs={3} sm="auto">
                    <Typography
                      variant="body1"
                      className="date-label"
                      sx={{ marginBottom: 0 }}
                    >
                      Ends
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sm>
                    <Box className="end-datetime">
                      <FormControl fullWidth className="select">
                        <Select
                          labelId="end-label"
                          id="end"
                          value={end}
                          onChange={handleEndChange}
                        >
                          <MenuItem value={1}>On</MenuItem>
                          <MenuItem value={2}>Before</MenuItem>
                          <MenuItem value={3}>After</MenuItem>
                        </Select>
                      </FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DesktopDatePicker"]}>
                          <DesktopDatePicker
                            className="date-picker"
                            label="DateTime"
                            format="DD/MM/YYYY"
                            value={endValue}
                            onChange={(newValue) => setEndValue(newValue)}
                            slots={{
                              openPickerIcon: CalendarIcon,
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleScoModalClose}
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleScoModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const DesignerSco: React.FC = () => {
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [term, setTerm] = React.useState("");

  const handleTermChange = (event: SelectChangeEvent) => {
    setTerm(event.target.value as string);
  };

  const [emr1, setEmr1] = React.useState("1");

  const handleEmr1Change = (event: SelectChangeEvent) => {
    setEmr1(event.target.value as string);
  };

  const [term1, setTerm1] = React.useState("");

  const handleTerm1Change = (event: SelectChangeEvent) => {
    setTerm1(event.target.value as string);
  };

  const [term2, setTerm2] = React.useState("1");

  const handleTerm2Change = (event: SelectChangeEvent) => {
    setTerm2(event.target.value as string);
  };

  const [location, setLocation] = React.useState("");

  const handleLocationChange = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string);
  };

  const [location1, setLocation1] = React.useState("1");

  const handleLocation1Change = (event: SelectChangeEvent) => {
    setLocation1(event.target.value as string);
  };

  const [status, setStatus] = React.useState("");

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const [status1, setStatus1] = React.useState("1");

  const handleStatus1Change = (event: SelectChangeEvent) => {
    setStatus1(event.target.value as string);
  };

  const [status3, setStatus3] = React.useState("");

  const handleStatus3Change = (event: SelectChangeEvent) => {
    setStatus3(event.target.value as string);
  };

  const [enrolltype, setEnrollType] = React.useState("");

  const handleEnrolltypeChange = (event: SelectChangeEvent) => {
    setEnrollType(event.target.value as string);
  };

  const [stdname, setStdname] = React.useState("");

  const handleStdnameChange = (event: SelectChangeEvent) => {
    setStdname(event.target.value as string);
  };

  const [filterbox, setFilterbox] = React.useState(false);
  const [enrollFilterbox, setenrollFilterbox] = React.useState(false);
  const [sco, setSco] = React.useState(true);
  const [scoDetails, setSCoDetails] = React.useState(false);

  const [addScoModal, setScoModal] = React.useState(false);
  const handleScoModal = () => setScoModal(true);
  const handleScoModalClose = () => setScoModal(false);

  const [scoOfferingModal, setScoOfferingModal] = React.useState(false);
  const handleScoOfferingModal = () => setScoOfferingModal(true);
  const handleScoOfferingModalClose = () => setScoOfferingModal(false);

  const [educator, seteducator] = React.useState<string[]>([]);

  const handleEducatorChange = (event: SelectChangeEvent<typeof educator>) => {
    const {
      target: { value },
    } = event;
    seteducator(typeof value === "string" ? value.split(",") : value);
  };

  const [starttime, setStarttime] = React.useState("");

  const handleStarttimeChange = (event: SelectChangeEvent) => {
    setStarttime(event.target.value as string);
  };

  const [endtime, setEndtime] = React.useState("");

  const handleEndtimeChange = (event: SelectChangeEvent) => {
    setEndtime(event.target.value as string);
  };

  const [starttime1, setStarttime1] = React.useState("4");

  const handleStarttime1Change = (event: SelectChangeEvent) => {
    setStarttime1(event.target.value as string);
  };

  const [endtime1, setEndtime1] = React.useState("5");

  const handleEndtime1Change = (event: SelectChangeEvent) => {
    setEndtime1(event.target.value as string);
  };

  const [certiIssued, setCertiIssued] = React.useState("");

  const handleCertiIssuedChange = (event: SelectChangeEvent) => {
    setCertiIssued(event.target.value as string);
  };

  const [certiIssued1, setCertiIssued1] = React.useState("1");

  const handleCertiIssued1Change = (event: SelectChangeEvent) => {
    setCertiIssued1(event.target.value as string);
  };

  const [course1, setCourse1] = React.useState("1");

  const handleCourse1Change = (event: SelectChangeEvent) => {
    setCourse1(event.target.value as string);
  };

  const [emr, setEmr] = React.useState("");

  const handleEmrChange = (event: SelectChangeEvent) => {
    setEmr(event.target.value as string);
  };

  const [attendance, setAttendance] = React.useState(false);
  const handleAttendance = () => setAttendance(true);
  const handleAttendanceClose = () => setAttendance(false);

  const [stdlist, setStdlist] = React.useState(false);
  const handleStdlist = () => setStdlist(true);
  const handleStdlistModalClose = () => setStdlist(false);

  const [sessDate, setSessDate] = React.useState<Dayjs | null>(
    dayjs("2023-01-12")
  );

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Box
        className="filter-overlay"
        onClick={() => {
          document.body.classList.toggle("filter-open");
          setFilterbox(false);
          setenrollFilterbox(false);
        }}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        {sco && (
          <div className="main-content">
            <Box className="content-header sco-header">
              <Typography variant="h2" className="heading">
                Scheduled Course Offerings
              </Typography>
              <Box className="sco-header-btn-group">
                <Button
                  variant="contained"
                  className="btn-add"
                  onClick={handleScoModal}
                >
                  <img src={plusLightIcon} alt="plus" />
                  <span>Add</span>
                </Button>
                <Button variant="outlined" className="btn-import-old">
                  Import old enrollment
                </Button>
                <Button variant="outlined" className="btn-offering">
                  Past offerings
                </Button>
              </Box>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{ justifyContent: "space-between", width: "100%" }}
                      className="search-filter-inputs"
                    >
                      <TextField
                        id="search"
                        variant="outlined"
                        className="search-input"
                        placeholder="Search"
                      />

                      <Button
                        variant="outlined"
                        className="btn-filter"
                        onClick={() => {
                          setFilterbox(true);
                          document.body.classList.toggle("filter-open");
                        }}
                      >
                        <img
                          src={filterPrimaryIcon}
                          alt="filter"
                          className="default"
                        />
                        <img
                          src={filterLightIcon}
                          alt="filter"
                          className="active"
                        />
                        <span>Filter</span>
                      </Button>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              maxWidth: "115px",
                              width: "115px",
                              minWidth: "115px",
                            }}
                          >
                            Course Number
                          </TableCell>
                          <TableCell sx={{ width: "154px", minWidth: "154px" }}>
                            Course
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ width: "82px", minWidth: "82px" }}
                          >
                            Term
                          </TableCell>
                          <TableCell sx={{ width: "146px", minWidth: "146px" }}>
                            Location
                          </TableCell>
                          <TableCell sx={{ width: "120px", minWidth: "120px" }}>
                            No. Of Session Days
                          </TableCell>
                          <TableCell sx={{ width: "103px", minWidth: "103px" }}>
                            Certificates Issued?
                          </TableCell>
                          <TableCell sx={{ width: "75px", minWidth: "75px" }}>
                            EMR Entered
                          </TableCell>
                          <TableCell sx={{ width: "78px", minWidth: "78px" }}>
                            No. Enrolled
                          </TableCell>
                          <TableCell sx={{ width: "112px", minWidth: "112px" }}>
                            Days With Unsubmitted Attendance
                          </TableCell>
                          <TableCell align="center" sx={{ width: "102px" }}>
                            Status
                          </TableCell>
                          <TableCell align="center" sx={{ width: "92px" }}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.coursenum}
                            </TableCell>
                            <TableCell>
                              <a href="-">
                                <Typography variant="body2">
                                  {row.course}
                                </Typography>
                              </a>
                            </TableCell>
                            <TableCell align="center">{row.term}</TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {row.location}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Button
                                className="btn-course"
                                variant="outlined"
                                onClick={() => {
                                  setSCoDetails(true);
                                  setSco(false);
                                }}
                              >
                                <img
                                  src={calendarFilledDarkIcon}
                                  alt="Course List"
                                  className="default"
                                />
                                <img
                                  src={calendarFilledLightIcon}
                                  alt="Course List"
                                  className="active"
                                />
                                <Typography variant="body2">
                                  {row.days}
                                </Typography>
                              </Button>
                            </TableCell>
                            <TableCell>{row.issued}</TableCell>
                            <TableCell>{row.emr}</TableCell>
                            <TableCell>{row.enrollnum}</TableCell>
                            <TableCell>{row.attndday}</TableCell>
                            <TableCell
                              align="center"
                              className={`${row.status} status`}
                            >
                              <div>{row.status}</div>
                            </TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setSCoDetails(true);
                                    setSco(false);
                                  }}
                                >
                                  <img src={editIcon} alt="edit" />
                                </IconButton>
                                <IconButton>
                                  <img src={deleteIcon} alt="delete" />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
        {scoDetails && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Scheduled Course Offering Details
              </Typography>
              <Button
                variant="outlined"
                className="btn-back"
                onClick={() => {
                  setSCoDetails(false);
                  setSco(true);
                }}
              >
                Back
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                >
                  <Grid item xs={12} lg={8}>
                    <Card>
                      <Grid
                        container
                        columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      >
                        <Grid item xs={12} lg={3}>
                          <TextField
                            id="course-no"
                            label="Course No."
                            fullWidth
                            variant="outlined"
                            defaultValue="320605_ENG"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} lg={9}>
                          <Grid container columnSpacing="16px">
                            <Grid item xs>
                              <FormControl fullWidth className="select">
                                <InputLabel id="course1-label">
                                  Course
                                </InputLabel>
                                <Select
                                  labelId="course1-label"
                                  id="course1"
                                  label="Course"
                                  value={course1}
                                  onChange={handleCourse1Change}
                                >
                                  <MenuItem value={1}>
                                    Life Beyond Trauma
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    Life Beyond Trauma
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    Life Beyond Trauma
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs="auto">
                              <Button
                                className="btn-icon-details form-btn"
                                variant="outlined"
                                href={AppRoutings.DesignerCourses}
                              >
                                <img
                                  src={courseListIcon}
                                  alt="icon"
                                  className="default"
                                />
                                <img
                                  src={courseListLightIcon}
                                  alt="icon"
                                  className="active"
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                              labelId="status-label"
                              id="status"
                              label="Status"
                              value={status1}
                              onChange={handleStatus1Change}
                            >
                              <MenuItem value={1}>Confirmed</MenuItem>
                              <MenuItem value={2}>Withdrawn</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="term-select-label">Term</InputLabel>
                            <Select
                              labelId="term-select-label"
                              id="term-select"
                              label="Term"
                              value={term2}
                              onChange={handleTerm2Change}
                            >
                              <MenuItem value={1}>2022 T4</MenuItem>
                              <MenuItem value={2}>2022 T1</MenuItem>
                              <MenuItem value={3}>2022 T2</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <TextField
                            id="offer-days"
                            label="Offering Days"
                            fullWidth
                            variant="outlined"
                            defaultValue="4"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <TextField
                            id="term-status"
                            label="Term Status"
                            fullWidth
                            variant="outlined"
                            defaultValue="Past Term"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="location1-label">
                              Location
                            </InputLabel>
                            <Select
                              labelId="location1-label"
                              id="location1"
                              label="Location"
                              value={location1}
                              onChange={handleLocation1Change}
                            >
                              <MenuItem value={1}>
                                151 Goswell Road London
                              </MenuItem>
                              <MenuItem value={2}>143 Pinkville Road </MenuItem>
                              <MenuItem value={3}>
                                104 ShopMart Complex
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopDatePicker"]}>
                              <DesktopDatePicker
                                className="date-picker"
                                label="Date Of First Session"
                                slotProps={{ textField: { fullWidth: true } }}
                                slots={{
                                  openPickerIcon: CalendarIcon,
                                }}
                                format="DD MMM YYYY"
                                value={sessDate}
                                onChange={(newValue) => setSessDate(newValue)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="start-time-label">
                              Start Time
                            </InputLabel>
                            <Select
                              labelId="start-time-label"
                              id="start-time"
                              label="Start Time"
                              value={starttime1}
                              onChange={handleStarttime1Change}
                            >
                              <MenuItem value={1}>09:00</MenuItem>
                              <MenuItem value={2}>10:00</MenuItem>
                              <MenuItem value={3}>11:00</MenuItem>
                              <MenuItem value={4}>12:00</MenuItem>
                              <MenuItem value={5}>13:00</MenuItem>
                              <MenuItem value={6}>14:00</MenuItem>
                              <MenuItem value={7}>15:00</MenuItem>
                              <MenuItem value={8}>16:00</MenuItem>
                              <MenuItem value={9}>17:00</MenuItem>
                              <MenuItem value={10}>18:00</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="end-time-label">
                              End Time
                            </InputLabel>
                            <Select
                              labelId="end-time-label"
                              id="end-time"
                              label="End Time"
                              value={endtime1}
                              onChange={handleEndtime1Change}
                            >
                              <MenuItem value={1}>10:00</MenuItem>
                              <MenuItem value={2}>11:00</MenuItem>
                              <MenuItem value={3}>12:00</MenuItem>
                              <MenuItem value={4}>13:00</MenuItem>
                              <MenuItem value={5}>14:00</MenuItem>
                              <MenuItem value={6}>15:00</MenuItem>
                              <MenuItem value={7}>16:00</MenuItem>
                              <MenuItem value={8}>17:00</MenuItem>
                              <MenuItem value={9}>18:00</MenuItem>
                              <MenuItem value={10}>19:00</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="certi-issued1-label">
                              Certificates Issued
                            </InputLabel>
                            <Select
                              labelId="certi-issued1-label"
                              id="certi-issued1"
                              label="Certificates Issued"
                              value={certiIssued1}
                              onChange={handleCertiIssued1Change}
                            >
                              <MenuItem value={1}>No</MenuItem>
                              <MenuItem value={2}>Yes</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="emr-entered-label">
                              EMR Entered
                            </InputLabel>
                            <Select
                              labelId="emr-entered-label"
                              id="emr-entered"
                              label="EMR Entered"
                              value={emr1}
                              onChange={handleEmr1Change}
                            >
                              <MenuItem value={1}>No</MenuItem>
                              <MenuItem value={2}>Yes</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Box className="btn-group">
                        <Button variant="contained" className="btn-save">
                          <img src={saveIcon} alt="save" />
                          Save
                        </Button>
                        <Button variant="outlined" className="btn-cancel">
                          Cancel
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Card>
                      <List className="form-list">
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Blank Evaluation Forms" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Completed Evaluation Forms" />
                            <img src={pdfIcon} alt="pdf" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Completed Learning Outcomes forms" />
                            <img src={pdfIcon} alt="pdf" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Researcher’s Summary" />
                            <img src={pdfIcon} alt="pdf" />
                          </ListItemButton>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemText primary="Educator Feedback Form" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Typography variant="h3" className="attnd-heading">
                        Educators
                      </Typography>
                      <Grid
                        container
                        spacing={{ xs: "16px", md: "20px", lg: "24px" }}
                      >
                        <Grid item xs={12} sm={6} xl={3}>
                          <a href="-" className="educators-card">
                            <img src={userIcon} alt="user" />
                            <Box>
                              <Typography variant="body2" className="name">
                                Liana Carmen Lapuz
                              </Typography>
                              <Typography
                                variant="body2"
                                className="designation"
                              >
                                Clinical Educator
                              </Typography>
                            </Box>
                          </a>
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                          <a href="-" className="educators-card">
                            <img src={userIcon} alt="user" />
                            <Box>
                              <Typography variant="body2" className="name">
                                Melissa Nakhle
                              </Typography>
                              <Typography
                                variant="body2"
                                className="designation"
                              >
                                Peer Educator
                              </Typography>
                            </Box>
                          </a>
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                          <a href="-" className="educators-card">
                            <img src={userIcon} alt="user" />
                            <Box>
                              <Typography variant="body2" className="name">
                                Michelle McGerrell
                              </Typography>
                              <Typography
                                variant="body2"
                                className="designation"
                              >
                                Clinical Educator
                              </Typography>
                            </Box>
                          </a>
                        </Grid>
                        <Grid item xs={12} sm={6} xl={3}>
                          <a href="-" className="educators-card">
                            <img src={userIcon} alt="user" />
                            <Box>
                              <Typography variant="body2" className="name">
                                Michelle McGerrell
                              </Typography>
                              <Typography
                                variant="body2"
                                className="designation"
                              >
                                Clinical Educator
                              </Typography>
                            </Box>
                          </a>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Box className="table-card-header">
                        <Box
                          sx={{
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            flexWrap: "wrap",
                            rowGap: "16px",
                          }}
                        >
                          <Typography variant="h3">
                            Session Days And Attendance
                          </Typography>

                          <Button
                            variant="outlined"
                            className="btn-ascod"
                            sx={{ paddingLeft: "15px", paddingRight: "15px" }}
                            onClick={handleScoOfferingModal}
                          >
                            Add Schedule Course Offering Days
                          </Button>
                        </Box>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "153px",
                                  minWidth: "153px",
                                  [projectTheme.breakpoints.down("lg")]: {
                                    width: "110px",
                                    minWidth: "110px",
                                  },
                                }}
                              >
                                Day Number
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "220px",
                                  minWidth: "220px",
                                  [projectTheme.breakpoints.down("lg")]: {
                                    width: "190px",
                                    minWidth: "190px",
                                  },
                                }}
                              >
                                Students Who Attended
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "200px",
                                  minWidth: "200px",
                                  [projectTheme.breakpoints.down("lg")]: {
                                    width: "164px",
                                    minWidth: "164px",
                                  },
                                }}
                              >
                                Date of Offering Day
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "162px",
                                  minWidth: "162px",
                                  [projectTheme.breakpoints.down("lg")]: {
                                    width: "152px",
                                    minWidth: "152px",
                                  },
                                }}
                              >
                                Number Attended
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "201px",
                                  minWidth: "201px",
                                  [projectTheme.breakpoints.down("lg")]: {
                                    width: "184px",
                                    minWidth: "184px",
                                  },
                                }}
                              >
                                Attendance Submitted
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "144px",
                                  minWidth: "144px",
                                }}
                              >
                                Enter Attendance
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "112px",
                                  [projectTheme.breakpoints.down("sm")]: {
                                    width: "80px",
                                  },
                                }}
                              >
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sessDaysandAttndrow.map((row) => (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {row.dayno}
                                </TableCell>
                                <TableCell>
                                  <Button
                                    className="btn-course"
                                    variant="outlined"
                                    onClick={handleStdlist}
                                  >
                                    <img
                                      src={userSmallIcon}
                                      alt="Course List"
                                      className="default"
                                    />
                                    <img
                                      src={userSmallLightIcon}
                                      alt="Course List"
                                      className="active"
                                    />
                                    <Typography variant="body2">
                                      {row.stdattend}
                                    </Typography>
                                  </Button>
                                </TableCell>
                                <TableCell>{row.dood}</TableCell>
                                <TableCell>{row.attendno}</TableCell>
                                <TableCell>{row.attendance}</TableCell>
                                <TableCell align="center">
                                  <div className="table-actions">
                                    <IconButton onClick={handleAttendance}>
                                      <img src={attendanceIcon} alt="edit" />
                                    </IconButton>
                                  </div>
                                </TableCell>
                                <TableCell align="center">
                                  <div className="table-actions">
                                    <IconButton
                                      onClick={handleScoOfferingModal}
                                    >
                                      <img src={editIcon} alt="edit" />
                                    </IconButton>
                                    <IconButton>
                                      <img src={deleteIcon} alt="delete" />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Box className="table-card-header">
                        <Box
                          sx={{
                            justifyContent: "space-between",
                            width: { xs: "100%", lg: "auto" },
                          }}
                        >
                          <Typography variant="h3">Enrollments</Typography>
                          <Button
                            variant="outlined"
                            className="btn-filter"
                            sx={{
                              display: { xs: "flex", lg: "none" },
                            }}
                            onClick={() => {
                              setenrollFilterbox(true);
                              document.body.classList.toggle("filter-open");
                            }}
                          >
                            <img
                              src={filterPrimaryIcon}
                              alt="filter"
                              className="default"
                            />
                            <img
                              src={filterLightIcon}
                              alt="filter"
                              className="active"
                            />
                            Filter
                          </Button>
                        </Box>
                        <Box>
                          <Box className="counter-box info">
                            <div className="title">Enrolled</div>
                            <div className="counter">28</div>
                          </Box>
                          <Box className="counter-box success">
                            <div className="title">Attending</div>
                            <div className="counter">16</div>
                          </Box>
                          <Box className="counter-box warning">
                            <div className="title">Withdrawn</div>
                            <div className="counter">12</div>
                          </Box>
                          <Button
                            variant="outlined"
                            className="btn-filter"
                            sx={{
                              display: { xs: "none", lg: "flex" },
                            }}
                            onClick={() => {
                              setenrollFilterbox(true);
                              document.body.classList.toggle("filter-open");
                            }}
                          >
                            <img
                              src={filterPrimaryIcon}
                              alt="filter"
                              className="default"
                            />
                            <img
                              src={filterLightIcon}
                              alt="filter"
                              className="active"
                            />
                            Filter
                          </Button>
                        </Box>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "153px",
                                  minWidth: "153px",
                                }}
                              >
                                Student Name
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "116px",
                                  minWidth: "116px",
                                }}
                              >
                                Student No.
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "99px",
                                  minWidth: "99px",
                                }}
                              >
                                MRN
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "171px",
                                  minWidth: "171px",
                                  [projectTheme.breakpoints.down("lg")]: {
                                    width: "165px",
                                    minWidth: "165px",
                                  },
                                }}
                              >
                                Date Enrolled
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "138px",
                                  minWidth: "138px",
                                }}
                              >
                                Enrolment Type
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "133px",
                                  minWidth: "133px",
                                }}
                              >
                                Status
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "144px",
                                  minWidth: "144px",
                                }}
                              >
                                Mobile
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{
                                  width: "238px",
                                  minWidth: "238px",
                                }}
                              >
                                Email
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {enrollmentrow.map((row) => (
                              <TableRow>
                                <TableCell component="th" scope="row">
                                  {row.stdname}
                                </TableCell>
                                <TableCell>{row.stdno}</TableCell>
                                <TableCell>{row.mrn}</TableCell>
                                <TableCell>{row.enrolldate}</TableCell>
                                <TableCell>{row.enrolltype}</TableCell>
                                <TableCell
                                  align="center"
                                  className={`${row.status} status status-left`}
                                >
                                  <div>{row.status}</div>
                                </TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>{row.email}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box className="custom-pagination">
                        <Box className="custom-rowperpage">
                          <Typography variant="body2" component="span">
                            Page:
                          </Typography>
                          <Select
                            id="pagination-select"
                            value={page}
                            onChange={handleChange}
                            MenuProps={{
                              className: "pagination-menu",
                            }}
                          >
                            <MenuItem value={5} selected>
                              5
                            </MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                          </Select>
                          <Typography variant="body2" component="span">
                            Showing 10 - 20 of 50 entries
                          </Typography>{" "}
                        </Box>
                        <Pagination
                          count={5}
                          variant="outlined"
                          shape="rounded"
                          renderItem={(item) => (
                            <PaginationItem
                              slots={{
                                previous: ArrowBackIcon,
                                next: ArrowForwardIcon,
                              }}
                              {...item}
                            />
                          )}
                        />
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
      </main>

      <Modal open={addScoModal} onClose={handleScoModalClose}>
        <Box className="common-modal addsco-modal">
          <Box className="modal-header">
            <Typography variant="h4">Scheduled Course Offerings</Typography>
            <IconButton onClick={handleScoModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Grid
              container
              columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
            >
              <Grid item xs={12}>
                <TextField
                  id="course"
                  label="Course"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <FormControl fullWidth className="select">
                  <InputLabel id="location-select-label">Location</InputLabel>
                  <Select
                    labelId="location-select-label"
                    id="location-select"
                    label="Location"
                    value={location}
                    onChange={handleLocationChange}
                  >
                    <MenuItem value={1}>Item 1</MenuItem>
                    <MenuItem value={2}>Item 2</MenuItem>
                    <MenuItem value={3}>Item 3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth className="select">
                  <InputLabel id="term-select-1-label">Term</InputLabel>
                  <Select
                    labelId="term-select-1-label"
                    id="term-select-1"
                    label="Term"
                    value={term1}
                    onChange={handleTerm1Change}
                  >
                    <MenuItem value={1}>2022 T4</MenuItem>
                    <MenuItem value={2}>2022 T1</MenuItem>
                    <MenuItem value={3}>2022 T2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <FormControl fullWidth className="select">
                  <InputLabel id="educator-type-label">Educator</InputLabel>
                  <Select
                    labelId="educator-type-label"
                    id="educator-type"
                    multiple
                    label="Educator"
                    value={educator}
                    onChange={handleEducatorChange}
                    input={<OutlinedInput label="Educator" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={{
                      className: "educator-type-select",
                    }}
                  >
                    {educators.map((selecteducator) => (
                      <MenuItem key={selecteducator} value={selecteducator}>
                        <Checkbox
                          checked={educator.indexOf(selecteducator) > -1}
                          icon={<CheckboxIcon />}
                          checkedIcon={<CheckedboxIcon />}
                        />
                        <ListItemText primary={selecteducator} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="max-enroll"
                  label="Max. Enrollments"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Typography variant="body1" className="date-label">
              Date Time of First Session
            </Typography>
            <Box className="datetime-container">
              <Box className="datetime-input">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <DesktopDatePicker
                      className="date-picker"
                      label="Start Date"
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <FormControl fullWidth className="select">
                  <InputLabel id="start-time-label">Start Time</InputLabel>
                  <Select
                    labelId="start-time-label"
                    id="start-time"
                    label="Start Time"
                    value={starttime}
                    onChange={handleStarttimeChange}
                  >
                    <MenuItem value={1}>09:00</MenuItem>
                    <MenuItem value={2}>10:00</MenuItem>
                    <MenuItem value={3}>11:00</MenuItem>
                    <MenuItem value={4}>12:00</MenuItem>
                    <MenuItem value={5}>13:00</MenuItem>
                    <MenuItem value={6}>14:00</MenuItem>
                    <MenuItem value={7}>15:00</MenuItem>
                    <MenuItem value={8}>16:00</MenuItem>
                    <MenuItem value={9}>17:00</MenuItem>
                    <MenuItem value={10}>18:00</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Typography variant="body2" className="datetime-divider">
                to
              </Typography>
              <Box className="datetime-input">
                <FormControl fullWidth className="select">
                  <InputLabel id="end-time-label">End Time</InputLabel>
                  <Select
                    labelId="end-time-label"
                    id="end-time"
                    label="End Time"
                    value={endtime}
                    onChange={handleEndtimeChange}
                  >
                    <MenuItem value={1}>10:00</MenuItem>
                    <MenuItem value={2}>11:00</MenuItem>
                    <MenuItem value={3}>12:00</MenuItem>
                    <MenuItem value={4}>13:00</MenuItem>
                    <MenuItem value={5}>14:00</MenuItem>
                    <MenuItem value={6}>15:00</MenuItem>
                    <MenuItem value={7}>16:00</MenuItem>
                    <MenuItem value={8}>17:00</MenuItem>
                    <MenuItem value={9}>18:00</MenuItem>
                    <MenuItem value={10}>19:00</MenuItem>
                  </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <DesktopDatePicker
                      className="date-picker"
                      label="End Date"
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
            <ChildModal />
            <Grid
              container
              columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
            >
              <Grid item xs={12} sm={6} lg={4}>
                <FormControl fullWidth className="select">
                  <InputLabel id="certi-issued-label">
                    Certificates Issued
                  </InputLabel>
                  <Select
                    labelId="certi-issued-label"
                    id="certi-issued"
                    label="Certificates Issued "
                    value={certiIssued}
                    onChange={handleCertiIssuedChange}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <FormControl fullWidth className="select">
                  <InputLabel id="emr-entered-label">EMR Entered</InputLabel>
                  <Select
                    labelId="emr-entered-label"
                    id="emr-entered"
                    label="EMR Entered "
                    value={emr}
                    onChange={handleEmrChange}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg>
                <Box className="upload-group">
                  <Typography variant="body1" className="upload-label">
                    Blank Evaluation Form
                  </Typography>
                  <Button
                    variant="outlined"
                    component="label"
                    className="btn-upload"
                  >
                    <img
                      src={cloudPrimaryIcon}
                      alt="cloud"
                      className="default"
                    />
                    <img src={cloudLightIcon} alt="cloud" className="active" />
                    Upload
                    <input hidden accept="image/*" multiple type="file" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-continue"
              onClick={() => {
                setScoOfferingModal(true);
                setScoModal(false);
              }}
            >
              Continue
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleScoModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={scoOfferingModal} onClose={handleScoOfferingModalClose}>
        <Box className="common-modal addsco-modal addsco-offering-modal">
          <Box className="modal-header">
            <Typography variant="h4">
              Add Scheduled Course Offering Days
            </Typography>
            <IconButton onClick={handleScoOfferingModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Grid container rowSpacing={{ xs: "16px", md: "20px", lg: "24px" }}>
              <Grid item xs={12}>
                <Grid
                  container
                  columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                  rowSpacing="16px"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={5} md={3}>
                    <TextField
                      id="day-hum"
                      label="Day Number"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DesktopDatePicker"]}>
                        <DesktopDatePicker
                          className="date-picker"
                          label="Date Of Offering Day"
                          slots={{
                            openPickerIcon: CalendarIcon,
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={2} md>
                    <Button variant="contained" className="btn-add-small">
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className="date-label">
                  <strong>Life Beyond Trauma</strong> [2023 T1]- &340;E-0,
                  WL-0,Max -25 - Open&#41; at Recovery and wellbeing college,
                  Kogarah BLENDED, Face to Face / Online 01 Feb 2023 10:00 AM to
                  4:00 PM
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            width: "76px",
                            minWidth: "76px",
                          }}
                        >
                          Day No.
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "156px", minWidth: "156px" }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ maxWidth: "136px" }}
                          >
                            Students Who Attended
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "167px", minWidth: "167px" }}
                        >
                          Date of Offering Day
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ minWidth: "126px", width: "126px" }}
                        >
                          No. Attended
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "130px", minWidth: "130px" }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ maxWidth: "110px" }}
                          >
                            Attendance Submitted
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            minWidth: "115px",
                            [projectTheme.breakpoints.down("sm")]: {
                              minWidth: "105px",
                            },
                          }}
                        >
                          View Details
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addSchedulerow.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {row.dayno}
                          </TableCell>
                          <TableCell>
                            <Button className="btn-course" variant="outlined">
                              <img
                                src={userSmallIcon}
                                alt="Course List"
                                className="default"
                              />
                              <img
                                src={userSmallLightIcon}
                                alt="Course List"
                                className="active"
                              />
                              <Typography variant="body2">
                                {row.stdattend}
                              </Typography>
                            </Button>
                          </TableCell>
                          <TableCell>{row.dood}</TableCell>
                          <TableCell>{row.attendno}</TableCell>
                          <TableCell>{row.attendance}</TableCell>
                          <TableCell align="center">
                            <div className="table-actions">
                              <IconButton>
                                <img src={courseListSecondaryIcon} alt="list" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleScoOfferingModalClose}
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleScoOfferingModalClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={attendance} onClose={handleAttendanceClose}>
        <Box className="common-modal attendance-modal">
          <Box className="modal-header">
            <Typography variant="h4">Enter Attendance</Typography>
            <IconButton onClick={handleAttendanceClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ maxWidth: "646px", minWidth: "195px" }}
                    >
                      Student
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ width: "128px", minWidth: "128px" }}
                    >
                      Fill Attendance
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendancerows.map((row) => {
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {row.student}
                        </TableCell>
                        <TableCell align="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                disableFocusRipple
                                disableRipple
                                icon={<CheckboxIcon />}
                                checkedIcon={<CheckedboxIcon />}
                              />
                            }
                            label={undefined}
                            labelPlacement="end"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleAttendanceClose}
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleAttendanceClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={stdlist} onClose={handleStdlistModalClose}>
        <Box className="common-modal std-attended-modal">
          <Box className="modal-header">
            <Typography variant="h4">Students Attended</Typography>
            <IconButton onClick={handleStdlistModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ width: "340px", minWidth: "340px" }}
                    >
                      Students Who Attended
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Shane Lincoin
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Rachael Rogan
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Michale Conte
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Katherina Stufano
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Kamara Ducki
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Lydia Lin
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Hope Stothers
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Modal>

      {filterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <TextField
              id="course-num"
              label="Course Number "
              fullWidth
              variant="outlined"
            />
            <FormControl fullWidth className="select">
              <InputLabel id="term-select-label">Select Term</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                label="Select Term"
                value={term}
                onChange={handleTermChange}
              >
                <MenuItem value={1}>2022 T4</MenuItem>
                <MenuItem value={2}>2022 T1</MenuItem>
                <MenuItem value={3}>2022 T2</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                label="Status "
                value={status}
                onChange={handleStatusChange}
              >
                <MenuItem value={1}>Confirmed</MenuItem>
                <MenuItem value={2}>Withdrawn</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}

      {enrollFilterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setenrollFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="select">
              <InputLabel id="std-name-label">Student Name</InputLabel>
              <Select
                labelId="std-name-label"
                id="std-name"
                label="Student Name"
                value={stdname}
                onChange={handleStdnameChange}
              >
                <MenuItem value={1}>Rachael Rogan</MenuItem>
                <MenuItem value={2}>Michale Conte</MenuItem>
                <MenuItem value={3}>Katherina Stufano</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="std-num"
              label="Student Number"
              fullWidth
              variant="outlined"
            />
            <TextField id="mrn" label="MRN" fullWidth variant="outlined" />
            <FormControl fullWidth className="select">
              <InputLabel id="enrol-type-label">Enrollment Type</InputLabel>
              <Select
                labelId="enrol-type-label"
                id="enrol-type"
                label="Enrolment Type"
                value={enrolltype}
                onChange={handleEnrolltypeChange}
              >
                <MenuItem value={1}>Consumer</MenuItem>
                <MenuItem value={2}>Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="select">
              <InputLabel id="status3-label">Status</InputLabel>
              <Select
                labelId="status3-label"
                id="status3"
                label="Status"
                value={status3}
                onChange={handleStatus3Change}
              >
                <MenuItem value={1}>Confirmed</MenuItem>
                <MenuItem value={2}>Withdrawn</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setenrollFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default DesignerSco;
