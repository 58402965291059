import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  closeIcon,
  deleteIcon,
  editIcon,
  plusLightIcon,
  saveIcon,
} from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";
import projectTheme from "app.theme";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

interface LocationData {
  location: string;
}

function createLocationData(location: string): LocationData {
  return {
    location,
  };
}

const locationrows = [
  createLocationData("Recovery and wellbeing college, Kogarah BLENDED"),
  createLocationData("151 Goswell Road London "),
  createLocationData("Recovery and wellbeing college, Kogarah BLENDED"),
  createLocationData("Recovery and wellbeing college, Kogarah BLENDED"),
  createLocationData("Educator"),
  createLocationData("St Rose Valley - 111"),
  createLocationData("104 ShopMart Complex"),
  createLocationData("St Rose Valley - 11"),
  createLocationData("104 ShopMart Complex"),
  createLocationData("Rose Valley 40B"),
  createLocationData("Rose Valley 40B"),
];

const DesignerLocation: React.FC = () => {
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [location, setLocation] = React.useState(false);
  const handleLocation = () => setLocation(true);
  const handleLocationClose = () => setLocation(false);

  const [locationdlt, setLocationdlt] = React.useState(false);
  const handleLocationdlt = () => setLocationdlt(true);
  const handleLocationdltClose = () => setLocationdlt(false);

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        <div className="main-content">
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Locations
            </Typography>
            <Button
              variant="contained"
              onClick={handleLocation}
              className="btn-add"
            >
              <img src={plusLightIcon} alt="plus" />
              Add
            </Button>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card>
                <Box className="table-card-header">
                  <Box>
                    <TextField
                      id="search"
                      variant="outlined"
                      className="search-input"
                      placeholder="Search"
                    />
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Location</TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            width: "152px",
                            [projectTheme.breakpoints.down("sm")]: {
                              width: "112px",
                            },
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {locationrows.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {row.location}
                          </TableCell>
                          <TableCell align="center">
                            <div className="table-actions">
                              <IconButton onClick={handleLocation}>
                                <img src={editIcon} alt="edit" />
                              </IconButton>
                              <IconButton onClick={handleLocationdlt}>
                                <img src={deleteIcon} alt="delete" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      <MenuItem value={5} selected>
                        5
                      </MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                    </Select>
                    <Typography variant="body2" component="span">
                      Showing 10 - 20 of 50 entries
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={5}
                    variant="outlined"
                    shape="rounded"
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
          <DesignerFooter />
        </div>
      </main>
      <Modal open={location} onClose={handleLocationClose}>
        <Box className="common-modal location-modal">
          <Box className="modal-header">
            <Typography variant="h4">Add Location</Typography>
            <IconButton onClick={handleLocationClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <TextField
              id="location"
              label="Location"
              fullWidth
              variant="outlined"
              multiline
            />
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleLocationClose}
            >
              <img src={saveIcon} alt="save" />
              Save
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleLocationClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={locationdlt} onClose={handleLocationdltClose}>
        <Box className="common-modal delete-modal">
          <Box className="modal-header">
            <Typography variant="h4">Delete Confirmation</Typography>
            <IconButton onClick={handleLocationClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Typography variant="h5">
              Are you sure you want to delete location?
            </Typography>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleLocationdltClose}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleLocationdltClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DesignerLocation;
