import {
  calendarFilledDarkIcon,
  calendarFilledLightIcon,
  closeIcon,
  courseListSecondaryIcon,
  downloadIcon,
  filterLightIcon,
  filterPrimaryIcon,
} from "assets/images";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import NoRecords from "components/NoRecords";
import {
  Button,
  Card,
  Fade,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  APPROVED_STATUS,
  BLENDED_FACE_TO_FACE_ONLINE,
  CLEAR_TOOLTIP_TITLE,
  COURSE_OFFERING_TYPE,
  DATE_VIEW_FORMAT,
  MY_ENROLMENT_COURSES,
  PAGE_SIZES,
  PAST_COURSE_TYPE,
  TIME_VIEW_FORMAT,
  UPCOMING_COURSE_TYPE,
  VIEW_TOOLTIP_TITLE,
} from "utils/constants/constant";
import { AppRoutings } from "utils/enums/app-routings";
import { Order } from "utils/enums/sorting";
import {
  convertToDateTimeWithFormat,
  getPaginationDetailText,
  onTextFieldKeyPress,
} from "utils/helper";
import projectTheme from "app.theme";
import {
  IEnhancedTableProps,
  IUpcomingCourse,
} from "utils/interfaces/student-dashboard";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

interface IStudentCoursesTableProps {
  coursesList: any;
  pagePermission: any;
  courseListMeta: any;
  handleRequestSort: (event: React.MouseEvent<unknown>, value: any) => void;
  order: Order;
  orderBy: keyof IUpcomingCourse;
  handleChange: (event: SelectChangeEvent) => void;
  onPageChange: (e: any, value: number) => void;
  page: string;
  pageNo: number;
  courseListType: string;
  handleFilter?: () => void;
  searchText: string;
  handleSearch: (event: any) => void;
  handleSearchKeyDown: (e: any) => void;
  handleClearSearch: () => void;
  downloadCertificate?: any;
  isUpComingCourse?: boolean;
}

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IUpcomingCourse) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

const StudentCourseTable = (props: IStudentCoursesTableProps) => {
  const history = useHistory();
  const {
    coursesList,
    pagePermission,
    courseListMeta,
    handleRequestSort,
    order,
    orderBy,
    page,
    pageNo,
    handleChange,
    onPageChange,
    courseListType,
    handleFilter,
    searchText,
    handleSearch,
    handleSearchKeyDown,
    handleClearSearch,
    downloadCertificate,
    isUpComingCourse,
  } = props;

  const user = useSelector((state: any) => state?.auth?.user);

  return (
    <Card>
      <Box className="table-card-header">
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            columnGap: "18px",
            width: "100%",
            paddingRight: "20px",
          }}
        >
          <Typography variant="h3">{courseListType}</Typography>
          <TextField
            id="search-box"
            variant="outlined"
            className="search-input"
            placeholder="Search"
            value={searchText}
            onChange={handleSearch}
            onKeyDown={handleSearchKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{ position: "absolute", right: "0" }}
                  position="end"
                >
                  {searchText && (
                    <Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      title={CLEAR_TOOLTIP_TITLE}
                      arrow
                    >
                      <IconButton edge="end" onClick={handleClearSearch}>
                        <img src={closeIcon} alt="close" />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
            onKeyPress={(event) =>
              onTextFieldKeyPress(event, searchText.length)
            }
          />
        </Box>
        <Button
          variant="outlined"
          className="btn-filter"
          onClick={handleFilter}
        >
          <img src={filterPrimaryIcon} alt="filter" className="default" />
          <img src={filterLightIcon} alt="filter" className="active" />
          <span>Filter</span>
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(e: any) => handleRequestSort(e, "courseName")}
                fieldName="Course"
                keyName="courseName"
                sxStyle={{
                  width: "302px",
                  minWidth: "302px",
                  [projectTheme.breakpoints.down("lg")]: {
                    width: "210px",
                    minWidth: "210px",
                  },
                }}
              />
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(e) => handleRequestSort(e, "term")}
                fieldName="Term"
                keyName="term"
                sxStyle={{
                  width: "86px",
                  minWidth: "86px",
                }}
              />
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(e) => handleRequestSort(e, "location")}
                fieldName="Location"
                keyName="location"
                sxStyle={{
                  width: "345px",
                  minWidth: "345px",
                }}
              />
              {[UPCOMING_COURSE_TYPE, MY_ENROLMENT_COURSES].includes(
                courseListType
              ) && (
                <TableCell
                  align="center"
                  sx={{ maxWidth: "157px", width: "157px" }}
                >
                  No.Of Session Days
                </TableCell>
              )}

              {courseListType === MY_ENROLMENT_COURSES && (
                <TableCell
                  align="center"
                  sx={{ maxWidth: "170px", width: "170px" }}
                >
                  Enrolment Status
                </TableCell>
              )}

              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(e) => handleRequestSort(e, "firstDateTime")}
                fieldName="Date & Time of First Session"
                keyName="firstDateTime"
                sxStyle={{
                  width: "225px",
                  minWidth: "225px",
                }}
              />
              <TableCell
                align={
                  courseListType === COURSE_OFFERING_TYPE ||
                  courseListType === UPCOMING_COURSE_TYPE
                    ? "center"
                    : "left"
                }
                sx={{
                  width: "88px",
                  [projectTheme.breakpoints.down("sm")]: {
                    width: "80px",
                  },
                }}
              >
                {courseListType === COURSE_OFFERING_TYPE
                  ? "View And Enrol"
                  : "Details"}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coursesList?.map((row: any) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="body2"
                    className="location sco-list-word-wrap"
                  >
                    {row.courseName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    className="location sco-list-word-wrap"
                  >
                    {row.term}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    className="location sco-list-word-wrap"
                  >
                    {row.location}
                    {row.isBlended && BLENDED_FACE_TO_FACE_ONLINE}
                  </Typography>
                </TableCell>
                {[UPCOMING_COURSE_TYPE, MY_ENROLMENT_COURSES].includes(
                  courseListType
                ) && (
                  <TableCell align="center">
                    <Button
                      disabled={!pagePermission?.view}
                      className={
                        !pagePermission?.view
                          ? "btn-course disabled-icon"
                          : "btn-course"
                      }
                      variant="outlined"
                      onClick={() => {
                        const isMyEnrolmentCourse =
                          courseListType === MY_ENROLMENT_COURSES;
                        const targetPathname = isMyEnrolmentCourse
                          ? AppRoutings.MyEnrolmentCourseDetails
                          : AppRoutings.StudentDashboardCourseDetails;

                        const courseOfferingId = isMyEnrolmentCourse
                          ? row?.courseOfferingId
                          : row?.id;

                        history.push({
                          pathname: targetPathname,
                          state: {
                            courseOfferingId,
                            studentId: user?.studentId,
                            isUpComingCourse,
                            studentCourseEnrolmentId:
                              row?.studentCourseEnrolmentId,
                          },
                        });
                      }}
                    >
                      <img
                        src={calendarFilledDarkIcon}
                        alt="Course List"
                        className="default"
                      />
                      <img
                        src={calendarFilledLightIcon}
                        alt="Course List"
                        className="active"
                      />
                      <Typography variant="body2">
                        {row.noOfSessionDays}
                      </Typography>
                    </Button>
                  </TableCell>
                )}
                {courseListType === MY_ENROLMENT_COURSES && (
                  <TableCell
                    align="center"
                    className={`${row.enrolmentStatus.toLocaleLowerCase()} status`}
                  >
                    {row.enrolmentStatus === APPROVED_STATUS ? (
                      <div>Confirmed</div>
                    ) : (
                      <div>{row.enrolmentStatus}</div>
                    )}
                  </TableCell>
                )}

                {courseListType === MY_ENROLMENT_COURSES ? (
                  <TableCell>
                    {" "}
                    {convertToDateTimeWithFormat(
                      row.startDateOfFirstSession,
                      DATE_VIEW_FORMAT
                    )}{" "}
                    {convertToDateTimeWithFormat(
                      row.startTimeOfFirstSession,
                      TIME_VIEW_FORMAT
                    )}{" "}
                    {"To"}{" "}
                    {convertToDateTimeWithFormat(
                      row.endTimeOfFirstSession,
                      TIME_VIEW_FORMAT
                    )}
                  </TableCell>
                ) : (
                  <TableCell>
                    {" "}
                    {convertToDateTimeWithFormat(
                      row.startDateOfFirstSession,
                      DATE_VIEW_FORMAT
                    )}{" "}
                    {convertToDateTimeWithFormat(
                      row.startTimeOfFirstSession,
                      TIME_VIEW_FORMAT
                    )}{" "}
                    {"To"}{" "}
                    {convertToDateTimeWithFormat(
                      row.endTimeOfFirstSession,
                      TIME_VIEW_FORMAT
                    )}
                  </TableCell>
                )}
                <TableCell align="left">
                  <div
                    className={`table-actions ${
                      courseListType === COURSE_OFFERING_TYPE ||
                      courseListType === UPCOMING_COURSE_TYPE
                        ? ""
                        : "enrolment-sco-actions"
                    }`}
                  >
                    <Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      title={VIEW_TOOLTIP_TITLE}
                      arrow
                    >
                      <IconButton
                        onClick={() => {
                          const isMyEnrolmentCourse =
                            courseListType === MY_ENROLMENT_COURSES;
                          const targetPathname = isMyEnrolmentCourse
                            ? AppRoutings.MyEnrolmentCourseDetails
                            : AppRoutings.StudentDashboardCourseDetails;

                          const courseOfferingId = isMyEnrolmentCourse
                            ? row?.courseOfferingId
                            : row?.id;
                          history.push({
                            pathname: targetPathname,
                            state: {
                              courseOfferingId,
                              studentId: user?.studentId,
                              studentCourseEnrolmentId:
                                row?.studentCourseEnrolmentId,
                              isUpComingCourse,
                            },
                          });
                        }}
                        disabled={!props?.pagePermission?.edit}
                        className={
                          !props?.pagePermission?.edit ? "disabled-icon" : ""
                        }
                      >
                        <img src={courseListSecondaryIcon} alt="list" />
                      </IconButton>
                    </Tooltip>
                    {courseListType === PAST_COURSE_TYPE &&
                      row.isCertificateIssued && (
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title="Download Certificate"
                          arrow
                        >
                          <IconButton
                            onClick={() =>
                              downloadCertificate(row.studentCourseEnrolmentId)
                            }
                            disabled={!props?.pagePermission?.view}
                            className={
                              !props?.pagePermission?.edit
                                ? "disabled-icon"
                                : "download-btn"
                            }
                          >
                            <img src={downloadIcon} alt="list" />
                          </IconButton>
                        </Tooltip>
                      )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {coursesList && coursesList.length > 0 ? (
        <Box className="custom-pagination">
          <Box className="custom-rowperpage">
            <Typography variant="body2" component="span">
              Page:
            </Typography>
            <Select
              id="pagination-select"
              value={page}
              onChange={handleChange}
              MenuProps={{
                className: "pagination-menu",
              }}
            >
              {PAGE_SIZES?.map((pageSize) => (
                <MenuItem
                  key={pageSize.value}
                  value={pageSize.value}
                  selected={pageSize?.selected}
                >
                  {pageSize.label}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2" component="span">
              {getPaginationDetailText(courseListMeta, pageNo, page)}
            </Typography>{" "}
          </Box>
          <Pagination
            count={courseListMeta?.totalPages}
            variant="outlined"
            shape="rounded"
            page={pageNo}
            onChange={onPageChange}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: ArrowBackIcon,
                  next: ArrowForwardIcon,
                }}
                {...item}
              />
            )}
          />
        </Box>
      ) : (
        <NoRecords />
      )}
    </Card>
  );
};

export default StudentCourseTable;
