import { Box } from "@mui/system";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon, successfullIcon } from "assets/images";
import { AppRoutings } from "utils/enums/app-routings";
import { useHistory } from "react-router-dom";
import { WAIT_LIST_SUCCESS } from "utils/constants/Messages";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface IApprovedModalProps {
  isOpenApprovedModal: boolean;
  handleApprovedModalClose: any;
  studentName: string;
  isWaitListed: boolean;
  isRejectedCourses: boolean;
  studentId: number;
  student: {
    waitListedCourseList: string[];
    approvedCourseList: string[];
  };
}

const ApprovedModal: React.FC<IApprovedModalProps> = ({
  isOpenApprovedModal,
  handleApprovedModalClose,
  studentName,
  isWaitListed,
  studentId,
  student,
  isRejectedCourses,
}) => {
  const history = useHistory();

  const showViewDetailsButton =
    student?.approvedCourseList && student.approvedCourseList.length > 0;

  return (
    <Modal open={isOpenApprovedModal} onClose={handleApprovedModalClose}>
      <Box className="common-modal approved-modal">
        <Box className="modal-header">
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={CLOSE_TOOLTIP_TITLE}
            arrow
          >
            <IconButton onClick={handleApprovedModalClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="modal-body">
          <div className="icon">
            <img src={successfullIcon} alt="Checked" />
          </div>
          {isWaitListed && (
            <Typography variant="h4" className="approval">
              {WAIT_LIST_SUCCESS}
            </Typography>
          )}
          {student?.approvedCourseList?.length > 0 && (
            <Typography variant="h4" className="approval">
              Courses have been approved. Here is the list of approved courses:
            </Typography>
          )}
          <ul>
            {student?.approvedCourseList?.map((course) => (
              <li key={course}>&#x2022; {course}</li>
            ))}
          </ul>
          {student?.waitListedCourseList?.length > 0 && (
            <Typography variant="h4" className="approval" sx={{ marginTop: 2 }}>
              Unfortunately, your courses have been moved to the waitlist due to
              full seats.
            </Typography>
          )}
          <ul>
            {student?.waitListedCourseList?.map((course) => (
              <li key={course}>&#x2022; {course}</li>
            ))}
          </ul>
          {isRejectedCourses && (
            <Typography variant="h4" className="approval" sx={{ marginTop: 2 }}>
              Unfortunately, your courses have been rejected.
            </Typography>
          )}
          <Typography variant="h6" className="std-name" sx={{ marginTop: 2 }}>
            Student Name : <span>{studentName}</span>
          </Typography>
        </Box>
        <Box className="modal-footer">
          {showViewDetailsButton && (
            <Button
              variant="contained"
              className="btn-continue"
              onClick={() =>
                history.push({
                  pathname: AppRoutings.StudentsDetails,
                  state: {
                    studentId: Number(studentId),
                    studentName: { studentName },
                  },
                })
              }
            >
              View details
            </Button>
          )}
          <Button
            variant="outlined"
            className="btn-cancel"
            onClick={handleApprovedModalClose}
          >
            close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApprovedModal;
