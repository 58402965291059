export enum AppPages {
  Dashboard = "dashboard",
  ScheduledOfferings = "scheduled offerings",
  Courses = "courses",
  Locations = "locations",
  Terms = "terms",
  Educators = "educators",
  Roles = "roles",
  EducatorHome = "educator home",
  Student = "students",
  StudentEnrolment = "student enrolments",
  Users = "users",
  Documents = "documents",
  StudentDashboard = "dashboard",
  MyEnrolment = "my enrolments",
  HandBook = "handbook",
  Reports = "reports",
  EducatorDocuments = "educator documents",
  CertificateSettings = "certificate settings",
}
