import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon, deleteIcon, editIcon, plusLightIcon } from "assets/images";
import React, { useCallback, useEffect, useState } from "react";
import projectTheme from "app.theme";
import {
  IAddEditLocation,
  IEnhancedTableProps,
  ILocation,
  ILocationContainerDispatch,
  ILocationContainerState,
  ILocationListMeta,
  ILocationPagePayloadValues,
} from "utils/interfaces/location";
import {
  AddEditLocationSuccessPayload,
  DeleteLocationSuccessPayload,
  LocationListSuccessPayload,
} from "store/location/types";
import {
  allowSearch,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  CLEAR_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DELETE_TOOLTIP_TITLE,
  EDIT_TOOLTIP_TITLE,
  PAGE_SIZES,
} from "utils/constants/constant";
import { Order, OrderByOptions } from "utils/enums/sorting";
import { IRoleModulePermission } from "store/role/types";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import NoRecords from "components/NoRecords";
import {
  LOCATION_ADD_SUCCESS_MSG,
  LOCATION_DELETE_SUCCESS_MSG,
  LOCATION_EDIT_SUCCESS_MSG,
} from "utils/constants/Messages";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { AppPages } from "utils/enums/app-pages";
import AddEditLocation from "./AddEditLocation";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof ILocation) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type LocationProps = ILocationContainerState &
  ILocationContainerDispatch;

const Location: React.FC<LocationProps> = (props) => {
  const [locationList, setLocationList] = useState<ILocation[]>();
  const [locationListMeta, setLocationListMeta] = useState<ILocationListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof ILocation>("location");
  const [isOpenLocationModal, setIsOpenLocationModal] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [isEditLocation, setIsEditLocation] = useState<boolean>(false);
  const [editLocationData, setEditLocationData] = useState<ILocation>();
  const [isOpenLocationDeleteModal, setIsOpenLocationDeleteModal] =
    useState(false);
  const [deleteLocationId, setDeleteLocationId] = useState<number>();
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const user = useSelector((state: any) => state?.auth?.user);
  const history = useHistory();

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof ILocation) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const handleLocation = (isEdit: boolean, editData: ILocation | null) => {
    setIsEditLocation(isEdit);
    if (editData) {
      setEditLocationData(editData);
    }
    setIsOpenLocationModal(true);
  };
  const handleLocationClose = () => setIsOpenLocationModal(false);

  const onLocationListSuccess = (response: LocationListSuccessPayload) => {
    setLocationList(response.locationList);
    setLocationListMeta(response.locationListPagination);
  };

  const getLocationList = async (filtered?: boolean) => {
    const { locationListRequest } = props;

    if (locationListRequest) {
      showLoader();
      const values: ILocationPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.location = filtered ? "" : searchText.trim();

      const payload = {
        values,
        callback: onLocationListSuccess,
      };

      locationListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.Locations
    );
    setPagePermission(permission);
    if (permission?.view) {
      getLocationList();
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleLocationDeleteModal = (locationId: number) => {
    setDeleteLocationId(locationId);
    setIsOpenLocationDeleteModal(true);
  };
  const handleLocationDeleteCloseModal = () => {
    setDeleteLocationId(0);
    setIsOpenLocationDeleteModal(false);
  };

  const onDeleteLocationSuccess = (response: DeleteLocationSuccessPayload) => {
    if (response.success) {
      toast.success(LOCATION_DELETE_SUCCESS_MSG);
      handleLocationDeleteCloseModal();
      getLocationList();
    }
  };

  const handleDeleteLocation = () => {
    const { deleteLocationRequest } = props;

    if (deleteLocationRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(deleteLocationId),
        },
        callback: onDeleteLocationSuccess,
      };

      deleteLocationRequest(payload);
    }
  };

  const handleLocationSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getLocationList(true);
  };

  const handleLocationSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getLocationList();
    }
  };

  const onAddLocationSuccess = (response: AddEditLocationSuccessPayload) => {
    if (response.success) {
      toast.success(LOCATION_ADD_SUCCESS_MSG);
      handleLocationClose();
      getLocationList();
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleAddLocation = (formData: IAddEditLocation) => {
    const { addLocationRequest } = props;

    if (addLocationRequest) {
      showLoader();
      const payload = {
        values: {
          location: formData.location.trim(),
          isBlended: formData.isBlended,
        },
        callback: onAddLocationSuccess,
      };

      addLocationRequest(payload);
    }
  };

  const onEditLocationSuccess = (response: AddEditLocationSuccessPayload) => {
    if (response.success) {
      toast.success(LOCATION_EDIT_SUCCESS_MSG);
      handleLocationClose();
      getLocationList();
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleEditLocation = (formData: IAddEditLocation) => {
    const { editLocationRequest } = props;

    if (editLocationRequest) {
      showLoader();
      const payload = {
        values: {
          id: editLocationData?.id,
          location: formData.location.trim(),
          isBlended: formData.isBlended,
        },
        callback: onEditLocationSuccess,
      };

      editLocationRequest(payload);
    }
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  return (
    <>
      <div>
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Locations
          </Typography>
          <Button
            variant="contained"
            onClick={() => handleLocation(false, null)}
            className="btn-add"
            disabled={!pagePermission?.add}
          >
            <img src={plusLightIcon} alt="plus" />
            Add
          </Button>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Card>
              <Box className="table-card-header">
                <Box>
                  <TextField
                    id="search-box"
                    variant="outlined"
                    className="search-input"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleLocationSearch}
                    onKeyDown={handleLocationSearchKeyDown}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ position: "absolute", right: "0" }}
                          position="end"
                        >
                          {searchText && (
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={CLEAR_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                edge="end"
                                onClick={handleClearSearch}
                              >
                                <img src={closeIcon} alt="close" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, searchText?.length)
                    }
                  />
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        fieldName="Location"
                        keyName="location"
                        sxStyle={{
                          maxWidth: "616px",
                          width: "616px",
                          minWidth: "616px",
                        }}
                      />
                      <TableCell
                        align="center"
                        sx={{
                          width: "152px",
                          [projectTheme.breakpoints.down("sm")]: {
                            width: "112px",
                          },
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {locationList?.map((row) => (
                      <TableRow key={row?.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="ellipsis-text"
                        >
                          {row?.location}
                          {row.isBlended &&
                            " - Blended (Face To Face / Online)"}
                        </TableCell>
                        <TableCell align="center">
                          <div className="table-actions">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={EDIT_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                onClick={() => handleLocation(true, row)}
                                disabled={!pagePermission?.edit}
                                className={
                                  !pagePermission?.edit ? "disabled-icon" : ""
                                }
                              >
                                <img src={editIcon} alt="edit" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={DELETE_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  handleLocationDeleteModal(row?.id)
                                }
                                disabled={!pagePermission?.delete}
                                className={
                                  !pagePermission?.delete ? "disabled-icon" : ""
                                }
                              >
                                <img src={deleteIcon} alt="delete" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {locationList && locationList.length > 0 ? (
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      {PAGE_SIZES?.map((pageSize) => (
                        <MenuItem
                          key={pageSize.value}
                          value={pageSize.value}
                          selected={pageSize?.selected}
                        >
                          {pageSize.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" component="span">
                      {getPaginationDetailText(locationListMeta, pageNo, page)}
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={locationListMeta?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={pageNo}
                    onChange={onPageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              ) : (
                <NoRecords />
              )}
            </Card>
          </Grid>
        </Grid>
      </div>

      <AddEditLocation
        isEditLocation={isEditLocation}
        isOpenLocationModal={isOpenLocationModal}
        handleLocationClose={handleLocationClose}
        handleSaveClick={
          isEditLocation ? handleEditLocation : handleAddLocation
        }
        editLocationData={editLocationData}
      />

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenLocationDeleteModal}
        handleDeleteConfirmationModalClose={handleLocationDeleteCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete location?"
        handleYesClick={handleDeleteLocation}
      />
    </>
  );
};

export default Location;
