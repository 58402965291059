/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import {
  Modal,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  OutlinedInput,
  ListItemText,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  IAddEditScheduledOffering,
  ICourseOfferingDaysResponse,
  IRepeat,
} from "utils/interfaces/scheduled-offering";
import { useEffect, useState } from "react";
import {
  closeIcon,
  cloudLightIcon,
  cloudPrimaryIcon,
  settingIcon,
} from "assets/images";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import {
  DATE_FORMAT_WITH_TIME,
  DATE_PICKER_FORMAT,
  DEFAULT_PAGE_NO,
  MAX_INT_VALUE,
  SCO_CERTIFICATE,
  SCO_DOC_SUPPORTED_TYPES,
  TIME_FORMAT,
  TIME_VIEW_FORMAT,
} from "utils/constants/constant";
import {
  checkEndDateLessThanOrEqualToStart,
  checkEndDateLessThanStart,
  checkEndTimeLessThanStart,
  convertToDateTimeWithFormat,
  educatorRenderValue,
  getPagePayloadValues,
  getScoDocAcceptedTypes,
  handlePasteNumberField,
  hideLoaderForBatchApiCall,
  onNumberFieldKeyPress,
  showLoader,
  showLoaderForBatchApiCall,
} from "utils/helper";
import { toast } from "react-toastify";
import { CourseListSuccessPayload } from "store/course/types";
import { ICourse, ICoursePagePayloadValues } from "utils/interfaces/course";
import { useDispatch } from "react-redux";
import { courseListRequest } from "store/course/actions";
import { LocationListSuccessPayload } from "store/location/types";
import {
  ILocation,
  ILocationPagePayloadValues,
} from "utils/interfaces/location";
import { locationListRequest } from "store/location/actions";
import { TermListSuccessPayload } from "store/term/types";
import { termListRequest } from "store/term/actions";
import { ITerm, ITermPagePayloadValues } from "utils/interfaces/term";
import { EducatorListSuccessPayload } from "store/educator/types";
import { educatorListRequest } from "store/educator/actions";
import {
  IEducator,
  IEducatorPagePayloadValues,
} from "utils/interfaces/educator";
import { EducatorStatus } from "utils/enums/educator";
import { UPLOAD_PDF_OR_WORD_FILE } from "utils/constants/Messages";
import { OrderByOptions } from "utils/enums/sorting";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import CalendarIcon from "components/CalendarIcon";
import dayjs, { Dayjs } from "dayjs";
import { ScoDaysListSuccessPayload } from "store/scheduledOffering/types";
import { SCORepeatEnd } from "utils/enums/scheduled-offering";
import { scoDaysListRequest } from "store/scheduledOffering/actions";
import RepeatModal from "../RepeatModal";

interface IAddEditSCOProps {
  isOpenScheduledOfferingModal: boolean;
  handleScheduledOfferingClose: any;
  handleSaveClick: any;
  repeatCourseOffering?: IRepeat;
  setScoDaysList: React.Dispatch<
    React.SetStateAction<ICourseOfferingDaysResponse[] | undefined>
  >;
  setRepeatCourseOffering: any;
  setScoDetails: any;
  pickerMinDate: any;
  setPickerMinDate: any;
  pickerMaxDate: any;
  setPickerMaxDate: any;
}

const AddEditScheduledOffering: React.FC<IAddEditSCOProps> = ({
  isOpenScheduledOfferingModal,
  handleScheduledOfferingClose,
  handleSaveClick,
  repeatCourseOffering,
  setRepeatCourseOffering,
  setScoDetails,
  pickerMinDate,
  setPickerMinDate,
  pickerMaxDate,
  setScoDaysList,
  setPickerMaxDate,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
  } = useForm<IAddEditScheduledOffering>({
    defaultValues: {
      educatorIds: [],
      startDateOfFirstSession: null,
      endDateOfFirstSession: null,
      isAllDay: false,
      isRepeat: false,
    },
  });
  const dispatch = useDispatch();
  const [isOpenRepeatModal, setIsOpenRepeatModal] = useState<boolean>(false);
  const [courseList, setCourseList] = useState<ICourse[]>();
  const [locationList, setLocationList] = useState<ILocation[]>();
  const [termList, setTermList] = useState<ITerm[]>();
  const [educatorList, setEducatorList] = useState<IEducator[]>();
  const [repeatDataError, setRepeatDataError] = useState<string>("");
  const [isEndTimePatternError, setIsEndTimePatternError] =
    useState<boolean>(false);

  const handleRepeatModal = () => {
    if (watch("startDateOfFirstSession")) {
      setIsOpenRepeatModal(true);
    } else {
      trigger("startDateOfFirstSession");
    }
  };
  const handleRepeatModalClose = () => setIsOpenRepeatModal(false);

  const onCourseListSuccess = (response: CourseListSuccessPayload) => {
    setCourseList(response.courseList);
  };

  const getCourseList = () => {
    showLoaderForBatchApiCall();
    const values: ICoursePagePayloadValues = getPagePayloadValues(
      "Name",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.name = "";
    const payload = {
      values,
      callback: onCourseListSuccess,
    };

    dispatch(courseListRequest(payload));
  };

  const onLocationListSuccess = (response: LocationListSuccessPayload) => {
    setLocationList(response.locationList);
  };

  const getLocationList = async () => {
    showLoaderForBatchApiCall();
    const values: ILocationPagePayloadValues = getPagePayloadValues(
      "Location",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.location = "";

    const payload = {
      values,
      callback: onLocationListSuccess,
    };

    dispatch(locationListRequest(payload));
  };

  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
  };

  const getTermList = () => {
    showLoaderForBatchApiCall();
    const values: ITermPagePayloadValues = getPagePayloadValues(
      "TermName",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.termName = "";
    const payload = {
      values,
      callback: onTermListSuccess,
    };

    dispatch(termListRequest(payload));
  };

  const onEducatorListSuccess = (response: EducatorListSuccessPayload) => {
    setEducatorList(response.educatorList);
    hideLoaderForBatchApiCall();
  };

  const getEducatorList = () => {
    showLoaderForBatchApiCall();
    const values: IEducatorPagePayloadValues = getPagePayloadValues(
      "Name",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.name = "";
    const payload = {
      values,
      callback: onEducatorListSuccess,
    };

    dispatch(educatorListRequest(payload));
  };

  useEffect(() => {
    if (isOpenScheduledOfferingModal) {
      getCourseList();
      getLocationList();
      getTermList();
      getEducatorList();
      reset();
      setValue("certificatesIssued", 0);
      setValue("emrEntered", 0);
      setRepeatDataError("");
    }
  }, [isOpenScheduledOfferingModal]);

  const getCourseIdError = (): string => {
    if (errors.courseId) {
      if (errors.courseId.type === "required") {
        return "Course is required";
      }
    }

    return "";
  };

  const getLocationIdError = (): string => {
    if (errors.locationId) {
      if (errors.locationId.type === "required") {
        return "Location is required";
      }
    }

    return "";
  };

  const getTermIdError = (): string => {
    if (errors.termId) {
      if (errors.termId.type === "required") {
        return "Term is required";
      }
    }

    return "";
  };

  const getStartDateError = (): string => {
    if (errors.startDateOfFirstSession) {
      if (errors.startDateOfFirstSession.type === "required") {
        return "Start date is required";
      }
    }

    return "";
  };

  const getStartTimeError = (): string => {
    if (errors.startTimeOfFirstSession) {
      if (errors.startTimeOfFirstSession.type === "required") {
        return "Start time is required";
      }
      if (errors.startTimeOfFirstSession.type === "validate") {
        return "Start time should be in proper format (e.g., 11:11 AM/PM)";
      }
    }

    return "";
  };

  const getEndTimeError = (): string => {
    if (isEndTimePatternError) {
      return "End time should be in proper format (e.g., 11:11 AM/PM)";
    }
    if (errors.endTimeOfFirstSession) {
      if (errors.endTimeOfFirstSession.type === "required") {
        return "End time is required";
      }
      if (errors.endTimeOfFirstSession.type === "validate") {
        return "End time should be greater than Start time";
      }
    }

    return "";
  };

  const getEndDateError = (): string => {
    if (errors.endDateOfFirstSession) {
      if (errors.endDateOfFirstSession.type === "required") {
        return "End date is required";
      }
      if (errors.endDateOfFirstSession.type === "validate") {
        return "End Date should be greater than Start Date";
      }
    }

    return "";
  };

  const onModalClose = () => {
    reset();
    handleScheduledOfferingClose();
  };

  useEffect(() => {
    if (watch("courseId")) {
      trigger("courseId");
    }
    if (watch("locationId")) {
      trigger("locationId");
    }
    if (watch("termId")) {
      trigger("termId");
    }
    if (watch("startTimeOfFirstSession")) {
      trigger("startTimeOfFirstSession");
    }
    if (watch("endTimeOfFirstSession")) {
      trigger("endTimeOfFirstSession");
    }
    if (watch("startDateOfFirstSession")) {
      setValue("endDateOfFirstSession", watch("startDateOfFirstSession"));
      trigger("startDateOfFirstSession");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watch("courseId"),
    watch("locationId"),
    watch("termId"),
    watch("startTimeOfFirstSession"),
    watch("endTimeOfFirstSession"),
    watch("startDateOfFirstSession"),
  ]);

  const handleUploadForm = async (event: React.ChangeEvent<any>) => {
    const { files } = event.target;
    const file = files[0];

    if (!SCO_DOC_SUPPORTED_TYPES.includes(file?.type)) {
      toast.error(UPLOAD_PDF_OR_WORD_FILE);
    } else {
      setValue("blankEvaluationFormDoc", file.name);
      setValue("blankEvaluationForm", file);
    }
  };
  const onScoDaysListSuccess = (response: ScoDaysListSuccessPayload) => {
    setScoDaysList(response.scoDaysList);
    if (watch("startDateOfFirstSession") < watch("endDateOfFirstSession")) {
      setValue(
        "startDateOfFirstSession",
        dayjs(response.scoDaysList[0].sessionStartDateTime)
      );
      setValue(
        "endDateOfFirstSession",
        dayjs(response.scoDaysList[0].sessionStartDateTime).add(1, "day")
      );
    } else
      setValue(
        "startDateOfFirstSession",
        dayjs(response.scoDaysList[0].sessionStartDateTime)
      );
  };
  const handleSaveRepeatClick = (data: IRepeat) => {
    setRepeatCourseOffering(data);
    handleRepeatModalClose();
    setRepeatDataError("");
    if (watch("isRepeat")) {
      if (scoDaysListRequest && data) {
        showLoader();

        const payload = {
          values: {
            startDateOfFirstSession: convertToDateTimeWithFormat(
              watch("startDateOfFirstSession"),
              DATE_FORMAT_WITH_TIME
            ),
            repeatType: data?.repeatType,
            occurenceStart: Number(data?.occurenceStart),
            repeatDays: data?.repeatDays
              ? data?.repeatDays?.substring(0, data?.repeatDays?.length)
              : "",
            endType: data?.endType,
            occurenceEnd:
              data?.endType === SCORepeatEnd.After
                ? Number(data?.occurenceEnd)
                : null,
            endOn:
              data?.endType === SCORepeatEnd.On
                ? convertToDateTimeWithFormat(
                    data?.endOn,
                    DATE_FORMAT_WITH_TIME
                  )
                : null,
          },
          callback: onScoDaysListSuccess,
        };
        if (payload?.values?.repeatDays) {
          payload.values.repeatDays = payload?.values?.repeatDays.replace(
            /,+$/,
            ""
          );
        }
        dispatch(scoDaysListRequest(payload));
      }
    }
  };

  const handleContinueClick = (formData: IAddEditScheduledOffering) => {
    if (!isEndTimePatternError) {
      if (formData.isRepeat && !repeatCourseOffering) {
        setRepeatDataError("Please select required repeat data.");
      } else {
        const selectedCourse = courseList?.find(
          (course) => course.id === formData.courseId
        );
        const selectedLocation = locationList?.find(
          (location) => location.id === formData.locationId
        );
        const selectedTerm = termList?.find(
          (term) => term.id === formData.termId
        );

        const details = {
          courseName: selectedCourse?.name,
          location: selectedLocation?.location,
          termName: selectedTerm?.termName,
        };
        setScoDetails(details);
        setRepeatDataError("");
        handleSaveClick(formData);
      }
    }
  };

  const onChangeCheckBox = (e: any, fieldName: string) => {
    if (fieldName === "isAllDay") {
      setValue("isAllDay", e.target.checked);
      setValue("isRepeat", false);
    } else {
      setValue("isAllDay", false);
      setValue("isRepeat", e.target.checked);
    }
  };

  const handleEndTimeChange = (value: Dayjs | null) => {
    if (convertToDateTimeWithFormat(value, TIME_FORMAT) === "Invalid date")
      setIsEndTimePatternError(true);
    else setIsEndTimePatternError(false);
  };

  useEffect(() => {
    if (watch("termId")) {
      const selectedTerm = termList?.find(
        (term) => Number(term.id) === Number(watch("termId"))
      );

      const minDate = dayjs(selectedTerm?.startDate);
      const maxDate = dayjs(selectedTerm?.endDate);

      setValue("startDateOfFirstSession", minDate);
      setPickerMinDate(minDate);
      setPickerMaxDate(maxDate);
    }
  }, [watch("termId")]);

  return (
    <>
      <Modal open={isOpenScheduledOfferingModal} onClose={onModalClose}>
        <form onSubmit={handleSubmit(handleContinueClick)}>
          <Box className="common-modal addsco-modal">
            <Box className="modal-header">
              <Typography variant="h4">Scheduled Course Offerings</Typography>
              <IconButton onClick={onModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Box>
            <Box className="modal-body">
              <Grid
                container
                columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
              >
                <Grid item xs={12}>
                  <FormControl fullWidth className="select">
                    <Controller
                      name="courseId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          id="course-select"
                          options={courseList || []}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, course) =>
                            option.id === course.id
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <>
                                  Course <span className="color-red">*</span>
                                </>
                              }
                              error={!!errors.courseId}
                              InputLabelProps={{
                                shrink: !!watch("courseId"),
                              }}
                              helperText={
                                !!errors.courseId && getCourseIdError()
                              }
                            />
                          )}
                          value={courseList?.find(
                            (course) => course.id === value
                          )}
                          onChange={(_, newValue) => {
                            onChange(newValue ? newValue.id : null);
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <FormControl fullWidth className="select">
                    <InputLabel
                      id="location-select-label"
                      shrink={!!watch("locationId")}
                    >
                      Location <span className="color-red">*</span>
                    </InputLabel>
                    <Select
                      labelId="location-select-label"
                      id="location-select"
                      label="Location"
                      MenuProps={{
                        className: "filter-select-menu sco-location-menu",
                      }}
                      value={watch("locationId")}
                      error={!!errors.locationId}
                      {...register("locationId", {
                        required: true,
                      })}
                    >
                      {locationList?.map((loc) => (
                        <MenuItem key={loc.id} value={loc.id}>
                          {loc.location}
                          {loc.isBlended &&
                            " - Blended (Face To Face / Online)"}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.locationId && (
                      <FormHelperText error>
                        {getLocationIdError()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth className="select">
                    <InputLabel
                      id="term-select-1-label"
                      shrink={!!watch("termId")}
                    >
                      Term <span className="color-red">*</span>
                    </InputLabel>
                    <Select
                      labelId="term-select-1-label"
                      id="term-select-1"
                      label="Term"
                      value={watch("termId")}
                      error={!!errors.termId}
                      {...register("termId", {
                        required: true,
                      })}
                    >
                      {termList?.map((term) => (
                        <MenuItem key={term.id} value={term.id}>
                          {term.termName}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!errors.termId && (
                      <FormHelperText error>{getTermIdError()}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="educator-type-label">Educator</InputLabel>
                    <Select
                      labelId="educator-type-label"
                      id="educator-type"
                      multiple
                      label="Educator"
                      input={<OutlinedInput label="Educator" />}
                      renderValue={(selected) =>
                        educatorRenderValue(selected, educatorList)
                      }
                      value={watch("educatorIds")}
                      {...register("educatorIds")}
                      MenuProps={{
                        className: "educator-type-select",
                      }}
                    >
                      {educatorList?.map((educator) => (
                        <MenuItem
                          key={educator.id}
                          value={educator.id}
                          disabled={educator.status === EducatorStatus.Inactive}
                        >
                          <Checkbox
                            checked={
                              watch("educatorIds")?.indexOf(educator.id) > -1
                            }
                            icon={<CheckboxIcon />}
                            checkedIcon={<CheckedBoxIcon />}
                          />
                          <ListItemText primary={educator.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    type="number"
                    id="max-enroll"
                    label="Max. Enrolments"
                    fullWidth
                    variant="outlined"
                    onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                    onPaste={handlePasteNumberField}
                    {...register("maxEnrollments")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="certi-issued-label">
                      Certificates Issued
                    </InputLabel>
                    <Select
                      labelId="certi-issued-label"
                      id="certi-issued"
                      label="Certificates Issued "
                      value={watch("certificatesIssued")}
                      {...register("certificatesIssued")}
                    >
                      {SCO_CERTIFICATE?.map((sco) => (
                        <MenuItem key={sco.value} value={sco.value}>
                          {sco.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <FormControl fullWidth className="select">
                    <InputLabel id="emr-entered-label">EMR Entered</InputLabel>
                    <Select
                      labelId="emr-entered-label"
                      id="emr-entered"
                      label="EMR Entered "
                      value={watch("emrEntered")}
                      {...register("emrEntered")}
                    >
                      {SCO_CERTIFICATE?.map((sco) => (
                        <MenuItem key={sco.value} value={sco.value}>
                          {sco.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg>
                  <Box className="upload-group">
                    <Typography variant="body1" className="upload-label">
                      Blank Evaluation Form
                    </Typography>
                    <Button
                      variant="outlined"
                      component="label"
                      className="btn-upload"
                    >
                      <img
                        src={cloudPrimaryIcon}
                        alt="cloud"
                        className="default"
                      />
                      <img
                        src={cloudLightIcon}
                        alt="cloud"
                        className="active"
                      />
                      Upload
                      <input
                        hidden
                        accept={getScoDocAcceptedTypes()}
                        type="file"
                        onChange={handleUploadForm}
                      />
                    </Button>
                  </Box>
                  {watch("blankEvaluationFormDoc") && (
                    <Typography variant="body1" className="upload-label">
                      Chosen file: {watch("blankEvaluationFormDoc")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Typography variant="body1" className="date-label">
                Date Time of First Session
              </Typography>
              <Box className="datetime-container">
                <Box className="datetime-input">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DesktopDatePicker"]}>
                      <Controller
                        control={control}
                        {...register("startDateOfFirstSession", {
                          required: true,
                        })}
                        render={({ field }) => (
                          <DesktopDatePicker
                            {...field}
                            className="date-picker disabled-picker-text-field"
                            format={DATE_PICKER_FORMAT}
                            label={
                              <>
                                Start Date <span className="color-red">*</span>
                              </>
                            }
                            minDate={pickerMinDate}
                            maxDate={pickerMaxDate}
                            slots={{
                              openPickerIcon: CalendarIcon,
                            }}
                            slotProps={{
                              textField: {
                                disabled: true,
                                error: !!errors.startDateOfFirstSession,
                                helperText: getStartDateError(),
                              },
                            }}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DesktopTimePicker"]}>
                      <Controller
                        control={control}
                        {...register("startTimeOfFirstSession", {
                          required: true,
                          validate: (value) =>
                            convertToDateTimeWithFormat(value, TIME_FORMAT) !==
                            "Invalid date",
                        })}
                        render={({ field }) => (
                          <DesktopTimePicker
                            {...field}
                            className="time-picker disabled-time-picker"
                            format={TIME_VIEW_FORMAT}
                            ampm
                            label={
                              <>
                                Start Time <span className="color-red">*</span>
                              </>
                            }
                            slotProps={{
                              textField: {
                                error: !!errors.startTimeOfFirstSession,
                                helperText: getStartTimeError(),
                              },
                            }}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Typography variant="body2" className="datetime-divider">
                  to
                </Typography>
                <Box className="datetime-input">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DesktopTimePicker"]}>
                      <Controller
                        control={control}
                        {...register("endTimeOfFirstSession", {
                          required: true,
                          validate: (value) =>
                            !checkEndTimeLessThanStart(
                              watch("startTimeOfFirstSession"),
                              value
                            ) ||
                            !checkEndDateLessThanOrEqualToStart(
                              watch("startDateOfFirstSession"),
                              watch("endDateOfFirstSession")
                            ),
                        })}
                        render={({ field }) => (
                          <DesktopTimePicker
                            {...field}
                            onChange={(value) => {
                              handleEndTimeChange(value);
                              field.onChange(value);
                            }}
                            format={TIME_VIEW_FORMAT}
                            ampm
                            className="time-picker disabled-time-picker"
                            label={
                              <>
                                End Time <span className="color-red">*</span>
                              </>
                            }
                            slotProps={{
                              textField: {
                                error:
                                  !!errors.endTimeOfFirstSession ||
                                  isEndTimePatternError,
                                helperText: getEndTimeError(),
                              },
                            }}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DesktopDatePicker"]}>
                      <Controller
                        control={control}
                        {...register("endDateOfFirstSession", {
                          required: true,
                          validate: (value) =>
                            !checkEndDateLessThanStart(
                              watch("startDateOfFirstSession"),
                              value
                            ),
                        })}
                        render={({ field }) => (
                          <DesktopDatePicker
                            {...field}
                            minDate={pickerMinDate}
                            maxDate={pickerMaxDate}
                            className="date-picker sco-date-picker disabled-picker-text-field"
                            format={DATE_PICKER_FORMAT}
                            label={
                              <>
                                End Date <span className="color-red">*</span>
                              </>
                            }
                            slots={{
                              openPickerIcon: CalendarIcon,
                            }}
                            slotProps={{
                              textField: {
                                disabled: true,
                                error: !!errors.startDateOfFirstSession,
                                helperText: getEndDateError(),
                              },
                            }}
                          />
                        )}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box className="checkbox-group">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                      checked={watch("isAllDay")}
                      onChange={(e) => onChangeCheckBox(e, "isAllDay")}
                    />
                  }
                  label="Single"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                      checked={watch("isRepeat")}
                      onChange={(e) => onChangeCheckBox(e, "isRepeat")}
                    />
                  }
                  label="Repeat"
                  labelPlacement="end"
                />
                <IconButton
                  disabled={!watch("isRepeat")}
                  className={!watch("isRepeat") ? "disabled-icon" : ""}
                  onClick={handleRepeatModal}
                >
                  <img src={settingIcon} alt="setting" />
                </IconButton>
              </Box>
              {repeatDataError && (
                <FormHelperText error className="repeat-on-error-text">
                  {repeatDataError}
                </FormHelperText>
              )}
            </Box>
            <Box className="modal-footer">
              <Button
                variant="contained"
                className="btn-continue"
                type="submit"
              >
                Continue
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={onModalClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
      <RepeatModal
        isOpenRepeatModal={isOpenRepeatModal}
        handleRepeatModalClose={handleRepeatModalClose}
        handleSaveRepeatClick={handleSaveRepeatClick}
        startDate={watch("startDateOfFirstSession")}
        repeatCourseOffering={repeatCourseOffering}
        pickerMinDate={pickerMinDate}
        pickerMaxDate={pickerMaxDate}
      />
    </>
  );
};

export default AddEditScheduledOffering;
