import baseService from "services/base-service";

const studentDashboardBaseUrl = `/api/StudentDashboard`;

const getUpcomingCourseList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  name?: string;
  term?: number;
  locationName?: string;
  isBlendedCourse?: boolean;
}) =>
  baseService.post(
    `${studentDashboardBaseUrl}/GetUpcomingCourse?id=${payload.id}`,
    payload
  );

const getPastCourseList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  course?: string;
  term?: number;
  isBlendedCourse?: boolean;
}) =>
  baseService.post(
    `${studentDashboardBaseUrl}/GetPastCourse?id=${payload.id}`,
    payload
  );

const getCourseOfferingList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  course?: string;
  term?: number;
  isBlendedCourse?: boolean;
}) =>
  baseService.post(
    `${studentDashboardBaseUrl}/GetCourseOffering?id=${payload.id}`,
    payload
  );

const getStudentDashboardCourseDetail = async (payload: {
  courseOfferingId: number;
  studentId: number;
}) =>
  baseService.get(
    `${studentDashboardBaseUrl}/GetStudentDashboardCourseDetails?courseOfferingId=${payload.courseOfferingId}&studentId=${payload.studentId}`
  );

const applyCourse = async (payload: {
  courseOfferingId: number;
  studentId: number;
  preferredCourseDelivery: string;
  isWithdraw: boolean;
  studentCourseEnrolmentId?: number;
}) =>
  baseService.post(
    `${studentDashboardBaseUrl}/ApplyOrWithdrawCourseFromStudentDashboard`,
    payload
  );

const ChangeCoursePreference = async (payload: {
  courseOfferingId: number;
  studentId: number;
  preferredCourseDelivery: string;
}) =>
  baseService.put(
    `${studentDashboardBaseUrl}/ChangeCourseDeliveryPreference`,
    payload
  );

const getMyEnrolmentList = async (payload: {
  id: number;
  sortColumn: string;
  sortBy: number;
  pageNo: number;
  pageSize: number;
  course?: string;
  term?: number;
  locationName?: number;
  status?: number;
}) =>
  baseService.post(
    `${studentDashboardBaseUrl}/GetMyEnrolments?id=${payload.id}`,
    payload
  );

const downloadCertificate = async (payload: { id: number }) =>
  baseService.post(
    `${studentDashboardBaseUrl}/DownloadCertificate?id=${payload.id}`
  );

const getStudentDocuments = async (payload: { type: string }) =>
  baseService.get(
    `${studentDashboardBaseUrl}/GetStudentHandBook?type=${payload.type}`
  );

export default {
  getUpcomingCourseList,
  getPastCourseList,
  getCourseOfferingList,
  getStudentDashboardCourseDetail,
  applyCourse,
  getMyEnrolmentList,
  downloadCertificate,
  ChangeCoursePreference,
  getStudentDocuments,
};
