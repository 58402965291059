import { Box } from "@mui/system";
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  BLENDED_FACE_TO_FACE_ONLINE,
  CLOSE_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  MAX_INT_VALUE,
} from "utils/constants/constant";
import {
  IEnrolmentOptionItem,
  IEnrolmentOptions,
} from "utils/interfaces/student-enrolment";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { OrderByOptions } from "utils/enums/sorting";
import {
  showLoaderForBatchApiCall,
  getPagePayloadValues,
  hideLoaderForBatchApiCall,
  onNumberFieldKeyPress,
  showLoader,
} from "utils/helper";
import { ITerm, ITermPagePayloadValues } from "utils/interfaces/term";
import { useDispatch } from "react-redux";
import { termListRequest } from "store/term/actions";
import { TermListSuccessPayload } from "store/term/types";
import { ICourseReportFilter } from "utils/interfaces/course-report";
import {
  ILocation,
  ILocationPagePayloadValues,
} from "utils/interfaces/location";
import { locationListRequest } from "store/location/actions";
import { LocationListSuccessPayload } from "store/location/types";

interface ICourseReportFilterProps {
  filterBox: boolean;
  setFilterBox: React.Dispatch<React.SetStateAction<boolean>>;
  handleApplyClick: (formData: ICourseReportFilter) => void;
  filteredData?: ICourseReportFilter;
  pageNo: number;
  setPageNo: React.Dispatch<React.SetStateAction<number>>;
  setFilteredData: React.Dispatch<
    React.SetStateAction<ICourseReportFilter | undefined>
  >;
  getReportList: (
    filterData?: ICourseReportFilter,
    pageNum?: number
  ) => Promise<void>;
  setAppliedFilter: React.Dispatch<React.SetStateAction<boolean>>;
  enrolmentOptionList?: IEnrolmentOptions;
}

interface IReportFilter {
  terms?: string[];
  locations?: string[];
  year: number;
}

const defaultValues: IReportFilter = {
  terms: [],
  locations: [],
  year: 0,
};

const CourseReportFilter: React.FC<ICourseReportFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getReportList,
  setAppliedFilter,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ICourseReportFilter>({
    defaultValues,
  });
  const dispatch = useDispatch();
  const [termList, setTermList] = useState<ITerm[]>();
  const [locationList, setLocationList] = useState<ILocation[]>();

  const getYearError = (): string => {
    if (errors.year) {
      if (errors.year.type === "minLength") {
        return "Enter a valid 4-digit year";
      }
      if (errors.year.type === "maxLength") {
        return "Enter a valid 4-digit year";
      }
    }

    return "";
  };

  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
    hideLoaderForBatchApiCall();
  };

  const getTermList = () => {
    showLoaderForBatchApiCall();

    const values: ITermPagePayloadValues = getPagePayloadValues(
      "TermName",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.termName = "";

    const payload = {
      values,
      callback: onTermListSuccess,
    };

    dispatch(termListRequest(payload));
  };
  const onLocationListSuccess = (response: LocationListSuccessPayload) => {
    setLocationList(response.locationList);
  };

  const getLocationList = async () => {
    showLoader();
    const values: ILocationPagePayloadValues = getPagePayloadValues(
      "Location",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.location = "";

    const payload = {
      values,
      callback: onLocationListSuccess,
    };

    dispatch(locationListRequest(payload));
  };

  useEffect(() => {
    if (filteredData) {
      setValue("year", filteredData?.year);
      setValue("locations", filteredData?.locations);
      setValue("terms", filteredData?.terms);
    } else {
      reset();
    }
    getTermList();
    getLocationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, setValue, filterBox, reset]);

  const onClearClick = () => {
    if (
      !(
        !filteredData?.year &&
        !filteredData?.locations?.length &&
        !filteredData?.terms?.length
      )
    ) {
      reset();
      setFilterBox(false);
      setAppliedFilter(false);
      document.body.classList.toggle("filter-open");
      if (pageNo === DEFAULT_PAGE_NO) {
        getReportList();
      } else {
        setPageNo(1);
      }
      setFilteredData(undefined);
    }
  };

  const renderSelectedValues = (
    selected: string[],
    optionList: Array<IEnrolmentOptionItem> | undefined,
    isValueField?: boolean
  ) => {
    const selectedString: string[] = [];
    if (isValueField) {
      selected.forEach((item: string) => {
        const selectedItem = optionList?.find(
          (option) => option.value === item
        );
        if (selectedItem) {
          selectedString.push(selectedItem.label);
        }
      });
    } else {
      selected.forEach((item: string) => {
        const selectedItem = optionList?.find(
          (option) => option.label === item
        );
        if (selectedItem) {
          selectedString.push(selectedItem.label);
        }
      });
    }

    return selectedString.join(", ");
  };

  const handleFieldChange = (
    event: SelectChangeEvent<string[]>,
    field: keyof ICourseReportFilter
  ) => {
    const { value } = event.target;
    setValue(field, value as string[]);
  };
  const termOptions: Array<IEnrolmentOptionItem> | undefined = termList?.map(
    (term) => ({
      value: term.id.toString(),
      label: term.termName,
    })
  );

  const locationOptions = locationList?.map((location) => ({
    value: location.id.toString(),
    label: location.isBlended
      ? `${location.location}${BLENDED_FACE_TO_FACE_ONLINE} `
      : location.location,
  }));

  return (
    <>
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setFilterBox(false);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            <TextField
              id="year-select"
              label={<>Year</>}
              fullWidth
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: !!watch("year"),
              }}
              error={!!errors.year}
              helperText={getYearError()}
              value={watch("year") === 0 ? "" : watch("year")}
              {...register("year", {
                maxLength: 4,
                minLength: 4,
              })}
              onKeyPress={onNumberFieldKeyPress}
            />
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Term</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Term"
                {...register("terms")}
                value={watch("terms") || []}
                onChange={(e) => handleFieldChange(e, "terms")}
                input={<OutlinedInput label="Term" />}
                renderValue={(selected) =>
                  renderSelectedValues(selected, termOptions, true)
                }
              >
                {termOptions?.map((term) => (
                  <MenuItem key={term.value} value={term.value}>
                    <Checkbox
                      checked={
                        (watch("terms") as unknown as string[]).indexOf(
                          term.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={term.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth className="table-select select">
              <InputLabel id="term-select-label">Location</InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                multiple
                label="Location"
                {...register("locations")}
                value={watch("locations") || []}
                onChange={(e) => handleFieldChange(e, "locations")}
                input={<OutlinedInput label="Location" />}
                renderValue={(selected) =>
                  renderSelectedValues(selected, locationOptions, true)
                }
              >
                {locationOptions?.map((location) => (
                  <MenuItem key={location.value} value={location.value}>
                    <Checkbox
                      checked={
                        (watch("locations") as unknown as string[]).indexOf(
                          location.value
                        ) > -1
                      }
                      icon={<CheckboxIcon />}
                      checkedIcon={<CheckedBoxIcon />}
                    />
                    <ListItemText primary={location.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-clear"
              onClick={onClearClick}
              disabled={
                !filteredData?.locations?.length &&
                !filteredData?.year &&
                !filteredData?.terms?.length
              }
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CourseReportFilter;
