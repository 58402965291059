import {
  ILogin,
  ILoginContainerDispatch,
  ILoginContainerState,
} from "utils/interfaces/login";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  EmailPattern,
  PasswordPattern,
  getPagePermissions,
  setLoginDetails,
  showLoader,
} from "utils/helper";
import {
  callIcon,
  emailIcon,
  eyeClose,
  eyeOpen,
  loginLogo,
} from "assets/images";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { LoginSuccessPayload } from "store/auth/types";
import { AppRoutings } from "utils/enums/app-routings";
import { LOGGED_IN_SUCCESS_MSG } from "utils/constants/Messages";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import { AppPages } from "utils/enums/app-pages";
import ArrowBackWardButtonIcon from "components/ArrowBackButtonIcon";
import ReCAPTCHA from "react-google-recaptcha";
import Config, { NODE_ENV_TYPES } from "config/index";
import { CheckValidReCaptchaSuccessPayload } from "store/studentEnrolment/types";

export type LoginProps = ILoginContainerState & ILoginContainerDispatch;

const LoginForm: React.FC<LoginProps> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isDisableLogin, setIsDisableLogin] = useState<boolean>(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };
  const history = useHistory();
  const location: any = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>();

  const getUserNameError = (): string => {
    if (errors.username) {
      if (errors.username.type === "required") {
        return "Email is required";
      }
      if (errors.username.type === "pattern") {
        return "Enter valid email";
      }
    }

    return "";
  };

  const getPasswordError = (): string => {
    if (errors.password) {
      if (errors.password.type === "required") {
        return "Password is required";
      }
      if (errors.password.type === "pattern") {
        return "Password must have at least 8 characters that include at least one uppercase character, one number, and  one special character.";
      }
    }

    return "";
  };

  const onLoginSuccess = async (response: LoginSuccessPayload) => {
    await setLoginDetails(response);
    const roles = response?.user?.roleName;
    let isStudent = false;
    roles.forEach((role: any) => {
      if (role.toLowerCase().includes("student")) {
        isStudent = true;
      }
    });

    if (response?.user?.educatorId) {
      const eduHomePermission = getPagePermissions(
        response?.user?.permissions,
        AppPages.EducatorHome,
        "educator"
      );
      if (eduHomePermission?.view) {
        history.push(AppRoutings.EducatorsHome);
      } else {
        history.push(AppRoutings.Profile);
      }
    } else if (isStudent) {
      const studentPermission = getPagePermissions(
        response?.user?.permissions,
        AppPages.StudentDashboard,
        "student"
      );
      if (studentPermission?.view) {
        history.push(AppRoutings.StudentDashboard);
      } else {
        history.push(AppRoutings.Profile);
      }
    } else {
      const dashboardPermission = getPagePermissions(
        response?.user?.permissions,
        AppPages.Dashboard,
        "administrator"
      );
      if (dashboardPermission?.view) {
        history.push(AppRoutings.Dashboard);
      } else {
        history.push(AppRoutings.Profile);
      }
    }

    toast.success(LOGGED_IN_SUCCESS_MSG);
  };

  const onSubmit = async (data: ILogin) => {
    const { loginRequest } = props;

    if (loginRequest) {
      showLoader();
      const payload = {
        values: {
          email: data.username,
          password: data.password,
          rememberMe: data.rememberMe,
        },
        callback: onLoginSuccess,
      };

      loginRequest(payload);
    }
  };

  const enrolmentFormHandler = () => {
    history.push(AppRoutings.StudentEnrolmentForm);
  };

  const onCheckValidReCaptchaSuccess = (
    response: CheckValidReCaptchaSuccessPayload
  ) => {
    if (response.success) {
      setIsDisableLogin(false);
    }
  };

  const handleReCaptchaChange = (value: string | null) => {
    if (Config.env.NodeEnv === NODE_ENV_TYPES.Development) {
      setIsDisableLogin(false);
    } else {
      const { checkValidReCaptchaRequest } = props;
      if (checkValidReCaptchaRequest) {
        showLoader();
        const payload = {
          values: {
            reCaptchaToken: value,
          },
          callback: onCheckValidReCaptchaSuccess,
        };
        checkValidReCaptchaRequest(payload);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="login-wrapper">
        <Grid container className="login-container">
          <Grid item xs={12} sm="auto">
            <a
              href="/"
              title="South Eastern Sydney Recovery and Wellbeing College"
              className="login-logo"
            >
              <img
                src={loginLogo}
                alt="South Eastern Sydney Recovery and Wellbeing College"
              />
            </a>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Card className="login-card student-login-card">
              <CardContent>
                <Typography variant="h2" className="card-heading">
                  Login
                </Typography>
                <div className="login-content-form">
                  <TextField
                    id="email"
                    label={
                      <>
                        Email <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    variant="outlined"
                    error={!!errors.username}
                    helperText={getUserNameError()}
                    {...register("username", {
                      required: true,
                      pattern: EmailPattern,
                    })}
                  />

                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="password" error={!!errors.password}>
                      Password <span className="color-red">*</span>
                    </InputLabel>
                    <OutlinedInput
                      id="password"
                      className="with-icon"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            disableFocusRipple
                            disableRipple
                          >
                            {showPassword ? (
                              <img src={eyeOpen} alt="show" />
                            ) : (
                              <img src={eyeClose} alt="hide" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      error={!!errors.password}
                      {...register("password", {
                        required: true,
                        pattern: PasswordPattern,
                      })}
                    />
                    {!!errors.password && (
                      <FormHelperText error>
                        {getPasswordError()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </CardContent>
              <CardActions>
                <Box className="login-links">
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableFocusRipple
                        disableRipple
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckedBoxIcon />}
                        id="rememberMe"
                        {...register("rememberMe")}
                      />
                    }
                    label="Remember me"
                    labelPlacement="end"
                  />
                  <a
                    href={AppRoutings.ForgotPassword}
                    title="Forgot Password"
                    className="forgot-pwd"
                  >
                    Forgot Password?
                  </a>
                </Box>
                <Box className="modal-body">
                  <Box className="confirm-modal-body">
                    <ReCAPTCHA
                      onChange={handleReCaptchaChange}
                      sitekey={Config.env.SiteKey}
                    />
                  </Box>
                </Box>
                <Button
                  variant="contained"
                  title="Login"
                  className="login-btn"
                  disableElevation
                  type="submit"
                  disabled={isDisableLogin}
                >
                  Login
                </Button>
              </CardActions>
              {location?.state?.isEnrolmentLoaded && (
                <>
                  <Button
                    variant="outlined"
                    className="backToEnrolment"
                    type="button"
                    fullWidth
                    startIcon={<ArrowBackWardButtonIcon />}
                    onClick={enrolmentFormHandler}
                  >
                    BACK TO ENROLMENT
                  </Button>
                  <CardActions className="contact-info">
                    <Typography
                      variant="body2"
                      className="label"
                      component="label"
                    >
                      If you are unable to login, please contact the college on
                    </Typography>
                    <div className="link-list">
                      <Link href="tel:9113 2981">
                        <img src={callIcon} alt="Phone Call" />
                        9113 2981
                      </Link>
                      <Link href="mailto:SESLHD-RecoveryCollege@health.nsw.gov.au">
                        <img src={emailIcon} alt="Email" />
                        SESLHD-RecoveryCollege@health.nsw.gov.au
                      </Link>
                    </div>
                  </CardActions>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default LoginForm;
