/* eslint-disable prettier/prettier */
import {
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  VALIDATE_RESET_PASSWORD_URL_REQUEST,
  VALIDATE_RESET_PASSWORD_URL_SUCCESS,
  VALIDATE_RESET_PASSWORD_URL_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  UPDATE_USER,
} from "./actionTypes";

import {
  LoginPayload,
  LoginRequest,
  LoginSuccess,
  LoginSuccessPayload,
  LoginFailure,
  LoginFailurePayload,
  ForgotPasswordPayload,
  ForgotPasswordRequest,
  ForgotPasswordSuccessPayload,
  ForgotPasswordSuccess,
  ForgotPasswordFailurePayload,
  ForgotPasswordFailure,
  RefreshTokenPayload,
  RefreshTokenRequest,
  RefreshTokenSuccessPayload,
  RefreshTokenSuccess,
  RefreshTokenFailurePayload,
  RefreshTokenFailure,
  ValidateResetPasswordUrlPayload,
  ValidateResetPasswordUrlRequest,
  ValidateResetPasswordUrlSuccessPayload,
  ValidateResetPasswordUrlSuccess,
  ValidateResetPasswordUrlFailurePayload,
  ValidateResetPasswordUrlFailure,
  ResetPasswordPayload,
  ResetPasswordRequest,
  ResetPasswordSuccessPayload,
  ResetPasswordSuccess,
  ResetPasswordFailurePayload,
  ResetPasswordFailure,
  UpdateUserPayload,
  UpdateUserRequest,
} from "./types";

export const loginRequest = (payload: LoginPayload): LoginRequest => ({
  type: LOGIN_REQUEST,
  payload,
});

export const loginSuccess = (payload: LoginSuccessPayload): LoginSuccess => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (payload: LoginFailurePayload): LoginFailure => ({
  type: LOGIN_FAILURE,
  payload,
});

export const forgotPasswordRequest = (
  payload: ForgotPasswordPayload
): ForgotPasswordRequest => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload,
});

export const forgotPasswordSuccess = (
  payload: ForgotPasswordSuccessPayload
): ForgotPasswordSuccess => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFailure = (
  payload: ForgotPasswordFailurePayload
): ForgotPasswordFailure => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload,
});

export const refreshTokenRequest = (
  payload: RefreshTokenPayload
): RefreshTokenRequest => ({
  type: REFRESH_TOKEN_REQUEST,
  payload,
});

export const refreshTokenSuccess = (
  payload: RefreshTokenSuccessPayload
): RefreshTokenSuccess => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload,
});

export const refreshTokenFailure = (
  payload: RefreshTokenFailurePayload
): RefreshTokenFailure => ({
  type: REFRESH_TOKEN_FAILURE,
  payload,
});

export const validateResetPasswordUrlRequest = (
  payload: ValidateResetPasswordUrlPayload
): ValidateResetPasswordUrlRequest => ({
  type: VALIDATE_RESET_PASSWORD_URL_REQUEST,
  payload,
});

export const validateResetPasswordUrlSuccess = (
  payload: ValidateResetPasswordUrlSuccessPayload
): ValidateResetPasswordUrlSuccess => ({
  type: VALIDATE_RESET_PASSWORD_URL_SUCCESS,
  payload,
});

export const validateResetPasswordUrlFailure = (
  payload: ValidateResetPasswordUrlFailurePayload
): ValidateResetPasswordUrlFailure => ({
  type: VALIDATE_RESET_PASSWORD_URL_FAILURE,
  payload,
});

export const resetPasswordRequest = (
  payload: ResetPasswordPayload
): ResetPasswordRequest => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccess = (
  payload: ResetPasswordSuccessPayload
): ResetPasswordSuccess => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailure = (
  payload: ResetPasswordFailurePayload
): ResetPasswordFailure => ({
  type: RESET_PASSWORD_FAILURE,
  payload,
});

export const updateUser = (payload: UpdateUserPayload): UpdateUserRequest => ({
  type: UPDATE_USER,
  payload,
});
