import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { cancelIcon, courseListIcon, userIcon } from "assets/images";
import { IScoEducator } from "utils/interfaces/scheduled-offering";
import { EDUCATOR_TYPES } from "utils/constants/educator";
import { useHistory } from "react-router-dom";
import { IRoleModulePermission } from "store/role/types";
import { AppRoutings } from "utils/enums/app-routings";

interface IEducatorsListProps {
  educatorsList: Array<IScoEducator>;
  educatorsPagePermission?: IRoleModulePermission;
  handleAddEducatorsModal: () => void;
  handleDeleteEducatorModal: (deleteId: number) => void;
  handleGetEducatorReflectionForm: (educatorId: number) => void;
}

const EducatorsList: React.FC<IEducatorsListProps> = ({
  educatorsList,
  educatorsPagePermission,
  handleAddEducatorsModal,
  handleDeleteEducatorModal,
  handleGetEducatorReflectionForm,
}) => {
  const history = useHistory();
  const getEducatorType = (typeId: number) => {
    const typeItem = EDUCATOR_TYPES.find((type) => type.value === typeId);

    return typeItem?.label;
  };

  const onEducatorDeleteClick = (e: any, educatorId: number) => {
    e.stopPropagation();
    handleDeleteEducatorModal(educatorId);
  };

  return (
    <Grid item xs={12}>
      <Card>
        <Box className="table-card-header">
          <Box
            sx={{
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
              rowGap: "16px",
            }}
          >
            <Typography
              variant="h3"
              className="attnd-heading educators-heading"
            >
              Educators
            </Typography>

            <Button
              variant="outlined"
              className="btn-ascod"
              sx={{ paddingLeft: "15px", paddingRight: "15px" }}
              onClick={handleAddEducatorsModal}
            >
              Add
            </Button>
          </Box>
        </Box>
        <Grid container spacing={{ xs: "16px", md: "20px", lg: "24px" }}>
          {educatorsList?.map((educator) => (
            <Grid item xs={12} md={6} xl={3} key={educator.id}>
              <Box className="educators-card position-relative">
                <img src={userIcon} alt="user" />
                <Box
                  className="educators-card-content"
                  onClick={() => {
                    if (educatorsPagePermission?.edit) {
                      history.push({
                        pathname: AppRoutings.EditEducator,
                        state: { educatorId: Number(educator.educatorID) },
                      });
                    }
                  }}
                >
                  <Typography component="span" variant="body2" className="name">
                    {educator.educatorName}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    className="designation"
                  >
                    {getEducatorType(educator.educatorType)}
                  </Typography>
                </Box>
                <div className="btn-group">
                  {educator.isFormFilled && (
                    <Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      title="View Educator Reflection Form"
                      arrow
                    >
                      <IconButton
                        className="btn-educator"
                        onClick={() =>
                          handleGetEducatorReflectionForm(educator.educatorID)
                        }
                      >
                        <img src={courseListIcon} alt="Details" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Delete Educator"
                    arrow
                  >
                    <IconButton
                      className="btn-close"
                      onClick={(e) => onEducatorDeleteClick(e, educator.id)}
                    >
                      <img
                        src={cancelIcon}
                        alt="cancel"
                        className="educator-delete-img"
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
        {(!educatorsList || educatorsList.length === 0) && (
          <Typography className="no-educators-text">
            No Educators assigned.
          </Typography>
        )}
      </Card>
    </Grid>
  );
};

export default EducatorsList;
