import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Pagination,
  PaginationItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  addEnrolIcon,
  addOldEnrolIcon,
  arrowBackwardIcon,
  arrowForwardIcon,
  attendanceIcon,
  calendarIcon,
  closeIcon,
  deleteIcon,
  editIcon,
  enrolSummIcon,
  filterLightIcon,
  filterPrimaryIcon,
  mailIcon,
  menuVerticalIcon,
  plusLightIcon,
  plusPrimaryIcon,
  radioCheckedIcon,
  radioIcon,
  saveIcon,
  sendMailIcon,
  sendSMSIcon,
  uploadIcon,
} from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";
import projectTheme from "app.theme";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AppRoutings } from "utils/enums/app-routings";

function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

function RadioCheckBoxIcon() {
  return <img src={radioIcon} alt="checkbox" />;
}
function RadioCheckedboxIcon() {
  return <img src={radioCheckedIcon} alt="checkbox" />;
}

function CalendarIcon() {
  return (
    <div className="calendar-icon">
      <img src={calendarIcon} alt="calendar" />
    </div>
  );
}

interface StudentData {
  stdName: string;
  dob: string;
  mobile: string;
  email: string;
  addr: string;
}

function createStudentData(
  stdName: string,
  dob: string,
  mobile: string,
  email: string,
  addr: string
): StudentData {
  return {
    stdName,
    dob,
    mobile,
    email,
    addr,
  };
}

const studentrows = [
  createStudentData(
    "Rachael Rogan",
    "20, Dec. 1995",
    "826384427",
    "rachael.rogan@gmail.com",
    "Recovery & wellbeing college, Kogarah"
  ),
  createStudentData(
    "Michale Conte",
    "18, Sep. 1992",
    "988263827",
    "michael.cote@email.com",
    "151 Goswell Road London "
  ),
  createStudentData(
    "Katherina Stufano",
    "13, Dec. 1998",
    "998263827",
    "katherina.stufano@email.com",
    "Recovery & wellbeing college, Kogarah"
  ),
  createStudentData(
    "Katherina Stufano",
    "19, Aug. 2001",
    "998263827",
    "katherina.stufano@email.com",
    "Recovery & wellbeing college, Kogarah"
  ),
  createStudentData(
    "Katherina Stufano",
    "26, Nov. 1999",
    "998263827",
    "katherina.stufano@email.com",
    "151 Goswell Road London "
  ),
];

interface Data {
  termstatus: string;
  term: number;
  startDate: string;
  enrolDate: string;
  location: string;
  sesscourse: string;
  enrollby: string;
  sesstime: string;
  attndrate: string;
  attndfinal: string;
  blendcourse: string;
  status: string;
}

function createData(
  termstatus: string,
  term: number,
  startDate: string,
  enrolDate: string,
  location: string,
  sesscourse: string,
  enrollby: string,
  sesstime: string,
  attndrate: string,
  attndfinal: string,
  blendcourse: string,
  status: string
): Data {
  return {
    termstatus,
    term,
    startDate,
    enrolDate,
    location,
    sesscourse,
    enrollby,
    sesstime,
    attndrate,
    attndfinal,
    blendcourse,
    status,
  };
}

const rows = [
  createData(
    "future",
    1,
    "03 Nov 2022",
    "03 Nov 2022",
    "151 Goswell Road London",
    "Life Beyond Trauma",
    "Rachael Rogan",
    "10:00 AM to 4:00 PM",
    "1.00",
    "Yes",
    "Online",
    "confirmed"
  ),
  createData(
    "past",
    1,
    "10 Nov 2022",
    "10 Nov 2022",
    "151 Goswell Road London",
    "Mindfulnes workshop",
    "Michale Conte",
    "12:00 PM to 2:00 PM",
    "1.00",
    "Yes",
    "-",
    "pending"
  ),
  createData(
    "current",
    1,
    "17 Nov 2022",
    "17 Nov 2022",
    "143 Pinkville Road",
    "Creativity for recovery",
    "Katherina Stufano",
    "10:00 AM to 4:00 PM",
    "0.00",
    "Yes",
    "Face to Face",
    "confirmed"
  ),
  createData(
    "past",
    2,
    "17 Nov 2022",
    "17 Nov 2022",
    "Goswell Square II",
    "An introduction to recovery & the strength of approch",
    "Katherina Stufano",
    "10:00 AM to 4:00 PM",
    "0.00",
    "Yes",
    "Face to Face",
    "pending"
  ),
];

interface EnrolSummData {
  course: string;
  location: string;
  datetime: string;
  enrolstatus: string;
}

function createEnrolSummData(
  course: string,
  location: string,
  datetime: string,
  enrolstatus: string
): EnrolSummData {
  return {
    course,
    location,
    datetime,
    enrolstatus,
  };
}

const EnrolSummDatarows = [
  createEnrolSummData(
    "Education for Recovery: Foundations",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023 10:00 AM to 4:00 PM",
    "confirmed"
  ),
  createEnrolSummData(
    "Skills for Co-facilitations",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM to 2:00 PM",
    "pending"
  ),
  createEnrolSummData(
    "Education for Recovery: Foundations",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023 10:00 AM to 4:00 PM",
    "confirmed"
  ),
  createEnrolSummData(
    "Skills for Co-facilitations",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM to 2:00 PM",
    "pending"
  ),
  createEnrolSummData(
    "Education for Recovery: Foundations",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023 10:00 AM to 4:00 PM",
    "confirmed"
  ),
];

interface AttendRecData {
  course: string;
  term: string;
  location: string;
  date: string;
  timedur: string;
  numattnd: number;
  attnd: string;
}

function createAttendRecData(
  course: string,
  term: string,
  location: string,
  date: string,
  timedur: string,
  numattnd: number,
  attnd: string
): AttendRecData {
  return {
    course,
    term,
    location,
    date,
    timedur,
    numattnd,
    attnd,
  };
}

const AttendRecDatarows = [
  createAttendRecData(
    "Education for Recovery: Foundations",
    "2023 T1",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023",
    "10:00 AM to 4:00 PM",
    32,
    "present"
  ),
  createAttendRecData(
    "Skills for Co-facilitations",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "12 Jan 2023",
    "12:00 PM to 2:00 PM",
    22,
    "present"
  ),
  createAttendRecData(
    "Education for Recovery: Foundations",
    "2023 T1",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023",
    "10:00 AM to 4:00 PM",
    32,
    "absent"
  ),
  createAttendRecData(
    "Skills for Co-facilitations",
    "2023 T2",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    " 01 Feb 2023",
    "10:00 AM to 4:00 PM",
    24,
    "present"
  ),
  createAttendRecData(
    "Education for Recovery: Foundations",
    "2023 T1",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023",
    "12:00 PM to 2:00 PM",
    15,
    "absent"
  ),
];

interface SendData {
  course: string;
  location: string;
  datetime: string;
  enrolstatus: string;
}

function createSendData(
  course: string,
  location: string,
  datetime: string,
  enrolstatus: string
): SendData {
  return {
    course,
    location,
    datetime,
    enrolstatus,
  };
}

const SendDatarows = [
  createSendData(
    "Education for Recovery: Foundations",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023 10:00 AM to 4:00 PM",
    "confirmed"
  ),
  createSendData(
    "Skills for Co-facilitations",
    "Recovery & wellbeing college, Kogarah",
    "12 Jan 2023, 12:00 PM to 2:00 PM",
    "pending"
  ),
  createSendData(
    "Education for Recovery: Foundations",
    "Recovery & wellbeing college, Kogarah - face to face/ online",
    "01 Feb 2023 10:00 AM to 4:00 PM",
    "confirmed"
  ),
];

const DesignerStudent: React.FC = () => {
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [studentdlt, setStudentdlt] = React.useState(false);
  const handleStudentdlt = () => setStudentdlt(true);
  const handleStudentdltClose = () => setStudentdlt(false);

  const [sendSMS, setSendSMS] = React.useState(false);
  const handleSendSMSClose = () => setSendSMS(false);

  const [sendMail, setSendMail] = React.useState(false);
  const handleSendMailClose = () => setSendMail(false);

  const [student, setStudent] = React.useState(true);
  const [studentDetail, setStudentDetail] = React.useState(false);
  const [enrolSumm, setEnrolSumm] = React.useState(false);
  const [attdrec, setAttdrec] = React.useState(false);

  const [stdDetails, setStdDetails] = React.useState<null | HTMLElement>(null);
  const open = Boolean(stdDetails);
  const handleStdDetailsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setStdDetails(event.currentTarget);
  };
  const handleStdDetailsClose = () => {
    setStdDetails(null);
  };

  const [dobDate, setDobDate] = React.useState<Dayjs | null>(
    dayjs("2022-01-12")
  );

  const [mediaConDate, setmediaConDate] = React.useState<Dayjs | null>();

  const [genderId, setGenderId] = React.useState("1");

  const handleGenderIdChange = (event: SelectChangeEvent) => {
    setGenderId(event.target.value as string);
  };

  const [sector, setSector] = React.useState("1");

  const handleSectorChange = (event: SelectChangeEvent) => {
    setSector(event.target.value as string);
  };

  const [feeForServ, setFeeForServ] = React.useState("1");

  const handleFeeForServChange = (event: SelectChangeEvent) => {
    setFeeForServ(event.target.value as string);
  };

  const [mediaConTo, setMediaConTo] = React.useState("1");

  const handleMediaConToChange = (event: SelectChangeEvent) => {
    setMediaConTo(event.target.value as string);
  };

  const [sexuality, setSexuality] = React.useState("2");

  const handleSexualityChange = (event: SelectChangeEvent) => {
    setSexuality(event.target.value as string);
  };

  const [activeEmr, setActiveEmr] = React.useState("2");

  const handleActiveEmrChange = (event: SelectChangeEvent) => {
    setActiveEmr(event.target.value as string);
  };

  const [enrolConPref, setEnrolConPref] = React.useState("1");

  const handleEnrolConPrefChange = (event: SelectChangeEvent) => {
    setEnrolConPref(event.target.value as string);
  };

  const [empStatus, setEmpStatus] = React.useState("1");

  const handleEmpStatusChange = (event: SelectChangeEvent) => {
    setEmpStatus(event.target.value as string);
  };

  const [team, setTeam] = React.useState("1");

  const handleTeamChange = (event: SelectChangeEvent) => {
    setTeam(event.target.value as string);
  };

  const [currntRole, setCurrntRole] = React.useState("1");

  const handleCurrntRoleChange = (event: SelectChangeEvent) => {
    setCurrntRole(event.target.value as string);
  };

  const [workEnv, setWorkEnv] = React.useState("1");

  const handleWorkEnvChange = (event: SelectChangeEvent) => {
    setWorkEnv(event.target.value as string);
  };

  const [siteServ, setSiteServ] = React.useState("1");

  const handleSiteServChange = (event: SelectChangeEvent) => {
    setSiteServ(event.target.value as string);
  };

  const [filterbox, setFilterbox] = React.useState(false);

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Box
        className="filter-overlay"
        onClick={() => {
          document.body.classList.toggle("filter-open");
          setFilterbox(false);
        }}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        {student && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Students
              </Typography>
              <Button variant="outlined" className="btn-export">
                Export
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{ justifyContent: "space-between", width: "100%" }}
                      className="search-filter-inputs"
                    >
                      <TextField
                        id="search"
                        variant="outlined"
                        className="search-input"
                        placeholder="Search"
                      />

                      <Button
                        variant="outlined"
                        className="btn-filter"
                        onClick={() => {
                          setFilterbox(true);
                          document.body.classList.toggle("filter-open");
                        }}
                      >
                        <img
                          src={filterPrimaryIcon}
                          alt="filter"
                          className="default"
                        />
                        <img
                          src={filterLightIcon}
                          alt="filter"
                          className="active"
                        />
                        <span>Filter</span>
                      </Button>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{ width: "153px", minWidth: "153px" }}
                          >
                            Student Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "144px", minWidth: "118px" }}
                          >
                            Date Of Birth
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "123px", minWidth: "105px" }}
                          >
                            Mobile
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "278px", minWidth: "216px" }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "341px", minWidth: "176px" }}
                          >
                            Address
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "152px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "112px",
                              },
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {studentrows.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.stdName}
                            </TableCell>
                            <TableCell>
                              {moment(row.dob).format("D, MMM. YYYY")}
                            </TableCell>
                            <TableCell>{row.mobile}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {row.addr}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <div className="table-actions">
                                <IconButton
                                  onClick={() => {
                                    setStudentDetail(true);
                                    setStudent(false);
                                  }}
                                >
                                  <img src={editIcon} alt="edit" />
                                </IconButton>
                                <IconButton onClick={handleStudentdlt}>
                                  <img src={deleteIcon} alt="delete" />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}

        {studentDetail && (
          <div className="main-content std-details">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Student Details
              </Typography>
              <Box className="std-detail-btn-group">
                <Button
                  variant="outlined"
                  className="btn-back"
                  onClick={() => {
                    setStudentDetail(false);
                    setStudent(true);
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  className="btn-add-enrol"
                  href={AppRoutings.DesignerStudentTemp}
                >
                  <img src={plusPrimaryIcon} alt="plus" className="default" />
                  <img src={plusLightIcon} alt="plus" className="active" />
                  Add New Enrolment
                </Button>
                <Button
                  id="std-details-button"
                  aria-controls={open ? "std-details-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleStdDetailsClick}
                  className="btn-vertical-menu"
                  variant="contained"
                >
                  <img src={menuVerticalIcon} alt="Menu" />
                </Button>
                <Menu
                  id="std-details-menu"
                  anchorEl={stdDetails}
                  className="std-details-menu"
                  open={open}
                  onClose={handleStdDetailsClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={handleStdDetailsClose}>
                    <img src={addEnrolIcon} alt="Add New Enrolment" />
                    Add New Enrolment
                  </MenuItem>
                  <MenuItem onClick={handleStdDetailsClose}>
                    <img src={addOldEnrolIcon} alt="Add Old Enrolment" />
                    Add Old Enrolment
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStdDetails(null);
                      setStudentDetail(false);
                      setEnrolSumm(true);
                    }}
                  >
                    <img src={enrolSummIcon} alt="Enrolment Summary" />
                    Enrolment Summary
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStdDetails(null);
                      setStudentDetail(false);
                      setAttdrec(true);
                    }}
                  >
                    <img src={attendanceIcon} alt="Attendance Record" />
                    Attendance Record
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStdDetails(null);
                      setSendSMS(true);
                    }}
                  >
                    <img src={sendSMSIcon} alt="Send SMS Confirmation" />
                    Send SMS Confirmation
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStdDetails(null);
                      setSendMail(true);
                    }}
                  >
                    <img src={sendMailIcon} alt="Send Email Confirmation" />
                    Send Email Confirmation
                  </MenuItem>
                  <MenuItem onClick={handleStdDetailsClose}>
                    <img src={deleteIcon} alt="Delete" />
                    Delete
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                >
                  <Grid item xs={12} lg={7}>
                    <Card className="std-details-card card-first">
                      <Typography variant="body1" className="sco-detail-title">
                        Student ID
                      </Typography>
                      <Typography variant="body1" className="sco-detail">
                        2337
                      </Typography>

                      <TextField
                        id="std-notes"
                        className="std-notes-textarea"
                        label="Student Notes"
                        fullWidth
                        variant="outlined"
                        defaultValue="Students will acquire skills  to use recovery oriented language"
                        multiline
                      />

                      <Box className="btn-group">
                        <Button variant="contained" className="btn-save">
                          <img src={saveIcon} alt="save" />
                          Save
                        </Button>
                        <Button variant="outlined" className="btn-cancel">
                          Cancel
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12} lg={5}>
                    <Card className="std-details-card">
                      <Grid
                        container
                        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Record Created by
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            Elise Vrgleski
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="body1"
                            className="sco-detail-title"
                          >
                            Date Record Created
                          </Typography>
                          <Typography variant="body1" className="sco-detail">
                            28-06-2023
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="enol-con-pref-label">
                              Enrolment Confirmation Preference
                            </InputLabel>
                            <Select
                              labelId="enol-con-pref-label"
                              id="enol-con-pref"
                              label="Enrolment Confirmation Preference"
                              value={enrolConPref}
                              onChange={handleEnrolConPrefChange}
                              required
                              className="std-details-input"
                            >
                              <MenuItem value={1}>Email</MenuItem>
                              <MenuItem value={2}>Email</MenuItem>
                              <MenuItem value={3}>Email</MenuItem>
                            </Select>
                          </FormControl>
                          <Box className="btn-group">
                            <Button variant="contained" className="btn-save">
                              <img src={saveIcon} alt="save" />
                              Save
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <Box className="table-card-header">
                        <Box
                          sx={{
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                            flexWrap: "wrap",
                            rowGap: "16px",
                          }}
                        >
                          <Typography variant="h3">
                            Enrolment and Attendance History
                          </Typography>
                          <TextField
                            id="search"
                            variant="outlined"
                            className="search-input"
                            placeholder="Search"
                          />
                        </Box>
                      </Box>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  maxWidth: "117px",
                                  width: "117px",
                                  minWidth: "117px",
                                }}
                              >
                                Term Status
                              </TableCell>
                              <TableCell
                                sx={{ width: "56px", minWidth: "56px" }}
                              >
                                Term
                              </TableCell>
                              <TableCell
                                sx={{ width: "121px", minWidth: "121px" }}
                              >
                                Course start Date
                              </TableCell>
                              <TableCell
                                sx={{ width: "114px", minWidth: "114px" }}
                              >
                                Date Enrolled
                              </TableCell>
                              <TableCell
                                sx={{
                                  width: "144px",
                                  minWidth: "144px",
                                  maxWidth: "144px",
                                }}
                              >
                                Location
                              </TableCell>
                              <TableCell
                                sx={{ width: "145px", minWidth: "145px" }}
                              >
                                Course Session
                              </TableCell>
                              <TableCell
                                sx={{ width: "150px", minWidth: "150px" }}
                              >
                                Enrolled by
                              </TableCell>
                              <TableCell
                                sx={{ width: "163px", minWidth: "163px" }}
                              >
                                Time of Session
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: "108px", minWidth: "108px" }}
                              >
                                Attendance Rate
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: "115px", minWidth: "115px" }}
                              >
                                Attendance Finalized
                              </TableCell>
                              <TableCell
                                sx={{ width: "133px", minWidth: "133px" }}
                              >
                                Blended Course
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{ width: "143px", minWidth: "132px" }}
                              >
                                Enrolment Status
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  width: "155px",
                                  minWidth: "155px",
                                  maxWidth: "155px",
                                }}
                              >
                                Send Confirmation Email
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className={`${row.termstatus} status`}
                                >
                                  <div>{row.termstatus} Term</div>
                                </TableCell>
                                <TableCell align="center">{row.term}</TableCell>
                                <TableCell>
                                  {moment(row.startDate).format("D MMM YYYY")}
                                </TableCell>
                                <TableCell>
                                  {moment(row.enrolDate).format("D MMM YYYY")}
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {row.location}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    {row.sesscourse}
                                  </Typography>
                                </TableCell>
                                <TableCell>{row.enrollby}</TableCell>
                                <TableCell>{row.sesstime}</TableCell>
                                <TableCell align="center">
                                  {row.attndrate}
                                </TableCell>
                                <TableCell align="center">
                                  {row.attndfinal}
                                </TableCell>
                                <TableCell align="center">
                                  {row.blendcourse}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className={`${row.status} status`}
                                >
                                  <div>{row.status}</div>
                                </TableCell>
                                <TableCell align="center">
                                  <div className="table-actions">
                                    <IconButton>
                                      <img src={mailIcon} alt="mail" />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card className="std-details-card">
                      <Typography variant="h3" className="attnd-heading">
                        Student Details
                      </Typography>
                      <Grid
                        container
                        columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="first-name1"
                            label="First Name"
                            fullWidth
                            variant="outlined"
                            defaultValue="Rachael"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="last-name1"
                            label="Last Name"
                            fullWidth
                            variant="outlined"
                            defaultValue="Rogan"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="phone"
                            label="Phone"
                            fullWidth
                            variant="outlined"
                            defaultValue="28012487"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="mobile1"
                            label="Mobile"
                            fullWidth
                            variant="outlined"
                            defaultValue="826384427"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="email"
                            label="Email"
                            fullWidth
                            variant="outlined"
                            defaultValue="rachael.rogan@gmail.com"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="strt-add"
                            label="Street Address"
                            fullWidth
                            variant="outlined"
                            defaultValue="Rachael Rogan"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="suburd"
                            label="Suburd"
                            fullWidth
                            variant="outlined"
                            defaultValue="Alfords Point"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="post-code"
                            label="Post Code"
                            fullWidth
                            variant="outlined"
                            defaultValue="32504"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="state"
                            label="State"
                            fullWidth
                            variant="outlined"
                            defaultValue="NEW"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopDatePicker"]}>
                              <DesktopDatePicker
                                className="date-picker"
                                label="Date of Birth"
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                  },
                                }}
                                slots={{
                                  openPickerIcon: CalendarIcon,
                                }}
                                format="DD/MM/YYYY"
                                value={dobDate}
                                onChange={(newValue) => setDobDate(newValue)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="contact-method"
                            label="Contact Method"
                            fullWidth
                            variant="outlined"
                            defaultValue="Email"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="emrg-ctnt-name"
                            label="Emergency Contact Name"
                            fullWidth
                            variant="outlined"
                            defaultValue="Katherina Stufano"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="emrg-ctnt-rel"
                            label="Emergency Contact Relationship"
                            fullWidth
                            variant="outlined"
                            defaultValue="Brother"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="emrg-ctnt-ph"
                            label="Emergency Contact Phone"
                            fullWidth
                            variant="outlined"
                            defaultValue="826384427"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="staff-enrol"
                            label="Staff Enrolment"
                            fullWidth
                            variant="outlined"
                            defaultValue="No"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="enrol-type"
                            label="Enrolment Type"
                            fullWidth
                            variant="outlined"
                            defaultValue="Consumer"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="pmt-inactive"
                            label="Permanent Inactivation"
                            fullWidth
                            variant="outlined"
                            defaultValue="No"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="deceased"
                            label="Deceased?"
                            fullWidth
                            variant="outlined"
                            defaultValue="No"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <TextField
                            id="mh-enc"
                            label="MH Encounter"
                            fullWidth
                            variant="outlined"
                            defaultValue="No"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="sector1-label">Sector</InputLabel>
                            <Select
                              labelId="sector1-label"
                              id="sector1"
                              label="Sector"
                              value={sector}
                              onChange={handleSectorChange}
                              required
                            >
                              <MenuItem value={1}>East</MenuItem>
                              <MenuItem value={2}>East</MenuItem>
                              <MenuItem value={3}>East</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="feeforServ-label">
                              Fee for Service
                            </InputLabel>
                            <Select
                              labelId="feeforServ-label"
                              id="feeforServ"
                              label="Fee for Service"
                              value={feeForServ}
                              onChange={handleFeeForServChange}
                              required
                            >
                              <MenuItem value={1}>No</MenuItem>
                              <MenuItem value={2}>Yes</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="support-need"
                            className="skills-textarea"
                            label="Supports Required/Specific Learning Needs"
                            fullWidth
                            variant="outlined"
                            multiline
                          />
                        </Grid>
                      </Grid>
                      <Box className="btn-group">
                        <Button variant="contained" className="btn-save">
                          <img src={saveIcon} alt="save" />
                          Save
                        </Button>
                        <Button variant="outlined" className="btn-cancel">
                          Cancel
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card className="std-details-card">
                      <Typography variant="h3" className="attnd-heading">
                        Research Profile Details
                      </Typography>
                      <Grid
                        container
                        columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      >
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="std-conn"
                            label="Student (Connection)"
                            fullWidth
                            variant="outlined"
                            defaultValue="Rachael Rogan"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="num-ques"
                            label="No. of Questionnaires Completed"
                            fullWidth
                            variant="outlined"
                            defaultValue="01"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            id="find-rc"
                            label="How did you hear about RC?"
                            fullWidth
                            variant="outlined"
                            defaultValue="Family/Friend"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            id="elg-criteria"
                            label="Eligibility Criteria"
                            fullWidth
                            variant="outlined"
                            defaultValue="Person Currently or Previously COVID Positive"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            id="svc-provider"
                            label="Service Provider"
                            fullWidth
                            variant="outlined"
                            defaultValue="Partners in Recovery"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            id="atsci-id"
                            label="Aboriginal/Torres Strait Islander Identity"
                            fullWidth
                            variant="outlined"
                            defaultValue="NEW"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="disability"
                            label="Disability"
                            fullWidth
                            variant="outlined"
                            defaultValue="No"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="cob"
                            label="Country of Birth"
                            fullWidth
                            variant="outlined"
                            defaultValue="UK"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="home-lang"
                            label="Language at home"
                            fullWidth
                            variant="outlined"
                            defaultValue="English"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="ethnicity"
                            label="Ethnicity"
                            fullWidth
                            variant="outlined"
                            defaultValue="Prefer not to say"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="genderid-label">
                              Gender Identity
                            </InputLabel>
                            <Select
                              labelId="genderid-label"
                              id="genderid"
                              label="Gender Identity"
                              value={genderId}
                              onChange={handleGenderIdChange}
                              required
                            >
                              <MenuItem value={1}>Woman</MenuItem>
                              <MenuItem value={2}>Woman</MenuItem>
                              <MenuItem value={3}>Woman</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="sexuality-label">
                              Sexuality
                            </InputLabel>
                            <Select
                              labelId="sexuality-label"
                              id="sexuality"
                              label="Sexuality"
                              value={sexuality}
                              onChange={handleSexualityChange}
                              required
                            >
                              <MenuItem value={1}>
                                Lesbian, Gay Bisexual
                              </MenuItem>
                              <MenuItem value={2}>
                                Lesbian, Gay Bisexual
                              </MenuItem>
                              <MenuItem value={3}>
                                Lesbian, Gay Bisexual
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="intersex"
                            label="Intersex"
                            fullWidth
                            variant="outlined"
                            defaultValue="Prefer not to say"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="mh-mrn"
                            label="MRN(MH)"
                            fullWidth
                            variant="outlined"
                            defaultValue="124548"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            id="research-code"
                            label="Research Code"
                            fullWidth
                            variant="outlined"
                            defaultValue="21548"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="active-emr-label">
                              Active Comm&#39;ty eMR &#40;at enrolment&#41;
                            </InputLabel>
                            <Select
                              labelId="active-emr-label"
                              id="active-emr"
                              label="Active Comm'ty eMR (at enrolment)"
                              value={activeEmr}
                              onChange={handleActiveEmrChange}
                              required
                            >
                              <MenuItem value={1}>Unknown</MenuItem>
                              <MenuItem value={2}>Unknown</MenuItem>
                              <MenuItem value={3}>Unknown</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Box className="btn-group">
                        <Button variant="contained" className="btn-save">
                          <img src={saveIcon} alt="save" />
                          Save
                        </Button>
                        <Button variant="outlined" className="btn-cancel">
                          Cancel
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card className="std-details-card">
                      <Typography variant="h3" className="attnd-heading">
                        Staff Details
                      </Typography>
                      <Grid
                        container
                        columnSpacing={{
                          sm: "16px",
                          md: " 20px",
                          xl: "24px",
                        }}
                      >
                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="empStatus-label">
                              Employment Status*
                            </InputLabel>
                            <Select
                              labelId="empStatus-label"
                              id="empStatus"
                              label="Employment Status*"
                              value={empStatus}
                              onChange={handleEmpStatusChange}
                              required
                            >
                              <MenuItem value={1}>Part-time</MenuItem>
                              <MenuItem value={2}>full-time</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="team-label">Team*</InputLabel>
                            <Select
                              labelId="team-label"
                              id="team"
                              label="Team*"
                              value={team}
                              onChange={handleTeamChange}
                              required
                            >
                              <MenuItem value={1}>Acute Care Team</MenuItem>
                              <MenuItem value={2}>Acute Care Team</MenuItem>
                              <MenuItem value={3}>Acute Care Team</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="currntRole-label">
                              Current Role*
                            </InputLabel>
                            <Select
                              labelId="currntRole-label"
                              id="currntRole"
                              label="Current Role*"
                              value={currntRole}
                              onChange={handleCurrntRoleChange}
                              required
                            >
                              <MenuItem value={1}>
                                Staff from Partner Service
                              </MenuItem>
                              <MenuItem value={2}>
                                Staff from Partner Service
                              </MenuItem>
                              <MenuItem value={3}>
                                Staff from Partner Service
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="workEnv-label">
                              Work Environment*
                            </InputLabel>
                            <Select
                              labelId="workEnv-label"
                              id="workEnv"
                              label="Work Environment*"
                              value={workEnv}
                              onChange={handleWorkEnvChange}
                              required
                            >
                              <MenuItem value={1}>Community</MenuItem>
                              <MenuItem value={2}>Community</MenuItem>
                              <MenuItem value={3}>Community</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="siteServ-label">
                              Site/Service*
                            </InputLabel>
                            <Select
                              labelId="siteServ-label"
                              id="siteServ"
                              label="Site/Service*"
                              value={siteServ}
                              onChange={handleSiteServChange}
                              required
                            >
                              <MenuItem value={1}>
                                STGH/St George Community
                              </MenuItem>
                              <MenuItem value={2}>
                                STGH/St George Community
                              </MenuItem>
                              <MenuItem value={3}>
                                STGH/St George Community
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="mang-name"
                            label="Manager's Name"
                            fullWidth
                            variant="outlined"
                            required
                            defaultValue="John Doe"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <TextField
                            id="mang-email"
                            label="Manager's Email"
                            fullWidth
                            variant="outlined"
                            defaultValue="johndoe@gmail.com"
                          />
                        </Grid>
                      </Grid>
                      <Box className="btn-group">
                        <Button variant="contained" className="btn-save">
                          <img src={saveIcon} alt="save" />
                          Save
                        </Button>
                        <Button variant="outlined" className="btn-cancel">
                          Cancel
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card className="std-details-card">
                      <Typography variant="h3" className="attnd-heading">
                        Media Consent
                      </Typography>
                      <Box className="media-upload">
                        <Typography variant="h5">Media Consent Scan</Typography>
                        <Box className="upload">
                          <Button
                            variant="contained"
                            color="secondary"
                            component="label"
                            className="btn-upload"
                          >
                            <img src={uploadIcon} alt="cloud" />
                            Choose File
                            <input
                              hidden
                              accept="image/*"
                              multiple
                              type="file"
                            />
                          </Button>
                          <Typography variant="body1">
                            No Selected Files
                          </Typography>
                        </Box>
                      </Box>
                      <Grid
                        container
                        className="media-consent"
                        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                      >
                        <Grid item xs={12} lg={4}>
                          <Typography variant="h5" className="confirmation">
                            Media Consent?
                          </Typography>
                          <RadioGroup row>
                            <FormControlLabel
                              value="1"
                              control={
                                <Radio
                                  icon={<RadioCheckBoxIcon />}
                                  checkedIcon={<RadioCheckedboxIcon />}
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="2"
                              control={
                                <Radio
                                  icon={<RadioCheckBoxIcon />}
                                  checkedIcon={<RadioCheckedboxIcon />}
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopDatePicker"]}>
                              <DesktopDatePicker
                                className="date-picker"
                                label="Media Consent date"
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                  },
                                }}
                                slots={{
                                  openPickerIcon: CalendarIcon,
                                }}
                                format="DD/MM/YYYY"
                                value={mediaConDate}
                                onChange={(newValue) =>
                                  setmediaConDate(newValue)
                                }
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <FormControl fullWidth className="select">
                            <InputLabel id="MediaConTo-label">
                              Media Consented to
                            </InputLabel>
                            <Select
                              labelId="MediaConTo-label"
                              id="MediaConTo"
                              label="Media Consented to"
                              value={mediaConTo}
                              onChange={handleMediaConToChange}
                              required
                            >
                              <MenuItem value={1}>Newsletter</MenuItem>
                              <MenuItem value={2}>Newsletter</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Box className="btn-group">
                        <Button variant="contained" className="btn-save">
                          <img src={saveIcon} alt="save" />
                          Save
                        </Button>
                        <Button variant="outlined" className="btn-cancel">
                          Cancel
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}

        {enrolSumm && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Enrollment summary
              </Typography>
              <Box className="std-detail-btn-group">
                <Button
                  variant="outlined"
                  className="btn-back"
                  onClick={() => {
                    setStudentDetail(true);
                    setEnrolSumm(false);
                  }}
                >
                  Back
                </Button>
                <Button variant="contained" className="btn-enrol-confirm">
                  enrollment confirmation
                </Button>
              </Box>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box className="table-card-texts">
                      <Typography variant="body1">Dear Student,</Typography>
                      <Typography variant="body1">
                        Welcome to the SOUTH EASTERN SYDNEY RECOVERY COLLEGE, We
                        hope becoming a student with us will take you closer to
                        achieving your goals and ambitions and enable you to
                        develop the knowledge and skills to support yourself and
                        others.
                      </Typography>
                      <Typography variant="body1">
                        Please see below enrolment requests
                      </Typography>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{ width: "345px", minWidth: "345px" }}
                          >
                            Course
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "405px", minWidth: "325px" }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "274px", minWidth: "274px" }}
                          >
                            Date & Time of First Session
                          </TableCell>
                          <TableCell align="center" sx={{ width: "152px" }}>
                            Enrolment Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {EnrolSummDatarows.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.course}
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {row.location}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.datetime}</TableCell>
                            <TableCell
                              align="center"
                              className={`${row.enrolstatus} status`}
                            >
                              <div>{row.enrolstatus}</div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}

        {attdrec && (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Attendance record
              </Typography>
              <Button
                variant="outlined"
                className="btn-back"
                onClick={() => {
                  setStudentDetail(true);
                  setAttdrec(false);
                }}
              >
                Back
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box
                      sx={{ justifyContent: "space-between", width: "100%" }}
                      className="search-filter-inputs"
                    >
                      <TextField
                        id="search"
                        variant="outlined"
                        className="search-input"
                        placeholder="Search"
                      />

                      <Typography variant="h5">
                        Student Name :{" "}
                        <span className="mail-add">Rachael Rogan</span>
                      </Typography>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{ width: "192px", minWidth: "192px" }}
                          >
                            Course
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "110px", minWidth: "110px" }}
                          >
                            Term
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "232px", minWidth: "232px" }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "164px", minWidth: "164px" }}
                          >
                            Date of First Session
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "179px", minWidth: "179px" }}
                          >
                            Time and duration of each session
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ width: "123px", minWidth: "123px" }}
                          >
                            No. Of Session in this Course
                          </TableCell>
                          <TableCell align="center" sx={{ width: "127px" }}>
                            Attendance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {AttendRecDatarows.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Typography variant="body2">
                                {row.course}
                              </Typography>
                            </TableCell>
                            <TableCell>{row.term}</TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {row.location}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {moment(row.date).format("DD MMM YYYY")}
                            </TableCell>
                            <TableCell>{row.timedur}</TableCell>
                            <TableCell>{row.numattnd}</TableCell>
                            <TableCell
                              align="center"
                              className={`${row.attnd} status`}
                            >
                              <div>{row.attnd}</div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    className="note"
                    sx={{ marginTop: { xs: "20px", lg: "41px" } }}
                  >
                    Note: Successful attendance is defined as attending at least
                    75% of the course (by duration).
                  </Typography>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
      </main>
      <Modal open={studentdlt} onClose={handleStudentdltClose}>
        <Box className="common-modal delete-modal">
          <Box className="modal-header">
            <Typography variant="h2">Delete Confirmation</Typography>
            <IconButton onClick={handleStudentdltClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Typography variant="h5">
              Are you sure you want to delete student ?
            </Typography>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-yes"
              onClick={handleStudentdltClose}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              className="btn-no"
              onClick={handleStudentdltClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={sendSMS} onClose={handleSendSMSClose}>
        <Box className="common-modal delete-modal send-sms-modal">
          <Box className="modal-header">
            <Typography variant="h2">Send SMS confirmation</Typography>
            <IconButton onClick={handleSendSMSClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Typography variant="h5" className="text-w-table">
              Are you sure want to send SMS confirmation for below courses?
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ width: "188px", minWidth: "188px" }}
                    >
                      Course
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "184px", minWidth: "184px" }}
                    >
                      Location
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "244px", minWidth: "244px" }}
                    >
                      Date & Time of First Session
                    </TableCell>
                    <TableCell align="center" sx={{ width: "152px" }}>
                      Enrolment Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {SendDatarows.map((row) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">{row.course}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{row.location}</Typography>
                      </TableCell>
                      <TableCell>{row.datetime}</TableCell>
                      <TableCell
                        align="center"
                        className={`${row.enrolstatus} status`}
                      >
                        <div>{row.enrolstatus}</div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-yes"
              onClick={handleSendSMSClose}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              className="btn-no"
              onClick={handleSendSMSClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={sendMail} onClose={handleSendMailClose}>
        <Box className="common-modal delete-modal send-sms-modal">
          <Box className="modal-header">
            <Typography variant="h2">Send Email confirmation </Typography>
            <IconButton onClick={handleSendMailClose}>
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="modal-body">
            <Typography variant="h5" className="text-w-table">
              Are you sure want to send Email confirmation for below courses?
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ width: "188px", minWidth: "188px" }}
                    >
                      Course
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "184px", minWidth: "184px" }}
                    >
                      Location
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "244px", minWidth: "244px" }}
                    >
                      Date & Time of First Session
                    </TableCell>
                    <TableCell align="center" sx={{ width: "152px" }}>
                      Enrolment Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {SendDatarows.map((row) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">{row.course}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{row.location}</Typography>
                      </TableCell>
                      <TableCell>{row.datetime}</TableCell>
                      <TableCell
                        align="center"
                        className={`${row.enrolstatus} status`}
                      >
                        <div>{row.enrolstatus}</div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-yes"
              onClick={handleSendMailClose}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              className="btn-no"
              onClick={handleSendMailClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>

      {filterbox && (
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <IconButton
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              <img src={closeIcon} alt="close" />
            </IconButton>
          </Box>
          <Box className="filter-body">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <DesktopDatePicker
                  className="date-picker"
                  label="Date of Birth"
                  slots={{
                    openPickerIcon: CalendarIcon,
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <TextField
              id="mobile"
              label="Mobile"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="email"
              label="Email"
              fullWidth
              variant="outlined"
              type="email"
            />
          </Box>
          <Box className="filter-footer">
            <Button
              variant="contained"
              className="btn-apply"
              onClick={() => {
                setFilterbox(false);
                document.body.classList.toggle("filter-open");
              }}
            >
              Apply
            </Button>
            <Button variant="outlined" className="btn-clear" disabled>
              Clear
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default DesignerStudent;
