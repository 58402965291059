import { all, fork } from "redux-saga/effects";

import authSaga from "./auth/saga";
import locationSaga from "./location/saga";
import roleSaga from "./role/saga";
import termSaga from "./term/saga";
import profileSaga from "./profile/saga";
import courseSaga from "./course/saga";
import educatorSaga from "./educator/saga";
import scheduledOfferingSaga from "./scheduledOffering/saga";
import dashboardSaga from "./dashboard/saga";
import educatorHomeSaga from "./educatorHome/saga";
import studentEnrolmentSaga from "./studentEnrolment/saga";
import studentSaga from "./student/saga";
import userSaga from "./user/saga";
import documentSaga from "./document/saga";
import studentDashboardSaga from "./studentDashboard/saga";
import reportSaga from "./report/saga";
import staffReportSaga from "./staffReport/saga";
import courseReportSaga from "./courseReport/saga";

export function* rootSaga() {
  yield all([fork(authSaga)]);
  yield all([fork(locationSaga)]);
  yield all([fork(roleSaga)]);
  yield all([fork(termSaga)]);
  yield all([fork(profileSaga)]);
  yield all([fork(courseSaga)]);
  yield all([fork(educatorSaga)]);
  yield all([fork(scheduledOfferingSaga)]);
  yield all([fork(dashboardSaga)]);
  yield all([fork(educatorHomeSaga)]);
  yield all([fork(studentEnrolmentSaga)]);
  yield all([fork(userSaga)]);
  yield all([fork(studentSaga)]);
  yield all([fork(documentSaga)]);
  yield all([fork(studentDashboardSaga)]);
  yield all([fork(reportSaga)]);
  yield all([fork(staffReportSaga)]);
  yield all([fork(courseReportSaga)]);
}
