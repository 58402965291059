import React, { ReactNode } from "react";
import { Box } from "@mui/system";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

if (window.innerWidth > 900) {
  document.body.classList.toggle("sidebar-toggle");
}

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <Header />
      <main>
        <Sidebar />
        <div className="main-content">
          <section>{children}</section>
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default Layout;
