import {
  TERM_LIST_REQUEST,
  TERM_LIST_FAILURE,
  TERM_LIST_SUCCESS,
  ADD_TERM_REQUEST,
  ADD_TERM_SUCCESS,
  ADD_TERM_FAILURE,
  EDIT_TERM_REQUEST,
  EDIT_TERM_SUCCESS,
  EDIT_TERM_FAILURE,
  DELETE_TERM_REQUEST,
  DELETE_TERM_SUCCESS,
  DELETE_TERM_FAILURE,
  TERM_BY_ID_REQUEST,
  TERM_BY_ID_SUCCESS,
  TERM_BY_ID_FAILURE,
} from "./actionTypes";
import {
  TermListPayload,
  TermListRequest,
  TermListSuccess,
  TermListSuccessPayload,
  TermListFailure,
  TermListFailurePayload,
  DeleteTermPayload,
  DeleteTermRequest,
  DeleteTermSuccessPayload,
  DeleteTermSuccess,
  DeleteTermFailurePayload,
  DeleteTermFailure,
  AddEditTermPayload,
  AddTermRequest,
  AddEditTermSuccessPayload,
  AddTermSuccess,
  AddEditTermFailurePayload,
  AddTermFailure,
  EditTermSuccess,
  EditTermRequest,
  EditTermFailure,
  TermByIdFailurePayload,
  TermByIdFailure,
  TermByIdPayload,
  TermByIdRequest,
  TermByIdSuccess,
  TermByIdSuccessPayload,
} from "./types";

export const termListRequest = (payload: TermListPayload): TermListRequest => ({
  type: TERM_LIST_REQUEST,
  payload,
});

export const termListSuccess = (
  payload: TermListSuccessPayload
): TermListSuccess => ({
  type: TERM_LIST_SUCCESS,
  payload,
});

export const termListFailure = (
  payload: TermListFailurePayload
): TermListFailure => ({
  type: TERM_LIST_FAILURE,
  payload,
});

export const addTermRequest = (
  payload: AddEditTermPayload
): AddTermRequest => ({
  type: ADD_TERM_REQUEST,
  payload,
});

export const addTermSuccess = (
  payload: AddEditTermSuccessPayload
): AddTermSuccess => ({
  type: ADD_TERM_SUCCESS,
  payload,
});

export const addTermFailure = (
  payload: AddEditTermFailurePayload
): AddTermFailure => ({
  type: ADD_TERM_FAILURE,
  payload,
});

export const editTermRequest = (
  payload: AddEditTermPayload
): EditTermRequest => ({
  type: EDIT_TERM_REQUEST,
  payload,
});

export const editTermSuccess = (
  payload: AddEditTermSuccessPayload
): EditTermSuccess => ({
  type: EDIT_TERM_SUCCESS,
  payload,
});

export const editTermFailure = (
  payload: AddEditTermFailurePayload
): EditTermFailure => ({
  type: EDIT_TERM_FAILURE,
  payload,
});

export const deleteTermRequest = (
  payload: DeleteTermPayload
): DeleteTermRequest => ({
  type: DELETE_TERM_REQUEST,
  payload,
});

export const deleteTermSuccess = (
  payload: DeleteTermSuccessPayload
): DeleteTermSuccess => ({
  type: DELETE_TERM_SUCCESS,
  payload,
});

export const deleteTermFailure = (
  payload: DeleteTermFailurePayload
): DeleteTermFailure => ({
  type: DELETE_TERM_FAILURE,
  payload,
});

export const termByIdRequest = (payload: TermByIdPayload): TermByIdRequest => ({
  type: TERM_BY_ID_REQUEST,
  payload,
});

export const termByIdSuccess = (
  payload: TermByIdSuccessPayload
): TermByIdSuccess => ({
  type: TERM_BY_ID_SUCCESS,
  payload,
});

export const termByIdFailure = (
  payload: TermByIdFailurePayload
): TermByIdFailure => ({
  type: TERM_BY_ID_FAILURE,
  payload,
});
