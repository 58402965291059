import { Box } from "@mui/system";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import { saveIcon } from "assets/images";
import React from "react";
import {
  IProfile,
  IProfileContainerDispatch,
  IProfileContainerState,
} from "utils/interfaces/profile";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { onTextFieldKeyPress, showLoader } from "utils/helper";
import { UpdateUserProfileSuccessPayload } from "store/profile/types";
import { toast } from "react-toastify";
import {
  ADDRESS_MAX_LENGTH,
  ALPHABETIC_INPUT_REGEX,
  FIRST_NAME_MAX_LENGTH,
  INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
  LAST_NAME_MAX_LENGTH,
} from "utils/constants/constant";
import { updateUser } from "store/auth/actions";
import { useHistory } from "react-router-dom";
import {
  ALPHABETIC_VALIDATION_MSG,
  UPDATED_USER_PROFILE_SUCCESS_MSG,
  WHITE_SPACE_ERROR_SPACE,
} from "utils/constants/Messages";

export type ProfileProps = IProfileContainerState & IProfileContainerDispatch;

const Profile: React.FC<ProfileProps> = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IProfile>();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const getFirstNameError = (): string => {
    if (errors.firstName) {
      if (errors.firstName.type === "required") {
        return "First name is required";
      }

      if (errors.firstName.type === "maxLength") {
        return `Maximum length of first name should be ${FIRST_NAME_MAX_LENGTH}`;
      }

      if (errors.firstName.type === "pattern") {
        return `First name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getLastNameError = (): string => {
    if (errors.lastName) {
      if (errors.lastName.type === "required") {
        return "Last name is required";
      }

      if (errors.lastName.type === "maxLength") {
        return `Maximum length of last name should be ${LAST_NAME_MAX_LENGTH}`;
      }

      if (errors.lastName.type === "pattern") {
        return `Last name ${ALPHABETIC_VALIDATION_MSG}`;
      }
    }

    return "";
  };

  const getAddressError = (): string => {
    if (errors.address) {
      if (errors.address.type === "maxLength") {
        return `Maximum length of address should be ${ADDRESS_MAX_LENGTH}`;
      }
      if (errors.address.type === "pattern") {
        return `Address ${WHITE_SPACE_ERROR_SPACE}`;
      }
    }

    return "";
  };

  const onUpdateProfileSuccess = (
    response: UpdateUserProfileSuccessPayload
  ) => {
    if (response.success) {
      toast.success(UPDATED_USER_PROFILE_SUCCESS_MSG);
      const payload = {
        values: {
          firstName: watch("firstName"),
          lastName: watch("lastName"),
          address: watch("address"),
        },
        callback: () => {
          return true;
        },
      };

      dispatch(updateUser(payload));
    }
  };

  const onSubmit = (formData: IProfile) => {
    const { updateUserProfileRequest } = props;

    if (updateUserProfileRequest) {
      showLoader();
      const payload = {
        values: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          address: formData.address,
        },
        callback: onUpdateProfileSuccess,
      };

      updateUserProfileRequest(payload);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Profile
        </Typography>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Card className="profile-card">
            <Box className="profile-card-container">
              <Grid container columnSpacing="24px">
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="first-name"
                    label={
                      <>
                        First Name <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    defaultValue={user?.firstName}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: !!user?.firstName || !!watch("firstName"),
                    }}
                    error={!!errors.firstName}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, watch("firstName")?.length)
                    }
                    helperText={getFirstNameError()}
                    {...register("firstName", {
                      required: true,
                      maxLength: FIRST_NAME_MAX_LENGTH,
                      pattern: ALPHABETIC_INPUT_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="last-name"
                    label={
                      <>
                        Last Name <span className="color-red">*</span>
                      </>
                    }
                    fullWidth
                    defaultValue={user?.lastName}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: !!user?.lastName || !!watch("lastName"),
                    }}
                    error={!!errors.lastName}
                    helperText={getLastNameError()}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, watch("lastName")?.length)
                    }
                    {...register("lastName", {
                      required: true,
                      maxLength: LAST_NAME_MAX_LENGTH,
                      pattern: ALPHABETIC_INPUT_REGEX,
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="role"
                    label="Role"
                    fullWidth
                    disabled
                    defaultValue={user?.roleName?.toString()}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="email"
                    label="Email"
                    fullWidth
                    disabled
                    defaultValue={user?.emailAddress}
                    variant="outlined"
                    {...register("emailAddress")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="address"
                    label="Address"
                    className="textarea-add"
                    fullWidth
                    multiline
                    rows={3}
                    defaultValue={user?.address}
                    variant="outlined"
                    error={!!errors.address}
                    helperText={getAddressError()}
                    {...register("address", {
                      maxLength: ADDRESS_MAX_LENGTH,
                      pattern:
                        INPUT_VALIDATION_NO_WHITE_SPACE_WITH_SPECIAL_CHARACTER_REGEX,
                    })}
                  />
                </Grid>
              </Grid>
              <Box className="btn-group">
                <Button variant="contained" className="btn-save" type="submit">
                  <img src={saveIcon} alt="save" />
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="btn-cancel"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default Profile;
