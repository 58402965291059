import { Box } from "@mui/system";
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  courseListIcon,
  courseListLightIcon,
  saveIcon,
  settingIcon,
} from "assets/images";
import React, { useEffect, useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ICourse, ICoursePagePayloadValues } from "utils/interfaces/course";
import {
  ILocation,
  ILocationPagePayloadValues,
} from "utils/interfaces/location";
import { ITerm, ITermPagePayloadValues } from "utils/interfaces/term";
import {
  base64ToArrayBuffer,
  checkEndDateLessThanOrEqualToStart,
  checkEndDateLessThanToStart,
  checkEndTimeLessThanStart,
  convertToDateTime,
  convertToDateTimeUsingOldFormat,
  convertToDateTimeWithFormat,
  getPagePayloadValues,
  getPagePermissions,
  getUploadedScoDocumentType,
  handlePasteNumberField,
  hideLoaderForBatchApiCall,
  onNumberFieldKeyPress,
  showLoader,
  showLoaderForBatchApiCall,
} from "utils/helper";
import {
  DATE_FORMAT,
  DATE_FORMAT_WITH_TIME,
  DATE_VIEW_FORMAT,
  DEFAULT_PAGE_NO,
  MAX_INT_VALUE,
  SCO_CERTIFICATE,
  SCO_STATUS,
  TERM_STATUS,
  TIME_FORMAT,
} from "utils/constants/constant";
import { SCOCertificate, SCORepeatEnd } from "utils/enums/scheduled-offering";
import { CourseListSuccessPayload } from "store/course/types";
import { courseListRequest } from "store/course/actions";
import { LocationListSuccessPayload } from "store/location/types";
import { locationListRequest } from "store/location/actions";
import { TermListSuccessPayload } from "store/term/types";
import { termListRequest } from "store/term/actions";
import {
  GenerateCertificateStatus,
  IAddEditScheduledOffering,
  IAddScheduleDetails,
  ICourseCancel,
  ICourseOfferingDays,
  ICourseOfferingDaysResponse,
  ICourseOfferingEducators,
  ICourseReflectionsForm,
  IDeleteDocDetails,
  IRepeat,
  ISCODetail,
  ISCOGenerateCertificateStudentListData,
  IScoDetails,
  IScoDetailsContainerDispatch,
  IScoDetailsContainerState,
  IScoDocument,
  IScoEducator,
  IScoSession,
} from "utils/interfaces/scheduled-offering";
import { Controller, useForm } from "react-hook-form";
import {
  AddEditScheduledOfferingSuccessPayload,
  AddEditSessionDaySuccessPayload,
  AddScoEducatorSuccessPayload,
  CancelSessionDaySuccessPayload,
  DeleteDocumentSuccessPayload,
  DeleteScoEducatorSuccessPayload,
  DeleteSessionDaySuccessPayload,
  DownloadDocumentSuccessPayload,
  FillGenerateCertificateSuccessPayload,
  GetEducatorReflectionFormSuccessPayload,
  GetSCOGenerateCertificateListSuccessPayload,
  GetScheduledOfferingByIdSuccessPayload,
  ScoDaysListSuccessPayload,
  SessionListByScoIdSuccessPayload,
  UploadDocumentSuccessPayload,
} from "store/scheduledOffering/types";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import { IRoleModulePermission } from "store/role/types";
import { AppRoutings } from "utils/enums/app-routings";
import {
  DOC_DELETE_SUCCESS_MSG,
  DOC_UPLOAD_SUCCESS_MSG,
  GENERATE_CERTIFICATE_FILLED_SUCCESS_MESSAGE,
  SCO_EDIT_SUCCESS_MSG,
  SCO_EDUCATOR_ADD_SUCCESS_MSG,
  SCO_EDUCATOR_DELETE_SUCCESS_MSG,
  SESSION_DAY_ADD_SUCCESS_MSG,
  SESSION_DAY_CANCEL_SUCCESS_MSG,
  SESSION_DAY_DELETE_SUCCESS_MSG,
  SESSION_DAY_EDIT_SUCCESS_MSG,
  SESSION_START_DATE_SHOULD_BE_BETWEEN_TERM_DATES,
} from "utils/constants/Messages";
import { OrderByOptions } from "utils/enums/sorting";
import {
  saveGenerateCertificateRequest,
  scoDaysListRequest,
} from "store/scheduledOffering/actions";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import CalendarIcon from "components/CalendarIcon";
import { AppPages } from "utils/enums/app-pages";
import { ExportToCsv } from "export-to-csv";
import CheckboxIcon from "components/CheckBoxIcon";
import CheckedBoxIcon from "components/CheckedBoxIcon";
import EducatorsList from "./EducatorsList";
import SessionDaysAndAttendance from "./SessionDays";
import Enrollments from "./Enrollments";
import DocumentsList from "./DocumentsList";
import AddEditSessionDay from "./AddEditSessionDay";
import AddEducators from "./AddEducators";
import GenerateCertificateModal from "./GenerateCertificate";
import EducatorCourseReflectionForm from "./CourseReflectionForm";
import RepeatModal from "../RepeatModal";
import AddSCODays from "../AddSCODays";

export type SCODetailsProps = IScoDetailsContainerState &
  IScoDetailsContainerDispatch;

const SCODetails: React.FC<SCODetailsProps> = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors },
  } = useForm<IAddEditScheduledOffering>({
    defaultValues: {
      courseId: 0,
      status: 0,
      termId: 0,
      termStatus: 0,
      locationId: 0,
      certificatesIssued: null,
      emrEntered: null,
    },
  });
  const {
    enroledStudentListRequest,
    enrolmentOptionListRequest,
    SCOStudentAttendanceListRequest,
    fillStudentAttendanceRequest,
    SCOStudentWhoAttendedListRequest,
    enrolmentCountRequest,
    fillGenerateCertificateRequest,
    SCOGenerateCertificateListRequest,
    printCourseEnrolmentsRequest,
    downloadCertificateRequest,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const location =
    useLocation<{ scoId: number; autoScrollToSessions: boolean }>();
  const user = useSelector((state: any) => state?.auth?.user);
  const [courseList, setCourseList] = useState<ICourse[]>();
  const [locationList, setLocationList] = useState<ILocation[]>();
  const [termList, setTermList] = useState<ITerm[]>();
  const [scheduledOfferingDetails, setScheduledOfferingDetails] =
    useState<IScoDetails>();
  const [documentsList, setDocumentsList] = useState<IScoDocument[]>([]);
  const [educatorsList, setEducatorsList] = useState<IScoEducator[]>([]);
  const [sessionsList, setSessionsList] = useState<IScoSession[]>([]);
  const [addEditSessionDayModal, setAddEditSessionDayModal] =
    useState<boolean>(false);
  const [isEditSessionDay, setIsEditSessionDay] = useState<boolean>(false);
  const [editSessionDayData, setEditSessionDayData] =
    useState<ICourseOfferingDaysResponse>();
  const [isOpenDeleteDocModal, setIsOpenDeleteDocModal] = useState(false);
  const [deleteDocData, setDeleteDocData] = useState<IDeleteDocDetails>();
  const [eduPagePermission, setEduPagePermission] =
    useState<IRoleModulePermission>();
  const [coursePagePermission, setCoursePagePermission] =
    useState<IRoleModulePermission>();
  const [pickerMinDate, setPickerMinDate] = useState<any>();
  const [pickerMaxDate, setPickerMaxDate] = useState<any>();
  const [addEducatorsModal, setAddEducatorsModal] = useState<boolean>(false);
  const detailsRef = useRef<null | HTMLDivElement>(null);
  const [isOpenDeleteEducatorModal, setIsOpenDeleteEducatorModal] =
    useState(false);
  const [deleteEducatorId, setDeleteEducatorId] = useState<number>(0);
  const [isOpenGenerateCertificate, setIsOpenGenerateCertificate] =
    useState<boolean>(false);
  const [generateCertificateStatus, setGenerateCertificateStatus] = useState<
    GenerateCertificateStatus[]
  >([]);
  const [generateCertificateRows, setGenerateCertificateRows] = useState<
    ISCOGenerateCertificateStudentListData[]
  >([]);
  const isSaveCertificateChange = useSelector(
    (state: any) => state?.scheduledOffering?.isSaveCertificate
  );
  const [isEducatorDetailsOpen, setIsEducatorDetailsOpen] =
    useState<boolean>(false);
  const [reflectionsFormData, setReflectionsFormData] =
    useState<ICourseReflectionsForm>();
  const handleEducatorDetailsClose = () => {
    setIsEducatorDetailsOpen(false);
    setReflectionsFormData(undefined);
  };
  const handleAddEducatorsModal = () => setAddEducatorsModal(true);
  const handleAddEducatorsModalClose = () => setAddEducatorsModal(false);
  const [isEnableGenerateCertificate, setIsEnableGenerateCertificate] =
    useState<boolean>(false);
  const [isSingleSession, setIsSingleSession] = useState<boolean>(false);
  const [repeatDataError, setRepeatDataError] = useState<string>("");
  const [isOpenRepeatModal, setIsOpenRepeatModal] = useState<boolean>(false);
  const handleRepeatModalClose = () => setIsOpenRepeatModal(false);
  const [repeatCourseOffering, setRepeatCourseOffering] = useState<IRepeat>();
  const [addFormData, setAddFormData] = useState<IAddEditScheduledOffering>();
  const [scoDaysList, setScoDaysList] =
    useState<ICourseOfferingDaysResponse[]>();
  const [addSCODaysModal, setAddSCODaysModal] = useState<boolean>(false);
  const [scoDetails, setScoDetails] = useState<ISCODetail>();
  const [isRepeatChange, setIsRepeatChange] = useState<boolean>(false);
  const [isTermChange, setIsTermChange] = useState<boolean>(false);

  const handleAddSCODaysModal = () => setAddSCODaysModal(true);
  const handleAddSCODaysModalClose = () => setAddSCODaysModal(false);

  const handleAddEditSessionDayModal = (
    isEdit: boolean,
    editData: ICourseOfferingDaysResponse | null
  ) => {
    setIsEditSessionDay(isEdit);
    if (editData) {
      setEditSessionDayData(editData);
    }
    setAddEditSessionDayModal(true);
  };

  const handleAddEditSessionDayModalClose = () =>
    setAddEditSessionDayModal(false);

  const onCourseListSuccess = (response: CourseListSuccessPayload) => {
    setCourseList(response.courseList);
  };

  const getCourseList = () => {
    showLoader();

    const values: ICoursePagePayloadValues = getPagePayloadValues(
      "Name",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.name = "";

    const payload = {
      values,
      callback: onCourseListSuccess,
    };

    dispatch(courseListRequest(payload));
  };

  const onLocationListSuccess = (response: LocationListSuccessPayload) => {
    setLocationList(response.locationList);
  };

  const getLocationList = async () => {
    showLoader();
    const values: ILocationPagePayloadValues = getPagePayloadValues(
      "Location",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.location = "";

    const payload = {
      values,
      callback: onLocationListSuccess,
    };

    dispatch(locationListRequest(payload));
  };

  const onTermListSuccess = (response: TermListSuccessPayload) => {
    setTermList(response.termList);
  };

  const getTermList = () => {
    showLoader();
    const values: ITermPagePayloadValues = getPagePayloadValues(
      "TermName",
      OrderByOptions.Asc,
      DEFAULT_PAGE_NO,
      MAX_INT_VALUE
    );
    values.termName = "";
    const payload = {
      values,
      callback: onTermListSuccess,
    };

    dispatch(termListRequest(payload));
  };

  const onScheduledOfferingByIdSuccess = (
    response: GetScheduledOfferingByIdSuccessPayload
  ) => {
    setScheduledOfferingDetails(response.scheduledOfferingById);
    setEducatorsList(response.scheduledOfferingById.courseOfferingEducators);
    setDocumentsList(response.scheduledOfferingById.courseOfferingDocuments);
    setRepeatCourseOffering(
      response.scheduledOfferingById.courseOfferingRepeatSettingsDTO
    );
    if (response.scheduledOfferingById?.isCertificateIssued)
      setIsEnableGenerateCertificate(true);
    setIsSingleSession(response.scheduledOfferingById.isAllDay);
    setScoDetails({
      courseName: response.scheduledOfferingById.courseDTO.name,
      location: response.scheduledOfferingById.locationDTO.location,
      termName: response.scheduledOfferingById.termsDTO.termName,
    });
  };

  const getCourseOfferingDetails = () => {
    const { getScheduledOfferingByIdRequest } = props;

    if (getScheduledOfferingByIdRequest) {
      showLoader();
      const { scoId } = location.state;

      const payload = {
        values: { id: scoId },
        callback: onScheduledOfferingByIdSuccess,
      };

      getScheduledOfferingByIdRequest(payload);
    }
  };

  const onSessionListByScoIdSuccess = (
    response: SessionListByScoIdSuccessPayload
  ) => {
    setSessionsList(response.sessionListByScoId);
    setValue("noOfSessions", Number(response.sessionListByScoId?.length));
  };

  const getCourseOfferingSessionsList = () => {
    const { sessionListByScoIdRequest } = props;

    if (sessionListByScoIdRequest) {
      showLoader();
      const { scoId } = location.state;

      const payload = {
        values: { id: scoId },
        callback: onSessionListByScoIdSuccess,
      };

      sessionListByScoIdRequest(payload);
    }
  };

  const executeScroll = () => {
    if (detailsRef.current) {
      detailsRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.ScheduledOfferings
    );

    if (location.state?.scoId) {
      if (permission?.edit) {
        getCourseList();
        getLocationList();
        getTermList();
        getCourseOfferingDetails();
        getCourseOfferingSessionsList();
        setRepeatDataError("");
        const coursePermission = getPagePermissions(
          user?.permissions,
          AppPages.Courses
        );
        setCoursePagePermission(coursePermission);
        const educatorsPermission = getPagePermissions(
          user?.permissions,
          AppPages.Educators
        );
        setEduPagePermission(educatorsPermission);
        if (!location.state?.autoScrollToSessions) {
          executeScroll();
        }
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scheduledOfferingDetails) {
      setValue("id", scheduledOfferingDetails.id);
      setValue("courseNumber", scheduledOfferingDetails.courseDTO?.courseNo);
      setValue("courseId", scheduledOfferingDetails.courseID);
      setValue("status", scheduledOfferingDetails.status);
      setValue("termId", scheduledOfferingDetails.termID);
      setValue("termStatus", scheduledOfferingDetails.termsDTO?.status);
      setValue("noOfSessions", scheduledOfferingDetails.noOfSessions);
      setValue("locationId", scheduledOfferingDetails.locationID);

      const startDate = convertToDateTime(
        scheduledOfferingDetails.startDateOfFirstSession
      );
      const endDate = convertToDateTime(
        scheduledOfferingDetails.endDateOfFirstSession
      );
      setValue("startDateOfFirstSession", dayjs(startDate));
      setValue("endDateOfFirstSession", dayjs(endDate));

      const startTime = convertToDateTime(
        scheduledOfferingDetails.startTimeOfFirstSession
      );
      setValue("startTimeOfFirstSession", dayjs(startTime));

      const endTime = convertToDateTime(
        scheduledOfferingDetails.endTimeOfFirstSession
      );
      setValue("endTimeOfFirstSession", dayjs(endTime));

      setValue(
        "certificatesIssued",
        Number(scheduledOfferingDetails.isCertificateIssued)
      );
      setValue("emrEntered", Number(scheduledOfferingDetails.isEMREntered));
      setValue("maxEnrollments", scheduledOfferingDetails.maxEnrollments);
      setValue("isAllDay", scheduledOfferingDetails.isAllDay);
      setValue("isRepeat", scheduledOfferingDetails.isRepeat);

      const selectedTerm = termList?.find(
        (term) => Number(term.id) === Number(scheduledOfferingDetails.termID)
      );

      const minDate = dayjs(selectedTerm?.startDate);
      const maxDate = dayjs(selectedTerm?.endDate);

      setPickerMinDate(minDate);
      setPickerMaxDate(maxDate);
    }
  }, [scheduledOfferingDetails, setValue, termList]);

  useEffect(() => {
    if (watch("courseId")) {
      const selectedCourse = courseList?.find(
        (course) => Number(course.id) === Number(watch("courseId"))
      );
      setValue("courseNumber", selectedCourse?.courseNo);
    }
    if (watch("termId")) {
      const selectedTerm = termList?.find(
        (term) => Number(term.id) === Number(watch("termId"))
      );
      setValue("termStatus", selectedTerm?.status);
      const minDate = dayjs(selectedTerm?.startDate);
      const maxDate = dayjs(selectedTerm?.endDate);
      if (watch("termId") !== scheduledOfferingDetails?.termID)
        setIsTermChange(true);
      setPickerMinDate(minDate);
      setPickerMaxDate(maxDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("courseId"), courseList, watch("termId"), termList]);

  const getCourseIdError = (): string => {
    if (errors.courseId) {
      if (errors.courseId.type === "required") {
        return "Course is required";
      }
    }

    return "";
  };

  const getStatusError = (): string => {
    if (errors.status) {
      if (errors.status.type === "required") {
        return "Status is required";
      }
    }

    return "";
  };

  const getTermIdError = (): string => {
    if (errors.termId) {
      if (errors.termId.type === "required") {
        return "Term is required";
      }
    }

    return "";
  };

  const getLocationIdError = (): string => {
    if (errors.locationId) {
      if (errors.locationId.type === "required") {
        return "Location is required";
      }
    }

    return "";
  };

  const getStartDateError = (): string => {
    if (errors.startDateOfFirstSession) {
      if (errors.startDateOfFirstSession.type === "required") {
        return "Start date is required";
      }
      if (errors.startDateOfFirstSession.type === "validate") {
        return SESSION_START_DATE_SHOULD_BE_BETWEEN_TERM_DATES;
      }
    }

    return "";
  };

  const getStartTimeError = (): string => {
    if (errors.startTimeOfFirstSession) {
      if (errors.startTimeOfFirstSession.type === "required") {
        return "Start time is required";
      }
    }

    return "";
  };

  const getEndTimeError = (): string => {
    if (errors.endTimeOfFirstSession) {
      if (errors.endTimeOfFirstSession.type === "required") {
        return "End time is required";
      }
      if (errors.endTimeOfFirstSession.type === "validate") {
        return "End time should be greater than Start time";
      }
    }

    return "";
  };

  const getMaxEnrolmentsError = (): string => {
    if (errors.maxEnrollments) {
      if (errors.maxEnrollments.type === "min") {
        return "Max Enrolment value cannot be decreased.";
      }
    }

    return "";
  };

  const handleDeleteDocModal = (deleteData: IDeleteDocDetails) => {
    setDeleteDocData(deleteData);
    setIsOpenDeleteDocModal(true);
  };

  const handleDeleteDocCloseModal = () => {
    setDeleteDocData({
      docId: 0,
      type: 0,
      courseOfferingID: 0,
    });
    setIsOpenDeleteDocModal(false);
  };

  const onEditScheduledOfferingSuccess = (
    response: AddEditScheduledOfferingSuccessPayload
  ) => {
    if (response.success) {
      toast.success(SCO_EDIT_SUCCESS_MSG);
      handleAddSCODaysModalClose();
      getCourseOfferingDetails();
      getCourseOfferingSessionsList();
      getCourseOfferingDetails();
      setIsRepeatChange(false);
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const onScoDaysListSuccess = (response: ScoDaysListSuccessPayload) => {
    if (
      dayjs(watch("startDateOfFirstSession")).isBefore(dayjs()) &&
      scheduledOfferingDetails?.termID === watch("termId")
    ) {
      const filteredSessions = sessionsList.filter((item: IScoSession) =>
        dayjs(item.sessionStartDateTime).isBefore(dayjs())
      );
      const lastIndex =
        filteredSessions.length > 0
          ? sessionsList
              .sort((x) => x.dayNumber)
              .lastIndexOf(filteredSessions[filteredSessions.length - 1])
          : -1;

      let lastIndexDayNumber =
        lastIndex === -1 ? 0 : sessionsList[lastIndex].dayNumber;
      let updatedDaysList = response.scoDaysList.map((item) => {
        lastIndexDayNumber += 1;
        item.dayNumber = lastIndexDayNumber;

        return item;
      });
      const TodaysSession = updatedDaysList.find(
        (session) =>
          dayjs(session.sessionStartDateTime).date() === dayjs().date()
      );
      const currentTimeParsed = dayjs(dayjs().format("HH:mm:ss"), "HH:mm:ss");
      const firstSessionTimeParsed = dayjs(
        dayjs(watch("startTimeOfFirstSession")).format("HH:mm:ss"),
        "HH:mm:ss"
      );
      if (TodaysSession && currentTimeParsed.isAfter(firstSessionTimeParsed)) {
        updatedDaysList = updatedDaysList.filter(
          (session) => session.dayNumber !== TodaysSession?.dayNumber
        );
        let { dayNumber } = TodaysSession;
        updatedDaysList = updatedDaysList.map((item) => {
          item.dayNumber = dayNumber;
          dayNumber += 1;

          return item;
        });
        setScoDaysList(updatedDaysList);
      } else {
        setScoDaysList(updatedDaysList);
      }
    } else {
      setScoDaysList(response.scoDaysList);
    }
    handleAddSCODaysModal();
  };

  const handleAddScheduledOffering = (formData: IAddEditScheduledOffering) => {
    if (isTermChange && !isSingleSession) {
      setRepeatDataError("Please update repeat settings.");

      return;
    }
    let changeRepeatSetting = false;

    if (
      ((scheduledOfferingDetails && scheduledOfferingDetails?.isAllDay) !==
        isSingleSession ||
        repeatCourseOffering !==
          scheduledOfferingDetails?.courseOfferingRepeatSettingsDTO) &&
      !isSingleSession
    ) {
      changeRepeatSetting = true;
    } else {
      changeRepeatSetting = false;
    }
    setIsRepeatChange(changeRepeatSetting);
    if (changeRepeatSetting) {
      if (!isSingleSession && !repeatCourseOffering?.repeatType) {
        setRepeatDataError("Please select required repeat data.");
      } else {
        formData.isAllDay = isSingleSession;
        formData.isRepeat = !isSingleSession;
        setAddFormData(formData);
        if (!isSingleSession) {
          if (scoDaysListRequest && repeatCourseOffering) {
            showLoader();
            const payload = {
              values: {
                startDateOfFirstSession: dayjs(
                  watch("startDateOfFirstSession")
                ).isBefore(dayjs())
                  ? convertToDateTimeWithFormat(
                      dayjs().add(1, "day"),
                      DATE_FORMAT_WITH_TIME
                    )
                  : convertToDateTimeWithFormat(
                      formData.startDateOfFirstSession,
                      DATE_FORMAT_WITH_TIME
                    ),
                repeatType: repeatCourseOffering?.repeatType,
                occurenceStart: Number(repeatCourseOffering?.occurenceStart),
                repeatDays: repeatCourseOffering?.repeatDays
                  ? repeatCourseOffering?.repeatDays?.substring(
                      0,
                      repeatCourseOffering?.repeatDays?.length
                    )
                  : "",
                endType: repeatCourseOffering?.endType,
                occurenceEnd:
                  repeatCourseOffering?.endType === SCORepeatEnd.After
                    ? Number(repeatCourseOffering?.occurenceEnd)
                    : null,
                endOn:
                  repeatCourseOffering?.endType === SCORepeatEnd.On
                    ? convertToDateTimeWithFormat(
                        repeatCourseOffering?.endOn,
                        DATE_FORMAT_WITH_TIME
                      )
                    : null,
              },
              callback: onScoDaysListSuccess,
            };
            if (payload?.values?.repeatDays) {
              payload.values.repeatDays = payload?.values?.repeatDays.replace(
                /,+$/,
                ""
              );
            }
            dispatch(scoDaysListRequest(payload));
          }
        } else {
          handleAddSCODaysModal();
        }
      }
    } else {
      const startDate = convertToDateTimeWithFormat(
        formData?.startDateOfFirstSession,
        DATE_FORMAT
      );
      const endDate = convertToDateTimeWithFormat(
        scheduledOfferingDetails?.endDateOfFirstSession,
        DATE_FORMAT
      );

      const startTime = convertToDateTimeWithFormat(
        formData?.startTimeOfFirstSession,
        TIME_FORMAT
      );
      const endTime = convertToDateTimeWithFormat(
        formData?.endTimeOfFirstSession,
        TIME_FORMAT
      );

      setIsEnableGenerateCertificate(
        formData?.certificatesIssued === SCOCertificate.Yes
      );

      const { editScheduledOfferingRequest } = props;
      if (editScheduledOfferingRequest) {
        showLoader();
        const payload = {
          values: {
            id: formData.id,
            courseId: Number(formData?.courseId),
            locationId: Number(formData?.locationId),
            termId: Number(formData?.termId),
            maxEnrollments: formData?.maxEnrollments
              ? Number(formData?.maxEnrollments)
              : null,
            certificatesIssued:
              formData?.certificatesIssued === SCOCertificate.No ||
              formData?.certificatesIssued === SCOCertificate.Yes
                ? Number(formData?.certificatesIssued)
                : null,
            emrEntered:
              formData?.emrEntered === SCOCertificate.No ||
              formData?.emrEntered === SCOCertificate.Yes
                ? Number(formData?.emrEntered)
                : null,
            startDateOfFirstSession: `${startDate} ${convertToDateTimeUsingOldFormat(
              startDate,
              DATE_FORMAT,
              TIME_FORMAT
            )}`,
            startTimeOfFirstSession: startTime,
            endTimeOfFirstSession: endTime,
            endDateOfFirstSession: `${endDate} ${convertToDateTimeUsingOldFormat(
              endDate,
              DATE_FORMAT,
              TIME_FORMAT
            )}`,
            isAllDay: isSingleSession,
            isRepeat: !isSingleSession,
            status: formData?.status,
            isRepeatChange: changeRepeatSetting || isSingleSession,
          },
          callback: onEditScheduledOfferingSuccess,
        };

        editScheduledOfferingRequest(payload);
      }
    }
  };
  const handleSaveClick = (addScheduleRow: Array<IAddScheduleDetails>) => {
    const formData = addFormData;
    const startDate = convertToDateTimeWithFormat(
      formData?.startDateOfFirstSession,
      DATE_FORMAT
    );
    const endDate = convertToDateTimeWithFormat(
      scheduledOfferingDetails?.endDateOfFirstSession,
      DATE_FORMAT
    );
    const startTime = convertToDateTimeWithFormat(
      formData?.startTimeOfFirstSession,
      TIME_FORMAT
    );
    const endTime = convertToDateTimeWithFormat(
      formData?.endTimeOfFirstSession,
      TIME_FORMAT
    );

    setIsEnableGenerateCertificate(
      formData?.certificatesIssued === SCOCertificate.Yes
    );

    let repeat;

    if (repeatCourseOffering) {
      repeat = {
        ...repeatCourseOffering,
        repeatDays: repeatCourseOffering.repeatDays
          ? repeatCourseOffering.repeatDays?.substring(
              0,
              repeatCourseOffering.repeatDays?.length
            )
          : "",
        endOn: !repeatCourseOffering?.endOn
          ? null
          : convertToDateTimeWithFormat(
              repeatCourseOffering.endOn,
              `${DATE_FORMAT} ${TIME_FORMAT}`
            ),
      };
    }

    if (repeat?.repeatDays) {
      repeat.repeatDays = repeat.repeatDays.replace(/,+$/, "");
    }
    if (repeat?.endOn === "Invalid date") {
      repeat.endOn = null;
    }

    const offeringDays: Array<ICourseOfferingDays> = [];
    addScheduleRow.forEach((row) => {
      const formattedSessionDate = `${convertToDateTime(
        row.sessionDate,
        DATE_FORMAT
      )} ${startTime}`;
      const formattedSessionEndTime = `${convertToDateTime(
        row.sessionDate,
        DATE_FORMAT
      )} ${endTime}`;
      offeringDays.push({
        dayNumber: Number(row.day),
        sessionStartDateTime: formattedSessionDate,
        sessionEndDateTime: formattedSessionEndTime,
      });
    });

    const { editScheduledOfferingRequest } = props;
    if (editScheduledOfferingRequest) {
      showLoader();
      const payload = {
        values: {
          id: formData?.id,
          courseId: Number(formData?.courseId),
          locationId: Number(formData?.locationId),
          termId: Number(formData?.termId),
          maxEnrollments: formData?.maxEnrollments
            ? Number(formData?.maxEnrollments)
            : null,
          certificatesIssued:
            formData?.certificatesIssued === SCOCertificate.No ||
            formData?.certificatesIssued === SCOCertificate.Yes
              ? Number(formData?.certificatesIssued)
              : null,
          emrEntered:
            formData?.emrEntered === SCOCertificate.No ||
            formData?.emrEntered === SCOCertificate.Yes
              ? Number(formData?.emrEntered)
              : null,
          startDateOfFirstSession: `${startDate} ${convertToDateTimeUsingOldFormat(
            startDate,
            DATE_FORMAT,
            TIME_FORMAT
          )}`,
          startTimeOfFirstSession: startTime,
          endTimeOfFirstSession: endTime,
          endDateOfFirstSession: `${endDate} ${convertToDateTimeUsingOldFormat(
            endDate,
            DATE_FORMAT,
            TIME_FORMAT
          )}`,
          isAllDay: isSingleSession,
          isRepeat: !isSingleSession,
          status: formData?.status,
          courseOfferingRepeatSettings: addFormData?.isRepeat ? repeat : null,
          courseOfferingSessions: offeringDays,
          isRepeatChange,
        },
        callback: onEditScheduledOfferingSuccess,
      };

      editScheduledOfferingRequest(payload);
    }
  };

  const onAddEditSessionDaySuccess = (
    response: AddEditSessionDaySuccessPayload
  ) => {
    if (response.success) {
      if (isEditSessionDay) {
        toast.success(SESSION_DAY_EDIT_SUCCESS_MSG);
      } else {
        toast.success(SESSION_DAY_ADD_SUCCESS_MSG);
      }
      handleAddEditSessionDayModalClose();
      getCourseOfferingSessionsList();
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleAddSessionDay = (formData: ICourseOfferingDaysResponse) => {
    const { addSessionDayRequest } = props;
    const { scoId } = location.state;
    if (addSessionDayRequest) {
      showLoader();
      const startTime = convertToDateTimeWithFormat(
        watch("startTimeOfFirstSession"),
        TIME_FORMAT
      );
      const endTime = convertToDateTimeWithFormat(
        watch("endTimeOfFirstSession"),
        TIME_FORMAT
      );

      const payload = {
        values: {
          dayNumber: Number(formData.dayNumber),
          sessionStartDateTime: `${convertToDateTimeWithFormat(
            formData?.sessionStartDateTime,
            DATE_FORMAT
          )} ${startTime}`,
          sessionEndDateTime: `${convertToDateTimeWithFormat(
            formData?.sessionStartDateTime,
            DATE_FORMAT
          )} ${endTime}`,
          courseOfferingID: Number(scoId),
        },
        callback: onAddEditSessionDaySuccess,
      };

      addSessionDayRequest(payload);
    }
  };

  const handleEditSessionDay = (formData: ICourseOfferingDaysResponse) => {
    const { editSessionDayRequest } = props;
    const { scoId } = location.state;

    const endDate = convertToDateTimeWithFormat(
      formData?.sessionStartDateTime,
      DATE_FORMAT
    );

    const endTime = convertToDateTimeWithFormat(
      formData?.sessionEndDateTime,
      TIME_FORMAT
    );

    if (editSessionDayRequest) {
      showLoader();
      const payload = {
        values: {
          id: formData.id,
          dayNumber: Number(formData.dayNumber),
          sessionStartDateTime: convertToDateTimeWithFormat(
            formData?.sessionStartDateTime,
            `${DATE_FORMAT} ${TIME_FORMAT}`
          ),
          sessionEndDateTime: `${endDate} ${endTime}`,
          courseOfferingID: Number(scoId),
        },
        callback: onAddEditSessionDaySuccess,
      };

      editSessionDayRequest(payload);
    }
  };

  const onDeleteSessionDaySuccess = (
    response: DeleteSessionDaySuccessPayload
  ) => {
    if (response.success) {
      toast.success(SESSION_DAY_DELETE_SUCCESS_MSG);
      getCourseOfferingSessionsList();
      getCourseOfferingDetails();
    }
  };

  const handleDeleteSessionDay = (deleteDayId: number) => {
    const { deleteSessionDayRequest } = props;

    if (deleteSessionDayRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(deleteDayId),
        },
        callback: onDeleteSessionDaySuccess,
      };

      deleteSessionDayRequest(payload);
    }
  };

  const onDownloadDocumentSuccess = (
    response: DownloadDocumentSuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.document);
    const docType = getUploadedScoDocumentType(response.documentName);
    const file = new Blob([item], {
      type: docType,
    });

    saveAs(file, response.documentName);
  };

  const onDownloadDocument = (
    name: string,
    type: number,
    courseOfferingID: number
  ) => {
    const { downloadDocumentRequest } = props;

    if (downloadDocumentRequest) {
      showLoader();

      const payload = {
        values: { name, type, courseOfferingID },
        callback: onDownloadDocumentSuccess,
      };

      downloadDocumentRequest(payload);
    }
  };

  const onUploadDocumentSuccess = (response: UploadDocumentSuccessPayload) => {
    if (response.success) {
      toast.success(DOC_UPLOAD_SUCCESS_MSG);
      getCourseOfferingDetails();
    }
  };

  const onUploadDocument = (
    name: string,
    type: number,
    courseOfferingID: number,
    document: any
  ) => {
    const { uploadDocumentRequest } = props;

    if (uploadDocumentRequest) {
      showLoader();

      const payload = {
        values: { name, type, courseOfferingID, document },
        callback: onUploadDocumentSuccess,
      };

      uploadDocumentRequest(payload);
    }
  };

  const onDeleteDocumentSuccess = (response: DeleteDocumentSuccessPayload) => {
    if (response.success) {
      toast.success(DOC_DELETE_SUCCESS_MSG);
      handleDeleteDocCloseModal();
      getCourseOfferingDetails();
    }
  };

  const onDeleteDocument = () => {
    const { deleteDocumentRequest } = props;

    if (deleteDocumentRequest && deleteDocData) {
      showLoader();

      const payload = {
        values: {
          id: deleteDocData?.docId,
          type: deleteDocData?.type,
          courseOfferingID: deleteDocData?.courseOfferingID,
        },
        callback: onDeleteDocumentSuccess,
      };

      deleteDocumentRequest(payload);
    }
  };

  const onCancelSessionDaySuccess = (
    response: CancelSessionDaySuccessPayload
  ) => {
    if (response.success) {
      toast.success(SESSION_DAY_CANCEL_SUCCESS_MSG);
      getCourseOfferingSessionsList();
    }
  };

  const handleCancelSessionDay = (data: ICourseCancel) => {
    const { cancelSessionDayRequest } = props;

    if (cancelSessionDayRequest) {
      showLoader();
      const payload = {
        values: {
          id: data.id,
          cancellationComment: data.cancellationComment,
        },
        callback: onCancelSessionDaySuccess,
      };

      cancelSessionDayRequest(payload);
    }
  };

  const onAddEducatorSuccess = (response: AddScoEducatorSuccessPayload) => {
    if (response.success) {
      toast.success(SCO_EDUCATOR_ADD_SUCCESS_MSG);
      handleAddEducatorsModalClose();
      getCourseOfferingDetails();
    }
  };

  const handleAddEducatorsClick = (formData: ICourseOfferingEducators) => {
    const { addScoEducatorRequest } = props;

    if (addScoEducatorRequest) {
      showLoader();

      const payload = {
        values: {
          courseOfferingId: location.state.scoId,
          educatorIds: formData.educatorIds,
        },
        callback: onAddEducatorSuccess,
      };

      addScoEducatorRequest(payload);
    }
  };

  const handleDeleteEducatorModal = (deleteId: number) => {
    setDeleteEducatorId(deleteId);
    setIsOpenDeleteEducatorModal(true);
  };

  const handleDeleteEducatorCloseModal = () => {
    setDeleteEducatorId(0);
    setIsOpenDeleteEducatorModal(false);
  };

  const onDeleteEducatorSuccess = (
    response: DeleteScoEducatorSuccessPayload
  ) => {
    if (response.success) {
      toast.success(SCO_EDUCATOR_DELETE_SUCCESS_MSG);
      handleDeleteEducatorCloseModal();
      getCourseOfferingDetails();
    }
  };

  const onDeleteEducator = () => {
    const { deleteScoEducatorRequest } = props;

    if (deleteScoEducatorRequest) {
      showLoader();

      const payload = {
        values: {
          id: deleteEducatorId,
        },
        callback: onDeleteEducatorSuccess,
      };

      deleteScoEducatorRequest(payload);
    }
  };

  const handleGenerateCertificateClose = () =>
    setIsOpenGenerateCertificate(false);

  const onFillStudentAttendanceSuccess = (
    response: FillGenerateCertificateSuccessPayload
  ) => {
    if (response.success) {
      getCourseOfferingDetails();
      toast.success(GENERATE_CERTIFICATE_FILLED_SUCCESS_MESSAGE);
      handleGenerateCertificateClose();
      dispatch(
        saveGenerateCertificateRequest({
          isSaveCertificate: !isSaveCertificateChange,
        })
      );
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleGenerateCertificateSave = () => {
    if (fillGenerateCertificateRequest) {
      showLoader();
      const payload = {
        values: {
          courseOfferingId: location.state.scoId,
          certificateStatusList: generateCertificateStatus,
        },
        callback: onFillStudentAttendanceSuccess,
      };
      fillGenerateCertificateRequest(payload);
    }
  };

  const handleCheckboxChange = (index: number) => {
    setGenerateCertificateStatus((prevStatus) => {
      const updatedStatus = [...prevStatus];
      updatedStatus[index].isCertificateGenerate =
        !updatedStatus[index].isCertificateGenerate;

      return updatedStatus;
    });
  };

  const handleSelectAll = (selectAll: boolean) => {
    setGenerateCertificateStatus((prevStatus) => {
      return prevStatus.map((item) => ({
        ...item,
        isCertificateGenerate: selectAll,
      }));
    });
  };

  const onSCOGenerateCertificateListSuccess = (
    response: GetSCOGenerateCertificateListSuccessPayload
  ) => {
    setGenerateCertificateRows(response.studentGenerateCertificateList);
    const updatedStatus = response.studentGenerateCertificateList.map(
      (row) => ({
        studentCourseEnrolmentId: row?.studentCourseEnrolmentId,
        isCertificateGenerate: row?.isCertificateGenerate,
      })
    );
    setGenerateCertificateStatus(updatedStatus);
    setIsOpenGenerateCertificate(true);
    hideLoaderForBatchApiCall();
  };

  const getSCOStudentAttendanceList = () => {
    if (SCOGenerateCertificateListRequest) {
      showLoaderForBatchApiCall();
      const values = {
        courseOfferingId: location.state.scoId,
      };
      const payload = {
        values,
        callback: onSCOGenerateCertificateListSuccess,
      };
      SCOGenerateCertificateListRequest(payload);
    }
  };

  const { changeEnrolmentStatusRequest } = props;
  const onGetEducatorReflectionFormSuccess = (
    response: GetEducatorReflectionFormSuccessPayload
  ) => {
    if (response.formDetails) {
      setReflectionsFormData(response.formDetails);
    }
  };

  const handleGetEducatorReflectionForm = (educatorId: number) => {
    const { getEducatorReflectionFormRequest } = props;
    if (getEducatorReflectionFormRequest) {
      showLoader();
      const payload = {
        values: {
          educatorId,
          courseOfferingId: location.state.scoId,
        },
        callback: onGetEducatorReflectionFormSuccess,
      };

      getEducatorReflectionFormRequest(payload);
    }
    setIsEducatorDetailsOpen(true);
  };

  const csvFileHeaders = [
    "Course Name",
    "Term",
    "Year",
    "Form Filling Date",
    "What went well?",
    "How Can We Continue To Do This?",
    "What Didn’t Go So Well?",
    "What Ideas Do You Have To Improve This?",
  ];

  const getReflectionFormModifiedData = (data?: ICourseReflectionsForm) => {
    return {
      "Course Name": scheduledOfferingDetails?.courseDTO.name,
      Term: scheduledOfferingDetails?.termsDTO.termName,
      Year: dayjs(scheduledOfferingDetails?.startDateOfFirstSession).format(
        "YYYY"
      ),
      "Form Filling Date": convertToDateTimeWithFormat(
        data?.date,
        DATE_VIEW_FORMAT
      ),
      "What went well?": data?.whatWentWell,
      "How Can We Continue To Do This?": data?.howCanWeContinueToDoThis,
      "What Didn’t Go So Well?": data?.whatDidNotGoSoWell,
      "What Ideas Do You Have To Improve This?":
        data?.whatIdeasDoYouHaveToImproveThis,
    };
  };

  const handlePrintEducatorReflectionForm = () => {
    const modifiedData = [getReflectionFormModifiedData(reflectionsFormData)];
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: csvFileHeaders,
      filename: "Educator Course Reflection Form",
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(modifiedData);
  };

  const onChangeCheckBox = (fieldName: string) => {
    if (fieldName === "isAllDay") {
      setIsSingleSession(true);
      setRepeatDataError("");
    } else {
      setIsSingleSession(false);
    }
  };

  const handleRepeatModal = () => {
    if (watch("startDateOfFirstSession")) {
      setIsOpenRepeatModal(true);
    } else {
      trigger("startDateOfFirstSession");
    }
  };

  const handleSaveRepeatClick = (data: IRepeat) => {
    setRepeatCourseOffering(data);
    handleRepeatModalClose();
    setRepeatDataError("");
    setIsTermChange(false);
  };

  return (
    <>
      <Box className="content-header sco-header" ref={detailsRef}>
        <Typography variant="h2" className="heading">
          Scheduled Course Offering Details
        </Typography>
        <Box className="sco-details-header-btn-group">
          <Button
            variant="outlined"
            className="btn-generate-certificate"
            onClick={() => {
              if (isEnableGenerateCertificate) {
                getSCOStudentAttendanceList();
              }
            }}
            disabled={!isEnableGenerateCertificate}
          >
            Generate Certificate
          </Button>
          <Button
            variant="outlined"
            className="btn-back"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Box>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Grid container spacing={{ xs: "16px", lg: "20px", xl: "24px" }}>
            <Grid item xs={12} lg={8}>
              <form onSubmit={handleSubmit(handleAddScheduledOffering)}>
                <Card className="sco-detail-card">
                  <Grid
                    container
                    columnSpacing={{ xs: "16px", lg: "20px", xl: "24px" }}
                  >
                    <Grid item xs={12} lg={3}>
                      <TextField
                        id="course-no"
                        label="Course No."
                        fullWidth
                        variant="outlined"
                        defaultValue="320605_ENG"
                        disabled
                        {...register("courseNumber")}
                      />
                    </Grid>
                    <Grid item xs={12} lg={9}>
                      <div className="select-input-w-btn">
                        <FormControl fullWidth className="select">
                          <Autocomplete
                            id="course1"
                            options={courseList || []}
                            getOptionLabel={(option) => option.name || ""}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={
                                  <>
                                    Course <span className="color-red">*</span>
                                  </>
                                }
                                error={!!errors.courseId}
                                InputLabelProps={{
                                  id: "course1-label",
                                  shrink: !!watch("courseId"),
                                }}
                                helperText={
                                  !!errors.courseId && getCourseIdError()
                                }
                              />
                            )}
                            value={
                              courseList?.find(
                                (course) => course.id === watch("courseId")
                              ) || null
                            }
                            onChange={(_, newValue) => {
                              setValue(
                                "courseId",
                                newValue ? newValue.id : watch("courseId")
                              );
                            }}
                          />
                          {!!errors.courseId && (
                            <FormHelperText error>
                              {getCourseIdError()}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title="View Course"
                          arrow
                        >
                          <Button
                            className="btn-icon-details form-btn"
                            variant="outlined"
                            disabled={!coursePagePermission?.edit}
                            onClick={() =>
                              history.push({
                                pathname: AppRoutings.EditCourse,
                                state: {
                                  courseId: Number(watch("courseId")),
                                },
                              })
                            }
                          >
                            <img
                              src={courseListIcon}
                              alt="icon"
                              className="default"
                            />
                            <img
                              src={courseListLightIcon}
                              alt="icon"
                              className="active"
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="status-label">
                          Status <span className="color-red">*</span>
                        </InputLabel>
                        <Select
                          labelId="status-label"
                          id="status"
                          label="Status"
                          value={watch("status")}
                          error={!!errors.status}
                          {...register("status", {
                            required: true,
                          })}
                        >
                          {SCO_STATUS?.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors.status && (
                          <FormHelperText error>
                            {getStatusError()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="term-select-label">
                          Term <span className="color-red">*</span>
                        </InputLabel>
                        <Select
                          labelId="term-select-label"
                          id="term-select"
                          label="Term"
                          value={watch("termId")}
                          error={!!errors.termId}
                          {...register("termId", {
                            required: true,
                          })}
                          MenuProps={{
                            className: "filter-select-menu",
                          }}
                        >
                          {termList?.map((term) => (
                            <MenuItem key={term.id} value={term.id}>
                              {term.termName}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors.termId && (
                          <FormHelperText error>
                            {getTermIdError()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <TextField
                        id="offer-days"
                        label="Offering Days"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...register("noOfSessions")}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                      <FormControl fullWidth className="select">
                        <InputLabel
                          id="term-status-label"
                          shrink={watch("termStatus") !== 0}
                        >
                          Term Status
                        </InputLabel>
                        <Select
                          labelId="term-status-label"
                          id="term-status"
                          label="Term status"
                          disabled
                          value={watch("termStatus")}
                          {...register("termStatus")}
                        >
                          {TERM_STATUS?.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <FormControl fullWidth className="select">
                        <InputLabel id="location1-label">
                          Location <span className="color-red">*</span>
                        </InputLabel>
                        <Select
                          labelId="location1-label"
                          id="location1"
                          label="Location"
                          value={watch("locationId")}
                          error={!!errors.locationId}
                          {...register("locationId", {
                            required: true,
                          })}
                          MenuProps={{
                            className: "filter-select-menu sco-location-menu",
                          }}
                        >
                          {locationList?.map((loc) => (
                            <MenuItem key={loc.id} value={loc.id}>
                              {loc.location}
                              {loc.isBlended &&
                                " - Blended (Face To Face / Online)"}
                            </MenuItem>
                          ))}
                        </Select>
                        {!!errors.locationId && (
                          <FormHelperText error>
                            {getLocationIdError()}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DesktopDatePicker"]}>
                          <Controller
                            control={control}
                            {...register("startDateOfFirstSession", {
                              required: true,
                              validate: (value) =>
                                checkEndDateLessThanOrEqualToStart(
                                  pickerMaxDate,
                                  value
                                ) &&
                                !checkEndDateLessThanToStart(
                                  pickerMinDate,
                                  value
                                ),
                            })}
                            render={({ field }) => (
                              <DesktopDatePicker
                                {...field}
                                className="date-picker disabled-picker-text-field sco-details-date-picker"
                                label={
                                  <>
                                    Date Of First Session{" "}
                                    <span className="color-red">*</span>
                                  </>
                                }
                                minDate={pickerMinDate}
                                maxDate={pickerMaxDate}
                                slotProps={{
                                  textField: {
                                    disabled: true,
                                    fullWidth: true,
                                    error: !!errors.startDateOfFirstSession,
                                    helperText: getStartDateError(),
                                  },
                                }}
                                slots={{
                                  openPickerIcon: CalendarIcon,
                                }}
                                format={DATE_VIEW_FORMAT}
                              />
                            )}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DesktopTimePicker"]}>
                          <Controller
                            control={control}
                            {...register("startTimeOfFirstSession", {
                              required: true,
                            })}
                            render={({ field }) => (
                              <DesktopTimePicker
                                {...field}
                                className="time-picker full-width-picker"
                                label={
                                  <>
                                    Start Time{" "}
                                    <span className="color-red">*</span>
                                  </>
                                }
                                slotProps={{
                                  textField: {
                                    error: !!errors.startTimeOfFirstSession,
                                    helperText: getStartTimeError(),
                                  },
                                }}
                              />
                            )}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DesktopTimePicker"]}>
                          <Controller
                            control={control}
                            {...register("endTimeOfFirstSession", {
                              required: true,
                              validate: (value) =>
                                !checkEndTimeLessThanStart(
                                  watch("startTimeOfFirstSession"),
                                  value
                                ) ||
                                !checkEndDateLessThanOrEqualToStart(
                                  watch("startDateOfFirstSession"),
                                  watch("endDateOfFirstSession")
                                ),
                            })}
                            render={({ field }) => (
                              <DesktopTimePicker
                                {...field}
                                className="time-picker full-width-picker"
                                label={
                                  <>
                                    End Time{" "}
                                    <span className="color-red">*</span>
                                  </>
                                }
                                slotProps={{
                                  textField: {
                                    error: !!errors.endTimeOfFirstSession,
                                    helperText: getEndTimeError(),
                                  },
                                }}
                              />
                            )}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                      <FormControl fullWidth className="select">
                        <InputLabel
                          id="certi-issued1-label"
                          shrink={
                            watch("certificatesIssued") === SCOCertificate.No ||
                            watch("certificatesIssued") === SCOCertificate.Yes
                          }
                        >
                          Certificates Issued
                        </InputLabel>
                        <Select
                          labelId="certi-issued1-label"
                          id="certi-issued1"
                          label="Certificates Issued"
                          value={watch("certificatesIssued")}
                          {...register("certificatesIssued")}
                        >
                          {SCO_CERTIFICATE?.map((sco) => (
                            <MenuItem key={sco.value} value={sco.value}>
                              {sco.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                      <FormControl fullWidth className="select">
                        <InputLabel
                          id="emr-entered-label"
                          shrink={
                            watch("emrEntered") === SCOCertificate.No ||
                            watch("emrEntered") === SCOCertificate.Yes
                          }
                        >
                          EMR Entered
                        </InputLabel>
                        <Select
                          labelId="emr-entered-label"
                          id="emr-entered"
                          label="EMR Entered"
                          value={watch("emrEntered")}
                          {...register("emrEntered")}
                        >
                          {SCO_CERTIFICATE?.map((sco) => (
                            <MenuItem key={sco.value} value={sco.value}>
                              {sco.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                      <TextField
                        type="number"
                        id="max-enroll"
                        label="Max. Enrolments"
                        fullWidth
                        variant="outlined"
                        onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                        onPaste={handlePasteNumberField}
                        helperText={getMaxEnrolmentsError()}
                        error={!!errors.maxEnrollments}
                        {...register("maxEnrollments", {
                          min: scheduledOfferingDetails?.maxEnrollments,
                        })}
                        InputLabelProps={{
                          shrink: Boolean(watch("maxEnrollments")),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                      <Box className="checkbox-group">
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckboxIcon />}
                              checkedIcon={<CheckedBoxIcon />}
                              checked={isSingleSession}
                              onChange={() => onChangeCheckBox("isAllDay")}
                            />
                          }
                          label="Single"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              icon={<CheckboxIcon />}
                              checkedIcon={<CheckedBoxIcon />}
                              checked={!isSingleSession}
                              onChange={() => onChangeCheckBox("isRepeat")}
                            />
                          }
                          label="Repeat"
                          labelPlacement="end"
                        />
                        <IconButton
                          disabled={isSingleSession}
                          className={isSingleSession ? "disabled-icon" : ""}
                          onClick={handleRepeatModal}
                        >
                          <img src={settingIcon} alt="setting" />
                        </IconButton>
                      </Box>
                      {repeatDataError && (
                        <FormHelperText error className="repeat-on-error-text">
                          {repeatDataError}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Box className="btn-group">
                    <Button
                      variant="contained"
                      className="btn-save"
                      type="submit"
                    >
                      <img src={saveIcon} alt="save" />
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      className="btn-cancel"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Card>
              </form>
            </Grid>

            <Grid item xs={12} lg={4}>
              <DocumentsList
                documentsList={documentsList}
                onDownloadDocument={onDownloadDocument}
                onUploadDocument={onUploadDocument}
                courseOfferingId={scheduledOfferingDetails?.id}
                handleDeleteDocModal={handleDeleteDocModal}
              />
            </Grid>

            <EducatorsList
              educatorsList={educatorsList}
              educatorsPagePermission={eduPagePermission}
              handleAddEducatorsModal={handleAddEducatorsModal}
              handleDeleteEducatorModal={handleDeleteEducatorModal}
              handleGetEducatorReflectionForm={handleGetEducatorReflectionForm}
            />
            <SessionDaysAndAttendance
              sessionsList={sessionsList}
              handleAddEditSessionDayModal={handleAddEditSessionDayModal}
              handleDeleteSessionDay={handleDeleteSessionDay}
              handleCancelSessionDay={handleCancelSessionDay}
              autoScrollToSessions={location.state?.autoScrollToSessions}
              SCOStudentAttendanceListRequest={SCOStudentAttendanceListRequest}
              SCOStudentWhoAttendedListRequest={
                SCOStudentWhoAttendedListRequest
              }
              fillStudentAttendanceRequest={fillStudentAttendanceRequest}
              getCourseOfferingSessionsList={getCourseOfferingSessionsList}
            />
            <Enrollments
              courseName={scoDetails?.courseName as string}
              courseOfferingId={location.state.scoId}
              enroledStudentListRequest={enroledStudentListRequest}
              enrolmentOptionListRequest={enrolmentOptionListRequest}
              enrolmentCountRequest={enrolmentCountRequest}
              changeEnrolmentStatusRequest={changeEnrolmentStatusRequest}
              printCourseEnrolmentsRequest={printCourseEnrolmentsRequest}
              downloadCertificateRequest={downloadCertificateRequest}
            />
          </Grid>
        </Grid>
      </Grid>

      <AddEditSessionDay
        addEditSessionDayModal={addEditSessionDayModal}
        handleAddEditSessionDayModalClose={handleAddEditSessionDayModalClose}
        handleAddEditSessionDayClick={
          isEditSessionDay ? handleEditSessionDay : handleAddSessionDay
        }
        isEditSessionDay={isEditSessionDay}
        editSessionDayData={editSessionDayData}
        startDate={watch("startDateOfFirstSession")}
        pickerMinDate={pickerMinDate}
        pickerMaxDate={pickerMaxDate}
      />

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenDeleteDocModal}
        handleDeleteConfirmationModalClose={handleDeleteDocCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete document?"
        handleYesClick={onDeleteDocument}
      />

      <AddEducators
        addEducatorsModal={addEducatorsModal}
        handleAddEducatorsModalClose={handleAddEducatorsModalClose}
        handleAddEducatorsClick={handleAddEducatorsClick}
        addedEducatorsList={educatorsList}
      />

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenDeleteEducatorModal}
        handleDeleteConfirmationModalClose={handleDeleteEducatorCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete educator?"
        handleYesClick={onDeleteEducator}
      />

      {isOpenGenerateCertificate && (
        <GenerateCertificateModal
          handleGenerateCertificateClose={handleGenerateCertificateClose}
          handleGenerateCertificateSave={handleGenerateCertificateSave}
          handleCheckboxChange={handleCheckboxChange}
          generateCertificateData={generateCertificateRows}
          generateCertificateStatus={generateCertificateStatus}
          handleSelectAll={handleSelectAll}
        />
      )}
      {scheduledOfferingDetails && (
        <EducatorCourseReflectionForm
          isEducatorDetailsOpen={isEducatorDetailsOpen}
          handleEducatorDetailsClose={handleEducatorDetailsClose}
          scheduledOfferingDetails={scheduledOfferingDetails}
          reflectionsFormData={reflectionsFormData}
          handlePrintEducatorReflectionForm={handlePrintEducatorReflectionForm}
        />
      )}
      <RepeatModal
        isOpenRepeatModal={isOpenRepeatModal}
        handleRepeatModalClose={handleRepeatModalClose}
        handleSaveRepeatClick={handleSaveRepeatClick}
        startDate={
          dayjs(watch("startDateOfFirstSession")).isBefore(dayjs())
            ? dayjs().add(1, "day")
            : watch("startDateOfFirstSession")
        }
        repeatCourseOffering={repeatCourseOffering}
        pickerMinDate={pickerMinDate}
        pickerMaxDate={pickerMaxDate}
      />

      <AddSCODays
        addSCODaysModal={addSCODaysModal}
        handleAddSCODaysModalClose={handleAddSCODaysModalClose}
        addFormData={addFormData}
        handleFinalAddSCOClick={handleSaveClick}
        scoDetails={scoDetails}
        scoDaysList={scoDaysList}
        pickerMinDate={pickerMinDate}
        pickerMaxDate={pickerMaxDate}
      />
    </>
  );
};

export default SCODetails;
