import { Box } from "@mui/system";
import {
  Button,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { closeIcon } from "assets/images";
import { Controller, useForm } from "react-hook-form";
import { IAddEditTerm } from "utils/interfaces/term";
import {
  CLOSE_TOOLTIP_TITLE,
  DATE_PICKER_FORMAT,
  DEFAULT_PAGE_NO,
  TERM_NUMBER,
  TERM_STATUS,
} from "utils/constants/constant";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect } from "react";
import { onNumberFieldKeyPress } from "utils/helper";
import CalendarIcon from "components/CalendarIcon";

interface ITermFilterProps {
  filterBox: boolean;
  setFilterBox: any;
  handleApplyClick: any;
  filteredData?: IAddEditTerm;
  pageNo: number;
  setPageNo: any;
  setFilteredData: any;
  getTermList: any;
  setAppliedFilter: any;
}

interface ITermFilter {
  term: number;
  year: number;
  startDate?: any;
  endDate?: any;
  status: number;
}

const defaultValues: ITermFilter = {
  term: 0,
  year: 0,
  status: 0,
  startDate: null,
  endDate: null,
};

const TermFilter: React.FC<ITermFilterProps> = ({
  filterBox,
  setFilterBox,
  handleApplyClick,
  filteredData,
  pageNo,
  setPageNo,
  setFilteredData,
  getTermList,
  setAppliedFilter,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<IAddEditTerm>({ defaultValues });

  const getYearError = (): string => {
    if (errors.year) {
      if (errors.year.type === "minLength") {
        return "Enter a valid 4-digit year";
      }
      if (errors.year.type === "maxLength") {
        return "Enter a valid 4-digit year";
      }
    }

    return "";
  };
  useEffect(() => {
    if (filteredData) {
      setValue("term", filteredData?.term);
      setValue("year", filteredData?.year);
      setValue("status", filteredData?.status);
      if (filteredData?.startDate !== "Invalid date") {
        setValue("startDate", dayjs(filteredData?.startDate));
      }
      if (filteredData?.endDate !== "Invalid date") {
        setValue("endDate", dayjs(filteredData?.endDate));
      }
    } else {
      const defaultValue: ITermFilter = {
        term: 0,
        year: 0,
        status: 0,
      };

      reset(defaultValue);
    }
  }, [filteredData, setValue, filterBox, reset]);

  const onClearClick = () => {
    const defaultValue: ITermFilter = {
      term: 0,
      year: 0,
      status: 0,
    };
    reset(defaultValue);
    setFilterBox(false);
    setAppliedFilter(false);
    document.body.classList.toggle("filter-open");
    if (pageNo === DEFAULT_PAGE_NO) {
      getTermList();
    } else {
      setPageNo(1);
    }
    setFilteredData();
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleApplyClick)}>
        <Box className="filter-popover">
          <Box className="filter-header">
            <Typography variant="h4">Filter</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  setFilterBox(false);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="filter-body">
            <FormControl fullWidth className="select">
              <InputLabel id="term-select-label" shrink={watch("term") !== 0}>
                Term
              </InputLabel>
              <Select
                labelId="term-select-label"
                id="term-select"
                label="Term"
                value={watch("term")}
                {...register("term")}
              >
                {TERM_NUMBER?.map((term) => (
                  <MenuItem key={term.value} value={term.value}>
                    {term.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="year-select"
              label={<>Year</>}
              fullWidth
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: !!watch("year"),
              }}
              error={!!errors.year}
              helperText={getYearError()}
              value={watch("year") === 0 ? "" : watch("year")}
              {...register("year", {
                maxLength: 4,
                minLength: 4,
              })}
              onKeyPress={onNumberFieldKeyPress}
            />
            <FormControl fullWidth className="select">
              <InputLabel id="term-status-label" shrink={watch("status") !== 0}>
                Term Status
              </InputLabel>
              <Select
                labelId="term-status-label"
                id="term-status"
                label="Year"
                value={watch("status")}
                {...register("status")}
              >
                {TERM_STATUS?.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid container columnSpacing="24px">
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <Controller
                      control={control}
                      {...register("startDate")}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          className="date-picker disabled-picker-text-field"
                          format={DATE_PICKER_FORMAT}
                          label="Terms Start Date"
                          slots={{
                            openPickerIcon: CalendarIcon,
                          }}
                          slotProps={{
                            textField: {
                              disabled: true,
                            },
                          }}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDatePicker"]}>
                    <Controller
                      control={control}
                      {...register("endDate")}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          className="date-picker disabled-picker-text-field"
                          format={DATE_PICKER_FORMAT}
                          label="Terms End Date"
                          slots={{
                            openPickerIcon: CalendarIcon,
                          }}
                          slotProps={{
                            textField: {
                              disabled: true,
                            },
                          }}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>
          <Box className="filter-footer">
            <Button variant="contained" className="btn-apply" type="submit">
              Apply
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={onClearClick}
              disabled={
                !(
                  watch("term") ||
                  watch("year") ||
                  watch("status") ||
                  watch("startDate") ||
                  watch("endDate")
                )
              }
            >
              Clear
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default TermFilter;
