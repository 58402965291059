import { Box } from "@mui/system";
import {
  Modal,
  Button,
  Grid,
  IconButton,
  Typography,
  TextField,
  Fade,
  Tooltip,
} from "@mui/material";
import { closeIcon, saveIcon } from "assets/images";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { ICourseOfferingDaysResponse } from "utils/interfaces/scheduled-offering";
import { useEffect } from "react";
import dayjs from "dayjs";
import {
  checkEndDateLessThanToStart,
  handlePasteNumberField,
  onNumberFieldKeyPress,
} from "utils/helper";
import {
  CLOSE_TOOLTIP_TITLE,
  DATE_PICKER_FORMAT,
} from "utils/constants/constant";
import CalendarIcon from "components/CalendarIcon";

interface IAddSCODaysProps {
  addEditSessionDayModal: boolean;
  handleAddEditSessionDayModalClose: any;
  handleAddEditSessionDayClick: any;
  isEditSessionDay: boolean;
  editSessionDayData?: ICourseOfferingDaysResponse;
  startDate: any;
  pickerMinDate: any;
  pickerMaxDate: any;
}

const AddEditSessionDay: React.FC<IAddSCODaysProps> = ({
  addEditSessionDayModal,
  handleAddEditSessionDayModalClose,
  handleAddEditSessionDayClick,
  isEditSessionDay,
  editSessionDayData,
  startDate,
  pickerMinDate,
  pickerMaxDate,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<ICourseOfferingDaysResponse>({
    defaultValues: {
      sessionStartDateTime: null,
    },
  });

  useEffect(() => {
    reset();
    if (isEditSessionDay && editSessionDayData) {
      setValue("id", editSessionDayData?.id);
      setValue("dayNumber", editSessionDayData?.dayNumber);
      setValue(
        "sessionStartDateTime",
        dayjs(editSessionDayData?.sessionStartDateTime)
      );
      setValue(
        "sessionEndDateTime",
        dayjs(editSessionDayData?.sessionEndDateTime)
      );
    } else {
      reset();
    }
  }, [
    editSessionDayData,
    isEditSessionDay,
    setValue,
    addEditSessionDayModal,
    reset,
  ]);

  const getDayNumberError = (): string => {
    if (errors.dayNumber) {
      if (errors.dayNumber.type === "required") {
        return "Day number is required";
      }
    }

    return "";
  };

  const getDateOfOfferingDayError = (): string => {
    if (errors.sessionStartDateTime) {
      if (errors.sessionStartDateTime.type === "required") {
        return "Date of offering day is required";
      }
      if (errors.sessionStartDateTime.type === "validate") {
        return "Please select date greater than start date.";
      }
    }

    return "";
  };

  return (
    <>
      <Modal
        open={addEditSessionDayModal}
        onClose={handleAddEditSessionDayModalClose}
      >
        <Box className="common-modal addsco-modal addsco-offering-modal">
          <Box className="modal-header">
            <Typography variant="h4">
              {isEditSessionDay
                ? "Edit Scheduled Course Offering Day"
                : "Add Scheduled Course Offering Day"}
            </Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleAddEditSessionDayModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <form onSubmit={handleSubmit(handleAddEditSessionDayClick)}>
            <Box className="modal-body">
              <Grid
                container
                rowSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    columnSpacing={{ xs: "16px", md: "20px", lg: "24px" }}
                    rowSpacing="16px"
                  >
                    <Grid item xs={12} sm={5} md={3}>
                      <TextField
                        type="number"
                        id="day-hum"
                        label={
                          <>
                            Day Number <span className="color-red">*</span>
                          </>
                        }
                        fullWidth
                        InputLabelProps={{ shrink: !!watch("dayNumber") }}
                        variant="outlined"
                        onKeyPress={(e) => onNumberFieldKeyPress(e, true)}
                        onPaste={handlePasteNumberField}
                        error={!!errors.dayNumber}
                        helperText={getDayNumberError()}
                        {...register("dayNumber", {
                          required: true,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} md={4}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DesktopDatePicker"]}>
                          <Controller
                            control={control}
                            {...register("sessionStartDateTime", {
                              required: true,
                              validate: (value) =>
                                !checkEndDateLessThanToStart(startDate, value),
                            })}
                            render={({ field }) => (
                              <DesktopDatePicker
                                {...field}
                                className="date-picker disabled-picker-text-field"
                                format={DATE_PICKER_FORMAT}
                                label={
                                  <>
                                    Date Of Offering Day{" "}
                                    <span className="color-red">*</span>
                                  </>
                                }
                                value={watch("sessionStartDateTime")}
                                minDate={pickerMinDate}
                                maxDate={pickerMaxDate}
                                slots={{
                                  openPickerIcon: CalendarIcon,
                                }}
                                slotProps={{
                                  textField: {
                                    disabled: true,
                                    error: !!errors.sessionStartDateTime,
                                    helperText: getDateOfOfferingDayError(),
                                  },
                                }}
                              />
                            )}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box className="modal-footer">
              <Button variant="contained" className="btn-save" type="submit">
                <img src={saveIcon} alt="save" />
                Save
              </Button>
              <Button
                variant="outlined"
                className="btn-cancel"
                onClick={handleAddEditSessionDayModalClose}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AddEditSessionDay;
