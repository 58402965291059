const designerRoutes = [
  "designer-login",
  "designer-dashboard",
  "designer-roles",
  "designer-forgot-password",
  "designer-reset-password",
  "designer-location",
  "designer-terms",
  "designer-educators",
  "designer-courses",
  "designer-educators-home",
  "designer-profile",
  "designer-schedule-course-offering",
  "designer-student-template",
];

export const publicRoutes = [
  ...designerRoutes,
  "login",
  "reset-password",
  "forgot-password",
  "student-enrolment",
];
