import DesignerSidebar from "components/desingerSidebar";
import DesignerHeader from "components/designerHeader";
import { Box } from "@mui/system";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  arrowBackwardIcon,
  arrowForwardIcon,
  checkIcon,
  deleteIcon,
  editIcon,
  plusLightIcon,
  saveIcon,
  uncheckIcon,
} from "assets/images";
import React from "react";
import DesignerFooter from "components/designerFooter";
import projectTheme from "app.theme";

function CheckboxIcon() {
  return <img src={uncheckIcon} alt="checkbox" />;
}
function CheckedboxIcon() {
  return <img src={checkIcon} alt="checkbox" />;
}
function ArrowBackIcon() {
  return <img src={arrowBackwardIcon} alt="arrow-backward" />;
}
function ArrowForwardIcon() {
  return <img src={arrowForwardIcon} alt="arrow-forward" />;
}

interface Data {
  permission: string;
}

function createData(permission: string): Data {
  return {
    permission,
  };
}

const rows = [
  createData("Dashboard"),
  createData("Scheduled Offerings"),
  createData("Locations"),
  createData("Terms"),
  createData("Educators"),
  createData("Courses"),
  createData("Roles"),
];

interface RolesData {
  roles: string;
}

function createRolesData(roles: string): RolesData {
  return {
    roles,
  };
}

const rolesrows = [
  createRolesData("Administrator"),
  createRolesData("Educator"),
  createRolesData("Staff"),
  createRolesData("Administrator"),
  createRolesData("Educator"),
  createRolesData("Staff"),
  createRolesData("Administrator"),
  createRolesData("Educator"),
  createRolesData("Staff"),
  createRolesData("Administrator"),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof RolesData
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (newOrderBy: keyof RolesData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key="roles"
      align="left"
      onClick={createSortHandler("roles")}
      sortDirection={orderBy === "roles" ? order : false}
    >
      Roles
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
}

const DesignerRoles: React.FC = () => {
  const [addRoles, setAddRoles] = React.useState(false);
  const [page, setPage] = React.useState("5");

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
  };

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof RolesData>("roles");

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof RolesData) => {
      const isAsc = orderBy === newOrderBy && order === "asc";
      const toggledOrder = isAsc ? "desc" : "asc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  return (
    <div className="wrapper">
      <Box
        className="overlay"
        onClick={() => document.body.classList.toggle("sidebar-toggle")}
      />
      <DesignerHeader />
      <main>
        <DesignerSidebar />
        {addRoles ? (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Roles
              </Typography>
              <Button
                variant="outlined"
                className="btn-back"
                onClick={() => setAddRoles(false)}
              >
                Back
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box>
                      <TextField
                        id="roles"
                        variant="outlined"
                        className="roles-input"
                        label="Role"
                      />
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              [projectTheme.breakpoints.down("lg")]: {
                                width: "236px",
                              },
                            }}
                          >
                            Permission
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "173px",
                              [projectTheme.breakpoints.down("lg")]: {
                                minWidth: "112px",
                              },
                              [projectTheme.breakpoints.down("sm")]: {
                                minWidth: "88px",
                              },
                            }}
                          >
                            View
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "173px",
                              [projectTheme.breakpoints.down("lg")]: {
                                minWidth: "112px",
                              },
                              [projectTheme.breakpoints.down("sm")]: {
                                minWidth: "88px",
                              },
                            }}
                          >
                            Add
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "182px",
                              [projectTheme.breakpoints.down("lg")]: {
                                minWidth: "112px",
                              },
                              [projectTheme.breakpoints.down("sm")]: {
                                minWidth: "88px",
                              },
                            }}
                          >
                            Edit
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "182px",
                              [projectTheme.breakpoints.down("lg")]: {
                                minWidth: "112px",
                              },
                              [projectTheme.breakpoints.down("sm")]: {
                                minWidth: "88px",
                              },
                            }}
                          >
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          role="checkbox"
                          tabIndex={-1}
                          key="administrator"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableFocusRipple
                                  disableRipple
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckedboxIcon />}
                                />
                              }
                              label="Administrator"
                              labelPlacement="end"
                            />
                          </TableCell>
                          <TableCell align="center" />
                          <TableCell align="center" />
                          <TableCell align="center" />
                          <TableCell align="center" />
                        </TableRow>
                        {rows.map((row) => {
                          return (
                            <TableRow
                              role="checkbox"
                              tabIndex={-1}
                              key={row.permission}
                              className="table-sub-row"
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell scope="row" padding="none">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disableFocusRipple
                                      disableRipple
                                      icon={<CheckboxIcon />}
                                      checkedIcon={<CheckedboxIcon />}
                                    />
                                  }
                                  label={row.permission}
                                  labelPlacement="end"
                                />
                              </TableCell>
                              <TableCell align="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disableFocusRipple
                                      disableRipple
                                      icon={<CheckboxIcon />}
                                      checkedIcon={<CheckedboxIcon />}
                                    />
                                  }
                                  label={undefined}
                                  labelPlacement="end"
                                />
                              </TableCell>
                              <TableCell align="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disableFocusRipple
                                      disableRipple
                                      icon={<CheckboxIcon />}
                                      checkedIcon={<CheckedboxIcon />}
                                    />
                                  }
                                  label={undefined}
                                  labelPlacement="end"
                                />
                              </TableCell>
                              <TableCell align="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disableFocusRipple
                                      disableRipple
                                      icon={<CheckboxIcon />}
                                      checkedIcon={<CheckedboxIcon />}
                                    />
                                  }
                                  label={undefined}
                                  labelPlacement="end"
                                />
                              </TableCell>
                              <TableCell align="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disableFocusRipple
                                      disableRipple
                                      icon={<CheckboxIcon />}
                                      checkedIcon={<CheckedboxIcon />}
                                    />
                                  }
                                  label={undefined}
                                  labelPlacement="end"
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow
                          role="checkbox"
                          tabIndex={-1}
                          key="educator"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableFocusRipple
                                  disableRipple
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckedboxIcon />}
                                />
                              }
                              label="Educator"
                              labelPlacement="end"
                            />
                          </TableCell>
                          <TableCell align="center" />
                          <TableCell align="center" />
                          <TableCell align="center" />
                          <TableCell align="center" />
                        </TableRow>
                        <TableRow
                          role="checkbox"
                          tabIndex={-1}
                          key="Educator Home"
                          className="table-sub-row"
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell scope="row" padding="none">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableFocusRipple
                                  disableRipple
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckedboxIcon />}
                                />
                              }
                              label="Educator Home"
                              labelPlacement="end"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableFocusRipple
                                  disableRipple
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckedboxIcon />}
                                />
                              }
                              label={undefined}
                              labelPlacement="end"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableFocusRipple
                                  disableRipple
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckedboxIcon />}
                                />
                              }
                              label={undefined}
                              labelPlacement="end"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableFocusRipple
                                  disableRipple
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckedboxIcon />}
                                />
                              }
                              label={undefined}
                              labelPlacement="end"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableFocusRipple
                                  disableRipple
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckedboxIcon />}
                                />
                              }
                              label={undefined}
                              labelPlacement="end"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="btn-group" sx={{ paddingTop: "30px" }}>
                    <Button
                      variant="contained"
                      className="btn-save"
                      onClick={() => setAddRoles(false)}
                    >
                      <img src={saveIcon} alt="save" />
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      className="btn-cancel"
                      onClick={() => setAddRoles(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        ) : (
          <div className="main-content">
            <Box className="content-header">
              <Typography variant="h2" className="heading">
                Roles
              </Typography>
              <Button
                variant="contained"
                className="btn-add"
                onClick={() => setAddRoles(true)}
              >
                <img src={plusLightIcon} alt="plus" />
                Add
              </Button>
            </Box>
            <Grid
              container
              spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
              className="content-container"
            >
              <Grid item xs={12}>
                <Card>
                  <Box className="table-card-header">
                    <Box>
                      <TextField
                        id="search"
                        variant="outlined"
                        className="search-input"
                        placeholder="Search"
                      />
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                          />
                          <TableCell
                            align="center"
                            sx={{
                              width: "152px",
                              [projectTheme.breakpoints.down("sm")]: {
                                width: "112px",
                              },
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stableSort(
                          rolesrows,
                          getComparator(order, orderBy)
                        ).map((row) => {
                          return (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {row.roles}
                              </TableCell>
                              <TableCell align="center">
                                <div className="table-actions">
                                  <IconButton onClick={() => setAddRoles(true)}>
                                    <img src={editIcon} alt="edit" />
                                  </IconButton>
                                  <IconButton>
                                    <img src={deleteIcon} alt="delete" />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        <MenuItem value={5} selected>
                          5
                        </MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <Typography variant="body2" component="span">
                        Showing 10 - 20 of 50 entries
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={5}
                      variant="outlined"
                      shape="rounded"
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <DesignerFooter />
          </div>
        )}
      </main>
    </div>
  );
};

export default DesignerRoles;
