// const steps = [0, 1, 2, 3];
export enum FormSteps {
  CollegeForm = 0,
  StudentDetails = 1,
  StudentSupports = 2,
  CourseSelection = 3,
}

export enum StaffEnrolment {
  NonStaff = 0,
  Staff = 1,
}
