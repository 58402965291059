import { AppRoutings } from "utils/enums/app-routings";
import { useHistory } from "react-router-dom";

const ErrorPage: React.FC = () => {
  const history = useHistory();

  const homeClick = () => {
    history.push(AppRoutings.LogIn);
  };

  return (
    <div>
      <h1>404</h1>
      <h2>Oops, This Page Could Not Be Found.</h2>
      <button type="button" onClick={homeClick}>
        Go home
      </button>
    </div>
  );
};

export default ErrorPage;
