export const EDUCATOR_TYPES = [
  { value: 1, label: "Clinical Educator" },
  { value: 2, label: "Other (External Training Organisation)" },
  { value: 3, label: "Peer Educator" },
];

export const EDUCATOR_STATUS = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const EDUCATOR_SERVICE = [
  { value: 1, label: "First Choice" },
  { value: 2, label: "Second Choice" },
];

export const COURSE_DELIVERY_LANGUAGE = [
  { value: 1, label: "First Choice" },
  { value: 2, label: "Second Choice" },
];

export const STUDENT_PROFILE = [];

export const EDUCATOR_FIRST_NAME_MAX_LENGTH = 50;
export const EDUCATOR_LAST_NAME_MAX_LENGTH = 50;
export const EDUCATOR_EMAIL_MAX_LENGTH = 255;
export const EDUCATOR_STREET_ADDRESS_MAX_LENGTH = 255;
export const EDUCATOR_SUBURB_MAX_LENGTH = 100;
export const EDUCATOR_STATE_MAX_LENGTH = 50;
export const EDUCATOR_CITY_MAX_LENGTH = 255;
export const EDUCATOR_PHONE_MAX_LENGTH = 11;
export const EDUCATOR_OTHER_WORKPLACE_MAX_LENGTH = 100;
export const EDUCATOR_AVAILABILITY_MAX_LENGTH = 100;
export const EDUCATOR_EMERGENCY_CONTACT_MAX_LENGTH = 50;
export const EDUCATOR_EMERGENCY_CONTACT_RELATION_MAX_LENGTH = 50;
export const EDUCATOR_SKILLS_MAX_LENGTH = 250;
