import { Box } from "@mui/system";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon, confirmSubmitIcon } from "assets/images";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface ISubmitConfirmationModalProps {
  handleSubmitConfirmationModalClose: (isNoClick?: boolean) => void;
  submitConfirmationMessage: string;
  handleYesClick: any;
}

const ConfirmModal: React.FC<ISubmitConfirmationModalProps> = ({
  handleSubmitConfirmationModalClose,
  submitConfirmationMessage,
  handleYesClick,
}) => {
  const handleYes = () => {
    handleSubmitConfirmationModalClose();
    handleYesClick();
  };

  return (
    <>
      <Modal
        open
        onClose={() => {
          handleSubmitConfirmationModalClose();
        }}
      >
        <Box className="common-modal delete-modal">
          <Box className="modal-header confirm-modal">
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton
                onClick={() => {
                  handleSubmitConfirmationModalClose();
                }}
              >
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body">
            <Box className="confirm-modal-body">
              <img src={confirmSubmitIcon} alt="confirm submit" />
            </Box>
            <Typography variant="h5" align="center">
              {submitConfirmationMessage}
            </Typography>
          </Box>
          <Box className="confirm-submit-footer">
            <Button variant="contained" onClick={handleYes}>
              Yes
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                handleSubmitConfirmationModalClose(true);
              }}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default ConfirmModal;
