import { Box } from "@mui/system";
import {
  Button,
  Card,
  Grid,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  IEducatorHomeListMeta,
  IEnhancedTableProps,
  IEnroledStudentDetailForEducatorHome,
} from "utils/interfaces/educator-home";
import { useCallback, useEffect, useState } from "react";
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  ENROLMENT_STATUS,
  MAX_INT_VALUE,
  PAGE_SIZES,
} from "utils/constants/constant";
import NoRecords from "components/NoRecords";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { useLocation } from "react-router-dom";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  base64ToArrayBuffer,
  getPagePayloadValuesWithId,
  getPaginationDetailText,
  hideLoaderForBatchApiCall,
  showLoader,
  showLoaderForBatchApiCall,
} from "utils/helper";
import {
  EnroledStudentListForEducatorHomePayload,
  EnroledStudentListForEducatorHomeSuccessPayload,
} from "store/educatorHome/types";
import { IEnroledStudentPagePayloadValues } from "utils/interfaces/scheduled-offering";
import {
  enrolmentCountRequest,
  printCourseEnrolmentsRequest,
} from "store/scheduledOffering/actions";
import {
  EnrolmentCountSuccessPayload,
  PrintCourseEnrolmentsSuccessPayload,
} from "store/scheduledOffering/types";
import { useDispatch } from "react-redux";
import projectTheme from "app.theme";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IEnroledStudentDetailForEducatorHome) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

interface IEducatorHomeEnrollmentsProps {
  enroledStudentListForEducatorHomeRequest: any;
}

const EducatorHomeEnrollments: React.FC<IEducatorHomeEnrollmentsProps> = ({
  enroledStudentListForEducatorHomeRequest,
}) => {
  const location = useLocation<{ scoId: number }>();
  const [enrollmentList, setEnrollmentList] =
    useState<IEnroledStudentDetailForEducatorHome[]>();
  const [enrollmentListMeta, setEnrollmentListMeta] =
    useState<IEducatorHomeListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] =
    useState<keyof IEnroledStudentDetailForEducatorHome>("studentName");
  const [enrolledCount, setEnrolledCount] = useState<number>();
  const [attendingCount, setAttendingCount] = useState<number>();
  const [withdrawnCount, setWithdrawnCount] = useState<number>();
  const [isBlendedCourse, setIsBlendedCourse] = useState<boolean>(false);
  const dispatch = useDispatch();
  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = useCallback(
    (
      event: React.MouseEvent<unknown>,
      newOrderBy: keyof IEnroledStudentDetailForEducatorHome
    ) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onEnrolmentListSuccess = (
    response: EnroledStudentListForEducatorHomeSuccessPayload
  ) => {
    setEnrollmentList(response.enroledStudentList);
    setEnrollmentListMeta(response.enroledStudentListPagination);
  };

  const getEnrolmentList = async () => {
    if (enroledStudentListForEducatorHomeRequest) {
      showLoader();

      const values = getPagePayloadValuesWithId(
        location?.state?.scoId,
        orderBy,
        order,
        pageNo,
        page
      );

      const payload: EnroledStudentListForEducatorHomePayload = {
        values,
        callback: onEnrolmentListSuccess,
      };
      enroledStudentListForEducatorHomeRequest(payload);
    }
  };

  const onPrintCourseListSuccess = (
    response: PrintCourseEnrolmentsSuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.item);
    const file = new Blob([item], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.setProperty("print", "fit-to-print");
    iframe.src = fileURL;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      const win = iframe.contentWindow;
      if (win) {
        win.print();
      }
    };
  };

  const getPrintEnrolments = () => {
    if (printCourseEnrolmentsRequest) {
      showLoader();
      const values: IEnroledStudentPagePayloadValues =
        getPagePayloadValuesWithId(
          location?.state?.scoId,
          orderBy,
          order,
          DEFAULT_PAGE_NO,
          MAX_INT_VALUE
        );
      values.name = "";
      values.IsEducatorHomeEnrolment = true;

      const payload = {
        values,
        callback: onPrintCourseListSuccess,
      };

      dispatch(printCourseEnrolmentsRequest(payload));
    }
  };

  const onEnrolmentCountSuccess = (response: EnrolmentCountSuccessPayload) => {
    setEnrolledCount(response?.enrolledCount);
    setAttendingCount(response?.attendingCount);
    setWithdrawnCount(response?.withdrawnCount);
    setIsBlendedCourse(response?.isBlended);
    hideLoaderForBatchApiCall();
  };

  const getEnrolmentCount = () => {
    if (enrolmentCountRequest) {
      showLoaderForBatchApiCall();
      const payload = {
        values: { id: location?.state?.scoId },
        callback: onEnrolmentCountSuccess,
      };
      dispatch(enrolmentCountRequest(payload));
    }
  };

  useEffect(() => {
    getEnrolmentList();
    getEnrolmentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const getStatusLabel = (status: number) => {
    let statusString = "";
    ENROLMENT_STATUS?.forEach((item) => {
      if (item.value === status) {
        statusString = item.label;
      }
    });

    return statusString;
  };

  return (
    <Grid item xs={12}>
      <Card>
        <Box className="table-card-header">
          <Box
            sx={{
              justifyContent: "space-between",
              width: { xs: "100%", lg: "auto" },
            }}
          >
            <Typography variant="h3">Enrolments</Typography>
          </Box>
          <Box>
            <Box className="counter-box info">
              <div className="title">Enrolled</div>
              <div className="counter">{enrolledCount}</div>
            </Box>
            <Box className="counter-box success">
              <div className="title">Attendance</div>
              <div className="counter">{attendingCount}</div>
            </Box>
            <Box className="counter-box warning">
              <div className="title">Withdrawn</div>
              <div className="counter">{withdrawnCount}</div>
            </Box>{" "}
            <Button
              variant="outlined"
              className="btn-filter btn-export"
              disabled={!enrollmentList?.length}
              onClick={getPrintEnrolments}
            >
              Print
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(e) => handleRequestSort(e, "studentName")}
                  fieldName="Student Name"
                  keyName="studentName"
                  sxStyle={{
                    width: "153px",
                    minWidth: "153px",
                  }}
                />
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(e) => handleRequestSort(e, "mrn")}
                  fieldName="MRN"
                  keyName="mrn"
                  sxStyle={{
                    width: "99px",
                    minWidth: "99px",
                    [projectTheme.breakpoints.down("lg")]: {
                      width: "99px",
                      minWidth: "99px",
                    },
                  }}
                />
                <TableCell
                  align="left"
                  sx={{
                    width: "150px",
                    minWidth: "150px",
                    [projectTheme.breakpoints.down("lg")]: {
                      width: "165px",
                      minWidth: "165px",
                    },
                  }}
                >
                  Date Enrolled
                </TableCell>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(e) => handleRequestSort(e, "enrolmentType")}
                  fieldName="Enrolment Type"
                  keyName="enrolmentType"
                  sxStyle={{
                    width: "142px",
                    minWidth: "142px",
                    [projectTheme.breakpoints.down("lg")]: {
                      width: "142px",
                      minWidth: "142px",
                    },
                  }}
                />
                <TableCell
                  align="left"
                  sx={{
                    width: "120px",
                    minWidth: "120px",
                    textAlign: "left",
                  }}
                >
                  Status
                </TableCell>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(e) => handleRequestSort(e, "mobile")}
                  fieldName="Phone"
                  keyName="mobile"
                  sxStyle={{
                    width: "144px",
                    minWidth: "144px",
                  }}
                />
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(e) =>
                    handleRequestSort(e, "emergencyContact")
                  }
                  fieldName="Emergency Contact"
                  keyName="emergencyContact"
                  sxStyle={{
                    width: "172px",
                    minWidth: "172px",
                  }}
                />
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(e) => handleRequestSort(e, "email")}
                  fieldName="Email"
                  keyName="email"
                  sxStyle={{
                    width: "172px",
                    minWidth: "172px",
                  }}
                />
                <TableCell
                  align="left"
                  sx={{ width: "220px", minWidth: "220px" }}
                >
                  Supports Required / Specific Learning Needs
                </TableCell>{" "}
                {isBlendedCourse && (
                  <TableCell
                    align="left"
                    sx={{
                      width: "150px",
                      minWidth: "150px",
                    }}
                  >
                    Preferred Course Delivery
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {enrollmentList?.map((row) => (
                <TableRow
                  key={`${row.studentId}${row.status}${row.dateEnrolled}`}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="body2" className="sco-list-word-wrap">
                      {row?.studentName}
                    </Typography>
                  </TableCell>
                  <TableCell>{row?.mrn}</TableCell>
                  <TableCell>{row?.dateEnrolled}</TableCell>
                  <TableCell>{row?.enrolmentType}</TableCell>
                  <TableCell
                    align="left"
                    className={`${getStatusLabel(
                      row.status
                    ).toLowerCase()} status`}
                  >
                    <div className="enrolment-status">
                      {getStatusLabel(row.status)}
                    </div>
                  </TableCell>
                  <TableCell>{row?.mobile}</TableCell>
                  <TableCell>{row?.emergencyContact}</TableCell>
                  <TableCell>
                    <Typography variant="body2" title={row.email}>
                      {row.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      title={row.supportRequired || "-"}
                    >
                      {row.supportRequired || "-"}
                    </Typography>
                  </TableCell>
                  {isBlendedCourse && (
                    <TableCell>{row.preferredCourseDelivery || "-"}</TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {enrollmentList && enrollmentList.length > 0 ? (
          <Box className="custom-pagination">
            <Box className="custom-rowperpage">
              <Typography variant="body2" component="span">
                Page:
              </Typography>
              <Select
                id="pagination-select"
                value={page}
                onChange={handleChange}
                MenuProps={{
                  className: "pagination-menu",
                }}
              >
                {PAGE_SIZES?.map((pageSize) => (
                  <MenuItem
                    key={pageSize.value}
                    value={pageSize.value}
                    selected={pageSize?.selected}
                  >
                    {pageSize.label}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2" component="span">
                {getPaginationDetailText(enrollmentListMeta, pageNo, page)}
              </Typography>{" "}
            </Box>
            <Pagination
              count={enrollmentListMeta?.totalPages}
              variant="outlined"
              shape="rounded"
              page={pageNo}
              onChange={onPageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon,
                  }}
                  {...item}
                />
              )}
            />
          </Box>
        ) : (
          <NoRecords />
        )}
      </Card>
    </Grid>
  );
};

export default EducatorHomeEnrollments;
