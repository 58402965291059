import { connect, MapDispatchToProps } from "react-redux";
import ReportComponent, { ReportProps } from "pages/Report";
import {
  getEnrolmentReportStudentDetailsRequest,
  printEnrolmentReportRequest,
  reportListRequest,
} from "store/report/actions";
import { IReportContainerDispatch, IReport } from "utils/interfaces/report";
import { enrolmentOptionListRequest } from "store/studentEnrolment/actions";

const mapDispatchToProps: MapDispatchToProps<
  IReportContainerDispatch,
  IReport
> = {
  reportListRequest,
  enrolmentOptionListRequest,
  printEnrolmentReportRequest,
  getEnrolmentReportStudentDetailsRequest,
};

const connector = connect(null, mapDispatchToProps);

const Reports: React.FC<ReportProps> = (props) => {
  return <ReportComponent {...props} />;
};

export default connector(Reports);
