import { connect, MapDispatchToProps } from "react-redux";
import ScheduledOfferingComponent, { ScheduledOfferingProps } from "pages/SCO";
import {
  addScheduledOfferingRequest,
  deleteScheduledOfferingRequest,
  editScheduledOfferingRequest,
  scheduledOfferingListRequest,
  scoDaysListRequest,
} from "store/scheduledOffering/actions";
import {
  IScheduledOfferingContainerDispatch,
  IScheduledOffering,
} from "utils/interfaces/scheduled-offering";

const mapDispatchToProps: MapDispatchToProps<
  IScheduledOfferingContainerDispatch,
  IScheduledOffering
> = {
  scheduledOfferingListRequest,
  addScheduledOfferingRequest,
  editScheduledOfferingRequest,
  deleteScheduledOfferingRequest,
  scoDaysListRequest,
};

const connector = connect(null, mapDispatchToProps);

const ScheduledOfferings: React.FC<ScheduledOfferingProps> = (props) => {
  return <ScheduledOfferingComponent {...props} />;
};

export default connector(ScheduledOfferings);
