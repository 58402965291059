import { connect, MapDispatchToProps } from "react-redux";
import StudentProfileComponent, {
  StudentProfileProps,
} from "pages/StudentProfile";
import { updateUserProfileRequest } from "store/profile/actions";
import {
  IStudentProfileContainerDispatch,
  IStudentProfile,
} from "utils/interfaces/student-profile";
import {
  editStudentProfileDetailRequest,
  studentProfileDetailsRequest,
} from "store/student/action";

const mapDispatchToProps: MapDispatchToProps<
  IStudentProfileContainerDispatch,
  IStudentProfile
> = {
  updateUserProfileRequest,
  studentProfileDetailsRequest,
  editStudentProfileDetailRequest,
};

const connector = connect(null, mapDispatchToProps);

const StudentProfile: React.FC<StudentProfileProps> = (props) => {
  return <StudentProfileComponent {...props} />;
};

export default connector(StudentProfile);
