import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon, deleteIcon, editIcon, plusLightIcon } from "assets/images";
import React, { useEffect, useState } from "react";
import projectTheme from "app.theme";
import {
  CLEAR_TOOLTIP_TITLE,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DELETE_TOOLTIP_TITLE,
  EDIT_TOOLTIP_TITLE,
  EDUCATOR_ROLE_ID,
  PAGE_SIZES,
  STUDENT_ROLE_ID,
} from "utils/constants/constant";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  IEnhancedTableProps,
  IRole,
  IRoleContainerDispatch,
  IRoleContainerState,
  IRoleListMeta,
  IRolesDetails,
  IRolePagePayloadValues,
} from "utils/interfaces/role";
import {
  DeleteRoleSuccessPayload,
  IRoleModulePermission,
  RoleListSuccessPayload,
} from "store/role/types";
import {
  allowSearch,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import NoRecords from "components/NoRecords";
import { AppRoutings } from "utils/enums/app-routings";
import { ROLE_DELETE_SUCCESS_MSG } from "utils/constants/Messages";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { AppPages } from "utils/enums/app-pages";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (newOrderBy: keyof IRolesDetails) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key="roles"
      align="left"
      onClick={createSortHandler("roleName")}
      sortDirection={orderBy === "roleName" ? order : false}
    >
      Roles
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type RoleProps = IRoleContainerState & IRoleContainerDispatch;

const Roles: React.FC<RoleProps> = (props) => {
  const [page, setPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IRolesDetails>("roleName");
  const [roleList, setRoleList] = useState<IRole[]>();
  const [roleListMeta, setRoleListMeta] = useState<IRoleListMeta>();
  const [searchText, setSearchText] = useState<string>("");
  const [isOpenRoleDeleteModal, setIsOpenRoleDeleteModal] = useState(false);
  const [deleteRoleId, setDeleteRoleId] = useState<number>();
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const onRoleListSuccess = (response: RoleListSuccessPayload) => {
    setRoleList(response.roleList);
    setRoleListMeta(response.roleListPagination);
  };

  const getRoleList = async (filtered?: boolean) => {
    const { roleListRequest } = props;

    if (roleListRequest) {
      showLoader();
      const values: IRolePagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.roleName = filtered ? "" : searchText.trim();

      const payload = {
        values,
        callback: onRoleListSuccess,
      };

      roleListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Roles);
    setPagePermission(permission);
    if (permission?.view) {
      getRoleList();
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IRolesDetails) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleRoleSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getRoleList(true);
  };

  const handleRoleSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getRoleList();
    }
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRoleDeleteModal = (roleId: number) => {
    setDeleteRoleId(roleId);
    setIsOpenRoleDeleteModal(true);
  };

  const handleRoleDeleteCloseModal = () => {
    setDeleteRoleId(0);
    setIsOpenRoleDeleteModal(false);
  };

  const onDeleteRoleSuccess = (response: DeleteRoleSuccessPayload) => {
    if (response.success) {
      toast.success(ROLE_DELETE_SUCCESS_MSG);
      handleRoleDeleteCloseModal();
      getRoleList();
    } else if (response.message) {
      toast.error(response.message);
    }
  };

  const handleDeleteRole = () => {
    const { deleteRoleRequest } = props;

    if (deleteRoleRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(deleteRoleId),
        },
        callback: onDeleteRoleSuccess,
      };

      deleteRoleRequest(payload);
    }
  };

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" className="heading">
          Roles
        </Typography>
        <Button
          variant="contained"
          className="btn-add"
          onClick={() => history.push(AppRoutings.AddRole)}
          disabled={!pagePermission?.add}
        >
          <img src={plusLightIcon} alt="plus" />
          Add
        </Button>
      </Box>
      <Grid
        container
        spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
        className="content-container"
      >
        <Grid item xs={12}>
          <Card>
            <Box className="table-card-header">
              <Box>
                <TextField
                  id="search-box"
                  variant="outlined"
                  className="search-input"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleRoleSearch}
                  onKeyDown={handleRoleSearchKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        sx={{ position: "absolute", right: "0" }}
                        position="end"
                      >
                        {searchText && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={CLEAR_TOOLTIP_TITLE}
                            arrow
                          >
                            <IconButton edge="end" onClick={handleClearSearch}>
                              <img src={closeIcon} alt="close" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) =>
                    onTextFieldKeyPress(event, searchText?.length)
                  }
                />
              </Box>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableCell
                      align="center"
                      sx={{
                        width: "152px",
                        [projectTheme.breakpoints.down("sm")]: {
                          width: "112px",
                        },
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roleList?.map((row) => {
                    return (
                      <TableRow key={row?.id}>
                        <TableCell component="th" scope="row">
                          {row?.roleName}
                        </TableCell>
                        <TableCell align="center">
                          <div className="table-actions">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={EDIT_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                disabled={!pagePermission?.edit}
                                onClick={() =>
                                  history.push({
                                    pathname: AppRoutings.EditRole,
                                    state: { roleId: Number(row?.id) },
                                  })
                                }
                                className={
                                  !pagePermission?.edit ? "disabled-icon" : ""
                                }
                              >
                                <img src={editIcon} alt="edit" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={DELETE_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                disabled={
                                  !pagePermission?.delete ||
                                  row.id === EDUCATOR_ROLE_ID ||
                                  row.id === STUDENT_ROLE_ID
                                }
                                onClick={() =>
                                  handleRoleDeleteModal(Number(row?.id))
                                }
                                className={
                                  !pagePermission?.delete ||
                                  row.id === EDUCATOR_ROLE_ID ||
                                  row.id === STUDENT_ROLE_ID
                                    ? "disabled-icon"
                                    : ""
                                }
                              >
                                <img src={deleteIcon} alt="delete" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {roleList && roleList.length > 0 ? (
              <Box className="custom-pagination">
                <Box className="custom-rowperpage">
                  <Typography variant="body2" component="span">
                    Page:
                  </Typography>
                  <Select
                    id="pagination-select"
                    value={page}
                    onChange={handleChange}
                    MenuProps={{
                      className: "pagination-menu",
                    }}
                  >
                    {PAGE_SIZES?.map((pageSize) => (
                      <MenuItem
                        key={pageSize.value}
                        value={pageSize.value}
                        selected={pageSize?.selected}
                      >
                        {pageSize.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="body2" component="span">
                    {getPaginationDetailText(roleListMeta, pageNo, page)}
                  </Typography>{" "}
                </Box>
                <Pagination
                  count={roleListMeta?.totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={pageNo}
                  onChange={onPageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                    />
                  )}
                />
              </Box>
            ) : (
              <NoRecords />
            )}
          </Card>
        </Grid>
      </Grid>

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenRoleDeleteModal}
        handleDeleteConfirmationModalClose={handleRoleDeleteCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete role?"
        handleYesClick={handleDeleteRole}
      />
    </>
  );
};

export default Roles;
