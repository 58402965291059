import { connect, MapDispatchToProps } from "react-redux";
import CourseOfferingDetailComponent, {
  CourseOfferingDetailProps,
} from "pages/StudentDashboard/CourseOfferingDetails";
import {
  ICourseOfferingDetail,
  IStudentDashboardCourseDetailsContainerDispatch,
} from "utils/interfaces/student-dashboard";
import {
  applyOrWithdrawCourseRequest,
  changeCoursePreferenceRequest,
  studentDashboardCourseDetailByIdRequest,
} from "store/studentDashboard/actions";
import { enrolmentOptionListRequest } from "store/studentEnrolment/actions";

const mapDispatchToProps: MapDispatchToProps<
  IStudentDashboardCourseDetailsContainerDispatch,
  ICourseOfferingDetail
> = {
  studentDashboardCourseDetailByIdRequest,
  enrolmentOptionListRequest,
  applyOrWithdrawCourseRequest,
  changeCoursePreferenceRequest,
};

const connector = connect(null, mapDispatchToProps);

const CourseOfferingDetails: React.FC<CourseOfferingDetailProps> = (props) => {
  return <CourseOfferingDetailComponent {...props} />;
};

export default connector(CourseOfferingDetails);
