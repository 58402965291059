import { Box } from "@mui/system";
import {
  Button,
  Fade,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { closeIcon } from "assets/images";
import { CLOSE_TOOLTIP_TITLE } from "utils/constants/constant";

interface IDeleteConfirmationModalProps {
  isOpenDeleteConfirmationModal: boolean;
  handleDeleteConfirmationModalClose: any;
  deleteConfirmationMessage: string;
  handleYesClick: any;
}

const DeleteConfirmationModal: React.FC<IDeleteConfirmationModalProps> = ({
  isOpenDeleteConfirmationModal,
  handleDeleteConfirmationModalClose,
  deleteConfirmationMessage,
  handleYesClick,
}) => {
  return (
    <>
      <Modal
        open={isOpenDeleteConfirmationModal}
        onClose={handleDeleteConfirmationModalClose}
      >
        <Box className="common-modal delete-modal">
          <Box className="modal-header">
            <Typography variant="h4">Delete Confirmation</Typography>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={CLOSE_TOOLTIP_TITLE}
              arrow
            >
              <IconButton onClick={handleDeleteConfirmationModalClose}>
                <img src={closeIcon} alt="close" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box className="modal-body">
            <Typography variant="h5">{deleteConfirmationMessage}</Typography>
          </Box>
          <Box className="modal-footer">
            <Button
              variant="contained"
              className="btn-save"
              onClick={handleYesClick}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              className="btn-cancel"
              onClick={handleDeleteConfirmationModalClose}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteConfirmationModal;
