import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  calendarFilledDarkIcon,
  calendarFilledLightIcon,
  closeIcon,
  deleteIcon,
  editIcon,
  filterLightIcon,
  filterPrimaryIcon,
  plusLightIcon,
} from "assets/images";
import React, { useState, useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  IAddEditScheduledOffering,
  IAddScheduleDetails,
  ICourseOfferingDays,
  ICourseOfferingDaysResponse,
  IEnhancedTableProps,
  IRepeat,
  ISCODetail,
  ISCOPageDetails,
  IScheduledOffering,
  IScheduledOfferingContainerDispatch,
  IScheduledOfferingContainerState,
  IScheduledOfferingListMeta,
  IScoPagePayloadValues,
} from "utils/interfaces/scheduled-offering";
import { IRoleModulePermission } from "store/role/types";
import {
  allowSearch,
  convertToDateTime,
  convertToDateTimeWithFormat,
  getPagePayloadValues,
  getPagePermissions,
  getPaginationDetailText,
  onTextFieldKeyPress,
  showLoader,
} from "utils/helper";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  CLEAR_TOOLTIP_TITLE,
  DATE_FORMAT,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DELETE_TOOLTIP_TITLE,
  EDIT_TOOLTIP_TITLE,
  PAGE_SIZES,
  SCO_CERTIFICATE,
  SCO_STATUS,
  TIME_FORMAT,
} from "utils/constants/constant";
import {
  SCOBlendedCourse,
  SCOCertificate,
} from "utils/enums/scheduled-offering";
import {
  AddEditScheduledOfferingSuccessPayload,
  DeleteScheduledOfferingSuccessPayload,
  ScheduledOfferingListSuccessPayload,
} from "store/scheduledOffering/types";
import { toast } from "react-toastify";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import NoRecords from "components/NoRecords";
import { AppRoutings } from "utils/enums/app-routings";
import {
  SCO_ADD_SUCCESS_MSG,
  SCO_DELETE_SUCCESS_MSG,
  SELECT_MIN_ONE_FIELD,
} from "utils/constants/Messages";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { AppPages } from "utils/enums/app-pages";
import OverlayBox from "components/OverlayBox";
import AddEditScheduledOffering from "./AddEditSCO";
import SCOFilter from "./SCOFilter";
import AddSCODays from "./AddSCODays";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof ISCOPageDetails) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type ScheduledOfferingProps = IScheduledOfferingContainerState &
  IScheduledOfferingContainerDispatch;

const ScheduledCourseOfferings: React.FC<ScheduledOfferingProps> = (props) => {
  const [scheduledOfferingList, setScheduledOfferingList] =
    useState<IScheduledOffering[]>();
  const [scheduledOfferingListMeta, setScheduledOfferingListMeta] =
    useState<IScheduledOfferingListMeta>();
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof ISCOPageDetails>("courseNo");
  const [searchText, setSearchText] = useState<string>("");
  const [isOpenScoModal, setIsOpenScoModal] = useState<boolean>(false);
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [filteredData, setFilteredData] = useState<IAddEditScheduledOffering>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
  const [isOpenScoDeleteModal, setIsOpenScoDeleteModal] = useState(false);
  const [deleteScoId, setDeleteScoId] = useState<number>();
  const [addFormData, setAddFormData] = useState<IAddEditScheduledOffering>();
  const [repeatCourseOffering, setRepeatCourseOffering] = useState<IRepeat>();
  const [filterBox, setFilterBox] = useState<boolean>(false);
  const [addSCODaysModal, setAddSCODaysModal] = useState<boolean>(false);
  const [scoDetails, setScoDetails] = useState<ISCODetail>();
  const [scoDaysList, setScoDaysList] =
    useState<ICourseOfferingDaysResponse[]>();
  const [coursePagePermission, setCoursePagePermission] =
    useState<IRoleModulePermission>();
  const [pickerMinDate, setPickerMinDate] = useState<any>();
  const [pickerMaxDate, setPickerMaxDate] = useState<any>();
  const user = useSelector((state: any) => state?.auth?.user);
  const history = useHistory();

  const handleSco = () => setIsOpenScoModal(true);
  const handleScoClose = () => {
    setRepeatCourseOffering(undefined);
    setIsOpenScoModal(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof ISCOPageDetails) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onScheduledOfferingListSuccess = (
    response: ScheduledOfferingListSuccessPayload
  ) => {
    setScheduledOfferingList(response.scheduledOfferingList);
    setScheduledOfferingListMeta(response.scheduledOfferingListPagination);
  };

  const getScheduledOfferingList = async (
    filterData?: IAddEditScheduledOffering,
    filtered?: boolean,
    pageNum?: number
  ) => {
    const { scheduledOfferingListRequest } = props;

    if (scheduledOfferingListRequest) {
      showLoader();

      const values: IScoPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.courseName = filtered ? "" : searchText.trim();

      if (filterData?.courseNumber) {
        values.courseNumber = filterData?.courseNumber;
      }
      if (filterData?.terms) {
        values.terms = filterData?.terms.map((term) => +term);
      }
      if (filterData?.locations) {
        values.locations = filterData?.locations.map((loc) => +loc);
      }
      if (
        filterData?.certificatesIssued === SCOCertificate.No ||
        filterData?.certificatesIssued === SCOCertificate.Yes
      ) {
        values.certificateIssued = filterData?.certificatesIssued;
      }
      if (
        filterData?.emrEntered === SCOCertificate.No ||
        filterData?.emrEntered === SCOCertificate.Yes
      ) {
        values.emrEntered = filterData?.emrEntered;
      }
      if (filterData?.status) {
        values.status = filterData?.status;
      }
      if (filterData?.year) {
        values.year = filterData?.year;
      }
      if (
        filterData?.isBlendedCourse === SCOBlendedCourse.No ||
        filterData?.isBlendedCourse === SCOBlendedCourse.Yes
      ) {
        values.isBlendedCourse = filterData?.isBlendedCourse;
      }

      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onScheduledOfferingListSuccess,
      };

      scheduledOfferingListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.ScheduledOfferings
    );
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getScheduledOfferingList(filteredData, true, 1);
      } else if (filteredData) {
        getScheduledOfferingList(filteredData);
      } else {
        getScheduledOfferingList();
      }
      const coursePermission = getPagePermissions(
        user?.permissions,
        AppPages.Courses
      );
      setCoursePagePermission(coursePermission);
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleScoSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getScheduledOfferingList(filteredData, true, 1);
  };

  const handleScoSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getScheduledOfferingList(filteredData);
    }
  };

  const handleScoDeleteModal = (scoId: number) => {
    setDeleteScoId(scoId);
    setIsOpenScoDeleteModal(true);
  };

  const handleScoDeleteCloseModal = () => {
    setDeleteScoId(0);
    setIsOpenScoDeleteModal(false);
  };

  const handleAddSCODaysModal = () => setAddSCODaysModal(true);
  const handleAddSCODaysModalClose = () => setAddSCODaysModal(false);

  const handleAddScheduledOffering = (formData: IAddEditScheduledOffering) => {
    setAddFormData(formData);
    handleAddSCODaysModal();
  };

  const onDeleteScoSuccess = (
    response: DeleteScheduledOfferingSuccessPayload
  ) => {
    if (response.success) {
      toast.success(SCO_DELETE_SUCCESS_MSG);
      handleScoDeleteCloseModal();
      getScheduledOfferingList();
    }
  };

  const handleDeleteSco = () => {
    const { deleteScheduledOfferingRequest } = props;

    if (deleteScheduledOfferingRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(deleteScoId),
        },
        callback: onDeleteScoSuccess,
      };

      deleteScheduledOfferingRequest(payload);
    }
  };

  const handleFilterApplyClick = (formData: IAddEditScheduledOffering) => {
    if (
      !(
        formData.courseNumber ||
        formData.terms ||
        formData.locations ||
        formData.certificatesIssued === SCOCertificate.No ||
        formData.certificatesIssued === SCOCertificate.Yes ||
        formData.emrEntered === SCOCertificate.No ||
        formData.emrEntered === SCOCertificate.Yes ||
        formData.status ||
        formData.year ||
        formData.isBlendedCourse === SCOBlendedCourse.No ||
        formData.isBlendedCourse === SCOBlendedCourse.Yes
      )
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      setSearchText("");
      if (pageNo === DEFAULT_PAGE_NO) {
        getScheduledOfferingList(formData, true, 1);
      } else {
        setPageNo(1);
      }
      setFilterBox(false);
      document.body.classList.toggle("filter-open");
    }
  };

  const onAddScheduledOfferingSuccess = (
    response: AddEditScheduledOfferingSuccessPayload
  ) => {
    if (response.success) {
      toast.success(SCO_ADD_SUCCESS_MSG);
      handleScoClose();
      handleAddSCODaysModalClose();
      getScheduledOfferingList();
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleFinalAddSCOClick = (
    addScheduleRow: Array<IAddScheduleDetails>
  ) => {
    let repeat;
    if (repeatCourseOffering) {
      const formattedRepeatEndDate = convertToDateTimeWithFormat(
        repeatCourseOffering.endOn,
        `${DATE_FORMAT} ${TIME_FORMAT}`
      );
      repeat = {
        ...repeatCourseOffering,
        repeatDays: repeatCourseOffering.repeatDays
          ? repeatCourseOffering.repeatDays?.substring(
              0,
              repeatCourseOffering.repeatDays?.length
            )
          : "",
        endOn:
          formattedRepeatEndDate !== "Invalid date"
            ? formattedRepeatEndDate
            : null,
      };
    }
    if (repeat?.repeatDays) {
      repeat.repeatDays = repeat.repeatDays.replace(/,$/, "");
    }
    const startTime = convertToDateTimeWithFormat(
      addFormData?.startTimeOfFirstSession,
      TIME_FORMAT
    );
    const endTime = convertToDateTimeWithFormat(
      addFormData?.endTimeOfFirstSession,
      TIME_FORMAT
    );

    const offeringDays: Array<ICourseOfferingDays> = [];
    addScheduleRow.forEach((row) => {
      const formattedSessionDate = `${convertToDateTime(
        row.sessionDate,
        DATE_FORMAT
      )} ${startTime}`;
      const formattedSessionEndTime = `${convertToDateTime(
        row.sessionDate,
        DATE_FORMAT
      )} ${endTime}`;
      offeringDays.push({
        dayNumber: Number(row.day),
        sessionStartDateTime: formattedSessionDate,
        sessionEndDateTime: formattedSessionEndTime,
      });
    });

    const { addScheduledOfferingRequest } = props;

    if (addScheduledOfferingRequest) {
      showLoader();
      const payload = {
        values: {
          courseId: Number(addFormData?.courseId),
          locationId: Number(addFormData?.locationId),
          termId: Number(addFormData?.termId),
          educatorIds: addFormData?.educatorIds ? addFormData.educatorIds : [],
          maxEnrollments: addFormData?.maxEnrollments
            ? Number(addFormData?.maxEnrollments)
            : null,
          certificatesIssued:
            addFormData?.certificatesIssued === SCOCertificate.No ||
            addFormData?.certificatesIssued === SCOCertificate.Yes
              ? Number(addFormData?.certificatesIssued)
              : null,
          emrEntered:
            addFormData?.emrEntered === SCOCertificate.No ||
            addFormData?.emrEntered === SCOCertificate.Yes
              ? Number(addFormData?.emrEntered)
              : null,
          blankEvaluationFormDoc:
            addFormData?.blankEvaluationFormDoc?.toString(),
          blankEvaluationForm: addFormData?.blankEvaluationForm
            ? addFormData.blankEvaluationForm
            : "",
          startDateOfFirstSession: convertToDateTimeWithFormat(
            addFormData?.startDateOfFirstSession,
            `${DATE_FORMAT} ${TIME_FORMAT}`
          ),
          startTimeOfFirstSession: startTime,
          endTimeOfFirstSession: endTime,
          endDateOfFirstSession: convertToDateTimeWithFormat(
            addFormData?.endDateOfFirstSession,
            `${DATE_FORMAT} ${TIME_FORMAT}`
          ),
          isAllDay: addFormData?.isAllDay,
          isRepeat: addFormData?.isRepeat,
          courseOfferingRepeatSettings: addFormData?.isRepeat ? repeat : null,
          courseOfferingSessions: offeringDays,
        },
        callback: onAddScheduledOfferingSuccess,
      };
      addScheduledOfferingRequest(payload);
    }
  };

  const getStatusLabel = (status: number) => {
    let statusString = "";
    SCO_STATUS?.forEach((item) => {
      if (item.value === status) {
        statusString = item.label;
      }
    });

    return statusString;
  };

  const getCertificateAndEmrLabel = (value: number) => {
    let label = "";
    SCO_CERTIFICATE?.forEach((item) => {
      if (item.value === value) {
        label = item.label;
      }
    });

    return label;
  };

  return (
    <div>
      {filterBox && <OverlayBox setFilterBox={setFilterBox} />}
      <div>
        <Box className="content-header">
          <Typography variant="h2" className="heading">
            Scheduled Course Offerings
          </Typography>
          <Box sx={{ display: "flex", columnGap: "15px" }}>
            <Button
              variant="contained"
              className="btn-add"
              onClick={handleSco}
              disabled={!pagePermission?.add}
            >
              <img src={plusLightIcon} alt="plus" />
              Add
            </Button>
          </Box>
        </Box>
        <Grid
          container
          spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
          className="content-container"
        >
          <Grid item xs={12}>
            <Card>
              <Box className="table-card-header">
                <Box
                  sx={{ justifyContent: "space-between", width: "100%" }}
                  className="search-filter-inputs"
                >
                  <TextField
                    id="search-box"
                    variant="outlined"
                    className="search-input"
                    placeholder="Search"
                    value={searchText}
                    onChange={handleScoSearch}
                    onKeyDown={handleScoSearchKeyDown}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          sx={{ position: "absolute", right: "0" }}
                          position="end"
                        >
                          {searchText && (
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={CLEAR_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                edge="end"
                                onClick={handleClearSearch}
                              >
                                <img src={closeIcon} alt="close" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event) =>
                      onTextFieldKeyPress(event, searchText?.length)
                    }
                  />

                  <Button
                    variant="outlined"
                    className="btn-filter"
                    onClick={() => {
                      setFilterBox(true);
                      document.body.classList.toggle("filter-open");
                    }}
                  >
                    <img
                      src={filterPrimaryIcon}
                      alt="filter"
                      className="default"
                    />
                    <img
                      src={filterLightIcon}
                      alt="filter"
                      className="active"
                    />
                    <span>Filter</span>
                  </Button>
                </Box>
              </Box>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e: React.MouseEvent<unknown>) =>
                          handleRequestSort(e, "courseNo")
                        }
                        fieldName="Course#"
                        keyName="courseNo"
                        sxStyle={{
                          maxWidth: "130px",
                          width: "130px",
                          minWidth: "130px",
                        }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e: React.MouseEvent<unknown>) =>
                          handleRequestSort(e, "course")
                        }
                        fieldName="Course"
                        keyName="course"
                        sxStyle={{
                          width: "154px",
                          minWidth: "154px",
                        }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e: React.MouseEvent<unknown>) =>
                          handleRequestSort(e, "term")
                        }
                        fieldName="Term"
                        keyName="term"
                        sxStyle={{
                          width: "82px",
                          minWidth: "82px",
                        }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e: React.MouseEvent<unknown>) =>
                          handleRequestSort(e, "location")
                        }
                        fieldName="Location"
                        keyName="location"
                        sxStyle={{
                          width: "146px",
                          minWidth: "146px",
                        }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e: React.MouseEvent<unknown>) =>
                          handleRequestSort(e, "firstSession")
                        }
                        fieldName="First Session"
                        keyName="firstSession"
                        sxStyle={{
                          width: "150px",
                          minWidth: "150px",
                        }}
                      />
                      <TableCell
                        align="center"
                        sx={{ width: "120px", minWidth: "120px" }}
                      >
                        No. Of Session Days
                      </TableCell>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e: React.MouseEvent<unknown>) =>
                          handleRequestSort(e, "certificateIssued")
                        }
                        fieldName="Certificates Issued?"
                        keyName="certificateIssued"
                        sxStyle={{
                          width: "103px",
                          minWidth: "103px",
                          textAlign: "center",
                        }}
                      />
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e: React.MouseEvent<unknown>) =>
                          handleRequestSort(e, "EMREntered")
                        }
                        fieldName="EMR Entered"
                        keyName="EMREntered"
                        sxStyle={{
                          width: "90px",
                          minWidth: "90px",
                          textAlign: "center",
                        }}
                      />
                      <TableCell
                        align="center"
                        sx={{ width: "78px", minWidth: "78px" }}
                      >
                        No. Enrolled
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ width: "112px", minWidth: "112px" }}
                      >
                        Sessions with unsubmitted attendance
                      </TableCell>{" "}
                      <TableCell sx={{ width: "112px", minWidth: "112px" }}>
                        Educators
                      </TableCell>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={(e: React.MouseEvent<unknown>) =>
                          handleRequestSort(e, "status")
                        }
                        fieldName="Status"
                        keyName="status"
                        sxStyle={{ width: "102px", textAlign: "center" }}
                      />
                      <TableCell align="center" sx={{ width: "92px" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scheduledOfferingList?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.courseDTO?.courseNo}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              if (coursePagePermission?.edit) {
                                history.push({
                                  pathname: AppRoutings.EditCourse,
                                  state: {
                                    courseId: Number(row.courseID),
                                  },
                                });
                              }
                            }}
                          >
                            <Typography
                              variant="body2"
                              className="sco-list-word-wrap"
                            >
                              {row.courseDTO?.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {row.termsDTO?.termName}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            className="sco-list-word-wrap"
                          >
                            {row.locationDTO?.location}
                            {row.locationDTO.isBlended &&
                              " - Blended (Face To Face / Online)"}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {row.firstSession ? (
                              <>
                                <div>{row.firstSession.split(",")[0]}</div>
                                <div>{row.firstSession.split(",")[1]}</div>
                              </>
                            ) : (
                              "-"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="outlined"
                            disabled={!pagePermission?.edit}
                            className={
                              !pagePermission?.edit
                                ? "btn-course disabled-icon"
                                : "btn-course"
                            }
                            onClick={() =>
                              history.push({
                                pathname: AppRoutings.ScheduledOfferingsDetails,
                                state: {
                                  scoId: Number(row.id),
                                  autoScrollToSessions: true,
                                },
                              })
                            }
                          >
                            <img
                              src={calendarFilledDarkIcon}
                              alt="Course List"
                              className="default"
                            />
                            <img
                              src={calendarFilledLightIcon}
                              alt="Course List"
                              className="active"
                            />
                            <Typography variant="body2">
                              {row.noOfSessions}
                            </Typography>
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          {getCertificateAndEmrLabel(
                            Number(row.isCertificateIssued)
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {getCertificateAndEmrLabel(Number(row.isEMREntered))}
                        </TableCell>
                        <TableCell align="center">{row.noEnrolled}</TableCell>
                        <TableCell align="center">
                          {row.daysWithUnsubmittedAttendance}
                        </TableCell>
                        <TableCell sx={{ wordWrap: "break-word" }}>
                          {row.educators || "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={`${getStatusLabel(
                            row.status
                          ).toLowerCase()} status`}
                        >
                          <div>{getStatusLabel(row.status)}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div className="table-actions">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={EDIT_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                disabled={!pagePermission?.edit}
                                className={
                                  !pagePermission?.edit ? "disabled-icon" : ""
                                }
                                onClick={() =>
                                  history.push({
                                    pathname:
                                      AppRoutings.ScheduledOfferingsDetails,
                                    state: {
                                      scoId: Number(row.id),
                                      autoScrollToSessions: false,
                                    },
                                  })
                                }
                              >
                                <img src={editIcon} alt="edit" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={DELETE_TOOLTIP_TITLE}
                              arrow
                            >
                              <IconButton
                                onClick={() => handleScoDeleteModal(row?.id)}
                                disabled={!pagePermission?.delete}
                                className={
                                  !pagePermission?.delete ? "disabled-icon" : ""
                                }
                              >
                                <img src={deleteIcon} alt="delete" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {scheduledOfferingList && scheduledOfferingList.length > 0 ? (
                <Box className="custom-pagination">
                  <Box className="custom-rowperpage">
                    <Typography variant="body2" component="span">
                      Page:
                    </Typography>
                    <Select
                      id="pagination-select"
                      value={page}
                      onChange={handleChange}
                      MenuProps={{
                        className: "pagination-menu",
                      }}
                    >
                      {PAGE_SIZES?.map((pageSize) => (
                        <MenuItem
                          key={pageSize.value}
                          value={pageSize.value}
                          selected={pageSize?.selected}
                        >
                          {pageSize.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="body2" component="span">
                      {getPaginationDetailText(
                        scheduledOfferingListMeta,
                        pageNo,
                        page
                      )}
                    </Typography>{" "}
                  </Box>
                  <Pagination
                    count={scheduledOfferingListMeta?.totalPages}
                    variant="outlined"
                    shape="rounded"
                    page={pageNo}
                    onChange={onPageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Box>
              ) : (
                <NoRecords />
              )}
            </Card>
          </Grid>
        </Grid>
      </div>

      <AddEditScheduledOffering
        isOpenScheduledOfferingModal={isOpenScoModal}
        handleScheduledOfferingClose={handleScoClose}
        handleSaveClick={handleAddScheduledOffering}
        setScoDaysList={setScoDaysList}
        repeatCourseOffering={repeatCourseOffering}
        setRepeatCourseOffering={setRepeatCourseOffering}
        setScoDetails={setScoDetails}
        pickerMinDate={pickerMinDate}
        setPickerMinDate={setPickerMinDate}
        pickerMaxDate={pickerMaxDate}
        setPickerMaxDate={setPickerMaxDate}
      />

      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenScoDeleteModal}
        handleDeleteConfirmationModalClose={handleScoDeleteCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete scheduled course offering?"
        handleYesClick={handleDeleteSco}
      />

      <AddSCODays
        addSCODaysModal={addSCODaysModal}
        handleAddSCODaysModalClose={handleAddSCODaysModalClose}
        addFormData={addFormData}
        handleFinalAddSCOClick={handleFinalAddSCOClick}
        scoDetails={scoDetails}
        scoDaysList={scoDaysList}
        pickerMinDate={pickerMinDate}
        pickerMaxDate={pickerMaxDate}
      />

      {filterBox && (
        <SCOFilter
          filterBox={filterBox}
          setFilterBox={setFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          setFilteredData={setFilteredData}
          getScheduledOfferingList={getScheduledOfferingList}
          setAppliedFilter={setAppliedFilter}
        />
      )}
    </div>
  );
};

export default ScheduledCourseOfferings;
