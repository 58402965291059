import { Box } from "@mui/system";
import {
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  editIcon,
  filterLightIcon,
  filterPrimaryIcon,
  printIcon,
  sendSMSIcon,
  sentSMSIcon,
} from "assets/images";
import projectTheme from "app.theme";
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZES,
  ENROLMENT_STATUS,
  MAX_INT_VALUE,
} from "utils/constants/constant";
import NoRecords from "components/NoRecords";
import {
  IChangeEnrolmentStatus,
  IEnhancedTableProps,
  IEnroledStudent,
  IEnroledStudentFilter,
  IEnroledStudentPagePayloadValues,
  IScheduledOfferingListMeta,
} from "utils/interfaces/scheduled-offering";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import { Order, OrderByOptions } from "utils/enums/sorting";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  base64ToArrayBuffer,
  getPagePayloadValuesWithId,
  getPagePermissions,
  getPaginationDetailText,
  hideLoaderForBatchApiCall,
  showLoader,
  showLoaderForBatchApiCall,
  toggleFilter,
} from "utils/helper";
import { AppPages } from "utils/enums/app-pages";
import {
  EnroledStudentListSuccessPayload,
  EnrolmentCountSuccessPayload,
  PrintCourseEnrolmentsSuccessPayload,
} from "store/scheduledOffering/types";
import React, { useEffect, useState } from "react";
import {
  ENROLMENT_STATUS_CHANGED_SUCCESSFULLY,
  RESEND_SMS_SUCCESS,
  SELECT_MIN_ONE_FIELD,
} from "utils/constants/Messages";
import { toast } from "react-toastify";
import { IEnrolmentOptions } from "utils/interfaces/student-enrolment";
import {
  ChangeEnrolmentStatusPayload,
  ChangeEnrolmentStatusSuccessPayload,
  EnrolmentOptionListSuccessPayload,
} from "store/studentEnrolment/types";
import { AppRoutings } from "utils/enums/app-routings";
import {
  DownloadCertificatePayload,
  DownloadCertificateSuccessPayload,
} from "store/studentDashboard/types";
import ConfirmModal from "components/ConfirmModal";
import { reSendConfirmationMailRequest } from "store/student/action";
import { ReSendConfirmationMailSuccessPayload } from "store/student/types";
import { EnrolmentStatus } from "utils/enums/student-dashboard";
import EnrollmentFilter from "./EnrollmentFilter";
import ChangeEnrolmentStatus from "../ChangeEnrolmentStatus";

interface IEnrollmentStudentProps {
  courseOfferingId: number;
  enroledStudentListRequest: any;
  enrolmentOptionListRequest: any;
  enrolmentCountRequest: any;
  printCourseEnrolmentsRequest: any;
  changeEnrolmentStatusRequest: (payload: ChangeEnrolmentStatusPayload) => {};
  downloadCertificateRequest: (payload: DownloadCertificatePayload) => {};
  courseName: string;
}

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IEnroledStudent) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

const Enrollments = ({
  courseOfferingId,
  enroledStudentListRequest,
  enrolmentOptionListRequest,
  enrolmentCountRequest,
  printCourseEnrolmentsRequest,
  changeEnrolmentStatusRequest,
  downloadCertificateRequest,
  courseName,
}: IEnrollmentStudentProps) => {
  const [page, setPage] = useState<string>(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const user = useSelector((state: any) => state?.auth?.user);
  const history = useHistory();
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IEnroledStudent>("studentName");
  const [filteredData, setFilteredData] = useState<IEnroledStudentFilter>();
  const [enroledStudentList, setEnroledStudentList] =
    useState<IEnroledStudent[]>();
  const [scheduledOfferingListMeta, setScheduledOfferingListMeta] =
    useState<IScheduledOfferingListMeta>();
  const [enrolmentOptionList, setEnrolmentOptionList] =
    useState<IEnrolmentOptions>();
  const [enrollFilterBox, setEnrollFilterBox] = useState<boolean>(false);
  const [enrolledCount, setEnrolledCount] = useState<number>();
  const [attendingCount, setAttendingCount] = useState<number>();
  const [withdrawnCount, setWithdrawnCount] = useState<number>();
  const [isPrintDisabled, setIsPrintDisabled] = useState<boolean>(false);
  const [isBlendedCourse, setIsBlendedCourse] = useState<boolean>(false);
  const [
    isOpenChangeEnrolmentStatusModal,
    setIsOpenChangeEnrolmentStatusModal,
  ] = useState<boolean>(false);
  const [changeEnrolmentStatusData, setChangeEnrolmentStatusData] =
    useState<IChangeEnrolmentStatus>();
  const [studentCourseEnrolmentId, setStudentCourseEnrolmentId] =
    useState<number>();
  const [
    isOpenReSendConfirmationMailModal,
    setIsOpenReSendConfirmationMailModal,
  ] = useState<boolean>(false);
  const handleChange = (event: SelectChangeEvent) => {
    setPage(event?.target?.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IEnroledStudent) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const handleSubmitConfirmationModalClose = () => {
    setIsOpenReSendConfirmationMailModal(false);
  };

  const onEnroledStudentListSuccess = (
    response: EnroledStudentListSuccessPayload
  ) => {
    setEnroledStudentList(response?.enroledStudentList);
    setScheduledOfferingListMeta(response?.enroledStudentListPagination);
    hideLoaderForBatchApiCall();
  };

  const onEnrolmentCountSuccess = (response: EnrolmentCountSuccessPayload) => {
    setEnrolledCount(response?.enrolledCount);
    setAttendingCount(response?.attendingCount);
    setWithdrawnCount(response?.withdrawnCount);
    setIsPrintDisabled(response?.isPrint);
    setIsBlendedCourse(response?.isBlended);
    hideLoaderForBatchApiCall();
  };

  const getEnroledStudentList = (filterData?: IEnroledStudentFilter) => {
    if (enroledStudentListRequest) {
      showLoaderForBatchApiCall();
      const values: IEnroledStudentPagePayloadValues =
        getPagePayloadValuesWithId(
          courseOfferingId,
          orderBy,
          order,
          pageNo,
          page
        );
      if (filterData?.name) {
        values.name = filterData?.name;
      }
      if (filterData?.mrnNo) {
        values.mrnNo = filterData?.mrnNo;
      }
      if (filterData?.type) {
        values.type = filterData?.type;
      }
      if (
        filterData?.enrolmentStatus ||
        filterData?.enrolmentStatus === EnrolmentStatus.Pending
      ) {
        values.enrolmentStatus = filterData?.enrolmentStatus;
      }
      if (filterData) {
        setFilteredData(filterData);
      } else {
        setFilteredData(undefined);
      }

      const payload = {
        values,
        callback: onEnroledStudentListSuccess,
      };
      enroledStudentListRequest(payload);
    }
  };

  const getEnrolmentCount = () => {
    if (enrolmentCountRequest) {
      showLoaderForBatchApiCall();
      const payload = {
        values: { id: courseOfferingId },
        callback: onEnrolmentCountSuccess,
      };
      enrolmentCountRequest(payload);
    }
  };

  const isSaveAttendanceClick = useSelector(
    (state: any) => state?.scheduledOffering?.isSaveAttendance
  );

  const isSaveGenerateCertificateClick = useSelector(
    (state: any) => state?.scheduledOffering?.isSaveCertificate
  );

  const handleFilter = () => {
    setEnrollFilterBox(true);
    toggleFilter();
  };

  const handleFilterApplyClick = (formData: IEnroledStudentFilter) => {
    if (
      !(
        formData.name ||
        formData.mrnNo ||
        formData.type ||
        formData.enrolmentStatus ||
        formData.enrolmentStatus === EnrolmentStatus.Pending
      )
    ) {
      toast.error(SELECT_MIN_ONE_FIELD);
    } else {
      setFilteredData(formData);
      if (pageNo === DEFAULT_PAGE_NO) {
        getEnroledStudentList(formData);
      } else {
        setPageNo(1);
      }
      setEnrollFilterBox(false);
      toggleFilter();
    }
  };

  const onEnrolmentOptionListSuccess = (
    response: EnrolmentOptionListSuccessPayload
  ) => {
    setEnrolmentOptionList(response.enrolmentOptionList);
  };

  const getEnrolmentOptionList = () => {
    if (enrolmentOptionListRequest) {
      showLoader();

      const payload = {
        values: {},
        callback: onEnrolmentOptionListSuccess,
      };

      enrolmentOptionListRequest(payload);
    }
  };

  const getStatusLabel = (status: number) => {
    let statusString = "";
    ENROLMENT_STATUS?.forEach((item) => {
      if (item.value === status) {
        statusString = item.label;
      }
    });

    return statusString;
  };

  const handleChangeEnrolmentStatusClose = () =>
    setIsOpenChangeEnrolmentStatusModal(false);

  const handleChangeEnrolmentStatusModal = (
    id: number,
    enrolmentStatus: number
  ) => {
    setChangeEnrolmentStatusData({ enrolmentId: id, enrolmentStatus });
    setIsOpenChangeEnrolmentStatusModal(true);
  };

  const onChangeEnrolmentStatusSuccess = (
    response: ChangeEnrolmentStatusSuccessPayload
  ) => {
    if (response.success) {
      toast.success(ENROLMENT_STATUS_CHANGED_SUCCESSFULLY);
      if (filteredData) {
        getEnroledStudentList(filteredData);
      } else {
        getEnroledStudentList();
      }
      getEnrolmentCount();
      handleChangeEnrolmentStatusClose();
    }
  };

  const handleChangeEnrolmentStatus = (status: IChangeEnrolmentStatus) => {
    if (changeEnrolmentStatusRequest) {
      showLoader();
      const payload = {
        values: {
          id: changeEnrolmentStatusData?.enrolmentId as number,
          enrolmentStatus: status.enrolmentStatus,
        },
        callback: onChangeEnrolmentStatusSuccess,
      };
      changeEnrolmentStatusRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.ScheduledOfferings
    );
    if (permission?.view) {
      getEnrolmentCount();
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveAttendanceClick]);

  useEffect(() => {
    const permission = getPagePermissions(
      user?.permissions,
      AppPages.ScheduledOfferings
    );
    getEnrolmentOptionList();
    if (permission?.view) {
      getEnrolmentCount();
      if (filteredData) {
        getEnroledStudentList(filteredData);
      } else {
        getEnroledStudentList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, order, orderBy, page, isSaveGenerateCertificateClick]);

  const onPrintCourseListSuccess = (
    response: PrintCourseEnrolmentsSuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.item);
    const file = new Blob([item], {
      type: "application/pdf",
    });

    const fileURL = URL.createObjectURL(file);

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.setProperty("print", "fit-to-print");
    iframe.src = fileURL;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      const win = iframe.contentWindow;
      if (win) {
        win.print();
      }
    };
  };

  const getPrintEnrolments = () => {
    if (printCourseEnrolmentsRequest) {
      showLoader();
      const values: IEnroledStudentPagePayloadValues =
        getPagePayloadValuesWithId(
          courseOfferingId,
          orderBy,
          order,
          DEFAULT_PAGE_NO,
          MAX_INT_VALUE
        );
      values.name = "";

      if (
        filteredData?.enrolmentStatus ||
        filteredData?.enrolmentStatus === EnrolmentStatus.Pending
      ) {
        values.enrolmentStatus = filteredData?.enrolmentStatus;
      }
      if (filteredData?.mrnNo) {
        values.mrnNo = filteredData?.mrnNo;
      }
      if (filteredData?.name) {
        values.name = filteredData?.name;
      }
      if (filteredData?.type) {
        values.type = filteredData?.type;
      }

      const payload = {
        values,
        callback: onPrintCourseListSuccess,
      };

      printCourseEnrolmentsRequest(payload);
    }
  };

  const onDownloadCertificateSuccess = (
    response: DownloadCertificateSuccessPayload
  ) => {
    const item = base64ToArrayBuffer(response.item);
    const file = new Blob([item], {
      type: "application/pdf",
    });
    const fileURL = URL.createObjectURL(file);
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.style.setProperty("print", "fit-to-print");
    iframe.src = fileURL;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      const win = iframe.contentWindow;
      if (win) {
        win.print();
      }
    };
  };

  const downloadCertificateHandler = (id: number) => {
    if (downloadCertificateRequest) {
      showLoader();

      const payload = {
        values: { id },
        callback: onDownloadCertificateSuccess,
      };

      downloadCertificateRequest(payload);
    }
  };

  const handleSendConfirmation = (id: number) => {
    setIsOpenReSendConfirmationMailModal(true);
    setStudentCourseEnrolmentId(id);
  };

  const onReSendConfirmationMailSuccess = (
    response: ReSendConfirmationMailSuccessPayload
  ) => {
    if (response.success) {
      toast.success(RESEND_SMS_SUCCESS);
      setEnroledStudentList((prevList) =>
        prevList?.map((item) =>
          item.studentCourseEnrolmentID === studentCourseEnrolmentId
            ? {
                ...item,
                isSMSSent: item.isSMSSent ? item.isSMSSent : !item.isSMSSent,
              }
            : item
        )
      );

      handleSubmitConfirmationModalClose();
    } else {
      toast.error(response.message);
    }
  };
  const dispatch = useDispatch();

  const handleConfirmationModalSubmitClick = () => {
    if (reSendConfirmationMailRequest) {
      showLoader();
      const payload = {
        values: {
          studentCourseEnrolmentId: Number(studentCourseEnrolmentId),
          courseName,
          isEmailConfirmation: false,
        },
        callback: onReSendConfirmationMailSuccess,
      };
      dispatch(reSendConfirmationMailRequest(payload));
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <Box className="table-card-header">
            <Box
              sx={{
                justifyContent: "space-between",
                width: { xs: "100%", lg: "auto" },
              }}
            >
              <Typography variant="h3">Enrolments</Typography>
              <Box sx={{ display: "flex", columnGap: "10px" }}>
                <Button
                  variant="outlined"
                  className="btn-filter btn-export"
                  disabled={!isPrintDisabled}
                  sx={{
                    display: { xs: "flex", lg: "none" },
                  }}
                  onClick={() =>
                    isPrintDisabled ? getPrintEnrolments() : null
                  }
                >
                  Print
                </Button>
                <Button
                  variant="outlined"
                  className="btn-filter"
                  sx={{
                    display: { xs: "flex", lg: "none" },
                  }}
                  onClick={handleFilter}
                >
                  <img
                    src={filterPrimaryIcon}
                    alt="filter"
                    className="default"
                  />
                  <img src={filterLightIcon} alt="filter" className="active" />
                  Filter
                </Button>
              </Box>
            </Box>
            <Box>
              <Box className="counter-box info">
                <div className="title">Enrolled</div>
                <div className="counter">{enrolledCount}</div>
              </Box>
              <Box className="counter-box success">
                <div className="title">Attendance</div>
                <div className="counter">{attendingCount}</div>
              </Box>
              <Box className="counter-box warning">
                <div className="title">Withdrawn</div>
                <div className="counter">{withdrawnCount}</div>
              </Box>{" "}
              <Button
                variant="outlined"
                className="btn-export"
                disabled={!isPrintDisabled}
                sx={{
                  display: { xs: "none", lg: "flex" },
                }}
                onClick={() => (isPrintDisabled ? getPrintEnrolments() : null)}
              >
                Print
              </Button>
              <Button
                variant="outlined"
                className="btn-filter"
                sx={{
                  display: { xs: "none", lg: "flex" },
                }}
                onClick={() => {
                  setEnrollFilterBox(true);
                  document.body.classList.toggle("filter-open");
                }}
              >
                <img src={filterPrimaryIcon} alt="filter" className="default" />
                <img src={filterLightIcon} alt="filter" className="active" />
                Filter
              </Button>
            </Box>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: any) =>
                      handleRequestSort(e, "studentName")
                    }
                    fieldName="Student Name"
                    keyName="studentName"
                    sxStyle={{
                      width: "153px",
                      minWidth: "153px",
                      [projectTheme.breakpoints.down("lg")]: {
                        width: "153px",
                        minWidth: "153px",
                      },
                    }}
                  />
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: any) => handleRequestSort(e, "mrn")}
                    fieldName="MRN"
                    keyName="mrn"
                    sxStyle={{
                      width: "99px",
                      minWidth: "99px",
                      [projectTheme.breakpoints.down("lg")]: {
                        width: "99px",
                        minWidth: "99px",
                      },
                    }}
                  />
                  <TableCell
                    align="left"
                    sx={{
                      width: "150px",
                      minWidth: "150px",
                      [projectTheme.breakpoints.down("lg")]: {
                        width: "165px",
                        minWidth: "165px",
                      },
                    }}
                  >
                    Date Enrolled
                  </TableCell>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: any) =>
                      handleRequestSort(e, "enrolmentType")
                    }
                    fieldName="Enrolment Type"
                    keyName="enrolmentType"
                    sxStyle={{
                      width: "142px",
                      minWidth: "142px",
                      [projectTheme.breakpoints.down("lg")]: {
                        width: "142px",
                        minWidth: "142px",
                      },
                    }}
                  />
                  <TableCell
                    align="left"
                    sx={{
                      width: "120px",
                      minWidth: "120px",
                      textAlign: "left",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      width: "130px",
                      minWidth: "130px",
                    }}
                  >
                    Phone
                  </TableCell>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e: any) => handleRequestSort(e, "email")}
                    fieldName="Email"
                    keyName="email"
                    sxStyle={{
                      width: "238px",
                      minWidth: "238px",
                      [projectTheme.breakpoints.down("lg")]: {
                        width: "238px",
                        minWidth: "238px",
                      },
                    }}
                  />
                  <TableCell
                    align="left"
                    sx={{
                      width: "220px",
                      minWidth: "220px",
                    }}
                  >
                    Supports Required/Specific Learning Needs
                  </TableCell>
                  {isBlendedCourse && (
                    <TableCell
                      align="left"
                      sx={{
                        width: "150px",
                        minWidth: "150px",
                      }}
                    >
                      Preferred Course Delivery
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    sx={{
                      width: "155px",
                      minWidth: "155px",
                      maxWidth: "155px",
                    }}
                  >
                    Send Confirmation SMS
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "127px", width: "127px" }}
                  >
                    <span style={{ marginLeft: "10px" }}>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enroledStudentList?.map((row: IEnroledStudent) => (
                  <TableRow key={row.studentCourseEnrolmentID}>
                    <TableCell component="th" scope="row">
                      <Box
                        sx={{ cursor: row.isNotUser ? "default" : "pointer" }}
                        onClick={() => {
                          if (!row.isNotUser) {
                            history.push({
                              pathname: AppRoutings.StudentsDetails,
                              state: {
                                studentId: Number(row.id),
                                studentName: `${row.studentName}`,
                                isDeleted: row.isDeleted,
                              },
                            });
                          }
                        }}
                      >
                        <Typography variant="body2">
                          {row.studentName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{row.mrn}</TableCell>
                    <TableCell>{row.dateEnrolled}</TableCell>
                    <TableCell>{row.enrolmentType}</TableCell>
                    <TableCell
                      align="left"
                      className={`${getStatusLabel(
                        row.status
                      ).toLowerCase()} status`}
                    >
                      <div className="enrolment-status">
                        {getStatusLabel(row.status)}
                      </div>
                    </TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>
                      <Typography variant="body2" title={row.email}>
                        {row.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body2"
                        title={row.information || "-"}
                      >
                        {row.information || "-"}
                      </Typography>
                    </TableCell>
                    {isBlendedCourse && (
                      <TableCell>
                        {row.preferredCourseDelivery || "-"}
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <div className="table-actions">
                        {row.status === EnrolmentStatus.Confirmed &&
                          !row.isDeleted && (
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={`${
                                row?.isSMSSent
                                  ? "SMS already sent"
                                  : "Send confirmation SMS"
                              }`}
                              arrow
                            >
                              <IconButton
                                disabled={row.isDeleted}
                                onClick={() =>
                                  row.isDeleted
                                    ? null
                                    : handleSendConfirmation(
                                        row?.studentCourseEnrolmentID
                                      )
                                }
                              >
                                <img
                                  src={
                                    row?.isSMSSent ? sentSMSIcon : sendSMSIcon
                                  }
                                  alt="sms"
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="table-actions enrolment-sco-actions">
                        {!row.isNotUser && (
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Edit Status"
                            arrow
                          >
                            <IconButton
                              disabled={row.isDeleted}
                              className={row.isDeleted ? "disabled-icon" : ""}
                              onClick={() => {
                                if (!row.isDeleted) {
                                  handleChangeEnrolmentStatusModal(
                                    row.studentCourseEnrolmentID,
                                    row.status
                                  );
                                }
                              }}
                            >
                              <img src={editIcon} alt="edit" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {row.isCertificateGenerated &&
                          !row.isNotUser &&
                          row.status === EnrolmentStatus.Confirmed && (
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Print Certificate"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  downloadCertificateHandler(
                                    row.studentCourseEnrolmentID
                                  )
                                }
                              >
                                <img src={printIcon} alt="list" />
                              </IconButton>
                            </Tooltip>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {enroledStudentList && enroledStudentList.length > 0 ? (
            <Box className="custom-pagination">
              <Box className="custom-rowperpage">
                <Typography variant="body2" component="span">
                  Page:
                </Typography>
                <Select
                  id="pagination-select"
                  value={page}
                  onChange={handleChange}
                  MenuProps={{
                    className: "pagination-menu",
                  }}
                >
                  {PAGE_SIZES?.map((pageSize) => (
                    <MenuItem
                      key={pageSize.value}
                      value={pageSize.value}
                      selected={pageSize?.selected}
                    >
                      {pageSize.label}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="body2" component="span">
                  {getPaginationDetailText(
                    scheduledOfferingListMeta,
                    pageNo,
                    page
                  )}
                </Typography>{" "}
              </Box>
              <Pagination
                count={scheduledOfferingListMeta?.totalPages}
                variant="outlined"
                shape="rounded"
                page={pageNo}
                onChange={onPageChange}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: ArrowBackIcon,
                      next: ArrowForwardIcon,
                    }}
                    {...item}
                  />
                )}
              />
            </Box>
          ) : (
            <NoRecords />
          )}
        </Card>
      </Grid>
      {enrollFilterBox && (
        <EnrollmentFilter
          setEnrollFilterBox={setEnrollFilterBox}
          handleApplyClick={handleFilterApplyClick}
          filteredData={filteredData}
          filterBox={enrollFilterBox}
          setFilteredData={setFilteredData}
          enroledStudentListRequest={getEnroledStudentList}
          pageNo={pageNo}
          setPageNo={setPageNo}
          enrolmentOptionList={enrolmentOptionList}
        />
      )}
      <ChangeEnrolmentStatus
        isOpenChangeEnrolmentStatusModal={isOpenChangeEnrolmentStatusModal}
        handleChangeEnrolmentStatusClose={handleChangeEnrolmentStatusClose}
        handleSaveClick={handleChangeEnrolmentStatus}
        status={changeEnrolmentStatusData}
      />
      {isOpenReSendConfirmationMailModal && (
        <ConfirmModal
          handleSubmitConfirmationModalClose={
            handleSubmitConfirmationModalClose
          }
          submitConfirmationMessage="Are you sure you want to resend confirmation sms?"
          handleYesClick={handleConfirmationModalSubmitClick}
        />
      )}
    </>
  );
};

export default Enrollments;
