import { connect, MapDispatchToProps } from "react-redux";
import {
  IMyEnrolmentContainerDispatch,
  IMyEnrolmentCourse,
} from "utils/interfaces/student-dashboard";
import { myEnrolmentListRequest } from "store/studentDashboard/actions";
import MyEnrolmentComponent, { MyEnrolmentProps } from ".";

const mapDispatchToProps: MapDispatchToProps<
  IMyEnrolmentContainerDispatch,
  IMyEnrolmentCourse
> = {
  myEnrolmentListRequest,
};

const connector = connect(null, mapDispatchToProps);

const MyEnrolment: React.FC<MyEnrolmentProps> = (props) => {
  return <MyEnrolmentComponent {...props} />;
};

export default connector(MyEnrolment);
