import { plusLightIcon, editIcon, deleteIcon, closeIcon } from "assets/images";
import ArrowBackIcon from "components/ArrowBackIcon";
import ArrowForwardIcon from "components/ArrowForwardIcon";
import NoRecords from "components/NoRecords";
import {
  TableCell,
  Box,
  SelectChangeEvent,
  Typography,
  Button,
  Grid,
  Card,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Select,
  MenuItem,
  Pagination,
  PaginationItem,
  InputAdornment,
  Fade,
  Tooltip,
} from "@mui/material";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NO,
  PAGE_SIZES,
  EDIT_TOOLTIP_TITLE,
  DELETE_TOOLTIP_TITLE,
  CLEAR_TOOLTIP_TITLE,
} from "utils/constants/constant";
import { AppPages } from "utils/enums/app-pages";
import { Order, OrderByOptions } from "utils/enums/sorting";
import {
  hideLoaderForBatchApiCall,
  showLoaderForBatchApiCall,
  getPagePayloadValues,
  getPagePermissions,
  allowSearch,
  getPaginationDetailText,
  showLoader,
  onTextFieldKeyPress,
} from "utils/helper";
import {
  IUserContainerState,
  IUserContainerDispatch,
  IEnhancedTableProps,
  IUsersDetails,
  IUser,
  IUserListMeta,
  IAddEditUser,
  IUserPagePayloadValues,
} from "utils/interfaces/user";
import projectTheme from "app.theme";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IRoleModulePermission } from "store/role/types";
import {
  AddEditUserSuccessPayload,
  DeleteUserSuccessPayload,
  UserListSuccessPayload,
} from "store/user/types";
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import {
  USER_ADD_SUCCESS_MSG,
  USER_DELETE_SUCCESS_MSG,
  USER_EDIT_SUCCESS_MSG,
} from "utils/constants/Messages";
import { toast } from "react-toastify";
import AddEditUser from "./AddEditUser";

const EnhancedTableHead = (props: IEnhancedTableProps) => {
  const { order, orderBy, onRequestSort, fieldName, keyName, sxStyle } = props;
  const createSortHandler =
    (newOrderBy: keyof IUsersDetails) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableCell
      key={keyName}
      align="left"
      onClick={createSortHandler(keyName)}
      sortDirection={orderBy === keyName ? order : false}
      sx={sxStyle}
      className="term-sorting"
    >
      {fieldName}
      <Box component="span" className="sorting-icon" />
    </TableCell>
  );
};

export type UserProps = IUserContainerState & IUserContainerDispatch;

const Users: React.FC<UserProps> = (props) => {
  const [userList, setUserList] = useState<IUser[]>();
  const [userListMeta, setUserListMeta] = useState<IUserListMeta>();
  const [page, setPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNo, setPageNo] = useState<number>(DEFAULT_PAGE_NO);
  const [order, setOrder] = useState<Order>(OrderByOptions.Asc);
  const [orderBy, setOrderBy] = useState<keyof IUsersDetails>("name");
  const [searchText, setSearchText] = useState<string>("");
  const [pagePermission, setPagePermission] = useState<IRoleModulePermission>();
  const [filteredData, setFilteredData] = useState<IAddEditUser>();
  const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<number>();
  const [isEditUser, setIsEditUser] = useState<boolean>(false);
  const [isOpenUserModal, setIsOpenUserModal] = useState<boolean>(false);
  const [editUserData, setEditUserData] = useState<IUser>();
  const [isOpenUserDeleteModal, setIsOpenUserDeleteModal] =
    useState<boolean>(false);
  const history = useHistory();
  const user = useSelector((state: any) => state?.auth?.user);

  const handleChange = (event: SelectChangeEvent) => {
    setPage(event.target.value as string);
    setPageNo(1);
  };

  const onPageChange = (e: any, value: number) => {
    setPageNo(value);
  };

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof IUsersDetails) => {
      const isAsc = orderBy === newOrderBy && order === OrderByOptions.Asc;
      const toggledOrder = isAsc ? OrderByOptions.Desc : OrderByOptions.Asc;
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const onUserListSuccess = (response: UserListSuccessPayload) => {
    setUserList(response.userList);
    setUserListMeta(response.userListPagination);
    hideLoaderForBatchApiCall();
  };

  const getUserList = async (
    filterData?: IAddEditUser,
    filtered?: boolean,
    pageNum?: number
  ) => {
    const { userListRequest } = props;
    if (userListRequest) {
      showLoaderForBatchApiCall();

      const values: IUserPagePayloadValues = getPagePayloadValues(
        orderBy,
        order,
        pageNo,
        page
      );
      values.name = filtered ? "" : searchText.trim();

      if (filterData) {
        setFilteredData(filterData);
        setAppliedFilter(true);

        if (pageNum) {
          values.pageNo = pageNum;
          setPageNo(pageNum);
        }
      } else {
        setFilteredData(undefined);
      }
      const payload = {
        values,
        callback: onUserListSuccess,
      };
      userListRequest(payload);
    }
  };

  useEffect(() => {
    const permission = getPagePermissions(user?.permissions, AppPages.Users);
    setPagePermission(permission);
    if (permission?.view) {
      if (filteredData && !appliedFilter) {
        getUserList(filteredData, true, 1);
      } else if (filteredData) {
        getUserList(filteredData);
      } else {
        getUserList();
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageNo, order, orderBy]);

  const handleUserDeleteModal = (userId: number) => {
    setDeleteUserId(userId);
    setIsOpenUserDeleteModal(true);
  };

  const handleUserDeleteCloseModal = () => {
    setDeleteUserId(0);
    setIsOpenUserDeleteModal(false);
  };

  const handleUserSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    getUserList(filteredData, true, 1);
  };

  const handleUserSearchKeyDown = (e: any) => {
    if (allowSearch(e)) {
      setPageNo(1);
      getUserList(filteredData);
    }
  };
  const onDeleteUserSuccess = (response: DeleteUserSuccessPayload) => {
    if (response.success) {
      toast.success(USER_DELETE_SUCCESS_MSG);
      handleUserDeleteCloseModal();
      getUserList();
    }
  };

  const handleDeleteUser = () => {
    const { deleteUserRequest } = props;

    if (deleteUserRequest) {
      showLoader();
      const payload = {
        values: {
          id: Number(deleteUserId),
        },
        callback: onDeleteUserSuccess,
      };

      deleteUserRequest(payload);
    }
  };

  const handleUserClose = () => setIsOpenUserModal(false);

  const onAddUserSuccess = (response: AddEditUserSuccessPayload) => {
    if (response.success) {
      toast.success(USER_ADD_SUCCESS_MSG);
      handleUserClose();
      getUserList();
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const onEditUserSuccess = (response: AddEditUserSuccessPayload) => {
    if (response.success) {
      toast.success(USER_EDIT_SUCCESS_MSG);
      handleUserClose();
      getUserList();
    } else if (response.message) {
      toast.warning(response.message);
    }
  };

  const handleEditUser = (formData: IAddEditUser) => {
    const { editUserRequest } = props;
    if (editUserRequest) {
      showLoader();
      const payload = {
        values: {
          id: editUserData?.id,
          firstName: formData.firstName.trim(),
          lastName: formData.lastName.trim(),
          email: formData.email.trim(),
          roleIds: formData.roleIds,
          address: formData.address?.trim() || "",
        },
        callback: onEditUserSuccess,
      };
      editUserRequest(payload);
    }
  };

  const handleAddUser = (formData: IAddEditUser) => {
    const { addUserRequest } = props;
    if (addUserRequest) {
      showLoader();
      const payload = {
        values: {
          firstName: formData.firstName.trim(),
          lastName: formData.lastName.trim(),
          email: formData.email.trim(),
          roleIds: formData.roleIds,
          address: formData.address?.trim() || "",
        },
        callback: onAddUserSuccess,
      };
      addUserRequest(payload);
    }
  };

  const handleUser = (isEdit: boolean, editData: IUser | null) => {
    setIsEditUser(isEdit);
    if (editData) {
      setEditUserData(editData);
    } else {
      setEditUserData(undefined);
    }
    setIsOpenUserModal(true);
  };

  return (
    <div>
      <>
        <div>
          <Box className="content-header">
            <Typography variant="h2" className="heading">
              Users
            </Typography>
            <Button
              variant="contained"
              className="btn-add"
              onClick={() => handleUser(false, null)}
              disabled={!pagePermission?.add}
            >
              <img src={plusLightIcon} alt="plus" />
              Add
            </Button>
          </Box>
          <Grid
            container
            spacing={{ xs: "16px", lg: "20px", xl: "24px" }}
            className="content-container"
          >
            <Grid item xs={12}>
              <Card>
                <Box className="table-card-header">
                  <Box
                    sx={{ justifyContent: "space-between", width: "100%" }}
                    className="search-filter-inputs"
                  >
                    <TextField
                      id="search-box"
                      variant="outlined"
                      className="search-input"
                      placeholder="Search"
                      value={searchText}
                      onChange={handleUserSearch}
                      onKeyDown={handleUserSearchKeyDown}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ position: "absolute", right: "0" }}
                            position="end"
                          >
                            {searchText && (
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={CLEAR_TOOLTIP_TITLE}
                                arrow
                              >
                                <IconButton
                                  edge="end"
                                  onClick={handleClearSearch}
                                >
                                  <img src={closeIcon} alt="close" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      onKeyPress={(event) =>
                        onTextFieldKeyPress(event, searchText?.length)
                      }
                    />
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "name")}
                          fieldName="Name"
                          keyName="name"
                          sxStyle={{
                            maxWidth: "175px",
                            width: "175px",
                            minWidth: "203px",
                          }}
                        />
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "email")}
                          fieldName="Email"
                          keyName="email"
                          sxStyle={{ width: "253px", minWidth: "253px" }}
                        />
                        <TableCell
                          align="left"
                          sx={{
                            width: "220px",
                            [projectTheme.breakpoints.down("sm")]: {
                              width: "220px",
                            },
                          }}
                        >
                          Role
                        </TableCell>
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={(e) => handleRequestSort(e, "address")}
                          fieldName="Address"
                          keyName="address"
                          sxStyle={{ width: "410px", minWidth: "410px" }}
                        />
                        <TableCell
                          align="center"
                          sx={{
                            width: "100px",
                            [projectTheme.breakpoints.down("sm")]: {
                              width: "112px",
                            },
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userList?.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            sx={{ wordWrap: "break-word" }}
                            component="th"
                            scope="row"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="email">
                              {row.email}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="email">
                              {row.roleNames
                                ? row.roleNames
                                    ?.map((rn) => ` ${rn}`)
                                    .toString()
                                : ""}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" className="email">
                              {row.address || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <div className="table-actions">
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={EDIT_TOOLTIP_TITLE}
                                arrow
                              >
                                <IconButton
                                  onClick={() => handleUser(true, row)}
                                  disabled={!pagePermission?.edit}
                                  className={
                                    !pagePermission?.edit ? "disabled-icon" : ""
                                  }
                                >
                                  <img src={editIcon} alt="edit" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title={DELETE_TOOLTIP_TITLE}
                                arrow
                              >
                                <IconButton
                                  onClick={() =>
                                    handleUserDeleteModal(Number(row?.id))
                                  }
                                  disabled={!pagePermission?.delete}
                                  className={
                                    !pagePermission?.delete
                                      ? "disabled-icon"
                                      : ""
                                  }
                                >
                                  <img src={deleteIcon} alt="delete" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {userList && userList.length > 0 ? (
                  <Box className="custom-pagination">
                    <Box className="custom-rowperpage">
                      <Typography variant="body2" component="span">
                        Page:
                      </Typography>
                      <Select
                        id="pagination-select"
                        value={page}
                        onChange={handleChange}
                        MenuProps={{
                          className: "pagination-menu",
                        }}
                      >
                        {PAGE_SIZES?.map((pageSize) => (
                          <MenuItem
                            key={pageSize.value}
                            value={pageSize.value}
                            selected={pageSize?.selected}
                          >
                            {pageSize.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant="body2" component="span">
                        {getPaginationDetailText(userListMeta, pageNo, page)}
                      </Typography>{" "}
                    </Box>
                    <Pagination
                      count={userListMeta?.totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={pageNo}
                      onChange={onPageChange}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{
                            previous: ArrowBackIcon,
                            next: ArrowForwardIcon,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </Box>
                ) : (
                  <NoRecords />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      </>
      <AddEditUser
        isEditUser={isEditUser}
        isOpenUserModal={isOpenUserModal}
        handleUserClose={handleUserClose}
        handleSaveClick={isEditUser ? handleEditUser : handleAddUser}
        editUserData={editUserData}
      />
      <DeleteConfirmationModal
        isOpenDeleteConfirmationModal={isOpenUserDeleteModal}
        handleDeleteConfirmationModalClose={handleUserDeleteCloseModal}
        deleteConfirmationMessage="Are you sure you want to delete user?"
        handleYesClick={handleDeleteUser}
      />
    </div>
  );
};

export default Users;
